<template>
	<div
		:data-ta="`radio_button_${name}`"
		class="com-field-item com-field-radio-button"
	>
		<el-form-item
			:ref="name"
			:label="label || title"
			:prop="name"
			:label-width="labelWidth"
			class="line-height-base"
		>
			<el-radio-group
				v-model="model"
				:disabled="disabled || readonly || !isEdit"
				:data-ta="name"
				:size="size"
				class="w-100p"
			>
				<el-radio-button
					v-for="{ value: value2, label: label2 } in options"
					:key="value2"
					:value="value2"
					:data-ta-radio="value2"
					:label="value2"
					@click="onRadioClick(value2)"
				>
					{{ label2 }}
				</el-radio-button>
			</el-radio-group>
		</el-form-item>
	</div>
</template>

<script>
import defaultProps from './defaultFieldsProps'

export default {
	name: 'ComFieldRadioButton',
	components: {
	},
	props: {
		...defaultProps,
		options: {
			type: Array,
			default: () => ([]),
		},
	},
	computed: {
		model: {
			get() { return this.modelValue ?? (this.defaultValue || '') },
			set(value) {
				this.$emit('change', value)
				this.$emit('update:modelValue', value)
			},
		},
	},
	methods: {
		onRadioClick(val) {
			this.$emit('on-radio-click', val)
		},
	},
}
</script>

<style lang="scss">
.com-field-radio-button {
	.el-radio__input {
		display: inline-flex;
		align-items: center;
	}

	.el-radio__label {
		display: inline-flex;
		align-items: center;
	}

	.el-form-item__content {
		line-height: 28px;
	}

	.el-radio-button__inner {
		font-weight: var(--el-font-weight-primary);
	}
}
</style>