import i18n from '~/plugins/i18n'

export default {
	OPTOMETRIST: {
		label: i18n.global.t('Медицинский оптик-оптометрист'),
		id: 'OPTOMETRIST',
	},
	DOCTOR: {
		label: i18n.global.t('Врач-офтальмолог'),
		id: 'DOCTOR',
	},
}