<template>
	<el-tooltip
		v-if="isHelpMode && isRu"
		class="ml-1"
	>
		<ItIcon
			name="question-mark-circle-outline"
			width="16"
			height="16"
			class="fill-brand"
		/>

		<template #content>
			<div
				style="width: 200px;"
			>
				<div class="text-center">{{ text }}</div>

				<a
					v-if="href"
					:href="href"
					target="_blank"
					rel="noopener noreferrer"
					class="d-block mt-2"
				>
					<el-button
						type="info"
						size="default"
						class="w-100p"
					>
						<span>Узнать</span>

						<ItIcon
							name="external-link"
							width="14"
							height="14"
						/>
					</el-button>
				</a>
			</div></template>
	</el-tooltip>
</template>

<script>
import { mapState } from 'vuex'
import { isRu } from '~/helpers/common/computed'

export default {
	name: 'OptimaHelpTooltip',
	props: {
		text: {
			type: String,
			required: true,
		},
		href: {
			type: String,
			default: null,
		},
	},
	computed: {
		isRu,
		...mapState('user', [
			'isHelpMode',
		]),
	},
}
</script>