import fieldsOrderTypes from '~/pages/departments/data/fieldsOrderTypes'
import fieldsProductTypes from '~/pages/departments/data/fieldsProductTypes'

export default {
	mNewDepartment(state) {
		state.loading = false
	},
	mDepartment(state, payload) {
		state.department = payload
	},
	mSetDefaultDepartment(state) {
		state.department = state.defaultDepartment
	},
	mDepartments(state, payload) {
		state.departments = payload.content.map(item => {
			Object.entries(item).forEach(([k, v]) => {
				if (v === null || v === '') {
					item[k] = '-'
				}
			})
			return {
				...item,
				legalEntity: item.legalEntityId,
			}
		})
	},
	mDepartmentsByUserGroup(state, payload) {
		state.departmentsByUserGroup = payload
	},
	mDepartmentsProduction(state, payload) {
		state.departmentsProduction = payload?.content || []
	},
	mDepartmentsDepot(state, payload) {
		state.departmentsDepot = payload?.content || []
	},
	mDepartmentsForSearch(state, payload) {
		state.departmentsForSearch = payload?.content || []
	},
	mDepartmentsOtherSearch(state, payload) {
		state.departmentsOtherSearch = payload
	},
	mDepartmentsTypes(state, payload) {
		state.departmentsTypes = payload
	},
	mAccountsCash(state, payload) {
		state.accountsCash = payload
	},
	mAccountsCashless(state, payload) {
		state.accountsCashless = payload
	},
	mOrdersTypes(state, payload) {
		const ids = payload.map(item => item.id)
		const fields = fieldsOrderTypes.filter(field => ids.includes(field.name))
		state.ordersTypes = fields
	},
	mDepartmentLegalEntities(state, payload) {
		state.departmentLegalEntities = payload
		/* state.departmentLegalEntities = {
			...payload,
			account: payload.account || '',
			fiscalRegister: {
				...,
				"id": payload.fiscalRegister.id || '',
				"configJson": null,
				"externalId": null,
				"name": null,
				"type": null
			}
			fiscalRegister: {
			},
			"unremovable": false
		} */
	},
	mDepartmentUnusedLegalEntities(state, payload) {
		state.departmentUnusedLegalEntities = payload
	},
	mDepartmentLegalEntityRules(state, payload) {
		state.departmentLegalEntityRules = payload
	},
	mDepartmentLegalEntityRulesPossibleAccessoryCategories(state, payload) {
		state.departmentLegalEntityRulesPossibleAccessoryCategories = payload
	},
	mDepartmentLegalEntityRulesPossibleServiceTypes(state, payload) {
		state.departmentLegalEntityRulesPossibleServiceTypes = payload
	},
	mFiscalRegisterTypes(state, payload) {
		state.fiscalRegisterTypes = payload
	},
	mFiscalRegisterExternal(state, payload) {
		state.fiscalRegisterExternal = payload
	},
	mFiscalRegister(state, payload) {
		state.fiscalRegister = payload
	},
	mProductsTypes(state, payload) {
		const ids = payload.map(item => item.id)
		const fields = fieldsProductTypes.filter(field => ids.includes(field.name))
		state.productsTypes = fields
	},
	mAccessoryTypes(state, payload) {
		const ids = payload.map(item => item.id)
		const fields = fieldsProductTypes.filter(field => ids.includes(field.name))
		state.productsTypes = fields
	},
	mServicesTypes(state, payload) {
		const typesMap = {}
		payload.forEach(st => {
			typesMap[st.id] = st
		})
		state.servicesTypes = typesMap
	},
	mRegistryData(state, payload) {
		state.registryData = payload
	},
	mNativeFiscalRegisterEditing(state, payload) {
		state.nativeFiscalRegisterEditing = payload
	},
	mLegalEntitiesInTable(state, payload) {
		state.legalEntitiesInTable = payload
	},
	mDepartmentSettings(state, payload) {
		state.departmentSettings = payload
	},
}