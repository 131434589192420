<template>
	<el-input
		ref="goodsBarcodesScanner"
		v-model="barcode"
		:placeholder="$t('Просканируйте или введите вручную')"
		:maxlength="barcodeMaxLength"
		class="com-order-barcode"
		:style="cssVars"
		data-ta="order-barcode"
		size="default"
		@update:modelValue="scanBarcode"
		@blur="onBlur"
	>
		<template #prepend><span

			class="com-order-barcode__btn bold"
		>
			{{ $t('Штрихкод') }}
		</span></template>
	</el-input>
</template>

<script>
const THROTTLING_SIZE = 1000

export default {
	name: 'GoodsBarcodesScanner',
	props: {
		barcodesLength: {
			type: Array,
			default: () => [],
		},
		throttlingSize: {
			type: Number,
			default: THROTTLING_SIZE,
		},
	},

	data: () => ({
		barcode: '',
		inputBorderColor: '#1989fa',
	}),
	computed: {
		barcodeMaxLength() {
			return Math.max(...this.barcodesLength)
		},
		cssVars() {
			return {
				'--border-color': this.inputBorderColor,
			}
		},
	},
	mounted() {
		this.setPasteEventListener()
	},
	methods: {
		scanBarcode() {
			this.barcode = this.barcode
				.replace(/\s+/g, '')
				.replace(/\D/g, '')

			function throttleScan(val) {
				if (this.throttleTO) {
					clearTimeout(this.throttleTO)
				}

				this.throttleTO = setTimeout(() => {
					const validLength = this.barcodesLength.find(item => item === val.length)
					if (validLength) {
						this.inputBorderColor = '#1989fa'
						this.$emit('scanBarcode', val)
						this.barcode = ''
					}
				}, this.throttlingSize)
			}

			throttleScan.call(this, this.barcode)
		},
		onFocus() {
			this.$refs.goodsBarcodesScanner.focus()
		},
		onBlur() {
			this.inputBorderColor = '#1989fa'
			this.$emit('blur')
		},
		setPasteEventListener() {
			const input = this.$refs.goodsBarcodesScanner.$el
			input.addEventListener('paste', e => {
				e.preventDefault()

				const { clipboardData } = e
				this.barcode = clipboardData.getData('Text')
					.replace(/\s+/g, '')

				this.scanBarcode()
			})
		},
	},
}
</script>

<style lang="scss">

.com-order-barcode {
	&__btn {
		font-size: 12px;
	}

	.el-input-group__prepend {
		background-color: #1989fa;
		color: white;
	}

	.el-input__inner {
		font-size: 12px;

		&:focus {
			outline: none;
			border-color: var(--border-color);
		}
	}
}
</style>