<template>
	<div
		:class="{ 'show-scroll': certificatesCalcs.length > 3 }"
		class="gift-certificates"
	>
		<div
			v-for="(cert, idx) in certificatesCalcs"
			:key="cert.id"
			class="gift-certificate"
		>
			<div class="payment-popup__small-title d-flex align-items-center">
				<div>
					№ <span class="font-semi-bold">{{ cert.certificateNumber }}</span> <br>
					Остаток <span class="font-semi-bold">{{ cert.totalPriceFormatted }}</span>
				</div>
			</div>
			<div class="payment-popup__large-title">
				<span class="color-text-primary">{{ cert.soldPriceFormatted }}</span>

				<el-tooltip
					class="item"
					effect="dark"
					:content="$t('Удалить сертификат')"
					placement="top-start"
				>
					<el-button
						text
						icon="close"
						class="close-icon color-text-regular"
						@click="removeCertificate(idx)"
					/>
				</el-tooltip>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	name: 'GiftCertificates',

	props: {
		certificates: {
			type: Array,
			default: () => [],
		},
		totalSum: {
			type: Number,
			default: 0,
		},
		certificatesCalcs: {
			type: Array,
			default: () => [],
		},
	},

	methods: {
		removeCertificate(idx) {
			this.$emit('removeCertificate', idx)
		},
	},
}
</script>

<style lang="scss">

.show-scroll {
	overflow-y: scroll;
	max-height: 175px;
}

.gift-certificates {
	display: flex;
	align-items: flex-end;
	flex-direction: column;
}

.gift-certificate {
	box-sizing: border-box;
	display: flex;
	justify-content: space-between;
	margin-top: 8px;
	background-color: var(--el-background-color-base);
	padding: 8px 12px;
	border: 1px solid #cbcbd5;
	border-radius: 4px;
	width: 288px;

	&:first-child {
		margin-top: 0;
	}
}
</style>