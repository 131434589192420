import { ordersStatuses } from '~/helpers/common/OrdersStatuses'
import { orderTypes } from '~/helpers/common/OrderTypes'
import { PRESCRIPTION_BASES } from '~/helpers/common/PrescriptionsBases'

export default {
	mUsersProduction(state, payload) {
		state.usersProduction = payload
	},
	mDepartmentsStores(state, payload) {
		state.departmentsStores = payload
	},
	mDepartmentsProductions(state, payload) {
		state.departmentsProductions = payload
	},
	mDepartmentsList(state, payload) {
		state.departmentsList = payload
	},
	mOrdersStatuses(state, payload) {
		state.ordersStatuses = payload.map(status => ({
			value: status,
			label: ordersStatuses[status].label,
		}))
	},
	mExtrasOrdersStatuses(state, payload) {
		state.extrasOrderStatuses = payload
	},
	mOrdersTypesWithExtraTypes(state, payload) {
		state.ordersTypesWithExtraTypes = payload.map(type => ({
			value: type.id,
			label: orderTypes[String(type.id).toUpperCase()].label,
		}))
	},
	mPrescriptionsBases(state, payload) {
		state.prescriptionsBases = payload.map(prescriptionsBase => ({
			value: prescriptionsBase,
			label: PRESCRIPTION_BASES[prescriptionsBase],
		}))
	},
	mWorkshopOrder(state, payload) {
		state.workshopOrder = payload || {}
	},
}