<template>
	<SimpleCard class="order-payment">
		<template #default>
			<BonusCard :card="getBonusCard"/>

			<div class="order-payment__calculation">
				<div class="order-payment__order-info">
					<span>{{ $t('Сумма заказа') }}</span>
					<span
						class="bold"
						data-ta="order-cart-price"
					>{{ getOrderSum }}</span>
				</div>
				<div class="order-payment__order-info">
					<span>{{ $t('Скидка') }}</span>
					<span
						class="order-payment__discount"
						data-ta="order-discount"
					>{{ getOrderDiscount }}</span>
				</div>
			</div>

			<el-divider
				v-if="getBonusCard"
				class="order-payment__divider mt-3"
			/>

			<Bonuses
				v-if="getBonusCard"
				:card="getBonusCard"
			/>

			<el-divider class="order-payment__divider"/>

			<div class="order-payment__sum">
				<span class="bold">{{ $t('Итого') }}</span>
				<span
					class="font-bold order-payment__totalSum color-text-primary"
					data-ta="order-total"
				>{{ getOrderTotalSum }}</span>
			</div>

			<div class="order-payment__complete">
				<PaymentPopup
					:total-sum="getOrderTotalSum"
					@submit-payments="$emit('submit-payments', $event)"
				/>

				<PaymentPopup
					v-if="gCartInfo && !gCartInfo.mainOnly"
					:total-sum="getOrderTotalSum"
					:only-cash="true"
					@submit-payments="$emit('submit-payments', $event)"
				/>

				<div
					class="order-payment__cancel bold cursor-pointer"
					@click="$emit('return')"
				>
					{{ $t('Отменить продажу') }}
				</div>
			</div>
		</template>
	</SimpleCard>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import SimpleCard from '~/components/old/SimpleCard'
import { formatCurrency } from '~/utils/currencies'
import BonusCard from './PartOrderTotal/BonusCard.vue'
import Bonuses from './PartOrderTotal/Bonuses.vue'
import PaymentPopup from './PartOrderTotal/PaymentPopup.vue'

export default {
	name: 'PartOrderTotal',
	components: {
		SimpleCard,
		BonusCard,
		Bonuses,
		PaymentPopup,
	},
	computed: {
		...mapState('orders', ['cartUid']),
		...mapState('router', ['from']),
		...mapGetters('orders', ['gCartInfo']),

		getBonusCard() {
			return this.gCartInfo?.clientCard
		},
		getOrderSum() {
			return this.formatCurrency(this.gCartInfo?.cartPrice || 0, this.$i18n.locale)
		},
		getOrderDiscount() {
			return this.gCartInfo
				&& this.gCartInfo.cartDiscount > 0 ? `-${this.formatCurrency(this.gCartInfo.cartDiscount, this.$i18n.locale)}` : 0
		},
		getOrderTotalSum() {
			return this.formatCurrency(this.gCartInfo?.cartDiscountPrice || 0, this.$i18n.locale)
		},
	},

	methods: {
		formatCurrency,
		...mapActions('orders', [
			'aAddBonusCard',
			'aDeleteBonusCard',
			'aServices',
			'aUnmarkedGoods',
		]),
	},
}
</script>

<style lang="scss">
.order-payment {
	box-shadow: 0 10px 20px rgba(0, 0, 0, 0.08);
	line-height: 16px;

	&__add-card {
		width: 100%;
		font-size: 14px;
		height: 36px;
	}

	&__divider {
		margin: 8px 0;
	}

	&__calculation {
		margin-top: 16px;
		display: flex;
		flex-direction: column;
		gap: 12px
	}

	&__order-info {
		display: flex;
		justify-content: space-between;
	}

	&__bold {
		font-weight: 600;
	}

	&__discount {
		font-weight: 600;
		color: #ff3c3c
	}

	&__bonuses-amount {
		color: green
	}

	&__sum {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		margin-top: 16px;
	}

	&__totalSum {
		font-size: 24px !important;
	}

	&__complete {
		margin-top: 16px;
		display: flex;
		flex-direction: column;
		gap: 16px;
	}

	&__confirm {
		width: 100%;
		font-size: 18px;
		padding-top: 12px;
		padding-bottom: 12px;
		height: 48px;
		font-weight: 600;
	}

	&__cancel {
		width: 100%;
		color: red;
		text-align: center;
		font-size: 14px;
	}

	a {
		text-decoration: none !important;

		&:hover {
			opacity: 0.7;
		}
	}
}
</style>