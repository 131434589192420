import ordersState from './ordersState'
import ordersActions from './ordersActions'
import ordersMutations from './ordersMutations'
import ordersGetters from './ordersGetters'

export default {
	namespaced: true,
	state: ordersState,
	getters: ordersGetters,
	mutations: ordersMutations,
	actions: ordersActions,
}