import { formatDateTimeUtc, getFioFull, formatOrderIdCell } from '~/utils/formatters'
import { dayjs } from '~/kit/plugins/dayjs'
import i18n from '~/plugins/i18n'

export default {
	mJournalsClients(state, payload) {
		if (payload) {
			const { content, ...other } = payload
			state.journalsClients = content
			state.journalsClientsPagination = other
		} else {
			state.journalsClients = []
			state.journalsClientsPagination = {}
		}
	},
	mJournalsDepartments(state, payload) {
		if (payload) {
			const { content, ...other } = payload
			state.journalsDepartments = content
			state.journalsDepartmentsPagination = other
		} else {
			state.journalsDepartments = []
			state.journalsDepartmentsPagination = {}
		}
	},
	mJournalsUsers(state, payload) {
		if (payload) {
			const { content, ...other } = payload
			state.journalsUsers = content.map(user => ({
				...user,
				fio: getFioFull(user),
			}))
			state.journalsUsersPagination = other
		} else {
			state.journalsUsers = []
			state.journalsUsersPagination = {}
		}
	},
	mJournalsGlasses(state, payload) {
		if (payload) {
			const { content, ...other } = payload
			state.journalsGlasses = content.map(row => {
				const create = row.order.createdAt
					? formatDateTimeUtc(row.order.createdAt).split(' ')
					: ['-', '']
				const finished = row.order.finishedAt
					? formatDateTimeUtc(row.order.finishedAt).split(' ')
					: ['-', '']
				const productionStarted = row.productionStartedAt
					? formatDateTimeUtc(row.productionStartedAt).split(' ')
					: ['-', '']

				return {
					...row.order,
					idFormatted: formatOrderIdCell(null, null, row.order.id),
					userShortName: row.employee.shortName,
					userFullName: getFioFull(row.employee),
					productionStartedDate: productionStarted[0],
					productionStartedTime: productionStarted[1],
					createDate: create[0],
					createTime: create[1],
					finishedDate: finished[0],
					finishedTime: finished[1],
				}
			})
			state.journalsGlassesPagination = other
		} else {
			state.journalsGlasses = []
			state.journalsGlassesPagination = {}
		}
	},
	mJournalsLegalEntities(state, payload) {
		if (payload) {
			const { content, ...other } = payload
			state.journalsLegalEntities = content
			state.journalsLegalEntitiesPagination = other
		} else {
			state.journalsLegalEntities = []
			state.journalsLegalEntitiesPagination = {}
		}
	},
	mJournalsLicences(state, payload) {
		if (payload) {
			const { content, ...other } = payload
			state.journalsLicences = content
			state.journalsLicencesPagination = other
		} else {
			state.journalsLicences = []
			state.journalsLicencessPagination = {}
		}
	},
	mJournalsAccessControl(state, payload) {
		if (payload) {
			const { content, ...other } = payload
			state.journalsAccessControl = content.map((item, id) => ({
				...item,
				dayLoginAt: dayjs(item.loginAt).utc().format('DD.MM.YYYY'),
				timeLoginAt: dayjs(item.loginAt).utc().format('HH:mm'),
				dayLastActionAt: dayjs(item.lastActionAt)
					.utc()
					.format('DD.MM.YYYY'),
				timeLastActionAt: dayjs(item.lastActionAt)
					.utc()
					.format('HH:mm'),
				id,
			}))
			state.journalsAccessControlPagination = other
		} else {
			state.journalsAccessControl = []
			state.journalsAccessControlPagination = {}
		}
	},
	mJournalsAccessControlStatistics(state, payload) {
		if (payload) {
			const { content, ...other } = payload
			state.journalsAccessControlStatistics = content.map(item => ({
				...item,
				id: item.id,
				workTime: `${item.workTimeHours + i18n.global.t('ч.')} ${item.workTimeMinutes}${i18n.global.t('м.')}`,
			}))
			state.journalsAccessControlStatisticsPagination = other
		} else {
			state.journalsAccessControlStatistics = []
			state.journalsAccessControlStatisticsPagination = {}
		}
	},
	mJournalsClientFiles(state, payload) {
		if (payload) {
			const { content, ...other } = payload
			state.journalsClientFiles = content.map(item => ({
				...item,
				userFullName: getFioFull(item.user),
			}))
			state.journalsClientFilesPagination = other
		} else {
			state.journalsClientFiles = []
			state.journalsClientFilesPagination = {}
		}
	},
	mJournalsSalesPlans(state, payload) {
		if (payload) {
			const { content, ...other } = payload
			state.journalsSalesPlans = content
			state.journalsSalesPlansPagination = other
		} else {
			state.journalsSalesPlans = []
			state.journalsSalesPlansPagination = {}
		}
	},
	mJournalsOrders(state, payload) {
		if (payload) {
			const { content, totalResult, ...other } = payload
			state.journalsOrders = content
			state.journalsOrdersPagination = other
			state.journalsOrdersTotals = totalResult
		} else {
			state.journalsOrders = []
			state.journalsOrdersPagination = {}
			state.journalsOrdersTotals = {}
		}
	},
	mJournalsOrdersExpectedDate(state, payload) {
		if (payload) {
			const { content, totalResult, ...other } = payload
			state.journalsOrdersExpectedDate = content
			state.journalsOrdersExpectedDatePagination = other
			state.journalsOrdersExpectedDateTotals = totalResult
		} else {
			state.journalsOrdersExpectedDate = []
			state.journalsOrdersExpectedDatePagination = {}
			state.journalsOrdersExpectedDateTotals = {}
		}
	},
	mJournalsTransfers(state, payload) {
		if (payload) {
			const { content, totalResult, ...other } = payload
			state.journalsTransfers = content
			state.journalsTransfersPagination = other
			state.journalsTransfersTotals = totalResult || null
		} else {
			state.journalsTransfers = []
			state.journalsTransfersPagination = {}
			state.journalsTransfersTotals = null
		}
	},
	mJournalsDirectory(state, payload) {
		if (payload) {
			const { content, ...other } = payload

			if (content && other) {
				state.journalsDirectory = content
				state.journalsDirectoryPagination = other
			} else {
				state.journalsDirectory = payload
				state.journalsDirectoryPagination = {}
			}
		} else {
			state.journalsDirectory = []
			state.journalsDirectoryPagination = {}
		}
	},
	mJournalsOrdersCompleted(state, payload) {
		if (payload) {
			const { content, ...other } = payload
			state.journalsOrdersCompleted = content
			state.journalsOrdersCompletedPagination = other
		} else {
			state.journalsOrdersCompleted = []
			state.journalsOrdersCompletedPagination = {}
		}
	},
	mJournalsAcceptTransfer(state, payload) {
		if (payload) {
			const { content, ...other } = payload

			if (content && other) {
				state.journalsAcceptTransfer = content
				state.journalsAcceptTransferPagination = other
			} else {
				state.journalsAcceptTransfer = payload
				state.journalsAcceptTransferPagination = {}
			}
		} else {
			state.journalsAcceptTransfer = []
			state.journalsAcceptTransferPagination = {}
		}
	},
	mJournalsReturns(state, payload) {
		if (payload) {
			const { content, ...other } = payload

			state.journalsReturns = content
			state.journalsReturnsPagination = other
		} else {
			state.journalsReturns = []
			state.journalsReturnsPagination = {}
		}
	},
	mJournalsExtras(state, payload) {
		if (payload) {
			const { content, totalResult, ...other } = payload
			state.journalsExtras = content
			state.journalsExtrasPagination = other
			state.journalsExtrasTotals = totalResult
		} else {
			state.journalsExtras = []
			state.journalsExtrasPagination = {}
			state.journalsExtrasTotals = {}
		}
	},
	mJournalsExtrasGroups(state, payload) {
		if (payload) {
			const { content, totalResult, ...other } = payload
			state.journalsExtrasGroups = content
			state.journalsExtrasGroupsPagination = other
			state.journalsExtrasGroupsTotals = totalResult
		} else {
			state.journalsExtrasGroups = []
			state.journalsExtrasGroupsPagination = {}
			state.journalsExtrasGroupsTotals = {}
		}
	},
	mJournalsRevisions(state, payload) {
		if (payload) {
			const { content, ...other } = payload
			state.journalsRevisions = content
			state.journalsRevisionsPagination = other
		} else {
			state.journalsRevisions = []
			state.journalsRevisionsPagination = {}
		}
	},
	mJournalsCancellations(state, payload) {
		if (payload) {
			const { content, totalResult, ...other } = payload
			state.journalsCancellations = content
			state.journalsCancellationsPagination = other
			state.journalsCancellationsTotals = totalResult
		} else {
			state.journalsCancellations = []
			state.journalsCancellationsPagination = {}
			state.journalsCancellationsTotals = {}
		}
	},
	mJournalsCancellationsMaterials(state, payload) {
		if (payload) {
			const { content, totalResult, ...other } = payload
			state.journalsCancellationsMaterials = content
			state.journalsCancellationsMaterialsPagination = other
			state.journalsCancellationsMaterialsTotals = totalResult
		} else {
			state.journalsCancellationsMaterials = []
			state.journalsCancellationsMaterialsPagination = {}
			state.journalsCancellationsMaterialsTotals = {}
		}
	},
	mJournalsReevaluations(state, payload) {
		if (payload) {
			const { content, ...other } = payload

			state.journalsReevaluations = content
			state.journalsReevaluationsPagination = other
		} else {
			state.journalsReevaluations = []
			state.journalsReevaluationsPagination = {}
		}
	},
	mJournalsWholesaleReevaluations(state, payload) {
		if (payload) {
			const { content, ...other } = payload

			state.journalsWholesaleReevaluations = content
			state.journalsWholesaleReevaluationsPagination = other
		} else {
			state.journalsWholesaleReevaluations = []
			state.journalsWholesaleReevaluationsPagination = {}
		}
	},
	mJournalsConfirmedSupplies(state, payload) {
		if (payload) {
			const { content, totalResult, ...other } = payload

			state.journalsConfirmedSupplies = content
			state.journalsConfirmedSuppliesTotals = totalResult
			state.journalsConfirmedSuppliesPagination = other
		} else {
			state.journalsConfirmedSupplies = []
			state.journalsConfirmedSuppliesTotals = {}
			state.journalsConfirmedSuppliesPagination = {}
		}
	},
	mJournalsSavedSupplies(state, payload) {
		if (payload) {
			const { content, totalResult, ...other } = payload

			state.journalsSavedSupplies = content
			state.journalsSavedSuppliesTotals = totalResult
			state.journalsSavedSuppliesPagination = other
		} else {
			state.journalsSavedSupplies = []
			state.journalsSavedSuppliesTotals = {}
			state.journalsSavedSuppliesPagination = {}
		}
	},
	mJournalsDiscountsStandard(state, payload) {
		if (payload) {
			const { content, ...other } = payload
			state.journalsDiscountsStandard = content
			state.journalsDiscountsStandardPagination = other
		} else {
			state.journalsDiscountsStandard = []
			state.journalsDiscountsStandardPagination = {}
		}
	},
	mJournalsDiscountsCoupons(state, payload) {
		if (payload) {
			const { content, ...other } = payload
			state.journalsDiscountsCoupons = content
			state.journalsDiscountsCouponsPagination = other
		} else {
			state.journalsDiscountsCoupons = []
			state.journalsDiscountsCouponsPagination = {}
		}
	},
	mJournalsDiscountsAutomatic(state, payload) {
		if (payload) {
			const { content, ...other } = payload
			state.journalsDiscountsAutomatic = content
			state.journalsDiscountsAutomaticPagination = other
		} else {
			state.journalsDiscountsAutomatic = []
			state.journalsDiscountsAutomaticPagination = {}
		}
	},
	mJournalsDiscountsClientsCard(state, payload) {
		if (payload) {
			const { content, ...other } = payload
			state.journalsDiscountsClientsCard = content
			state.journalsDiscountsClientsCardPagination = other
		} else {
			state.journalsDiscountsClientsCard = []
			state.journalsDiscountsClientsCardPagination = {}
		}
	},
	mJournalsDiscountsCLReplacement(state, payload) {
		if (payload) {
			const { content, ...other } = payload
			state.journalsDiscountsCLReplacement = content
			state.journalsDiscountsCLReplacementPagination = other
		} else {
			state.journalsDiscountsCLReplacement = []
			state.journalsDiscountsCLReplacementPagination = {}
		}
	},
	mJournalsDiscountsMarkdown(state, payload) {
		if (payload) {
			const { content, ...other } = payload
			state.journalsDiscountsMarkdown = content
			state.journalsDiscountsMarkdownPagination = other
		} else {
			state.journalsDiscountsMarkdown = []
			state.journalsDiscountsMarkdownPagination = {}
		}
	},
	mJournalsWorkshop(state, payload) {
		if (payload) {
			const { content, ...other } = payload
			state.journalsWorkshop = content
			state.journalsWorkshopPagination = other
		} else {
			state.journalsWorkshop = []
			state.journalsWorkshopPagination = {}
		}
	},
	mJournalsBalance(state, payload) {
		if (payload) {
			const { content, ...other } = payload
			state.journalsBalance = content
			state.journalsBalancePagination = other
		} else {
			state.journalsBalance = []
			state.journalsBalancePagination = {}
		}
	},
	mJournalsNews(state, payload) {
		if (payload) {
			const { content, ...other } = payload
			state.journalsNews = content
			state.journalsNewsPagination = other
		} else {
			state.journalsNews = []
			state.journalsNewsPagination = {}
		}
	},
	mJournalsGiftCertificates(state, payload) {
		if (payload) {
			const { content, ...other } = payload
			state.journalsGiftCertificates = content
			state.journalsGiftCertificatesPagination = other
		} else {
			state.journalsGiftCertificates = []
			state.journalsGiftCertificatesPagination = {}
		}
	},
	mJournalsGiftCertificatesByDate(state, payload) {
		if (payload) {
			const { content, ...other } = payload
			state.journalsGiftCertificatesByDate = content
			state.journalsGiftCertificatesByDatePagination = other
		} else {
			state.journalsGiftCertificatesByDate = []
			state.journalsGiftCertificatesByDatePagination = {}
		}
	},
	mJournalsCompilanceRegister(state, payload) {
		if (payload) {
			const { content, ...other } = payload
			state.journalsCompilanceRegister = content
			state.journalsCompilanceRegisterPagination = other
		} else {
			state.journalsCompilanceRegister = []
			state.journalsCompilanceRegisterPagination = {}
		}
	},
	mJournalsMailingSms(state, payload) {
		if (payload) {
			const { content, pageStatistics, totalStatistics, ...other } = payload
			state.journalsMailingSms = content
			state.journalsMailingSmsPagination = other
			state.journalsMailingSmsTotals = { pageStatistics, totalStatistics }
		} else {
			state.journalsMailingSms = []
			state.journalsMailingSmsPagination = {}
			state.journalsMailingSmsTotals = {}
		}
	},
	mJournalsMailingEmail(state, payload) {
		if (payload) {
			const { content, pageStatistics, totalStatistics, ...other } = payload
			state.journalsMailingEmail = content
			state.journalsMailingEmailPagination = other
			state.journalsMailingEmailTotals = { pageStatistics, totalStatistics }
		} else {
			state.journalsMailingEmail = []
			state.journalsMailingEmailPagination = {}
			state.journalsMailingEmailTotals = {}
		}
	},
	mJournalsMailingFailed(state, payload) {
		if (payload) {
			const { content, pageStatistics, totalStatistics, ...other } = payload
			state.journalsMailingFailed = content
			state.journalsMailingFailedPagination = other
		} else {
			state.journalsMailingFailed = []
			state.journalsMailingFailedPagination = {}
		}
	},
	mJournalsClientsFilters(state, payload) {
		if (payload) {
			const { content, pageStatistics, totalStatistics, ...other } = payload
			state.journalsClientsFilters = content
			state.journalsClientsFiltersPagination = other
		} else {
			state.journalsClientsFilters = []
			state.journalsClientsFiltersPagination = {}
		}
	},
}