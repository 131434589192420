import { mapState, mapMutations, mapActions } from 'vuex'
import isEqual from 'lodash/isEqual'
import isLocalhost from '~/utils/isLocalhost'
import { showObjectsDiff } from '~/utils/objectsDiff'
import confirmBeforeLeave from '~/plugins/router/hooks/confirmBeforeLeave'

let throttleToHandle = null
const THROTTLE_SIZE = 300

export default {
	data() {
		return {
			mxShowBottomButtons: false,
		}
	},

	computed: {
		...mapState('layout', [
			'activeTab',
			'isChanged',
		]),
		...mapState('router', [
			'from',
		]),
		mxFromFullPath() {
			return this.from?.fullPath
		},
	},

	methods: {
		...mapMutations('router', ['mCleanReturnHistoryAfterReturn']),
		...mapActions('router', ['aReturn']),

		mxLayoutParamsUpdate(layoutParams) {
			this.layoutParams = {
				...this.layoutParams,
				...layoutParams,
				hasReturnButton: typeof layoutParams.hasReturnButton === 'boolean' ? layoutParams.hasReturnButton : this.hasReturnButton,
			}

			if (this.mSetDeletedState) {
				this.mSetDeletedState(Boolean(layoutParams.isDeleted))
			}

			let { title, browserTitle } = layoutParams // eslint-disable-line
			if (!browserTitle && Array.isArray(title)) {
				title = title[1] || title[0]
			}

			this.setDocumentTitle(browserTitle || title)
		},

		// Проверяем наличие методов для отображаемых кнопок. К примеру,
		// если есть кнопка Сохранить, значит на странице должен быть метод save() и т.п.
		mxCheckupButtonsMethodsExistsOnThePage() {
			const controls = [
				...(this.$refs.topControls?.controls || []),
				...(this.$refs.bottomControls?.controls || []),
			]
			const methodsNotFound = []

			controls.forEach(btn => {
				if (btn.name && !(
					this.pageMethods[btn.name] || this.layoutParams.redefinition[btn.name]
				)) {
					methodsNotFound.push(btn)
				}
			})

			if (methodsNotFound.length) {
				const errorMessage = [
					this.$options.name,
					', methods not found on target page "',
					this.pageMethods.$options.name,
					'": [',
					methodsNotFound.join(', '),
					']',
				]
				throw new Error(errorMessage.join(''))
			}
		},
		mxCheckupButtonsMethodsExistsOnThePageDI() {
			const controls = [
				...(this.$refs.topControls?.controls || []),
				...(this.$refs.bottomControls?.controls || []),
			]
			const methodsNotFound = []

			controls.forEach(btn => {
				if (btn.name && !(
					this.componentsMethods[btn.name] || this.layoutParams.redefinition[btn.name]
				)) {
					methodsNotFound.push(btn.name)
				}
			})

			if (methodsNotFound.length) {
				const errorMessage = [
					'Methods not found on target page: ',
					'[',
					methodsNotFound.join(', '),
					']',
				]
				throw new Error(errorMessage.join(''))
			}
		},

		// ручная валидация, вызываемая перед валидацией ElementUI для проверки данных в табах
		// TODO: перенести в утилиты, чтобы использовать для любых данных без шаманизма
		// со стандартными ф-ми валидации ElementUI
		// Upd: сделана валидация в utils/validation, нужно подключить её здесь
		mxPreValidation(data, rules) {
			function validation(fieldData, rule) { // eslint-disable-line
				if (rule.required) {
					if ([undefined, '', null].includes(fieldData)) {
						return Promise.reject()
					}
					return Promise.resolve()
				}
				if (rule.max) {
					if (fieldData && fieldData.length > rule.max) {
						return Promise.reject()
					}
					return Promise.resolve()
				}
				if (rule.min) {
					if (!fieldData || fieldData.length < rule.max) {
						return Promise.reject()
					}
					return Promise.resolve()
				}
				if (rule.validator) {
					return new Promise((resolve, reject) => {
						rule.validator(rule, fieldData, result => {
							if (result) return reject()
							return resolve()
						})
					})
				}
			}

			const fields = []
			const validations = Object.entries(rules)
				.reduce((promises, [field, fieldRules]) => {
					fields.push(field)
					if (!Array.isArray(fieldRules)) {
						throw new Error(`${field}: validation rules must be array`)
					}
					fieldRules.forEach(rule => promises.push(validation(data[field], rule)))
					return promises
				}, [])

			return Promise.allSettled(validations).then(results => {
				if (results.some(r => r.status === 'rejected')) {
					if (isLocalhost) {
						results.forEach((r, i) => {
							if (r.status === 'rejected') {
								console.log('Validation error:', fields[i])
							}
						})
					}
					return Promise.reject()
				}
				return true
			})
		},

		mxConfirmIfFormChanged() {
			if (!this.layoutParams.beforeRouteLeaveParams) return true

			if (this.$store.state.isIframeHid) return true

			if (!this.isChanged) return true

			return confirmBeforeLeave.call(this, this.layoutParams.beforeRouteLeaveParams)
		},

		async mxCancelFormEdit() {
			if (!this.layoutParams.cancelConfirmationParams.message) {
				return true
			}

			const { title, message, confirmButtonText, cancelButtonText } = this.layoutParams.cancelConfirmationParams

			try {
				await this.$confirm(message, title, {
					confirmButtonText,
					cancelButtonText,
					showClose: false,
					confirmButtonClass: 'confirm-button--danger',
					cancelButtonClass: 'cancel-button--primary',
				})
				return true
			} catch (error) {
				return false
			}
		},

		mxCheckupBottomButtonsVisibility() {
			if (this.layoutParams.buttonsShowOnlyBottom) {
				this.mxShowBottomButtons = true
				return
			}
			const elPage = document.getElementById('layout-page-tabs')
				|| document.getElementById('layout-page')
				|| document.getElementById('layout-journal')
			const elTabs = document.getElementsByClassName('layout-part-tabs-wrapper')[0]
			let contentHeight = elPage?.offsetHeight
			if (elTabs) {
				contentHeight = elTabs.offsetHeight
			}
			const halfScreenSize = window.innerHeight / 2
			this.mxShowBottomButtons = this.buttons && contentHeight > halfScreenSize
		},

		mxCheckupBottomButtonsVisibilityDI() {
			if (this.layoutParams.buttonsShowOnlyBottom) {
				this.mxShowBottomButtons = true
				return
			}
			let contentHeight = this.$el.offsetHeight
			if (this.$refs.tabs) {
				contentHeight = this.$el.offsetHeight
			}
			const halfScreenSize = window.innerHeight / 2
			this.mxShowBottomButtons = this.buttons && contentHeight > halfScreenSize
		},

		async mxReturn() {
			if (this.pageMethods.return) {
				this.pageMethods.return()
				return
			}

			await this.aReturn(this.layoutParams)

			this.mLayoutDropState()
		},


		mxCheckChanges() {
			function checkChanges() {
				// Если уходить со страницы с активный курсором в любом поле, сработает focusout,
				// и на момент проверки в данных страницы будет null
				const isUserStaysOnPage = Boolean(this.gPageData)
				const dataChanged = isUserStaysOnPage && !isEqual(this.componentCtx.data, this.gPageData)
				this.mSetChangedState(dataChanged)
				throttleToHandle = null

				if (dataChanged && isLocalhost) {
					showObjectsDiff(this.componentCtx.data, this.gPageData)
				}
			}

			if (throttleToHandle) {
				clearTimeout(throttleToHandle)
			}

			throttleToHandle = setTimeout(() => checkChanges.call(this), THROTTLE_SIZE)
		},
	},
}
