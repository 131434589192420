import phoneMasking from '~/utils/phoneMasking'
import genderFromPatronymic from '~/utils/genderFromPatronymic'

import { lensesParametersConvertation } from '~/utils/formatters'


export default {
	mInformationSources(state, payload) {
		state.informationSources = payload
	},
	// From legacy
	mSetLegacyClientData(state, payload) {
		const mask = `${state.settings.phoneCode || ''} ${state.settings.phoneMask || '(###) ###-##-##'}`
		const { birthdayDay, birthdayMonth, birthdayYear, tel1, patronymicName, gender } = {
			...state.current,
			...payload,
		}

		state.legacy = {
			...state.legacy,
			...payload,
			id: payload.id || null,
			birthdayDay: String(+birthdayDay || '') || null,
			birthdayMonth: String(+birthdayMonth || '') || null,
			birthdayYear: String(+birthdayYear || '') || null,
			tel1: phoneMasking(tel1, mask),
			gender: genderFromPatronymic(patronymicName, gender),
		}
	},
	mResetLegacyData(state) {
		state.legacy = {}
	},
	mCurrent(state, payload) {
		const mask = `${state.settings.phoneCode || ''} ${state.settings.phoneMask || '(###) ###-##-##'}`

		const { tel1 } = {
			...state.current,
			...payload,
		}

		state.current = {
			...state.current,
			...payload,
			tel1: phoneMasking(tel1, mask),
		}
	},
	mClient(state, payload) {
		const mask = `${state.settings.phoneCode || ''} ${state.settings.phoneMask || '(###) ###-##-##'}`

		const { tel1 } = {
			...state.current,
			...payload,
		}

		state.client = {
			...state.current,
			...payload,
			tel1: phoneMasking(tel1, mask),
		}
	},
	mSetDefaults(state, payload) {
		state.default = {
			...state.default,
			...payload,
		}
	},
	mSetSettings(state, payload) {
		state.settings = {
			...state.settings,
			...payload,
		}
	},
	mSetPrescription(state, payload) {
		state.prescriptions = payload.prescriptions.map(lensesParametersConvertation)
		state.clPrescriptions = payload.contactLensPrescriptions.map(lensesParametersConvertation)
	},
	mSetComments(state, payload) {
		state.clientComments = payload.content.map(item => ({ ...item, id: item.id }))
	},
	mClientOrders(state, payload) {
		state.clientOrders = payload.content
	},
	mContactLenses(state, payload) {
		state.contactLenses = payload.content.map((item, id) => ({
			...item,
			contactLens: lensesParametersConvertation(item.contactLens),
			id,
		}))
	},
	mSalesPlan(state, payload) {
		const top = [...payload.usersEfficiency]
			.sort((a, b) => b.contribution - a.contribution)
			.slice(0, 3)
			.map((item, index) => ({ id: item.user.id, index: index + 1 }))

		payload.usersEfficiency = payload.usersEfficiency.map(item => {
			const placeInfo = top.find(data => data.id === item.user.id)
			return placeInfo ? { ...item, place: placeInfo.index } : item
		})
		Object.entries(payload).forEach(([key, value]) => {
			if (value === null) {
				payload[key] = 0
			}
		})
		state.salesPlan = payload
	},
	mDepartmentSalesPlans(state, payload) {
		state.departmentSalesPlans = payload
	},
	mClientInfo(state, payload) {
		if (payload.tel1 === '-') {
			payload.tel1 = '—'
		}
		if (payload.tel2 === '-') {
			payload.tel2 = '—'
		}
		if (payload.email === '-') {
			payload.email = '—'
		}
		state.clientInfo = payload
	},
	mClientCheckVisionData(state, payload) {
		state.clientCheckVisionData = payload
	},
	mClientCardCheckError(state, payload = true) {
		state.clientCardCheckError = payload
	},
	mClientPhones(state, payload) {
		state.clientPhones = payload
	},
	mClientRelations(state, payload) {
		state.clientRelations = payload
	},
}