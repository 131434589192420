export const getDefaultData = () => ({
	goodsType: '',
	priceFrom: null,
	priceTo: null,
	category: null,
	model: null,
	manufacturer: null,
	contactLensesName: null,
	wearingPeriod: '',
	radiusOfCurvature: '',
	diameter: '',
	expireOnFrom: '',
	expireOnTo: '',
	dioptresFrom: '',
	dioptresTo: '',
	cylinderFrom: '',
	cylinderTo: '',
	brand: null,
	glassesMaterial: '',
	targetGroup: '',
	type: '',
	models: [],
	material: '',
	frameType: '',
	color: '',
	lensCover: '',
	lensGeometry: '',
	refractionIndex: '',
	lensClass: '',
	technology: '',
	lensType: '',
	servicesId: [],
	lensesType: '',
})