import {
	// isNavigationFailure,
	// NavigationFailureType,
	createRouter,
	createWebHistory,
} from 'vue-router'


import { defineAsyncComponent } from 'vue'
import routesRedirects from './routes.redirects'
import routesPages from './routes.pages'
import routesJournals from './routes.journals'
import routesOrders from './routes.orders'
import routesPopups from './routes.popups'
import routesOlds from './routes.olds'

export const routes = [
	...routesRedirects,
	...routesJournals,
	...routesPages,
	...routesOrders,
	...routesOlds,
	...routesPopups,

	{
		name: 'IndexPage',
		path: '/',
		component: () => import('~/pages/IndexPage.vue'),
	},

	// Errors
	{
		name: 'Error403',
		path: '/403',
		component: defineAsyncComponent(() => import('~/pages/errors/403.vue')),
	},
	{
		name: 'Error404',
		path: '/:pathMatch(.*)*',
		component: defineAsyncComponent(() => import('~/pages/errors/404.vue')),
	},
]

/*
// var NavigationFailureType = {
//     redirected: 2,
//     aborted: 4,
//     cancelled: 8,
//     duplicated: 16
// };

function onNavigationFailure(failure) {
	if (isNavigationFailure(failure, NavigationFailureType.duplicated)
		|| isNavigationFailure(failure, NavigationFailureType.cancelled)
	) {
		// Случаются трудноуловимые и невоспроизводимые ошибки дублирования и отмены
		// из-за смешанной навигации нового фронта и легаси,
		// их бывает так много, что Сентри не вывозит, поэтому блочим их до отказа от легаси
	} else {
		throw failure
	}
}

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(onNavigationFailure)
}

const originalReplace = Router.prototype.replace
Router.prototype.replace = function replace(location) {
	return originalReplace.call(this, location).catch(onNavigationFailure)
} */

const router = createRouter({
	history: createWebHistory('/'),
	routes,
})

export default router
export const routesList = routes