import clientsAgreementsState from './clientsAgreementsState'
import clientsAgreementsActions from './clientsAgreementsActions'
import clientsAgreementsMutations from './clientsAgreementsMutations'
import clientsAgreementsGetters from './clientsAgreementsGetters'

export default {
	namespaced: true,
	state: clientsAgreementsState,
	getters: clientsAgreementsGetters,
	mutations: clientsAgreementsMutations,
	actions: clientsAgreementsActions,
}