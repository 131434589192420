import i18n from '~/plugins/i18n'
import {
	validatorTypesOptions,
	lifetimeRulesLabelMap,
	lensesCategoriesOptions,
} from '~/pages/application-settings/data/SettingsTab.options'


export default {
	gLensesTypesOptions(state) {
		return state.lensesTypes.map(item => ({
			label: i18n.global.t(item.label),
			value: item.value,
		}))
	},
	gPasswordComplexityOptions(state) {
		if (!state.validatorTypes) return []
		return state.validatorTypes.map(item => ({
			label: i18n.global.t(validatorTypesOptions[item]),
			value: item,
		}))
	},
	gChangePasswordOptions(state) {
		if (!state.lifetimeRules) return []
		return state.lifetimeRules.map(item => ({
			label: i18n.global.t(lifetimeRulesLabelMap[item]),
			value: item,
		}))
	},
	gWearingPeriodOptions(state) {
		if (!state.wearingPeriod) return []

		// Сортируем подарки с бэка:
		// 0: "2 недели"
		// 1: "1 день"
		// 2: "6-12 месяцев"
		// 3: "3-4 месяца"
		// 4: "3 месяца"
		// 5: "1 месяц"

		const weights = {
			ден: 1,
			дне: 1,
			недел: 7,
			мес: 30,
			лет: 365,
			год: 365,
		}

		function getWeight(item) {
			const key = Object.keys(weights).find(k => item.includes(k))
			return parseInt(item) * (weights[key] || 1)
		}

		const periods = state.wearingPeriod.content.map(item => ({
			label: i18n.global.t(item.name),
			value: item.name,
			weight: getWeight(item.name),
		}))

		periods.sort((a, b) => (a.weight > b.weight ? 1 : -1))

		return periods
	},
	gLensesCategoriesOptions(state) {
		if (!state.lensesCategories) return []
		return Object.keys(state.lensesCategories).map(key => ({
			label: i18n.global.t(lensesCategoriesOptions[key]),
			value: state.lensesCategories[key],
		}))
	},
}