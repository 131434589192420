import MEDICAL_POSITIONS from '~/helpers/common/MedicalPositions'

export default {
	gUsersList(state) {
		return state.users
	},
	gRoles(state) {
		return state.roles?.map(role => ({
			value: role.name,
			label: role.description,
		})) || []
	},
	gAvailableAssistantsOptions(state) {
		return (state.availableAssistants || []).map(i => ({
			value: String(i.id),
			label: i.shortName,
		}))
	},
	gUsersUserGroupsOptions(state) {
		return state.usersUserGroups.map(i => ({ value: i.id, label: i.name }))
	},
	gUsersDepartmentsOptions(state) {
		return state.usersDepartments.map(i => ({ value: i.id, label: i.name }))
	},
	gDoctorCategoriesOptions(state) {
		return state.doctorCategories.map(i => ({ value: i.name, label: i.name }))
	},
	gMedicalPositionsOptions(state) {
		return state.medicalPositions.map(i => ({ value: MEDICAL_POSITIONS[i].id, label: MEDICAL_POSITIONS[i].label }))
	},
	gUsersInDepartmentByRolesOptions(state) {
		return state.usersInDepartmentByRoles.map(i => ({ value: String(i.id), label: i.fullName }))
	},
	gUsersOptions(state) {
		return state.users.map(i => ({ value: String(i.id), label: i.fio }))
	},
	gUsersNotFiredOptions(state) {
		return state.usersNotFired.map(i => ({ value: String(i.id), label: i.fio }))
	},
	gUserGroupsOptions(state) {
		return state.userGroups.map(i => ({ value: String(i.id), label: i.name }))
	},
	gDoctorGroupId(state) {
		const doctorGroup = state.usersUserGroups
			.find(userGroup => userGroup.name === 'Офтальмологи')

		if (!doctorGroup) return null

		return doctorGroup.id
	},
	gDoctorStoreGroupId(state) {
		const doctorStoreGroup = state.usersUserGroups
			.find(userGroup => userGroup.name === 'Офтальмологи-продавцы')

		if (!doctorStoreGroup) return null

		return doctorStoreGroup.id
	},
}