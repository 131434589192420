import i18n from '~/plugins/i18n'
import VuexHttp, { ERRORS_PROCESSING_MODES } from '../vuexHttpService'
import { $settings } from '~/plugins/settings'

const vHttp = new VuexHttp('')

export default {
	async aSettings({ commit }) {
		const settings = await vHttp.GET(this, {
			url: 'settings',
			mutation: 'mSettings',
		})

		commit('client/mSetDefaults', {
			smsAccepted: settings.acceptedEmailAndSmsDistribution,
			emailAccepted: settings.acceptedEmailAndSmsDistribution,
			city: settings.defaultCity,
		}, { root: true })

		Object.entries(settings).forEach(([k, v]) => $settings[k] = v)

		return settings
	},
	async aCities() {
		const cities = await vHttp.GET(this, {
			url: 'catalog/cities',
			mutation: 'mCities',
		})
		return cities
	},
	async aTimezones({ state }) {
		const url = `timezones?locale=ru&region=${state.settings.region}`
		const timezones = await vHttp.GET(this, {
			url,
			mutation: 'mTimezones',
		})
		return timezones
	},
	async aLanguages() {
		const languages = await vHttp.GET(this, {
			url: `languages`,
			mutation: 'mLanguages',
		})
		return languages
	},

	// TODO перенести в модуль settings, объединить с applications-settings?
	async aGlassesProductionServiceVAT() {
		return vHttp.GET(this, {
			url: 'taxes/variable-cost-glasses-production-service',
			mutation: 'mGlassesProductionServiceVAT',
		})
	},
	async aSaveGlassesProductionServiceVAT(_, data) {
		return vHttp.PUT(this, {
			url: 'taxes/variable-cost-glasses-production-service',
			data,
			successMessage: i18n.global.t('Сохранено'),
			errorMessage: i18n.global.t('Ошибка'),
			errorsProcessing: ERRORS_PROCESSING_MODES.USE_MESSAGE,
		})
	},
}