export default function (sound) {
	const audioCtx = new (window.AudioContext || window.webkitAudioContext)()
	const source = audioCtx.createBufferSource()
	const request = new XMLHttpRequest()
	request.open('GET', `https://static.itigris.ru/sounds/optima/${sound}`, true)
	request.responseType = 'arraybuffer'
	request.onload = () => {
		const audioData = request.response
		audioCtx.decodeAudioData(
			audioData,
			buffer => {
				source.buffer = buffer
				source.connect(audioCtx.destination)
				source.loop = false
				source.start(0)
			},
		)
	}

	request.send()
}