<template>
	<div>
		<h3 class="mb-0 d-flex align-items-center" style="height: 49px;">
			<slot/>
		</h3>
		<hr
			color="#e1e1e1"
			size="1"
			style="margin: 0 0 15px;"
		>
	</div>
</template>

<script>
export default {
	name: 'OldPageHeader',
}
</script>