import { getIframeId } from '~/utils/iframes'
import { isIframe } from '~/utils/iframes'

export default {
	methods: {
		setDocumentTitle(pageTitle = 'ITigris Optima') {
			document.title = pageTitle
			// eslint-disable-next-line no-restricted-globals
			if (isIframe) parent.postMessage({ type: 'setDocumentTitle', data: pageTitle, iframeId: getIframeId() }, '*')
		},
	},
}