import { DEFAULT_CLIENT_FIELDS } from './client.data'

export default function () {
	return {
		default: DEFAULT_CLIENT_FIELDS,
		client: {},
		current: {},
		legacy: {}, // данные из легаси при создании клиента
		informationSources: null,
		settings: {
			phoneCode: '+7',
			hasPatronymicName: true,
			phoneMask: '(###)###-##-##',
			firstNameBeforeFamilyName: false,
		},
		prescriptions: [],
		clPrescriptions: [],
		clientOrders: [],
		contactLenses: [],
		salesPlan: {},
		departmentSalesPlans: [],
		accessJournal: [],
		accessStatistics: [],
		clientInfo: {},
		clientComments: [],
		clientCheckVisionData: null,
		clientPhones: null,
		clientRelations: null,
	}
}