import { ANONIMUS } from './client.data'

export default {
	gClient(state) {
		return {
			...state.default,
			...state.current,
		}
	},
	gDefaultClient(state) {
		return {
			...state.default,
		}
	},
	gLegacyClient(state) {
		return {
			...state.legacy,
		}
	},
	gInformationSourcesOptions(state) {
		return (state.informationSources || []).map(i => ({ value: i, label: i }))
	},
	gAnonimusClient() {
		return {
			...ANONIMUS,
		}
	},
	gClientRelationsList(state) {
		return Object.entries(state.clientRelations?.relations || {})
			.map(([label, value]) => ({
				...value,
				label,
			}))
			.sort((a, b) => {
				if (a.label.toLowerCase() < b.label.toLowerCase()) {
					return -1;
				}
				if (a.label.toLowerCase() > b.label.toLowerCase()) {
					return 1;
				}
				return 0;
			})
	},
}