export default {
	mSettings(state, payload) {
		state.settings = {
			...payload,
			/* currency: {
				code: 'RUB',
				majorName: 'рубли',
				minorName: 'копейки',
				majorReduced: 'руб',
				minorReduced: 'коп',
				reducedWithPoint: true,
				symbol: 'E',
			}, */
			// firstNameBeforeFamilyName: true,
			// inactiveTime: 0,
			// hasTurnovers: false,
			// hasBasePrice: false,
		}
	},
	mLoading(state, payload) {
		if (payload.moduleName) {
			state[payload.moduleName][payload.stateName] = payload.processing
		} else if (payload.stateName) {
			state[payload.stateName] = payload.processing
		} else {
			state.loading = payload.processing
		}
	},
	mSending(state, payload) {
		if (payload.moduleName) {
			state[payload.moduleName][payload.stateName] = payload.processing
		} else if (payload.stateName) {
			state[payload.stateName] = payload.processing
		} else {
			state.sending = payload.processing
		}
	},
	mError(state, payload) {
		state.error = payload
	},
	mPreviousPageLink(state, payload) {
		state.previousPageLink = payload
	},
	mCities(state, payload) {
		state.cities = payload
	},
	mTimezones(state, payload) {
		state.timezones = Object.entries(payload).map(([value, label]) => ({ value: +value, label }))
	},
	mLanguages(state, payload) {
		state.languages = payload
	},

	// TODO перенести в модуль settings, объединить с applications-settings?
	mGlassesProductionServiceVAT(state, payload) {
		state.glassesProductionServiceVAT = payload
	},
}