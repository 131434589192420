<template>
	<div class="layout-part-action-buttons d-flex nowrap gap-4">
		<template v-for="control in controls">
			<div v-if="control.isVisible" :key="control.name">
				<el-tooltip
					effect="light"
					:content="control.tooltip"
					:disabled="!control.tooltip"
					placement="bottom"
				>
					<el-button
						v-if="control.type === 'button'"
						:size="size"
						:type="control.btnType"
						:plain="control.plain"
						:disabled="control.isDisabled"
						:class="control.class"
						:loading="control.loading"
						:data-ta="control.name"
						@click="onClick($event, control)"
					>
						<ItIcon
							v-if="control.icon"
							:name="control.icon"
						/>
						<span v-if="control.label">{{ control.label }}</span>
					</el-button>

					<el-checkbox
						v-if="control.type === 'checkbox'"
						:disabled="control.isDisabled"
						:label="control.label"
						:checked="control.checked"
						:data-ta="control.name"
						@change="control.onChange"
					/>
				</el-tooltip>
			</div>
		</template>
	</div>
</template>

<script>
import { LAYOUT_BUTTONS_DEFAULTS } from '../data/layoutButtons'

const DEFAULT_BUTTONS = ['cancel', 'edit', 'delete', 'restore', 'save']

export default {
	name: 'LayoutPartActionButtons',
	props: {
		isEdit: {
			type: Boolean,
			default: false,
		},
		isDeleted: {
			type: Boolean,
			default: false,
		},
		isChanged: {
			type: Boolean,
			default: false,
		},
		useButtons: {
			type: [Array, Boolean, Object],
			default: () => [],
		},
		disableButtons: {
			type: Object,
			default: () => ({}),
		},
		extraButtons: {
			type: Object,
			default: () => ({}),
		},
		confirmButton: {
			type: String,
			default: 'OK',
		},
		deleteConfirmationTitle: {
			type: String,
			default: '',
		},
		deleteConfirmationText: {
			type: String,
			default: '',
		},
		size: {
			type: String,
			default: 'small',
		},
	},
	computed: {
		controls() {
			const controls = this.useButtons === true ? DEFAULT_BUTTONS : (this.useButtons || [])

			if (!controls.map) return []

			return controls.map(cntrl => {
				const cntrlName = cntrl.name || cntrl
				let control = LAYOUT_BUTTONS_DEFAULTS()[cntrlName] || this.extraButtons[cntrlName] || cntrl

				if (typeof cntrl === 'object') {
					control = {
						...control,
						...cntrl,
					}
				}

				control.isDisabled = this.disableButtons[control.name]

				switch (control.name) {
				case 'save':
					control.btnType = this.isChanged ? 'primary' : ''
					control.isVisible = !this.isDeleted
					break
				case 'restore':
					control.isVisible = this.isDeleted
					break
				case 'cancel':
					control.isVisible = this.isEdit
					break
				case 'delete':
					control.isVisible = !this.isDeleted
					break
				case 'edit':
					control.isVisible = !this.isDeleted
					break
				default:
					break
				}

				return control
			})
		},
	},
	methods: {
		tooltipDisable(event) {
			let { target } = event
			while (target && target.tagName !== 'BUTTON') {
				target = target.parentNode
			}
			target && target.blur() // eslint-disable-line
		},
		async emit(e, { event, onClick }) {
			this.tooltipDisable(e)

			if (event === 'on-delete' && this.deleteConfirmationText) {
				try {
					await this.$confirm(this.deleteConfirmationText, this.deleteConfirmationTitle, {
						confirmButtonText: this.confirmButton,
						showClose: false,
						cancelButtonClass: 'cancel-button--primary',
						confirmButtonClass: 'confirm-button--danger',
						cancelButtonText: this.$t('Отмена'),
						type: 'warning',
					})
					this.$emit(event)
				} catch (err) {
					//
				}
			} else if (event) {
				this.$emit(event)
			} else if (onClick) {
				onClick()
			}
		},

		onClick(event, control) {
			if (control?.onClick) {
				control.onClick(event)
			} else {
				this.emit(event, control)
			}
		},
	},
}
</script>

<style lang="scss">
.layout-part-action-buttons {
	.el-button--default {
		height: 36px;
		font-size: 14px;
		min-width: 36px;
	}

	.el-button .it-icon {
		width: 20px;
		height: 20px;
	}
}
</style>