<template>
	<router-link
		v-if="hasFrom || defaultTo || forceTo"
		:to="to"
		class="mx-2"
		data-ta="returnButton"
	>
		<slot v-if="$slots.default"/>

		<ItIcon v-else name="arrow-left"/>
	</router-link>
</template>

<script>
import { mapState } from 'vuex'

export default {
	name: 'ReturnButton',
	props: {
		defaultTo: {
			type: Object,
			required: false,
			default: null,
		},
		forceTo: {
			type: Object,
			required: false,
			default: null,
		},
	},
	computed: {
		...mapState('router', [
			'hasFrom',
			'from',
		]),
		to() {
			if (this.forceTo === null) return this.hasFrom ? this.from : this.defaultTo
			// !this.hasFrom, сразу отправляем на forceTo
			// Иначе, если компоненты совпадают, то возвращаем from, т.к. он может содержать нужные query/params
			if (!this.hasFrom || this.from.name !== this.forceTo.name) return this.forceTo
			return this.from
		},
	},
	methods: {
		goBack() {
			this.$router.push(this.to)
		},
	},
}
</script>