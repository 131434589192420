import compilanceRegisterActions from './compilanceRegisterActions'
import compilanceRegisterGetters from './compilanceRegisterGetters'
import compilanceRegisterMutations from './compilanceRegisterMutations'
import compilanceRegisterState from './compilanceRegisterState'

export default {
	namespaced: true,
	state: compilanceRegisterState,
	getters: compilanceRegisterGetters,
	mutations: compilanceRegisterMutations,
	actions: compilanceRegisterActions,
}