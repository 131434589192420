import store from '~/plugins/store'
import router from '~/plugins/router'
import { getIframeContentHeight } from './iframesContentHeight'
import { getIframeId, isMainIframe } from '../iframes'
import { enableScroll, disableScroll } from '../scrollDisable'

const legacy = window.parent

// window.addEventListener('message', e => console.log('message', e.data.type, e.data.data))

export const declareOldFrontMethods = function () {
	window.orderF = {
		showPopup(orderId, withPrint) {
			legacy.postMessage({
				type: 'triggerOldFrontMethod',
				data: {
					constructorName: 'orderF',
					methodName: 'showPopup',
					args: [orderId, withPrint],
				},
				iframeId: getIframeId(),
			}, '*')
		},
	}

	window.commonF = {
		openMenuElementLegacy(...url) {
			legacy.postMessage({
				type: 'triggerOldFrontMethod',
				data: {
					constructorName: 'commonF',
					methodName: 'openMenuElementLegacy',
					args: url,
				},
				iframeId: getIframeId(),
			}, '*')
		},
		openMenuElementLegacyBackground(...url) {
			window.commonF.openMenuElementLegacy(...url, 'hiddenLayoutBody')
		},
	}

	window.discountUtilF = {
		editDiscount(id, type) {
			legacy.postMessage({
				type: 'triggerOldFrontMethod',
				data: {
					constructorName: 'discountUtilF',
					methodName: 'editDiscount',
					args: [id, type],
				},
				iframeId: getIframeId(),
			}, '*')
		},
	}

	window.discountF = {
		createDiscount(type, isCoupon) {
			legacy.postMessage({
				type: 'triggerOldFrontMethod',
				data: {
					constructorName: 'discountF',
					methodName: 'createDiscount',
					args: [type, isCoupon],
				},
				iframeId: getIframeId(),
			}, '*')
		},
		showAutoDiscount(id) {
			legacy.postMessage({
				type: 'triggerOldFrontMethod',
				data: {
					constructorName: 'discountF',
					methodName: 'showAutoDiscount',
					args: [id],
				},
				iframeId: getIframeId(),
			}, '*')
		},
		copyDiscount(id, type) {
			legacy.postMessage({
				type: 'triggerOldFrontMethod',
				data: {
					constructorName: 'discountF',
					methodName: 'copyDiscount',
					args: [id, type],
				},
				iframeId: getIframeId(),
			}, '*')
		},
	}

	window.loadLinkSimple = (...url) => {
		legacy.postMessage({
			type: 'triggerOldFrontMethod',
			data: {
				constructorName: 'window',
				methodName: 'loadLinkSimple',
				args: url,
			},
			iframeId: getIframeId(),
		}, '*')
	}

	window.loadLinkSimpleLegacyBackground = async (...url) => {
		legacy.postMessage({
			type: 'triggerOldFrontMethod',
			data: {
				constructorName: 'window',
				methodName: 'loadLinkSimpleLegacy',
				args: [...url, 'hiddenLayoutBody'],
			},
			iframeId: getIframeId(),
		}, '*')
	}

	window.loadLinkSimpleLegacy = async (...url) => {
		try {
			if (isMainIframe()) {
				await router.push('/')
			}
			legacy.postMessage({
				type: 'triggerOldFrontMethod',
				data: {
					constructorName: 'window',
					methodName: 'loadLinkSimpleLegacy',
					args: url,
				},
				iframeId: getIframeId(),
			}, '*')
		} catch (e) {
			//
		}
	}

	window.showOrderPopup = (...url) => {
		legacy.postMessage({
			type: 'triggerOldFrontMethod',
			data: {
				constructorName: 'popupF',
				methodName: 'showOrder',
				args: url,
			},
			iframeId: getIframeId(),
		}, '*')
	}

	window.loadFormSimpleLegacy = async (...url) => {
		try {
			if (isMainIframe()) {
				await router.push('/')
			}
			legacy.postMessage({
				type: 'triggerOldFrontMethod',
				data: {
					constructorName: 'window',
					methodName: 'loadFormSimpleLegacy',
					args: url,
				},
				iframeId: getIframeId(),
			}, '*')
		} catch (e) {
			//
		}
	}

	window.loadLinkWithParamsSimpleLegacy = (url, params) => {
		legacy.postMessage({
			type: 'triggerOldFrontMethod',
			data: {
				constructorName: 'window',
				methodName: 'loadLinkWithParamsSimple',
				args: [url, params],
			},
			iframeId: getIframeId(),
		}, '*')
	}

	window.loadLinkWithoutDataConfirm = (...url) => {
		legacy.postMessage({
			type: 'triggerOldFrontMethod',
			data: {
				constructorName: 'window',
				methodName: 'loadLinkWithoutDataConfirm',
				args: url,
			},
			iframeId: getIframeId(),
		}, '*')
	}

	window.loginFNewTab = (...url) => {
		legacy.postMessage({
			type: 'triggerOldFrontMethod',
			data: {
				constructorName: 'loginF',
				methodName: 'newTab',
				args: url,
			},
			iframeId: getIframeId(),
		}, '*')
	}

	window.userPincode = (...url) => {
		legacy.postMessage({
			type: 'triggerOldFrontMethod',
			data: {
				constructorName: 'userPincode',
				methodName: 'iframePincode',
				args: url,
			},
			iframeId: getIframeId(),
		}, '*')
	}

	window.userChange = (...url) => {
		legacy.postMessage({
			type: 'triggerOldFrontMethod',
			data: {
				constructorName: 'user',
				methodName: 'updateCurrentSessionUser',
				args: url,
			},
			iframeId: getIframeId(),
		}, '*')
	}

	window.showAgreementPopup = (...url) => {
		legacy.postMessage({
			type: 'triggerOldFrontMethod',
			data: {
				constructorName: 'client',
				methodName: 'showAgreementPopup',
				args: url,
			},
			iframeId: getIframeId(),
		}, '*')
	}

	window.sendIframeRedirect = (...url) => {
		legacy.postMessage({
			type: 'triggerOldFrontMethod',
			data: {
				constructorName: 'iframeDataTransport',
				methodName: 'sendMessageById',
				args: url,
			},
			iframeId: getIframeId(),
		}, '*')
	}

	window.userInfo = (...url) => {
		legacy.postMessage({
			type: 'triggerOldFrontMethod',
			data: {
				constructorName: 'client',
				methodName: 'showPopup',
				args: url,
			},
			iframeId: getIframeId(),
		}, '*')
	}

	window.closeLegacyPopup = id => {
		const iframeId = id || getIframeId()
		legacy.postMessage({
			type: 'triggerOldFrontMethod',
			data: {
				constructorName: 'client',
				methodName: 'closePopup',
				args: ['vuePopups', iframeId],
			},
			iframeId,
		}, '*')
	}

	window.openClientHistoryWindow = clientId => {
		legacy.postMessage({
			type: 'triggerOldFrontMethod',
			data: {
				constructorName: 'loginF',
				methodName: 'newTab',
				args: [localStorage.getItem('user/company'), clientId],
			},
			iframeId: getIframeId(),
		}, '*')
	}

	window.iframesBroadcast = (...url) => {
		legacy.postMessage({
			type: 'triggerOldFrontMethod',
			data: {
				constructorName: 'iframeDataTransport',
				methodName: 'broadcastMessage',
				args: url,
			},
			iframeId: getIframeId(),
		}, '*')
	}

	window.triggerOldFrontMethod = (constructorName, methodName, ...url) => {
		legacy.postMessage({
			type: 'triggerOldFrontMethod',
			data: {
				constructorName,
				methodName,
				args: url,
			},
			iframeId: getIframeId(),
		}, '*')
	}

	window.hideLegacyPopups = () => {
		legacy.postMessage({
			type: 'hidePopups',
			iframeId: getIframeId(),
		}, '*')
	}

	window.mainIframeShow = () => {
		legacy.postMessage({
			type: 'mainIframeShow',
			iframeId: getIframeId(),
		}, '*')
	}

	window.mainIframeHide = () => {
		legacy.postMessage({
			type: 'mainIframeHide',
			iframeId: getIframeId(),
		}, '*')
	}

	window.mainIframeDrawerShown = () => {
		legacy.postMessage({
			type: 'mainIframeDrawerShown',
			iframeId: getIframeId(),
		}, '*')
	}

	window.mainIframeDrawerHid = () => {
		legacy.postMessage({
			type: 'mainIframeDrawerHid',
			iframeId: getIframeId(),
		}, '*')
	}

	window.holdOnLegacySessionRequest = () => {
		legacy.postMessage({
			type: 'holdOnLegacySessionRequest',
			iframeId: getIframeId(),
		}, '*')
	}

	window.onIframeAppMounted = () => {
		legacy.postMessage({
			type: 'iframeAppMounted',
		}, '*')
	}

	window.onWindowHeightChanged = height => {
		legacy.postMessage({
			type: 'windowHeightChanged',
			data: `${(height || getIframeContentHeight())}px`,
			iframeId: getIframeId(),
		}, '*')
	}

	window.setLegacyPopupWidth = width => {
		legacy.postMessage({
			type: 'contentWidthChanged',
			data: width,
			iframeId: getIframeId(),
		}, '*')
	}

	window.scrollToLegacy = ({ x, y }) => {
		legacy.postMessage({
			type: 'triggerOldFrontMethod',
			data: {
				constructorName: 'window',
				methodName: 'scrollTo',
				args: [x, y],
			},
			iframeId: getIframeId(),
		}, '*')
	}
}