function defaultState() {
	return {
		iframeId: '',
		boundingClientRect: {},
	}
}
// TODO сейчас используется в миксине из $isFormValid frontend-kit
// TODO для валидации форм и скролла к первой ошибке
// TODO надо бы как-то исправить это, сделав валидацию отдельно, скролл отдельно
// TODO и убрав привязку к стору запускаемого iframe
export default {
	namespaced: true,
	state: defaultState(),
	mutations: {
		setIframeId(state, iframeId) {
			state.iframeId = iframeId
		},
		setBoundingClientRect(state, boundingClientRect) {
			state.boundingClientRect = boundingClientRect
		},
	},
	actions: {
		/* eslint-disable no-restricted-globals */
		postMessageGetIframeBoundingClientRect({ state }) {
			parent.postMessage({ type: 'getIframeBoundingClientRect', iframeId: state.iframeId }, '*')
		},
		postMessageScrollBy({ state }, { x, y }) {
			parent.postMessage({
				type: 'triggerOldFrontMethod',
				data: {
					constructorName: 'window',
					methodName: 'scrollBy',
					args: [x, y],
				},
				iframeId: state.iframeId,
			}, '*')
		},
	},
}