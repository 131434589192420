export default () => ({
	id: null,
	name: null,
	author: {
		id: null,
		label: null,
	},
	clientParameters: {
		id: null,
		firstName: null,
		familyName: null,
		gender: null,
		ageFrom: null,
		ageTo: null,
		birthdayFrom: null,
		birthdayTo: null,
		birthdayInDays: null,
		hasOrder: null,
		orderSumFrom: null,
		orderSumTo: null,
		hasCard: null,
		cardSumFrom: null,
		cardSumTo: null,
		assistant: null,
		lastCallFrom: null,
		lastCallTo: null,
		lastSmsFrom: null,
		lastSmsTo: null,
		lastEmailFrom: null,
		lastEmailTo: null,
		lastPrivateSmsFrom: null,
		lastPrivateSmsTo: null,
		daysToOffer: null,
		cardEditedDays: null,
		mobile: null,
		daysToExpiration: null,
		cities: [],
	},
	notMadeOrderParameters: {
		id: null,
		lastOrderDaysAgoFrom: null,
		lastOrderDaysAgoTo: null,
		status: 'CREATED',
		sumFrom: null,
		sumTo: null,
		isClientPrescription: null,
		goodTypes: [],
		orderTypes: [],
		departments: [],
	},
	madeOrderParameters: {
		id: null,
		lastOrderDaysAgoFrom: null,
		lastOrderDaysAgoTo: null,
		status: 'CREATED',
		sumFrom: null,
		sumTo: null,
		isClientPrescription: null,
		goodTypes: [],
		orderTypes: [],
		departments: [],
	},
	notSoldGoodParameters: {
		id: null,
		lastOrderDaysAgoFrom: null,
		lastOrderDaysAgoTo: null,
		type: null,
		isReturned: null,
		orderTypes: [],
	},
	soldGoodParameters: {
		id: null,
		lastOrderDaysAgoFrom: null,
		lastOrderDaysAgoTo: null,
		type: null,
		isReturned: null,
		orderTypes: [],
	},
	notMadePrescriptionParameters: {
		id: null,
		purpose: null,
		doctor: null,
		lastOrderDaysAgo: null,
		type: null,
		inUse: null,
		dioptresFrom: null,
		dioptresTo: null,
		cylinders: null,
		addidations: null,
	},
	madePrescriptionParameters: {
		id: null,
		purpose: null,
		doctor: null,
		lastOrderDaysAgo: null,
		type: null,
		inUse: null,
		dioptresFrom: null,
		dioptresTo: null,
		cylinders: [],
		addidations: [],
	},
	createdAt: null,
	deleted: false,
})