import { createStore } from 'vuex'
import { isIframe } from '~/utils/iframes'
import shareStateService from './shareStateService'
import root from './root'
import modules from './modules'

const isDevelopment = import.meta.env.VITE_MODE === 'development'
const plugins = []

if (isIframe) {
	// плагин для хуков передачи данных между айфрэймами
	plugins.push(shareStateService)
}

const store = createStore({
	// https://vuex.vuejs.org/ru/guide/strict.html
	strict: isDevelopment,
	plugins,
	modules,
	...root,
})

export default store