import i18n from '~/plugins/i18n'

// Переводы в departments.xlsx

export default [{
	name: 'CONTACT_LENS',
	label: i18n.global.t('Контактные линзы'),
	type: 'select',
}, {
	name: 'LENS',
	label: i18n.global.t('Линзы'),
	type: 'select',
}, {
	name: 'GLASSES',
	label: i18n.global.t('Оправы'),
	type: 'select',
}, {
	name: 'EXTRA',
	label: i18n.global.t('Массовка'),
	type: 'select',
}, {
	name: 'ACCESSORY',
	label: i18n.global.t('Аксессуары'),
	type: 'select',
}, {
	name: 'SUNGLASSES',
	label: i18n.global.t('Солнцезащитные очки'),
	type: 'select',
}, {
	name: 'GIFT_CERTIFICATE',
	label: i18n.global.t('Подарочные сертификаты'),
	type: 'select',
}, {
	name: 'SERVE',
	label: i18n.global.t('Услуги'),
	type: 'select',
}]