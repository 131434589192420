import axios from 'axios'

let httpInstance = null
let VITE_BASE_URL = 'https://optima-1.stage.itigris.ru/api/v1'
let VITE_BASE2_URL = 'https://optima-2-backend-1.stage.itigris.ru/api/v2'

if (import.meta.env.VITE_MODE === 'development' && import.meta.env.NODE_ENV !== 'production') {
	VITE_BASE_URL = import.meta.env.VITE_LOCAL_API_V1_ORIGIN + import.meta.env.VITE_LOCAL_API_V1_PATH
	VITE_BASE2_URL = import.meta.env.VITE_LOCAL_API_V2_ORIGIN + import.meta.env.VITE_LOCAL_API_V2_PATH
}

const instanceName = import.meta.env.VITE_BASE_INSTANCE_NAME || 'optima'
const instanceName2 = import.meta.env.VITE_BASE2_INSTANCE_NAME || 'optima2'

httpInstance = {
	[instanceName]: axios.create({
		baseURL: VITE_BASE_URL,
	}),
	[instanceName2]: axios.create({
		baseURL: VITE_BASE2_URL,
	}),
}

export const http = httpInstance

// export as vue plugin
export default {
	install(app, { httpConfig = {} } = {}) {
		if (httpConfig.withUrlSearchParams) {
			httpInstance.defaults.paramsSerializer = function (params) {
				const cleanedParams = Object.keys(params).reduce((acc, key) => {
					if (params[key] === null || typeof params[key] === 'undefined') return acc
					acc[key] = params[key]
					return acc
				}, {})
				return new URLSearchParams(cleanedParams).toString()
			}
		}

		app.config.globalProperties.$http = httpInstance
	},
}