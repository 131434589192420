export default function () {
	return {
		usersProduction: [],
		departmentsStores: [],
		departmentsProductions: [],
		departmentsList: [],
		ordersStatuses: [],
		extrasOrderStatuses: [],
		ordersTypesWithExtraTypes: [],
		users: [],
		prescriptionsBases: [],
		workshopOrder: {},
		workshopOrderLoading: false,
	}
}