<!-- eslint-disable vue/no-mutating-props -->
<template>
	<div
		class="com-form-fields"
		:class="formClasses"
	>
		<div
			v-for="(column, idx) in fieldsByColumns"
			:key="idx"
			class="com-form-fields__column"
			:class="columnsClasses[idx]"
		>
			<template
				v-for="(field, idx2) in column"
				:key="`field-${idx2}`"
			>
				<div
					v-if="!field.hidden"
					class="com-form-fields__item-wrap"
					:class="field.classes"
				>
					<el-divider
						v-if="field.type === 'divider'"
						v-bind="field"
					>
						<span v-if="field.label" class="bold">{{ field.label }}</span>
					</el-divider>
					<ComFieldDadata
						v-else-if="field.type === 'dadata'"
						v-bind="field"
						v-model="data[field.name]"
						:city="data[field.cityFieldName]"
						:is-edit="isEdit"
						:readonly="readonly"
						@update:modelValue="value => onChange(field, value)"
					/>
					<ComFieldPhone
						v-else-if="field.type === 'phone'"
						v-bind="field"
						v-model="data[field.name]"
						:is-edit="isEdit"
						:readonly="readonly"
						@update:modelValue="value => onChange(field, value)"
					/>
					<ComFieldPhonePopover
						v-else-if="field.type === 'phonePopover'"
						v-bind="field"
						v-model="data[field.name]"
						:is-edit="isEdit"
						:readonly="readonly"
						@update:modelValue="value => onChange(field, value)"
					/>
					<ComFieldSelect
						v-else-if="field.type === 'select'"
						v-bind="field"
						v-model="data[field.name]"
						:is-edit="isEdit"
						:readonly="readonly"
						@update:modelValue="value => onChange(field, value)"
					/>
					<ComFieldRadio
						v-else-if="field.type === 'radio'"
						v-bind="field"
						v-model="data[field.name]"
						:is-edit="isEdit"
						:readonly="readonly"
						@update:modelValue="value => onChange(field, value)"
					/>
					<ComFieldRadioButton
						v-else-if="field.type === 'radioButton'"
						v-bind="field"
						v-model="data[field.name]"
						:is-edit="isEdit"
						:readonly="readonly"
						@update:modelValue="value => onChange(field, value)"
					/>
					<ComFieldButtonsGroup
						v-else-if="field.type === 'buttonsGroup'"
						v-bind="field"
						v-model="data[field.name]"
						:is-edit="isEdit"
						:readonly="readonly"
						@update:modelValue="value => onChange(field, value)"
					/>
					<ComFieldDate
						v-else-if="field.type === 'date'"
						v-bind="field"
						v-model="data[field.name]"
						:is-edit="isEdit"
						:readonly="readonly"
						@update:modelValue="value => onChange(field, value)"
					/>
					<ComFieldPeriod
						v-else-if="field.type === 'period'"
						v-bind="field"
						v-model="data[field.name]"
						:is-edit="isEdit"
						:readonly="readonly"
						@update:modelValue="value => onChange(field, value)"
					/>
					<ComFieldDateSeparated
						v-else-if="field.type === 'dateSeparated'"
						v-bind="field"
						v-model="data[field.name]"
						:is-edit="isEdit"
						:readonly="readonly"
						@update:modelValue="value => onChange(field, value)"
					/>
					<ComFieldCheckboxes
						v-else-if="field.type === 'checkboxes'"
						v-bind="field"
						:values="data"
						:is-edit="isEdit"
						:readonly="readonly"
						@on-checkbox-change="onCheckboxesChange"
					/>
					<ComFieldCheckbox
						v-else-if="field.type === 'checkbox'"
						v-bind="field"
						v-model="data[field.name]"
						:is-edit="isEdit"
						:readonly="readonly"
						@update:modelValue="value => onChange(field, value)"
					/>
					<ComFieldSwitch
						v-else-if="field.type === 'switch'"
						v-bind="field"
						v-model="data[field.name]"
						:is-edit="isEdit"
						:readonly="readonly"
						@update:modelValue="value => onChange(field, value)"
					/>
					<ComFieldInputNumber
						v-else-if="field.type === 'inputNumber'"
						v-bind="field"
						v-model="data[field.name]"
						:is-edit="isEdit"
						:readonly="readonly"
						@update:modelValue="value => onChange(field, value)"
					/>
					<ComFieldCurrency
						v-else-if="field.type === 'inputCurrency'"
						v-bind="field"
						v-model="data[field.name]"
						:is-edit="isEdit"
						:readonly="readonly"
						@update:modelValue="onInput(field.name, ...arguments)"
					/>
					<ComFieldTextarea
						v-else-if="field.type === 'textarea'"
						v-bind="field"
						v-model="data[field.name]"
						:is-edit="isEdit"
						:readonly="readonly"
						@update:modelValue="value => onChange(field, value)"
					/>
					<ComFieldInputPopover
						v-else-if="field.type === 'inputPopover'"
						v-bind="field"
						v-model="data[field.name]"
						:is-edit="isEdit"
						:readonly="readonly"
						@update:modelValue="value => onChange(field, value)"
					/>
					<ComFieldRange
						v-else-if="field.type === 'range'"
						v-bind="field"
						v-model:modelFrom="data[field.name]"
						v-model:modelTo="data[field.nameTo]"
						:is-edit="isEdit"
						:readonly="readonly"
						@update:modelFrom="value => onChange(field, value)"
						@update:modelTo="value => onChange({ name: field.nameTo }, value)"
					/>
					<ComFieldRangeDates
						v-else-if="field.type === 'rangeDates'"
						v-bind="field"
						v-model:modelFrom="data[field.name]"
						v-model:modelTo="data[field.nameTo]"
						:is-edit="isEdit"
						:readonly="readonly"
						@update:modelFrom="value => onChange(field, value)"
						@update:modelTo="value => onChange({ name: field.nameTo }, value)"
					/>
					<ComFieldRangeSelects
						v-else-if="field.type === 'rangeSelects'"
						v-bind="field"
						v-model:modelFrom="data[field.name]"
						v-model:modelTo="data[field.nameTo]"
						:is-edit="isEdit"
						:readonly="readonly"
						@update:modelFrom="value => onChange(field, value)"
						@update:modelTo="value => onChange({ name: field.nameTo }, value)"
					/>
					<ComFieldRangeCurrency
						v-else-if="field.type === 'rangeCurrency'"
						v-bind="field"
						v-model:modelFrom="data[field.name]"
						v-model:modelTo="data[field.nameTo]"
						:is-edit="isEdit"
						:readonly="readonly"
						@update:modelFrom="value => onChange(field, value)"
						@update:modelTo="value => onChange({ name: field.nameTo }, value)"
					/>
					<ComFieldInput
						v-else
						v-bind="field"
						v-model="data[field.name]"
						:is-edit="isEdit"
						:readonly="readonly"
						@update:modelValue="value => onChange(field, value)"
					/>
					<div
						v-if="field.actions && isEdit"
						:key="`column-${field.name}`"
						class="com-form-fields__actions-wrap"
					>
						<el-tooltip
							v-for="action of field.actions"
							:key="`${field.name}-${action.action}`"
							effect="light"
							:content="action.tooltip"
							placement="bottom-start"
						>
							<ItIcon
								:name="action.icon"
								width="18"
								height="18"
								class="com-form-fields__action-icon mb-3"
								:class="'fill-' + (action.color || 'info')"
								style="cursor: pointer"
								data-ta="field-action-icon"
								@click="(action.fn || $noop)(field)"
							/>
						</el-tooltip>
					</div>
				</div>
			</template>
		</div>
		<slot/>
	</div>
</template>

<script>
import ComFieldInput from '~/components/ComFields/ComFieldInput'
import ComFieldInputPopover from '~/components/ComFields/ComFieldInputPopover'
import ComFieldInputNumber from '~/components/ComFields/ComFieldInputNumber'
import ComFieldSelect from '~/components/ComFields/ComFieldSelect'
import ComFieldPhone from '~/components/ComFields/ComFieldPhone'
import ComFieldPhonePopover from '~/components/ComFields/ComFieldPhonePopover'
import ComFieldDadata from '~/components/ComFields/ComFieldDadata'
import ComFieldDate from '~/components/ComFields/ComFieldDate'
import ComFieldPeriod from '~/components/ComFields/ComFieldPeriod'
import ComFieldDateSeparated from '~/components/ComFields/ComFieldDateSeparated'
import ComFieldRadio from '~/components/ComFields/ComFieldRadio'
import ComFieldRadioButton from '~/components/ComFields/ComFieldRadioButton'
import ComFieldCheckboxes from '~/components/ComFields/ComFieldCheckboxes'
import ComFieldCheckbox from '~/components/ComFields/ComFieldCheckbox'
import ComFieldTextarea from '~/components/ComFields/ComFieldTextarea'
import ComFieldButtonsGroup from '~/components/ComFields/ComFieldButtonsGroup.vue'
import ComFieldCurrency from '~/components/ComFields/ComFieldCurrency'
import ComFieldRange from '~/components/ComFields/ComFieldRange'
import ComFieldRangeDates from '~/components/ComFields/ComFieldRangeDates'
import ComFieldRangeSelects from '~/components/ComFields/ComFieldRangeSelects'
import ComFieldRangeCurrency from '~/components/ComFields/ComFieldRangeCurrency'
import ComFieldSwitch from '~/components/ComFields/ComFieldSwitch'

export default {
	name: 'ComFormFields',
	components: {
		ComFieldInput,
		ComFieldInputPopover,
		ComFieldInputNumber,
		ComFieldSelect,
		ComFieldPhone,
		ComFieldPhonePopover,
		ComFieldRadio,
		ComFieldRadioButton,
		ComFieldCheckboxes,
		ComFieldCheckbox,
		ComFieldDadata,
		ComFieldDate,
		ComFieldDateSeparated,
		ComFieldPeriod,
		ComFieldTextarea,
		ComFieldButtonsGroup,
		ComFieldCurrency,
		ComFieldRange,
		ComFieldRangeDates,
		ComFieldRangeSelects,
		ComFieldRangeCurrency,
		ComFieldSwitch,
	},
	props: {
		isEdit: {
			type: Boolean,
			default: false,
		},
		isView: {
			type: Boolean,
			default: false,
		},
		readonly: {
			type: Boolean,
			default: false,
		},
		columns: {
			type: [Number, String],
			default: 2,
		},
		gridColumns: {
			type: Number,
			default: null,
		},
		rows: {
			type: Boolean,
			default: false,
		},
		labelPosition: {
			type: String,
			default: 'left',
		},
		labelWidth: {
			type: String,
			default: '40%',
		},
		itemsCss: {
			type: Object,
			default: null,
		},
		size: {
			type: String,
			default: '',
		},
		fields: {
			type: Array,
			default: () => ([]),
		},
		data: {
			type: Object,
			default: () => ({}),
		},
		rules: {
			type: Object,
			default: () => ({}),
		},
	},
	computed: {
		formClasses() {
			const classes = []
			if (this.rows && this.gridColumns) {
				classes.push('com-form-fields--rows')
			}
			if (this.labelPosition) {
				classes.push(`com-form-fields--label-position-${this.labelPosition}`)
			}
			return classes.join(' ')
		},
		columnsClasses() {
			const columns = {}
			Object.entries(this.fieldsByColumns).forEach(([column, fields]) => {
				columns[column] = ``
				if (this.gridColumns) {
					columns[column] = `grid grid-cols-${this.gridColumns} grid-column`

					if (this.rows) {
						fields.forEach((field, idx) => {
							field.classes = field.classes ? `${field.classes} ` : ''
							field.classes += `row `
							if (idx % this.gridColumns === 0) {
								field.classes += `row-start`
							}
							if (idx % this.gridColumns === this.gridColumns - 1) {
								field.classes += `row-end`
							}
						})
					}
				}

				if (fields.find(item => item.actions)) {
					columns[column] += ' com-form-fields__column--actions'
				}
			})
			return columns
		},
		fieldsByColumns() {
			const columnsAmount = Number(this.columns)
			if (columnsAmount <= 1) {
				return [this.fields.map(this.setParamsToFieldObject)]
			}

			const fields = [...this.fields]
			const columns = []
			const columnItemsAmount = Math.ceil(this.fields.length / columnsAmount)
			const columnsMapLength = {}

			// раскидываем по колонкам поля с установленным параметром column
			for (let i = fields.length - 1; i >= 0; i--) {
				const { column } = fields[i]
				if (typeof column === 'number') {
					columns[column] = columns[column] || []
					columns[column].push(this.setParamsToFieldObject(fields.splice(i, 1)[0]))
					columnsMapLength[column] = (columnsMapLength[column] || 0) + 1
				}
			}

			// заполняем колонки оставшимися полями
			for (let c = 0; c < columnsAmount; c++) {
				columns[c] = columns[c]?.reverse() || []
				if (fields.length) {
					const additionalFields = fields.splice(0, columnItemsAmount - (columnsMapLength[c] || 0))
					columns[c].push(...additionalFields.map(this.setParamsToFieldObject))
				}
			}
			return columns
		},
	},
	methods: {
		setParamsToFieldObject(field) {
			const rules = this.rules[field.name]
			const classes = [field.classes]

			if (this.gridColumns) {
				classes.push(`col-span-${field.cols || field.gridCols || 1}`)
			}

			if (field.type === 'divider' && !field.label) {
				classes.push(`empty-divider-wrap`)
			}

			return {
				rules,
				...field,
				size: this.size,
				labelWidth: this.labelWidth,
				classes: classes.join(' ').trim() || null,
				css: {
					...this.itemsCss,
					...field.css,
				},
			}
		},
		onCheckboxesChange(key, value) {
			this.onChange({ name: key }, value)
		},
		onChange({ name }, value) {
			this.$emit('change', {
				key: name,
				value,
			})
			this.$emit('update:modelValue', {
				key: name,
				value,
			})
		},
	},
}
</script>

<style lang="scss">
.com-form-fields {
	display: grid;
	max-width: 100%;
	grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
	grid-gap: 48px;

	&--label-position-left {
		.el-form-item {
			display: flex;
		}
	}

	&--label-position-top {
		.el-form-item {
			display: block;
		}
	}

	.empty-divider-wrap {
		margin: -16px 0;
	}

	.grid-column {
		grid-gap: 1rem;
	}
}

.com-form-fields--rows {
	.grid-column {
		grid-gap: 0;
		grid-row-gap: 8px;
	}

	.row {
		display: flex;
		align-items: center;
		min-height: 48px;
		border-top: var(--el-border-base);
		border-bottom: var(--el-border-base);
		border-radius: 0px;
		padding-left: 8px;
	}

	.row-start {
		border-left: var(--el-border-base);
		border-top-left-radius: var(--el-border-radius-base);
		border-bottom-left-radius: var(--el-border-radius-base);
	}

	.row-end,
	.row:last-child {
		border-right: var(--el-border-base);
		border-top-right-radius: var(--el-border-radius-base);
		border-bottom-right-radius: var(--el-border-radius-base);
		padding-right: 0;
	}

	.el-form-item {
		margin-bottom: 0;
		margin: 4px;
	}
}

.com-form-fields__actions-wrap {
	flex-shrink: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 30px;

	.com-form-fields__actions-wrap-btn {
		margin-left: 12px;
	}
}

.com-field__label--read-mode {
	box-sizing: border-box;
	padding-right: 12px;
	font-size: 12px;
}

.com-field-item {
	.el-form-item__label {
		min-height: 32px;
		display: flex;
		align-items: center;
		text-align: left;
	}

	.el-form-item .el-form-item__label {
		line-height: 14px;
		display: flex;
		min-height: 32px;
		margin-bottom: 0;
	}

	.el-checkbox {
		width: 100%;
	}

	.el-tag--info {
		max-width: 100%;
		word-break: break-all;
	}

	.el-loading-spinner {
		margin-top: -12px;
	}

	.el-loading-spinner .circular {
		width: 24px;
		height: 24px;
	}

	.el-loading-parent--relative {
		overflow: visible;
	}
}

.com-form-fields__column--actions {
	.com-form-fields__item-wrap {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.com-field-item {
			flex-basis: calc(100% - 30px);
		}
	}
}
</style>
