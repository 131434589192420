let confirmLeaveMessageBox = null

export default function confirmBeforeLeave(params) {
	if (confirmLeaveMessageBox) {
		return confirmLeaveMessageBox
	}

	confirmLeaveMessageBox = this.$confirmLeave(params)
		.then(() => true)
		.catch(err => {
			throw err
		})
		.finally(() => {
			confirmLeaveMessageBox = false
		})

	return confirmLeaveMessageBox
}