export default {
	mBarcodesSettings(state, payload) {
		const { content, hasBarcodeSettingsModes } = payload
		state.hasBarcodeSettingsModes = hasBarcodeSettingsModes
		state.barcodesSettings = content
	},
	mActiveMode(state, payload) {
		state.activeMode = payload
	},
	mUPCSettings(state, payload) {
		state.UPCSettings = payload
	},
}