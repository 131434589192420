<!-- eslint-disable vue/no-v-html -->
<template>
	<el-tooltip
		class="part-info-tooltip item"
		effect="dark"
		placement="top"
		:offset="10"
	>
		<template #content>
			<div class="part-info-tooltip__tooltip-content">
				<div
					v-if="infoTooltip.html"
					v-html="infoTooltip.html"
				/>
				<template v-else>
					{{ infoTooltip.text }}
					<br>
				</template>

				<a
					v-if="infoTooltip.link"
					:href="infoTooltip.link.href"
					target="_blank"
					rel="noreferrer noopener"
					class="d-block mt-3"
				>
					<el-button
						type="info"
						class="w-100p"
					>
						{{ infoTooltip.link.text }}
						<ItIcon
							name="external-link"
							class="ml-2 fill-text-placeholder"
							width="14"
							height="14"
						/>
					</el-button>
				</a>
			</div>
		</template>

		<ItIcon
			v-if="showIcon"
			:name="iconName"
			:class="iconClass"
			width="14"
			height="14"
			:style="iconStyle"
		/>
	</el-tooltip>
</template>

<script>

export default {
	name: 'PartInfoTooltip',

	props: {
		infoTooltip: {
			type: Object,
			default: null,
		},
		labelTooltip: {
			type: Boolean,
			default: false,
		},
	},

	computed: {
		showIcon() {
			return Boolean(this.infoTooltip.text || this.infoTooltip.html || this.infoTooltip.link)
		},
		iconName() {
			return this.infoTooltip?.iconName || 'question-mark-circle-outline'
		},
		iconClass() {
			return this.infoTooltip?.iconClass || 'fill-text-primary'
		},
		iconStyle() {
			if (this.labelTooltip) {
				return `position: absolute; left: -24px; top: 9px;`
			}
			return ``
		},
	},
}
</script>

<style lang="scss">
.part-info-tooltip {
	&__tooltip-content {
		width: 256px;
		font-size: 12px !important;
	}
}
</style>