import { setIframeHeight, getIframeContentHeight } from './iframesContentHeight'
import updateElMessageBoxIframeStyles from './iframesElMessageBoxStyle'
import updateElNotificationIframeStyles from './iframesElNotificationsStyle'
import updateElDialogIframeStyles from './iframesElDialogStyle'
import updateElDrawerIframeStyles from './iframesElDrawerStyle'


let resizeObserver = null


function hasResizeObserver() {
	return !!window.ResizeObserver
}

function getResizebleElement() {
	return hasResizeObserver() ? document.getElementById('layout-index') : document.getElementById('oldBrowserHeightChangeListener')
}

export const iframeBodyResizeRemove = () => {
	if (hasResizeObserver()) {
		resizeObserver.unobserve(getResizebleElement())
	}
}

export const iframeBodyResizeRegister = () => {
	const postMessageWindowHeightChanged = () => {
		setIframeHeight(getIframeContentHeight())
		updateElMessageBoxIframeStyles()
		updateElNotificationIframeStyles()
		updateElDialogIframeStyles()
		updateElDrawerIframeStyles()
	}

	const resizeableElement = getResizebleElement()

	// Инициализация
	postMessageWindowHeightChanged()

	if (!hasResizeObserver()) {
		resizeableElement.contentWindow.onresize = postMessageWindowHeightChanged
	} else if (!resizeObserver) {
		resizeObserver = new ResizeObserver(postMessageWindowHeightChanged)
		resizeObserver.observe(resizeableElement)
	}
}