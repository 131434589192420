export const lifetimeRulesLabelMap = {
	INFINITY: 'Никогда',
	PERIODICAL: 'Периодически',
}

export const validatorTypesOptions = {
	WEAK: 'Стандартная',
	STRONG: 'Надежная',
}

export const lensesCategoriesOptions = {
	STOCK_LENS_CATEGORY: 'Из другого департамента',
	ORDER_LENS_CATEGORY: 'Заказная линза',
}