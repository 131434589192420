import i18n from '~/plugins/i18n'


export default {
	CREATED: {
		label: i18n.global.t('Создано'),
		value: 'WAIT',
		color: '#FB9E13',
		colorClass: 'bg-color-warning',
	},
	ACCEPTED: {
		label: i18n.global.t('Списано'),
		value: 'ACCEPTED',
		color: '#C2C2CC',
		colorClass: 'bg-color-primary',
	},
	CANCELLED: {
		label: i18n.global.t('Отменено'),
		value: 'IN_WORK',
		color: '#FF3C3C',
		colorClass: 'bg-color-danger',
	},
	PARTLY_ACCEPTED: {
		label: i18n.global.t('Частично списано'),
		value: 'ORDER_COMPLETED',
		color: '#909399',
		colorClass: 'bg-color-success',
	},
	/*
	ORDER_READY: {
		label: i18n.global.t('Готов'),
		value: 'ORDER_READY',
		color: '#FCE300',
		colorClass: 'bg-color-yellow',
	}, */
}