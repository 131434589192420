export default {
	gAccessories(state, _getters, rootState) {
		const { journalsDirectory } = rootState.journals
		const accessories = []

		state.accessories
			.forEach(item => {
				const addedAccessory = journalsDirectory.find(innerItem => innerItem.name.replace(/\s/g, '') === item.replace(/\s/g, ''))
				if (!addedAccessory) accessories.push(item)
			})

		return accessories
	},
}