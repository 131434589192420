// TODO копипаст из админки
export function isCreationView() {
	return this.$route.query.view === 'create'
}

export function entityId() {
	return this.$route.query.id || this.$route.query.clonedEntityId
}

export function isRu() {
	return this.$i18n.locale === 'ru'
}
/*
export function pickerOptions() {
	return {
		startDate: {
			disabledDate: time => this.$dayjs(time).isBefore(this.$dayjs().subtract(5, 'year')) || this.$dayjs().isBefore(time),
		},
		finishDate: {
			disabledDate: time => this.$dayjs().isBefore(time),
		},
	}
}
*/