<template>
	<div class="part-order-header">
		<LayoutPartTitle
			:title="title"
			:optima-help="optimaHelp"
			:has-return-button="hasReturnButton"
			@on-return="onReturn"
		/>

		<PartOrderClientAndSeller/>
	</div>
</template>

<script>
import PartOrderClientAndSeller from '~/pages/orders/parts/PartOrderClientAndSeller.vue'
import LayoutPartTitle from '~/layout/parts/LayoutPartTitle.vue'

export default {
	name: 'PartOrderHeader',
	components: {
		LayoutPartTitle,
		PartOrderClientAndSeller,
	},
	props: {
		title: {
			type: String,
			default: '',
		},
		hasReturnButton: {
			type: Boolean,
			default: false,
		},
		optimaHelp: {
			type: Object,
			required: false,
			default: null,
		},
	},
	data() {
		return {
			sellerId: null,
			searchString: '',
		}
	},

	methods: {
		onReturn() {
			this.$emit('on-return')
		},
	},
}
</script>

<style lang="scss">
.part-order-header {
	display: flex;
	justify-content: space-between;

	&__client-btn {
		display: flex;
		align-items: center;
		cursor: pointer;

		&--disabled {
			cursor: default;
		}
	}
}
</style>