<template>
	<div class="part-order-barcode">
		<el-popover
			ref="popover"
			:visible="isPopoverVisible"
			width="284"
			trigger="manual"
		>
			<template #reference>
				<GoodsBarcodesScanner
					ref="barcode"
					:barcodes-length="barcodesLengths"
					@scanBarcode="scanBarcode"
				/>
			</template>

			<CLPopoverBody
				v-if="contactLens"
				:key="isExpiredCL"
				v-model="contactLens.currentQuantity"
				:contact-lens="contactLens"
				:is-expired-cl="isExpiredCL"
				:is-popover-visible="isPopoverVisible"
				:max-size="maxSize"
				:full-package="contactLens.restQuantity"
			/>

			<div class="d-flex justify-content-center gap-12 mt-6">
				<el-button
					type="primary"
					class="w-100p"
					plain
					@click="close"
				>
					{{ $t('Отмена') }}
				</el-button>
				<el-button
					class="w-100p"
					type="primary"
					@click="confirm"
				>
					{{ $t('Добавить') }}
				</el-button>
			</div>
		</el-popover>
	</div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import GoodsBarcodesScanner from '~/components/goods/GoodsBarcodesScanner.vue'
import soundsEffects from '~/utils/soundsEffects'
import multiplicityCLPackageTest from '~/utils/multiplicityCLPackageTest'
import CLPopoverBody from './PartOrderBarcode/CLPopoverBody.vue'

export default {
	name: 'PartOrderBarcode',
	components: {
		GoodsBarcodesScanner,
		CLPopoverBody,
	},
	data: () => ({
		contactLens: null,
		barcode: null,
		isPopoverVisible: false,
		maxSize: null,
		isExpiredCL: false,
	}),
	computed: {
		...mapState('orders', [
			'cartUid',
		]),
		...mapState('good', ['barcodesLengths']),
	},
	mounted() {
		this.$refs.barcode.onFocus()
	},
	methods: {
		...mapActions('orders', [
			'aScanBarcode',
		]),

		async scanBarcode(barcode) {
			const data = { barcode }
			const response = await this.aScanBarcode({ data, uid: this.cartUid })

			if (!response) {
				soundsEffects('barcode-scan-error.wav')
				return
			}

			if (response.data.sellableCategory === 'CONTACT_LENS') {
				this.barcode = barcode
				this.contactLens = response.data
				const { expireAt } = response.data
				const isExpiredCL = expireAt
					? this.$dayjs().isAfter(this.$dayjs(expireAt))
					: false

				this.isExpiredCL = isExpiredCL

				if (!response.data.onlyFullPackages) {
					this.maxSize = response.data.restQuantity
					this.contactLens.currentQuantity = response.data.restQuantity
					this.isPopoverVisible = true
					return
				}

				this.contactLens.currentQuantity = null

				if (isExpiredCL) {
					this.isPopoverVisible = true
				} else {
					this.$emit('addGoods', { item: this.contactLens, barcode, isScan: true })
				}
			} else if (response.data.sellableCategory === 'CONTACT_LENS' && response.data.onlyFullPackages) {
				const contactLens = response.data
				contactLens.currentQuantity = null
				this.$emit('addGoods', { item: contactLens, barcode, isScan: true })
			} else {
				this.$emit('addGoods', { item: response.data, barcode, isScan: true })
			}
		},
		confirm() {
			if (this.isExpiredCL) {
				this.isExpiredCL = false
				if (this.contactLens.onlyFullPackages) {
					this.isPopoverVisible = false
					this.$emit('addGoods', {
						item: this.contactLens,
						barcode: this.barcode,
						isScan: true,
					})
				}
				return
			}

			const { retailPrice, packageQuantity, currentQuantity } = this.contactLens
			const isValidAmount = multiplicityCLPackageTest(retailPrice, packageQuantity, currentQuantity)

			if (!isValidAmount) {
				const errorMessage = 'Стоимость пачки не кратна стоимости выбранного количества линз.'
					+ 'Продайте пачку полностью или попросите менеджера переоценить пачку!'

				this.$notifyUserAboutError(this.$t(errorMessage))
				return
			}

			this.$emit('addGoods', { item: this.contactLens, barcode: this.barcode })
			this.close()
		},
		close() {
			this.isPopoverVisible = false
		},
	},
}
</script>

<style lang="scss">
.part-order-barcode {
	margin-top: 32px;
}

.gap-12 {
	gap: 12px;
}
</style>
