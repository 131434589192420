import { routerPush, callBeforeRouteLeaveForLegacyLink } from './routerPush'

export const loadFormSimpleCheckup = async url => {
	// Обработка ссылок, установленных в шаблонах легаси вручную, примерно до 202302
	if (url.startsWith('apiView/index?frontendView=')) {
		let path = '/'
		path += url.replace('apiView/index?frontendView=', '')

		if (!path.includes('?')) {
			path = path.split('&')
			path = `${path.shift()}?${path.join('&')}`
		}

		path.replaceAll('//', '/')
		routerPush(path)
		return true
	}

	return callBeforeRouteLeaveForLegacyLink()
}