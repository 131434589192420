<template>
	<el-popover
		placement="bottom"
		width="400"
		trigger="click"
		class="com-seller-select"
		:class="disabled && 'com-seller-select--disabled'"
		:disabled="disabled"
	>
		<div class="com-seller-select__header pb-4">{{ $t('Выберите продавца') }}</div>

		<el-input
			v-if="pincodeMode"
			v-model="pincode"
			:placeholder="$t('Пинкод')"
			class="mb-3"
			@update:modelValue="enterPincode"
			@change="enterPincode"
		>
			<template #append><el-button><ItIcon
				name="pincode"
			/></el-button></template>
		</el-input>

		<template v-else>
			<el-input
				v-model="filterString"
				class="mb-3"
				:placeholder="$t('Введите имя, фамилию или отчество')"
				suffix-icon="search"
			/>
			<div class="com-seller-select__selector">
				<div
					v-for="user in filteredUsers"
					:key="user.id"
					class="my-2 com-seller-select__option"
					@click="selectUser(user)"
				>
					<el-radio
						v-model="selectedUser"
						:label="user.id"
					>{{ user.fio }}</el-radio>
				</div>
			</div>
		</template>

		<template #reference>
			<div
				class="d-flex align-items-center"
				:class="{ 'com-seller-select__trigger': !isSaleOrRepair }"
			>
				<div
					class="d-flex flex-column ml-2"
				>
					<span
						v-if="hasLabel"
					>{{ $t('Продавец') }}</span>
					<strong
						class="mt-1"
						:class="{ 'pr-6': !isSaleOrRepair }"
					>{{ activeSellerName }} <ItIcon
						v-if="!disabled"
						name="chevron-down-thin"
						height="20"
						class="com-seller-select__icon"
					/></strong>
				</div>
			</div>
		</template>
	</el-popover>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import { ORDER_TYPE_MAP } from '~/helpers/common/OrderTypes'
import { getFioFull } from '~/utils/formatters'
import { isIframe } from '~/utils/iframes'

export default {
	name: 'ComSellerSelect',
	props: {
		hasLabel: {
			type: Boolean,
			default: true,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			pincode: '',
			pincodeLength: 10,
			filterString: '',
			saveStateKey: 'com-seller-select.selectedSeller',
			selectedUser: null,
		}
	},
	computed: {
		...mapState(['settings']),
		...mapState('orders', ['orderType']),
		...mapState('users', [
			'selectedSeller',
			'usersAssignable',
		]),
		...mapGetters('user', [
			'gUser',
			'gRoles',
			'gFioFull',
		]),
		pincodeMode() {
			return +this.settings.inactiveTime
		},
		filteredUsers() {
			const usersAssignable = this.usersAssignable.map(user => ({
				...user,
				fio: getFioFull(user, this.settings.firstNameBeforeFamilyName),
				active: user.id === this.selectedSeller.id,
			}))
			if (!this.filterString) {
				return usersAssignable
			}
			return usersAssignable.filter(u => u.fio.toLowerCase().includes(this.filterString.toLowerCase()))
		},
		activeSellerName() {
			if (this.selectedSeller) {
				return getFioFull(this.selectedSeller, this.settings.firstNameBeforeFamilyName)
			}
			return this.gFioFull
		},
		componentsState() {
			return this.$store.getters['user/gComponentsState'] || {}
		},
		isSaleOrRepair() {
			return this.orderType === 'SALE' || this.orderType === 'REPAIR'
		},
	},
	watch: {
		orderType: {
			immediate: true,
			handler() { this.getDepartmentsCurrentSellers() },
		},
	},
	async mounted() {
		if (!this.selectedSeller) {
			this.mSelectedSeller(this.gUser)
			this.sendSelectedSellerToLegacy()
			this.prevSelectedSeller = { ...this.gUser }
			this.selectedUser = this.gUser.id
		}
		document.body.addEventListener('keyup', this.closeOnEsc)
	},
	methods: {
		...mapActions('users', ['aUsersAssignable']),
		...mapMutations('users', ['mSelectedSeller']),
		enterPincode() {
			// Pincodes:
			// 4835803136
			// 9914974500
			const pincode = this.pincode.trim()
			if (pincode.length >= this.pincodeLength) {
				window.userPincode(pincode)
				if (pincode.length === this.pincodeLength) {
					this.pincode = ''
				}
			}
		},
		selectUser(user) {
			this.selectedUser = user.id
			this.mSelectedSeller(user)
			this.sendSelectedSellerToLegacy()
			this.close()
		},
		sendSelectedSellerToLegacy() {
			if (isIframe) {
				window.userChange(this.selectedSeller?.id || this.gUser.id)
			}
		},
		getDepartmentsCurrentSellers() {
			if (!this.pincodeMode && ORDER_TYPE_MAP[this.orderType]) {
				this.aUsersAssignable(ORDER_TYPE_MAP[this.orderType])
			}
		},
		onLegacySelectUser(user) {
			if (user) {
				this.mSelectedSeller(user)
			}
		},
		closeOnEsc(event) {
			if (event.key === 'Escape') {
				this.close()
			}
		},
		close() {
			document.body.click()
		},
	},
}
</script>

<style lang="scss">
.com-seller-select {
	&__header {
		font-weight: 600;
		font-size: 14px;
		line-height: 16px;
	}

	&__selector {
		max-height: 140px;
		overflow-y: auto;
	}

	&__trigger {
		min-width: 200px;
		position: relative;
		cursor: pointer;
	}

	&__option {
		cursor: pointer;
	}

	&__icon {
		position: absolute;
		bottom: -2px;
		right: 0;
	}

	&--disabled &__trigger {
		cursor: default;
	}
}
</style>