import departmentsState from './departmentsState'
import departmentsActions from './departmentsActions'
import departmentsMutations from './departmentsMutations'
import departmentsGetters from './departmentsGetters'

export default {
	namespaced: true,
	state: departmentsState,
	getters: departmentsGetters,
	mutations: departmentsMutations,
	actions: departmentsActions,
}