import clientFiltersState from './clientFiltersState'
import clientFiltersActions from './clientFiltersActions'
import clientFiltersMutations from './clientFiltersMutations'
import clientFiltersGetters from './clientFiltersGetters'

export default {
	namespaced: true,
	state: clientFiltersState,
	getters: clientFiltersGetters,
	mutations: clientFiltersMutations,
	actions: clientFiltersActions,
}