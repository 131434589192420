import VuexHttp from '../../vuexHttpService'

const vHttp = new VuexHttp('supplies')

export default {
	namespaced: true,

	state: {
		suppliers: [],
		notSystemSuppliers: [],
	},

	mutations: {
		mSuppliers(state, payload) {
			state.suppliers = payload
		},
		mNotSystemSuppliers(state, payload) {
			state.notSystemSuppliers = payload
		},
	},

	actions: {
		async aSuppliers() {
			return vHttp.GET(this, {
				url: `/suppliers`,
				mutation: `mSuppliers`,
			})
		},
		async aSupplyById(_, id) {
			return vHttp.GET(this, {
				url: `supplies/${id}`,
			})
		},
		async aDeleteSupplyById(_, id) {
			return vHttp.DELETE(this, {
				url: `supplies-delete/${id}`,
			})
		},
		aNotSystemSuppliers() {
			return vHttp.GET(this, {
				url: `suppliers/not-system`,
				mutation: 'mNotSystemSuppliers',
			})
		},
	},

	getters: {
		gSuppliersOptions(state) {
			return state.suppliers
				.map(supplier => ({ label: supplier.name, value: supplier.name }))
		},
		gNotSystemSuppliersOptions(state) {
			return state.notSystemSuppliers
				.map(supplier => ({
					label: supplier.name,
					value: supplier.name,
				}))
		},
	},
}