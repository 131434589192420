<template>
	<el-input
		ref="inputRef"
		v-model="formattedValue"
		:validate-event="false"
		@clear="$emit('clear')"
	>
		<template #prefix>
			<ItIcon
				v-if="prefixIcon"
				class="fill-info"
				height="24px"
				width="24px"
				:name="prefixIcon"
			/>

			<span
				v-else-if="prefixChar"
			>{{ prefixChar }}</span>
		</template>

		<template #suffix>
			<ItIcon
				v-if="suffixIcon"
				height="24"
				width="24"
				:name="suffixIcon"
			/>

			<span
				v-else-if="suffixChar"
			>{{ suffixChar }}</span>
		</template>
	</el-input>
</template>

<script>
import { useCurrencyInput } from 'vue-currency-input'
import { watch, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { getCurrenciesFormats } from '~/utils/currencies'


const DEFAULT_OPTIONS = {
	locale: 'ru-RU',
	currency: 'RUB',
	currencyDisplay: 'hidden',
	valueRange: {
		min: 0.00,
	},
	hideCurrencySymbolOnFocus: true,
	hideGroupingSeparatorOnFocus: true,
	hideNegligibleDecimalDigitsOnFocus: true,
	autoDecimalDigits: false,
	useGrouping: true,
	accountingSign: false,
	precision: 2,
}

export default {
	name: 'ComCurrencyInput',

	props: {
		prefixIcon: {
			type: String,
			default: null,
		},
		suffixIcon: {
			type: String,
			default: null,
		},
		suffixChar: {
			type: String,
			default: null,
		},
		prefixChar: {
			type: String,
			default: null,
		},
		modelValue: {
			type: Number,
			default: null,
		},
		options: {
			type: Object,
			default: () => ({
				...DEFAULT_OPTIONS,
			}),
		},
	},

	setup(props) {
		const { locale } = useI18n()

		// eslint-disable-next-line vue/no-setup-props-destructure
		const { inputRef, formattedValue, setValue } = useCurrencyInput({
			...DEFAULT_OPTIONS,
			...props.options,
		})

		watch(() => props.modelValue, newValue => {
			setValue(newValue)
		})

		const setInputListener = input => {
			// Получаем знак-разделитель для текущей локали или браузера
			const decimalSeparator = getCurrenciesFormats(locale.value || navigator.languages[0]).decimal

			input.addEventListener('paste', e => {
				e.preventDefault()

				const { clipboardData } = e
				const pastedData = clipboardData.getData('Text')
					.replace(new RegExp(`\\${decimalSeparator}`), '.')

				parseFloat(pastedData)

				setValue(pastedData)
			})
		}

		function focus() {
			inputRef.value.input.focus()
		}

		onMounted(() => setInputListener(inputRef.value.input))

		return { inputRef, formattedValue, focus }
	},
}
</script>