import { getMonths, getWeekDays } from '~/utils/localeConsts'

export const monthItems = function () {
	return getMonths(this.$store.state.user.locale).map(str => str.slice(0, 3))
		.map((label, index) => ({ value: index + 1, label }))
}

export const weekdayItems = function () {
	return getWeekDays({ locale: this.$store.state.user.locale, mode: 'short' })
		.map((label, index, items) => {
			// Отсчёт начинается с Вс, где вс 1 день, поэтому +2
			let cronValue = index + 2

			if (items.length === (index + 1)) cronValue = 1

			return { cronValue, label }
		})
}