import { goodsParamsFiltersFormatter, goodsTypesFiltersFormatter } from '~/pages/discounts-journals/data/goodsParamsFormatters'
import VuexHttp from '../../vuexHttpService'

const vHttp = new VuexHttp('journals')

export default {
	async aJournalsClients(_, { query, options }) {
		return vHttp.GET(this, {
			url: {
				path: 'clients',
				query,
			},
			mutation: 'mJournalsClients',
			options,
		})
	},
	async aJournalsDepartments(_, params) {
		return vHttp.GET(this, {
			url: {
				path: 'departments',
				query: params,
			},
			mutation: 'mJournalsDepartments',
		})
	},
	async aJournalsUsers(_, params) {
		return vHttp.GET(this, {
			url: {
				path: 'users',
				query: params,
			},
			mutation: 'mJournalsUsers',
		})
	},
	async aJournalsGlasses(_, params) {
		return vHttp.GET(this, {
			url: {
				path: 'serves/glasses',
				query: params,
			},
			mutation: 'mJournalsGlasses',
		})
	},
	async aJournalsLegalEntities(_, params) {
		return vHttp.GET(this, {
			url: {
				path: 'legal-entities',
				query: params,
			},
			mutation: 'mJournalsLegalEntities',
		})
	},
	async aJournalsLicences(_, params) {
		return vHttp.GET(this, {
			url: {
				path: `legal-entities/licences`,
				query: params,
			},
			mutation: 'mJournalsLicences',
		})
	},
	async aJournalsAccessControl(_, { query }) {
		const path = `access-control`

		return vHttp.GET(this, {
			url: {
				path,
				query,
			},
			mutation: 'mJournalsAccessControl',
		})
	},
	async aJournalsAccessControlStatistics(_, { query }) {
		const path = 'access-control/statistics'

		return vHttp.GET(this, {
			url: {
				path,
				query,
			},
			mutation: 'mJournalsAccessControlStatistics',
		})
	},
	async aJournalsClientFiles(_, params) {
		const path = `clients/${params.id}/files`
		const copy = { ...params }
		delete copy.id

		return vHttp.GET(this, {
			url: {
				path,
				query: copy,
			},
			mutation: 'mJournalsClientFiles',
		})
	},
	async aJournalsSalesPlans(_, params) {
		const path = `sales-plans`
		const copy = { ...params }
		delete copy.id

		return vHttp.GET(this, {
			// mock: true,
			url: {
				path,
				query: copy,
			},
			mutation: 'mJournalsSalesPlans',
		})
	},
	async aJournalsOrders(_, { query, options }) {
		return vHttp.GET(this, {
			// mock: true,
			url: {
				path: 'orders',
				query,
			},
			mutation: 'mJournalsOrders',
			options,
		})
	},
	async aJournalsOrdersExpectedDate(_, { query, options }) {
		return vHttp.GET(this, {
			// mock: true,
			url: {
				path: 'orders',
				query,
			},
			mutation: 'mJournalsOrdersExpectedDate',
			options,
		})
	},
	async aJournalsTransfers(_, query) {
		return vHttp.GET(this, {
			// mock: true,
			url: {
				path: 'transfers',
				query,
			},
			mutation: 'mJournalsTransfers',
		})
	},
	async aJournalsDirectory(_, { dirInfo, params }) {
		const path = `${dirInfo.category}/${dirInfo.name}/list`

		const queryParams = {
			page: params.page,
			size: params.size,
		}

		// Если в параметре запроса присутствует обратный слэш, заменяем его на 2 слэша
		// Т.к. Java не понимает одиночный обратный слэш, его надо продублировать
		// Та же проблема и в JS, поэтому одиночный обратный слэш здесь, как двойной

		queryParams[dirInfo.searchQueryParam] = typeof params.searchString === 'string'
			? params.searchString.replaceAll('\\', '\\\\')
			: params.searchString

		return vHttp.GET(this, {
			url: {
				path,
				query: queryParams,
			},
			mutation: 'mJournalsDirectory',
		})
	},
	async aJournalsOrdersCompleted(_, query) {
		return vHttp.GET(this, {
			// mock: true,
			url: {
				path: 'orders/completed',
				query,
			},
			mutation: 'mJournalsOrdersCompleted',
		})
	},
	async aJournalsAcceptTransfer(_, params) {
		const path = 'transfers/accepted'

		return vHttp.GET(this, {
			url: {
				path,
				query: params,
			},
			mutation: 'mJournalsAcceptTransfer',
		})
	},
	async aJournalsReturns(_, params) {
		const path = params.orderId
			? `order-returns/by-order/${params.orderId}`
			: 'order-returns'

		const query = params.orderId ? null : params

		return vHttp.GET(this, {
			url: {
				path,
				query,
			},
			mutation: 'mJournalsReturns',
		})
	},
	async aJournalsExtras(_, { query, options }) {
		return vHttp.GET(this, {
			url: {
				path: 'order/extras',
				query: {
					...query,
					isGroupExtras: false,
				},
				options,
			},
			mutation: 'mJournalsExtras',
		})
	},
	async aJournalsExtrasGroups(_, { query, options }) {
		return vHttp.GET(this, {
			url: {
				path: 'order/extras',
				query: {
					...query,
					isGroupExtras: true,
				},
				options,
			},
			mutation: 'mJournalsExtrasGroups',
		})
	},
	async aJournalsRevisions(_, params) {
		return vHttp.GET(this, {
			url: {
				path: '/revisions',
				query: params,
			},
			mutation: 'mJournalsRevisions',
		})
	},
	async aJournalsCancellations(_, { query, options }) {
		return vHttp.GET(this, {
			url: {
				path: 'cancellations',
				query,
			},
			mutation: 'mJournalsCancellations',
			options,
		})
	},
	async aJournalsCancellationsMaterials(_, { query, options }) {
		return vHttp.GET(this, {
			url: {
				path: 'cancellations/client-goods',
				query,
			},
			mutation: 'mJournalsCancellationsMaterials',
			options,
		})
	},
	async aJournalsReevaluations(_, { query, options }) {
		return vHttp.GET(this, {
			url: {
				path: 'reevaluations',
				query: { ...query, type: 'RETAIL' },
			},
			mutation: 'mJournalsReevaluations',
			options,
		})
	},
	async aJournalsWholesaleReevaluations(_, { query, options }) {
		return vHttp.GET(this, {
			url: {
				path: 'reevaluations',
				query: { ...query, type: 'WHOLESALE' },
			},
			mutation: 'mJournalsWholesaleReevaluations',
			options,
		})
	},
	async aJournalsConfirmedSupplies(_, params) {
		const path = 'supplies'
		return vHttp.GET(this, {
			url: {
				path,
				query: {
					...params,
					status: 'REGISTERED',
				},
			},
			mutation: 'mJournalsConfirmedSupplies',
		})
	},
	async aJournalsSavedSupplies(_, params) {
		const path = 'supplies'
		return vHttp.GET(this, {
			url: {
				path,
				query: {
					...params,
					status: 'SAVED',
				},
			},
			mutation: 'mJournalsSavedSupplies',
		})
	},
	async aJournalsDiscountsStandard(_, { query, options }) {
		return vHttp.GET(this, {
			url: {
				path: 'discounts',
				query: { ...query, coupon: false },
			},
			mutation: 'mJournalsDiscountsStandard',
			options,
		})
	},
	async aJournalsDiscountsCoupons(_, { query, options }) {
		return vHttp.GET(this, {
			url: {
				path: 'discounts',
				query: { ...query, coupon: true },
			},
			mutation: 'mJournalsDiscountsCoupons',
			options,
		})
	},
	async aJournalsDiscountsAutomatic(_, { query, options }) {
		const formattedQuery = {}

		if (query.goodsType) {
			formattedQuery.goodsType = goodsTypesFiltersFormatter(query.goodsType)
		}

		Object.entries(query).forEach(([paramName, paramValue]) => {
			if (paramName === 'goodsType') return
			if (!paramValue && paramName !== 'active') return

			const formattedParamName = goodsParamsFiltersFormatter(paramName, query.goodsType)
			formattedQuery[formattedParamName] = paramValue
		})

		return vHttp.GET(this, {
			url: {
				path: 'auto-discounts',
				query: formattedQuery,
			},
			mutation: 'mJournalsDiscountsAutomatic',
			options,
		})
	},
	async aJournalsDiscountsClientsCard(_, { query, options }) {
		return vHttp.GET(this, {
			url: {
				path: 'client-card-discounts',
				query,
			},
			mutation: 'mJournalsDiscountsClientsCard',
			options,
		})
	},
	async aJournalsDiscountsCLReplacement(_, { query, options }) {
		return vHttp.GET(this, {
			url: {
				path: 'cl-replace-discounts',
				query,
			},
			mutation: 'mJournalsDiscountsCLReplacement',
			options,
		})
	},
	async aJournalsDiscountsMarkdown(_, { query, options }) {
		const formattedQuery = {}

		if (query.goodsType) {
			formattedQuery.goodsType = goodsTypesFiltersFormatter(query.goodsType)
		}

		Object.entries(query).forEach(([paramName, paramValue]) => {
			if (paramName === 'goodsType') return
			if (!paramValue && paramName !== 'active') return

			const formattedParamName = goodsParamsFiltersFormatter(paramName, query.goodsType)
			formattedQuery[formattedParamName] = paramValue
		})

		return vHttp.GET(this, {
			url: {
				path: 'markdown-discounts',
				query: formattedQuery,
			},
			mutation: 'mJournalsDiscountsMarkdown',
			options,
		})
	},
	async aJournalsWorkshop(_, params) {
		return vHttp.GET(this, {
			url: {
				path: '/production-glasses',
				query: params,
			},
			mutation: 'mJournalsWorkshop',
		})
	},
	aJournalsBalance(_, query) {
		return vHttp.GET(this, {
			url: {
				path: 'balance/history',
				query,
			},
			mutation: 'mJournalsBalance',
		})
	},
	aJournalsNews(_, query) {
		return vHttp.GET(this, {
			url: {
				path: 'news',
				query,
			},
			mutation: 'mJournalsNews',
		})
	},
	aJournalsGiftCertificates(_, query) {
		return vHttp.GET(this, {
			url: {
				path: 'gift-certificates',
				query,
			},
			mutation: 'mJournalsGiftCertificates',
		})
	},
	aJournalsGiftCertificatesByDate(_, query) {
		return vHttp.GET(this, {
			url: {
				path: 'gift-certificates/by-date',
				query,
			},
			mutation: 'mJournalsGiftCertificatesByDate',
		})
	},
	async aJournalsCompilanceRegister(_, { query }) {
		return vHttp.GET(this, {
			url: {
				path: '/good/certificates',
				query,
			},
			mutation: 'mJournalsCompilanceRegister',
		})
	},
	aJournalsMailingSms(_, query) {
		return vHttp.GET(this, {
			url: {
				path: 'mailings',
				query: {
					type: 'SMS',
					...query,
				},
			},
			mutation: 'mJournalsMailingSms',
		})
	},
	aJournalsMailingEmail(_, query) {
		return vHttp.GET(this, {
			url: {
				path: 'mailings',
				query: {
					type: 'EMAIL',
					...query,
				},
			},
			mutation: 'mJournalsMailingEmail',
		})
	},
	aJournalsMailingFailed(_, { id, query }) {
		return vHttp.GET(this, {
			url: {
				path: `mailings/${id}/failed-messages`,
				query,
			},
			mutation: 'mJournalsMailingFailed',
		})
	},
	aJournalsClientsFilters(_, { query }) {
		return vHttp.GET(this, {
			url: {
				path: `clients/filters`,
				query,
			},
			mutation: 'mJournalsClientsFilters',
		})
	},
}