// import VueScrollTo from 'vue-scrollto'
// import isElementInViewport from '../../utils/isElementInViewport'
// import { isIframe } from '../../utils/isIframe'

async function $isFormValid(ref) {
	const form = typeof ref === 'object' ? ref : this.$refs[ref]
	
	let isValid = await new Promise(resolve => form.validate(resolve)) 
	
	// в Element-plus нужно устанавливать на форму атрибут scroll-to-error
	/* if (!isValid) {
		const firstError = form.fields?.find(formItem => formItem.validateState === 'error')
		const offset = -12

		if (firstError && !isIframe) {
			if (!isElementInViewport({ el: firstError.$el })) VueScrollTo.scrollTo(firstError.$el, 500, { easing: 'ease', offset })
		} else if (firstError && isIframe) {
			this.$store.dispatch('iframe/postMessageGetIframeBoundingClientRect')
			setTimeout(() => {
				if (!isElementInViewport({
					el: firstError.$el,
					isIframe,
					iframeRect: this.$store.state.iframe.boundingClientRect,
				})) {
					const topSum = firstError.$el.getBoundingClientRect().top + this.$store.state.iframe.boundingClientRect.top + offset
					this.$store.dispatch('iframe/postMessageScrollBy', { x: 0, y: topSum })
				}
			}, 20)
		}
	} */

	return isValid
}

export default {
	install(app) {
		app.config.globalProperties.$isFormValid = $isFormValid
	},
}