const defaultDepartment = {
	address: null,
	cardAccountId: null,
	cashAccountId: null,
	city: null,
	collectionAccountId: null,
	collectionGrayAccountId: null,
	depotId: null,
	frMode: false,
	grayAccountId: null,
	hasServices: false,
	name: null,
	otherDepartmentSearchId: 'current',
	phone: null,
	productionId: null,
	storeBrandId: null,
	timeZone: 180,
	type: 'DEPOT',
}

export default function () {
	return {
		departments: [],
		departmentsLoading: false,
		department: null,
		defaultDepartment,
		departmentsByUserGroup: [],
		departmentsProduction: [],
		departmentsOtherSearch: [],
		departmentsDepot: [],
		departmentsForSearch: [],
		departmentsTypes: [],
		accountsCash: [],
		accountsCashless: [],
		accountsTypes: [],
		ordersTypes: [],
		accessoryTypes: [],
		productsTypes: [],
		accessories: [],
		services: [],
		servicesTypes: [],
		departmentLegalEntityRules: {},
		departmentLegalEntityRulesPossibleAccessoryCategories: [],
		departmentLegalEntityRulesPossibleServiceTypes: [],
		departmentLegalEntities: [],
		departmentUnusedLegalEntities: [],
		fiscalRegisterExternal: [],
		fiscalRegisterTypes: [],
		fiscalRegister: [],
		registryData: [],
		nativeFiscalRegisterEditing: false,
		legalEntitiesInTable: [],
		departmentSettings: null,
	}
}