<template>
	<div
		class="com-order-tags"
		data-ta="order-tags"
	>
		<el-tag
			v-for="tag of tagsFiltered"
			:key="tag.name || tag.text"
			type="info"
			class="com-order-tags__tag"
			@click="clickTag(tag)"
		>
			<ItIcon
				v-if="tag.name && tag.name.includes('barcode')"
				name="barcode-settings"
				class="color-text-regular"
				width="14"
				height="14"
			/>
			<span class="color-text-regular">{{ tag.text }}</span>
			<ItIcon
				v-if="deletable"
				name="close"
				class="color-text-regular com-order-tags__delete"
				width="14"
				height="14"
				@click.stop="deleteTag(tag)"
			/>
		</el-tag>
	</div>
</template>

<script>
export default {
	props: {
		tags: {
			type: Array,
			default: () => [],
		},
		deletable: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		tagsFiltered() {
			return this.tags
				.filter(item => !!item)
				.map(item => {
					let text
					if (item.name && item.name.includes('barcode')) {
						text = `${item.value}`
					} else if (item?.name) {
						text = `${item.name}: ${item.amount}`
					} else {
						text = item?.value || item
					}


					if (typeof item === 'string') {
						return {
							text,
						}
					}

					return {
						...item,
						text,
					}
				})
		},
	},
	methods: {
		clickTag(tag) {
			this.$emit('click', tag)
		},
		deleteTag(tag) {
			this.$emit('delete', tag)
		},
	},
}
</script>

<style lang="scss">
.com-order-tags {
	display: flex;
	flex-wrap: wrap;
	gap: 4px;
	cursor: pointer;

	&__tag {
		border-radius: 20px;
		display: flex;
		align-items: center;
		gap: 4px;
		max-width: 200px;

		span {
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}
	}

	.el-tag__content {
		display: flex;
		align-items: center;
	}

	&__delete {
		cursor: pointer;
		flex-shrink: 0;

		&:hover {
			opacity: 0.5;
		}
	}
}
</style>