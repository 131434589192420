import VuexHttp from '../../vuexHttpService'

const vHttp = new VuexHttp('applicationSettings')

export default {
	async aApplicationSettings() {
		return vHttp.GET(this, {
			url: 'settings/application',
			mutation: 'mApplicationSettings',
			action: 'aLensesCategories',
		})
	},

	async aLensesCategories() {
		return vHttp.GET(this, {
			url: 'settings/application/lenses/categories',
			mutation: 'mLensesCategories',
		})
	},

	async aApplicationSettingsUpdate(_, payload) {
		return vHttp.PUT(this, {
			url: 'settings/application',
			data: payload,
		})
	},

	async aSecuritySettings() {
		return vHttp.GET(this, {
			url: 'settings/security',
			mutation: 'mSecuritySettings',
			action: 'aPasswordValidatorTypes',
		})
	},

	async aSaveSecuritySettings(_, data) {
		return vHttp.POST(this, {
			url: 'settings/security',
			data,
		})
	},

	async aPasswordValidatorTypes() {
		return vHttp.GET(this, {
			url: 'settings/security/password/validator-types',
			mutation: 'mValidatorTypes',
			action: 'aLifetimeRules',
		})
	},

	async aLifetimeRules() {
		return vHttp.GET(this, {
			url: 'settings/security/password/lifetime-rules',
			mutation: 'mLifetimeRules',
		})
	},

	async aInformationSettings() {
		return vHttp.GET(this, {
			url: 'settings/orders-reasons',
			mutation: 'mInformationSettings',
		})
	},

	async aSaveInformationSettings(_, data) {
		return vHttp.PUT(this, {
			url: 'settings/orders-reasons',
			data,
		})
	},

	async aMedicalCardSettings() {
		return vHttp.GET(this, {
			url: 'medical-charts/blocks',
			mutation: 'mMedicalCardSettings',
		})
	},

	async aSaveMedicalCardSettings(_, data) {
		const request = data.map(item => ({
			active: item.active,
			name: item.name,
			position: item.position,
		}))

		return vHttp.PUT(this, {
			url: 'medical-charts/blocks',
			data: request,
		})
	},

	async aCLExpirationDateSettings() {
		return vHttp.GET(this, {
			url: 'contact-lenses/expiration-rules',
			mutation: 'mCLExpirationDateSettings',
		})
	},

	async aWearingPeriodUpdate(_, data) {
		const requests = []
		const deleteRules = data.filter(item => item.delete).map(rule => rule.id).join(',')
		const createRules = data.filter(item => item.new && !item.delete).map(rule => ({
			criticalPeriodDays: rule.criticalPeriodDays,
			wearingPeriod: rule.wearingPeriod,
			hasColor: rule.hasColor,
		}))

		if (deleteRules.length) {
			requests.push(
				vHttp.DELETE(this, {
					url: `contact-lenses/expiration-rules?rulesIds=${deleteRules}`,
				})
			)
		}

		if (createRules.length) {
			requests.push(
				vHttp.POST(this, {
					url: 'contact-lenses/expiration-rules',
					data: createRules,
				})
			)
		}

		return Promise.all(requests)
	},

	async aWearingPeriod(_, query) {
		return vHttp.GET(this, {
			url: {
				path: 'contact-lenses/wearing-periods/list',
				query,
			},
			mutation: 'mWearingPeriod',
		})
	},
}