import fieldsOrderTypes from '~/pages/departments/data/fieldsOrderTypes'

export default {
	mLegalEntities(state, payload) {
		state.legalEntities = payload.content
	},
	mLegalEntitiesPagination(state, payload) {
		state.legalEntitiesPagination = payload
	},
	mTaxSchemes(state, payload) {
		state.taxSchemes = payload
	},
	mLicense(state, payload) {
		state.license = payload
	},
	mLegalEntityDepartments(state, payload) {
		if (payload) {
			const { content, ...other } = payload
			state.legalEntityDepartments = content.map(item => {
				item.account = item.account === null
					? {
						label: null,
						value: null,
					}
					: {
						label: item.account.name,
						value: item.account.id,
					}

				item.delete = false
				return item
			})
			state.legalEntityDepartmentsPagination = other
		} else {
			state.legalEntityDepartments = []
			state.legalEntityDepartmentsPagination = {}
		}
	},
	mLegalEntity(state, payload) {
		state.legalEntity = payload
	},
	mTaxRules(state, payload) {
		state.taxRules = payload
	},
	mProductsTypes(state, payload) {
		const fields = payload.map(field => {
			field.name = field.id
			field.title = field.localName
			field.type = 'select'
			return field
		})
		state.productsTypes = fields
	},
	mOrdersTypes(state, payload) {
		const ids = payload.map(item => item.id)
		const fields = fieldsOrderTypes.filter(field => ids.includes(field.name))
		state.ordersTypes = fields
	},
	mServicesTypes(state, payload) {
		const typesMap = {}
		payload.forEach(st => {
			typesMap[st.id] = st
		})
		state.servicesTypes = typesMap
	},
	mServesTypesGroups(state, payload) {
		state.servesTypesGroups = payload
	},
	mAccessoriesCategories(state, payload) {
		state.accessoriesCategories = payload
	},
	mSections(state, payload) {
		state.sections = payload
	},
	mUnusedDepartments(state, payload) {
		state.unusedDepartments = payload
	},
}