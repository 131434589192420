<template>
	<div class="d-flex gap-4 justify-content-flex-end">
		<el-button
			v-if="!hideButtons.update"
			type="primary"
			data-ta="update-filters"
			plain
			class="layout-parts-filters__update-btn"
			:disabled="disableButtons.update"
			@click="updateFilters"
		>
			<ItIcon name="reset-alt"/>
			<span>{{ $t('Обновить') }}</span>
		</el-button>

		<el-button
			v-if="!hideButtons.reset"
			type="primary"
			data-ta="reset-filters"
			plain
			class="layout-parts-filters__reset-btn"
			:disabled="disableButtons.reset"
			@click="resetFilters"
		>
			<ItIcon name="close"/>
			<span>{{ $t('Сбросить') }}</span>
		</el-button>

		<el-button
			v-if="hasToggleBtn"
			type="primary"
			data-ta="handle-filters"
			plain
			class="layout-parts-filters__toggle-btn"
			@click="toggleAdditional"
		>
			<ItIcon name="filter"/>
			<span v-if="isHideFiltersBtn">{{ $t('Скрыть фильтры') }}</span>
			<span v-else>{{ $t('Фильтры') }}</span>
			<div
				v-if="selectedAdditionalFilters && !isHideFiltersBtn"
				class="layout-parts-filters__toggle-btn-badge"
			>
				{{ selectedAdditionalFilters }}
			</div>
		</el-button>
	</div>
</template>

<script>
export default {
	name: 'LayoutPartFiltersButtons',

	props: {
		hasToggleBtn: {
			type: Boolean,
			default: false,
		},
		isHideFiltersBtn: {
			type: Boolean,
			default: false,
		},
		selectedAdditionalFilters: {
			type: Number,
			default: 0,
		},
		hideButtons: {
			type: Object,
			default: () => ({}),
		},
		disableButtons: {
			type: Object,
			default: () => ({}),
		},
	},

	methods: {
		updateFilters() {
			this.$emit('update-filters')
		},
		resetFilters() {
			this.$emit('reset-filters')
		},
		toggleAdditional() {
			this.$emit('toggle-additional')
		},
	},
}
</script>