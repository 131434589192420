<template>
	<ComPopover
		ref="popover"
		width="692"
		:has-actions="hasActions"
		:offset="10"
		:is-disabled-confirm="isDisabledConfirm"
		:action-confirm="$t('Добавить')"
		class="part-order-unmarked-goods"
		@hide="onHide"
		@show="showPopover"
		@confirm="confirmPopover"
	>
		<template #button>
			<el-button
				ref="btnPopover"
				type="primary"
				plain
				class="part-order-unmarked-goods__btn"
				data-ta="order-unmarked-btn"
			>
				{{ $t('Не маркируемые товары') }}
			</el-button>
		</template>

		<template #main>
			<div
				data-ta="order-unmarked-goods"
				class="order-unmarked-goods"
				@click.stop="$noop"
			>
				<el-input
					ref="searchInput"
					v-model="searchString"
					:placeholder="`${$t('Искать немаркируемый товар')}`"
					size="default"
					data-ta="order-unmarked-search"
					@update:modelValue="$emit('searchString', searchString)"
				>
					<template #suffix><ItIcon

						name="search"
						class="fill-text-placeholder"
					/></template>
				</el-input>

				<ComTableExpandable
					ref="unmarkedGoodsTable"
					:columns="columns"
					:items="unmarkedGoodsFiltered"
					:stripe="true"
					:expandable="false"
					:empty-text="`${'Не маркируемые товары закончились'}`"
					:row-class-name="getRowClass"
					height="280px"
					class="mt-4"
				>
					<template #model="{ row }">
						<span
							:class="row.count ? 'font-semi-bold color-primary' : ''"
						>{{ row.model }}</span>
					</template>

					<template #retailPrice="{ row }">
						{{ formatCurrency(row.retailPrice, $i18n.locale) }}
					</template>

					<template #amount="{ row }">
						<ComOrderTableQuantity
							v-model="row.count"
							:max="getMaxCount(row)"
							:min="0"
							:row="row"
							:changable="true"
							:is-count-updating="isCountUpdating"
							from="unmarkedGoods"
							data-ta="order-service-input"
							@update="onAmountChange(row, $event)"
						/>
					</template>

					<template #discounts="{ row }">
						<ComOrderTableDiscounts
							:discounts="row.discounts"
							@deleteDiscount="deleteDiscount(row.id)"
						/>
					</template>

					<template #discountPrice="{ row }">
						{{ formatCurrency(row.discountPrice, $i18n.locale) }}
					</template>
				</ComTableExpandable>

				<div v-if="showTotalCost" class="order-unmarked-goods__selected">
					<ComOrderTags
						:tags="selectedTags"
						:deletable="true"
						style="width: 75%"
						@click="clickOnTag"
						@delete="deleteSelectedItem"
					/>

					<div class="order-unmarked-goods__amount nowrap">
						<span class="order-unmarked-goods__total">{{ $t('Всего') }}</span>
						<span
							:key="updateTotal"
							class="order-unmarked-goods__amount-total color-text-primary"
							data-ta="order-unmarked-total"
						>{{ formatCurrency(totalCost, $i18n.locale) }}</span>
					</div>
				</div>
			</div>
		</template>
	</ComPopover>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'
import ComTableExpandable from '~/components/ComTableExpandable.vue'
import { formatCurrency } from '~/utils/currencies'
import ComPopover from '~/components/ComPopover'
import ComOrderTags from '../components/ComOrderTags.vue'
import ComOrderTableQuantity from '../components/ComOrderTable/ComOrderTableQuantity.vue'
import ComOrderTableDiscounts from '../components/ComOrderTable/ComOrderTableDiscounts.vue'
import { unmarkedGoodsColumns } from '../data/unmarkedGoodsColumns'
import { registerPopover, unregisterPopover } from '../data/clickOutsidePopover'

export default {
	name: 'PartOrderUnmarkedGoods',

	components: {
		ComPopover,
		ComTableExpandable,
		ComOrderTags,
		ComOrderTableQuantity,
		ComOrderTableDiscounts,
	},

	inject: ['setCartLoading'],

	data() {
		return {
			columns: unmarkedGoodsColumns,
			prop: 'unmarkedGoods',
			hasActions: true,
			searchString: '',
			unmarkedGoods: [],
			updateTotal: 0,
			throttleTO: null,
			isCountUpdating: false,
		}
	},

	computed: {
		...mapState('orders', [
			'cartUid',
			'orderErrors',
		]),
		...mapGetters('orders', [
			'gUnmarkedGoods',
			'gCartItems', // id, currentQuantity
		]),
		selectedUnmarkedGoods() {
			return this.unmarkedGoods
				.filter(item => item.count > 0)
		},
		selectedTags() {
			return this.selectedUnmarkedGoods
				.map(tag => ({
					...tag,
					name: tag.model,
					amount: tag.count,
				}))
		},
		unmarkedGoodsFiltered() {
			const substring = this.searchString.toLowerCase()
			return this.unmarkedGoods
				.filter(good => good.model.toLowerCase().includes(substring))
		},
		totalCost() {
			this.updateTotal // eslint-disable-line
			return this.selectedUnmarkedGoods
				.map(item => item.discountPrice)
				.reduce((prev, current) => prev + current, 0)
		},

		showTotalCost() {
			return this.selectedUnmarkedGoods.length
		},
		isDisabledConfirm() {
			if (this.orderErrors?.unmarkedGoods) {
				const unmarkedGoodsErrors = Object.values(this.orderErrors.unmarkedGoods)
				if (unmarkedGoodsErrors.includes(true)) return true
			}

			return !this.selectedUnmarkedGoods.length
		},
	},

	watch: {
		gUnmarkedGoods(val) {
			if (val) { this.unmarkedGoods = [...this.gUnmarkedGoods] }
		},
	},

	mounted() {
		registerPopover({
			group: 'unmarkedGoods',
			ref: this.$refs.popover,
			closeFn: this.close,
			triggerRef: this.$refs.btnPopover,
		})
	},

	beforeUnmount() {
		unregisterPopover(this.$refs.popover)
	},

	methods: {
		formatCurrency,
		...mapMutations('orders', [
			'mOrderErrors',
		]),
		...mapActions('orders', [
			'aAddAllUnmarkedGoods',
			'aDeleteGoods',
			'aUnmarkedGoods',
			'aUnmarkedDeleteDiscount',
			'aUnmarkedGoodsAmount',
		]),

		async showPopover() {
			await this.$nextTick()
			this.$refs.searchInput.focus()
		},

		onHide() {
			this.searchString = ''
		},

		async addAllGoods() {
			this.setCartLoading(true)

			const data = this.unmarkedGoods
				.map(item => {
					const count = item.count - (item.itemsInCart?.length || 0)
					if (count < 0) {
						const removeItems = item.itemsInCart.slice(0, -count)
						removeItems.forEach(remItem => this.aDeleteGoods({
							id: remItem.id,
							uid: this.cartUid,
						}))
					}
					return {
						model: item.model,
						count,
						retailPrice: item.retailPrice,
					}
				})
				.filter(item => item.count > 0)

			const response = await this.aAddAllUnmarkedGoods({ uid: this.cartUid, data })

			if (response?.status === 200) {
				await this.aUnmarkedGoods(this.cartUid)
				this.$notify({ title: this.$t('Добавлено в корзину'), type: 'success' })
			}

			this.setCartLoading(false)
		},

		deleteSelectedItem(item) {
			this.mOrderErrors({
				from: 'unmarkedGoods',
				error: {
					[item.id]: false,
				},
			})

			this.onAmountChange(item, 0)
		},

		clickOnTag(tag) {
			const { model } = tag
			const clickedItemIndex = this.unmarkedGoods.findIndex(goods => goods.model === model)

			const wrapper = this.$refs.unmarkedGoodsTable.$el.querySelector('.el-table__body-wrapper')
			const trs = wrapper.querySelectorAll('tbody tr')
			wrapper.scrollTo({
				top: trs[clickedItemIndex].offsetTop,
				left: 0,
				behavior: 'smooth',
			})
		},

		async deleteDiscount(id) {
			await this.aUnmarkedDeleteDiscount({
				uid: this.cartUid,
				id,
			})

			this.aUnmarkedGoods(this.cartUid)
		},

		async confirmPopover() {
			this.addAllGoods()
			this.close()
		},

		async onAmountChange(row, count) {
			if (row.id) {
				this.isCountUpdating = true
				await this.aUnmarkedGoodsAmount({
					uid: this.cartUid,
					id: row.id,
					count,
				})

				this.aUnmarkedGoods(this.cartUid)
				this.$nextTick(() => { this.isCountUpdating = false })
			}
		},
		close() {
			this.$refs.popover.close()
		},
		getMaxCount(row) {
			return row.maxCount > 100
				? 100
				: row.maxCount
		},

		getRowClass({ row }) {
			let resultClass = ''

			if (row.count) {
				resultClass = 'selected-row'
			}

			return resultClass
		},
	},
}
</script>

<style lang="scss">
.part-order-unmarked-goods {
	&__btn {
		width: 100%;
		font-weight: 600;
	}
}

.order-unmarked-goods {
	&__selected {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		margin-top: 24px;
		padding: 0 5px;
	}

	&__amount {
		display: flex;
		align-items: center;
		gap: 12px;
		max-height: 28px;
	}

	&__amount-total {
		font-weight: 700;
		font-size: 25px;
	}

	&__total {
		margin-top: 7px;
	}

	.input {
		width: 76px;
	}

	.icon {
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.el-input-group__append {
		padding: 7px 2px 3px 8px !important;
		width: 44% !important;
	}

	.el-input-number--mini .el-input-number__increase, .el-input-number--mini .el-input-number__decrease {
		width: 32px;
	}

	.el-table th {
		background-color: var(--el-background-color-dark);
	}

	.el-table__row--striped td {
		background: var(--el-background-color-base);
	}

	.selected-row {
		background-color: var(--el-border-color-extra-light);
	}
}
</style>