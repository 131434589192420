import { getFioFull } from '~/utils/formatters'

export default {
	mUsersAssignable(state, payload) {
		state.usersAssignable = payload
	},
	mUser(state, payload) {
		state.user = {
			...payload,
			fio: getFioFull(payload),
		}
	},
	mUsers(state, payload) {
		state.users = payload.content.map(user => ({
			...user,
			// TODO rename to fullName
			fio: getFioFull(user),
		}))
	},
	mUsersNotFired(state, payload) {
		state.usersNotFired = payload.map(user => ({
			...user,
			// TODO rename to fullName
			fio: getFioFull(user),
		}))
	},
	mNewUser(state) {
		state.current = {}
		state.loading = false
	},
	mUsersRoles(state, payload) {
		state.roles = payload
	},
	mUserGroups(state, payload) {
		state.userGroups = payload
	},
	mUsersUserGroups(state, payload) {
		state.usersUserGroups = payload
	},
	mUsersDepartments(state, payload) {
		state.usersDepartments = payload.content
	},
	mUsersInDepartmentByRoles(state, payload) {
		state.usersInDepartmentByRoles = payload
	},
	mUsersDepartmentsAppend(state, payload) {
		state.usersDepartments = state.usersDepartments.concat(payload.content)
	},
	mSelectedSeller(state, payload) {
		const user = payload || state.current
		if (!user) return
		state.selectedSeller = {
			...user,
			fullName: user.fullName || getFioFull(user),
		}
	},
	mAvailableAssistants(state, payload) {
		state.availableAssistants = payload
	},
	mDoctorCategories(state, payload) {
		state.doctorCategories = payload
	},
	mMedicalPositions(state, payload) {
		state.medicalPositions = payload
	},
}