<template>
	<div
		id="layout-page-tabs"
		:key="update"
		v-loading="isLoading"
	>
		<div class="d-flex justify-content-between mb-4">

			<LayoutPartTitle
				v-if="title"
				v-bind="layoutParams"
				@on-return="onReturn"
			/>

			<LayoutPartActionButtons
				v-if="topButtons && !isLoading && !buttonsShowOnlyBottom"
				ref="topControls"
				:use-buttons="topButtons"
				:is-edit="isEdit"
				:is-deleted="isDeleted"
				:is-changed="isChanged"
				:delete-confirmation-text="layoutParams.deleteConfirmation"
				:disable-buttons="disableButtons"
				:confirm-button="layoutParams.confirmButton"
				:delete-confirmation-title="layoutParams.deleteConfirmationTitle"
				size="default"
				@on-cancel="onCancel"
				@on-edit="onEdit"
				@on-delete="onDelete"
				@on-restore="onRestore"
				@on-save="onSave"
			/>
		</div>

		<LayoutPartTabsWrapper
			v-if="!isLoading"
			mode="component"
			:tabs="tabs"
			:is-edit="isEdit"
			:hide-once-tab="layoutParams.hideOnceTab"
			@register-tabs-methods="registerTabsMethods"
		/>

		<component
			:is="rawPage"
			id="layout-page-tabs__Page"
			@on-mounted="onMountedPage"
			@on-layout-props-update="layoutParamsUpdate"
			@register-page-methods="registerPageMethods"
			@on-update-tabs-rules="onUpdateTabsRules"
		/>

		<div class="mt-8 d-flex justify-content-flex-end">
			<LayoutPartActionButtons
				v-if="(mxShowBottomButtons && !isLoading) || bottomButtons"
				ref="bottomControls"
				:use-buttons="bottomButtons"
				:is-edit="isEdit"
				:is-deleted="isDeleted"
				:is-changed="isChanged"
				:delete-confirmation-text="layoutParams.deleteConfirmation"
				:disable-buttons="disableButtons"
				:confirm-button="layoutParams.confirmButton"
				:delete-confirmation-title="layoutParams.deleteConfirmationTitle"
				size="default"
				@on-cancel="onCancel"
				@on-edit="onEdit"
				@on-delete="onDelete"
				@on-restore="onRestore"
				@on-save="onSave"
			/>
		</div>
	</div>
</template>

<script>
import { markRaw } from 'vue'
import { mapMutations, mapGetters, mapState } from 'vuex'
import mxLayouts from '~/mixins/layouts/mxLayouts'
import LayoutPartTitle from '~/layout/parts/LayoutPartTitle.vue'
import LayoutPartActionButtons from '~/layout/parts/LayoutPartActionButtons.vue'
import LayoutPartTabsWrapper from '~/layout/parts/LayoutPartTabsWrapper.vue'

import { DEFAULT_LAYOUT_PAGE_TABS_PARAMS } from './data/defaults'

export default {
	name: 'LayoutPageTabs',
	components: {
		LayoutPartTitle,
		LayoutPartActionButtons,
		LayoutPartTabsWrapper,
	},
	mixins: [mxLayouts],
	provide() {
		return {
			layoutMethods: {
				forceUpdate: this.updateLayout,
				registerComponent: this.registerComponent,
				unregisterComponent: this.unregisterComponent,
				updateParams: this.updateParams,
			},
		}
	},
	async beforeRouteLeave(to, from, next) {
		const res = await this.mxConfirmIfFormChanged()
		next(Boolean(res))
	},
	props: {
		Page: {  // eslint-disable-line
			type: [Function, Object],
			required: true,
		},
	},
	data() {
		return {
			isLoading: true,
			isSavingProceed: false,
			isDeletionProceed: false,
			isResoreProceed: false,
			deleteConfirmation: '',
			update: 0,
			layoutParams: DEFAULT_LAYOUT_PAGE_TABS_PARAMS(),
			tabs: null,
		}
	},
	computed: {
		...mapState('layout', [
			'tabsData',
			'isEdit',
			'isChanged',
			'isDeleted',
			'activeTab',
			'changedTabs',
		]),
		...mapGetters('layout', [
			'gHasChangedTabs',
		]),
		rawPage() {
			return markRaw(this.Page)
		},
		title() {
			return this.layoutParams.title || ''
		},
		disableButtons() {
			const buttons = this.layoutParams?.disableButtons || {}
			return {
				...buttons,
				save: !this.isChanged || this.isSavingProceed,
				delete: buttons.delete || this.isDeletionProceed,
				restore: buttons.restore || this.isResoreProceed,
			}
		},
		topButtons() {
			return this.layoutParams.buttons?.top || this.layoutParams.buttons
		},
		bottomButtons() {
			return this.layoutParams.buttons?.bottom || this.layoutParams.buttons
		},
		buttonsShowOnlyBottom() {
			return this.layoutParams.buttonsShowOnlyBottom
		},
	},
	watch: {
		activeTab: 'activateTab',
	},
	created() {
		this.mLayoutDropState()
	},
	methods: {
		...mapMutations('layout', [
			'mLayoutDropState',
			'mSetChangedState',
			'mSetEditState',
			'mSetDeletedState',
			'mRestorePageData',
		]),

		layoutParamsUpdate(layoutParams) {
			this.layoutParams = {
				...this.layoutParams,
				...layoutParams,
				hasReturnButton: typeof layoutParams.hasReturnButton === 'boolean' ? layoutParams.hasReturnButton : this.hasReturnButton,
			}

			this.mSetDeletedState(Boolean(layoutParams.isDeleted))

			let { title, browserTitle } = layoutParams // eslint-disable-line
			if (!browserTitle && Array.isArray(title)) {
				title = title[1] || title[0]
			}
			this.setDocumentTitle(browserTitle || title)
		},

		onMountedPage(layoutParams) {
			if (!this.pageMethods.tabs) {
				throw new Error('Компонент страницы должен содержать массив табов в переменной tabs')
			}
			this.mSetEditState(false)

			this.tabs = layoutParams.tabs
			this.layoutParamsUpdate(layoutParams)

			this.isLoading = false

			this.$nextTick(() => {
				this.mxCheckupButtonsMethodsExistsOnThePage()
				this.mxCheckupBottomButtonsVisibility()
			})
		},

		setButtonsState(payload) {
			this.pageButtonsState = {
				...this.pageButtonsState,
				...payload,
			}
		},

		onEdit() {
			if (this.tabsMethods.beforeEdit) {
				this.tabsMethods.beforeEdit()
			}

			this.pageMethods.edit()
			this.mSetEditState(true)
		},

		async onCancel() {
			if (this.tabsMethods.beforeCancel) {
				await this.tabsMethods.beforeCancel()
			}

			if (this.isChanged) {
				const cancelEdit = await this.mxCancelFormEdit()

				if (cancelEdit) {
					await this.pageMethods.mxInit()
					await this.pageMethods.cancel()
					this.mSetChangedState(false)
				}
			}
			this.mSetEditState(false)

			if (this.tabsMethods.cancelled) {
				await this.tabsMethods.cancelled()
			}
		},

		async onDelete() {
			if (this.tabsMethods.beforeDelete) {
				await this.tabsMethods.beforeDelete()
			}

			this.isDeletionProceed = true
			const deleteResult = await this.pageMethods.delete()
			setTimeout(() => {
				this.isDeletionProceed = false
			}, 500)

			if (deleteResult) {
				if (this.layoutParams.deleteSuccessMessage) {
					this.$notify({ title: this.layoutParams.deleteSuccessMessage, type: 'success' })
				}
				this.mSetEditState(false)
				this.mSetChangedState(false)
				this.mSetDeletedState(true)
				if (this.tabsMethods.deleted) {
					await this.tabsMethods.deleted()
				}
			} else if (this.layoutParams.deleteErrorMessage) {
				this.$notify({ title: this.layoutParams.deleteErrorMessage, type: 'error' })
			}
		},

		async onRestore() {
			if (this.tabsMethods.beforeRestore) {
				await this.tabsMethods.beforeRestore()
			}

			this.isRestoreProceed = true
			const restoreResult = await this.pageMethods.restore()
			setTimeout(() => {
				this.isRestoreProceed = false
			}, 500)

			if (restoreResult) {
				if (this.layoutParams.restoreSuccessMessage) {
					this.$notify({ title: this.layoutParams.restoreSuccessMessage, type: 'success' })
				}
				this.mSetEditState(false)
				this.mSetChangedState(false)
				this.mSetDeletedState(false)
				if (this.tabsMethods.restored) {
					await this.tabsMethods.restored()
				}
			} else if (this.layoutParams.restoreErrorMessage) {
				this.$notify({ title: this.layoutParams.restoreErrorMessage, type: 'error' })
			}
		},

		async onSave() {
			const validationState = await this.validateTabs()

			if (!validationState) {
				return
			}

			this.isSavingProceed = true
			const saveResult = await this.pageMethods.save()
			setTimeout(() => {
				this.isSavingProceed = false
			}, 500)

			if (saveResult === undefined) {
				throw new Error('Ф-я save() должна возвращать результат сохранения')
			}

			if (saveResult) {
				if (this.layoutParams.saveSuccessMessage) {
					this.$notify({ title: this.layoutParams.saveSuccessMessage, type: 'success' })
				}
				await this.pageMethods.mxInit()
				this.mSetEditState(false)
				this.mSetChangedState(false)

				if (this.tabsMethods.saved) {
					this.tabsMethods.saved()
				}
			} else if (this.layoutParams.saveErrorMessage) {
				this.$notify({ title: this.layoutParams.saveErrorMessage, type: 'error' })
			}
		},

		onReturn() {
			this.mxReturn()
		},

		async getInvalidTabs() {
			const validationResults = await Promise.allSettled(this.tabs
				.filter(tab => tab.rules)
				.map(tab => this.mxPreValidation(this.tabsData[tab.name], tab.rules || [])))

			return validationResults.map((res, index) => {
				if (res.status === 'fulfilled') {
					return null
				}
				return this.tabs[index].name
			}).filter(tab => Boolean(tab))
		},

		async validateTabs() {
			const invalidTabs = await this.getInvalidTabs()
			if (invalidTabs.length) {
				const invalidTab = this.tabs.find(tab => tab.name === invalidTabs[0])
				this.activateTab(invalidTab.name)
				this.$nextTick(this.tabsMethods.validation)
				return false
			}
			return true
		},

		activateTab(tabName) {
			if (tabName && this.tabsMethods.activateTab) {
				this.tabsMethods.activateTab(tabName)
			}
		},

		updateLayout() {
			this.update += 1
		},

		registerTabsMethods(methods) {
			this.tabsMethods = methods
		},

		registerPageMethods(methods) {
			this.pageMethods = methods
		},

		registerComponent(componentCtx) {
			if (!componentCtx.layoutParams) {
				throw new Error('Компонент страницы должен содержать объект с параметрами шаблона layoutParams')
			}
			if (!componentCtx.loadData) {
				throw new Error('Компонент страницы должен содержать метод loadData(), который вызывается из mxInit')
			}

			this.mxLayoutParamsUpdate(componentCtx.layoutParams)

			componentCtx.layoutParams = {
				...DEFAULT_LAYOUT_PAGE_TABS_PARAMS(),
				...componentCtx.layoutParams,
			}

			this.componentsMethods = {
				return: componentCtx.return,
			}
		},

		unregisterComponent() {
			this.componentsMethods = {}
			this.componentsWatchers.forEach(fn => fn())
			this.componentsWatchers = []
		},

		updateParams(layoutParams) {
			this.mxLayoutParamsUpdate(layoutParams)
		},

		onUpdateTabsRules({ tab, rules }) {
			if (!this.tabs) return
			const updatableTab = this.tabs.find(item => item.name === tab)
			updatableTab.rules = rules
		},
	},
}
</script>

<style lang="scss">
	#layout-page-tabs {
		min-height: 700px;
	}
</style>