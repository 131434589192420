export default {
	mSalesPlanStatistics(state, payload) {
		const usersEfficiency = payload.usersEfficiency || []
		const top = [...usersEfficiency]
			.sort((a, b) => b.contribution - a.contribution)
			.slice(0, 3)
			.map((item, index) => ({ id: item.user.id, index: index + 1 }))

		payload.usersEfficiency = usersEfficiency.map(item => {
			const placeInfo = top.find(data => data.id === item.user.id)
			return placeInfo ? { ...item, place: placeInfo.index } : item
		})
		Object.entries(payload).forEach(([key, value]) => {
			if (value === null) {
				payload[key] = 0
			}
		})
		state.salesPlanStatistics = payload
	},
	mDepartmentSalesPlans(state, payload) {
		state.departmentSalesPlans = payload
	},
	mSalesPlanCurrent(state, payload) {
		state.current = payload
		if (payload) {
			const {
				plannedSum,
				normCurrentSum,
				currentSum,
				percentageOfCompletionSum,
			} = payload

			if (plannedSum) {
				const color = currentSum >= normCurrentSum ? 'green' : 'red'
				window.triggerOldFrontMethod('salesPlanF', 'showProgress', +percentageOfCompletionSum, color)
			}
		}
	},
}