<!-- eslint-disable vue/no-v-html -->
<template>
	<el-popover
		placement="top"
		width="219"
		trigger="click"
		popper-class="part-input-popover"
	>
		<p
			v-if="popover.title"
			class="mb-2 part-input-popover__title"
		>{{ popover.title }}</p>

		<el-input
			v-if="mask"
			v-model="newText"
			v-mask="mask"
			:placeholder="popover.placeholder"
			:data-ta="name + '__popover'"
			:clearable="clearable"
			:size="size"
			:type="fieldType"
			class="mb-4"
			:class="{
				'is-error': error || validationError,
			}"
			@update:modelValue="validation"
		/>
		<el-input
			v-else
			v-model="newText"
			:placeholder="popover.placeholder"
			:data-ta="name + '__popover'"
			:maxlength="maxLength"
			:clearable="clearable"
			:size="size"
			:type="fieldType"
			class="mb-4"
			:class="{
				'is-error': error || validationError,
			}"
			@update:modelValue="validation"
		/>

		<div v-if="error || validationError" class="el-form-item__error popover-error">{{ error || validationError }}</div>
		<div
			v-if="warn"
			class="el-form-item__warn popover-warn mb-3 d-flex"
		>
			<div class="mr-2">
				<ItIcon
					name="warning-solid"
					class="return-button-icon fill-warning"
				/>
			</div>
			<div>{{ warn }}</div>
		</div>

		<div
			style="text-align: right;"
			:class="{
				'mt-2': error || validationError,
			}"
		>
			<el-button
				size="small"
				:data-ta="name + '__popover-cancel'"
				@click.stop="onCancelEdit"
			>{{ $t('Отмена') }}</el-button>
			<el-button
				type="primary"
				size="small"
				class="ml-3"
				:disabled="Boolean(error || validationError)"
				:data-ta="name + '__popover-accept'"
				@click.stop="onAcceptInput"
			>{{ $t('Ок') }}</el-button>
		</div>

		<template #reference>
			<el-button
				type="primary"
				plain
				:data-ta="name + '__edit-btn'"
				class="part-input-popover__action-btn ml-3"
			><ItIcon
				name="pencil"
				class="return-button-icon fill-primary"
			/></el-button>
		</template>
	</el-popover>
</template>

<script>

import { mask } from 'vue-the-mask'
import Validations from '~/utils/validation'

export default {
	name: 'PartInputPopover',

	directives: {
		mask,
	},
	props: {
		name: {
			type: String,
			required: true,
		},
		popover: {
			type: Object,
			required: true,
		},
		rules: {
			type: Array,
			default() { return [] },
		},
		mask: {
			type: String,
			default: null,
		},
		size: {
			type: String,
			default: null,
		},
		maxLength: {
			type: Number,
			default: null,
		},
		fieldType: {
			type: String,
			default: null,
		},
		clearable: {
			type: Boolean,
			default: true,
		},
		warn: {
			type: String,
			default: null,
		},
		error: {
			type: String,
			default: null,
		},
	},

	data() {
		return {
			newText: '',
			validationError: null,
		}
	},
	methods: {
		closePopover() {
			document.body.click()
		},
		onCancelEdit() {
			this.validationError = false
			this.closePopover()
			this.$emit('cancelEdit')
		},
		async validation(value) {
			this.validationError = await Validations(value, this.rules)
		},
		async onAcceptInput() {
			this.validationError = await Validations(this.newText, this.rules)

			if (!this.validationError) {
				this.$emit('acceptEdit', this.newText, this.closePopover)
			}
		},
	},
}
</script>

<style lang="scss">
.part-input-popover {

	.popover-warn,
	.part-input-popover__title {
		word-break: keep-all;
		text-align: left;
	}

	.el-input-group__append {
		padding: 0 3px;
	}

	&__action-btn {
		height: 28px;
		width: 28px;
		padding-left: 8px;
		padding-right: 8px;
	}

	.is-error {
		.el-input__inner {
			border-color: var(--el-color-danger);
		}
	}
}
</style>