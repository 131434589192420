export default function () {
	return {
		legalEntities: [],
		legalEntitiesPagination: {},
		legalEntitiesLoading: false,
		taxSchemes: [],
		taxSchemesLoading: false,
		license: {},
		licenseLoading: false,
		legalEntityDepartments: [],
		legalEntityDepartmentsPagination: {},
		legalEntityDepartmentsLoading: false,
		legalEntity: {},
		taxRules: {},
		servicesTypes: [],
		ordersTypes: [],
		productsTypes: [],
		accessoriesCategories: [],
		sections: [],
		servesTypesGroups: [],
		unusedDepartments: [],
	}
}