import VuexHttp from '../../vuexHttpService'

const vHttp = new VuexHttp('integrationSettings')

export default {
	async aIntegratedSuplier(_, id) {
		return vHttp.GET(this, {
			url: `integrated-suppliers/${id}`,
			mutation: 'mIntegratedSuplier',
		})
	},
	async aLegalEntities() {
		return vHttp.GET(this, {
			url: `legal-entities?closed=false&size=1000`,
			mutation: 'mLegalEntities',
		})
	},
	async aSettings(_, { integratedSupplierId, legalEntityId }) {
		return vHttp.GET(this, {
			url: `integrated-suppliers/${integratedSupplierId}/legal-entities/${legalEntityId}/settings`,
			mutation: 'mSettings',
		})
	},
	async aSetSettings(_, { integratedSupplierId, legalEntityId, requestMethod, data }) {
		return vHttp[requestMethod](this, {
			url: `integrated-suppliers/${integratedSupplierId}/legal-entities/${legalEntityId}/settings`,
			data,
		})
	},
}