export default function getUrl(url) {
	if (typeof url === 'string') {
		return url
	}

	const queryStr = Object.entries(url.query || {}).reduce((query, [k, v]) => {
		if (Array.isArray(v)) {
			if (v.length > 0) query.push(`${k}=${encodeURIComponent(v)}`)
		} else if (v !== null) {
			query.push(`${k}=${encodeURIComponent(v)}`)
		}
		return query
	}, []).join('&')

	if (!queryStr) {
		return url.path
	}

	return `${url.path}?${queryStr}`
}