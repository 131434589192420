import i18n from '~/plugins/i18n'

export const servicesColumns = [
	{
		prop: 'name',
		label: i18n.global.t('Описание'),
		width: '210px',
		align: 'left',
	},
	{
		prop: 'retailPrice',
		label: i18n.global.t('Цена'),
		width: '72px',
		align: 'left',
	},
	{
		prop: 'amount',
		label: i18n.global.t('Кол-во'),
		width: '76px',
		align: 'left',
	},
	{
		prop: 'discounts',
		label: i18n.global.t('Скидка'),
		width: '210px',
		align: 'right',
	},
	{
		prop: 'discountPrice',
		label: i18n.global.t('Стоимость'),
		width: '100px',
		align: 'right',
	},
]