// from https://github.com/probil/v-mask
function maskit(value = '', mask = '', masked = true) {
	// 88001234567
	// +79211234567
	// 8 (812) 123-4567
	let iMask = 0
	let iValue = 0
	let output = ''
	const tokens = {
		'#': { pattern: /\d/ },
		X: { pattern: /[0-9a-zA-Z]/ },
		S: { pattern: /[a-zA-Z]/ },
		A: { pattern: /[a-zA-Z]/, transform: v => v.toLocaleUpperCase() },
		a: { pattern: /[a-zA-Z]/, transform: v => v.toLocaleLowerCase() },
		'!': { escape: true },
	}
	while (iMask < mask.length && iValue < value.length) {
		let cMask = mask[iMask]
		const masker = tokens[cMask]
		const cValue = value[iValue]
		if (masker && !masker.escape) {
			if (masker.pattern.test(cValue)) {
				output += masker.transform ? masker.transform(cValue) : cValue
				iMask += 1
			}
			iValue += 1
		} else {
			if (masker && masker.escape) {
				iMask += 1 // take the next mask char and treat it as char
				cMask = mask[iMask]
			}
			if (masked) output += cMask
			if (cValue === cMask) iValue += 1 // user typed the same char
			iMask += 1
		}
	}

	// fix mask that ends with a char: (#)
	let restOutput = ''
	while (iMask < mask.length && masked) {
		const cMask = mask[iMask]
		if (tokens[cMask]) {
			restOutput = ''
			break
		}
		restOutput += cMask
		iMask += 1
	}

	return output + restOutput
}


export default function (val = '', mask) {
	const onlyDidgits = val.replace(/[\D]*/g, '')
	let tel = val
	if (onlyDidgits.startsWith('8')) {
		tel = onlyDidgits.substr(1)
		if (!tel) {
			// использовать первую цифру маски
			tel = mask.replace(/[\D]*/g, '')[0] || '' // eslint-disable-line
		}
	}
	return maskit(tel, mask)
}