import i18n from '~/plugins/i18n'
import { binaryOptions } from '~/helpers/common'

// Переводы в departments.xlsx

export default [{
	name: 'type',
	label: i18n.global.t('Тип'),
	type: 'select',
	column: 0,
	disabled: true,
}, {
	name: 'name',
	label: i18n.global.t('Название'),
	type: 'input',
	column: 0,
}, {
	name: 'id',
	label: i18n.global.t('Идентификатор'),
	type: 'input',
	column: 0,
	disabled: true,
}, {
	name: 'legalEntityId',
	label: i18n.global.t('Юридич. лицо'),
	type: 'select',
	linkPattern: '/legal-entity/%value%',
	column: 0,
}, {
	name: 'location',
	label: i18n.global.t('Адрес'),
	type: 'dadata',
	bound: 'address',
	column: 0,
}, {
	name: 'city',
	label: i18n.global.t('Город'),
	type: 'dadata',
	bound: 'city',
	column: 0,
	returnStringValue: true,
}, {
	name: 'phone',
	label: i18n.global.t('Телефон'),
	type: 'input',
	column: 0,
	maxLength: 30,
	showWordLimit: true,
}, {
	name: 'timeZone',
	label: i18n.global.t('Часовой пояс'),
	type: 'select',
	column: 0,
}, 
{
	name: 'description',
	label: i18n.global.t('Прочее'),
	type: 'input',
	column: 0,
	notFound: true,
}, {
	name: 'expectedGlassesMode',
	label: i18n.global.t('Оправа при изготовлении очков'),
	type: 'select',
	column: 0,
	options: [
		{ value: true, label: i18n.global.t('Заявка на оправу') },
		{ value: false, label: i18n.global.t('Просканированная оправа') },
	]
}, {
	name: 'departmentOid',
	label: 'OID структурного подразделения',
	type: 'input',
	maxLength: 50,
	showWordLimit: true,
	column: 0,
	infoTooltip: {
		iconName: 'info-outline',
		text: 'Значение необходимо взять из ФРМО',
	},
}, {
	name: 'departmentGuid',
	label: 'Guid',
	type: 'input',
	maxLength: 36,
	showWordLimit: true,
	column: 0,
}, {
	name: 'departmentLpuId',
	label: 'idLPU',
	type: 'input',
	maxLength: 36,
	showWordLimit: true,
	column: 0,
}, {
	name: 'frMode',
	label: i18n.global.t('Фиск. регистратор'),
	type: 'select',
	column: 1,
	options: binaryOptions(),
}, {
	name: 'hasServices',
	label: i18n.global.t('Оказывает услуги'),
	type: 'select',
	column: 1,
	options: binaryOptions(),
}, {
	name: 'otherDepartmentSearchId',
	label: i18n.global.t('Базовый для поиска'),
	type: 'select',
	column: 1,
	options: binaryOptions(),
}, {
	name: 'depotId',
	label: i18n.global.t('Базовый склад'),
	type: 'select',
	column: 1,
}, {
	name: 'productionId',
	label: i18n.global.t('Базовый цех'),
	type: 'select',
	column: 1,
}, {
	name: 'cashAccountId',
	label: i18n.global.t('Наличный счет'),
	type: 'select',
	column: 1,
}, {
	name: 'cardAccountId',
	label: i18n.global.t('Безналичный счет'),
	type: 'select',
	column: 1,
}, {
	name: 'collectionAccountId',
	label: i18n.global.t('Счёт для инкассации'),
	type: 'select',
	column: 1,
}, {
	name: 'grayAccountId',
	label: i18n.global.t('Подразделение'),
	type: 'select',
	column: 1,
}, {
	name: 'collectionGrayAccountId',
	label: i18n.global.t('Подарочный сертификат'),
	type: 'select',
	column: 1,
},
]