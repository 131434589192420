import jwtDecode from 'jwt-decode'
import { USER_AUTHORITIES } from '~/utils/consts'
import { getFioFull, getFioShort } from '~/utils/formatters'

import VuexHttp from '../../vuexHttpService'

const vHttp = new VuexHttp('user')

function defaultState() {
	return {
		id: null,
		session: null,
		company: '',
		login: null,
		firstName: null,
		familyName: null,
		patronymicName: null,
		currentDepartmentId: null,
		userGroup: null,
		authorities: [],
		optima2BackendUrl: localStorage.getItem('user/optima2BackendUrl') || '',
		optimaLegacyBackendUrl: localStorage.getItem('user/optimaLegacyBackendUrl') || '',
		applicationUrl: localStorage.getItem('user/applicationUrl') || '',
		roles: null,
	}
}

export default {
	namespaced: true,
	state: {
		id: null,
		session: null,
		company: '',
		locale: 'ru',
		isHelpMode: true,
		login: null,
		firstName: null,
		familyName: null,
		patronymicName: null,
		currentDepartmentId: null,
		userGroup: null,
		authorities: [],
		optima2BackendUrl: localStorage.getItem('user/optima2BackendUrl') || '',
		optimaLegacyBackendUrl: localStorage.getItem('user/optimaLegacyBackendUrl') || '',
		applicationUrl: localStorage.getItem('user/applicationUrl') || '',
		componentsState: {},
		componentsStateSession: {},
		userSettings: {},
		currentUser: {},
		statistics: {},
	},
	getters: {
		gUser(state) {
			const user = { id: state.id } // getter actuator
			Object.keys(defaultState()).forEach(key => {
				user[key] = state[key]
			})
			return user
		},
		gFioFull: (state, _, rootState) => getFioFull(state, rootState.settings.firstNameBeforeFamilyName),
		gFioShort: state => getFioShort(state),
		gComponentsState: state => ({
			...state.componentsState,
			...state.componentsStateSession,
		}),
		gRoles(state) {
			return {
				isManager: state.authorities.includes(USER_AUTHORITIES.ROLE_MANAGER),
				isBonusAccumulationManager: state.authorities.includes(USER_AUTHORITIES.ROLE_BONUS_ACCUMULATION_MANAGER),
				isNotificationManager: state.authorities.includes(USER_AUTHORITIES.ROLE_NOTIFICATION_MANAGER),
				isAccountant: state.authorities.includes(USER_AUTHORITIES.ROLE_ACCOUNTANT),
				isDoctor: state.authorities.includes(USER_AUTHORITIES.ROLE_DOCTOR),
				isDepot: state.authorities.includes(USER_AUTHORITIES.ROLE_DEPOT),
				isProduction: state.authorities.includes(USER_AUTHORITIES.ROLE_PRODUCTION),
				isStore: state.authorities.includes(USER_AUTHORITIES.ROLE_STORE),
				isAdmin: state.authorities.includes(USER_AUTHORITIES.ROLE_ADMIN),
				isTopManager: state.authorities.includes(USER_AUTHORITIES.ROLE_TOP_MANAGER),
				isSecretary: state.authorities.includes(USER_AUTHORITIES.ROLE_SECRETARY),
				isDoctorStore: state.authorities.includes(USER_AUTHORITIES.ROLE_DOCTOR_STORE),
				isOptClient: state.authorities.includes(USER_AUTHORITIES.ROLE_OPT_CLIENT),
				isItigrisUser: state.authorities.includes(USER_AUTHORITIES.ROLE_ITIGRIS_USER),
				isMergeClients: state.authorities.includes(USER_AUTHORITIES.ROLE_MERGE_CLIENTS),
			}
		},
		// USER_AUTHORITIES
		isManager: (state, getters) => getters.gRoles.isManager,
		isBonusAccumulationManager: (state, getters) => getters.gRoles.isBonusAccumulationManager,
		isNotificationManager: (state, getters) => getters.gRoles.isNotificationManager,
		isAccountant: (state, getters) => getters.gRoles.isAccountant,
		isDoctor: (state, getters) => getters.gRoles.isDoctor,
		isDepot: (state, getters) => getters.gRoles.isDepot,
		isProduction: (state, getters) => getters.gRoles.isProduction,
		isStore: (state, getters) => getters.gRoles.isStore,
		isAdmin: (state, getters) => getters.gRoles.isAdmin,
		isTopManager: (state, getters) => getters.gRoles.isTopManager,
		isSecretary: (state, getters) => getters.gRoles.isSecretary,
		isDoctorStore: (state, getters) => getters.gRoles.isDoctorStore,
		isOptClient: (state, getters) => getters.gRoles.isOptClient,
		isItigrisUser: (state, getters) => getters.gRoles.isItigrisUser,
		// Права доступа
		// News
		canCreateNews: (state, getters) => getters.isTopManager || getters.isManager,
		canEditNews: (state, getters) => getters.isTopManager || getters.isManager,
		canReadNews: (state, getters) => !getters.isOptClient,
		// Balance
		canReadBalance: (state, getters) => getters.isTopManager || getters.isAdmin,
		// ApplicationSettings
		canReadApplicationSettings: (state, getters) => getters.isAdmin,
		// NotificationSettings
		canReadNotificationSettings: (state, getters) => getters.isTopManager || getters.isAdmin || getters.isNotificationManager,
		// Bonus
		canCreateBonus: (state, getters) => getters.isTopManager || getters.isManager,
		canEditBonus: (state, getters) => getters.isTopManager || getters.isManager,
		canReadBonus: (state, getters) => getters.isTopManager || getters.isManager || getters.isAccountant,
		canReadBonusByFilters: (state, getters) => getters.isBonusAccumulationManager,
		canEditBonusSettings: (state, getters) => getters.isTopManager || getters.isManager,
		// Report
		canReadReport: (state, getters) => getters.isTopManager || getters.isManager || getters.isAccountant,
		canMergeClients: (state, getters) => getters.gRoles.isMergeClients,
	},
	mutations: {
		setUserData(state, userData) {
			Object.keys(userData).forEach(propName => {
				state[propName] = userData[propName]
			})
		},
		mCurrentUser(state, data) {
			state.currentUser = data
		},
		setAuthorities(state, authorities) {
			state.authorities = authorities.map(item => item.authority)
		},
		setLocale(state, locale) {
			state.locale = locale
		},
		setBackendUrls(state, urls) {
			state.optima2BackendUrl = urls.optima2BackendUrl
			state.optimaLegacyBackendUrl = urls.optimaLegacyBackendUrl
			localStorage.setItem('user/optima2BackendUrl', urls.optima2BackendUrl)
			localStorage.setItem('user/optimaLegacyBackendUrl', urls.optimaLegacyBackendUrl)
		},
		setApplicationUrl(state, { applicationUrl }) {
			state.applicationUrl = applicationUrl
			localStorage.setItem('user/applicationUrl', applicationUrl)
		},
		dropState(state) {
			Object.assign(state, defaultState())
		},
		mSetComponentState(state, payload) {
			Object.entries(payload).forEach(([k, v]) => { state.componentsState[k] = v })
			state.componentsState = { ...state.componentsState }
			localStorage.setItem(`state/${state.login}`, JSON.stringify(state.componentsState))
		},
		mSetComponentStateSession(state, payload) {
			Object.entries(payload).forEach(([k, v]) => { state.componentsStateSession[k] = v })
			state.componentsStateSession = { ...state.componentsStateSession }
			// localStorage.setItem(`state/${state.login}.session`, JSON.stringify(state.componentsStateSession))
			sessionStorage.setItem(`state/${state.login}`, JSON.stringify(state.componentsStateSession))
		},
		mLoadComponentState(state) {
			const componentsStateStr = localStorage.getItem(`state/${state.login}`) || '{}'
			// const componentsStateSessionStr = localStorage.getItem(`state/${state.login}.session`) || '{}'
			const componentsStateSessionStr = sessionStorage.getItem(`state/${state.login}`) || '{}'

			try {
				state.componentsState = JSON.parse(componentsStateStr)
			} catch (err) {
				state.componentsState = {}
			}

			try {
				/* let componentsStateSession = JSON.parse(componentsStateSessionStr)
				if (state.session !== componentsStateSession._session) { // eslint-disable-line
					componentsStateSession = {
						_session: state.session,
					}
				} */
				state.componentsStateSession = JSON.parse(componentsStateSessionStr)
			} catch (err) {
				state.componentsStateSession = {}
			}
		},
		mUserSettings(state, payload) {
			state.userSettings = payload
			state.isHelpMode = payload.helpMode
		},
		mUserRoles(state, payload) {
			state.roles = payload
		},
	},
	actions: {
		dropToDefaults({ commit, dispatch }) {
			dispatch('clearCache')
			commit('dropState')
		},
		clearCache() {
			Object.keys(defaultState()).forEach(key => {
				localStorage.removeItem(`user/${key}`)
			})
		},
		async aMe() {
			// mCurrentUser rename to mMe
			return vHttp.GET(this, {
				url: {
					path: `me`,
				},
				mutation: 'mCurrentUser',
			})
		},
		async aMeSettings() {
			// mUserSettings rename to mMeSettings
			return vHttp.GET(this, {
				url: {
					path: `me/settings`,
				},
				mutation: 'mUserSettings',
			})
		},
		async setTokenMeta({ commit, rootState }) {
			const { principal, authorities } = JSON.parse(jwtDecode(rootState.auth.token.accessToken).sub)
			commit('setAuthorities', authorities)
			commit('setUserData', principal)
			commit('mCurrentUser', principal)
		},
		async aUserRoles() {
			return vHttp.GET(this, {
				url: {
					path: `roles`,
				},
			})
		},
		async aAfterAuth({ getters, dispatch }) {
			if (getters.gRoles.isStore || getters.gRoles.isDoctorStore) {
				await dispatch('salesPlans/aSalesPlansCurrent', true, { root: true })
			}

			await dispatch('aMeSettings')
			await dispatch('aMe')
			await dispatch('statistics/aStatistics', true, { root: true })
		},
	},
}