import i18n from '~/plugins/i18n'

export const unmarkedGoodsColumns = [
	{
		prop: 'model',
		label: i18n.global.t('Описание'),
		width: 165,
		align: 'left',
		slot: 'model',
	},
	{
		prop: 'retailPrice',
		label: i18n.global.t('Цена'),
		width: 72,
		align: 'left',
		slot: 'retailPrice',
	},
	{
		prop: 'amount',
		label: i18n.global.t('Кол-во'),
		width: 76,
		align: 'left',
		slot: 'amount',
	},
	{
		prop: 'maxCount',
		label: i18n.global.t('Остаток'),
		width: 72,
		align: 'left',
	},
	{
		prop: 'discounts',
		label: i18n.global.t('Скидка'),
		align: 'right',
		slot: 'discounts',
	},
	{
		prop: 'discountPrice',
		label: i18n.global.t('Стоимость'),
		width: 105,
		align: 'right',
		slot: 'discountPrice',
	},
]