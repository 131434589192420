<template>
	<el-card
		id="page-bonus-settings"
		v-loading="isSettingsLoading"
	>
		<template #header>
			<div class="d-flex align-items-center">
				<ReturnButton :force-to="{ name: 'Bonus' }"/>

				<div class="font-size-large font-semi-bold ml-2 mr-2">{{ $t('Настройки бонусной системы') }}</div>

				<OptimaHelpTooltip
					text="Как настроить бонусную систему"
					href="https://optimahelp.itigris.ru/m/57456/l/1358448-"
				/>
			</div>
		</template>

		<el-form
			ref="form"
			:model="form"
			:rules="rules"
			label-width="152px"
			label-position="left"
			scroll-to-error
			require-asterisk-position="right"
		>
			<div class="font-semi-bold mb-4">{{ $t('Продажи') }}</div>

			<el-row :gutter="$gutter" class="mt-3">
				<el-col
					:span="10"
				>
					<el-form-item prop="default_duration">
						<template #label>
							<span>{{ $t('Срок действия') }}</span>

							<el-tooltip>
								<ItIcon
									name="info-outline"
									width="12px"
									height="12px"
									class="fill-info ml-2"
								/>

								<template #content>
									<div style="width: 200px;">{{ $t('Также определяет срок действия баллов, начисленных вручную') }}</div>
								</template>
							</el-tooltip>
						</template>

						<el-input
							v-model="form.default_duration"
							:readonly="!canEditBonusSettings"
							type="number"
							class="form-item"
							data-ta="default_duration"
						/>
					</el-form-item>
				</el-col>

				<el-col
					:span="10"
				>
					<el-form-item :label="$t('Отсрочка')" prop="bonus_delay">
						<el-input
							v-model="form.bonus_delay"
							class="form-item"
							:readonly="!canEditBonusSettings"
							type="number"
							data-ta="bonus_delay"
						>
							<template #append>{{ $t('дней') }}</template>
						</el-input>
					</el-form-item>
				</el-col>
			</el-row>

			<el-row :gutter="$gutter" class="mt-3">
				<el-col
					:span="10"
				>
					<div class="font-semi-bold mb-4">{{ $t('День рождения') }}</div>
					<el-form-item :label="$t('Бонусные баллы')" prop="birthday_bonus">
						<el-input
							v-model="form.birthday_bonus"
							type="number"
							class="form-item"
							data-ta="birthday_bonus"
							:readonly="!canEditBonusSettings"
						/>
					</el-form-item>
					<el-form-item :label="$t('Срок действия')" prop="birthday_duration">
						<el-input
							v-model="form.birthday_duration"
							:readonly="!canEditBonusSettings"
							type="number"
							class="form-item"
							data-ta="birthday_duration"
						>
							<template #append>{{ $t('дней') }}</template>
						</el-input>
					</el-form-item>
				</el-col>
				<el-col
					:span="10"
				>
					<div class="font-semi-bold mb-4">{{ $t('Выдача карты') }}</div>
					<el-form-item :label="$t('Бонусные баллы')" prop="new_card_bonus_points">
						<el-input
							v-model="form.new_card_bonus_points"
							:readonly="!canEditBonusSettings"
							type="number"
							class="form-item"
							data-ta="new_card_bonus_points"
						/>
					</el-form-item>
					<el-form-item :label="$t('Срок действия')" prop="new_card_bonus_duration">
						<el-input
							v-model="form.new_card_bonus_duration"
							:readonly="!canEditBonusSettings"
							type="number"
							class="form-item"
							data-ta="new_card_bonus_duration"
						>
							<template #append>{{ $t('дней') }}</template>
						</el-input>
					</el-form-item>
				</el-col>
			</el-row>

			<el-divider/>

			<template v-if="showMobileSettings">
				<el-row :gutter="$gutter" class="mb-7">
					<el-col
						:span="10"
					>
						<div class="font-semi-bold font-size-16">{{ $t('Мобильное приложение') }}</div>
					</el-col>

					<el-col
						:span="5"
						:offset="9"
					>
						<el-switch
							v-model="form.has_mobile_user_conditions"
							:active-text="$t('Особые правила')"
							:disabled="!canEditBonusSettings"
							data-ta="has_mobile_user_conditions"
						/>
					</el-col>
				</el-row>

				<el-row v-if="form.has_mobile_user_conditions" :gutter="$gutter">
					<el-col
						:span="10"
					>
						<div class="font-semi-bold mb-4">{{ $t('Установка приложения') }}</div>
						<el-form-item :label="$t('Бонусные баллы')" prop="mobile_installation_bonus">
							<el-input
								v-model="form.mobile_installation_bonus"
								:readonly="!canEditBonusSettings"
								type="number"
								class="form-item"
								data-ta="mobile_installation_bonus"
							/>
						</el-form-item>
						<el-form-item :label="$t('Срок действия')" prop="mobile_installation_duration">
							<el-input
								v-model="form.mobile_installation_duration"
								:readonly="!canEditBonusSettings"
								type="number"
								class="form-item"
								data-ta="mobile_installation_duration"
							>
								<template #append>{{ $t('дней') }}</template>
							</el-input>
						</el-form-item>
					</el-col>

					<el-col
						:span="10"
					>
						<div class="font-semi-bold mb-4">{{ $t('Рекомендация') }}</div>
						<el-form-item :label="$t('Бонусные баллы')" prop="mobile_recommendation_bonus">
							<el-input
								v-model="form.mobile_recommendation_bonus"
								:readonly="!canEditBonusSettings"
								type="number"
								class="form-item"
								data-ta="mobile"
							/>
						</el-form-item>
						<el-form-item :label="$t('Срок действия')" prop="mobile_recommendation_duration">
							<el-input
								v-model="form.mobile_recommendation_duration"
								:readonly="!canEditBonusSettings"
								type="number"
								class="form-item"
								data-ta="mobile_recommendation_duration"
							>
								<template #append>{{ $t('дней') }}</template>
							</el-input>
						</el-form-item>
					</el-col>
				</el-row>

				<el-divider/>
			</template>

			<el-row
				:gutter="$gutter"
				class="mb-4"
				style="height: 32px;"
			>
				<el-col
					:span="10"
				>
					<div class="font-semi-bold">{{ $t('Сообщение о начислении бонусов') }}</div>
				</el-col>

				<el-col
					v-if="canReadNotificationSettings"
					:span="5"
					:offset="9"
					class="text-right"
				>
					<el-button
						v-if="form.bonus_notification_sms || form.bonus_notification_email"
						size="default"
						data-ta="notification_btn"
						@click="setNotificationText"
					>{{ $t('Настроить текст') }}</el-button>
				</el-col>
			</el-row>

			<el-switch
				v-model="form.bonus_notification_sms"
				:active-text="$t('СМС')"
				class="mb-5"
				data-ta="bonus_notification_sms"
				:disabled="!canEditBonusSettings"
			/>
			<br>
			<el-switch
				v-model="form.bonus_notification_email"
				active-text="Email"
				class="mb-5"
				data-ta="bonus_notification_email"
				:disabled="!canEditBonusSettings"
			/>
		</el-form>

		<div
			v-if="canEditBonusSettings"
			class="d-flex justify-content-flex-end"
		>
			<ReturnButton :force-to="{ name: 'Bonus' }">
				<el-button
					:loading="isSettingsSaving"
					data-ta="cancel_btn"
				>{{ $t('Отмена') }}</el-button>
			</ReturnButton>

			<el-button
				class="ml-2"
				type="primary"
				:loading="isSettingsSaving"
				data-ta="save_btn"
				@click="saveSettings"
			>{{ $t('Сохранить') }}</el-button>
		</div>
	</el-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'
import ReturnButton from '~/components/old/ReturnButton'
import { MESSAGES_SETTINGS_TYPES, NOTIFICATION_MESSAGE_TYPE } from '~/utils/consts'
import { isRequired, isInteger, minNumber, maxNumber } from '~/utils/validationRules'
import mxFormChanged from '~/mixins/common/mxFormChanged'

export default {
	name: 'BonusSettings',
	components: {
		ReturnButton,
		OptimaHelpTooltip: defineAsyncComponent(() => import('~/components/old/OptimaHelpTooltip')),
	},
	mixins: [
		mxFormChanged,
	],
	async beforeRouteUpdate(to, from, next) {
		const res = await this.mxConfirmIfFormChanged()
		next(Boolean(res))
	},
	async beforeRouteLeave(to, from, next) {
		const res = await this.mxConfirmIfFormChanged()
		next(Boolean(res))
	},
	data() {
		return {
			form: {},
			isSettingsLoading: false,
			isSettingsSaving: false,
			showMobileSettings: false,
			rules: {
				default_duration: [isRequired, isInteger, minNumber(0), maxNumber(999999999999999)],
				bonus_delay: [isInteger, minNumber(0), maxNumber(999999999999999)],
				birthday_bonus: [isRequired, isInteger, minNumber(0), maxNumber(999999999999999)],
				birthday_duration: [isRequired, isInteger, minNumber(0), maxNumber(999999999999999)],
				new_card_bonus_points: [isRequired, isInteger, minNumber(0), maxNumber(999999999999999)],
				new_card_bonus_duration: [isRequired, isInteger, minNumber(0), maxNumber(999999999999999)],
				mobile_installation_bonus: [isRequired, isInteger, minNumber(0), maxNumber(999999999999999)],
				mobile_installation_duration: [isRequired, isInteger, minNumber(0), maxNumber(999999999999999)],
				mobile_recommendation_bonus: [isRequired, isInteger, minNumber(0), maxNumber(999999999999999)],
				mobile_recommendation_duration: [isRequired, isInteger, minNumber(0), maxNumber(999999999999999)],
			},
		}
	},
	computed: {
		...mapGetters('user', [
			'canEditBonusSettings',
			'canReadNotificationSettings',
		]),
		messagesSettingsTab() {
			if (this.form.bonus_notification_sms) return MESSAGES_SETTINGS_TYPES.TYPE_SMS_ID
			return MESSAGES_SETTINGS_TYPES.TYPE_EMAIL_ID
		},
	},
	watch: {
		'form.has_mobile_user_conditions': {
			handler(value) {
				if (!value) {
					this.form.mobile_installation_bonus = 0
					this.form.mobile_recommendation_bonus = 0
				}
			},
		},
		form: {
			deep: true,
			handler() {
				if (!this.isSettingsLoading) this.isFormChanged = true
			},
		},
	},
	created() {
		this.setSettings()
	},
	methods: {
		async setSettings() {
			this.isSettingsLoading = true

			try {
				const { data } = await this.$http.optima2.get('bonus/settings')
				this.form = data.settings
				this.showMobileSettings = data.additionalSettings.showMobileSettings
			} catch (error) {
				this.$notifyUserAboutError(error)
			}

			this.$nextTick(() => {
				this.isSettingsLoading = false
			})
		},
		async saveSettings(withRedirect = true) {
			const isValid = await this.$isFormValid('form')
			if (!isValid) {
				return
			}

			this.isSettingsSaving = true

			try {
				await this.$http.optima2.put('bonus/settings', { settings: this.form })

				this.$notify({ title: this.$t('Настройки бонусной системы сохранены'), type: 'success' })

				this.isFormChanged = false

				if (withRedirect) this.$router.push({ name: 'Bonus' })
			} catch (error) {
				this.$notifyUserAboutError(error)
			}

			this.isSettingsSaving = false
		},
		async setNotificationText() {
			const isValid = await this.$isFormValid('form')
			if (!isValid) {
				this.$notifyUserAboutError(this.$t('Перед переходом на другую страницу корректно заполните все поля формы'))
				return
			}

			try {
				await this.$confirm(this.$t('Вы собираетесь осуществить переход на другую страницу. Сохранить данные на текущей странице и перейти?'), this.$t('Внимание'), {
					confirmButtonText: 'Ок',
					cancelButtonText: this.$t('Отмена'),
					type: 'warning',
				})

				await this.saveSettings(false)

				this.$router.push({ name: 'NotificationSettings', query: { tab: this.messagesSettingsTab, messageType: NOTIFICATION_MESSAGE_TYPE.BONUSES_ACCRUAL } })
			} catch (error) {
				if (error === 'cancel' || error === 'close') return

				this.$notifyUserAboutError(error)
			}
		},
	},
}
</script>

<style lang="scss">

#page-bonus-settings {
	.form-item {
		width: 148px;

		/* .el-input.el-input-group--append {
			.el-input-group__append {
				color: var(--el-color-text-placeholder);
				padding: 0 12px;
			}
		} */
	}
}
</style>