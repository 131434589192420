export default function () {
	return {
		user: null,
		users: [],
		usersNotFired: [],
		usersAssignable: [],
		roles: null,
		userGroups: [],
		userGroupsLoading: false,
		usersUserGroups: [],
		usersUserGroupsLoading: false,
		usersDepartments: [],
		availableAssistants: [],
		usersDepartmentsLoading: false,
		selectedSeller: null,
		doctorCategories: [],
		medicalPositions: [],
		usersInDepartmentByRoles: [],
	}
}