<template>
	<el-form
		ref="form"
		class="layout-parts-filters-fields grid gap-4 align-items-end"
		:class="`grid-cols-${columns}`"
		:model="data"
		:rules="rules"
		size="default"
		require-asterisk-position="right"
		:label-position="labelPosition"
		@submit.prevent
	>
		<el-form-item
			v-for="field in fieldsWithNames"
			:key="field.name"
			:class="[{
					'com-filters__input': field.type === 'input',
					'com-filters__select': field.type === 'select',
					'com-filters__search-input': field.type === 'searchInput',
					'com-filters__date-picker': field.type === 'datePicker',
					'com-filters__daterange-picker': field.type === 'daterangePicker',
					'com-filters__tabs': field.type === 'tabs',
					['com-filters__item-' + field.name]: true,
				},
				`col-span-${field.cols}`,
			]"
			:style="field.style"
			:prop="field.name"
			:label="field.label"
			:data-ta="'formItem_' + field.name"
			@submit.prevent
		>
			<LayoutPartRadioButtonsGroup
				v-if="field.type === 'tabs'"
				v-model="data[field.name]"
				:tabs="field.tabs"
				:default="field.defaultValue"
				@update:modelValue="value => onChange(field, value)"
			/>
			<!-- vue-the-mask (v-mask) не умеет обрабатывать undefined -->
			<el-input
				v-if="field.type === 'input' && field.mask"
				v-model="data[field.name]"
				v-mask="field.mask"
				:placeholder="field.placeholder"
				:minlength="field.minlength"
				:maxlength="field.maxlength"
				:disabled="field.disabled"
				:data-ta="field.name"
				:clearable="field.clearable"
				@update:modelValue="value => onChange(field, value)"
				@clear="value => onClear(field)"
				@blur="value => onBlur(field, value)"
				@keydown.enter="value => onEnter(field, value)"
			/>
			<el-input
				v-else-if="field.type === 'input'"
				v-model="data[field.name]"
				:placeholder="field.placeholder"
				:minlength="field.minlength"
				:maxlength="field.maxlength"
				:disabled="field.disabled"
				:data-ta="field.name"
				:clearable="field.clearable"
				@update:modelValue="value => onChange(field, value)"
				@clear="value => onClear(field)"
				@blur="value => onBlur(field, value)"
				@keydown.enter="value => onEnter(field, value)"
			>
				<template #suffix>
					<ItIcon
						v-if="field.suffixIcon"
						:name="field.suffixIcon"
					/>
				</template>
			</el-input>
			<FilterSearchInput
				v-else-if="field.type === 'searchInput'"
				v-model="data[field.name]"
				:field="field"
				@on-search="value => onSearch(field, value)"
			/>
			<el-select
				v-else-if="field.type === 'select'"
				v-model="data[field.name]"
				:placeholder="field.placeholder"
				:clearable="field.clearable"
				:filterable="field.filterable"
				:multiple="field.multiple"
				:disabled="field.disabled"
				:data-ta="field.name"
				:collapse-tags="field.collapsable"
				:collapse-tags-tooltip="field.collapsable"
				:popper-class="'ta-select-dropdown ta-select-dropdown-' + field.name"
				:popper-options="popperOptions"
				:fit-input-width="true"
				placement="bottom"
				@update:modelValue="value => onChange(field, value)"
				@clear="value => onClear(field)"
				@blur="value => onBlur(field, value)"
				@keydown.enter="value => onEnter(field, value)"
			>
				<template
					v-if="field.groups"
				>
					<el-option-group
						v-for="group in field.groups"
						:key="group.label"
						:label="group.label"
					>
						<el-option
							v-for="item in group.options"
							:key="item.value"
							:label="item.label"
							:value="item.value"
							:data-ta="field.name + '-' + item.value"
						/>
					</el-option-group>
				</template>
				<el-option-group
					v-else-if="field.options.length"
					:key="field.options.length"
					:label="field.optionsTitle"
				>
					<el-option
						v-for="item in field.options"
						:key="item.value"
						:label="item.label"
						:value="item.value"
						:data-ta="field.name + '-' + item.value"
					/>
				</el-option-group>
			</el-select>
			<el-date-picker
				v-else-if="field.type === 'datePicker'"
				v-model="data[field.name]"
				:disabled="field.disabled"
				:data-ta="field.name"
				:clearable="field.clearable"
				:placeholder="field.placeholder"
				:disabled-date="date => getDisabledDateFn(date, field)"
				format="DD.MM.YYYY"
				value-format="YYYY-MM-DD"
				class="com-filters__date-picker"
				:fallback-placements="['bottom']"
				@update:modelValue="value => onChange(field, value)"
				@clear="value => onClear(field)"
				@blur="value => onBlur(field, value)"
				@keydown.enter="value => onEnter(field, value)"
			/>
			<el-date-picker
				v-else-if="field.type === 'daterangePicker'"
				:key="field.key"
				v-model="field.value"
				:disabled="field.disabled"
				:data-ta="field.name"
				:disabled-date="date => checkDisabledDate(date, field)"
				:shortcuts="field.shortcuts || []"
				:start-placeholder="field.placeholders.start"
				:end-placeholder="field.placeholders.end"
				:clearable="field.clearable"
				format="DD.MM.YYYY"
				value-format="YYYY-MM-DD"
				class="com-filters__daterange-picker"
				type="daterange"
				align="right"
				style="width: auto"
				popper-class="daterange-picker"
				:fallback-placements="['bottom']"
				@update:modelValue="value => onChange(field, value)"
				@on-clear="value => onClear(field)"
				@on-blur="value => onBlur(field, value)"
				@on-enter="value => onEnter(field, value)"
				@calendar-change="value => onCalendarChange(field, value)"
				@visible-change="visibility => onCalendarToggle(field, visibility)"
			/>
			<el-checkbox
				v-else-if="field.type === 'checkbox'"
				v-model="data[field.name]"
				border
				style="height: 32px"
				@update:modelValue="value => onChange(field, value)"
				@blur="value => onBlur(field, value)"
			>{{ field.placeholder }}</el-checkbox>
			<el-radio-group
				v-else-if="field.type === 'radio'"
				v-model="data[field.name]"
				:data-ta="field.name"
				@update:modelValue="value => onChangeRadio(field, value)"
			>
				<el-radio-button
					v-for="item in field.options"
					:key="item.value"
					:label="item.label"
					:value="item.value"
					:data-ta="field.name + '-' + item.value"
				/>
			</el-radio-group>
			<div class="com-filters_tooltip">
				<el-tooltip
					v-if="field.tooltip"
					v-bind="field.tooltip"
				>
					<ItIcon
						name="info"
						width="14"
						height="14"
						class="fill-grey"
					/>
				</el-tooltip>
			</div>
		</el-form-item>

		<slot name="buttons"/>
	</el-form>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { mask } from 'vue-the-mask'
import { formatDate } from '~/utils/formatters'
import {
	TODAY,
	CURRENT_WEEK,
	LAST_WEEK,
	LAST_MONTH,
	LAST_YEAR,
	CURRENT_MONTH,
	CURRENT_YEAR,
	SLIDING_WEEK,
	SLIDING_MONTH,
	SLIDING_YEAR,
	YESTERDAY,
} from '~/helpers/common/daterangeShortcuts'
// import ElSelect from '~/el-plus/es/components/select'

// import { ElSelect as ElSelectLocal } from '~/el-plus/es/components/select'
import LayoutPartRadioButtonsGroup from '../LayoutPartRadioButtonsGroup.vue'
import FilterSearchInput from './FilterSearchInput.vue'


export default {
	name: 'LayoutPartFiltersFields',
	components: {
		LayoutPartRadioButtonsGroup,
		FilterSearchInput,
		// ElSelect,
	},
	directives: {
		mask,
	},
	props: {
		modelValue: {
			type: Object,
			default: () => ({}),
		},
		fields: {
			type: Object,
			required: true,
		},
		focus: {
			type: String,
			default: 'auto',
		},
		columns: {
			type: Number,
			default: 4,
		},
		applyImmediately: {
			type: Boolean,
			default: true,
		},
		labelPosition: {
			type: String,
			default: 'top',
		},
	},
	data() {
		return {
			data: {},
			datarangePlaceholders: {
				start: this.$t('С'),
				end: this.$t('По'),
			},
			fieldsWithNames: [],
			fieldsAttributes: {},
			popperOptions: {
				placement: 'bottom',
				modifiers: [
					{
						name: 'preventOverflow',
						options: {
							mainAxis: false,
							altBoundary: false,
							rootBoundary: 'document',
							tether: false,
						},
					},
				],
			},
		}
	},
	computed: {
		...mapGetters('user', ['gComponentsState']),
		rules() {
			return this.fieldsWithNames.reduce((rules, field) => {
				if (field.rule) {
					rules[field.name] = field.rule
				}
				return rules
			}, {})
		},
		datarangesFields() {
			return this.fieldsWithNames.filter(field => field.type === 'daterangePicker')
		},
		defaultShortcuts() {
			return {
				TODAY,
				CURRENT_WEEK,
				LAST_WEEK,
				LAST_MONTH,
				LAST_YEAR,
				CURRENT_MONTH,
				CURRENT_YEAR,
				SLIDING_WEEK,
				SLIDING_MONTH,
				SLIDING_YEAR,
				YESTERDAY,
			}
		},
	},
	watch: {
		modelValue: {
			deep: true,
			immediate: true,
			handler(value) {
				this.data = { ...value }
				this.pushDatarangesChanges()
			},
		},
		fields: {
			deep: true,
			immediate: true,
			handler(value) {
				this.fieldsWithNames = Object.entries(value).reduce((res, [name, item]) => {
					if (item.hidden) {
						return res
					}

					const field = { ...item, name }
					if (!field.cols) {
						field.cols = 3
					}

					if (field.type === 'daterangePicker') {
						field.shortcuts = field.shortcuts
							? this.setDataPickerShortcuts(field.shortcuts)
							: null

						if (!field.placeholders) {
							field.placeholders = this.datarangePlaceholders
						}
						this.setDatarangeFieldModel(field)
					} else if (this.data[name] === undefined && ![null, undefined].includes(field.defaultValue)) {
						this.data[name] = field.defaultValue ?? null
					}

					res.push(field)

					return res
				}, [])
			},
		},
	},
	mounted() {
		this.focusOnMounted()
		this.$emit('on-mounted')
	},
	methods: {
		...mapMutations('user', ['mSetComponentState']),

		// Изменение отображения периода при изменении данных фильтра
		pushDatarangesChanges() {
			this.datarangesFields.forEach(field => {
				field.value = [this.data[field.fromName], this.data[field.toName]]
			})
		},
		setDatarangeFieldModel(field) {
			field.value = [
				formatDate(new Date(this.data[field.fromName] || field.defaultValue[0]), 'YYYY-MM-DD'),
				formatDate(new Date(this.data[field.toName] || field.defaultValue[1]), 'YYYY-MM-DD'),
			]
			field.key = 0
		},
		setDataPickerShortcuts(shortcuts) {
			return shortcuts.map(shortcut => this.defaultShortcuts[shortcut])
		},
		onCalendarChange(field, date) {
			field.firstSelectedDate = null
			if (!date[1]) {
				// eslint-disable-next-line prefer-destructuring
				field.firstSelectedDate = date[0]
			}
		},
		onCalendarToggle(field, visibility) {
			if (!visibility && field.firstSelectedDate) {
				field.key++
				if (Date.parse(field.firstSelectedDate) < Date.parse(field.value[1])) {
					field.value = [field.firstSelectedDate, field.value[1]]
				} else {
					field.value = [field.value[0], field.firstSelectedDate]
				}
				this.onChange(field)
			}
		},
		updateFilters() {
			const data = Object.entries(this.data).reduce((acc, [k, v]) => {
				acc[k] = v?.trim ? v.trim() : v
				return acc
			}, {})
			this.$emit('update:modelValue', data)
		},
		resetFilters() {
			Object.entries(this.fields).forEach(([key, field]) => {
				if (field?.type === 'daterangePicker') {
					this.data[field.fromName] = field.defaultValue[0] || '' // eslint-disable-line
					this.data[field.toName] = field.defaultValue[1] || '' // eslint-disable-line
					this.setDatarangeFieldModel(field)
				} else if (field) {
					this.data[key] = field.defaultValue || null
				}
			})
			this.updateFilters()
		},
		checkDisabledDate(date, field) {
			if (field.maxDate && date.getTime() > Date.parse(field.maxDate)) return true
			return field.minDate && date.getTime() < Date.parse(field.minDate)
		},
		onChangeRadio(field, value) {
			const values = field.options.map(option => option.value)
			if (values.includes(value)) {
				this.onChange(field, value)
				return
			}
			const item = field.options.find(option => option.label === value)
			this.onChange(field, item.value)
		},
		onChange(field, value) {
			if (field.type === 'daterangePicker') {
				const daterangeValue = field.value || field.defaultValue
				const [from, to] = daterangeValue || []
				this.data[field.fromName] = from ? formatDate(from, 'YYYY-MM-DD') : ''
				this.data[field.toName] = to ? formatDate(to, 'YYYY-MM-DD') : ''
				// this.setDatarangeFieldModel(field)
			} else {
				let val = value
				if (field.type === 'datePicker' && field.isRequired && value === null) {
					val = field.defaultValue
				}

				this.data[field.name] = val ?? null
			}

			if (field.onChange) {
				field.onChange(value)
			}

			if (this.applyImmediately && field.applyImmediately !== false) {
				this.updateFilters()
			}
		},
		onBlur(field, value) {
			if (field.onBlur) {
				field.onBlur(value)
			}

			if (field.applyOnBlur) {
				this.updateFilters()
			}
		},
		onEnter(field, value) {
			if (field.onEnter) {
				field.onEnter(value)
			}

			if (field.applyOnEnter) {
				this.updateFilters()
			}
		},
		onClear(field) {
			if (field.onClear) {
				field.onClear(this.data[field.name])
			}
			if (field.applyOnClear && (!this.applyImmediately || field.applyImmediately === false)) {
				this.updateFilters()
			}
		},
		onSearch(field, value) {
			this.data[field.name] = value
			if (field.onChange) {
				field.onChange(value)
			}
			this.updateFilters()
		},
		focusOnMounted() {
			let focusField = null

			if (this.focus === 'auto') {
				focusField = this.fieldsWithNames.find(field => field.type === 'searchInput')
			} else if (this.focus) {
				focusField = this.fieldsWithNames.find(field => field.name === this.focus)
			}

			if (focusField) {
				if (focusField.type === 'searchInput') {
					focusField.focus = true
				} else {
					setTimeout(() => {
						this.$refs[focusField.name][0].focus()
					}, 100)
				}
			}
		},
		validate() {
			return this.$isFormValid('form')
		},
		getDisabledDateFn(date, field) {
			const res = field.disabledDateFn ? field.disabledDateFn(date) : this.checkDisabledDate(date, field)
			return res
		},
		getFiltersData() {
			return this.data
		},
	},
}
</script>

<style lang="scss">

.layout-parts-filters-fields {
	.el-form-item__label {
		font-size: 12px;
	}

	.com-filters__input,
	.com-filters__select,
	.com-filters__search-input,
	.com-filters__date-picker,
	.com-filters__daterange-picker {
		box-sizing: border-box;
		width: 100%;

		.el-select {
			width: 100%;
		}
		.el-input {
			height: 100%;
		}
	}

	.com-filters__tabs {
		.el-form-item__content {
			display: flex;
		}
	}

	.el-form-item {
		margin-bottom: 0;
	}

	.el-input__suffix-inner {
		display: flex !important;
	}

	.com-filters__date-picker {
		width: 100% !important;
	}

	.com-filters_tooltip {
		position: absolute;
		top: 50%;
		margin-top: -14px;
		right: 15px;
	}

	.el-input.is-disabled .el-input__inner {
		color: var(--el-text-placeholder-dark);
	}

	input::placeholder {
		font-size: 12px !important;
		text-overflow: hidden;
	}

	.el-checkbox.is-bordered {
		width: 100%;
	}

	.el-select .el-input__inner {
		text-overflow: ellipsis;
	}

	.el-date-editor.el-input__wrapper {
		height: var(--el-filters-controls-height);
	}
	.el-radio-group {
		display: block;
		white-space: nowrap;
	}
	.el-radio-group .el-radio-button__inner {
		line-height: var(--el-filters-controls-height);
		padding: 0 12px;
		font-weight: 400;
		box-sizing: border-box;
	}
	&.el-form--label-left {
		.el-form-item__label {
			line-height: 16px;
			flex: 0 1 30%;
		}
		.el-form-item__content {
			flex: 1 0 70%;
		}
	}
}

.daterange-picker {
	.el-picker-panel {
		&__sidebar,
		& *[slot=sidebar] {
			width: 160px !important;
			position: absolute !important;
			padding-top: 16px !important;

			& + .el-picker-panel__body {
				margin-left: 160px !important;
			}
		}

		&__shortcut {
			font-size: 12px;
		}
	}
}
</style>