export default {
	mCurrentClient(state, payload) {
		state.currentClient = payload
	},
	mClientAgreementType(state, payload) {
		state.clientAgreementType = payload
	},
	mSendMessageError(state, payload = true) {
		state.sendMessageError = payload
	},
	mAcceptingAgreementError(state, payload = true) {
		state.acceptingAgreementError = payload
	},
	mClientAgreementData(state, payload) {
		state.clientAgreementData = payload
	},
	mCheckingWasSmsSent(state, payload) {
		state.checkingWasSmsSent = payload
	},
	mAgreement(state, payload) {
		state.agreement = payload
	},
	mClientsEmail(state, payload) {
		state.clientsEmail = payload.email
	},
	mStoreBalanceInfo(state, payload) {
		state.storeBalanceInfo = payload
	},
}