import i18n from '~/plugins/i18n'

// Переводы в departments.xlsx

export default [{
	name: 'GLASSES',
	label: i18n.global.t('Изготовление очков'),
	type: 'select',
}, {
	name: 'SALE',
	label: i18n.global.t('Заказ "Продажа"'),
	type: 'select',
}, {
	name: 'REPAIR',
	label: i18n.global.t('Заказ "Ремонт"'),
	type: 'select',
}, {
	name: 'CONTACT_LENS',
	label: i18n.global.t('Продажа контактных линз'),
	type: 'select',
}, {
	name: 'CHECK_VISION',
	label: i18n.global.t('Проверка зрения'),
	type: 'select',
}]