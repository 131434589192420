<template>
	<OptimaSidebarNav
		id="layout-sidebar"
		logo-description="ITigris Optima"
		:navs="navs"
		class="layout-part-sidebar"
	/>
</template>

<script>
import { mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'
import { ORDERS_TYPES } from '~/helpers/common/OrderTypes'

export default {
	name: 'LayoutPartSidebar',
	components: {
		OptimaSidebarNav: defineAsyncComponent(() => import('~/kit/sidebar/SidebarNav')),
	},
	computed: {
		...mapGetters('user', [
			'isAdmin',
			'isStore',
			'isDepot',
			'isDoctor',
			'isDoctorStore',
			'isSecretary',
			'isOptClient',
			'canReadBalance',
			'canReadNews',
			'canReadApplicationSettings',
			'canReadNotificationSettings',
			'canReadBonus',
			'canReadReport',
			'isDoctorStore',
			'isProduction',
		]),
		navs() {
			const navs = []

			if (this.isStore) {
				navs.push(...[{
					/* label: orderTypes.glasses.label,
					icon: 'glasses',
					path: '/order/client?type=glasses',
				}, { */
					label: ORDERS_TYPES.SALE.label,
					icon: 'sales',
					path: '/clients-journal?deleted=false&size=10&page=1&orderType=SaleOrder',
				}, {
					label: ORDERS_TYPES.REPAIR.label,
					icon: 'wrench',
					path: '/clients-journal?deleted=false&size=10&page=1&orderType=RepairOrder',
				}, {
					label: ORDERS_TYPES.CONTACT_LENS.label,
					icon: 'lenses',
					path: '/clients-journal?deleted=false&size=10&page=1&orderType=ClOrder',
				},
				])
			}

			navs.push({
				label: 'Журнал пользователей',
				icon: 'users',
				path: '/users-journal',
			})

			navs.push({
				label: 'Контроль доступа',
				icon: 'users',
				path: '/access-control',
			})

			navs.push({
				label: 'Журнал клиентов',
				icon: 'clients',
				path: '/clients-journal',
			})

			if (this.isDoctorStore || this.isStore) {
				navs.push({
					label: this.$t('Выдача заказа'),
					icon: 'clients',
					path: '/orders-completed',
				})
			}

			if (!this.isProduction && !this.isAdmin) {
				navs.push({
					label: 'Журнал заказов',
					icon: 'clients',
					path: '/orders-journal',
				})

				navs.push({
					label: 'Журнал массовок',
					icon: 'clients',
					path: '/extras-journal',
				})

				navs.push({
					label: 'Журнал списаний',
					icon: 'clients',
					path: '/cancellations-journal',
				})
			}

			navs.push({
				label: 'Планы продаж',
				icon: 'sales',
				path: '/sales-plans',
			})

			navs.push({
				label: 'Журнал очков',
				icon: 'glasses',
				path: '/glasses-journal',
			})

			navs.push({
				label: 'Журнал департаментов',
				icon: 'departments',
				path: '/departments-journal',
			})

			navs.push({
				label: 'Юридические лица',
				icon: 'legal-entities',
				path: '/legal-entities',
			})

			navs.push({
				label: 'Штрихкоды на листе',
				icon: 'barcode-list',
				path: '/barcodes-on-sheet',
			})

			if (!this.isAdmin) {
				navs.push({
					label: 'Журнал возвратов',
					icon: 'goods-returns',
					path: '/returns-journal',
				})
			}

			navs.push({
				label: 'Принять трансфер',
				icon: 'transfers',
				path: '/accept-transfer-journal',
			})
			navs.push({
				label: 'Журнал трансферов',
				icon: 'transfers',
				path: '/transfers-journal',
			})

			navs.push({
				label: 'Журнал переоценок',
				icon: '',
				path: '/reevaluations',
			})

			if (this.isAdmin || this.isDepot) {
				navs.push({
					label: 'Справочники',
					icon: 'directories',
					path: '/directories',
				})
			}

			if (this.isAdmin) {
				navs.push({
					label: 'Настройки штрихкодов',
					icon: 'barcode-settings',
					path: '/barcodes-settings',
				})

				navs.push({
					label: 'Настройки интеграции',
					icon: 'intergration',
					children: [{
						label: 'ООО "Оптик-Клуб"',
						icon: 'intergration',
						path: '/integration-settings/2',
					},
					{
						label: 'ООО "Джонсон & Джонсон"',
						icon: 'intergration',
						path: '/integration-settings/1',
					}],
				})
			}

			if (this.canReadBalance) {
				navs.push({
					label: this.$t('Баланс'),
					icon: 'wallet',
					path: '/balance',
				})
			}

			if (this.canReadNews) {
				navs.push({
					label: this.$t('Новости'),
					icon: 'news',
					path: '/news/list',
				})
			}

			if (this.canReadNotificationSettings) {
				navs.push({
					label: this.$t('Настройки сообщений'),
					icon: 'notification',
					path: '/notification/settings',
				})
			}

			if (this.canReadApplicationSettings) {
				navs.push({
					label: this.$t('Настройки приложения'),
					icon: 'settings',
					path: '/settings/application-settings',
				})
			}

			if (this.canReadBonus) {
				navs.push({
					label: this.$t('Бонусы'),
					icon: 'bonus',
					path: '/bonus',
				})
			}

			if (this.canReadReport) {
				navs.push({
					label: this.$t('Отчеты'),
					icon: 'reports',
					path: '/report',
				})
			}

			navs.push({
				label: this.$t('Журнал ревизий'),
				icon: 'reports',
				path: '/revisions-journal',
			})

			navs.push({
				label: this.$t('Журнал поставок'),
				icon: 'supply-journal',
				path: '/supplies-journal',
			})

			navs.push({
				label: this.$t('Журнал скидок'),
				icon: 'discount',
				path: '/discounts-journal',
			})

			navs.push({
				label: this.$t('Реестр соответствия'),
				icon: 'reports',
				path: '/compilance-register-journal',
			})

			if (this.isProduction) {
				navs.push({
					label: this.$t('Изготовление очков'),
					icon: 'glasses',
					path: '/workshop-journal',
				})
			}

			return navs
		},
	},
}
</script>