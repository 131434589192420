import VuexHttp from '../../vuexHttpService'

const vHttp = new VuexHttp('workshops')

export default {
	async aUsersProduction() {
		return vHttp.GET(this, {
			url: 'users/production',
			mutation: 'mUsersProduction',
		})
	},
	async aDepartmentsStores() {
		return vHttp.GET(this, {
			url: 'departments/stores',
			mutation: 'mDepartmentsStores',
		})
	},
	async aDepartmentsProductions() {
		return vHttp.GET(this, {
			url: 'departments/productions',
			mutation: 'mDepartmentsProductions',
		})
	},
	async aDepartmentsList() {
		return vHttp.GET(this, {
			url: 'departments/list',
			mutation: 'mDepartmentsList',
		})
	},
	async aOrdersStatuses() {
		return vHttp.GET(this, {
			url: 'orders/statuses',
			mutation: 'mOrdersStatuses',
		})
	},
	async aExtrasOrderStatuses() {
		return vHttp.GET(this, {
			url: 'order/extras/statuses',
			mutation: 'mExtrasOrdersStatuses',
		})
	},
	async aOrdersTypesWithExtraTypes() {
		return vHttp.GET(this, {
			url: 'orders/types?withExtraTypes=true',
			mutation: 'mOrdersTypesWithExtraTypes',
		})
	},
	async aPrescriptionsBases() {
		return vHttp.GET(this, {
			url: 'prescriptions/bases',
			mutation: 'mPrescriptionsBases',
		})
	},
	async aWorkshopOrder({ commit }, params) {
		const { orderId, ...query } = params

		const order = await vHttp.GET(this, {
			url: {
				path: `/production-glasses/${orderId}`,
				query,
			},
			mutation: 'mWorkshopOrder',
		})

		if (!order) commit('mWorkshopOrder', {})
	},
	async aFindGlassesOrderById(_, orderId) {
		return vHttp.GET(this, {
			url: `serves/glasses/${orderId}`,
		})
	},
}