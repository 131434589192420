import i18n from '~/plugins/i18n'

export function LAYOUT_BUTTONS_DEFAULTS() {
	return {
		cancel: {
			type: 'button',
			name: 'cancel',
			label: i18n.global.t('Отмена'),
			icon: null,
			tooltip: null,
			isVisible: false,
			isDisabled: false,
			event: 'on-cancel',
		},
		edit: {
			type: 'button',
			name: 'edit',
			label: null,
			icon: 'pensil',
			btnType: 'primary',
			plain: true,
			class: 'px-2',
			tooltip: i18n.global.t('Редактировать'),
			isDisabled: false,
			isVisible: true,
			event: 'on-edit',
		},
		delete: {
			type: 'button',
			name: 'delete',
			label: null,
			icon: 'trash',
			tooltip: i18n.global.t('Удалить'),
			isVisible: false,
			btnType: 'danger',
			plain: true,
			class: 'px-2',
			isDisabled: false,
			event: 'on-delete',
		},
		restore: {
			type: 'button',
			name: 'restore',
			label: i18n.global.t('Восстановить'),
			icon: 'restore',
			tooltip: null,
			isVisible: false,
			isDisabled: false,
			event: 'on-restore',
		},
		save: {
			type: 'button',
			name: 'save',
			label: i18n.global.t('Сохранить'),
			icon: null,
			tooltip: null,
			isVisible: true,
			isPrimary: false,
			isDisabled: true,
			event: 'on-save',
		},
		close: {
			type: 'button',
			name: 'close',
			label: null,
			icon: 'close',
			tooltip: null,
			isVisible: true,
			event: 'on-close',
		},
	}
}