import i18n from '~/plugins/i18n'

export default {
	NO_ROUND: {
		label: i18n.global.t('Без округления'),
		value: 'NO_ROUND',
	},
	TO_TEN: {
		label: i18n.global.t('До десятков'),
		value: 'TO_TEN',
	},
	TO_HUNDRED: {
		label: i18n.global.t('До сотен'),
		value: 'TO_HUNDRED',
	},
	TO_INT: {
		label: i18n.global.t('До целого значения'),
		value: 'TO_INT',
	},
}