export default {
	content: [
		{
			id: 1000000013,
			familyName: 'Дорофеев',
			firstName: 'Константин',
			patronymicName: 'Константинович',
			birthday: '1982-01-01',
			phoneNumber: '89992391523',
			registrationDate: '2017-04-12',
		},
		{
			id: 1000009718,
			familyName: 'Соловьёв',
			firstName: 'Ипполит',
			patronymicName: 'Романович',
			birthday: null,
			phoneNumber: '89992391523',
			registrationDate: '2019-01-04',
		},
		{
			id: 1000009332,
			familyName: 'Никитин',
			firstName: 'Марк',
			patronymicName: 'Артёмович',
			birthday: '2001-01-31',
			phoneNumber: '89992391523',
			registrationDate: '2018-11-18',
		},
		{
			id: 1000009334,
			familyName: 'Прохоров',
			firstName: 'Егор',
			patronymicName: 'Романович',
			birthday: '1991-11-21',
			phoneNumber: '89992391523',
			registrationDate: '2018-11-19',
		},
		{
			id: 1000009337,
			familyName: 'Веселов',
			firstName: 'Осип',
			patronymicName: 'Максимович',
			birthday: '1977-12-29',
			phoneNumber: '89992391523',
			registrationDate: '2018-11-19',
		},
		{
			id: 1000009338,
			familyName: 'Фадеев',
			firstName: 'Дамир',
			patronymicName: 'Фёдорович',
			birthday: null,
			phoneNumber: '89992391523',
			registrationDate: '2018-11-19',
		},
		{
			id: 1000009626,
			familyName: 'Гаврилов',
			firstName: 'Мирослав',
			patronymicName: 'Станиславович',
			birthday: null,
			phoneNumber: '89992391523',
			registrationDate: '2018-12-22',
		},
		{
			id: 1000001188,
			familyName: 'Терентьев',
			firstName: 'Антон',
			patronymicName: 'Олегович',
			birthday: '1981-05-04',
			phoneNumber: '89992391523',
			registrationDate: '2017-04-12',
		},
		{
			id: 1000009629,
			familyName: 'Котов',
			firstName: 'Ефим',
			patronymicName: 'Олегович',
			birthday: null,
			phoneNumber: '89992391523',
			registrationDate: '2018-12-22',
		},
		{
			id: 1000001460,
			familyName: 'Волков',
			firstName: 'Донат',
			patronymicName: 'Константинович',
			birthday: null,
			phoneNumber: '89992391523',
			registrationDate: '2017-04-12',
		},
	],
	pageable: {
		sort: {
			sorted: false,
			unsorted: true,
			empty: true,
		},
		offset: 200,
		pageNumber: 20,
		pageSize: 10,
		paged: true,
		unpaged: false,
	},
	totalElements: 12295,
	totalPages: 1230,
	last: false,
	size: 10,
	number: 20,
	sort: {
		sorted: false,
		unsorted: true,
		empty: true,
	},
	numberOfElements: 10,
	first: false,
	empty: false,
}