/* eslint-disable no-console */

// TODO - добавить параметр duration: (0 - бесконечное отображение)
async function $notifyUserAboutError(error) {
	const title = this.$t ? this.$t('Ошибка') : 'Ошибка'

	if (typeof error === 'string') {
		this.$notify.error({ title, message: error })
		return
	}

	if (error.disableUserNotice) return

	if (!error.response) {
		const message = [error.field, error.message].filter(t => Boolean(t)).join(' ')
		this.$notify.error({ title, message })
		return
	}

	const { response } = error

	if (!response.data) {
		this.$notify.error({ title, message: response })
		return
	}

	if (typeof response.data.text === 'function') {
		try {
			const text = await response.data.text()
			response.data = JSON.parse(text)
		} catch (err) {
			console.error(err)
		}
	}

	if (response.data[0] && response.data[0].title) {
		this.$notify.error({ title, message: response.data[0].title })
		return
	}

	if (response.data.errors) {
		let message = ''

		if (Array.isArray(response.data.errors)) {
			response.data.errors.forEach(e => {
				const errorStr = [e.field, e.message].filter(t => Boolean(t)).join(' ')
				message += `${errorStr}<br>`
			})
		} else {
			Object.keys(response.data.errors).forEach(e => {
				message += `${e} ${response.data.errors[e].msg}<br>`
			})
		}

		this.$notify.error({
			title,
			dangerouslyUseHTMLString: true,
			message,
		})

		return
	}

	this.$notify.error({
		title: `${title} ${response.data.status || response.status}`,
		message: response.data.error || response.data.message || (this.$t ? this.$t('Обратитесь в техподдержку') : 'Обратитесь в техподдержку'),
	})

	if (import.meta.env.VITE_MODE === 'production') {
		console.dir(error)
	}
}

export default {
	install(app) {
		app.config.globalProperties.$notifyUserAboutError = $notifyUserAboutError
	},
}