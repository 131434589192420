import balanceState from './balanceState'
import balanceActions from './balanceActions'
import balanceMutations from './balanceMutations'
import balanceGetters from './balanceGetters'

export default {
	namespaced: true,
	state: balanceState,
	getters: balanceGetters,
	mutations: balanceMutations,
	actions: balanceActions,
}