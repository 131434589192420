import isEqual from 'lodash/isEqual'
import cloneDeep from 'lodash/cloneDeep'

let originalPageData = null
const originalTabsData = {}

function layoutDefaultState() {
	return {
		isEdit: false,
		buttons: true,
		pageData: null,
		tabsData: {},
		activeTab: '',
		isChanged: false,
		isDeleted: false,
		changedTabs: {},
	}
}

export default {
	namespaced: true,
	state: layoutDefaultState(),
	getters: {
		gHasChangedTabs(state) {
			return Boolean(Object.values(state.changedTabs).some(tab => tab))
		},
		gTabsData(state) {
			return state.tabsData
		},
		gPageData(state) {
			return state.pageData
		},
		gActiveTabData(state) {
			return state.tabsData[state.activeTab]
		},
		gActiveTabOriginalData(state) {
			state.tabsData = state.tabsData // eslint-disable-line
			return originalTabsData[state.activeTab]
		},
		gPageOriginalData(state) {
			state.pageData = state.pageData // eslint-disable-line
			return originalPageData
		},
		gActiveTab(state) {
			return state.activeTab
		},
	},
	mutations: {
		mLayoutDropState(state) {
			// eslint-disable-next-line no-param-reassign, no-unused-vars
			state = {
				...state,
				...layoutDefaultState(),
			}
		},
		mSetPageData(state, data) {
			originalPageData = cloneDeep(data)
			state.pageData = data
		},
		mSetPageDataField(state, { key, value }) {
			originalPageData[key] = value
			state.pageData = {
				...state.pageData,
				[key]: value,
			}
		},
		mUpdatePageData(state, data) {
			state.pageData = data
		},
		mRestorePageData(state) {
			state.pageData = cloneDeep(originalPageData)
		},
		mActiveTab(state, activeTab) {
			state.activeTab = activeTab
		},
		mSetTabData(state, { tab, data }) {
			originalTabsData[tab] = cloneDeep(data)
			state.tabsData = {
				...state.tabsData,
				[tab]: data,
			}
		},
		mUpdateTabData(state, { tab, data }) {
			state.tabsData[tab] = data
		},
		mRestoreTabsData(state) {
			state.pageData = cloneDeep(originalTabsData)
		},
		mSetTabChangedState(state, { tab, data }) {
			state.changedTabs[tab] = data
		},
		mSetActiveTabChangedState(state, data) {
			state.changedTabs[state.activeTab] = data
			state.isChanged = Boolean(Object.values(state.changedTabs).some(tab => tab))
		},
		mSetEditState(state, payload) {
			state.isEdit = payload
		},
		mSetChangedState(state, payload) {
			state.isChanged = payload
			if (payload === false) {
				state.changedTabs = {}
			}
		},
		mSetDeletedState(state, payload) {
			state.isDeleted = payload
		},
		mCheckTabChangedState(state, tab) {
			const changed = !isEqual(state.tabsData[tab], originalTabsData[tab])
			state.changedTabs[tab] = changed
		},
	},
}