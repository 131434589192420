const DEFAULT_DECIMAL = {
	style: 'decimal',
	minimumFractionDigits: 2,
}
const DEFAULT_CURRENCY = {
	style: 'currency',
}

function getOptions(currency) {
	if (currency) {
		return { ...DEFAULT_CURRENCY, currency }
	}
	return { ...DEFAULT_DECIMAL }
}

export const getCurrenciesFormats = (locale = 'ru', currency = null) => {
	const [, group, , decimal, fraction] = new Intl.NumberFormat(locale, getOptions(currency)).formatToParts(1234.56789)
	return {
		group: group.value,
		decimal: decimal.value,
		precision: fraction.value.length,
	}
}

export const currencyToNumber = (sum, locale = 'ru', currency = null) => {
	const { group, decimal } = getCurrenciesFormats(locale, currency)
	return sum ? Number(String(sum).replaceAll(group, '').replace(decimal, '.')) : 0
}

export const formatCurrency = (sum, locale = 'ru', currency = null) => new Intl.NumberFormat(locale, getOptions(currency)).format(sum)

export const formatCurrencyCell = (row, column, cellValue) => formatCurrency(cellValue)