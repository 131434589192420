<template>
	<el-input
		:ref="'quantity' + cartItemId"
		v-model="cnt"
		maxlength="2"
		class="input-cnt"
		data-ta="service-cnt"
		@input="onChangeCnt"
		@focus="onFocus"
	/>
</template>

<script>
export default {
	name: 'QuantityInput',
	props: {
		cartItemId: {
			type: String,
			required: true,
		},
		count: {
			type: Number,
			required: true,
		},
	},
	data() {
		return {
			cnt: 0,
		}
	},
	watch: {
		count: {
			immediate: true,
			handler(cnt) {
				this.cnt = cnt
			},
		},
	},
	methods: {
		onChangeCnt(cnt) {
			const intVal = parseInt(cnt)

			if (intVal >= 0) {
				this.cnt = intVal
				this.$emit('change-cnt', intVal)
			} else {
				this.cnt = ''
				this.$emit('change-cnt', 0)
			}
		},
		onFocus() {
			this.$refs[`quantity${this.cartItemId}`].select()
		},
	},
}
</script>