import i18n from '~/plugins/i18n'

const CLIENT_PRESCRIPTION_ID = 'CLIENT_PRESCRIPTION'
const DOCTOR_PRESCRIPTION_ID = 'DOCTOR_PRESCRIPTION'
const CLIENT_NO_CHECK_PRESCRIPTION_ID = 'CLIENT_NO_CHECK_PRESCRIPTION'
const CLIENT_LENSES_PRESCRIPTION_ID = 'CLIENT_LENSES_PRESCRIPTION'

export const PRESCRIPTION_BASES = {
	[CLIENT_PRESCRIPTION_ID]: i18n.global.t('Рецепт клиента'),
	[DOCTOR_PRESCRIPTION_ID]: i18n.global.t('Результат проверки зрения'),
	[CLIENT_NO_CHECK_PRESCRIPTION_ID]: i18n.global.t('Со слов клиента'),
	[CLIENT_LENSES_PRESCRIPTION_ID]: i18n.global.t('По линзам клиента'),
}