<template>
	<div class="layout-part-buttons-group gap-4">
		<div
			v-if="label"
			class="layout-part-buttons-group__label"
		>{{ label }}</div>

		<div v-if="dropdownMode">
			<el-dropdown
				v-for="item of buttonsList"
				:key="item.name"
				:trigger="item.trigger || 'click'"
				class="layout-part-dropdown"
				:placement="item.placement || 'bottom'"
				@command="item.action"
			>
				<el-button
					:type="item.type"
					:data-ta="item.dataTa"
					class="layout-part-dropdown__button"
				>
					<ItIcon
						v-if="item.icon"
						:name="item.icon"
					/>

					<span>{{ item.label }}</span>
				</el-button>

				<template #dropdown>
					<el-dropdown-menu
						class="layout-part-dropdown-menu"
					>
						<div
							v-if="item.menuTitle"
							class="layout-part-dropdown-menu__title"
						>{{ item.menuTitle }}</div>

						<el-dropdown-item
							v-for="control of item.controls"
							:key="control.name"
							:command="control.name"
							class="layout-part-dropdown-menu__item"
						>{{ control.label }}</el-dropdown-item>
					</el-dropdown-menu>
				</template>
			</el-dropdown>
		</div>

		<el-button-group v-else>
			<el-button
				v-for="item of buttonsList"
				:key="item.name"
				:type="item.type"
				:data-ta="item.dataTa"
				@click="item.action"
			>
				<ItIcon
					v-if="item.icon"
					:name="item.icon"
				/>

				<span>{{ item.label }}</span>
			</el-button>
		</el-button-group>
	</div>
</template>

<script>
export default {
	name: 'LayoutPartButtonsGroup',
	props: {
		buttonsList: {
			type: Array,
			default: () => [],
		},
		label: {
			type: String,
			default: '',
		},
		dropdownMode: {
			type: Boolean,
			default: false,
		},
	},
}
</script>

<style lang="scss">

.layout-part-buttons-group {
	display: flex;
	justify-content: space-between;
	font-size: 12px !important;

	&__label {
		padding-left: 16px;
		border-left: 1px solid var(--el-border-color-base);
		display: flex;
		align-items: center;
		font-size: 12px;
	}
}

.layout-part-dropdown {
	&__button {
		border: none !important;
		border-radius: 0 !important;
	}

	&:first-child .layout-part-dropdown__button {
		border-top-left-radius: 4px !important;
		border-bottom-left-radius: 4px !important;
		border-right: 0.9px solid #ffffff80 !important;
	}

	&:last-child .layout-part-dropdown__button {
		border-top-right-radius: 4px !important;
		border-bottom-right-radius: 4px !important;
		border-left: 0.9px solid #ffffff80 !important;
	}
}

.layout-part-dropdown-menu {
	&__title {
		padding: 4px 15px;
		font-size: 12px;
		font-weight: 600;
	}

	&__item {
		font-size: 12px !important;
	}
}
</style>