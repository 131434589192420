<template>
	<div
		v-if="fields"
		class="layout-parts-filters"
	>
		<transition name="bounce">
			<div
				v-show="(showAdditional && !popupProps?.isPopup) || !isAdditional"
			>
				<LayoutPartFiltersFields
					ref="filtersFields"
					v-model="model"
					:fields="fields"
					:columns="columns"
					:apply-immediately="applyImmediately"
					:class="isAdditional ? 'mt-4' : ''"
					:focus="focus"
					:label-position="labelPosition"
					@on-mounted="init"
				>
					<template #buttons>
						<LayoutPartFiltersButtons
							v-if="!isAdditional && hasFiltersButtons"
							:has-toggle-btn="hasAdditional"
							:is-hide-filters-btn="showAdditional"
							:selected-additional-filters="selectedAdditionalFilters"
							:disable-buttons="disableButtons"
							:hide-buttons="hideButtons"
							:class="[buttonsPosition]"
							@update-filters="onClickUpdateFilters"
							@reset-filters="onClickResetFilters"
							@toggle-additional="onClickToggleAdditional"
						/>
					</template>
				</LayoutPartFiltersFields>
			</div>
		</transition>

		<LayoutPartFiltersPopup
			ref="LayoutPartFiltersFieldsPopup"
			v-model="model"
			:popup-props="popupProps"
			@filter="onFilterFromPopup"
		>
			<template #default>
				<LayoutPartFiltersFields
					v-if="fields"
					ref="filtersFields"
					v-model="model"
					:fields="fields"
					:columns="columns"
					:apply-immediately="true"
					:focus="focus"
					:label-position="labelPosition"
					class="mt-4"
				/>

				<el-divider/>

				<GoodsParams
					v-if="popupProps?.hasGoodsParams"
					ref="goodsParams"
					v-model="model"
					:general-rule-types="popupProps.generalRuleTypes"
				/>
			</template>
		</LayoutPartFiltersPopup>
	</div>
</template>

<script>
import isEqual from 'lodash/isEqual'
import GoodsParams from '~/components/goods/GoodsParams.vue'
import { getDefaultData } from '~/components/goods/data/getDefaultData.js'
import LayoutPartFiltersFields from './LayoutPartFiltersFields.vue'
import LayoutPartFiltersButtons from './LayoutPartFiltersButtons.vue'
import LayoutPartFiltersPopup from './LayoutPartFiltersPopup.vue'

export default {
	name: 'LayoutPartFilters',
	components: {
		LayoutPartFiltersFields,
		LayoutPartFiltersButtons,
		LayoutPartFiltersPopup,
		GoodsParams,
	},
	props: {
		fields: {
			type: Object,
			default: null,
		},
		hasAdditional: {
			type: Boolean,
			default: false,
		},
		isAdditional: {
			type: Boolean,
			default: false,
		},
		showAdditional: {
			type: Boolean,
			default: false,
		},
		applyImmediately: {
			type: Boolean,
			default: true,
		},
		saveState: {
			type: Boolean,
			default: false,
		},
		saveStatePermanent: {
			type: Boolean,
			default: false,
		},
		saveStateKey: {
			type: String,
			default: 'filters.fields',
		},
		focus: {
			type: String,
			default: 'auto',
		},
		additionalFilters: {
			type: Object,
			default: null,
		},
		additionalFiltersFields: {
			type: Object,
			default: null,
		},
		persistentFocus: {
			type: Boolean,
			default: true,
		},
		hideButtons: {
			type: Object,
			default: () => ({}),
		},
		disableButtons: {
			type: Object,
			default: () => ({}),
		},
		popupProps: {
			type: Object,
			default: null,
		},
		hasPopup: {
			type: Boolean,
			default: false,
		},
		labelPosition: {
			type: String,
			default: 'top',
		},
		hasFiltersButtons: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			model: {},
			initialized: 0,
			columns: 12,
		}
	},
	computed: {
		componentsState() {
			return this.$store.getters['user/gComponentsState'] || {}
		},
		buttonsPosition() {
			let buttonsPosition = `col-span-3 col-end-${this.columns + 1} row-start-1`

			if (!this.isAdditional && this.hasAdditional) {
				buttonsPosition = `col-span-5 col-end-${this.columns + 1} row-start-1`
			}

			return buttonsPosition
		},
		selectedAdditionalFilters() {
			let amount = 0

			if (this.additionalFiltersFields) {
				Object.entries(this.additionalFiltersFields)
					.forEach(([filterKey, filterField]) => {
						const filterValue = this.additionalFilters?.[filterKey]

						if (filterValue && filterValue !== filterField.defaultValue) {
							if (typeof filterValue === 'string' && !filterValue?.length) return
							amount += 1
						}

						if (filterField.type === 'daterangePicker') {
							const [defaultFrom, defaultTo] = filterField.defaultValue
							const fromValue = this.additionalFilters?.[filterField.fromName]
							const toValue = this.additionalFilters?.[filterField.toName]

							if (fromValue !== defaultFrom || toValue !== defaultTo) {
								amount += 1
							}
						}
					})
			}

			if (this.hasPopup) {
				const defaultData = getDefaultData()
				Object.entries(defaultData)
					.forEach(([key, defaultValue]) => {
						if (this.additionalFilters?.[key] === undefined) return

						if (Array.isArray(defaultValue)) {
							amount += Number(this.additionalFilters[key].length !== defaultValue.length)
						} else if (this.additionalFilters[key] !== defaultValue) {
							amount += 1
						}
					})
			}
			return amount
		},
	},

	watch: {
		model: {
			deep: false,
			handler(val, oldVal) {
				if (val && this.initialized) {
					if (isEqual(val, oldVal)) {
						return
					}
					this.setQueryFromFilters()
					if (!this.popupProps?.isPopup) {
						this.emitFiltersEvent()
					}
				}
			},
		},
	},

	methods: {
		init() {
			this.setDefaultValues()

			if (this.saveState) {
				this.setFilterFromSavedState()
			} else {
				this.setFilterFromQuery()
			}

			this.setQueryFromFilters()

			this.$nextTick(() => {
				this.initialized = true
				this.$emit('on-init', this.model)
			})
		},
		getDatarangeFieldByKey(fields, key) {
			return Object.values(fields).find(item => {
				if (item.type === 'daterangePicker') {
					return item.fromName === key || item.toName === key
				}
				return false
			})
		},
		// eslint-disable-next-line no-unused-vars
		getFieldByKey(key) {
			if (!this.fields) {
				return null
			}
			if (this.fields[key]) {
				return this.fields[key]
			}

			return this.getDatarangeFieldByKey(this.fields, key)
		},
		setDefaultValues() {
			this.model = this.getDefaultValues()
		},
		getDefaultValues() {
			const defaultModel = {}

			Object.entries(this.fields).forEach(([key, field]) => {
				if (field.type === 'daterangePicker') {
					defaultModel[field.fromName] = field.defaultValue[0] // eslint-disable-line
					defaultModel[field.toName] = field.defaultValue[1] // eslint-disable-line
				} else if (field) {
					defaultModel[key] = field.defaultValue ?? null
				}
			})

			return defaultModel
		},
		setFilterFromSavedState() {
			const filtersData = this.componentsState[this.saveStateKey] || {}
			Object.entries(filtersData).forEach(([key, value]) => {
				const field = this.getFieldByKey(key)
				if (!field || field.disableSavingState) return

				this.model[key] = value
			})
		},
		setFilterFromQuery() {
			const query = { ...this.$route.query }
			return this.mergeFilters(query)
		},
		setQueryFromFilters() {
			const query = { ...this.$route.query }
			Object.entries(this.model).forEach(([key, value]) => {
				const field = this.getFieldByKey(key)
				if (!field || field.disableUsingQuery) {
					return
				}

				if (Array.isArray(value)) { // multiple select
					if (value.length) {
						query[key] = value.map(i => i && String(i))
					} else if (query[key]) {
						query[key] = null
					}
				} else if (value === null || value === '' || value === undefined) {
					delete query[key]
				} else {
					query[key] = String(value) // this.$route.query all values is String type
				}
			})

			if (!isEqual(query, this.$route.query)) {
				this.$router.push({ query })
				return true
			}
			return false
		},
		saveFieldsState() {
			let data = { ...this.model }

			data = Object.entries(data).reduce((res, [key, value]) => {
				const field = this.getFieldByKey(key)
				if (field && !field.disableSavingState) {
					res[key] = value
				}
				return res
			}, {})

			if (this.saveStatePermanent) {
				this.$store.commit('user/mSetComponentState', {
					[this.saveStateKey]: data,
				})
			} else {
				this.$store.commit('user/mSetComponentStateSession', {
					[this.saveStateKey]: data,
				})
			}
		},
		mergeFilters(filters = {}) {
			let isChanged = false

			const model = { ...this.model }
			Object.entries(filters).forEach(([key, value]) => {
				const field = this.getFieldByKey(key)
				if (!field) {
					return
				}

				let val = value

				if (['switch', 'checkbox'].includes(field.type) || (field.isBoolean && ['true', 'false'].includes(value))) {
					if (val === 'true') val = true
					if (val === 'false') val = false
				}

				if (field.multiple && typeof value === 'string') {
					// multiple select with 1 selected option is string
					isChanged = isChanged || model[key] !== [value]
					model[key] = value.split(',')
				} else {
					isChanged = isChanged || model[key] !== val
					model[key] = val
				}
			})
			this.model = model

			return isChanged
		},
		emitFiltersEvent(mode = 'change') {
			const eventName = `on-filters-${mode}`

			this.$emit(eventName, this.model, this.isAdditional)

			if (this.saveState) {
				this.saveFieldsState()
			}

			if (this.persistentFocus) {
				this.$refs.filtersFields.focusOnMounted()
			}
		},
		onClickUpdateFilters() {
			this.$emit('on-update')
		},
		getFiltersData() {
			return this.$refs.filtersFields.getFiltersData()
		},
		onClickResetFilters() {
			this.$emit('on-reset-filters')
		},
		onClickToggleAdditional() {
			this.$emit('toggle-additional')
		},
		resetFilters() {
			if (this.$refs.goodsParams) {
				this.$refs.goodsParams.resetAllFields()
			}
			this.setDefaultValues()
			this.emitFiltersEvent('reset')
		},
		onShowFiltersFieldsPopup() {
			this.$refs.LayoutPartFiltersFieldsPopup.onShow()
		},
		onFilterFromPopup() {
			if (!this.$refs.goodsParams.validate()) return
			this.$refs.LayoutPartFiltersFieldsPopup.onClose()
			this.$refs.filtersFields.updateFilters()
			this.emitFiltersEvent()
		},
		cleanUpSavedState() {
			const defaultModel = this.getDefaultValues()

			if (this.saveStatePermanent) {
				this.$store.commit('user/mSetComponentState', {
					[this.saveStateKey]: defaultModel,
				})
			} else {
				this.$store.commit('user/mSetComponentStateSession', {
					[this.saveStateKey]: defaultModel,
				})
			}
		},
	},
}
</script>


<style lang="scss">

.layout-parts-filters {
	.layout-part-buttons-group {
		width: 100%;
	}

	&__update-btn,
	&__reset-btn {
		width: 108px;
		padding-right: 13px;
		padding-left: 13px;
	}

	&__toggle-btn {
		width: 149px;
		padding-left: 11px !important;
		padding-right: 11px !important;

		&-badge {
			background: var(--el-color-danger);
			width: 16px;
			height: 16px;
			border-radius: 50%;
			font-size: 10px;
			color: var(--el-color-white);
			margin-left: 8px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	.el-input,
	.el-date-editor,
	.el-date-editor.el-input,
	.el-date-editor.el-input__wrapper {
		--el-input-height: var(--el-filters-controls-height);
	}

	.el-form-item {
		position: relative;
	}

	.el-form-item.el-form-item--small {
		margin-bottom: 0;
	}

	.el-form-item.el-form-item--small .el-form-item__label {
		padding-bottom: 0;
	}

	.bounce-enter-active {
		animation: bounce-in 0.4s;
	}

	.bounce-leave-active {
		animation: bounce-in 0.4s reverse;
	}

	@keyframes bounce-in {
		0% {
			max-height: 0;
		}

		100% {
			max-height: 100px;
		}
	}
}
</style>