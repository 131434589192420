import { getMonths, getWeekDays } from '~/utils/localeConsts'
import { parseCronFormat } from '~/utils/cron'

export async function removeRule(method, path) {
	try {
		await this.$confirm(this.$t('Вы точно хотите удалить правило?'), this.$t('Внимание'), {
			confirmButtonText: this.$t('Удалить'),
			cancelButtonText: this.$t('Отмена'),
			type: this.$t('warning'),
		})

		this.isLoading = true

		if (path.includes('bonus/rules')) {
			await this.$http.optima2[method](`${path}/${this.id}`)
		} else {
			await this.$http.optima[method](`${path}/${this.id}`)
		}

		this.$refs.ReturnButton.goBack()
	} catch (error) {
		if (error === 'cancel' || error === 'close') return
		this.$notifyUserAboutError(error)
	} finally {
		this.isLoading = false
	}
}

export function parseCronFormatWords(cron, locale) {
	const parsedCron = parseCronFormat(cron)

	const monthsLocale = getMonths(locale)
	// В крон формате понедельник всегда первый
	const weekDaysLocale = getWeekDays({ locale, isSundayFirst: true })

	const output = []

	if (parsedCron.months.length) output.push(`${this.$t('Месяцы')}: ${parsedCron.months.map(month => monthsLocale[month - 1]).join(', ')}`)

	switch (parsedCron.daysType) {
	case 'weekday': {
		output.push(`${this.$t('Еженедельно')}: ${parsedCron.period.weekday.map(weekday => weekDaysLocale[weekday - 1]).join(', ')}`)
		break
	}
	case 'day': default: {
		const outputDay = [this.$t('Ежедневно')]

		if (parsedCron.period.day.length > 0) outputDay.push(`${this.$t('с(от)')} ${parsedCron.period.day[0]} ${this.$t('по')} ${parsedCron.period.day[1]}`)

		output.push(outputDay.join(': '))

		break
	}
	case 'date': {
		output.push(`${this.$t('Числа месяца')}: ${parsedCron.period.date.join(', ')}`)
	}
	}

	return output.join('; ')
}