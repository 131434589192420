import { defineAsyncComponent } from 'vue'
import { USER_AUTHORITIES } from '~/utils/consts'
import LayoutJournal2 from '~/layout/LayoutJournal2.vue'
import LayoutList from '~/layout/LayoutList.vue'
import i18n from '~/plugins/i18n'

export default [
	{
		name: 'ClientFiles',
		path: '/client/:id/files',
		component: LayoutJournal2,
		meta: {
			title: 'Файлы клиента',
		},
		props: {
			journalName: 'ClientFiles',
			Page: defineAsyncComponent(() => import('~/pages/client/ClientFiles')),
		},
	},
	{
		name: 'ClientsJournal',
		path: '/clients-journal',
		component: LayoutJournal2,
		meta: {
			title: 'Журнал клиентов',
		},
		props: {
			journalName: 'Clients',
			Page: defineAsyncComponent(() => import('~/pages/clients-journal/ClientsJournal')),
		},
	},
	{
		name: 'DepartmentsJournal',
		path: '/departments-journal',
		component: LayoutJournal2,
		meta: {
			title: 'Журнал департаментов',
		},
		props: {
			journalName: 'Departments',
			Page: defineAsyncComponent(() => import('~/pages/departments/DepartmentsJournal')),
		},
	},
	{
		name: 'GlassesJournal',
		path: '/glasses-journal',
		component: LayoutJournal2,
		meta: {
			title: 'Журнал очков',
		},
		props: {
			journalName: 'Glasses',
			Page: defineAsyncComponent(() => import('~/pages/workshop/glasses-journal/GlassesJournal')),
		},
	},
	{
		name: 'LegalEntitiesJournal',
		path: '/legal-entities',
		component: LayoutJournal2,
		meta: {
			title: 'Журнал юридических лиц',
		},
		props: {
			journalName: 'LegalEntities',
			Page: defineAsyncComponent(() => import('~/pages/legal-entities/LegalEntitiesJournal')),
		},
	},
	{
		name: 'LicensesJournal',
		path: '/licenses',
		component: LayoutJournal2,
		meta: {
			title: 'Журнал лицензий',
		},
		props: {
			journalName: 'Licenses',
			Page: defineAsyncComponent(() => import('~/pages/licenses/LicensesJournal')),
		},
	},
	{
		name: 'SalesPlans',
		path: '/sales-plans',
		component: LayoutJournal2,
		meta: {
			title: 'Планы продаж',
			accessGranted: [
				USER_AUTHORITIES.ROLE_MANAGER,
				USER_AUTHORITIES.ROLE_STORE,
				USER_AUTHORITIES.ROLE_TOP_MANAGER,
				USER_AUTHORITIES.ROLE_DOCTOR_STORE,
				USER_AUTHORITIES.ROLE_ACCOUNTANT,
			],
		},
		props: {
			journalName: 'SalesPlans',
			Tabs: [{
				tab: '',
				name: 'SalesPlans',
				label: i18n.global.t('По магазинам'),
				component: defineAsyncComponent(() => import('~/pages/sales-plan/SalesPlansJournal')),
			}, {
				tab: 'general',
				name: 'SalesPlansGeneral',
				label: i18n.global.t('Общие'),
				component: defineAsyncComponent(() => import('~/pages/sales-plan/SalesPlansGeneral')),
				notAvailableToRoles: [
					USER_AUTHORITIES.ROLE_STORE,
					USER_AUTHORITIES.ROLE_DOCTOR_STORE,
				],
			}],
		},
	},
	{
		name: 'UsersJournal',
		path: '/users-journal',
		component: LayoutJournal2,
		meta: {
			title: 'Журнал пользователей',
		},
		props: {
			journalName: 'Users',
			Page: defineAsyncComponent(() => import('~/pages/users/UsersJournal')),
		},
	},
	{
		name: 'OrdersCompletedJournal',
		path: '/orders-completed',
		component: LayoutJournal2,
		meta: {
			title: 'Выдача заказа',
		},
		props: {
			journalName: 'OrdersCompleted',
			Page: defineAsyncComponent(() => import('~/pages/orders-journals/OrdersCompletedJournal')),
		},
	},
	{
		path: '/orders-journal/:tab?',
		name: 'Orders',
		component: LayoutJournal2,
		meta: {
			title: 'Журнал заказов',
			accessGranted: [
				USER_AUTHORITIES.ROLE_MANAGER,
				USER_AUTHORITIES.ROLE_DOCTOR,
				USER_AUTHORITIES.ROLE_STORE,
				USER_AUTHORITIES.ROLE_TOP_MANAGER,
				USER_AUTHORITIES.ROLE_DOCTOR_STORE,
				USER_AUTHORITIES.ROLE_ACCOUNTANT,
				USER_AUTHORITIES.ROLE_SECRETARY,
			],
		},
		props: {
			journalName: 'Orders',
			Tabs: [{
				tab: '',
				name: 'Orders',
				label: i18n.global.t('Все заказы'),
				component: defineAsyncComponent(() => import('~/pages/orders-journals/OrdersJournal')),
			}, {
				tab: 'expected-date',
				name: 'OrdersExpectedDate',
				label: i18n.global.t('С ожидаемой датой выдачи'),
				component: defineAsyncComponent(() => import('~/pages/orders-journals/OrdersExpectedDateJournal')),
				notAvailableToRoles: [
					USER_AUTHORITIES.ROLE_DOCTOR,
				],
			}],
		},
	},
	{
		name: 'TransfersJournal',
		path: '/transfers-journal',
		component: LayoutJournal2,
		props: {
			journalName: 'Transfers',
			Page: defineAsyncComponent(() => import('~/pages/transfers-journals/TransfersJournal')),
		},
		meta: {
			title: 'Журнал трансферов',
			accessGranted: [
				USER_AUTHORITIES.ROLE_TOP_MANAGER,
				USER_AUTHORITIES.ROLE_MANAGER,
				USER_AUTHORITIES.ROLE_ACCOUNTANT,
				USER_AUTHORITIES.ROLE_DEPOT,
				USER_AUTHORITIES.ROLE_PRODUCTION,
				USER_AUTHORITIES.ROLE_STORE,
			],
		},
	},
	{
		name: 'AccessControlJournal',
		path: '/access-control/:tab?',
		component: LayoutJournal2,
		meta: {
			title: 'Журнал контроля доступа',
			accessGranted: [
				USER_AUTHORITIES.ROLE_ADMIN,
				USER_AUTHORITIES.ROLE_TOP_MANAGER,
			],
		},
		props: {
			journalName: 'AccessControl',
			Tabs: [
				{
					tab: '',
					name: 'AccessControl',
					label: i18n.global.t('Журнал доступа'),
					component: defineAsyncComponent(() => import('~/pages/access-control/AccessControlJournal')),
				}, {
					tab: 'statistics',
					name: 'AccessControlStatistics',
					label: i18n.global.t('Статистика'),
					component: defineAsyncComponent(() => import('~/pages/access-control/AccessControlStatisticsJournal')),
				},
			],
		},
	},
	{
		name: 'ExtrasJournal',
		path: '/extras-journal/:tab?',
		component: LayoutJournal2,
		meta: {
			title: 'Журнал массовки',
			accessGranted: [
				USER_AUTHORITIES.ROLE_MANAGER,
				USER_AUTHORITIES.ROLE_TOP_MANAGER,
				USER_AUTHORITIES.ROLE_ACCOUNTANT,
				USER_AUTHORITIES.ROLE_DEPOT,
				USER_AUTHORITIES.ROLE_PRODUCTION,
				USER_AUTHORITIES.ROLE_STORE,
			],
		},
		props: {
			journalName: 'Extras',
			Tabs: [
				{
					tab: '',
					name: 'ExtrasGroups',
					label: i18n.global.t('Группы массовок'),
					component: defineAsyncComponent(() => import('~/pages/extras-journal/ExtrasGroupsJournal')),
					notAvailable(store) {
						return !store?.state?.settings?.hasGroupExtrasOrders
					},
				}, {
					tab: 'single',
					name: 'Extras',
					label: i18n.global.t('Массовка'),
					component: defineAsyncComponent(() => import('~/pages/extras-journal/ExtrasJournal')),
				},
			],
		},
	},
	{
		name: 'Reevaluations',
		path: '/reevaluations/:tab?',
		component: LayoutJournal2,
		props: {
			journalName: 'Reevaluations',
			Tabs: [
				{
					tab: 'wholesale',
					name: 'WholesaleReevaluations',
					label: i18n.global.t('Оптовые'),
					component: defineAsyncComponent(() => import('~/pages/reevaluations-journal/WholesaleReevaluationsJournal')),
					notAvailable(store) {
						const isStore = store?.getters['user/gRoles']?.isStore
						const hasBasePrice = store?.getters?.gSettings?.hasBasePrice
						return !hasBasePrice || isStore
					},
				},
				{
					tab: '',
					name: 'Reevaluations',
					label: i18n.global.t('Розничные'),
					component: defineAsyncComponent(() => import('~/pages/reevaluations-journal/ReevaluationsJournal')),
				},
			],
		},
		meta: {
			accessGranted: [
				USER_AUTHORITIES.ROLE_TOP_MANAGER,
				USER_AUTHORITIES.ROLE_MANAGER,
				USER_AUTHORITIES.ROLE_STORE,
				USER_AUTHORITIES.ROLE_DEPOT,
				USER_AUTHORITIES.ROLE_ACCOUNTANT,
			],
		},
	},
	{
		path: '/cancellations-journal/:tab?',
		name: 'Сancellations',
		component: LayoutJournal2,
		meta: {
			title: 'Журнал списаний',
			accessGranted: [
				USER_AUTHORITIES.ROLE_MANAGER,
				USER_AUTHORITIES.ROLE_TOP_MANAGER,
				USER_AUTHORITIES.ROLE_ACCOUNTANT,
				USER_AUTHORITIES.ROLE_STORE,
				USER_AUTHORITIES.ROLE_DEPOT,
				USER_AUTHORITIES.ROLE_PRODUCTION,
			],
		},
		props: {
			journalName: 'Сancellations',
			Tabs: [{
				tab: '',
				name: 'Cancellations',
				label: i18n.global.t('Товары'),
				component: defineAsyncComponent(() => import('~/pages/cancellations-journals/CancellationsJournal')),
			}, {
				tab: 'materials',
				name: 'CancellationsMaterials',
				label: i18n.global.t('Материал клиента'),
				component: defineAsyncComponent(() => import('~/pages/cancellations-journals/CancellationsMaterialsJournal')),
				notAvailable(store) {
					return !store?.state?.settings?.clientGoodsCancellations
				},
			}],
		},
	},

	// TODO переделать на шаблон страницы
	{
		name: 'Directory',
		path: '/directories/:category',
		component: LayoutList,
		meta: {
			title: 'Справочник',
		},
		props: {
			Page: defineAsyncComponent(() => import('~/pages/directories/Directory')),
		},
	},

	{
		name: 'ReturnsJournal',
		path: '/returns-journal',
		component: LayoutJournal2,
		meta: {
			title: 'Журнал возвратов',
		},
		props: {
			journalName: 'Returns',
			Page: defineAsyncComponent(() => import('~/pages/returns-journal/ReturnsJournal')),
			buttons: false,
		},
	},
	{
		name: 'AcceptTransferJournal',
		path: '/accept-transfer-journal',
		component: LayoutJournal2,
		props: {
			journalName: 'AcceptTransfer',
			Page: defineAsyncComponent(() => import('~/pages/transfers-journals/AcceptTransferJournal')),
		},
		meta: {
			title: 'Прием трансфера',
			accessGranted: [
				USER_AUTHORITIES.ROLE_STORE,
				USER_AUTHORITIES.ROLE_DEPOT,
				USER_AUTHORITIES.ROLE_PRODUCTION,
			],
		},
	},
	{
		name: 'RevisionsJournal',
		path: '/revisions-journal',
		component: LayoutJournal2,
		meta: {
			title: 'Журнал ревизий',
			accessGranted: [
				USER_AUTHORITIES.ROLE_TOP_MANAGER,
				USER_AUTHORITIES.ROLE_MANAGER,
				USER_AUTHORITIES.ROLE_ACCOUNTANT,
				USER_AUTHORITIES.ROLE_DEPOT,
				USER_AUTHORITIES.ROLE_PRODUCTION,
				USER_AUTHORITIES.ROLE_STORE,
			],
		},
		props: {
			journalName: 'Revisions',
			Page: defineAsyncComponent(() => import('~/pages/revisions-journal/RevisionsJournal')),
		},
	},
	{
		name: 'WorkshopJournal',
		path: '/workshop-journal',
		component: LayoutJournal2,
		props: {
			journalName: 'Workshop',
			Page: defineAsyncComponent(() => import('~/pages/workshop/workshop-journal/WorkshopJournal')),
		},
		meta: {
			accessGranted: [USER_AUTHORITIES.ROLE_PRODUCTION],
		},
	},
	{
		name: 'SuppliesJournal',
		path: '/supplies-journal/:tab?',
		component: LayoutJournal2,
		meta: {
			title: 'Журнал поставок',
			accessGranted: [
				USER_AUTHORITIES.ROLE_TOP_MANAGER,
				USER_AUTHORITIES.ROLE_MANAGER,
				USER_AUTHORITIES.ROLE_ACCOUNTANT,
				USER_AUTHORITIES.ROLE_STORE,
				USER_AUTHORITIES.ROLE_DEPOT,
			],
		},
		props: {
			journalName: 'Supplies',
			Tabs: [{
				tab: 'confirmed',
				name: 'ConfirmedSupplies',
				label: i18n.global.t('Подтвержденные'),
				component: defineAsyncComponent(() => import('~/pages/supplies-journal/ConfirmedSupplies')),
				notAvailable(store) {
					const { hasSuppliesInStore } = store?.state?.settings || {}
					return !!(!hasSuppliesInStore && store?.getters['user/gRoles']?.isStore)
				},
			}, {
				tab: 'saved',
				name: 'SavedSupplies',
				label: i18n.global.t('Сохраненные'),
				component: defineAsyncComponent(() => import('~/pages/supplies-journal/SavedSupplies')),
				notAvailable(store) {
					const { hasSuppliesInStore } = store?.state?.settings || {}
					return !!(!hasSuppliesInStore && store?.getters['user/gRoles']?.isStore)
				},
			}],
		},
	},
	{

		name: 'DiscountsJournal',
		path: '/discounts-journal/:tab?',
		component: LayoutJournal2,
		meta: {
			accessGranted: [
				USER_AUTHORITIES.ROLE_TOP_MANAGER,
				USER_AUTHORITIES.ROLE_MANAGER,
				USER_AUTHORITIES.ROLE_ACCOUNTANT,
				USER_AUTHORITIES.ROLE_STORE,
				USER_AUTHORITIES.ROLE_DOCTOR_STORE,
			],
		},
		props: {
			journalName: 'Discounts',
			Tabs: [
				{
					tab: '',
					name: 'DiscountsStandard',
					label: i18n.global.t('Стандартные'),
					component: defineAsyncComponent(() => import('~/pages/discounts-journals/StandardDiscountsJournal.vue')),
				},
				{
					tab: 'coupons',
					name: 'DiscountsCoupons',
					label: i18n.global.t('Купоны'),
					component: defineAsyncComponent(() => import('~/pages/discounts-journals/CouponsDiscountsJournal.vue')),
				},
				{
					tab: 'automatic',
					name: 'DiscountsAutomatic',
					label: i18n.global.t('Автоматические'),
					component: defineAsyncComponent(() => import('~/pages/discounts-journals/AutomaticDiscountsJournal.vue')),
				},
				{
					tab: 'client-card',
					name: 'DiscountsClientsCard',
					label: i18n.global.t('По карте клиента'),
					component: defineAsyncComponent(() => import('~/pages/discounts-journals/ClientCardDiscountsJournal.vue')),
				},
				{
					tab: 'cl-replacement',
					name: 'DiscountsCLReplacement',
					label: i18n.global.t('Замена КЛ'),
					component: defineAsyncComponent(() => import('~/pages/discounts-journals/CLReplacementDiscountsJournal.vue')),
				},
				{
					tab: 'markdown-discounts',
					name: 'DiscountsMarkdown',
					label: i18n.global.t('Уценка товара'),
					component: defineAsyncComponent(() => import('~/pages/discounts-journals/MarkdownDiscountsJournal.vue')),
				},
			],
		},
	},
	{
		name: 'BalanceJournal',
		path: '/balance',
		meta: {
			title: 'Баланс',
		},
		component: LayoutJournal2,
		props: {
			journalName: 'Balance',
			Page: defineAsyncComponent(() => import('~/pages/balance/BalanceJournal.vue')),
		},
	},
	{
		name: 'NewsJournal',
		path: '/news/list',
		meta: {
			title: 'Журнал новостей',
		},
		component: LayoutJournal2,
		props: {
			journalName: 'News',
			Page: defineAsyncComponent(() => import('~/pages/news/NewsJournal.vue')),
		},
	},
	{
		name: 'GiftCertificates',
		path: '/gift-certificates/:tab?',
		meta: {
			title: i18n.global.t('Журнал подарочных сертификатов'),
			accessGranted: [
				USER_AUTHORITIES.ROLE_TOP_MANAGER,
				USER_AUTHORITIES.ROLE_MANAGER,
				USER_AUTHORITIES.ROLE_ACCOUNTANT,
			],
		},
		component: LayoutJournal2,
		props: {
			journalName: 'GiftCertificates',
			Tabs: [{
				tab: '',
				name: 'GiftCertificates',
				label: i18n.global.t('На текущий момент'),
				component: defineAsyncComponent(() => import('~/pages/gift-certificates/GiftCertificatesJournal')),
			}, {
				tab: 'selected-date',
				name: 'GiftCertificatesByDate',
				label: i18n.global.t('На выбранную дату'),
				component: defineAsyncComponent(() => import('~/pages/gift-certificates/GiftCertificatesByDateJournal')),
			}],
		},
	},
	{
		name: 'CompilanceRegisterJournal',
		path: '/compilance-register-journal',
		component: LayoutJournal2,
		props: {
			journalName: 'CompilanceRegister',
			Page: defineAsyncComponent(
				() => import('~/pages/compilance-register/CompilanceRegisterJournal.vue')
			),
		},
		meta: {
			accessGranted: [
				USER_AUTHORITIES.ROLE_TOP_MANAGER,
				USER_AUTHORITIES.ROLE_MANAGER,
				USER_AUTHORITIES.ROLE_ACCOUNTANT,
				USER_AUTHORITIES.ROLE_STORE,
				USER_AUTHORITIES.ROLE_DEPOT,
			],
		},
	},
	{
		name: 'MailingJournal',
		path: '/mailing/list/:tab',
		meta: {
			title: 'Рассылки',
			accessGranted: [
				USER_AUTHORITIES.ROLE_TOP_MANAGER,
				USER_AUTHORITIES.ROLE_MANAGER,
				USER_AUTHORITIES.ROLE_SECRETARY,
				USER_AUTHORITIES.ROLE_STORE,
				USER_AUTHORITIES.ROLE_DOCTOR_STORE,
				USER_AUTHORITIES.ROLE_DOCTOR,
			],
		},
		component: LayoutJournal2,
		props: {
			journalName: 'Mailing',
			Tabs: [{
				tab: 'sms',
				name: 'MailingSms',
				label: i18n.global.t('СМС'),
				component: defineAsyncComponent(() => import('~/pages/mailing/MailingSmsJournal')),
				notAvailable(store) {
					if (!store) return true

					const {
						distributionManagerMode,
						distributionSecretaryMode,
						distributionStoreMode,
						distributionDoctorMode,
					} = store.state?.settings || {}

					const {
						isTopManager,
						isManager,
						isDoctorStore,
						isStore,
						isSecretary,
						isDoctor,
					} = store.getters['user/gRoles'] || {}

					if (isManager) return !distributionManagerMode
					if (isDoctorStore) return !(distributionStoreMode || distributionDoctorMode)
					if (isStore) return !distributionStoreMode
					if (isSecretary) return !distributionSecretaryMode
					if (isDoctor) return !distributionDoctorMode
					if (isTopManager) return false

					return true
				},
			}, {
				tab: 'email',
				name: 'MailingEmail',
				label: i18n.global.t('Email'),
				component: defineAsyncComponent(() => import('~/pages/mailing/MailingEmailJournal')),
				notAvailable(store) {
					if (!store) return true

					const {
						distributionManagerMode,
						distributionSecretaryMode,
						distributionStoreMode,
						distributionDoctorMode,
					} = store.state?.settings || {}

					const {
						isTopManager,
						isManager,
						isDoctorStore,
						isStore,
						isSecretary,
						isDoctor,
					} = store.getters['user/gRoles'] || {}

					if (isManager) return !distributionManagerMode
					if (isDoctorStore) return !(distributionStoreMode || distributionDoctorMode)
					if (isStore) return !distributionStoreMode
					if (isSecretary) return !distributionSecretaryMode
					if (isDoctor) return !distributionDoctorMode
					if (isTopManager) return false

					return true
				},
			}],
		},
	},
	{
		name: 'MailingFailedJournal',
		path: '/mailing/:id/failed/:type',
		meta: {
			accessGranted: [
				USER_AUTHORITIES.ROLE_TOP_MANAGER,
				USER_AUTHORITIES.ROLE_MANAGER,
				USER_AUTHORITIES.ROLE_SECRETARY,
				USER_AUTHORITIES.ROLE_STORE,
				USER_AUTHORITIES.ROLE_DOCTOR_STORE,
				USER_AUTHORITIES.ROLE_DOCTOR,
			],
		},
		component: LayoutJournal2,
		props: {
			journalName: 'MailingFailed',
			Page: defineAsyncComponent(() => import('~/pages/mailing/MailingFailedJournal')),
		},
	},
	{
		name: 'ClientsFiltersJournal',
		path: '/clients-filters',
		meta: {
			accessGranted: [
				USER_AUTHORITIES.ROLE_TOP_MANAGER,
				USER_AUTHORITIES.ROLE_MANAGER,
				USER_AUTHORITIES.ROLE_SECRETARY,
				USER_AUTHORITIES.ROLE_STORE,
				USER_AUTHORITIES.ROLE_DOCTOR_STORE,
				USER_AUTHORITIES.ROLE_DOCTOR,
			],
		},
		component: LayoutJournal2,
		props: {
			journalName: 'ClientsFilters',
			Page: defineAsyncComponent(() => import('~/pages/clients-filters/ClientsFiltersJournal')),
		},
	},
]