<template>
	<el-popover
		ref="popover"
		:visible="visible"
		:width="308"
		:offset="10"
		popper-class="part-order-certificates"
		placement="bottom-end"
		trigger="manual"
		@hide="resetFormFields"
		@show="showPopover"
	>
		<template #reference>
			<el-button
				ref="btnPopover"
				type="primary"
				plain
				class="part-order-certificates__btn"
				data-ta="order-certificates-btn"
				@click="visible = !visible"
			>
				{{ $t('Сертификаты') }}
			</el-button>
		</template>

		<el-form
			ref="form"
			:rules="rules"
			:model="certificate"
			class="order-certificates"
			label-position="top"
			require-asterisk-position="right"
			@keydown.enter.prevent="confirmPopover"
		>
			<el-form-item
				v-if="isRequiredNumber"
				:label="fields.certificateNumber.label"
				:prop="fields.certificateNumber.name"
			>
				<CertificateConfirmPopover
					ref="number"
					v-model="certificate.number"
					:field="fields.certificateNumber"
					:certificate-length="certificateLength"
					@clearCertificateNum="clearCertificateNum"
				/>
			</el-form-item>
			<ComFieldCurrency
				:ref="fields.certificateSum.name"
				v-model="certificate.sum"
				:placeholder="fields.certificateSum.placeholder"
				is-edit
				:name="fields.certificateSum.name"
				:size="fields.certificateSum.size"
				:type="fields.certificateSum.type"
				:label="fields.certificateSum.label"
				:max-length="17"
				@clear="onClear"
			/>
		</el-form>

		<div class="d-flex justify-content-flex-end mt-6">
			<el-button
				type="primary"
				class="bold"
				plain
				@click="close"
			>
				{{ $t('Отмена') }}
			</el-button>
			<el-button
				type="primary"
				class="ml-3 bold"
				:disabled="!isDisabledCertificates"
				@click="confirmPopover"
			>
				{{ $t('Добавить') }}
			</el-button>
		</div>
	</el-popover>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { isNumber, isRequired, minNumber, specialNumberOfCharacters, hasWhitespaces } from '~/utils/validationRules'
import ComFieldCurrency from '~/components/ComFields/ComFieldCurrency.vue'
import CertificateConfirmPopover from './PartOrderCertificates/CertificateConfirmPopover.vue'
import { registerPopover, unregisterPopover } from '../data/clickOutsidePopover'

export default {
	name: 'PartOrderCertificates',
	components: {
		ComFieldCurrency,
		CertificateConfirmPopover,
	},
	data() {
		return {
			prop: 'certificates',
			width: '388',
			hasActions: true,
			certificate: {
				number: null,
				sum: null,
			},
			visible: false,
			fields: {
				certificateNumber: {
					label: this.$t('Номер'),
					placeholder: this.$t('Просканируйте или введите вручную'),
					name: 'number',
					size: 'default',
					type: 'input',
				},
				certificateSum: {
					label: this.$t('Номинал'),
					placeholder: this.$t('Введите сумму'),
					name: 'sum',
					type: 'input',
					size: 'default',
				},
			},
		}
	},
	computed: {
		...mapState('orders', [
			'cartUid',
		]),
		...mapGetters('orders', [
			'gCartInfo',
		]),
		...mapGetters(['gSettings']),

		isRequiredNumber() {
			return this.gCartInfo?.giftCertificateAddingMode === 'NUMBER_REQUIRED'
		},

		isDisabledCertificates() {
			return this.isRequiredNumber
				? (!!this.certificate.sum && !!this.certificate.number)
				: !!this.certificate.sum
		},
		certificateLength() {
			return this.gSettings.giftCertificateRealNumLength
		},

		rules() {
			const rules = {
				sum: [hasWhitespaces, isNumber, isRequired, minNumber(1)],
				number: [
					hasWhitespaces,
					isNumber,
					isRequired,
					specialNumberOfCharacters(this.certificateLength),
				],
			}

			return rules
		},
	},

	mounted() {
		registerPopover({
			group: 'certificates',
			ref: this.$refs.popover,
			closeFn: this.close,
			triggerRef: this.$refs.btnPopover,
		})
	},

	beforeUnmount() {
		unregisterPopover(this.$refs.popover)
	},

	methods: {
		...mapActions('orders', ['aAddCertificate']),

		async showPopover() {
			await this.$nextTick()
			if (this.$refs?.number) {
				this.$refs.number.focus()
			} else {
				this.$refs.sum.focus()
			}
		},

		resetFormFields() {
			this.$refs.form.resetFields()
		},

		async addCertificate() {
			this.$refs.form.validate(async valid => {
				if (valid) {
					const data = {}

					if (this.certificate.number) {
						data.number = this.certificate.number
					}
					if (this.certificate.sum) {
						data.sum = this.convertToNumber(this.certificate.sum)
					}

					const response = await this.aAddCertificate({
						uid: this.cartUid,
						data,
					})

					if (response?.status === 200) {
						this.$notify({ title: this.$t('Добавлено в корзину'), type: 'success' })
						this.certificate = {
							number: null,
							sum: null,
						}
					}
				}

				return false
			})
		},

		async confirmPopover() {
			this.$refs.form.validate(valid => {
				if (valid) {
					this.addCertificate()
					this.close()
					return true
				}
				return false
			})
		},

		convertToNumber(val) {
			return Number(String(val).replace(',', '.'))
		},
		close() {
			this.visible = false
		},
		clearCertificateNum() {
			this.certificate.number = null
			this.$refs.sum.resetField()
		},
		onClear() {
			this.certificate.sum = null
		},
	},
}
</script>

<style lang="scss">
.part-order-certificates {
	&__btn {
		width: 100%;
		font-weight: 600;
	}
}

.order-certificates {
	display: flex;
	flex-direction: column;
	gap: 12px;

	.el-form-item {
		margin-bottom: 0 !important;

		&__label {
			padding: 0 !important;
			justify-content: flex-start;
		}
	}
}
</style>