export default function () {
	return {
		order: null,
		orderType: null,
		cart: null,
		cartUid: null,
		orderNotes: {},
		barcodesLength: [],
		services: [],
		usersByService: {},
		unmarkedGoods: [],
		cartsDiscounts: [],
		orderErrors: {},
		infoFieldsOptions: [],
		infoFieldsSettings: [],
		extrasOrder: null,
		extrasGroupsOrder: null,
		orderExtrasById: null,
		orderExtrasGroupById: null,
		CLPrescriptions: [],
		CLNomenclature: null,
		orderServices: [],
		servicesCartUuid: null,
		servicesDiscounts: [],
		giftCertificatesStatuses: [],
	}
}