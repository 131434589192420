<template>
	<div
		:data-ta="`period_${name}`"
		class="com-field-item com-field-period"
	>
		<PartFieldView
			v-if="!isEdit"
			v-model="viewValue"
			:name="name"
			:label="label"
			:label-width="labelWidth"
			:label-color-dot="labelColorDot"
			:link-pattern="linkPattern"
			:show-label="showLabel"
			:is-html="isHtml"
			:on-click="onClick"
			:info-tooltip="infoTooltip"
		/>


		<el-form-item
			v-else
			:ref="name"
			:prop="name"
			:label="label"
			:label-width="labelWidth"
		>

			<PartInfoTooltip
				v-if="infoLabelTooltip"
				:info-tooltip="infoLabelTooltip"
				label-tooltip
			/>

			<div class="d-flex align-items-center w-100p">
				<el-date-picker
					v-model="model"
					type="daterange"
					align="right"
					unlink-panels
					range-separator="-"
					format="DD.MM.YYYY"
					:disabled="disabled || readonly"
					:start-placeholder="startPlaceholder"
					:end-placeholder="endPlaceholder"
					:picker-options="pickerOptions"
					:disabled-date="fieldDatepickerCheckDisabledDate"
					:clearable="clearable"
					:size="size"
					:value-format="valueFormat"
					:fallback-placements="['bottom']"
					class="w-100p"
				>
					<template
						v-if="rangeSeparator"
						#range-separator
					>
						<ItIcon
							:name="rangeSeparator.name"
							:width="rangeSeparator.width"
							:height="rangeSeparator.height"
							:class="rangeSeparator.class"
						/>
					</template>
				</el-date-picker>

				<PartInfoTooltip
					v-if="infoTooltip"
					:info-tooltip="infoTooltip"
				/>

			</div>
		</el-form-item>
	</div>
</template>

<script>
import {
	TODAY,
	CURRENT_WEEK,
	LAST_WEEK,
	LAST_MONTH,
	LAST_YEAR,
	CURRENT_MONTH,
	CURRENT_YEAR,
	SLIDING_WEEK,
	SLIDING_MONTH,
	SLIDING_YEAR,
	YESTERDAY,
} from '~/helpers/common/daterangeShortcuts'
import i18n from '~/plugins/i18n'
import defaultProps from './defaultFieldsProps'
import PartFieldView from './parts/PartFieldView'
import PartInfoTooltip from './parts/PartInfoTooltip'


const PICKER_OPTIONS_PRESETS = {
	empty: null,
	default: {
		shortcuts: [
			TODAY,
			CURRENT_WEEK,
			LAST_WEEK,
			LAST_MONTH,
			LAST_YEAR,
			CURRENT_MONTH,
			CURRENT_YEAR,
			SLIDING_WEEK,
			SLIDING_MONTH,
			SLIDING_YEAR,
			YESTERDAY,
		],
	},
}

export default {
	name: 'ComFieldPeriod',
	components: {
		PartFieldView,
		PartInfoTooltip,
	},
	props: {
		...defaultProps,
		pickerOptionsPreset: {
			type: String,
			default: 'default',
		},
		minDate: {
			type: [Object, String],
			default: null,
		},
		maxDate: {
			type: Object,
			default: null,
		},
		startPlaceholder: {
			type: String,
			default: i18n.global.t('Начало'),
		},
		endPlaceholder: {
			type: String,
			default: i18n.global.t('Конец'),
		},
		rangeSeparator: {
			type: Object,
			default: null,
		},
		valueFormat: {
			type: String,
			default: 'YYYY-MM-DD',
		},
		defaultValues: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			pickerOptions: '',
		}
	},
	computed: {
		model: {
			get() { return typeof this.modelValue === 'undefined' ? null : this.modelValue },
			set(value) {
				const [startDate, endDate] = value

				// сброс значений на дефолтные при ручном вводе в инпут
				if (this.fieldDatepickerCheckDisabledDate(this.$dayjs(startDate).$d)
				|| this.fieldDatepickerCheckDisabledDate(this.$dayjs(endDate).$d)
				) {
					this.$emit('change', this.defaultValues)
					this.$emit('update:modelValue', this.defaultValues)
					return
				}

				this.$emit('change', value)
				this.$emit('update:modelValue', value)
			},
		},
		viewValue() {
			if (this.modelValue) {
				const start = this.$dayjs(this.modelValue[0]).format('DD.MM.YYYY')
				const end = this.$dayjs(this.modelValue[1]).format('DD.MM.YYYY')
				return `${start}-${end}`
			}
			return '—'
		},
	},
	created() {
		this.pickerOptions = PICKER_OPTIONS_PRESETS[this.pickerOptionsPreset] || {}
	},
	methods: {
		fieldDatepickerCheckDisabledDate(date) {
			if (this.maxDate && date.getTime() > Date.parse(this.maxDate)) return true
			return this.minDate && date < Date.parse(this.minDate)
		},
		onOptionClick(val) {
			this.$emit('on-option-click', val)
		},
	},
}
</script>

<style lang="scss">
.el-picker-panel {
	&__sidebar,
	& *[slot=sidebar] {
		width: 170px !important;
		position: absolute !important;

		& + .el-picker-panel__body {
			margin-left: 170px !important;
		}
	}
}
</style>