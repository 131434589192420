import statisticsState from './statisticsState'
import statisticsActions from './statisticsActions'
import statisticsMutations from './statisticsMutations'
import statisticsGetters from './statisticsGetters'

export default {
	namespaced: true,
	state: statisticsState,
	getters: statisticsGetters,
	mutations: statisticsMutations,
	actions: statisticsActions,
}