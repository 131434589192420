import barcodesState from './barcodesState'
import barcodesActions from './barcodesActions'
import barcodesMutations from './barcodesMutations'
import barcodesGetters from './barcodesGetters'

export default {
	namespaced: true,
	state: barcodesState,
	getters: barcodesGetters,
	mutations: barcodesMutations,
	actions: barcodesActions,
}