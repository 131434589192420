import { createApp } from 'vue'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import ElementLocaleRu from 'element-plus/dist/locale/ru.min.mjs'
import ElementLocaleEn from 'element-plus/dist/locale/en.min.mjs'

import * as Sentry from '@sentry/vue'

import VueYandexMetrika from 'vue3-yandex-metrika'

import './assets/styles/index-optima.scss'
import './assets/styles/scss/index.scss'
import './assets/styles/custom-vars.scss'
import './assets/css/vue3dev.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

import setDocumentTitle from '~/mixins/common/setDocumentTitle'
import $noop from '~/plugins/noop'
import $notify from '~/plugins/notify'
import $confirm from '~/plugins/confirm'
import $alert from '~/plugins/alert'
import $http from '~/kit/plugins/http'

import Auth from '~/kit/plugins/auth'
import $gutter from '~/kit/plugins/common/gutter'
import $isFormValid from '~/kit/plugins/common/isFormValid'
import $notifyUserAboutError from '~/kit/plugins/common/notifyUserAboutError'
import $dayjs from '~/kit/plugins/dayjs'

import ItIcon from '~/kit/components/ItIcon'
import ComFilters from '~/components/ComFilters.vue'
import Integer from '~/kit/directives/Integer' // TODO: only Bonuses use, remove it
import router from '~/plugins/router'
import store from '~/plugins/store'
import i18n from '~/plugins/i18n'
import OldPageHeader from '~/components/old/OldPageHeader'

import App from './App.vue'
import { declareOldFrontMethods } from './utils/iframes/iframesLegacyPostMessages'
import getCurrentLocale, { LOCALE_RU, LOCALE_EN } from './utils/locales'

const isDev = import.meta.env.NODE_ENV !== 'development'
const locale = getCurrentLocale()

declareOldFrontMethods()

const elementLocales = {
	[LOCALE_RU]: ElementLocaleRu,
	[LOCALE_EN]: ElementLocaleEn,
}

const app = createApp(App)
	.use(i18n)
	.use(router)
	.use(store)
	.use($http)
	.use(ElementPlus, {
		locale: elementLocales[locale],
	})
	.use(Auth, {
		store,
		httpConfig: {
			accessTokenKey: 'accessToken',
			refreshTokenKey: 'refreshToken',
			useHttpInstance: 'optima',
			tokenUrl: '',
			authPath: {
				refresh: 'sign/refresh',
				login: 'sign/in',
			},
			useJSON: true,
			paramsToGetToken: ['login', 'password', 'key'],
			sso: true,
		},
	})
	.use($notifyUserAboutError)
	.use($isFormValid)
	.use($gutter, 16)
	.use($dayjs, locale)
	.use($noop)
	.use($notify)
	.use($confirm)
	.use($alert)

// eslint-disable-next-line no-restricted-syntax
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
	app.component(key, component)
}

if (!isDev) {
	Sentry.init({
		dsn: import.meta.env.VITE_GLITCHTIP_DSN,
		environment: import.meta.env.VITE_MODE,
		tracing: true,
		logErrors: true,
		ignoreErrors: [
			// TODO https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
			'ResizeObserver loop limit exceeded',
			'ResizeObserver loop completed with undelivered notifications',
			// Баг с Kaspersky antivirus
			'FD126C42-EBFA-4E12-B309-BB3FDD723AC1/main',
			'Request failed with status code 401',
			'Network Error', // Проблема с интернетом на клиенте
			'Ya is not defined',
			'Cannot read property \'_avast_submit\' of undefined',
		],
		// Filter out default `Vue` integration
		integrations: integrations => integrations.filter(integration => integration.name !== 'Vue'),
	});
	Sentry.addIntegration(Sentry.vueIntegration({ app }));
}

if (!isDev) {
	const metrikaId = Number(import.meta.env.VITE_PLUGIN_YA_METRIKA_ID)
	if (metrikaId) {
		app.use(VueYandexMetrika, {
			id: metrikaId,
			router,
			env: import.meta.env.VITE_MODE,
			options: {
				clickmap: true,
				trackLinks: true,
				accurateTrackBounce: true,
				webvisor: true,
				trackHash: true,
			},
		})
	}
}

app.directive('integer', Integer) // TODO: only Bonuses use, remove it
app.component('OldPageHeader', OldPageHeader)
app.component('ComFilters', ComFilters)
app.mixin(setDocumentTitle)
app.component('ItIcon', ItIcon)

// Проброс оповещений во vuex
const $nuae = {
	get() {
		return app.config.globalProperties.$notifyUserAboutError
	},
	enumerable: false,
	configurable: false,
}
const $ntf = {
	get() {
		return app.config.globalProperties.$notify
	},
	enumerable: false,
	configurable: false,
}
Object.defineProperty(store, '$notifyUserAboutError', $nuae)
Object.defineProperty(store, '$notify', $ntf)

app.mount('body')