<template>
	<div
		:data-ta="`textarea_${name}`"
		class="textarea com-field-item com-field-input"
	>
		<PartFieldView
			v-if="!isEdit"
			v-model="viewValue"
			:name="name"
			:label="label"
			:label-width="labelWidth"
			:label-color-dot="labelColorDot"
			:link-pattern="linkPattern"
			:show-label="showLabel"
			:is-html="isHtml"
			:on-click="onClick"
			:info-tooltip="infoTooltip"
		/>

		<el-form-item
			v-else
			:ref="name"
			:prop="name"
			:label="label"
			:label-width="labelWidth"
		>

			<PartInfoTooltip
				v-if="infoLabelTooltip"
				:info-tooltip="infoLabelTooltip"
				label-tooltip
			/>

			<div class="d-flex align-items-center w-100p">

				<el-input
					v-model="model"
					:placeholder="placeholder"
					:data-ta="name"
					:clearable="clearable"
					:disabled="disabled || readonly"
					:maxlength="maxLength"
					:show-word-limit="showWordLimit"
					:size="size"
					:autosize="autosize"
					type="textarea"
					@change="onChange"
					@input="onInput"
				/>

				<PartInfoTooltip
					v-if="infoTooltip"
					:info-tooltip="infoTooltip"
				/>

			</div>
		</el-form-item>
	</div>
</template>

<script>
import { mask } from 'vue-the-mask'
import defaultProps from './defaultFieldsProps'
import PartFieldView from './parts/PartFieldView'
import PartInfoTooltip from './parts/PartInfoTooltip'

export default {
	name: 'ComFieldTextarea',
	components: {
		PartFieldView,
		PartInfoTooltip,
	},
	directives: {
		mask,
	},
	props: {
		...defaultProps,
		autosize: {
			type: Object,
			default: () => ({ minRows: 2, maxRows: 8 }),
		},
		clearable: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		model: {
			get() { return typeof this.modelValue === 'undefined' ? null : this.modelValue },
			set(value) {
				this.$emit('update:modelValue', value)
			},
		},
		viewValue() {
			return this.modelValue || '—'
		},
	},
	methods: {
		onChange(val) {
			this.$emit('on-change', val)
		},
		onInput(val) {
			this.$emit('on-input', val)
		},
	},
}
</script>

<style lang="scss">
.textarea {
	.el-textarea__inner {
		resize: none !important;
	}

	.el-input__count {
		margin: 5px 0 5px 8px;
		box-sizing: border-box !important;
		height: 20px !important;
	}
}
</style>