import i18n from '~/plugins/i18n'

// Переводы в helpers.xlsx

export const STATUS_ID_ALL = 'ALL'
export const STATUS_ID_WAIT = 'WAIT'
export const STATUS_ID_ACCEPTED = 'ACCEPTED'
export const STATUS_ID_IN_WORK = 'IN_WORK'
export const STATUS_ID_ORDER_READY = 'ORDER_READY'
export const STATUS_ID_ORDER_COMPLETED = 'ORDER_COMPLETED'
export const STATUS_ID_CANCEL = 'CANCEL'
export const STATUS_ID_RETURN = 'RETURN'

const STATUSES = {
	[STATUS_ID_ALL]: {
		label: i18n.global.t('Все'),
		value: STATUS_ID_ALL,
	},
	[STATUS_ID_WAIT]: {
		label: i18n.global.t('Ожидает линз'),
		value: STATUS_ID_WAIT,
		color: '#909399',
		colorClass: 'bg-color-info',
	},
	[STATUS_ID_ACCEPTED]: {
		label: i18n.global.t('Принят'),
		value: STATUS_ID_ACCEPTED,
		color: '#1989FA',
		colorClass: 'bg-color-primary',
	},
	[STATUS_ID_IN_WORK]: {
		label: i18n.global.t('Изготовление'),
		value: STATUS_ID_IN_WORK,
		color: '#69C0FF',
		colorClass: 'bg-color-secondary',
	},
	[STATUS_ID_ORDER_READY]: {
		label: i18n.global.t('Готов'),
		value: STATUS_ID_ORDER_READY,
		color: '#FCE300',
		colorClass: 'bg-color-yellow',
	},
	/* ORDER_READY_IN_STORE: {
		label: i18n.global.t('Готов к выдаче'),
		value: 'ORDER_READY_IN_STORE',
		color: '#FCE300',
		colorClass: 'bg-color-yellow',
	}, */
	[STATUS_ID_ORDER_COMPLETED]: {
		label: i18n.global.t('Завершен'),
		value: STATUS_ID_ORDER_COMPLETED,
		color: '#1CAD4B',
		colorClass: 'bg-color-success',
	},
	[STATUS_ID_CANCEL]: {
		label: i18n.global.t('Отменен'),
		value: STATUS_ID_CANCEL,
		color: '#FB9E13',
		colorClass: 'bg-color-warning',
	},
	[STATUS_ID_RETURN]: {
		label: i18n.global.t('Возврат'),
		value: STATUS_ID_RETURN,
		color: '#FF3C3C',
		colorClass: 'bg-color-danger',
	},
}

// TODO для совместимости до замены ordersStatuses на ORDERS_STATUSES
export const ordersStatuses = STATUSES

export const ORDERS_STATUSES = Object.entries(STATUSES).reduce((acc, [k, v]) => (acc[k] = v.value, acc), {}) // eslint-disable-line
export const getOrderStatusTranslation = status => ordersStatuses[status]?.label || i18n.global.t('Неправильный статус заказа')
export const getOrderStatusBgClass = status => ordersStatuses[status]?.colorClass || 'bg-color-primary'