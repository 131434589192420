export default function cacheFn(fn, ctx, options) {
	const { cacheTimeout } = options
	const cache = {}
	return async value => {
		if (value in cache) {
			return cache[value]
		}
		const result = fn.call(ctx, value)
		cache[value] = result
		if (cacheTimeout) {
			setTimeout(() => {
				delete cache[value]
			}, cacheTimeout)
		}
		return result
	}
}