import { isMainIframe } from '../iframes'

export default function updateElMessageBoxIframeStyles() {
	if (!isMainIframe()) return

	const iframeScreen = window.iframeLegacyScreenPositions
	if (!iframeScreen) return

	const boxes = document.getElementsByClassName('el-message-box')
	const top = Math.max(0, iframeScreen.windowScrollY - iframeScreen.iframeOffsetTop) + 80
	const bottom = document.body.scrollHeight - iframeScreen.windowInnerHeight - iframeScreen.windowScrollY - iframeScreen.iframeOffsetTop

	Array.from(boxes).forEach(box => {
		box.style.boxSizing = 'border-box'
		box.style.verticalAlign = 'top'
		box.style.marginTop = `${top}px`
		box.style.marginBottom = `${bottom}px`
		box.style.marginRight = `${iframeScreen.horizontalMargins}px`
		box.style.transition = `margin 500ms;`
		// box.style.opacity = 1
	})
}

// const selectedStyleSheet = null

/* function updateElMessageBoxIframeStylesOld() {
	const iframeScreen = window.iframeLegacyScreenPositions
	if (!iframeScreen) return

	function getStyleSheet() {
		try {
			selectedStyleSheet = new CSSStyleSheet()
			selectedStyleSheet.replaceSync('.el-message-box__wrapper { box-sizing: border-box; }')
			document.adoptedStyleSheets = [selectedStyleSheet]
		} catch (e) {
			selectedStyleSheet = null
		}
		return selectedStyleSheet
	}

	const styleSheet = getStyleSheet()
	if (!styleSheet) return

	const top = Math.max(0, iframeScreen.windowScrollY - iframeScreen.iframeOffsetTop)
	const bottom = document.body.scrollHeight
		- iframeScreen.windowInnerHeight
		- iframeScreen.windowScrollY
		- iframeScreen.iframeOffsetTop

	const styleString = `
	box-sizing: border-box;
	padding-top: ${top}px;
	padding-bottom: ${bottom}px;
	`
	const selector = '.el-message-box__wrapper'
	const rule = styleSheet.cssRules
	if (rule && rule[0]?.selectorText === selector) {
		styleSheet.deleteRule(0)
	}

	styleSheet.insertRule(`${selector} {${styleString}}`, 0)
} */