import { GoodsTypes } from '~/helpers/common/GoodsTypes'
import RevisionsStatuses from '~/pages/revisions-journal/data/RevisionsStatuses'
import VuexHttp from '../../vuexHttpService'

const vHttp = new VuexHttp('revisions')

export default {
	namespaced: true,
	state: {
		goodsTypes: [],
		revisionsStatuses: [],
	},
	mutations: {
		mGoodsTypes(state, payload) {
			state.goodsTypes = payload
		},
		mRevisionsStatuses(state, payload) {
			state.revisionsStatuses = payload
		},
	},
	actions: {
		aGoodsTypes() {
			return vHttp.GET(this, {
				url: {
					path: 'revisions/goods-types',
				},
				mutation: 'mGoodsTypes',
			})
		},
		aRevisionsStatuses() {
			return vHttp.GET(this, {
				url: {
					path: 'revisions/statuses',
				},
				mutation: 'mRevisionsStatuses',
			})
		},
	},
	getters: {
		gGoodsTypes(state) {
			return state.goodsTypes
				.map(goodsType => GoodsTypes[goodsType])
		},
		gRevisionsStatuses(state) {
			return state.revisionsStatuses
				.map(status => RevisionsStatuses[status])
		},
	},
}