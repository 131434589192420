import VuexHttp from '../../vuexHttpService'

const vHttp = new VuexHttp('directories')

export default {
	async aDirectoryItemCreate(_, { dirInfo, data }) {
		const url = `${dirInfo.category}/${dirInfo.name}`

		return vHttp.POST(this, {
			url,
			data,
		})
	},

	async aDirectoryItemUpdate(_, { dirInfo, updDirectory, data, isNumeric }) {
		const url = `${dirInfo.category}/${dirInfo.name}`
		const params = isNumeric
			? { value: updDirectory }
			: { name: updDirectory }

		const options = { params }

		return vHttp.PUT(this, {
			url,
			data,
			options,
		})
	},

	async aDirectoryItemDelete(_, { dirInfo, isNumeric, delDirectory }) {
		const url = `${dirInfo.category}/${dirInfo.name}`
		const params = isNumeric
			? { value: delDirectory }
			: { name: delDirectory }

		const options = { params }

		return vHttp.DELETE(this, {
			url,
			options,
		})
	},

	async aAccessories() {
		return vHttp.GET(this, {
			url: 'taxes/not-privileged-accessory-categories',
			mutation: 'mAccessories',
		})
	},
}