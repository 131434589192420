<template>
	<div
		:data-ta="`range_${name}_${nameTo}`"
		class="com-field-item com-field-range-currency"
	>
		<PartFieldView
			v-if="!isEdit"
			v-model="viewValues"
			:name="name"
			:options="options"
			:label="label"
			:label-width="labelWidth"
			:label-color-dot="labelColorDot"
			:link-pattern="linkPattern"
			:show-label="showLabel"
			:is-html="isHtml"
			:on-click="onClick"
			:info-tooltip="infoTooltip"
		/>

		<el-form-item
			v-else
			:ref="name"
			:prop="name"
			:label="label"
			:label-width="labelWidth"
		>

			<PartInfoTooltip
				v-if="infoLabelTooltip"
				:info-tooltip="infoLabelTooltip"
				label-tooltip
			/>

			<div class="d-flex align-items-center w-100p">
				<span class="mr-2 com-field-range-currency__label">{{ placeholderFrom }}</span>
				<ComCurrencyInput
					ref="inputRef"
					v-model="from"
					v-loading="loading"
					:placeholder="placeholder"
					:data-ta="dataTaFrom || name"
					:clearable="clearable"
					:disabled="disabled || readonly"
					:options="options"
					:maxlength="maxLength"
					:show-word-limit="showWordLimit"
					:size="size"
					:prefix-icon="prefixIcon"
					:suffix-icon="suffixIcon"
					:suffix-char="suffixChar"
				/>

				<span class="mr-2 ml-2 com-field-range-currency__label">{{ placeholderTo }}</span>
				<ComCurrencyInput
					ref="inputRef"
					v-model="to"
					v-loading="loading"
					:placeholder="placeholder"
					:data-ta="dataTaTo || nameTo"
					:clearable="clearable"
					:disabled="disabled || readonly"
					:options="options"
					:maxlength="maxLength"
					:show-word-limit="showWordLimit"
					:size="size"
					:prefix-icon="prefixIcon"
					:suffix-icon="suffixIcon"
					:suffix-char="suffixChar"
				/>

				<el-tooltip
					v-if="deleteTooltip"
					:content="deleteTooltip"
					:disabled="!deleteTooltip"
					placement="right"
					effect="light"
				>
					<ItIcon
						v-if="deleteFn"
						name="trash"
						class="ml-6 clickable"
						@click="deleteFn"
					/>
				</el-tooltip>

				<PartInfoTooltip
					v-if="infoTooltip"
					:info-tooltip="infoTooltip"
				/>

			</div>
		</el-form-item>
	</div>
</template>

<script>
import i18n from '~/plugins/i18n'
import defaultProps from './defaultFieldsProps'
import PartFieldView from './parts/PartFieldView'
import PartInfoTooltip from './parts/PartInfoTooltip'
import ComCurrencyInput from '../ComCurrencyInput'

export default {
	name: 'ComFieldRangeCurrency',
	components: {
		ComCurrencyInput,
		PartFieldView,
		PartInfoTooltip,
	},
	props: {
		...defaultProps,
		nameTo: {
			type: String,
			required: true,
		},
		formatValue: {
			type: Function,
			default: null,
		},
		modelFrom: {
			type: [Number, String],
			default: 0,
		},
		modelTo: {
			type: [Number, String],
			default: 0,
		},
		valueKey: {
			type: String,
			default: null,
		},
		multiple: {
			type: Boolean,
			default: false,
		},
		fieldType: {
			type: String,
			default: 'text',
		},
		filterable: {
			type: Boolean,
			default: true,
		},
		placeholderFrom: {
			type: String,
			default: i18n.global.t('от'),
		},
		placeholderTo: {
			type: String,
			default: i18n.global.t('по'),
		},
		deleteFn: {
			type: Function,
			default: null,
		},
		deleteTooltip: {
			type: String,
			default: null,
		},
		emptyValueLabel: {
			type: String,
			default: '—',
		},
		dataTaFrom: {
			type: String,
			default: null,
		},
		dataTaTo: {
			type: String,
			default: null,
		},
		prefixIcon: {
			type: String,
			default: null,
		},
		suffixIcon: {
			type: String,
			default: null,
		},
		suffixChar: {
			type: String,
			default: null,
		},
	},
	computed: {
		from: {
			get() { return this.modelFrom ?? null },
			set(value) {
				const format = this.formatValue || this.$noop
				this.$emit('change', format(value))
				this.$emit('update:modelFrom', format(value))
			},
		},
		to: {
			get() { return this.modelTo ?? null },
			set(value) {
				const format = this.formatValue || this.$noop
				this.$emit('change', format(value))
				this.$emit('update:modelTo', format(value))
			},
		},
		viewValuesFrom() {
			if (!this.modelFrom) {
				return [{
					label: this.emptyValueLabel,
					value: null,
				}]
			}
			return Array.isArray(this.modelFrom) ? this.modelFrom : [this.modelFrom]
		},
		viewValuesTo() {
			if (!this.modelTo) {
				return [{
					label: this.emptyValueLabel,
					value: null,
				}]
			}
			return Array.isArray(this.modelTo) ? this.modelTo : [this.modelTo]
		},
		viewValues() {
			return [this.viewValuesFrom[0], this.viewValuesTo[0]]
		},
	},
}
</script>

<style lang="scss">
.com-field-range-currency {
	.el-form-item__content {
		line-height: 28px;
	}
	&__label {
		font-size: var(--el-form-label-font-size);
	}
}
</style>