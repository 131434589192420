import goodState from './goodState'
import goodActions from './goodActions'
import goodMutations from './goodMutations'
import goodGetters from './goodGetters'

export default {
	namespaced: true,
	state: goodState,
	getters: goodGetters,
	mutations: goodMutations,
	actions: goodActions,
}