import i18n from '~/plugins/i18n'

export default {
	ACCESSORY: i18n.global.t('Аксессуары'),
	CONTACT_LENS: i18n.global.t('Контактные линзы'),
	EXTRA: i18n.global.t('Массовка'),
	GLASSES: i18n.global.t('Оправы'),
	LENS: i18n.global.t('Линзы'),
	SUNGLASSES: i18n.global.t('Солнцезащитные очки'),
}
