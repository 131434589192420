<template>
	<div
		id="layout-page"
		:key="update"
		v-loading="isLoading"
	>
		<div class="d-flex justify-content-between mb-6">

			<LayoutPartTitle
				v-if="title"
				v-bind="layoutParams"
				@on-return="onReturn"
			/>

			<LayoutPartActionButtons
				v-if="topButtons && !isLoading && !buttonsShowOnlyBottom"
				ref="topControls"
				:use-buttons="topButtons"
				:is-edit="isEdit"
				:is-deleted="isDeleted"
				:is-changed="isChanged"
				:delete-confirmation-text="layoutParams.deleteConfirmation"
				:disable-buttons="disableButtons"
				size="small"
				@on-cancel="onCancel"
				@on-edit="onEdit"
				@on-delete="onDelete"
				@on-restore="onRestore"
				@on-save="onSave"
			/>
		</div>

		<div ref="page">
			<component
				:is="Page"
				:is-edit="isEdit"
			/>
		</div>


		<div class="layout-page__footer d-flex justify-content-between mt-8 align-items-center">
			<div class="flex-grow">
				<LayoutPartActionButtons
					v-if="bottomLeftButtons"
					:use-buttons="bottomLeftButtons"
					size="small"
				/>
			</div>
			<div
				v-if="(mxShowBottomButtons && !isLoading) || bottomButtons"
				class="flex-shrink"
			>
				<LayoutPartActionButtons
					ref="bottomControls"
					:use-buttons="bottomButtons"
					:is-edit="isEdit"
					:is-deleted="isDeleted"
					:is-changed="isChanged"
					:delete-confirmation-text="layoutParams.deleteConfirmation"
					:disable-buttons="disableButtons"
					size="small"
					@on-cancel="onCancel"
					@on-edit="onEdit"
					@on-delete="onDelete"
					@on-restore="onRestore"
					@on-save="onSave"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import { mapMutations, mapState, mapGetters } from 'vuex'
import cloneDeep from 'lodash/cloneDeep'
import mxLayouts from '~/mixins/layouts/mxLayouts'
import LayoutPartTitle from '~/layout/parts/LayoutPartTitle.vue'
import LayoutPartActionButtons from '~/layout/parts/LayoutPartActionButtons.vue'
import { DEFAULT_LAYOUT_PAGE_PARAMS } from './data/defaults'

export default {
	name: 'LayoutPage',
	components: {
		LayoutPartTitle,
		LayoutPartActionButtons,
	},
	mixins: [mxLayouts],
	provide() {
		return {
			layoutMethods: {
				forceUpdate: this.forceUpdateLayout,
				registerComponent: this.registerComponent,
				unregisterComponent: this.unregisterComponent,
				updateParams: this.updateParams,
				checkChanges: this.mxCheckChanges,
				setChangedState: this.mSetChangedState,
			},
		}
	},
	async beforeRouteLeave(to, from, next) {
		const res = await this.mxConfirmIfFormChanged()
		next(Boolean(res))
	},
	props: {
		Page: {  // eslint-disable-line
			type: [Function, Object],
			required: true,
		},
	},
	data() {
		return {
			isLoading: true,
			isSavingProceed: false,
			isDeletionProceed: false,
			isResoreProceed: false,
			deleteConfirmation: '',
			update: 0,
			layoutParams: DEFAULT_LAYOUT_PAGE_PARAMS(),
		}
	},
	computed: {
		...mapState('layout', [
			'tabsData',
			'isEdit',
			'isChanged',
			'isDeleted',
			'activeTab',
		]),
		...mapGetters('layout', ['gPageData']),
		title() {
			return this.layoutParams.title || ''
		},
		disableButtons() {
			const buttons = this.layoutParams?.disableButtons || {}
			return {
				...buttons,
				save: !this.isChanged || this.isSavingProceed,
				delete: buttons.delete || this.isDeletionProceed,
				restore: buttons.restore || this.isResoreProceed,
			}
		},
		buttonsShowOnlyBottom() {
			return this.layoutParams.buttonsShowOnlyBottom
		},
		topButtons() {
			return this.layoutParams.buttons?.top || this.layoutParams.buttons
		},
		bottomButtons() {
			return this.layoutParams.buttons?.bottom || this.layoutParams.buttons
		},
		bottomLeftButtons() {
			return this.layoutParams.buttons?.bottomLeft
		},
	},
	created() {
		this.mLayoutDropState()
	},
	methods: {
		...mapMutations('layout', [
			'mLayoutDropState',
			'mSetChangedState',
			'mSetEditState',
			'mSetDeletedState',
			'mRestorePageData',
			'mSetPageData',
			'mUpdatePageData',
		]),

		setButtonsState(payload) {
			this.pageButtonsState = {
				...this.pageButtonsState,
				...payload,
			}
		},

		onEdit() {
			if (this.componentsMethods.edit) {
				this.componentsMethods.edit()
			}
			this.mSetEditState(true)
		},

		async onCancel() {
			if (this.layoutParams.redefinition?.cancel) {
				this.layoutParams.redefinition.cancel()
				return
			}

			let cancelEdit = true

			if (this.isChanged) {
				cancelEdit = await this.mxCancelFormEdit()
			}

			if (cancelEdit) {
				await this.componentsMethods.cancel()
				this.loadComponentData()
				this.mSetEditState(false)
				this.mSetChangedState(false)
			}
		},

		async onDelete() {
			if (this.layoutParams.redefinition?.delete) {
				this.layoutParams.redefinition.delete()
				return
			}

			this.isDeletionProceed = true
			const deleteResult = await this.componentsMethods.delete()
			setTimeout(() => {
				this.isDeletionProceed = false
			}, 500)

			if (deleteResult) {
				if (this.layoutParams.deleteSuccessMessage) {
					this.$notify({ title: this.layoutParams.deleteSuccessMessage, type: 'success' })
				}
				this.mSetEditState(false)
				this.mSetChangedState(false)
				this.mSetDeletedState(true)
				if (this.componentsMethods.deleted) {
					this.componentsMethods.deleted()
				}
			} else if (this.layoutParams.deleteErrorMessage) {
				this.$notify({ title: this.layoutParams.deleteErrorMessage, type: 'error' })
			}
		},

		async onRestore() {
			if (this.layoutParams.redefinition?.restore) {
				this.layoutParams.redefinition.restore()
				return
			}

			this.isRestoreProceed = true
			const restoreResult = await this.componentsMethods.restore()
			setTimeout(() => {
				this.isRestoreProceed = false
			}, 500)

			if (restoreResult) {
				if (this.layoutParams.restoreSuccessMessage) {
					this.$notify({ title: this.layoutParams.restoreSuccessMessage, type: 'success' })
				}
				this.mSetEditState(false)
				this.mSetChangedState(false)
				this.mSetDeletedState(false)
			} else if (this.layoutParams.restoreErrorMessage) {
				this.$notify({ title: this.layoutParams.restoreErrorMessage, type: 'error' })
			}
		},

		async onSave() {
			if (this.layoutParams.redefinition?.save) {
				this.layoutParams.redefinition.save()
				return
			}

			const isValid = await this.componentsMethods.validation()
			if (!isValid) {
				return
			}

			this.isSavingProceed = true
			const saveResult = await this.componentsMethods.save()
			setTimeout(() => {
				this.isSavingProceed = false
			}, 500)

			if (saveResult === undefined) {
				throw new Error('Ф-я save() должна возвращать результат сохранения')
			}

			if (saveResult) {
				if (this.layoutParams.saveSuccessMessage) {
					this.$notify({ title: this.layoutParams.saveSuccessMessage,
						type: 'success',
						offset: this.layoutParams.offsetMessage || 0 })
				}
				await this.loadComponentData()
				this.mSetEditState(false)
				this.mSetChangedState(false)

				if (this.componentsMethods.saved) {
					this.componentsMethods.saved()
				}
			} else if (this.layoutParams.saveErrorMessage) {
				this.$notify({ title: this.layoutParams.saveErrorMessage, type: 'error' })
			}
		},

		async onReturn() {
			if (this.componentsMethods.return) {
				this.componentsMethods.return()
				return
			}

			await this.aReturn(this.layoutParams)

			this.mLayoutDropState()
		},

		forceUpdateLayout() {
			this.$emit('force-update-layout')
		},

		async loadComponentData() {
			const pageData = await this.componentCtx.loadData()
			this.componentCtx.data = cloneDeep(pageData)
			this.mSetPageData(pageData)
			this.mSetChangedState(false)
			this.isLoading = false
		},

		registerComponent(componentCtx) {
			if (!componentCtx.layoutParams) {
				throw new Error('Компонент страницы должен содержать объект с параметрами шаблона layoutParams')
			}
			if (!componentCtx.loadData) {
				throw new Error('Компонент страницы должен содержать метод loadData()')
			}

			this.mSetEditState(false)

			this.componentsWatchers = []
			this.componentsWatchers.push(componentCtx.$watch('layoutParams', this.updateParams))

			this.componentCtx = componentCtx
			this.mxLayoutParamsUpdate(componentCtx.layoutParams)

			this.layoutParams = {
				...DEFAULT_LAYOUT_PAGE_PARAMS(),
				...componentCtx.layoutParams,
			}

			this.loadComponentData()

			this.componentsMethods = {
				validation: componentCtx.validation,
				save: componentCtx.save,
				edit: componentCtx.edit,
				cancel: componentCtx.cancel,
				return: componentCtx.return,
				delete: componentCtx.delete,
				deleted: componentCtx.deleted,
				restore: componentCtx.restore,
				saved: componentCtx.saved,
			}

			this.$nextTick(() => {
				this.mxCheckupButtonsMethodsExistsOnThePageDI()
				this.mxCheckupBottomButtonsVisibilityDI()
			})
			this.addListeners()
		},

		unregisterComponent() {
			this.componentsMethods = {}
			this.componentsWatchers.forEach(fn => fn())
			this.componentsWatchers = []
			this.removeListeners()
		},

		updateParams(layoutParams) {
			this.mxLayoutParamsUpdate(layoutParams)
		},


		addListeners(element = null, events = ['input', 'focusout', 'click']) {
			this.listeners = {
				el: element || this.$refs.page.$el || this.$refs.page,
				events,
			}

			if (this.listeners.el) {
				this.listeners.events.forEach(e => this.listeners.el.addEventListener(e, this.mxCheckChanges))
			}
		},

		removeListeners() {
			if (this.listeners.el && this.listeners.events) {
				this.listeners.events.forEach(e => this.listeners.el.removeEventListener(e, this.mxCheckChanges))
			}
		},
	},
}
</script>

<style lang="scss">
	#layout-page {
		min-height: 650px;
	}

	.return-button {
		cursor: pointer
	}
</style>