import directoriesState from './directoriesState'
import directoriesActions from './directoriesActions'
import directoriesMutations from './directoriesMutations'
import directoriesGetters from './directoriesGetters'

export default {
	namespaced: true,
	state: directoriesState,
	getters: directoriesGetters,
	mutations: directoriesMutations,
	actions: directoriesActions,
}