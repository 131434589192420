<template>
	<div class="order-table-description">
		<div
			:class="{ 'column': hasTwoColumns }"
			data-ta="order-item-description"
		>
			<div
				class="order-table-description__params"
			>
				<span
					v-if="description.name"
					class="bold mr-1"
				>{{ description.name }}</span>

				<span
					v-if="description.color"
					class="mr-1"
				>{{ description.color }}</span>

				<span
					v-if="description.size"
					class="mr-1"
				>{{ description.size }}</span>

				<template v-if="params">
					<span
						v-for="(item, idx) of params"
						:key="idx"
						class="mr-1"
					>{{ item }}</span>
				</template>
			</div>
		</div>

		<div
			v-if="hasTwoColumns"
			class="order-table-description__divider"
		/>

		<div
			v-if="hasTwoColumns"
			data-ta="order-item-properties"
			class="order-table-description__properties column"
		>
			<template
				v-for="(field, idx) of recipeParams"
			>
				<span
					v-if="field || field === 0"
					:key="idx"
				>{{ field }}</span>
			</template>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ComOrderTableDescription',
	props: {
		description: {
			type: Object,
			default: () => ({}),
		},
		sellableCategory: {
			type: String,
			default: null,
		},
	},
	computed: {
		hasTwoColumns() {
			const sellableCategories = ['LENS', 'CONTACT_LENS', 'EXTRA']
			return sellableCategories.includes(this.sellableCategory)
		},

		params() {
			return this.description?.params
				? this.description.params
				: null
		},

		recipeParams() {
			return this.description?.recipeParams
				? this.description.recipeParams
				: null
		},
	},
}
</script>

<style lang="scss">

.order-table-description {
	display: flex;
	align-items: center;

	.column {
		max-width: 150px;
		width: 50%
	}

	&__params {
		display: flex;
		flex-wrap: wrap;
	}

	&__divider {
		background: var(--el-color-text-regular);
		width: 1px;
		height: 20px;
		margin: 0 10px;
	}

	&__properties {
		span::after {
			content: ' / '
		}

		span:last-child::after {
			display: none;
		}
	}
}
</style>