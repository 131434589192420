<template>
	<div class="com-order-table-discounts">
		<div
			v-for="discount of discounts"
			:key="discount.id"
			class="com-order-table-discounts__discount"
		>
			<span>{{ `${discount.label} (${discount.sum})` }}</span>
			<template v-if="discount.deletable">
				<el-tooltip
					v-model="isTooltipVisible"
					effect="dark"
					:content="$t('Удалить скидку')"
					placement="top"
				>
					<el-icon
						class="delete-icon ml-1"
						@click.stop="deleteDiscount"
					><Close/></el-icon>
				</el-tooltip>
			</template>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ComOrderTableDiscounts',
	props: {
		discounts: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			isTooltipVisible: false,
		}
	},
	watch: {
		isTooltipVisible: {
			handler(val) {
				this.$emit('isTooltipVisible', val)
			},
		},
	},
	methods: {
		deleteDiscount() {
			this.isTooltipVisible = false
			this.$emit('deleteDiscount')
		},
	},
}
</script>

<style lang="scss">

.com-order-table-discounts {
	display: flex;
	flex-direction: column;
	gap: 1px;

	&__discount {
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}

	.delete-icon {
		font-size: 1.1rem;
		color: var(--el-color-text-regular);
		padding: 0
	}
}
</style>