import isEqual from 'lodash/isEqual'
import cloneDeep from 'lodash/cloneDeep'
import VuexHttp from '../../vuexHttpService'

const vHttp = new VuexHttp('legalEntities')

export default {
	async aLegalEntities(_, params) {
		return vHttp.GET(this, {
			url: {
				path: 'legal-entities',
				query: {
					...params,
					size: 1000,
				},
			},
			mutation: 'mLegalEntities',
		})
	},

	async aLegalEntityDepartments(_, payload) {
		const { legalEntityId, ...otherParams } = payload

		return vHttp.GET(this, {
			url: {
				path: `legal-entities/${legalEntityId}/departments`,
				query: otherParams,
			},
			mutation: 'mLegalEntityDepartments',
		})
	},

	async aTaxSchemes() {
		return vHttp.GET(this, {
			url: {
				path: `tax-schemes`,
			},
			mutation: 'mTaxSchemes',
		})
	},
	async aLegalEntityCreate(_, data) {
		return vHttp.POST(this, {
			url: `legal-entities`,
			data,
		})
	},

	async aLicenseCreate(_, data) {
		return vHttp.POST(this, {
			url: `legal-entities/licences`,
			data,
		})
	},

	async aLicense(_, id) {
		return vHttp.GET(this, {
			url: {
				path: `legal-entities/licences/${id}`,
			},
			mutation: 'mLicense',
		})
	},
	async aLicenseUpdate(_, { id, data }) {
		return vHttp.PUT(this, {
			url: {
				path: `legal-entities/licences/${id}`,
			},
			data: {
				...data,
			},
		})
	},
	async aLicenseDelete(_, id) {
		return vHttp.DELETE(this, {
			url: `legal-entities/licences/${id}`,
			mutation: 'mLicense',
		})
	},
	async aLegalEntity(_, id) {
		return vHttp.GET(this, {
			url: `legal-entities/${id}`,
			mutation: 'mLegalEntity',
		})
	},
	async aLegalEntityUpdate(_, { id, data }) {
		return vHttp.PUT(this, {
			url: `legal-entities/${id}`,
			data,
		})
	},
	async aLegalEntityDelete(_, id) {
		return vHttp.DELETE(this, {
			url: `legal-entities/${id}`,
		})
	},
	async aTaxRules(_, id) {
		return vHttp.GET(this, {
			url: `legal-entities/${id}/tax-rules`,
			mutation: 'mTaxRules',
		})
	},
	async aProductsTypes() {
		return vHttp.GET(this, {
			url: `tax-rules/sellable-categories`,
			mutation: 'mProductsTypes',
		})
	},
	async aOrdersTypes() {
		return vHttp.GET(this, {
			url: `orders/types`,
			mutation: 'mOrdersTypes',
		})
	},
	async aServicesTypes() {
		return vHttp.GET(this, {
			url: `services/types`,
			mutation: 'mServicesTypes',
		})
	},
	async aServesTypesGroups() {
		return vHttp.GET(this, {
			url: 'serves/types/user-groups',
			mutation: 'mServesTypesGroups',
		})
	},
	async aAccessoriesTypes() {
		return vHttp.GET(this, {
			url: `accessories/categories`,
			mutation: 'mAccessoriesCategories',
		})
	},

	async aOrderTypesCreate(_, { id, data }) {
		return vHttp.POST(this, {
			url: `legal-entities/${id}/tax-rules/orders/types`,
			data,
		})
	},

	async aOrderTypesUpdate(_, { id, data }) {
		return vHttp.PUT(this, {
			url: `legal-entities/${id}/tax-rules/orders/types`,
			data,
		})
	},
	async aSellableCategoriesCreate(_, { id, data }) {
		return vHttp.POST(this, {
			url: `legal-entities/${id}/tax-rules/sellable-categories`,
			data,
		})
	},

	async aSellableCategoriesUpdate(_, { id, data }) {
		return vHttp.PUT(this, {
			url: `legal-entities/${id}/tax-rules/sellable-categories`,
			data,
		})
	},

	async aAccessoryCategoriesCreate(_, { id, params }) {
		return vHttp.POST(this, {
			url: `legal-entities/${id}/tax-rules/accessories/categories`,
			options: {
				params,
			},
			preventDuplicatedRequest: false,
		})
	},

	async aAccessoryCategoriesUpdate(_, { id, params }) {
		return vHttp.PUT(this, {
			url: `legal-entities/${id}/tax-rules/accessories/categories`,
			options: {
				params,
			},
			preventDuplicatedRequest: false,
		})
	},
	async aAccessoryCategoriesDelete(_, { id, data }) {
		return vHttp.DELETE(this, {
			url: `legal-entities/${id}/tax-rules/accessories/categories`,
			data,
			preventDuplicatedRequest: false,
		})
	},
	async aServesTypesCreate(_, { id, data }) {
		return vHttp.POST(this, {
			url: `legal-entities/${id}/tax-rules/serves/types`,
			data,
			preventDuplicatedRequest: false,
		})
	},
	async aServesTypesUpdate(_, { id, data }) {
		return vHttp.PUT(this, {
			url: `legal-entities/${id}/tax-rules/serves/types`,
			data,
			preventDuplicatedRequest: false,
		})
	},
	async aServesTypesDelete(_, { id, data }) {
		return vHttp.DELETE(this, {
			url: `legal-entities/${id}/tax-rules/serves/types`,
			data,
			preventDuplicatedRequest: false,
		})
	},

	async aGetChangedRules(_, { original, updated = {} }) {
		const changes = []

		Object.keys(original).forEach(key => {
			if (updated[key] === undefined) {
				changes.push({
					key,
					create: false,
					delete: true,
				})
			}
		})

		Object.keys(updated).forEach(key => {
			if (original[key] === undefined) {
				changes.push({
					key,
					value: updated[key],
					create: true,
				})
			} else if (!isEqual(original[key], updated[key])) {
				changes.push({
					key,
					value: updated[key],
					create: false,
				})
			}
		})

		return changes
	},

	async aRulesUpdate({ getters, dispatch }, { id, rules }) {
		const {
			orderTypes,
			sellableCategories,
			accessoryCategories,
			servesTypes,
			servesTaxScheme,
		} = getters.gTaxRules

		const cloneSellableCategories = cloneDeep(sellableCategories)

		// Добавляем servesTaxScheme в sellableCategories
		// Отправляюстя они тут, но приходят на первом уровне вложенности
		cloneSellableCategories.SERVE = servesTaxScheme.servesTaxScheme

		cloneSellableCategories.ACCESSORY = cloneSellableCategories.ACCESSORY.ACCESSORY

		// Проверка на изменения
		const orderTypesChanges = await dispatch('aGetChangedRules', {
			original: orderTypes,
			updated: rules.orderTypes,
		})
		const sellableCategoriesChanges = await dispatch('aGetChangedRules', {
			original: cloneSellableCategories,
			updated: rules.sellableCategories,
		})

		const accessoryCategoriesChanges = await dispatch('aGetChangedRules', {
			original: accessoryCategories,
			updated: rules.accessoryCategories,
		})

		const servesTypesChanges = await dispatch('aGetChangedRules', {
			original: servesTypes,
			updated: rules.servesTypes,
		})

		const requests = []

		orderTypesChanges.forEach(orderType => {
			const data = {
				taxScheme: orderType.value,
				orderType: orderType.key,
			}

			if (orderType.create) {
				requests.push(dispatch('aOrderTypesCreate', { id, data }))
			} else {
				requests.push(dispatch('aOrderTypesUpdate', { id, data }))
			}
		})

		sellableCategoriesChanges.forEach(sellableCategory => {
			const data = {
				taxScheme: sellableCategory.value,
				sellableCategory: sellableCategory.key,
			}
			if (sellableCategory.create) {
				requests.push(dispatch('aSellableCategoriesCreate', { id, data }))
			} else if (!sellableCategory.delete) {
				requests.push(dispatch('aSellableCategoriesUpdate', { id, data }))
			}
		})

		const deleteAccessoryData = {
			accessoryCategoryNames: [],
		}

		accessoryCategoriesChanges.forEach(accessoryCategory => {
			const params = {
				accessoryCategoryName: accessoryCategory.key,
			}

			if (accessoryCategory.create) {
				requests.push(dispatch('aAccessoryCategoriesCreate', {
					id,
					params: {
						...params,
						taxScheme: accessoryCategory.value,
					},
				}))
			} else if (accessoryCategory.delete) {
				deleteAccessoryData.accessoryCategoryNames
					.push(accessoryCategory.key)
			} else {
				requests.push(dispatch('aAccessoryCategoriesUpdate', {
					id,
					params: {
						...params,
						taxScheme: accessoryCategory.value,
					},
				}))
			}
		})

		if (deleteAccessoryData.accessoryCategoryNames.length) {
			requests.push(dispatch('aAccessoryCategoriesDelete', {
				id,
				data: deleteAccessoryData,
			}))
		}

		const deleteServiceData = {
			serveTypeIds: [],
		}

		servesTypesChanges.forEach(serveType => {
			const data = {
				taxScheme: serveType.value,
				serveTypeId: serveType.key,
			}

			if (serveType.create) {
				requests.push(dispatch('aServesTypesCreate', { id, data }))
			} else if (serveType.delete) {
				deleteServiceData.serveTypeIds.push(serveType.key)
			} else {
				requests.push(dispatch('aServesTypesUpdate', { id, data }))
			}
		})

		if (deleteServiceData.serveTypeIds.length) {
			requests.push(dispatch('aServesTypesDelete', {
				id,
				data: deleteServiceData,
			}))
		}

		const responses = await Promise.all(requests)

		return responses.length
			? responses
			: ''
	},

	async aServicesTypesCreate(_, { id, data }) {
		return vHttp.POST(this, {
			url: `legal-entities/${id}/tax-rules/serves/types`,
			data,
		})
	},
	async aAcceorriesTypesDelete(_, { id, options }) {
		return vHttp.DELETE(this, {
			url: `legal-entities/${id}/tax-rules/accessories/categories`,
			options,
		})
	},
	async aServicesTypesDelete(_, { id, options }) {
		return vHttp.DELETE(this, {
			url: `legal-entities/${id}/tax-rules/serves/types`,
			options,
		})
	},
	async aSections(_, id) {
		return vHttp.GET(this, {
			url: `legal-entities/${id}/sections`,
			mutation: 'mSections',
		})
	},
	async aSectionsUpdate(_, { id, data }) {
		const newData = Object.entries(data).map(([key, value]) => {
			const result = {}

			switch (key) {
			case 'GOODS-NON_TAXABLE':
				result.section = value
				result.category = 'GOODS'
				result.vatType = 'NON_TAXABLE'
				break
			case 'GOODS-NORMAL':
				result.section = value
				result.category = 'GOODS'
				result.vatType = 'NORMAL'
				break
			case 'GOODS-PRIVILEGED':
				result.section = value
				result.category = 'GOODS'
				result.vatType = 'PRIVILEGED'
				break
			case 'SERVES-NON_TAXABLE':
				result.section = value
				result.category = 'SERVES'
				result.vatType = 'NON_TAXABLE'
				break
			case 'SERVES-NORMAL':
				result.section = value
				result.category = 'SERVES'
				result.vatType = 'NORMAL'
				break
			case 'SERVES-PRIVILEGED':
				result.section = value
				result.category = 'SERVES'
				result.vatType = 'PRIVILEGED'
				break
			default:
				break
			}

			return result
		})

		return vHttp.PUT(this, {
			url: `legal-entities/${id}/sections`,
			data: newData,
		})
	},

	aDeleteLegalEntityDepartments(_, { legalEntityId, departmentsIds }) {
		const requests = []

		if (!departmentsIds.length) {
			return true
		}
		departmentsIds.forEach(departmentId => {
			const request = vHttp.DELETE(this, {
				url: `departments/${departmentId}/legal-entities/${legalEntityId}`,
			})
			requests.push(request)
		})
		return Promise.all(requests)
	},
	aUnusedDepartments(_, id) {
		return vHttp.GET(this, {
			url: `legal-entities/${id}/unused-departments`,
			mutation: 'mUnusedDepartments',
		})
	},
	aCreateLegalEntityDepartment(_, { data, params }) {
		const { id, departmentId } = params

		return vHttp.POST(this, {
			url: `departments/${departmentId}/legal-entities/${id}`,
			data,
		})
	},
	aUpdateLegalEntityDepartment({ getters }, { data, id }) {
		function getChangedDepartments(original, updated = []) {
			const changes = []
			updated.forEach((item, index) => {
				let departmentChanged = false

				if (item.account.label !== original[index].account.label) {
					departmentChanged = true
				}

				if (item.fiscalRegister.type !== original[index].fiscalRegister.type) {
					departmentChanged = true
				}

				return departmentChanged ? changes.push(item) : null
			})
			return changes
		}

		const { gLegalEntitiesDepartments } = getters
		const legalEntititesDepartmentsChanged = getChangedDepartments(gLegalEntitiesDepartments, data.content)
		const requests = []

		legalEntititesDepartmentsChanged.forEach(item => {
			const requestObject = {
				url: `departments/${item.department.id}/legal-entities/${id}`,
				data: {
					accountId: item.account.value,
					fiscalRegisterType: item.fiscalRegister.type,
				},
			}
			const request = vHttp.PUT(this, requestObject)
			requests.push(request)
		})

		return Promise.all(requests)
	},
}