
import CANCELLATION_STATUSES from '~/helpers/common/CancellationStatuses'
import CANCELLATION_GOODS from '~/helpers/common/CancellationGoods'

export default {
	gCancellationStatusesOptions(state) {
		return state.cancellationsStatuses.map(item => ({
			value: item,
			label: CANCELLATION_STATUSES[item].label,
		}))
	},
	gCancellationBasisesOptions(state) {
		return state.cancellationsBasises.map(item => ({
			value: item.name,
			label: item.name,
		}))
	},
	gCancellationGoodTypesOptions(state) {
		return state.cancellationsGoodsTypes.map(item => ({
			value: item,
			label: CANCELLATION_GOODS[item],
		}))
	},
}