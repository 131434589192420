export default {
	mDeliveryDetails(state, payload) {
		state.deliverDetails = payload
			.sort((a, b) => a.departmentName.localeCompare(b.departmentName))
	},
	mUserGroups(state, payload) {
		state.userGroups = payload
	},
	mDepartments(state, payload) {
		state.departments = payload
	},
	mNewsItem(state, payload) {
		state.newsItem = payload
	},
	mCanMarkIsRead(state, payload) {
		state.canMarkIsRead = payload
	}
}