<template>
	<div class="part-order-client-and-seller">
		<div
			v-if="clientInfo.fullName"
			class="part-order-client-and-seller__client"
			data-ta="order-client-btn"
			:class="isAnonimus && 'part-order-client-and-seller__client-btn--disabled'"
			@click="openOrderClientInfo"
		>
			<div class="d-flex flex-column ml-2 lh-16">
				<span>{{ $t('Клиент') }}</span>
				<strong
					class="mt-1 pr-6"
					:class="{ clickable: !isAnonimus }"
					data-ta="order-client-fullName"
				>{{ clientInfo.fullName }}</strong>
			</div>
		</div>
		<WidgetSellerSelect
			:has-label="true"
			:disabled="true"
		/>
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
	name: 'PartOrderClientAndSeller',
	components: {
		WidgetSellerSelect: defineAsyncComponent(() => import('~/widgets/WidgetSellerSelect.vue')),
	},
	computed: {
		...mapState('client', ['clientInfo']),
		...mapGetters('client', ['gAnonimusClient']),

		isAnonimus() {
			return +this.$route.params.id === this.gAnonimusClient.id
		},
	},
	methods: {
		openOrderClientInfo() {
			if (!this.isAnonimus) {
				window.userInfo(this.$route.params.id, true)
			}
		},
	},
}
</script>

<style lang="scss">

.part-order-client-and-seller {
	display: flex;
	align-items: center;

	&__client-btn {
		display: flex;
		align-items: center;
		cursor: pointer;

		&--disabled {
			cursor: default;
		}
	}

	.clickable {
		color: var(--el-color-primary);
	}
}
</style>