import isEqual from 'lodash/isEqual'

const sharedMutationsTypes = [
	'users/mSelectedSeller',
	'clientsAgreements/mClientAgreementData',
]
const stateUid = Number((Date.now() + Math.random()).toString().replace('.', '').substring(-8)).toString(32)

let iframeId // eslint-disable-line
let storeClosure


export default store => {
	storeClosure = store

	store.subscribe(mutation => {
		const { payload, type } = mutation
		if (sharedMutationsTypes.includes(type)) {
			let prevValue = sessionStorage.getItem(`mutation.${type}`)
			if (prevValue) {
				try {
					prevValue = JSON.parse(prevValue)
				} catch (e) {
					//
				}
			}
			if (!isEqual(prevValue, payload)) {
				sessionStorage.setItem(`mutation.${type}`, JSON.stringify(payload))
				window.iframesBroadcast('stateUpdated', {
					type,
					stateUid,
				})
			}
		}
	})
}

export const shareStateCleanup = () => {
	sharedMutationsTypes.forEach(type => {
		sessionStorage.removeItem(`mutation.${type}`)
	})
}

export const shareStateHydration = id => {
	iframeId = id
	sharedMutationsTypes.forEach(type => {
		let value = sessionStorage.getItem(`mutation.${type}`)
		if (value) {
			value = JSON.parse(value)
		}
		storeClosure.commit(type, value)
	})
}

export const shareStateUpdate = ({ type, uid }) => {
	if (type) {
		if (stateUid === uid) return
		let value = sessionStorage.getItem(`mutation.${type}`)
		if (value) {
			value = JSON.parse(value)
		}
		storeClosure.commit(type, value)
	}
}