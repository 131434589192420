export default {
	gEnoughBalanceForEmail(state) {
		return state.storeBalanceInfo?.enoughForEmail
			|| false
	},
	gEnoughBalanceForVk(state) {
		return state.storeBalanceInfo?.enoughForVk
			|| false
	},
	gEnoughBalanceForSms(state) {
		return state.storeBalanceInfo?.enoughForSms
			|| false
	},
}