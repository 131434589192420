import { handleInput, handleChange } from './utils'

const MAX_NUMBER = 999999999999999

const getOnInputValue = str => str
	.replace(/[,бю/]/g, '.') // меняем запятые, символы `бю/` на точки
	.replace(/[^0-9.-]/g, '') // убираем всё, кроме цифр, точек и минусов
	.replace(/^([^.]*\.)(.*)$/, (a, b, c) => b + c.replace(/\./g, '')) // убираем лишние точки
	.replace(/^(-?)(.*)$/, (a, b, c) => b + c.replace(/-/g, '')) // убираем лишние минусы
	.replace(/^(-?)[.]/, '$10.') // меняем первую точку на 0 с точкой
	.replace(/^(-?)0*(\d+\.*\d*)/, '$1$2') // убираем первые нули

const getOnChangeValue = str => getOnInputValue(str)
	.replace(/\.0*$/, '') // убираем точку на конце (или точку с нулями)
	.replace(/^(-?\d+\.\d*[^0*]|-?\d*[^.])0*$/, '$1') // убираем все последние нули в дробном числе
	.replace(/^-(0?)$/, '$1') // убираем лишний минус (если значение `-0` или `-`)

function getCorrectValue(value, { modifiers, value: expression } = {}) {
	const max = (modifiers?.options && expression?.max) ?? MAX_NUMBER
	const min = (modifiers?.options && expression?.min) ?? -1 * MAX_NUMBER

	let correctValue = value

	if (value > max) correctValue = max
	if (value < min) correctValue = min

	return String(correctValue)
}

function getInitialValueString(value, modifiers) {
	let valueString = String(value || (value === 0 ? 0 : ''))

	if (modifiers?.positive) {
		valueString = valueString.replace(/-/g, '')
	}

	return valueString
}

export function inputNumberTemplate(functionOnInput, functionOnChange) {
	return {
		bind(el, binding, vnode) {
			if (binding.value?.cancel) return

			const vm = vnode.componentInstance
			const isInputNumber = vm.$options._componentTag === 'el-input-number' // eslint-disable-line
			const inputRef = isInputNumber ? vm.$refs.input.$refs.input : vm.$refs.input

			vm.$off('input')
			vm.$off('change')

			const inputListener = vm.$listeners.input
			const changeListener = vm.$listeners.change

			if (isInputNumber) {
				vm.handleInput = handleInput.bind(vm)
				vm.handleInputChange = handleChange.bind(vm)
			}

			vm.$on('input', value => {
				const valueString = getInitialValueString(value, binding.modifiers)

				let newValue = getCorrectValue(functionOnChange(valueString), binding)
				const inputValue = getCorrectValue(functionOnInput(valueString))

				// проверка, что можно выставить ещё одну цифру после запятой
				if (isInputNumber && vm.precision !== undefined) {
					const fractional = inputValue.split('.')[1]

					if (fractional && fractional.length > vm.precision) return
				}

				if (isInputNumber) newValue = Number(newValue)

				/* в элементе вызывается метод setNativeInputValue в $nextTick,
					а нам нужно поменять значение native input после вызова этого метода */
				vm.$nextTick(() => {
					vm.$nextTick(() => {
						if (isInputNumber) vm.userInput = inputValue
						else inputRef.value = inputValue
					})
				})

				if ((vm.value !== newValue && newValue !== '-') && inputListener) inputListener(newValue)
			})

			vm.$on('change', value => {
				const valueString = getInitialValueString(value, binding.modifiers)

				let newValue = getCorrectValue(functionOnChange(valueString), binding)

				if (isInputNumber) newValue = Number(newValue)

				vm.$nextTick(() => {
					if (isInputNumber) vm.userInput = newValue
					else inputRef.value = newValue
				})

				if ((vm.value !== newValue && newValue !== '-') && inputListener) inputListener(newValue)

				if (changeListener) changeListener(newValue)
			})
		},
	}
}

export default inputNumberTemplate(getOnInputValue, getOnChangeValue)