import { STATUSES as ExtraApplicationsStatuses } from '~/helpers/common/extraApplicationsStatuses'

export default {
	gUsersProduction(state) {
		return state.usersProduction.map(item => ({
			value: String(item.id),
			label: item.shortName,
		}))
	},
	gDepartmentsStoresOptions(state) {
		return state.departmentsStores.map(item => ({
			value: String(item.id),
			label: item.name,
		}))
	},
	gDepartmentsListOptions(state) {
		return state.departmentsList.map(item => ({
			value: String(item.id),
			label: item.name,
		}))
	},
	gDepartmentsProductions(state) {
		return state.departmentsProductions.map(item => ({
			value: String(item.id),
			label: item.name,
		}))
	},
	gOrdersStatuses(state) {
		return state.ordersStatuses
	},
	gExtrasOrderStatusesOptions(state) {
		return state.extrasOrderStatuses
			.map(status => ({ value: status, label: ExtraApplicationsStatuses[status].label }))
	},
	gOrdersTypesWithExtraTypes(state) {
		return state.ordersTypesWithExtraTypes
	},
	gPrescriptionsBases(state) {
		return state.prescriptionsBases
	},
}