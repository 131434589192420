import cancellationsState from './cancellationsState'
import cancellationsActions from './cancellationsActions'
import cancellationsMutations from './cancellationsMutations'
import cancellationsGetters from './cancellationsGetters'

export default {
	namespaced: true,
	state: cancellationsState,
	getters: cancellationsGetters,
	mutations: cancellationsMutations,
	actions: cancellationsActions,
}