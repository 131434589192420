export function createCronFormat({ months, daysType, period }) {
	const currentPeriod = period[daysType] || []

	const seconds = '0'
	const minutes = '0'
	const hours = '0'

	let dayOfMonth = '0'
	const month = months.length > 0 ? months.join(',') : '*'
	let dayOfWeek = '0'

	if (daysType === 'day') {
		dayOfMonth = currentPeriod.length > 0 ? currentPeriod.join('-') : '*'
		dayOfWeek = '?'
	}

	if (daysType === 'date') {
		dayOfMonth = currentPeriod.length > 0 ? currentPeriod.join(',') : '*'
		dayOfWeek = '?'
	}

	if (daysType === 'weekday') {
		dayOfWeek = currentPeriod.length > 0 ? currentPeriod.join(',') : '*'
		dayOfMonth = '?'
	}

	if (daysType === 'everyday') {
		dayOfMonth = '*'
		dayOfWeek = '?'
	}

	const year = '*'

	return [seconds, minutes, hours, dayOfMonth, month, dayOfWeek, year].join(' ')
}


function getCronItem(cron) {
	if (cron === '*' || cron === '?') return []

	if (cron.includes('-')) return cron.split('-').map(Number)

	return cron.split(',').map(Number)
}

export function parseCronFormat(cron) {
	const [seconds, minutes, hours, dayOfMonth, month, dayOfWeek, year] = cron.split(' ') // eslint-disable-line

	const output = {
		months: [],
		daysType: '',
		period: {
			day: [],
			date: [],
			weekday: [],
		},
	}

	output.months = getCronItem(month)

	if (dayOfMonth === '?') {
		output.daysType = 'weekday'
		output.period.weekday = getCronItem(dayOfWeek)
	} else if (dayOfMonth === '*') {
		output.daysType = 'everyday'
	} else if (dayOfMonth.includes('-')) {
		output.daysType = 'day'
		output.period.day = getCronItem(dayOfMonth)
	} else {
		output.daysType = 'date'
		output.period.date = getCronItem(dayOfMonth)
	}

	return output
}