import i18n from '~/plugins/i18n'

export const PRESCRIPTION_TYPES = {
	CONTACT_LENS: {
		label: i18n.global.t('Контактные линзы'),
		shortLabel: i18n.global.t('Конт. линзы'),
		value: 'CONTACT_LENS',
	},
	GLASSES: {
		label: i18n.global.t('Рецепт на очки'),
		value: 'GLASSES',
	},
}

export const PRESCRIPTION_TYPES_MAP = {
	CONTACT_LENSES: PRESCRIPTION_TYPES.CONTACT_LENS,
	CONTACT_LENS: PRESCRIPTION_TYPES.CONTACT_LENS,
	GLASSES: PRESCRIPTION_TYPES.GLASSES,
}

export const GoodsTypes = PRESCRIPTION_TYPES // для совместимости