export default {
	gSettings(state) {
		return state.settings
	},
	gPhoneMask(state) {
		return `${state.settings.phoneCode || ''} ${state.settings.phoneMask || '(###) ###-##-##'}`
	},
	gLanguagesOptions(state) {
		return state.languages.map(i => ({ value: i.code, label: i.localeName }))
	},
}