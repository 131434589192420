<template>
	<div
		:data-ta="`date_${name}`"
		class="com-field-item com-field-date"
	>
		<PartFieldView
			v-if="!isEdit"
			v-model="viewValue"
			:name="name"
			:label="label"
			:label-width="labelWidth"
			:label-color-dot="labelColorDot"
			:link-pattern="linkPattern"
			:show-label="showLabel"
			:is-html="isHtml"
			:on-click="onClick"
			:info-tooltip="infoTooltip"
		/>

		<el-form-item
			v-else
			:ref="name"
			:prop="name"
			:label="label"
			:label-width="labelWidth"
			:rules="manualInputValidation"
		>

			<PartInfoTooltip
				v-if="infoLabelTooltip"
				:info-tooltip="infoLabelTooltip"
				label-tooltip
			/>
			<el-input
				v-if="manualInput"
				v-model="manualModel"
				v-mask="dateMask"
				v-loading="loading"
				:placeholder="placeholder"
				:data-ta="name"
				:clearable="clearable"
				:disabled="disabled"
				:readonly="readonly"
				:maxlength="maxLength"
				:show-word-limit="showWordLimit"
				:size="size"
				@blur="updateModel"
			/>

			<div
				v-else
				class="d-flex align-items-center w-100p"
				:data-ta="name"
			>
				<el-date-picker
					v-model="model"
					type="date"
					:placeholder="placeholder"
					:format="format"
					:value-format="valueFormat"
					:disabled="disabled || readonly"
					:disabled-date="fieldDatepickerCheckDisabledDate"
					:picker-options="{
						firstDayOfWeek: 1,
					}"
					:size="size"
					:popper-class="popperClass"
					:fallback-placements="['bottom']"
					class="w-100p"
				/>

				<PartInfoTooltip
					v-if="infoTooltip"
					:info-tooltip="infoTooltip"
				/>

			</div>
		</el-form-item>
	</div>
</template>

<script>

import { mask } from 'vue-the-mask'
import defaultProps from './defaultFieldsProps'
import PartFieldView from './parts/PartFieldView'
import PartInfoTooltip from './parts/PartInfoTooltip'

export default {
	name: 'ComFieldDate',
	components: {
		PartFieldView,
		PartInfoTooltip,
	},
	directives: {
		mask,
	},
	props: {
		...defaultProps,
		format: {
			type: String,
			default: 'DD.MM.YYYY',
		},
		valueFormat: {
			type: String,
			default: 'YYYY-MM-DD',
		},
		minDate: {
			type: String,
			default: '',
		},
		maxDate: {
			type: String,
			default: '',
		},
		emptyValueLabel: {
			type: String,
			default: '—',
		},
		manualInput: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			manualModel: '',
		}
	},
	computed: {
		model: {
			get() {
				if (typeof this.modelValue === 'undefined') return null
				if (!this.manualInput) return this.modelValue
				return this.$dayjs(this.modelValue).format(this.format)
			},
			set(value) {
				this.$emit('change', value)
				this.$emit('update:modelValue', value)
			},
		},
		viewValue() {
			return this.modelValue
				? this.$dayjs(this.modelValue).format(this.format)
				: this.emptyValueLabel
		},
		dateMask() {
			// TODO change date formats for another regions
			return this.format.replace(/[A-Z]/g, '#')
		},
		manualInputValidation() {
			if (!this.manualInput) {
				return null
			}

			const DEFAULT_DATE = 978220800000 // '2000-12-31'

			return [{
				trigger: 'change',
				validator: (rule, value, callback) => {
					const defDate = this.$dayjs(DEFAULT_DATE).format(this.format)
					const compositeDate = this.manualModel + defDate.slice(this.manualModel.length)
					if (!this.$dayjs(compositeDate.slice(0, this.format.length), this.format, true).isValid()) {
						callback(this.$t('Некорректная дата'))
						return
					}
					callback()
				},
			}, {
				trigger: 'blur',
				validator: (rule, value, callback) => {
					if (this.manualModel.length !== this.format.length) {
						callback(this.$t('Некорректная дата'))
						return
					}
					if (this.$dayjs(this.manualModel, this.format) > this.$dayjs(this.maxDate)) {
						callback(this.$t('Дата должна быть меньше {0}', [this.maxDate]))
						return
					}
					if (this.$dayjs(this.manualModel, this.format) < this.$dayjs(this.minDate)) {
						callback(this.$t('Дата должна быть больше {0}', [this.minDate]))
						return
					}
					callback()
				},
			}]
		},
	},
	watch: {
		modelValue() {
			this.manualModel = this.$dayjs(this.modelValue).format(this.format)
		},
	},
	methods: {
		fieldDatepickerCheckDisabledDate(date) {
			if (this.maxDate && date.getTime() > Date.parse(this.maxDate)) return true
			return this.minDate && date < Date.parse(this.minDate)
		},
		onOptionClick(val) {
			this.$emit('on-option-click', val)
		},
		updateModel() {
			this.model = this.$dayjs(this.manualModel, this.format).format(this.valueFormat)
		},
	},
}
</script>