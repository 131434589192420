<template>
	<div
		class="com-expandable-table"
		@mousewheel="onMouseWheel"
	>
		<el-table
			ref="orderTable"
			:data="tableData"
			:height="height"
			:stripe="stripe"
			:empty-text="emptyText"
			:row-class-name="rowClassName || getRowClass"
			:expand-row-keys="expandRowKeys"
			:row-key="rowKey"
			:cell-class-name="cellClassName"
			:default-expand-all="defaultExpandAll"
			header-row-class-name="com-expandable-table__header"
			data-ta="com-expandable-table"
			:max-height="maxHeight"
			size="small"
			@row-click="onClickRow"
			@expand-change="onExpandChange"
			@scroll="onScroll"
		>

			<el-table-column
				v-if="expandable"
				type="expand"
				align="left"
				width="32px"
			>
				<template #header>
					<el-icon
						class="arrow-right cursor-pointer"
						@click="expandAll"
					><ArrowRight/></el-icon>
				</template>

				<template #default="props">
					<ComTableNestedTags
						v-if="props.row.nestedRow.tags?.length"
						:tags="props.row.nestedRow.tags"
					/>
				</template>
			</el-table-column>

			<el-table-column
				v-for="column of columns"
				:key="column.prop"
				:prop="column.prop"
				:label="column.label"
				:width="column.width"
				class-name="com-expandable-table__col"
				:align="column.align"
				:sortable="column.sortable"
				:formatter="column.formatter"
			>
				<template
					v-if="column.component || column.slot || column.actions"
					#default="props"
				>
					<component
						:is="column.component"
						v-bind="props.row"
						v-if="column.component"
					/>

					<slot
						v-else-if="column.slot"
						:name="column.slot"
						:row="props.row"
					/>

					<div
						v-else-if="column.actions"
					>
						<ConfirmPopover
							v-if="column.confirmData"
							:title="column.confirmData.title"
							:description="column.confirmData.description"
							:confirm="column.confirmData.confirmBtn"
							:cancel="column.confirmData.cancelBtn"
							:tooltip="column.tooltip"
							placement="bottom-start"
							:data-ta-reference="column.dataTa"
							:icon="column.icon"
							:width="column.confirmData.popoverWidth"
							@confirm="column.action(props.row)"
						/>

						<ItIcon
							v-else
							:name="column.icon"
							:data-ta="column.dataTa"
							style="cursor: pointer"
							width="16"
							height="20"
							@click="column.action(props.row)"
						/>
					</div>
				</template>
			</el-table-column>
		</el-table>
	</div>
</template>

<script>
import isEqual from 'lodash/isEqual'
import { defineAsyncComponent } from 'vue'
import ComTableNestedTags from '~/components/ComTable/ComTableNestedTags.vue'

export default {
	name: 'ComTableExpandable',
	components: {
		ComTableNestedTags,
		ConfirmPopover: defineAsyncComponent(() => import('~/components/old/ConfirmPopover.vue')),
		ComTableGoodsParamsCol: defineAsyncComponent(() => import('~/components/ComTable/ComTableGoodsParamsCol.vue')),
	},
	props: {
		items: {
			type: Array,
			default: () => [],
		},
		columns: {
			type: Array,
			default: () => [],
		},
		expandRowKeys: {
			type: Array,
			default: null,
		},
		height: {
			type: String,
			default: null,
		},
		expandable: {
			type: Boolean,
			default: true,
		},
		stripe: {
			type: Boolean,
			default: false,
		},
		virtual: {
			type: Boolean,
			default: false,
		},
		emptyText: {
			type: String,
			default: 'Нет данных',
		},
		rowKey: {
			type: String,
			default: null,
		},
		cellClassName: {
			type: Function,
			default: () => {},
		},
		defaultExpandAll: {
			type: Boolean,
			default: false,
		},
		sliceSize: {
			type: Number,
			default: 100,
		},
		rowClassName: {
			type: Function,
			default: null,
		},
		maxHeight: {
			type: [String, Number],
			default: null,
		},
	},
	data: () => ({
		expandedRows: [],
		slicedItems: null,
		slicePosition: 0,
		isAllExpanded: false,
		selectItem: null,
	}),
	computed: {
		tableData() {
			const tableData = this.virtual ? this.slicedItems : this.items
			return tableData
		},
	},
	watch: {
		items: {
			deep: false,
			handler(items) {
				this.slicedItems = items.slice(this.slicePosition, this.sliceSize + this.slicePosition)
				this.setPaddings()
			},
		},
		slicedItems: {
			handler() {
				this.$emit('table-updated')
			},
		},
	},
	created() {
		if (this.virtual) {
			this.slicedItems = this.items.slice(this.slicePosition, this.sliceSize)
		}

		if (this.defaultExpandAll) {
			this.expandedRows = this.items
		}
	},
	mounted() {
		if (this.virtual) {
			this.tableBodyWrapper = this.$el.querySelector('.el-table__body-wrapper')
			this.tableBodyWrapper?.addEventListener('scroll', this.onScroll)
			this.setPaddings()
		}
	},
	beforeDestroy() {
		if (this.virtual) {
			this.tableBodyWrapper?.removeEventListener('scroll', this.onScroll)
		}
	},
	methods: {
		getRowClass({ row }) {
			let resultClass = ''
			const isExpandedRow = this.expandedRows
				.find(item => isEqual(item, row))

			if (row.nestedRow?.tags?.length) {
				resultClass += ' cursor-pointer'
			} else {
				resultClass += ' non-expandable'
			}

			if (isExpandedRow) {
				resultClass += ' expanded-row'
			}

			return resultClass
		},
		expandAll() {
			this.isAllExpanded = !this.isAllExpanded
			this.tableData
				.filter(item => item.nestedRow)
				.forEach(item => {
					this.$refs.orderTable
						.toggleRowExpansion(item, this.isAllExpanded)
				})
		},
		expandRow(row) {
			if (row?.nestedRow) {
				this.$refs.orderTable
					.toggleRowExpansion(row)
			}
		},
		onExpandChange(row, rows) {
			if (!row.nestedRow?.tags?.length) {
				this.$refs.orderTable.toggleRowExpansion(row, false)
				return
			}
			// row.isExpanded = !row.isExpanded
			this.expandedRows = rows
		},
		onClickRow(row) {
			this.expandRow(row)
			this.$emit('row-click', row)
		},
		slideUp() {
			const item = this.items[--this.slicePosition]
			if (item) {
				this.slicedItems.unshift(item)
				this.slicedItems.pop()
			}
		},
		slideDown() {
			const item = this.items[++this.slicePosition + this.sliceSize]
			if (item) {
				this.slicedItems.push(item)
				this.slicedItems.shift()
			}
		},
		onMouseWheel(e) {
			if (!this.virtual) return
			e.preventDefault()
			if (e.deltaY > 0 && this.slicePosition < this.items.length) {
				this.slideDown()
			} else if (e.deltaY < 0 && this.slicePosition) {
				this.slideUp()
			}
		},
		onScroll(e) {
			const { scrollTop, scrollHeight } = e.target
			const scrollFn = () => {
				// this.tableBodyWrapper.classList.remove('scrolling')

				this.slicePosition = this.items.length * (scrollTop / (scrollHeight - this.tableBodyWrapper.offsetHeight)) - this.sliceSize / 2
				this.slicePosition = Math.round(Math.max(0, this.slicePosition))
				if (this.slicePosition > this.items.length - this.sliceSize) {
					this.slicePosition = this.items.length - this.sliceSize
				}
				this.slicedItems = this.items.slice(
					this.slicePosition,
					this.sliceSize + this.slicePosition
				)
				this.setPaddings()
			}
			if (!this.isScrollStep) {
				if (this.to) {
					clearTimeout(this.to)
				}
				this.to = setTimeout(scrollFn, 50)
				if (!this.isWheelStep) {
					// this.tableBodyWrapper.classList.add('scrolling')
				}
			}
			this.isScrollStep = false
			this.prevScrollTop = scrollTop
		},
		scrollStep(direction) {
			const rowHeight = this.getRowHeight()
			if (direction) {
				this.isScrollStep = true

				if (direction > 0) {
					this.slideDown()
					this.tableBodyWrapper.scrollTo(0, this.tableBodyWrapper.scrollTop + rowHeight)
				} else {
					this.slideUp()
					this.tableBodyWrapper.scrollTo(0, this.tableBodyWrapper.scrollTop - rowHeight)
				}
			}
		},
		getRowHeight() {
			this.tableBodyWrapper = this.tableBodyWrapper || this.$el.querySelector('.el-table__body-wrapper')
			const offsetHeigth = this.tableBodyWrapper.offsetHeight - parseInt(this.tableBodyWrapper.style.paddingTop || 0) - parseInt(this.tableBodyWrapper.style.paddingBottom || 0)
			return offsetHeigth / this.sliceSize
		},
		setPaddings() {
			const rowHeight = this.getRowHeight()
			if (rowHeight) {
				this.tableBodyWrapper = this.tableBodyWrapper || this.$el.querySelector('.el-table__body-wrapper')
				this.tableBody = this.tableBody || this.$el.querySelector('.el-table__body')
				this.tableBody.style.paddingTop = `${rowHeight * this.slicePosition}px`
				this.tableBody.style.paddingBottom = `${rowHeight * (this.items.length - this.slicePosition - this.sliceSize)}px`
			} else {
				setTimeout(this.setPaddings, 100)
			}
		},
		scrollToRow(idx) {
			this.slicePosition = idx
			this.slicedItems = this.items.slice(
				this.slicePosition,
				this.sliceSize + this.slicePosition
			)
			this.tableBody.style.paddingTop = '0px'
			this.tableBodyWrapper.scrollTo(0, 0)
		},
	},
}
</script>

<style lang="scss">

.com-expandable-table {
	&__header {
		height: 44px !important;
	}

	.el-table {
		&__empty-block {
			min-height: 44px;
		}

		&__empty-text {
			color: var(--el-color-text-regular);
		}

		&__expanded-cell {
			padding: 0 0 4px 40px !important;
			height: 32px;
			vertical-align: bottom;
		}

		&__row {
			min-height: 36px !important;

			td {
				padding: 4px 0 !important;
			}
		}

		.el-icon-arrow-right {
			color: var(--el-color-text-regular);
		}
	}

	.cell {
		padding: 0 7px !important;
	}

	.el-table__expand-icon {
		line-height: 23px !important;
	}

	.expanded-row > td {
		border-bottom: none;
	}

	.expandable {
		cursor: pointer,
	}

	.non-expandable {
		.el-table__expand-icon > .el-icon {
			display: none !important;
		}

		.el-table__expand-icon {
			cursor: auto;
		}
	}

	.empty {
		max-width: 1px !important;
		border: none !important;

		.el-icon-arrow-right::before {
			content: "";
		}
	}

	.cursor-pointer {
		cursor: pointer;
	}

	tr:hover > td {
		background-color: transparent !important;
	}

	.el-table__body .el-table__row--striped:hover > td {
		background-color: #f4f4f8 !important;
	}

	.el-table__row--striped + tr .el-table__expanded-cell {
		background-color: #f4f4f8 !important;
	}

	.el-checkbox__inner {
		display: none !important;
	}

	.el-table-column--selection {
		.cell {
			padding: 0 0 0 15px !important;

			.el-radio {
				width: 15px;
			}
		}
	}

	.el-table__body-wrapper.scrolling {
		opacity: .3;
	}
}
</style>
