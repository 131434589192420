import rootState from './rootState'
import rootActions from './rootActions'
import rootMutations from './rootMutations'
import rootGetters from './rootGetters'

export default {
	state: rootState,
	getters: rootGetters,
	mutations: rootMutations,
	actions: rootActions,
}