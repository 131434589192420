import { defineAsyncComponent } from 'vue'
import { USER_AUTHORITIES } from '~/utils/consts'
import OrderSale from '~/pages/orders/OrderSale'
import OrderFastRepair from '~/pages/orders/OrderFastRepair'
import OrderServices from '~/pages/orders/OrderServices'
import OrderReturn from '~/pages/orders/OrderReturn'

export default [
	{
		name: 'Sale',
		path: '/order/sale/:id',
		meta: {
			title: 'Заказ-продажа',
		},
		component: OrderSale,
	},
	{
		name: 'Repair',
		path: '/order/repair/:id',
		meta: {
			title: 'Заказ-ремонт',
		},
		component: OrderFastRepair,
	},
	{
		name: 'OrderClientView',
		// TODO: Изменить путь на '/client/:id/info-orders',
		path: '/order/client/:id/view',
		meta: {
			title: 'Заказы клиента',
		},
		component: defineAsyncComponent(() => import('~/pages/orders/OrderClientView')),
	},
	{
		name: 'CLReceipts',
		path: '/order/contact-lenses/receipts/:id',
		meta: {
			title: 'Продажа контактных линз',
		},
		component: defineAsyncComponent(() => import('~/pages/cl-receipts/CLReceipts.vue')),
	},
	{
		name: 'OrderServices',
		path: '/order/:id/services',
		meta: {
			title: 'Редактирование услуг',
		},
		component: OrderServices,
	},
	{
		name: 'OrderReturn',
		path: '/order/return/:id',
		meta: {
			title: 'Оформление возврата',
		},
		component: OrderReturn,
	},
	{
		name: 'OrderReturnView',
		component: () => import('~/pages/orders/OrderReturnView'),
		alias: '/popups/order/return/:id/view',
		path: '/order/return/:id/view',
		meta: {
			title: 'Просмотр возврата',
			accessGranted: [
				USER_AUTHORITIES.ROLE_TOP_MANAGER,
				USER_AUTHORITIES.ROLE_MANAGER,
				USER_AUTHORITIES.ROLE_ACCOUNTANT,
				USER_AUTHORITIES.ROLE_STORE,
				USER_AUTHORITIES.ROLE_DEPOT,
			],
		},
	},
]