const cssForPreventHeaderAndFooter = `
<style type="text/css" media="print">
	@page { margin: 0; size: auto; }
	body { margin: 1.6cm; }
</style>
`

export default function printHtmlDocument(html, printStyles) {
console.log('printStyles', 'printHtmlDocument.js:8', printStyles);

	const iframe = document.createElement('iframe')
	iframe.id = Date.now().toString(36)
	iframe.style = 'display: none; position: absolute; top: -100%; left: -100%; z-index: -99999;'
	iframe.srcdoc = (printStyles || cssForPreventHeaderAndFooter) + html 
	iframe.onload = () => iframe.contentWindow.print()

	document.body.appendChild(iframe)

	setTimeout(() => {
		if (document.getElementById(iframe.id)) {
			document.body.removeChild(iframe)
		}
	}, 10000)
}