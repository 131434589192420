<template>
	<div class="com-switch">
		<el-popover
			v-model:visible="isConfirmationVisible"
			placement="top-start"
			:width="248"
			:disabled="!confirmParams"
			trigger="contextmenu"
			popper-class="confirm-popover"
		>
			<template #reference>
				<el-switch
					:model-value="switchValue"
					:size="size"
					:active-text="switchParams?.activeText"
					:inactive-text="switchParams?.inactiveText"
					@change="onChangeSwitch"
				/>
			</template>

			<div
				class="d-flex gap-2"
			>
				<ItIcon
					name="warning-solid"
					height="16px"
					width="16px"
				/>

				<span
					class="bold color-text-primary font-size-14"
				>{{ confirmParams?.title }}</span>
			</div>

			<div
				class="confirm-popover__content"
			>{{ confirmParams?.content }}</div>

			<div class="mt-2 d-flex justify-content-flex-end gap-2">
				<el-button
					size="small"
					@click="isConfirmationVisible = false"
				>{{ cancelBtnLabel }}</el-button>
				<el-button
					size="small"
					type="primary"
					@click="onConfirm"
				>{{ confirmBtnLabel }}</el-button>
			</div>
		</el-popover>
	</div>
</template>

<script>
export default {
	name: 'PartSwitch',

	props: {
		switchParams: {
			type: Object,
			default: null,
		},
		size: {
			type: String,
			default: 'default',
			validator(value) {
				return ['', 'small', 'default'].includes(value)
			},
		},
	},

	data() {
		return {
			isConfirmationVisible: false,
			switchValue: false,
			isConfirmed: false,
		}
	},

	computed: {
		confirmParams() {
			return this.switchParams?.confirmParams
				|| null
		},
		confirmBtnLabel() {
			return this.confirmParams?.confirmBtnLabel
				|| this.$t('Продолжить')
		},
		cancelBtnLabel() {
			return this.confirmParams?.cancelBtnLabel
				|| this.$t('Отмена')
		},
	},

	methods: {
		onConfirm() {
			this.isConfirmed = true
			this.onChangeSwitch(true)
			this.isConfirmationVisible = false
		},

		onChangeSwitch(val) {
			if (this.confirmParams && !this.isConfirmed) {
				this.isConfirmationVisible = true
				return
			}

			this.switchValue = val
			this.$emit('on-change-switch', val)
		},
	},
}
</script>

<style lang="scss">
.com-switch {
	.confirm-popover {
		&__content {
			font-size: 12px;
			line-height: 16px;
			margin-top: 8px;
		}
	}

	.el-switch__label span {
		font-size: 12px;
		color: var(--el-color-text-primary);
		min-width: 65px;
	}
}
</style>