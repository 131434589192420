import usersState from './usersState'
import usersActions from './usersActions'
import usersMutations from './usersMutations'
import usersGetters from './usersGetters'

export default {
	namespaced: true,
	state: usersState,
	getters: usersGetters,
	mutations: usersMutations,
	actions: usersActions,
}