import i18n from '~/plugins/i18n'

// Переводы в helpers.xlsx

export const STATUSES = {
	ALL: {
		label: i18n.global.t('Все'),
		value: 'ALL',
	},
	ACCEPTED: {
		label: i18n.global.t('Запрос на изготовление'),
		value: 'ACCEPTED',
		color: '#FB9E13',
		colorClass: 'bg-color-warning',
	},
	IN_WORK: {
		label: i18n.global.t('Изготовление массовки'),
		value: 'IN_WORK',
		color: '#1989FA',
		colorClass: 'bg-color-primary',
	},
	ORDER_READY: {
		label: i18n.global.t('Изготовлена'),
		value: 'ORDER_READY',
		color: '#1CAD4B',
		colorClass: 'bg-color-success',
	},
	ORDER_COMPLETED: {
		label: i18n.global.t('Завершена'),
		value: 'ORDER_COMPLETED',
		color: '#909399',
		colorClass: 'bg-color-info',
	},
	CANCEL: {
		label: i18n.global.t('Отменена'),
		value: 'CANCEL',
		color: '#FF3C3C',
		colorClass: 'bg-color-danger',
	},
}

export const getExtraLabelTranslation = status => STATUSES[status]?.label || i18n.global.t('Неправильный статус заявки')
export const getExtraStatusBgClass = status => STATUSES[status]?.colorClass || 'bg-color-primary'