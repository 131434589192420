import {
	shareStateHydration,
	shareStateCleanup,
} from '~/plugins/store/shareStateService'


export const MAIN_IFRAME_ID = 'apiIframeMain'

let iframeId

class Queue {
	constructor() {
		this.queue = []
	}

	add(fn, ...args) {
		this.queue.push({ fn, args })
	}

	execAll(context) {
		this.queue.map(item => item.fn?.apply(context, item.args))
	}
}

export const iframeMessagesBeforeFetchSettingsQueue = new Queue()

export const setIframeId = id => {
	iframeId = id
	if (id === MAIN_IFRAME_ID) {
		shareStateCleanup()
	} else if (id) {
		shareStateHydration(id)
	}
}

export const isIframe = window.location !== window.parent.location
export const getIframeId = () => iframeId
export const isMainIframe = () => iframeId === MAIN_IFRAME_ID

export const iframesPostToMain = fn => {
	const prevIframeId = iframeId
	iframeId = MAIN_IFRAME_ID
	fn()
	iframeId = prevIframeId
}