import VuexHttp from '../../vuexHttpService'

const vHttp = new VuexHttp('news')

export default {
	aDeliverDetails(_, id) {
		return vHttp.GET(this, {
			url: `news/${id}/delivery`,
			mutation: 'mDeliveryDetails',
		})
	},
	aUserGroups() {
		return vHttp.GET(this, {
			url: 'news/user-groups',
			mutation: 'mUserGroups',
		})
	},
	aDepartments() {
		return vHttp.GET(this, {
			url: 'news/departments',
			mutation: 'mDepartments',
		})
	},
	aNewsItem(_, id) {
		return vHttp.GET(this, {
			url: `news/${id}`,
			mutation: 'mNewsItem',
		})
	},
	aNewsItemCreate(_, data) {
		return vHttp.POST(this, {
			url: 'news',
			data,
		})
	},
	aNewsItemUpdate(_, { id, data }) {
		return vHttp.PUT(this, {
			url: `news/${id}`,
			mutation: 'mNewsItem',
			data,
		})
	},
	aMarkAsRead({state, commit}, id) {
		if (!state.canMarkIsRead) return
		commit('mCanMarkIsRead', false)

		return vHttp.PUT(this, {
			url: `news/${id}/delivery`,
		})
	},
}