import legalEntitiesState from './legalEntitiesState'
import legalEntitiesAction from './legalEntitiesAction'
import legalEntitiesMutations from './legalEntitiesMutations'
import legalEntitiesGetters from './legalEntitiesGetters'

export default {
	namespaced: true,
	state: legalEntitiesState,
	getters: legalEntitiesGetters,
	mutations: legalEntitiesMutations,
	actions: legalEntitiesAction,
}