export default {
	mMailing(state, payload) {
		state.mailing = payload
	},
	mResendInfo(state, payload) {
		state.resendInfo = payload
	},
	mStatuses(state, payload) {
		state.statuses = payload
	},
}