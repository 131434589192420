import mailingsState from './mailingsState'
import mailingsActions from './mailingsActions'
import mailingsMutations from './mailingsMutations'
import mailingsGetters from './mailingsGetters'

export default {
	namespaced: true,
	state: mailingsState,
	getters: mailingsGetters,
	mutations: mailingsMutations,
	actions: mailingsActions,
}