/**
* @typedef {'string' | 'number' | 'boolean'} FilterValueType
*/

/**
* @typedef Filter
* @type {Object}
* @property {string} [label] - описание фильтра
* @property {*} value - текущее значение фильтра, используется для v-model
* @property {FilterValueType} [valueType] - тип сохраняемого значения, default: 'string
* @property {*} [defaultValue] - значение, выставляемое по умолчанию, default: null
* @property {boolean} [skip] - нужно ли пропустить фильтр при выставлении фильтров, default: false
* @property {boolean} [skipReset] - нужно ли пропустить фильтр при сбросе, default: false
* @property {function} [validator] - функция, возвращаемое true/false ставить ли значение в фильтр, default: undefined
* @property {function} [formatter] - функция, форматируемая значение фильтра при форматировании для отправки на бэкенд, default: undefined
* @property {function} [chosenFormatter] - функция, форматируемая значение фильтра при использовании миксины ChosenFilters, default: undefined
* @property {string} [backendFilterKey] - имя фильтра для бэкенда
* @property {boolean} [useFullArray] - использовать ли полный массив в качестве значения (например, при range-массивах)
* @property {string} [queryKey] - имя фильтра для query
*
* @typedef {Object.<string, Filter>} Filters
*/

const FilterValueType = {
	string: 'string',
	number: 'number',
	boolean: 'boolean',
}

/**
* Возвращает значение, приведённое к нужному типу
*
* @param {*} value - значение, которое нужно привести к типу
* @param {FilterValueType} type - тип, к которому нужно привести значение
*/
function typeConversion(value, type) {
	switch (type) {
	case FilterValueType.boolean: {
		if (typeof value === 'string') return !!(value && value !== 'false')
		return !!value
	}
	case FilterValueType.number: return +value
	case FilterValueType.string: default: return value.toString()
	}
}

/**
* Валидирует значение
*
* @param {*} value - значение, которое нужно проверить
* @param {function} [validator] - функция-валидатор
*/
function validValue(value, validator) {
	if (!validator) return true

	return validator(value)
}

/**
* Возвращает форматированное значение
*
* @param {*} value - значение
* @param {function} [formatter] - функция-форматтер
*/
function formatValue(value, formatter) {
	return formatter ? formatter(value) : value
}

export function isEqual(value1, value2) {
	if (value1 === value2) return true

	if (Array.isArray(value1) && Array.isArray(value2)) {
		if (value1.length === value2.length && value1.every(v => value2.includes(v))) return true
	}

	return false
}

export default {
	data() {
		return {
			filtersMixinOptions: {
				filtersDataName: 'filters',
				usePage: true,
				excludeFormatFilters: [], // какие фильтры не включаются в запрос на бэкенд
			},
		}
	},
	methods: {
		/**
		* Ставит фильтры из query в переданный объект.
		*/
		setFiltersFromQuery() {
			/** @type {Filters} filters */
			const filters = this[this.filtersMixinOptions.filtersDataName]

			if (!filters) throw new Error('Filters are not exist')

			const { query } = this.$route

			Object.entries(filters).forEach(([filter, filterValue]) => {
				if (filterValue.skip) return

				const queryValue = query[filterValue.queryKey || filter]

				if (!queryValue) {
					filterValue.value = filterValue.defaultValue
					return
				}

				const { valueType, validator } = filterValue
				const isValueArray = Array.isArray(filterValue.value) || Array.isArray(filterValue.defaultValue)

				if (isValueArray && Array.isArray(queryValue)) {
					filterValue.value = queryValue.filter(v => validValue(typeConversion(v, valueType), validator)).map(v => typeConversion(v, valueType))
					return
				}

				const conversionValue = typeConversion(queryValue, valueType)

				if (validValue(conversionValue, validator)) {
					filterValue.value = isValueArray ? [conversionValue] : conversionValue
					return
				}

				filterValue.value = filterValue.defaultValue
			})

			if (this.filtersMixinOptions.usePage && this.paginationMixinOptions) {
				if (query.page) this.pagination.page = +query.page
				if (query.pageSize) this.pagination.size = +query.pageSize
			}
		},
		routeReplace(paramNameEntry, valueEntry) {
			if (!paramNameEntry) return

			/** @type {Filters} filters */
			const filters = this[this.filtersMixinOptions.filtersDataName]
			const filter = filters[paramNameEntry] || Object.values(filters).find(f => f.queryKey === paramNameEntry)

			// может приходить null - чтобы убрать из query
			if (!filter && valueEntry === undefined) return

			const paramName = filter?.queryKey || paramNameEntry

			let value = valueEntry

			if (value === undefined) value = filter?.value

			if (filter && filter.valueType === 'boolean' && filter.defaultValue === true) {
				if (value === false) value = String(value)
			}

			const { [paramName]: paramValue, ...query } = this.$route.query

			if (value && (!Array.isArray(value) || value.length > 0)) query[paramName] = value

			if (!paramValue && (!value || (Array.isArray(value) && value.length === 0))) return
			if (String(paramValue) === String(value)) return

			this.$router.replace({ query })
		},
		/** Получить объект фильтров с значениями (используется для запросов на бэкенд) */
		getFormattedFilters() {
			/** @type {Filters} filters */
			const filters = this[this.filtersMixinOptions.filtersDataName]

			return Object.entries(filters).reduce((total, entry) => {
				const filterKey = entry[0]

				if (!this.filtersMixinOptions.excludeFormatFilters.includes(filterKey)) {
					const { value, formatter, backendFilterKey } = entry[1]

					total[backendFilterKey || filterKey] = formatValue(value, formatter)
				}

				return total
			}, {})
		},
		/**
		* Сброс фильтра до значения по умолчанию
		*
		* @param {string} filterKey
		* @param {*} value - какое значение нужно убрать (используется, если массив)
		*/
		resetFilter(filterKey, value) {
			/** @type {Filters} filters */
			const filters = this[this.filtersMixinOptions.filtersDataName]
			const filter = filters[filterKey]

			if (!filter || filter.skipReset) return

			const isArray = Array.isArray(filter.defaultValue) || Array.isArray(filter.value)

			if ((value && !filter.useFullArray) && isArray) {
				const index = filter.value.findIndex(item => item === value)

				if (index !== -1) filter.value.splice(index, 1)
			} else filter.value = filter.defaultValue

			this.routeReplace(filterKey, isEqual(filter.value, filter.defaultValue) ? null : value)
		},
		/** Сброс всех фильтров */
		resetAllFilters() {
			/** @type {Filters} filters */
			const filters = this[this.filtersMixinOptions.filtersDataName]

			Object.keys(filters).forEach(filter => {
				this.resetFilter(filter)
			})
		},
	},
}