import newsState from './newsState'
import newsActions from './newsActions'
import newsMutations from './newsMutations'
import newsGetters from './newsGetters'

export default {
	namespaced: true,
	state: newsState,
	getters: newsGetters,
	mutations: newsMutations,
	actions: newsActions,
}