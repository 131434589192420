import i18n from '~/plugins/i18n'

export const DEFAULT_LAYOUT_JOURNAL2_PARAMS = () => ({
	title: '',
	browserTitle: '',
	hasReturnButton: false,
	disableFilterButtons: {},
	returnTo: '/',
	deleteConfirmation: i18n.global.t('Продолжить?'),
	restoreSuccessMessage: i18n.global.t('Восстановлено'),
	restoreErrorMessage: i18n.global.t('Ошибка'),
	deleteSuccessMessage: i18n.global.t('Удалено'),
	deleteErrorMessage: i18n.global.t('Ошибка'),
	saveSuccessMessage: i18n.global.t('Сохранено'),
	saveErrorMessage: i18n.global.t('Ошибка'),
	hasPagination: true,
	headerBlocks: [],
	defaultPaginationSize: 100,
	optimaHelp: {
		text: null,
		href: null,
	},
	filters: {
		main: false,
		additional: false,
	},
})

export const DEFAULT_LAYOUT_PAGE_PARAMS = () => ({
	title: '',
	browserTitle: '',
	hasReturnButton: false,
	buttons: null, // TODO перенести кнопки из роутера на страницы
	disableButtons: {},
	buttonsShowOnlyBottom: null,
	returnTo: { name: 'DepartmentsJournal' },
	deleteConfirmation: i18n.global.t('Продолжить?'),
	restoreSuccessMessage: i18n.global.t('Восстановлено'),
	restoreErrorMessage: i18n.global.t('Ошибка'),
	deleteSuccessMessage: i18n.global.t('Удалено'),
	deleteErrorMessage: i18n.global.t('Ошибка'),
	saveSuccessMessage: i18n.global.t('Сохранено'),
	saveErrorMessage: i18n.global.t('Ошибка'),
	cancelConfirmationParams: {
		title: 'Отменить изменения?',
		message: i18n.global.t('Несохранённые данные будут потеряны. Продолжить?'),
		confirmButtonText: i18n.global.t('Да, продолжить'),
		cancelButtonText: i18n.global.t('Отмена'),
	},
	beforeRouteLeaveParams: {
		title: i18n.global.t('Покинуть страницу без сохранения?'),
		message: i18n.global.t('Несохранённые данные будут потеряны'),
		confirmButtonText: i18n.global.t('Да, покинуть'),
		cancelButtonText: i18n.global.t('Нет'),
	},
})

export const DEFAULT_LAYOUT_PAGE_TABS_PARAMS = () => ({
	title: '',
	browserTitle: '',
	hasReturnButton: false,
	hideOnceTab: false,
	buttons: null,
	disableButtons: {},
	buttonsShowOnlyBottom: null,
	returnTo: { name: 'DepartmentsJournal' },
	deleteConfirmation: i18n.global.t('Продолжить?'),
	confirmButton: 'OK',
	deleteConfirmationTitle: i18n.global.t('Подтверждение удаления'),
	restoreSuccessMessage: i18n.global.t('Восстановлено'),
	restoreErrorMessage: i18n.global.t('Ошибка'),
	deleteSuccessMessage: i18n.global.t('Удалено'),
	deleteErrorMessage: i18n.global.t('Ошибка'),
	saveSuccessMessage: i18n.global.t('Сохранено'),
	saveErrorMessage: i18n.global.t('Ошибка'),
	cancelConfirmationParams: {
		title: 'Отменить изменения?',
		message: i18n.global.t('Несохранённые данные будут потеряны. Продолжить?'),
		confirmButtonText: i18n.global.t('Да, продолжить'),
		cancelButtonText: i18n.global.t('Отмена'),
	},
	beforeRouteLeaveParams: {
		title: i18n.global.t('Покинуть страницу без сохранения?'),
		message: i18n.global.t('Несохранённые данные будут потеряны'),
		confirmButtonText: i18n.global.t('Да, покинуть'),
		cancelButtonText: i18n.global.t('Нет'),
	},
})

export function DEFAULT_LAYOUT_PAGE_BUTTONS() {
	return {
		cancel: {
			type: 'button',
			name: 'cancel',
			label: i18n.global.t('Отмена'),
			icon: null,
			tooltip: null,
			isVisible: false,
			isDisabled: false,
			event: 'on-cancel',
		},
		edit: {
			type: 'button',
			name: 'edit',
			label: null,
			icon: 'pensil',
			btnType: 'primary',
			plain: true,
			class: 'px-2',
			tooltip: i18n.global.t('Редактировать'),
			isDisabled: false,
			isVisible: true,
			event: 'on-edit',
		},
		delete: {
			type: 'button',
			name: 'delete',
			label: null,
			icon: 'trash',
			tooltip: i18n.global.t('Удалить'),
			isVisible: false,
			btnType: 'danger',
			plain: true,
			class: 'px-2',
			isDisabled: false,
			event: 'on-delete',
		},
		restore: {
			type: 'button',
			name: 'restore',
			label: i18n.global.t('Восстановить'),
			icon: 'restore',
			tooltip: null,
			isVisible: false,
			isDisabled: false,
			event: 'on-restore',
		},
		save: {
			type: 'button',
			name: 'save',
			label: i18n.global.t('Сохранить'),
			icon: null,
			tooltip: null,
			isVisible: false,
			isPrimary: false,
			isDisabled: true,
			event: 'on-save',
		},
		close: {
			type: 'button',
			name: 'close',
			label: null,
			icon: 'close',
			tooltip: null,
			isVisible: true,
			event: 'on-close',
		},
	}
}