import i18n from '~/plugins/i18n'

export const ORDER_TYPE_MAP = {
	SaleOrder: 'SALE',
	SALE: 'SALE',
	saleOrder: 'SALE',
	RepairOrder: 'REPAIR',
	repairOrder: 'REPAIR',
	REPAIR: 'REPAIR',
	ClOrder: 'CONTACT_LENS',
	CONTACT_LENS: 'CONTACT_LENS',
	GlassesOrder: 'GLASSES',
	GLASSES: 'GLASSES',
	CheckVisionOrder: 'CHECK_VISION',
	CHECK_VISION: 'CHECK_VISION',
	// Legacy
	sale_order: 'SALE',
	repair_order: 'REPAIR',
	cl_order: 'CONTACT_LENS',
	glasses_order: 'GLASSES',
	check_vision: 'CHECK_VISION',
}

export const ORDER_TYPE_BY_NUMBER = {
	1: 'SALE',
	2: 'REPAIR',
	3: 'GLASSES',
	4: 'CONTACT_LENS',
	5: 'CHECK_VISION',
}

export const ORDER_TYPE_SNAKE = {
	SALE: 'sale_order',
	REPAIR: 'repair_order',
	CONTACT_LENS: 'cl_order',
	GLASSES: 'glasses_order',
	CHECK_VISION: 'check_vision',
}

export const ORDER_TYPE_PASCAL = {
	SALE: 'SaleOrder',
	REPAIR: 'RepairOrder',
	CONTACT_LENS: 'ClOrder',
	GLASSES: 'GlassesOrder',
	CHECK_VISION: 'CheckVisionOrder',
}


// Переводы в helpers.xlsx

export const ORDERS_TYPES = {
	GLASSES: {
		name: 'GLASSES',
		label: i18n.global.t('Изготовление очков'),
		id: 'GLASSES',
	},
	SALE: {
		name: 'SALE',
		label: i18n.global.t('Продажа'),
		id: 'SALE',
	},
	REPAIR: {
		name: 'REPAIR',
		label: i18n.global.t('Ремонт'),
		id: 'REPAIR',
	},
	CONTACT_LENS: {
		name: 'CONTACT_LENS',
		label: i18n.global.t('Продажа контактных линз'),
		id: 'CONTACT_LENS',
	},
	CHECK_VISION: {
		name: 'CHECK_VISION',
		label: i18n.global.t('Проверка зрения'),
		id: 'CHECK_VISION',
	},
	REPAIR_GLASSES: {
		name: 'REPAIR_GLASSES',
		label: i18n.global.t('Ремонт очков'),
		id: 'REPAIR_GLASSES',
	},
	REPAIR_GLASSES_ORDER: {
		name: 'REPAIR_GLASSES_ORDER',
		label: i18n.global.t('Ремонт очков'),
		id: 'REPAIR_GLASSES_ORDER',
	},
}

export const orderTypes = ORDERS_TYPES