import MAILING_STATUSES from '~/helpers/common/MailingStatuses'

export default {
	gStatusesOptions(state) {
		return state.statuses.map(item => ({
			label: MAILING_STATUSES[item],
			value: item,
		}))
	},
	gFailedStatusesOptions(state) {
		return state.failedStatuses.map(item => ({
			label: MAILING_STATUSES[item],
			value: item,
		}))
	},
}