import RoundsTypes from '~/helpers/common/RoundsTypes'
import { getDefaultRuleTypeForms } from '~/components/goods/data/getDefaultRuleTypeForms.js'

export default {
	gCLManufacturersOptions(state) {
		if (!state.CLManufacturers?.content) return []

		return state.CLManufacturers.content
			.map(manufacturer => ({
				label: manufacturer.name,
				value: manufacturer.name,
			}))
	},

	gCLNamesOptions(state) {
		if (!state.CLNames?.length) return []

		return state.CLNames
			.map(manufacturer => ({
				label: manufacturer.name || manufacturer,
				value: manufacturer.name || manufacturer,
			}))
	},

	gRoundsOptions(state) {
		return state.rounds
			.map(item => RoundsTypes[item])
	},

	gReasonMarkdownsOptions(state) {
		return state.reasonMarkdowns
			.map(item => ({
				label: item.name,
				value: item.name,
			}))
	},
	gAutomaticDiscountDublicate(state) {
		if (!state.automaticDiscount) return null

		const dublicateDiscount = {}
		dublicateDiscount.name = state.automaticDiscount.name
		dublicateDiscount.discountPercent = state.automaticDiscount.discountPercent
		dublicateDiscount.startedOn = state.automaticDiscount.startedOn
		dublicateDiscount.finishedOn = state.automaticDiscount.finishedOn
		dublicateDiscount.periodOfAction = [state.automaticDiscount.startedOn, state.automaticDiscount.finishedOn]

		if (state.automaticDiscount.departmentIds !== null) {
			dublicateDiscount.departmentIds = state.automaticDiscount.departmentIds
				.map(departmentId => String(departmentId))
		}

		dublicateDiscount.priceFrom = state.automaticDiscount.priceFrom || null
		dublicateDiscount.priceTo = state.automaticDiscount.priceTo || null

		if (state.automaticDiscount.sellableResponse) {
			if (state.automaticDiscount.sellableResponse.sellableCategory === 'LENS') {
				dublicateDiscount.goodsType = 'LENSES'
			} else if (state.automaticDiscount.sellableResponse.sellableCategory === 'CONTACT_LENS') {
				dublicateDiscount.goodsType = 'CONTACT_LENSES'
			} else if (state.automaticDiscount.sellableResponse.sellableCategory === 'ACCESSORY') {
				dublicateDiscount.goodsType = 'ACCESSORIES'
			} else if (state.automaticDiscount.sellableResponse.sellableCategory === 'EXTRA') {
				dublicateDiscount.goodsType = 'EXTRAS'
			} else {
				dublicateDiscount.goodsType = state.automaticDiscount.sellableResponse.sellableCategory
			}
			const fieldsByGoodsType = getDefaultRuleTypeForms()[dublicateDiscount.goodsType]

			// ToDo
			Object.keys(fieldsByGoodsType)
				.forEach(key => {
					if (key === 'servicesId') {
						dublicateDiscount[key] = state.automaticDiscount.sellableResponse.serveTypeIds
					} else if (key === 'glassesMaterial') {
						dublicateDiscount.glassesMaterial = state.automaticDiscount.sellableResponse.material
					} else if (key === 'frameType') {
						dublicateDiscount.frameType = state.automaticDiscount.sellableResponse.type
							|| state.automaticDiscount.sellableResponse.frameType
					} else if (key === 'models') {
						dublicateDiscount.models = state.automaticDiscount.sellableResponse.models
							|| [state.automaticDiscount.sellableResponse.model]
					} else if (key === 'radiusOfCurvature') {
						dublicateDiscount[key] = state.automaticDiscount.sellableResponse.curvatureRadius
							|| state.automaticDiscount.sellableResponse.radiusOfCurvature
					} else if (key === 'contactLensesName') {
						dublicateDiscount[key] = state.automaticDiscount.sellableResponse.name
							|| state.automaticDiscount.sellableResponse.contactLensesName
					} else if (key === 'lensGeometry') {
						dublicateDiscount[key] = state.automaticDiscount.sellableResponse.geometry
							|| state.automaticDiscount.sellableResponse.lensGeometry
					} else if (key === 'lensCover') {
						dublicateDiscount[key] = state.automaticDiscount.sellableResponse.cover
							|| state.automaticDiscount.sellableResponse.lensCover
					} else if (key === 'lensType') {
						dublicateDiscount[key] = state.automaticDiscount.sellableResponse.type
							|| state.automaticDiscount.sellableResponse.lensType
					} else if (key === 'category') {
						dublicateDiscount[key] = state.automaticDiscount.sellableResponse.accessoryCategory
							|| state.automaticDiscount.sellableResponse.category
					} else {
						dublicateDiscount[key] = state.automaticDiscount.sellableResponse[key] || null
					}
				})
		}

		return dublicateDiscount
	},
}