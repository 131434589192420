<script setup>
import { defineAsyncComponent, ref, reactive, computed, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import cloneDeep from 'lodash/cloneDeep'
import dayjs from 'dayjs'
import { isRequired } from '~/utils/validationRules'
import { sellableCategories } from '~/helpers/common/GoodsTypes'
import { normalizeDioptreItem } from '~/utils/formatters'
import { tableConfig } from './table.config'

const ComTableGoodsParamsCol = defineAsyncComponent(() => import('~/components/ComTable/ComTableGoodsParamsCol'))

const { t } = useI18n()
const store = useStore()

const emit = defineEmits([
	'show-payment-popup',
	'update:selected-items',
	'set-comment',
	'set-card-return'
])

const props = defineProps({
	selectedItems: {
		type: Array,
		required: true,
	},
	orderId: {
		type: Number,
		required: true,
	},
})

const tableCols = reactive([...tableConfig])

const ERROR_SELECTION = t('Необходимо выбрать хотя бы один товар или услугу для оформления возврата!')

const isErrorNotifyVisible = ref(false)
const fromCardReturnVisible = ref(false)
const form = ref(null)
const errorText = ref('')
const isLoading = ref(false)
const formatParameter = item => item && String(item).replaceAll('.', ',')
const commentForm = reactive({
	comment: '',
	fromCardReturn: null,
})

const returnItems = computed(() => store.state.returns.returnItems)

watch(() => commentForm.comment, comment => {
	emit('set-comment', comment)
})

watch(() => commentForm.fromCardReturn, fromCardReturn => {
	emit('set-card-return', fromCardReturn)
})

watch(() => props.selectedItems, selectedItems => {
	if (selectedItems.length && isErrorNotifyVisible.value) {
		isErrorNotifyVisible.value = false
	}
})

const handleSelectionChange = selection => {
	emit('update:selected-items', selection)
}
const makeReturn = async () => {
	if (!props.selectedItems.length) {
		errorText.value = ERROR_SELECTION
		isErrorNotifyVisible.value = true
		return
	}

	let isValid = await form.value.validate()
	if (!isValid) return

	emit('show-payment-popup')
}


// TODO использовать общую ф-ю GoodsParamsMapper.js
const preparedReturnItems = computed(() => cloneDeep(returnItems.value).map(item => {
	if (item.accessoryCategory === 'Не маркируемые товары') {
		// По умолчанию sellableCategory = ACCESSORY
		item.sellableCategory = 'UNMARKED_GOODS'
	}

	if (item.sellableCategory === 'ACCESSORY') {
		item.type = t('Аксессуары')
		item.description = {
			name: `${item.accessoryCategory} ${item.model}`,
		}
	} else if (item.sellableCategory === 'CONTACT_LENS') {
		item.type = `${t('Конт. линзы')}`
		item.description = {
			name: item.name,
			params: [
				formatParameter(item.curvatureRadius),
				`${item.packageQuantity}pk`,
			],
			recipeParams: [
				normalizeDioptreItem(item.dioptre),
				formatParameter(item.cylinder),
				formatParameter(item.axis),
				formatParameter(item.add),
			],
		}

		if (item.expireAt) {
			const expireAt = dayjs(item.expireAt).format('DD.MM.YYYY')
			item.description.params
				.push(`${t('годен до:')} ${expireAt}`)
		}
	} else if (item.sellableCategory === 'LENS') {
		item.type = t('Линзы')
		item.description = {
			name: item.manufacturer,
			params: [
				item.brand,
				formatParameter(item.diameter),
				formatParameter(item.refractionIndex),
				item.lensClass,
				item.cover,
				item.color,
			].filter(param => param > ''),
			recipeParams: [
				normalizeDioptreItem(item.dioptre),
				formatParameter(item.cylinderDioptre),
				formatParameter(item.axis),
				formatParameter(item.addidation),
			],
			radius: item.curvatureRadius,
		}
	} else if (item.sellableCategory === 'GLASSES') {
		item.type = t('Оправы')
		item.description = {
			name: `${item.brand} ${item.model}`,
			color: item.color,
			size: item.size,
		}
	} else if (item.sellableCategory === 'SUNGLASSES') {
		item.type = t('Солнц. очки')
		item.description = {
			name: `${item.brand} ${item.model}`,
			color: item.color,
			size: item.size,
		}
	} else if (item.sellableCategory === 'SERVE') {
		item.type = t('Услуги')
	} else if (item.sellableCategory === 'UNMARKED_GOODS') {
		item.type = t('Не маркир. товары')
		item.description = {
			name: item.model,
		}
	} else if (item.sellableCategory === 'GIFT_CERTIFICATE') {
		item.type = t('Подарочный сертификат')
		const certNumber = store.state.settings.hasRealCertificateUUID && item.certificateNumber ? item.certificateNumber : item.id
		item.description = t('Номер сертификата') + ': ' + certNumber
	} else if (item.sellableCategory === 'EXTRA') {
		item.type = t('Массовка')
		item.description = {
			name: `${item.extrasDesc.frameBrand} ${item.extrasDesc.frameModel}`,
			color: item.extrasDesc.frameColor,
			params: [item.extrasDesc.frameMaterial],
			recipeParams: [
				normalizeDioptreItem(item.extrasDesc.odDioptre),
				formatParameter(item.dpp),
			],
		}
	}
	return item
}))

const noReturnItems = computed(() => !returnItems.value.length)

const loadData = async () => {
	isLoading.value = true
	await store.dispatch('returns/aReturnItems', props.orderId)
	// TODO fromCardReturn логика из легаси, нужно будет удалить для композитных платежей
	fromCardReturnVisible.value = store.state.returns.fromCardReturn
	commentForm.fromCardReturn = store.state.returns.fromCardReturn
	
	if (commentForm.fromCardReturn) {
		const totalSold =  store.state.returns.returnItems.reduce((total, item) => total += item.soldPrice, 0)
		commentForm.fromCardReturn = store.state.returns.returnAvailableCash < totalSold
	}

	isLoading.value = false
}

const onChangeComment = comment => {
	commentForm.comment = comment.trim().replaceAll(/\s+/g, ' ')
}

loadData()
</script>

<template>
	<div v-loading="isLoading">
		<div class="border-radius-8 border-base p-4 mb-4">
			<div class="font-semi-bold color-text-primary mb-2">{{ t('Товары и услуги в заказе') }}</div>
			<div
				class="font-size-12 color-text-secondary"
				:class="noReturnItems ? 'mb-9' : 'mb-4'"
			>{{ t('Выберите хотя бы один товар или услугу для оформления возврата') }}</div>

			<div v-if="noReturnItems" class="d-flex align-items-center flex-column">
				<img
					src="https://static.itigris.ru/images/empty-data-table.svg"
					:alt="t('Нет товаров или услуг для возрата')"
					class="mb-3"
					width="112"
				>
				<div class="font-semi-bold mb-5">{{ t('Нет товаров или услуг для возрата') }}</div>
			</div>
			<el-table
				v-else
				stripe
				size="small"
				:data="preparedReturnItems"
				@selection-change="handleSelectionChange"
			>
				<el-table-column type="selection" width="38"/>
				<el-table-column
					v-for="col in tableCols"
					:key="col.prop"
					:label="col.label"
					:prop="col.prop"
					:width="col.width"
					:align="col.align"
					:formatter="col.formatter"
					:header-align="col.headerAlign || col.align || 'left'"
				>
					<template v-if="col.custom" #default="{ row }">
						<div v-if="col.prop === 'name'">
							<div v-if="row.sellableCategory === sellableCategories.SERVE.value">
								<div class="font-semi-bold">{{ row.serveType.name }}</div>
								<div v-if="row.employee?.fullName">{{ t('Оказывает') }} {{ row.employee.fullName }}</div>
							</div>
							<div v-else-if="row.sellableCategory === sellableCategories.GIFT_CERTIFICATE.value">
								<span>{{ row.description }}</span>
							</div>
							<ComTableGoodsParamsCol
								v-else
								:sellable-category="row.sellableCategory"
								:description="row.description"
							/>
						</div>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<el-form
			ref="form"
			:model="commentForm"
			label-position="top"
			class="mb-6"
			require-asterisk-position="right"
		>
			<el-form-item
				v-if="fromCardReturnVisible"
				prop="commentForm.fromCardReturn"
			>
				<el-checkbox
					v-model="commentForm.fromCardReturn"
					:label="t('Произвести списание средств за возврат товаров и услуг с безналичного счета магазина (при его наличии)')"
				/>
			</el-form-item>
			<el-form-item
				:label="t('Комментарий к возврату')"
				prop="comment"
				:rules="isRequired"
			>
				<el-input
					v-model="commentForm.comment"
					:rows="2"
					type="textarea"
					:placeholder="t('Введите коментарий')"
					maxlength="320"
					show-word-limit
					:disabled="noReturnItems"
					@change="onChangeComment"
				/>
			</el-form-item>
		</el-form>

		<div class="d-flex justify-content-flex-end">
			<el-popover
				:visible="isErrorNotifyVisible"
				placement="top-end"
				width="231"
				popper-class="color-regular"
			>
				<div class="d-flex align-items-center gap-2 mb-2">
					<ItIcon
						name="warning-solid"
						width="16"
						height="16"
					/>
					<div class="font-semi-bold">{{ t('Ошибка сохранения') }}</div>
				</div>
				<div class="font-size-12 mb-2">
					{{ errorText }}
				</div>
				<div class="d-flex justify-content-flex-end">
					<el-button
						type="primary"
						plain
						@click="isErrorNotifyVisible = false"
					>{{ t('Закрыть') }}</el-button>
				</div>

				<template #reference>
					<el-button
						type="primary"
						plain
						size="default"
						:disabled="noReturnItems"
						@click="makeReturn"
					>{{ t('Оформить возврат') }}</el-button>
				</template>
			</el-popover>
		</div>
	</div>
</template>