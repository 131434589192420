import salesPlansState from './salesPlansState'
import salesPlansGetters from './salesPlansGetters'
import salesPlansMutations from './salesPlansMutations'
import salesPlansActions from './salesPlansActions'

export default {
	namespaced: true,
	state: salesPlansState,
	getters: salesPlansGetters,
	mutations: salesPlansMutations,
	actions: salesPlansActions,
}