<template>
	<div class="layout-part-tabs-wrapper">
		<el-tabs
			v-model="activeTab"
			:class="{
				'hide-once-tab': hideOnceTab && tabs.length === 1,
			}"
			@tab-click="tabClick"
		>
			<el-tab-pane
				v-for="tab in rawTabs"
				:key="tab.name"
				:label="tab.label"
				:name="tab.name"
			>
				<slot v-if="mode === 'slot' && tab.name === activeTab"/>
				<component
					:is="tab.component"
					v-else-if="mode === 'component' && tab.name === activeTab"
					:tab="tab"
					:rules="tab.rules"
					:is-edit="isEdit"
					@register-tabs-methods="registerTabsMethods"
				/>
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import cloneDeep from 'lodash/cloneDeep'
import { toRaw } from 'vue'

export default {
	name: 'PageTabsWrapper',
	props: {
		mode: {
			type: String,
			required: true,
			validator: value => ['slot', 'component'].indexOf(value) !== -1,
		},
		tabs: {
			type: Array,
			required: true,
		},
		activeTabLabel: {
			type: String,
			required: false,
			default: '',
		},
		useParams: {
			type: Boolean,
			required: false,
			default: false,
		},
		isEdit: {
			type: Boolean,
			required: false,
			default: false,
		},
		hideOnceTab: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	data() {
		return {
			activeTab: '',
			update: 0,
		}
	},
	computed: {
		...mapGetters('layout', ['gActiveTab', 'gActiveTabData']),

		rawTabs() {
			return this.tabs.map(tab => ({
				...tab,
				component: toRaw(tab.component),
			}))
		},
		localActiveTabLabel() {
			return this.activeTabLabel || 'activeTab'
		},
		routeKey() {
			return this.useParams ? 'params' : 'query'
		},
		$$route() {
			return this.$route[this.useParams ? 'params' : 'query']
		},
	},
	created() {
		this.setTab()
		this.$emit('register-tabs-methods', {
			beforeEdit: this.beforeEdit,
			beforeCancel: this.beforeCancel,
			cancelled: this.cancelled,
			beforeDelete: this.beforeDelete,
			deleted: this.deleted,
			beforeRestore: this.beforeRestore,
			restored: this.restored,
			saved: this.saved,
			activateTab: this.activateTab,
			validation: this.validation,
		})
	},
	methods: {
		...mapMutations('layout', [
			'mActiveTab',
		]),
		activateTab(tabName) {
			if (this.activeTab === tabName) return

			this.activeTab = tabName
			this.tabClick({ paneName: tabName })
			this.mActiveTab(tabName)
		},
		routeReplace(tabName) {
			// TODO rewrite it
			this.$router.replace({ [this.useParams ? 'params' : 'query']: { ...[this.useParams ? 'params' : 'query'], [this.localActiveTabLabel]: tabName } })
		},
		async tabClick({ paneName }) {
			if (this.gActiveTab !== paneName) {
				await this.routeReplace(paneName)
				this.mActiveTab(this.activeTab)
			}
		},
		setTab() {
			let activeTab = this.$$route[this.localActiveTabLabel]
			if (!activeTab) {
				activeTab = this.tabs[0].name
				this.routeReplace(activeTab)
			}
			this.activeTab = activeTab
			this.mActiveTab(this.activeTab)
		},
		saved() {
			this.tabsMethods.mxCheckChanges()
			if (this.tabsMethods.saved) {
				this.tabsMethods.saved()
				this.tabsMethods.mxTabData = cloneDeep(this.gActiveTabData)
			}
		},
		cancelled() {
			this.tabsMethods.mxOnCancel()
			if (this.tabsMethods.cancelled) {
				this.tabsMethods.cancelled()
			}
		},
		deleted() {
			this.tabsMethods.mxOnDelete()
			if (this.tabsMethods.deleted) {
				this.tabsMethods.deleted()
			}
		},
		restored() {
			this.tabsMethods.mxOnRestore()
			if (this.tabsMethods.restored) {
				this.tabsMethods.restored()
			}
		},
		validation() {
			if (this.tabsMethods.validation) {
				this.tabsMethods.validation()
			} else {
				this.tabsMethods.mxValidation()
			}
		},
		registerTabsMethods(methods) {
			this.tabsMethods = methods
		},
	},
}
</script>

<style lang="scss">
.layout-part-tabs-wrapper {
	.el-tabs__header {
		margin: 0 0 24px;
	}

	.el-tabs.hide-once-tab {
		.el-tabs__header {
			display: none;
		}
	}
}
</style>