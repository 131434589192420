let aStatisticsThrottleTimeout = true
setTimeout(() => { aStatisticsThrottleTimeout = false }, 10000)

export default async function (to, from, next) {
	if (!aStatisticsThrottleTimeout) {
		this.$store.dispatch('statistics/aStatistics')
		aStatisticsThrottleTimeout = true
		setTimeout(() => { aStatisticsThrottleTimeout = false }, 10000)
	}
	next()
}