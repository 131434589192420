<template>
	<el-pagination
		:key="'' + model.number + model.size"
		:current-page="+model.page"
		:page-size="+model.size"
		:total="+model.totalElements"
		:layout="layout"
		:background="background"
		:hide-on-single-page="hideOnSinglePage"
		class="layout-part-pagination mt-4 pb-2"
		popper-class="ta-select-dropdown ta-select-dropdown__pagination"
		small
		@size-change="onChangePaginationSize"
		@current-change="onChangePaginationPage"
	/>
</template>

<script>
const defaultPaginationServerResponse = function () {
	return {
		empty: false,
		first: false,
		last: false,
		number: 0,
		numberOfElements: 10,
		pageable: {
			sort: {
				sorted: true,
				unsorted: false,
				empty: false,
			},
			offset: 0,
			pageNumber: 0,
			pageSize: 10,
			paged: true,
		},
		size: 100,
		sort: { sorted: true, unsorted: false, empty: false },
		totalElements: 0,
		totalPages: 0,
	}
}

export default {
	name: 'LayoutPartPagination',
	props: {
		modelValue: {
			type: Object,
			default: defaultPaginationServerResponse,
		},
		saveState: {
			type: Boolean,
			default: true,
		},
		saveStateKey: {
			type: String,
			default: 'pagination.size',
		},
		saveRouteQuery: {
			type: Boolean,
			default: true,
		},
		background: {
			type: Boolean,
			default: false,
		},
		hideOnSinglePage: {
			type: Boolean,
			default: false,
		},
		forceLayout: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			model: {},
		}
	},
	computed: {
		layout() {
			if (this.forceLayout) return this.forceLayout

			if (!this.model.totalElements) {
				return 'total'
			} if (this.model.totalElements <= this.model.size) {
				return 'total, sizes'
			}
			return 'total, sizes, prev, pager, next'
		},
		componentsState() {
			return this.$store.getters['user/gComponentsState'] || {}
		},
	},
	watch: {
		modelValue: {
			deep: true,
			immediate: false,
			handler(val) {
				if (!val) return
				if (this.isValExceedsTheNumber(val)) {
					this.$nextTick(() => this.onChangePaginationPage(1))
				} else if (!this.model.page || this.model.page !== val.page || this.model.size !== val.size) {
					const page = val.number ? (val.number + 1) : (val.page || 1)
					this.model = {
						...this.model,
						...val,
						page,
					}
					this.updateQuery()
					this.saveStore()
				}
			},
		},
	},
	created() {
		const value = {
			...defaultPaginationServerResponse(),
			...(this.modelValue || {}),
		}

		if (this.$route.query.size) {
			value.size = +this.$route.query.size
		}
		if (this.$route.query.page) {
			value.page = +this.$route.query.page
			value.number = value.page - 1
		}
		this.model = value

		if (!this.$route.query.size && this.saveState) {
			this.loadStore()
		} else {
			this.$emit('update:modelValue', this.model)
		}

		this.$emit('on-init', this.model)
	},
	methods: {
		loadStore() {
			const model = {}
			const componentState = this.componentsState[this.saveStateKey]
			const size = componentState?.size || componentState
			const number = componentState?.number

			if (size) {
				model.size = size
			}

			if (number || number === 0) {
				model.page = number + 1
			}

			this.model = {
				...this.model,
				...model,
			}

			this.$emit('update:modelValue', this.model)
		},
		isValExceedsTheNumber(val) {
			if (!val.totalElements) return false
			const maxNumber = Math.floor(val.totalElements / val.size)
			return val.number > maxNumber
		},
		onChangePaginationSize(size) {
			const currentRow = this.model.size * this.model.number
			const newNumberUp = Math.floor(currentRow / size)

			this.model.number = newNumberUp
			this.model.page = newNumberUp + 1
			this.model.size = size

			this.updateQuery()
			this.saveStore()
			this.$emit('update:modelValue', this.model)
		},
		onChangePaginationPage(page = 1) {
			this.model.number = page - 1
			this.model.page = page

			this.updateQuery()
			this.saveStore()
			this.$emit('update:modelValue', this.model)
		},
		saveStore() {
			if (this.saveState) {
				this.$store.commit('user/mSetComponentStateSession', {
					[this.saveStateKey]: {
						size: this.model.size,
						number: this.model.number,
					},
				})
			}
		},
		updateQuery() {
			if (!this.saveRouteQuery) return false
			if (Number.isNaN(this.model.page)) {
				this.model.page = 1
				return
			}
			if (+this.$route.query.size === +this.model.size
				&& +this.$route.query.page === +this.model.page) {
				return
			}

			// eslint-disable-next-line
			if (this.$route.query.size != this.model.size || this.$route.query.page != this.model.page) {
				const query = {
					...this.$route.query,
					size: this.model.size,
					page: this.model.page,
				}

				this.$router.replace({
					query,
				})
			}
		},
	},
}
</script>

<style lang="scss">
	.layout-part-pagination {
		& * {
			font-size: 12px !important;
		}

		.active {
			font-weight: 600 !important;
			box-shadow: none !important;
			border-radius: 4px !important;
		}
	}
</style>