<template>
	<div
		id="layout-page-tabs"
		v-loading="isLoading"
	>
		<div class="d-flex justify-content-between mb-4">

			<LayoutPartTitle
				v-if="title"
				v-bind="layoutParams"
				@on-return="onReturn"
			/>

			<LayoutPartActionButtons
				v-if="topButtons && !isLoading && !buttonsShowOnlyBottom"
				ref="topControls"
				:use-buttons="topButtons"
				:is-edit="isEdit"
				:is-deleted="isDeleted"
				:is-changed="isChanged"
				:delete-confirmation-text="layoutParams.deleteConfirmation"
				:disable-buttons="disableButtons"
				:confirm-button="layoutParams.confirmButton"
				:delete-confirmation-title="layoutParams.deleteConfirmationTitle"
				size="default"
				@on-cancel="onCancel"
				@on-edit="onEdit"
				@on-delete="onDelete"
				@on-restore="onRestore"
				@on-save="onSave"
			/>
		</div>

		<el-tabs
			v-if="availableTabs.length > 1"
			v-model="activeTabName"
			:before-leave="beforeChangeTab"
			class="mb-2"
			style="height: 47px"
		>
			<el-tab-pane
				v-for="item in availableTabs"
				:key="item.name"
				:label="item.label"
				:name="item.name"
			/>
		</el-tabs>

		<component
			:is="rawPage"
			:key="activeTabName + update"
		/>

		<div class="mt-8 d-flex justify-content-flex-end">
			<LayoutPartActionButtons
				v-if="(mxShowBottomButtons && !isLoading) || bottomButtons"
				ref="bottomControls"
				:use-buttons="bottomButtons"
				:is-edit="isEdit"
				:is-deleted="isDeleted"
				:is-changed="isChanged"
				:delete-confirmation-text="layoutParams.deleteConfirmation"
				:disable-buttons="disableButtons"
				:confirm-button="layoutParams.confirmButton"
				:delete-confirmation-title="layoutParams.deleteConfirmationTitle"
				size="default"
				@on-cancel="onCancel"
				@on-edit="onEdit"
				@on-delete="onDelete"
				@on-restore="onRestore"
				@on-save="onSave"
			/>
		</div>
	</div>
</template>

<script>
import { markRaw } from 'vue'
import { mapMutations, mapGetters, mapState } from 'vuex'
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import mxLayouts from '~/mixins/layouts/mxLayouts'
import LayoutPartTitle from '~/layout/parts/LayoutPartTitle.vue'
import LayoutPartActionButtons from '~/layout/parts/LayoutPartActionButtons.vue'
import LayoutPartTabsWrapper from '~/layout/parts/LayoutPartTabsWrapper.vue'
// import { showObjectsDiff } from '~/utils/objectsDiff'
// import isLocalhost from '~/utils/isLocalhost'
import layoutBeforeRouteEnter from './data/layoutBeforeRouteEnter'
import { DEFAULT_LAYOUT_PAGE_TABS_PARAMS } from './data/defaults'
import { onEdit,
	onCancel,
	onDelete,
	onRestore,
	onSave,
	onReturn } from './data/layoutButtonsMethods'

let throttleToHandle = null
const THROTTLE_SIZE = 300

let tabsRules = {}
let tabsCurrentData = {}
let tabsOriginalData = {}

export default {
	name: 'LayoutPageTabs',
	components: {
		LayoutPartTitle,
		LayoutPartActionButtons,
		LayoutPartTabsWrapper,
	},
	mixins: [mxLayouts],
	provide() {
		return {
			layoutMethods: {
				forceUpdate: this.updateLayout,
				registerComponent: this.registerComponent,
				unregisterComponent: this.unregisterComponent,
				updateParams: this.updateParams,
				updateComponentRules: this.updateComponentRules,
				setOriginalData: this.setOriginalData,
				setCurrentData: this.setCurrentData,
			},
		}
	},
	async beforeRouteLeave(to, from, next) {
		const res = await this.mxConfirmIfFormChanged()
		next(Boolean(res))
	},
	props: {
		Page: {  // eslint-disable-line
			type: [Object, Function],
			default: null,
		},
		Tabs: {  // eslint-disable-line
			type: Array,
			default: () => ([]),
		},
	},
	data() {
		return {
			activeTabName: null,
			isLoading: true,
			isSavingProceed: false,
			isDeletionProceed: false,
			isResoreProceed: false,
			deleteConfirmation: '',
			update: 0,
			layoutParams: DEFAULT_LAYOUT_PAGE_TABS_PARAMS(),
			tabsRules: {},
			tabsCurrentData: {},
			tabsOriginalData: {},
		}
	},
	computed: {
		...mapState('layout', [
			'tabsData',
			'isEdit',
			'isChanged',
			'isDeleted',
			'activeTab',
			'changedTabs',
		]),
		...mapGetters('layout', [
			'gHasChangedTabs',
			'gTabsData',
		]),
		rawPage() {
			return markRaw(this.Page || this.activeTab.component)
		},
		availableTabs() {
			return (this.Tabs || []).filter(tab => {
				let tabNotAvailable = false
				if (tab.notAvailable) {
					tabNotAvailable = tab.notAvailable(this.$store)
				}
				if (tab.notAvailableToRoles) {
					tabNotAvailable = tabNotAvailable || tab.notAvailableToRoles.filter(role => this.authorities.includes(role)).length
				}
				return !tabNotAvailable
			})
		},
		activeTab() {
			return this.availableTabs.find(tab => tab.name === this.activeTabName) || {}
		},
		title() {
			return this.layoutParams.title || ''
		},
		disableButtons() {
			const buttons = this.layoutParams?.disableButtons || {}
			return {
				...buttons,
				save: !this.isChanged || this.isSavingProceed,
				delete: buttons.delete || this.isDeletionProceed,
				restore: buttons.restore || this.isResoreProceed,
			}
		},
		topButtons() {
			return this.layoutParams.buttons?.top || this.layoutParams.buttons
		},
		bottomButtons() {
			return this.layoutParams.buttons?.bottom || this.layoutParams.buttons
		},
		buttonsShowOnlyBottom() {
			return this.layoutParams.buttonsShowOnlyBottom
		},
	},

	watch: {
	},

	// eslint-disable-next-line vue/order-in-components
	beforeRouteEnter: layoutBeforeRouteEnter,

	async created() {
		this.mLayoutDropState()
		if (this.availableTabs.length) {
			const activeTab = this.availableTabs.find(tab => tab.tab === (this.$route.params.tab || ''))
			this.activeTabName = activeTab?.name || this.availableTabs[0].name
		}
	},

	mounted() {
		this.addListeners()
	},

	beforeUnmount() {
		this.removeListeners()
	},

	methods: {
		...mapMutations('layout', [
			'mLayoutDropState',
			'mSetChangedState',
			'mSetEditState',
			'mSetDeletedState',
			'mRestorePageData',
			'mSetTabData',
		]),

		onEdit,
		onCancel,
		onDelete,
		onRestore,
		onSave,
		onReturn,

		async beforeChangeTab(nextTabName) {
			this.initializationCounter = 0
			this.layoutParams = DEFAULT_LAYOUT_PAGE_TABS_PARAMS()
			this.loading = true
			const nextTab = this.availableTabs.find(tab => tab.name === nextTabName)
			const newRoute = {
				name: this.$route.name,
				params: {
					tab: nextTab.tab || null,
				},
				query: null,
			}
			await this.$router.replace(newRoute)
			this.activeTabName = nextTab.name
		},

		layoutParamsUpdate(layoutParams) {
			this.layoutParams = {
				...this.layoutParams,
				...layoutParams,
				hasReturnButton: typeof layoutParams.hasReturnButton === 'boolean' ? layoutParams.hasReturnButton : this.hasReturnButton,
			}

			this.mSetDeletedState(Boolean(layoutParams.isDeleted))

			let { title, browserTitle } = layoutParams // eslint-disable-line
			if (!browserTitle && Array.isArray(title)) {
				title = title[1] || title[0]
			}
			this.setDocumentTitle(browserTitle || title)
		},

		setButtonsState(payload) {
			this.pageButtonsState = {
				...this.pageButtonsState,
				...payload,
			}
		},
		async getInvalidTabs() {
			const rulesArr = Object.entries(tabsRules)
			const validations = rulesArr.map(([tab, rules]) => this.mxPreValidation(tabsCurrentData[tab], rules || []))
			const validationResults = await Promise.allSettled(validations)

			return validationResults.map((res, index) => {
				if (res.status === 'fulfilled') {
					return null
				}
				return rulesArr[index][0]
			}).filter(tab => Boolean(tab))
		},

		async validateTabs() {
			const invalidTabs = await this.getInvalidTabs()

			if (invalidTabs.length) {
				this.activateTab(invalidTabs[0])

				this.$nextTick(() => {
					if (this.componentsMethods.validation) {
						this.componentsMethods.validation()
					}
				})
				return false
			}
			return true
		},

		activateTab(tabName) {
			this.activeTabName = tabName
		},

		forceUpdateLayout() {
			this.update += 1
		},

		registerTabsMethods(methods) {
			this.tabsMethods = methods
		},

		registercomponentsMethods(methods) {
			this.componentsMethods = methods
		},

		async loadComponentData() {
			if (!tabsCurrentData[this.activeTab.name]) {
				const layoutData = await this.componentCtx.loadData()
				this.componentCtx.data = cloneDeep(layoutData)
				if (this.componentCtx.rules) {
					tabsRules[this.activeTab.name] = this.componentCtx.rules
				}
				tabsCurrentData[this.activeTab.name] = this.componentCtx.data
				tabsOriginalData[this.activeTab.name] = layoutData
			} else {
				this.componentCtx.data = tabsCurrentData[this.activeTab.name]
			}
			this.isLoading = false
		},

		setCurrentData(data) {
			tabsCurrentData[this.activeTab.name] = data
		},

		setOriginalData(data) {
			tabsOriginalData[this.activeTab.name] = cloneDeep(data)
		},

		async updateComponentRules() {
			tabsRules[this.activeTab.name] = this.componentCtx.rules
		},

		registerComponent(componentCtx) {
			if (!componentCtx.layoutParams) {
				throw new Error('Компонент страницы должен содержать объект с параметрами шаблона layoutParams')
			}
			if (!componentCtx.loadData) {
				throw new Error('Компонент страницы должен содержать метод loadData()')
			}

			this.mSetEditState(false)

			this.componentsWatchers = []
			this.componentsWatchers.push(componentCtx.$watch(`layoutParams`, this.updateParams))

			this.componentCtx = componentCtx
			this.mxLayoutParamsUpdate(componentCtx.layoutParams)

			componentCtx.layoutParams = {
				...DEFAULT_LAYOUT_PAGE_TABS_PARAMS(),
				...componentCtx.layoutParams,
			}

			this.loadComponentData()

			this.componentsMethods = {
				validation: componentCtx.validation,
				save: componentCtx.save,
				saved: componentCtx.saved,
				edit: componentCtx.edit,
				cancel: componentCtx.cancel,
				return: componentCtx.return,
				delete: componentCtx.delete,
				deleted: componentCtx.deleted,
				restore: componentCtx.restore,
			}

			this.$nextTick(() => {
				this.mxCheckupButtonsMethodsExistsOnThePageDI()
				this.mxCheckupBottomButtonsVisibilityDI()
			})
			this.addListeners()
		},

		unregisterComponent() {
			this.componentsMethods = {}
			this.componentsWatchers.forEach(fn => fn())
			this.componentsWatchers = []
			this.removeListeners()
		},

		resetLayout() {
			tabsRules = {}
			tabsCurrentData = {}
			tabsOriginalData = {}
			this.registerComponent(this.componentCtx)
		},

		updateParams(layoutParams) {
			this.mxLayoutParamsUpdate(layoutParams)
		},

		addListeners() {
			this.$el.addEventListener('input', this.checkChanges)
			this.$el.addEventListener('focusout', this.checkChanges)
			this.$el.addEventListener('click', this.checkChanges)
			this.$el.addEventListener('drop', this.checkChanges)
		},

		removeListeners() {
			this.$el.removeEventListener('input', this.checkChanges)
			this.$el.removeEventListener('focusout', this.checkChanges)
			this.$el.removeEventListener('click', this.checkChanges)
			this.$el.removeEventListener('drop', this.checkChanges)
		},

		checkChanges() {
			function check() {
				// Если уходить со страницы с активный курсором в любом поле, сработает focusout,
				// и на момент проверки в данных страницы будет null
				// const isUserStaysOnPage = Boolean(this.gPageData)
				const isUserStaysOnPage = true
				const dataChanged = isUserStaysOnPage && !isEqual(tabsCurrentData, tabsOriginalData)
				this.mSetChangedState(dataChanged)
				throttleToHandle = null

				/* if (dataChanged && isLocalhost) {
					showObjectsDiff(tabsCurrentData, tabsOriginalData)
				} */
			}

			if (throttleToHandle) {
				clearTimeout(throttleToHandle)
			}

			throttleToHandle = setTimeout(() => check.call(this), THROTTLE_SIZE)
		},
	},
}
</script>

<style lang="scss">
	#layout-page-tabs {
		min-height: 700px;
	}
</style>
