<template>
	<div
		class="part-order-services-table"
		:class="isServicesTableNoScroll && 'part-order-services-table--no-scroll'"
	>
		<div
			class="el-table el-table--fit el-table--striped el-table--scrollable-y el-table--enable-row-hover el-table--enable-row-transition el-table--small"
			data-ta="part-order-table-services"
			style="height: 280px;"
		>
			<div class="el-table__header-wrapper">
				<table
					cellspacing="0"
					cellpadding="0"
					border="0"
					class="el-table__header"
					style="width: 669px; min-height: 44px;"
				>
					<colgroup>
						<col name="col_2" width="210">
						<col name="col_3" width="72">
						<col name="col_4" width="76">
						<col name="col_5" width="210">
						<col name="col_6" width="100">
					</colgroup>
					<thead>
						<tr>
							<th class="col_2 is-left is-leaf el-table__cell">
								<div class="cell">{{ $t('Описание') }}</div>
							</th>
							<th class="col_3 is-left is-leaf el-table__cell">
								<div class="cell">{{ $t('Цена') }}</div>
							</th>
							<th class="col_4 is-left is-leaf el-table__cell">
								<div class="cell">{{ $t('Кол-во') }}</div>
							</th>
							<th class="col_5 is-right is-leaf el-table__cell">
								<div class="cell">{{ $t('Скидка') }}</div>
							</th>
							<th class="col_6 is-right is-leaf el-table__cell">
								<div class="cell">{{ $t('Стоимость') }}</div>
							</th>
						</tr>
					</thead>
				</table>
			</div>
			<div class="el-table__body-wrapper is-scrolling" style="height: 236px;">
				<table
					cellspacing="0"
					cellpadding="0"
					border="0"
					class="el-table__body"
				>
					<colgroup>
						<col name="col_2" width="210">
						<col name="col_3" width="72">
						<col name="col_4" width="76">
						<col name="col_5" width="210">
						<col name="col_6" width="100">
					</colgroup>
					<tbody>
						<template
							v-for="(row, idx) in servicesList"
							:key="'service' + row.id"
						>
							<tr

								:class="{
									'el-table__row--striped': idx % 2,
									'el-table__expanded-row': row.amount && expandedRows[row.id],
									'el-table__selected-amount': row.amount,
								}"
								class="el-table__row"
								@click="expandToggle(row)"
							>
								<td class="col_2 is-left el-table__cell">
									<div class="cell">
										<span
											:class="row.amount && 'font-semi-bold color-primary'"
										>{{ row.name }}</span></div>
								</td>
								<td class="col_3 is-left el-table__cell">
									<div class="cell nowrap">{{ row.retailPriceStr }}</div>
								</td>
								<td class="col_4 is-left el-table__cell">
									<div class="cell">
										<ComOrderTableQuantity
											v-model="row.amount"
											:max="100"
											:min="0"
											:row="row"
											:changable="true"
											:is-count-updating="isCountUpdating"
											from="services"
											data-ta="order-service-input"
											@update="onChangeAmount(row, $event)"
										/>
									</div>
								</td>
								<td class="col_5 is-right el-table__cell">
									<div class="cell">
										<ComOrderTableDiscounts
											:discounts="row.discounts"
											@deleteDiscount="$emit('deleteDiscount', row.id)"
										/>
									</div>
								</td>
								<td class="col_6 is-right el-table__cell">
									<div class="cell">{{ row.discountPriceStr }}</div>
								</td>
							</tr>
							<tr
								v-if="row.amount && expandedRows[row.id]"
								:key="'employe' + row.id"
							>
								<td
									colspan="5"
									class="el-table__expanded-cell"
								>
									<PartOrderServiceSubrow
										ref="partOrderServiceEmployees"
										:service="row"
										:employee-setting="employeeSetting"
										@close-popover="onClosePopover"
										@open-popover="onOpenPopover"
										@select-employee="onSelectEmployee"
										@select-comment="onSelectComment"
									/>
								</td>
							</tr>
						</template>
					</tbody>
				</table>
			</div>

		</div>
	</div>
</template>

<script>
/* eslint-disable vue/require-default-prop */

import { mapActions, mapState, mapGetters } from 'vuex'
import cloneDeep from 'lodash/cloneDeep'
import PartOrderServiceSubrow from './PartOrderServiceSubrow.vue'
import ComOrderTableQuantity from '../../components/ComOrderTable/ComOrderTableQuantity.vue'
import ComOrderTableDiscounts from '../../components/ComOrderTable/ComOrderTableDiscounts.vue'

export default {
	name: 'PartOrderServicesTable',
	components: {
		PartOrderServiceSubrow,
		ComOrderTableQuantity,
		ComOrderTableDiscounts,
	},
	props: {
		columns: {
			type: Array,
			require: true,
		},
		services: {
			type: Array,
			require: true,
		},
		emptyText: {
			type: String,
			require: true,
		},
	},

	data() {
		return {
			servicesList: [],
			isServicesTableNoScroll: false,
			expandedRows: {},
			isCountUpdating: false,
		}
	},

	computed: {
		...mapState(['settings']),
		...mapState('orders', ['cartUid', 'orderType']),
		...mapState('users', ['selectedSeller']),
		...mapGetters('user', ['gFioFull']),

		employeeSetting() {
			let setting

			if (this.orderType === 'saleOrder') {
				setting = this.settings.storeSelectAllServices
			}

			if (this.orderType === 'repairOrder') {
				setting = this.settings.storeSelectAllServicesForRepair
			}

			return setting
		},
	},

	watch: {
		services: {
			deep: true,
			handler(val) {
				if (!this.servicesList.length) {
					const expandedRows = {}
					val.forEach(service => {
						expandedRows[service.id] = Boolean(service.amount)
					})
					this.expandedRows = expandedRows
				}

				this.init(val)
			},
		},
	},

	methods: {
		...mapActions('orders', [
			'aAddService',
		]),

		init(val) {
			this.servicesList = val?.map(service => ({
				...cloneDeep(service),
			}))
		},

		async onChangeAmount(row, amount) {
			row.amount = amount

			const isNeedComment = row.needsComment && !row.comment
			const isNeedEmployer = this.employeeSetting && !row.employee

			if (isNeedComment) {
				this.expandToggle(row, Boolean(amount))
			}

			if (isNeedEmployer) {
				this.expandToggle(row, Boolean(amount))
				return
			}

			const data = {
				serveTypeId: row.id,
				count: row.amount,
				userId: row?.employee?.id || this.selectedSeller.id,
				comment: row.comment,
			}

			this.addService(data)
		},

		onSelectEmployee(service, comment, employee) {
			service.employee = employee

			const data = {
				serveTypeId: service.id,
				count: service.amount,
				userId: employee.id,
				comment,
			}

			this.addService(data)
		},

		onSelectComment(service, comment, employee) {
			service.comment = comment

			if (this.employeeSetting && !employee) return

			const data = {
				serveTypeId: service.id,
				count: service.amount,
				userId: employee?.id || this.selectedSeller.id,
				comment,
			}
			this.addService(data)
		},

		async addService(data) {
			this.isCountUpdating = true
			await this.aAddService({
				uid: this.cartUid,
				data,
			})
			this.$nextTick(() => { this.isCountUpdating = false })
		},

		validate() {
			const invalidRows = this.servicesList.filter(row => !row.employee && row.amount)

			if (invalidRows.length) {
				invalidRows.forEach(row => {
					this.expandedRows[row.id] = true
				})
				this.$nextTick(() => this.$refs.partOrderServiceEmployees.forEach(vcomp => vcomp.validate()))
			}

			return !invalidRows.length
		},

		setTableNoScroll(value = true) {
			this.isServicesTableNoScroll = value
		},

		onOpenPopover() {
			this.$nextTick(this.setTableNoScroll)
		},

		onClosePopover() {
			this.setTableNoScroll(false)
		},

		expandToggle(row, state) {
			if (!this.employeeSetting && !row.needsComment) {
				state = false // eslint-disable-line
			}

			this.expandedRows[row.id] = state ?? !this.expandedRows[row.id]
		},
	},
}
</script>

<style lang="scss">
.part-order-services-table {
	.hidden {
		display: none;
	}

	.employee-selector-wrap {
		padding: 3px 10px;
		display: flex;
		justify-content: space-between;
		width: 100%;
		gap: 8px;
	}

	.el-table__expanded-row td {
		border-bottom: none;
		cursor: pointer;
	}

	.el-table__row {
		min-height: 36px !important;

		td {
			padding: 4px 0 !important;
		}
	}

	.cell {
		padding: 0 7px !important;
	}

	// &--no-scroll > .el-table > .el-table__body-wrapper {
	// overflow: hidden;
	// }

	.el-table__expanded-row td,
	.el-table__expanded-row + tr td {
		background-color: rgb(242, 246, 252) !important;
	}

	.el-table__selected-amount {
		background-color: rgb(242, 246, 252) !important;
	}

	.el-table__body .el-table__row:hover > td {
		background-color: transparent !important;
	}

	.el-table__body .el-table__row--striped:hover > td {
		background-color: transparent !important;
	}

	.is-scrolling {
		overflow-y: auto;
	}

	.el-table th {
		background-color: var(--el-background-color-dark);
	}

	.el-table__row--striped td {
		background: var(--el-background-color-base);
	}
}
</style>