import i18n from '~/plugins/i18n'

export const TRANSFER_STATUSES = {
	SAVED: {
		label: i18n.global.t('Сохранен'),
		value: 'SAVED',
		color: '#909399',
	},
	REGISTERED: {
		label: i18n.global.t('Подтвержден'),
		value: 'REGISTERED',
		color: '#1989FA',
	},
	PARTLY_RECEIVED: {
		label: i18n.global.t('Частично принят'),
		value: 'PARTLY_RECEIVED',
		color: '#FB9E13',
	},
	COMPLETED: {
		label: i18n.global.t('Завершен'),
		value: 'COMPLETED',
		color: '#1CAD4B',
	},
	EXCEPT_SAVED: {
		label: i18n.global.t('Кроме сохраненных'),
		value: 'EXCEPT_SAVED',
	},
}