import VuexHttp from '../../vuexHttpService'

const vHttp = new VuexHttp('balance')

export default {
	async aBalance() {
		return vHttp.GET(this, {
			url: 'balance',
			mutation: 'mBalance',
		})
	},
	async aBalanceOperations() {
		return vHttp.GET(this, {
			url: 'balance/operations',
			mutation: 'mBalanceOperations',
		})
	},
}