<template>
	<el-input
		v-bind="attributes"
		ref="searchInput"
		v-model="data"
		:placeholder="field.placeholder"
		:disabled="field.disabled"
		:data-ta="field.name"
		:clearable="field.clearable"
		class="filter-search-input font-size-12"
		@update:modelValue="value => onSearchInput(value, field)"
		@paste="onPaste"
		@clear="onClear"
		@keyup.enter.prevent="onClickEnter"
	>
		<template #append>
			<el-button
				icon="search"
				@click="onSearchClick"
			/>
		</template>
	</el-input>
</template>

<script>
// Ф-я корректировки отображения введенного значения вместо v-mask
const DEFAULT_VIEW_PATTERN = value => value.replace(/\s\s+/g, ' ').replace(/^\s+|[^0-9 ]/g, '')
const DEFAULT_VALUE_PATTERN = value => value.replace(/[^0-9]/g, '')

export default {
	name: 'FilterSearchInput',

	props: {
		modelValue: {
			type: String,
			default: '',
		},
		field: {
			type: Object,
			required: true,
		},
		applyImmediately: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			data: null,
			attributes: {},
		}
	},
	computed: {
		inputViewPattern() {
			return this.field.inputViewPattern || DEFAULT_VIEW_PATTERN
		},
		inputValuePattern() {
			return this.field.inputValuePattern || DEFAULT_VALUE_PATTERN
		},
		cleanedValue() {
			if (this.inputValuePattern) {
				// eslint-disable-next-line vue/no-use-computed-property-like-method
				return this.inputValuePattern(this.data)
			}
			return this.data
		},
	},
	watch: {
		modelValue: {
			immediate: true,
			handler(value) {
				this.data = value
			},
		},
		'field.focus': {
			handler(value) {
				if (value) {
					this.$refs.searchInput.focus()
				}
			},
		},
	},
	methods: {
		// Валидация максимальной длины ввода
		updateInputMaxLength() {
			if (this.field.searchValueMaxLength) {
				if (this.field.searchValueMaxLength >= this.cleanedValue.length) {
					this.attributes = {
						...this.attributes,
						maxlength: this.data.length + Number(this.field.searchValueMaxLength > this.cleanedValue.length),
					}
				}
			}
		},
		onSearchInput() {
			if (this.inputViewPattern) {
				// eslint-disable-next-line vue/no-use-computed-property-like-method
				this.data = this.inputViewPattern(this.data)
			}

			this.updateInputMaxLength()
			this.$emit('update:modelValue', this.cleanedValue)

			if (this.field.onInput) {
				this.field.onInput(this.cleanedValue)
				return
			}

			if (this.field.searchValueMaxLength === this.cleanedValue.length) {
				this.$emit('on-search', this.cleanedValue)
			}
		},
		onSearchClick() {
			if (this.field.onClickBtn) {
				this.field.onClickBtn(this.cleanedValue)
			}
			this.$emit('on-search', this.cleanedValue)
		},
		onClickEnter() {
			this.$emit('on-search', this.cleanedValue)
		},
		onClear() {
			this.onSearchInput('', this.field)
			this.$emit('on-search', '')
		},
		onPaste() {
			this.attributes = {
				...this.attributes,
				maxlength: null,
			}
		},
	},
}
</script>

<style lang="scss">
.filter-search-input {
	.el-input-group__append, .el-input-group__prepend {
		padding: 0;
		width: 30px;
		.el-button {
			padding: 0;
			width: 30px;
		}
	}
}
</style>