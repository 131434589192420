import workshopsState from './workshopsState'
import workshopsActions from './workshopsActions'
import workshopsMutations from './workshopsMutations'
import workshopsGetters from './workshopsGetters'

export default {
	namespaced: true,
	state: workshopsState,
	getters: workshopsGetters,
	mutations: workshopsMutations,
	actions: workshopsActions,
}