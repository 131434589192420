<template>
	<div
		class="layout-part-action-blocks d-flex align-items-center gap-4"
		:class="contentPosition"
	>
		<div
			v-for="item in filteredBlocks"
			:key="item.title"
		>
			<WidgetSellerSelect
				v-if="item.type === 'sellerSelect'"
				v-bind="item"
			/>

			<div v-else-if="item.type === 'switch'">
				<span class="mr-2">{{ item.label }}</span>
				<el-switch
					v-model="item.value"
					@update:modelValue="value => onSwitchChange(value, item)"
				/>
			</div>

			<LayoutPartButtonsGroup
				v-if="item.type === 'buttonsGroups'"
				:buttons-list="item.buttonsList"
				:label="item.label"
				:dropdown-mode="item.dropdownMode"
			/>

			<el-tooltip
				v-else-if="item.hasTooltip"
				placement="bottom"
				effect="light"
				:content="item.tooltip"
				:disabled="!item.tooltip"
			>
				<el-button
					:type="item.type"
					:plain="item.plain"
					:loading="item.loading"
					:disabled="item.disabled"
					class="bold"
					size="default"
					:data-ta="item.dataTa"
					:class="{
						'pl-3 pr-3': item.icon && !item.class,
						[item.class]: item.class,
						'layout-part-action-blocks__button': item.dataTa !== 'print',
					}"
					@click="item.action && item.action() || $noop"
				>
					<ItIcon
						v-if="item.icon"
						:name="item.icon"
						:class="item.iconClass"
					/>
					<span v-if="item.title">{{ item.title }}</span>
				</el-button>
			</el-tooltip>
		</div>
	</div>
</template>

<script>
import WidgetSellerSelect from '~/widgets/WidgetSellerSelect.vue'
import LayoutPartButtonsGroup from './LayoutPartButtonsGroup.vue'

export default {
	name: 'LayoutPartActionBlocks',
	components: {
		WidgetSellerSelect,
		LayoutPartButtonsGroup,
	},
	props: {
		position: {
			type: String,
			default: 'end',
		},
		blocks: {
			type: Array,
			default: null,
		},
	},
	data() {
		return {
			filteredBlocks: [],
		}
	},
	computed: {
		contentPosition() {
			const positions = {
				start: 'justify-content-start',
				center: 'justify-content-center',
				end: 'justify-content-flex-end',
			}
			return positions[this.position]
		},
	},
	watch: {
		blocks: {
			immediate: true,
			handler(value = []) {
				this.filteredBlocks = value.filter(block => !block.hidden).map(block => ({
					...block,
					hasTooltip: !['switch', 'sellerSelect'].includes(block.type),
				}))
			},
		},
	},
	methods: {
		onSwitchChange(value, item) {
			return (item.action && item.action(value)) || this.$noop
		},
	},
}
</script>

<style lang="scss">
.layout-part-action-blocks {
	height: 36px;

	&__button {
		min-width: 108px;
	}
}
</style>