import VuexHttp from '../../vuexHttpService'

const vHttp = new VuexHttp('returns')

export default {
	namespaced: true,
	state: {
		returnInfo: null,
		returnItems: [],
		returnAvailableCash: 0,
		fromCardReturn: null,
	},
	actions: {
		async aReturnByNum(_, id) {
			return vHttp.GET(this, {
				url: `/order-returns/${id}`,
				mutation: 'mReturnByNum',
			})
		},
		async aReturnsByOrderId(_, id) {
			return vHttp.GET(this, {
				url: `/order-returns/by-order/${id}`,
			})
		},
		async aReturnsByGoodsId(_, id) {
			return vHttp.GET(this, {
				url: `/order-returns/by-goods/${id}`,
				mutation: 'mReturnsByGoodsId',
			})
		},
		async aReturnsByServeId(_, id) {
			return vHttp.GET(this, {
				url: `/order-returns/by-serve/${id}`,
				mutation: 'mReturnsByServeId',
			})
		},
		async aReturnsByGiftCertId(_, id) {
			return vHttp.GET(this, {
				url: `/order-returns/by-gift-certificate/${id}`,
				mutation: 'mReturnsByGiftCertId',
			})
		},
		async aReceiptPrint(_, { data, successMessage }) {
			return vHttp.POST(this, {
				url: 'receipt/returnSale',
				data,
				successMessage,
			})
		},
		async aChangePaymentType(_, { id, data, successMessage }) {
			return vHttp.PUT(this, {
				url: `order-returns/${id}/payment-type`,
				data,
				action: 'aReturnByNum',
				actionPayload: id,
				successMessage,
			})
		},
		async aReturnItems(_, orderId) {
			return vHttp.GET(this, {
				url: `orders/${orderId}/return-items`,
				mutation: 'mReturnItems',
			})
		},
		async aSaveReturnItems(_, data) {
			const { orderId } = data
			delete data.orderId
			return vHttp.POST(this, {
				url: `orders/${orderId}/return-items`,
				data,
			})
		},
	},
	mutations: {
		mReturnByNum(state, payload) {
			state.returnInfo = payload
		},
		mReturnItems(state, payload) {
			state.returnItems = payload.sellableResponses
			state.returnAvailableCash = payload.availableCash
			state.fromCardReturn = payload.fromCardReturn
		},
		mReturnsByGoodsId(state, payload) {
			state.returnInfo = payload
		},
		mReturnsByServeId(state, payload) {
			state.returnInfo = payload
		},
		mReturnsByGiftCertId(state, payload) {
			state.returnInfo = payload
		},
	},
}