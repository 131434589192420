<template>
	<ComTableExpandable
		ref="comOrderTable"
		:items="cartData"
		:columns="cartColumns"
		:empty-text="$t('Корзина пуста')"
		:expandable="true"
		:stripe="true"
		class="part-order-goods-table mt-6"
		max-height="auto"
	>
		<template #type="{ row }">
			<div class="d-flex flex-column">
				<span>{{ row.type }}</span>
				<span
					v-if="row.sellableCategory === 'CONTACT_LENS'"
				>{{ row.onlyFullPackages ? $t('Упаковки') : $t('Блистеры') }}</span>
			</div>
		</template>

		<template #description="{ row }">
			<ComOrderTableDescription
				:description="row.description"
				:sellable-category="row.sellableCategory"
			/>
		</template>

		<template #retailPrice="{ row }">
			{{ formatCurrency(row.price, $i18n.locale) }}
		</template>

		<template #currentQuantity="{ row }">
			<ComOrderTableQuantity
				ref="comOrderTableQuantity"
				:key="updateQuantity"
				v-model="row.groupSize"
				:row="row"
				:max="row.maxSize || 100"
				:is-count-updating="isCountUpdating"
				from="goodsCart"
				@isTooltipVisible="onTooltipVisible"
				@update="updateItemCount(row, $event)"
				@onDelete="deleteGoods"
			/>
		</template>

		<template #discounts="{ row }">
			<ComOrderTableDiscounts
				:discounts="row.discounts"
				@deleteDiscount="deleteDiscount(row)"
				@isTooltipVisible="onTooltipVisible"
			/>
		</template>

		<template #cost="{ row }">
			{{ formatCurrency(row.totalDiscountPrice, $i18n.locale) }}
		</template>

		<template #expandRow="{ row }">
			<div class="part-order-goods-table__tags">
				<ComOrderTags :tags="row.description.nestedParams"/>
			</div>
		</template>
	</ComTableExpandable>
</template>

<script>
import { mapActions, mapMutations, mapGetters, mapState } from 'vuex'
import cloneDeep from 'lodash/cloneDeep'
import clientsList from '~/mock/clientsList.js'
import { formatCurrency } from '~/utils/currencies'
import multiplicityCLPackageTest from '~/utils/multiplicityCLPackageTest'
import ComTableExpandable from '~/components/ComTableExpandable.vue'
import ComOrderTableQuantity from '../components/ComOrderTable/ComOrderTableQuantity.vue'
import ComOrderTableDescription from '../components/ComOrderTable/ComOrderTableDescription.vue'
import ComOrderTags from '../components/ComOrderTags.vue'
import ComOrderTableDiscounts from '../components/ComOrderTable/ComOrderTableDiscounts.vue'
import { cartColumns } from '../data/cartColumns'

export default {
	name: 'PartOrderGoodsTable',
	components: {
		ComTableExpandable,
		ComOrderTableDescription,
		ComOrderTableDiscounts,
		ComOrderTableQuantity,
		ComOrderTags,
	},
	data() {
		return {
			client: {},
			clientsList,
			isTooltipVisible: false,
			isCountUpdating: false,
			updateQuantity: 0,
		}
	},

	computed: {
		...mapGetters('orders', ['gCartItems']),
		...mapState('orders', ['cartUid']),
		cartData() {
			return cloneDeep(this.gCartItems)
		},
		cartColumns() {
			return cartColumns
		},
	},

	watch: {
		cartData: 'rerenderQuantity',
	},

	mounted() {
		this.setEventListeners()
	},

	methods: {
		formatCurrency,
		...mapActions('orders', [
			'aDeleteGoods',
			'aDeleteDiscount',
			'aTableItemCount',
			'aCart',
			'aServices',
			'aUnmarkedGoods',
		]),
		...mapMutations('orders', [
			'mOrderErrors',
		]),
		setEventListeners() {
			// Подписываемся на событие скролла таблицы
			const tableWrapper = this.$refs.comOrderTable.$el.querySelector('.el-table__body-wrapper')
			tableWrapper.addEventListener('scroll', this.onTableScroll)
			tableWrapper.addEventListener('mousewheel', this.onTableScroll)
		},

		async addGoods(goods) {
			this.$emit('addGoods', goods)
		},

		async deleteGoods(ids) {
			this.isLoading = true
			let response = { status: 200 }
			if (Array.isArray(ids)) {
				/* eslint-disable */
				for (const id of ids) {
					const responseData = await this.aDeleteGoods({ uid: this.cartUid, id })
					response = responseData.status !== 200
						? responseData
						: response
				}
			} else {
				response = await this.aDeleteGoods({ uid: this.cartUid, id: ids })
			}

			if (response?.status === 200) {
				this.$notify({ title: this.$t('Удалено из корзины'), type: 'success' })
				await this.aCart(this.cartUid)
				this.aServices(this.cartUid)
				this.aUnmarkedGoods(this.cartUid)
			}
			this.isLoading = false
		},

		async deleteDiscount(row) {
			const cartItemIds = row.cartItems
				.map(item => item.id)
			return this.aDeleteDiscount({
				uid: this.cartUid,
				cartItemIds,
			})
		},

		async updateItemCount(row, count) {
			this.setCountUpdate(true)

			const data = {
				count,
			}

			const { id } = row.cartItems[0]

			if ((row.maxSize && (count > row.maxSize)) || this.isInvalidCLAmount(row, count)) {
				this.$nextTick(() => { this.setCountUpdate(false) })
				return
			}

			try {
				const response = await this.aTableItemCount({
					uid: this.cartUid,
					id,
					data,
				})

				// В случае ошибки сбрасываем groupSize в ComOrderTableQuantity 
				if (!response) this.rerenderQuantity()

				if (row.sellableCategory === 'UNMARKED_GOODS') {
					await this.aUnmarkedGoods(this.cartUid)
				}

				if (row.sellableCategory === 'SERVE') {
					await this.aServices(this.cartUid)
				}
			} finally {
				this.$nextTick(() => { this.setCountUpdate(false) })
			}
		},

		onTooltipVisible(val) {
			this.isTooltipVisible = val
		},

		onTableScroll(e) {
			// Если открыт тултип, то запрещаем скроллинг 
			if (this.isTooltipVisible) {
				e.stopPropagation()
				e.preventDefault()
				e.target.scroll(0, 0)
				return false
			}
			return true
		},

		setCountUpdate(val) {
			// Если уже меняется количество товара, надо дождаться пока выполнится предыдущий запрос
			this.isCountUpdating = val
		},

		rerenderQuantity() {
			this.updateQuantity++
		},

		isInvalidCLAmount(row, count) {
			if (row.sellableCategory === 'CONTACT_LENS') {
				const { packageQuantity, retailPrice } = row
				return !multiplicityCLPackageTest(retailPrice, packageQuantity, count)
			}

			return false
		},
	},
}
</script>

<style lang="scss">
.part-order-goods-table {
	&__tags {
		max-width: 442px;
	}
}
</style>
