import VuexHttp from '../../vuexHttpService'

const vHttp = new VuexHttp('compilanceRegister')

export default {
	async aGoodById(_, id) {
		return vHttp.GET(this, {
			url: `good/${id}/certificates`,
		})
	},
	async aAccessoriesCategories() {
		return vHttp.GET(this, {
			url: 'nomenclature/accessory',
			mutation: 'mAccessoriesCategories',
		})
	},
	async aAccessoriesModels(_, params) {
		return vHttp.GET(this, {
			url: {
				path: 'nomenclature/accessory/model',
				query: params,
			},
			mutation: 'mAccessoriesModels',
		})
	},
	async aContactLensNomenclature() {
		return vHttp.GET(this, {
			url: 'nomenclature/contactlenses',
			mutation: 'mContactLensNomenclature',
		})
	},
	async aContactLensNames(_, params) {
		return vHttp.GET(this, {
			url: {
				path: 'nomenclature/contactlenses/name',
				query: params,
			},
			mutation: 'mContactLensNames',
		})
	},
	async aGlassesNomenclature() {
		return vHttp.GET(this, {
			url: 'nomenclature/glasses',
			mutation: 'mGlassesNomenclature',
		})
	},
	async aGlassesBrandsByManufacturer(_, params) {
		return vHttp.GET(this, {
			url: {
				path: 'nomenclature/glasses/brand',
				query: params,
			},
			mutation: 'mGlassesBrandsByManufacturer',
		})
	},
	async aLensNomenclature() {
		return vHttp.GET(this, {
			url: 'nomenclature/lenses',
			mutation: 'mLensNomenclature',
		})
	},
	async aLensBrandsByManufacturer(_, params) {
		return vHttp.GET(this, {
			url: {
				path: 'nomenclature/lenses/brand',
				query: params,
			},
			mutation: 'mLensBrandsByManufacturer',
		})
	},
	async aSunglassesNomenclature() {
		return vHttp.GET(this, {
			url: 'nomenclature/sunglasses',
			mutation: 'mSunglassesNomenclature',
		})
	},
	aSunglassesBrandsByManufacturer(_, params) {
		return vHttp.GET(this, {
			url: {
				path: 'nomenclature/sunglasses/brand',
				query: params,
			},
			mutation: 'mSunglassesBrandsByManufacturer',
		})
	},
	aCertificatesGoodsTypes(_, params) {
		return vHttp.GET(this, {
			url: {
				path: 'good/certificates/goods-types',
				query: params,
			},
			mutation: 'mCertificatesGoodsTypes',
		})
	},
	aSaveCertificate(_, { data, files = [] }) {
		const formData = new FormData()
		const bodyData = {
			...data,
			imageDescriptions: [],
		}

		if (files.length) {
			files.forEach(file => {
				formData.append('files', file.raw, file.name)
				if (file.description && file.description.length) {
					bodyData.imageDescriptions.push({
						name: file.name,
						description: file.description,
					})
				}
			})
		}

		formData.append(
			'bodyData',
			new Blob(
				[JSON.stringify(bodyData)],
				{ type: 'application/json' }
			),
		)
		return vHttp.POST(this, {
			url: 'good/certificates',
			data: formData,
		})
	},
	aCertificateById(_, id) {
		return vHttp.GET(this, {
			url: `good/certificates/${id}`,
			mutation: 'mCertificateById',
		})
	},
	aEditCertificateById({ state }, { data, files = [], deletedFiles = [] }) {
		const formData = new FormData()
		const bodyData = {
			...data,
			imageDescriptions: [],
			deletedFiles,
		}

		delete bodyData.files

		if (files.length) {
			files.forEach(file => {
				const isExistingFile = state.certificateById.files
					.find(item => item.imageName === file.name)

				if (!isExistingFile) {
					formData.append('files', file.raw, file.name)
				}

				if (file.description && file.description.length) {
					bodyData.imageDescriptions.push({
						name: file.name,
						description: file.description,
					})
				}
			})
		}

		formData.append(
			'bodyData',
			new Blob(
				[JSON.stringify(bodyData)],
				{ type: 'application/json' }
			),
		)

		return vHttp.PUT(this, {
			url: `good/certificates/${data.id}`,
			data: formData,
		})
	},
	aDeleteCertificateById(_, id) {
		return vHttp.DELETE(this, {
			url: `good/certificates/${id}`,
		})
	},
}