<template>
	<el-popover
		popper-class="it-select-picker border-radius-round p-0"
		width="300"
		@show="isPickerVisible = true"
		@hide="isPickerVisible = false"
	>
		<template #reference>
			<div class="w-100p">
				<el-select
					v-if="!options.range"
					v-bind="$attrs"
					v-model="model"
					multiple
					filterable
					:popper-append-to-body="false"
					popper-class="ta-select-dropdown it-select-picker__dropdown"
					class="it-select-picker__select"
					:class="[{ 'is-picker-visible': isPickerVisible }, selectClass]"
				>
					<el-option
						v-for="item in filteredItems"
						:key="item.value"
						:value="item.value"
						:label="item.label"
					/>
				</el-select>

				<el-date-picker
					v-else
					v-bind="$attrs"
					v-model="modelDatePicker"
					type="daterange"
					:start-placeholder="$t('Начало')"
					:end-placeholder="$t('Окончание')"
					format="D"
					value-format="YYYY-MM-DD"
					popper-class="it-select-picker__date-picker"
					:fallback-placements="['bottom']"
					:class="selectClass"
				>
					<template #range-separator>
						<ItIcon
							name="arrow-right"
							width="16"
							height="16"
							class="fill-text-placeholder"
						/>
					</template>
				</el-date-picker>
			</div>

		</template>

		<div class="it-select-picker__body" :class="options.columnsCount > 5 ? 'small' : 'large'">
			<div class="text-center py-2 px-3">{{ options.title }}</div>

			<el-divider class="my-0"/>

			<div class="py-2 px-2">
				<div
					v-for="rowIndex in rowsCount"
					:key="`row-${rowIndex}`"
					class="d-flex justify-content-center"
				>
					<template v-for="columnIndex in options.columnsCount">
						<SelectPickerItem
							v-if="filteredItems[(rowIndex - 1) * options.columnsCount + columnIndex - 1]"
							:key="`column-${columnIndex}`"
							:item="filteredItems[(rowIndex - 1) * options.columnsCount + columnIndex - 1]"
							:value="model"
							:range="options.range"
						/>
					</template>
				</div>
			</div>
		</div>
	</el-popover>
</template>

<script>
import { monthItems, weekdayItems } from '~/helpers/bonus/computed'
import SelectPickerItem from './SelectPickerItem'

export default {
	name: 'SelectPicker',
	components: {
		SelectPickerItem,
	},
	model: {
		prop: 'value',
		event: 'change',
	},
	props: {
		modelValue: {
			type: Array,
			required: true,
		},
		title: {
			type: String,
			required: false,
			default: null,
		},
		type: {
			type: String,
			required: false,
			default: 'month',
			validator: type => ['daterange', 'month', 'date', 'weekdays'].includes(type) || type === 'custom',
		},
		items: {
			type: Array,
			required: false,
			default: null,
		},
		columnsCount: {
			type: [Number, String],
			required: false,
			default: null,
		},
		exclude: {
			type: Array,
			required: false,
			default: () => null,
		},
		selectClass: {
			type: String,
			required: false,
			default: '',
		},
	},
	data() {
		return {
			isPickerVisible: false,
			optionsByTypes: {
				daterange: {
					title: this.$t('Дни месяца'),
					items: Array.from({ length: 31 }).map((el, i) => ({ value: i + 1, label: i + 1 })),
					columnsCount: 7,
					range: true,
				},
				month: {
					title: this.$t('Месяцы'),
					items: monthItems,
					columnsCount: 3,
				},
				date: {
					title: this.$t('Дни месяца'),
					items: Array.from({ length: 31 }).map((el, i) => ({ value: i + 1, label: i + 1 })),
					columnsCount: 7,
				},
				weekdays: {
					title: this.$t('Дни недели'),
					items: weekdayItems,
					columnsCount: 3,
				},
			},
		}
	},
	computed: {
		model: {
			get() { return this.modelValue },
			set(value) { this.$emit('change', value) },
		},
		modelDatePicker: {
			get() {
				return this.model.map(d => `2012-12-${(`0${d}`).slice(-2)}`)
			},
			set(value) {
				if (!value) this.model = []
			},
		},
		options() {
			if (this.type === 'custom') return { title: this.title, items: this.items, columnsCount: this.columnsCount }

			return this.optionsByTypes[this.type]
		},
		filteredItems() {
			const { exclude } = this

			if (!exclude) return this.options.items

			return this.options.items.filter(item => !exclude.includes(item.value))
		},
		rowsCount() {
			return Math.ceil(this.filteredItems.length / this.options.columnsCount)
		},
	},
}
</script>

<style lang="scss">

.it-select-picker {
	&__body {
		.it-select-picker__item {
			cursor: pointer;

			&:hover {
				color: var(--el-color-primary);
			}

			&.active {
				background-color: var(--el-color-primary);
				color: var(--el-color-white);
			}

			&.in-range {
				background-color: var(--el-color-secondary);
				color: var(--el-color-white);
			}
		}

		&.large {
			.it-select-picker__item {
				padding: 8px 12px;
				width: 72px;
				margin: 12px 8px;
				border-radius: var(--el-border-radius-round);
			}
		}

		&.small {
			.it-select-picker__item {
				width: 24px;
				line-height: 24px;
				margin: 3px 6px;
				border-radius: var(--el-border-radius-base);
			}
		}
	}

	&__select {
		.el-input__icon {
			transform: rotateZ(180deg);
		}

		&.is-picker-visible {
			.el-input__icon {
				transform: rotateZ(0deg);
			}
		}

	}


	&__date-picker {
		display: none;
	}

	&__dropdown { display: none; }
}
</style>