import i18n from '~/plugins/i18n'

export const getDefaultRulesTypes = () => ({
	ALL: {
		label: i18n.global.t('Все товары'),
		value: 'ALL',
	},
	SELECTED: {
		label: i18n.global.t('Избранные товары'),
		value: 'SELECTED',
	},
	SERVE: {
		label: i18n.global.t('Услуги'),
		value: 'SERVE',
	},
})

export const getDefaultSubRulesTypes = () => ({
	GLASSES: {
		label: i18n.global.t('Оправы'),
		icon: 'glasses',
		value: 'GLASSES',
	},
	SUNGLASSES: {
		label: i18n.global.t('Солнцезащитные очки'),
		icon: 'sun-glasses',
		value: 'SUNGLASSES',
	},
	CONTACT_LENSES: {
		label: i18n.global.t('Контактные линзы'),
		icon: 'contact-lens',
		value: 'CONTACT_LENSES',
	},
	LENSES: {
		label: i18n.global.t('Линзы'),
		icon: 'lenses',
		value: 'LENSES',
	},
	ACCESSORIES: {
		label: i18n.global.t('Аксессуары'),
		icon: 'glasses-case',
		value: 'ACCESSORIES',
	},
	EXTRAS: {
		label: i18n.global.t('Массовка'),
		icon: 'massovka',
		value: 'EXTRAS',
	},
})