export default {
	computed: {
		mxComponentsState() {
			return this.$store.getters['user/gComponentsState'] || {}
		},
	},
	created() {
		if (Boolean(this.saveFields) + Boolean(this.saveStateKey) < 2) {
			throw new Error('Для сохранения состояния полей компонента нужно заполнить два параметра: saveFields и saveStateKey')
		}

		this.mxLoadStateFromStore()
		this.mxInitSaveState()
	},
	methods: {
		mxInitSaveState() {
			if (this.saveFields && this.saveStateKey) {
				this.saveFields.forEach(field => {
					this.$watch(field, this.mxSaveStateToStore)
				})
			}
		},
		mxSaveStateToStore() {
			if (this.saveFields && this.saveStateKey) {
				const data = {}
				this.saveFields.forEach(field => {
					if (this[field] !== undefined) data[field] = this[field]
				})

				this.$store.commit('user/mSetComponentStateSession', {
					[this.saveStateKey]: data,
				})
			}
		},
		mxLoadStateFromStore() {
			if (this.saveFields && this.saveStateKey) {
				const data = this.mxComponentsState[this.saveStateKey] || {}
				Object.entries(data).forEach(([key, value]) => {
					if (this.saveFields.includes(key)) {
						if (key === 'activeTabName') {
							const hasTab = this.tabs.find(tab => tab.name === value)
							if (!hasTab) return
						}

						this[key] = value
					}
				})
			}
		},
	},
}