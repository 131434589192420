import { TRANSFER_STATUSES } from '~/helpers/common/TransfersStatuses'
import VuexHttp from '../../vuexHttpService'

const vHttp = new VuexHttp('transfers')

export default {
	namespaced: true,
	state: {
		transfersStatuses: [],
	},
	mutations: {
		mTransfersStatuses(state, payload) {
			state.transfersStatuses = payload
			state.transfersStatuses.push('EXCEPT_SAVED')
		},
	},
	actions: {
		async aTransfersStatuses() {
			const url = 'transfers/statuses'
			return vHttp.GET(this, {
				url,
				mutation: `mTransfersStatuses`,
			})
		},
		async aTransferById(_, params) {
			return vHttp.GET(this, {
				url: {
					path: `/transfers/${params.id}`,
					query: {
						transfers: params.transfers || null,
					},
				},
			})
		},
		async aAcceptTransferById(_, id) {
			return vHttp.GET(this, {
				url: {
					path: `transfers/accepted/${id}`,
				},
			})
		},
	},
	getters: {
		gTransfersStatusesOptions(state) {
			return state.transfersStatuses
				.map(status => {
					const result = {
						label: TRANSFER_STATUSES[status].label,
						value: status,
					}
					return result
				})
		},
	},
}