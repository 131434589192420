import printHtmlDocument from '~/utils/printHtmlDocument'
import { formatDate } from '~/utils/formatters'

function getFiltersHtml(filterFields, query) {
	const getLabel = (options, value, isBoolean = false) => options
		.find(opt => (isBoolean
			? opt.value === JSON.parse(value)
			: opt.value === value
		))?.label || value

	if (filterFields === null) return ''

	let filtersHtml = ''

	Object.entries(filterFields).forEach(([key, item]) => {
		if (item.disableUsingQuery) return
		if (item.disabled) return

		const value = query[key]
		if (item.hidden) return
		filtersHtml += '<div>'
		filtersHtml += item.label || item.placeholder
		filtersHtml += ': <b>'
		if (item.type === 'daterangePicker') {
			filtersHtml += `${formatDate(query[item.fromName])}-${formatDate(query[item.toName])}`
		} else if (item.type === 'datePicker') {
			filtersHtml += formatDate(value)
		} else if (item.type === 'select' && value) {
			if (item.multiple) {
				const options = item.groups ? item.groups[0].options : item.options
				const val = Array.isArray(value)
					? value.map(v => getLabel(options, v, item?.isBoolean)).join(', ')
					: value

				filtersHtml += val
			} else {
				filtersHtml += getLabel(item.options, value, item?.isBoolean)
			}
		} else if (item.type === 'tabs' && value) {
			filtersHtml += getLabel(item.tabs, value, item?.isBoolean)
		} else if (item.type === 'checkbox') {
			filtersHtml += (/true/i).test(value) ? (item.trueLabel || 'Да') : (item.falseLabel || 'Нет')
		} else {
			filtersHtml += value || '-'
		}
		filtersHtml += '</b>'
		filtersHtml += '</div>'
	})

	return filtersHtml
}

const printStyles = `
    @page {
		margin: 0.5cm auto !important;
	}

    th, td {
        border: 1px solid grey !important;
        padding: 0 3px;
        break-inside: avoid;
        break-after: auto
    }

    .table {
        display: table;
    }

    .cell {
        text-align: left;
    }
    .el-table__row .cell {
        word-break: break-word;
    }

	.el-scrollbar__view {
		display: block !important;
	}

    .is-right .cell {
        text-align: right;
    }`

export default function ({
	title,
	journalTable,
	mainFiltersFields,
	additionalFiltersFields,
	query,
	hasSignature,
	fioShort,
}) {
	let documentHtml = ''
	documentHtml += `<html><head><title>${title}</title>`
	documentHtml += '</head><body>'
	documentHtml += `<style>${printStyles}</style>`
	documentHtml += '<br>'
	documentHtml += `<h3>${title}</h3>`
	documentHtml += '<br>'
	documentHtml += getFiltersHtml(mainFiltersFields, query)
	documentHtml += getFiltersHtml(additionalFiltersFields, query)
	documentHtml += '<br><br>'
	documentHtml += '<section class="table">'
	documentHtml += journalTable

	if (hasSignature) {
		documentHtml += '<br><br>'
		documentHtml += 'Подпись: ___________________'
		if (fioShort) {
			documentHtml += `/ ${fioShort} /`
		} else {
			documentHtml += `/_________________________________________________/`
		}
	}

	documentHtml += '</section>'
	documentHtml += '</body></html>'

	printHtmlDocument(documentHtml)
}