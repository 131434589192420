<template>
	<div
		:data-ta="`checkboxe_${name}`"
		class="com-field-item com-field-checkbox"
	>
		<el-form-item
			:ref="name"
			:class="{
				'no-label': !label,
			}"
			:label="label"
			:prop="name"
		>
			<el-checkbox
				v-model="model"
				:disabled="disabled || readonly || !isEdit"
				:label="placeholder"
				:data-ta="name"
				:size="size"
				:border="border"
			/>
		</el-form-item>
	</div>
</template>

<script>
import defaultProps from './defaultFieldsProps'

export default {
	name: 'ComFieldCheckbox',
	components: {
	},
	props: {
		...defaultProps,
		border: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		model: {
			get() { return typeof this.modelValue === 'undefined' ? null : this.modelValue },
			set(value) {
				this.$emit('update:modelValue', value)
				return value
			},
		},
	},
}
</script>

<style lang="scss">
.com-field-checkbox {
	.no-label {
		.el-form-item__content {
			margin-left: 0 !important
		}
	}
}
</style>