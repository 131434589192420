<template>
	<el-dialog
		v-model="isVisible"
		class="layout-part-filters-popup"
		width="744px"
		top="5px"
		@closed="onClosed"
	>
		<template #header>
			<h3
				class="layout-part-filters-popup__title"
			>{{ title }}</h3>
		</template>

		<slot/>

		<template #footer>
			<div class="layout-part-filters-popup__actions">
				<el-button
					type="primary"
					data-ta="reset-filters"
					plain
					size="small"
					class="layout-parts-filters__reset-btn"
					@click="onClose"
				>
					<span>{{ $t('Отмена') }}</span>
				</el-button>

				<el-button
					type="primary"
					data-ta="save-filters"
					size="small"
					class="layout-parts-filters__reset-btn"
					@click="filter"
				>
					<span>{{ $t('Фильтровать') }}</span>
				</el-button>
			</div>
		</template>
	</el-dialog>
</template>

<script>
export default {
	name: 'LayoutPartFiltersPopup',

	props: {
		popupProps: {
			type: Object,
			default: null,
		},
		modelValue: {
			type: Object,
			default: null,
		},
	},

	data() {
		return {
			isVisible: false,
			model: null,
		}
	},

	computed: {
		title() {
			return this.popupProps?.title || this.$t('Фильтры')
		},

		isFilterBtnDisabled() {
			const modelValues = Object.values(this.modelValue)
				.filter(value => (Array.isArray(value) ? !!value.length : !!value))
			return !modelValues.length
		},
	},

	methods: {
		onShow() {
			this.isVisible = true
		},

		onClose() {
			this.isVisible = false
		},

		onClosed() {
			this.$emit('toggle-additional')
		},

		filter() {
			this.$emit('filter')
		},
	},
}
</script>

<style lang="scss">
.layout-part-filters-popup {
	border-radius: 12px !important;
	box-shadow: 0 16px 25px #2e384d7a !important;

	&__title {
		font-size: 18px;
		margin-bottom: 20px;
	}

	&__actions {
		margin-top: 20px;
		display: flex;
		justify-content: flex-end;
		gap: 16px;
	}

	.el-dialog {
		&__body {
			max-height: 450px;
			overflow-x: hidden;
			overflow-y: auto;
		}
	}
}
</style>