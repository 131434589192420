import discountsState from './discountsState'
import discountsActions from './discountsActions'
import discountsMutations from './discountsMutations'
import discountsGetters from './discountsGetters'

export default {
	namespaced: true,
	state: discountsState,
	getters: discountsGetters,
	mutations: discountsMutations,
	actions: discountsActions,
}