<template>
	<div
		:data-ta="`select_${name}`"
		class="com-field-item com-field-select"
	>
		<PartFieldView
			v-if="!isEdit"
			v-model="viewValues"
			:name="name"
			:options="options"
			:label="label"
			:label-width="labelWidth"
			:label-color-dot="labelColorDot"
			:label-position="labelPosition"
			:link-pattern="linkPattern"
			:show-label="showLabel"
			:is-html="isHtml"
			:on-click="onClick"
			:info-tooltip="infoTooltip"
		/>

		<el-form-item
			v-else
			:ref="name"
			:prop="name"
			:label="label"
			:label-width="labelWidth"
		>

			<PartInfoTooltip
				v-if="infoLabelTooltip"
				:info-tooltip="infoLabelTooltip"
				label-tooltip
			/>

			<div class="d-flex align-items-center w-100p">
				<el-select
					v-model="model"
					:disabled="disabled || readonly"
					:filterable="filterable"
					:data-ta="dataTa || name"
					:multiple="multiple"
					:placeholder="placeholder"
					:loading="loading"
					:clearable="clearable"
					:size="size"
					:collapse-tags="collapseTags"
					:popper-class="'ta-select-dropdown ta-select-dropdown-' + name"
					:value-key="valueKey"
					class="w-100p"
					@clear="onClear"
				>
					<template
						v-if="multiple && multitoggles"
						#header
					>
						<PartSelectMultitoggles
							@selectAll="selectAll"
							@deselectAll="deselectAll"
						/>
					</template>

					<el-option
						v-for="{ value: value2, label: label2 } in options"
						:key="value2"
						:value="value2"
						:label="label2"
						@click="onOptionClick(value2)"
					/>
				</el-select>

				<el-tooltip
					v-if="deleteTooltip"
					:content="deleteTooltip"
					:disabled="!deleteTooltip"
					placement="right"
					effect="light"
				>
					<ItIcon
						v-if="deleteFn"
						name="trash"
						class="ml-6 clickable"
						@click="deleteFn"
					/>
				</el-tooltip>

				<PartInfoTooltip
					v-if="infoTooltip"
					:info-tooltip="infoTooltip"
				/>

			</div>
		</el-form-item>
	</div>
</template>

<script>
import i18n from '~/plugins/i18n'
import defaultProps from './defaultFieldsProps'
import PartFieldView from './parts/PartFieldView'
import PartInfoTooltip from './parts/PartInfoTooltip'
import PartSelectMultitoggles from './parts/PartSelectMultitoggles'

export default {
	name: 'ComFieldSelect',
	components: {
		PartFieldView,
		PartInfoTooltip,
		PartSelectMultitoggles,
	},
	props: {
		...defaultProps,
		modelValue: {
			type: [String, Number, Boolean, Array, Object],
			default: '',
		},
		options: {
			type: Array,
			default: () => ([]),
		},
		valueKey: {
			type: String,
			default: null,
		},
		multiple: {
			type: Boolean,
			default: false,
		},
		filterable: {
			type: Boolean,
			default: true,
		},
		placeholder: {
			type: String,
			default: i18n.global.t('Выберите'),
		},
		deleteFn: {
			type: Function,
			default: null,
		},
		deleteTooltip: {
			type: String,
			default: null,
		},
		collapseTags: {
			type: Boolean,
			default: false,
		},
		emptyValueLabel: {
			type: String,
			default: '—',
		},
		multitoggles: {
			type: Boolean,
			default: false,
		},
		dataTa: {
			type: String,
			default: null,
		},
	},
	computed: {
		model: {
			get() { return this.modelValue ?? '' },
			set(value) {
				this.$emit('change', value)
				this.$emit('update:modelValue', value)
			},
		},
		viewValues() {
			let values = Array.isArray(this.modelValue) ? this.modelValue : [this.modelValue]

			if (!values.length) {
				return [{
					label: this.emptyValueLabel,
					value: null,
				}]
			}

			values = values.map(val => {
				const emptyVal = val ?? ''
				const option = this.options.find(item => item.value === emptyVal)
				if (option) {
					return {
						label: option.viewLabel || option.label || this.emptyValueLabel,
						link: this.linkPattern.replace('%value%', option.value),
						value: val,
					}
				}
				return {
					label: val || this.emptyValueLabel,
					value: val || emptyVal,
				}
			})

			return values
		},
	},
	mounted() {
		this.fixParkinsonUI()
	},
	methods: {
		onOptionClick(val) {
			this.$emit('on-option-click', val)
		},
		// при масштабировании в браузере zoom < 100% в компоненте el-select
		// стартует бесконечный цикл пересчёта высоты и элемент начинает "дрожать"
		// установка inline css height останавливает бесконечное изменение высоты
		fixParkinsonUI() {
			let throttle = 0
			const config = {
				attributes: false,
				childList: true,
				subtree: true,
			}
			const observer = new MutationObserver(() => {
				if (throttle && throttle < Date.now()) {
					return this.setInlineHeight()
				}
				throttle = Date.now() + 100
				return true
			})

			observer.observe(this.$el, config)
		},
		setInlineHeight() {
			const elTagsContainer = this.$el.querySelector('.el-select__tags')

			if (!elTagsContainer) return

			elTagsContainer.style.height = 'auto'
			setTimeout(() => {
				elTagsContainer.style.height = `${elTagsContainer.offsetHeight}px`
			})
		},
		selectAll() {
			const value = this.options.map(option => option.value)

			this.$emit('change', value)
			this.$emit('update:modelValue', value)
		},
		deselectAll() {
			const value = []

			this.$emit('change', value)
			this.$emit('update:modelValue', value)
		},
	},
}
</script>

<style lang="scss">
.com-field-select {
	flex: 1;
}
</style>