<template>
	<el-form
		v-if="model"
		class="filters-container"
		@submit.prevent
	>
		<template v-for="(field, fieldName) in fields">
			<el-form-item
				v-if="!field.hidden"
				:key="fieldName"
				:label="field.name"
				:class="{
					'com-filters__input': field.type === 'input',
					'com-filters__select': field.type === 'select',
					'com-filters__date-picker': field.type === 'datePicker',
					['com-filters__item-' + fieldName]: true,
				}"
				:style="field.style"
				prop="familyName"
				@submit.prevent
			>
				<!-- vue-the-mask (v-mask) не умеет обрабатывать undefined -->
				<template v-if="field.mask">
					<el-input
						v-if="field.type === 'input'"
						v-model="model[fieldName]"
						v-mask="field.mask"
						size="default"
						:placeholder="field.label"
						:minlength="field.minlength"
						:maxlength="field.maxlength"
						:disabled="field.disabled"
						:data-ta="fieldName"
						:clearable="field.clearable"
						@update:modelValue="callFn(field.onInput, field)"
					/>
				</template>
				<el-input
					v-if="field.type === 'searchInput'"
					v-model="model[fieldName]"
					size="default"
					:placeholder="field.label"
					:minlength="field.minlength"
					:maxlength="field.maxlength"
					:disabled="field.disabled"
					:data-ta="fieldName"
					:clearable="field.clearable"
					@update:modelValue="callFn(field.onInput, field)"
					@keydown.enter="submitFilters"
					@clear="submitFilters"
				>
					<template #suffix>
						<ItIcon
							name="search-small"
							class="fill-text-placeholder"
							style="cursor: pointer"
							@click="submitFilters"
						/>
					</template>
				</el-input>
				<el-input
					v-if="field.type === 'searchInputOuter'"
					ref="searchInputOuter"
					v-model="model[fieldName]"
					size="default"
					clearable
					:placeholder="field.label"
					:data-ta="fieldName"
					@update:modelValue="callFn(field.onInput, field)"
					@keydown.enter="submitFilters"
					@clear="submitFilters"
				>
					<template #append>
						<el-button
							icon="search"
							style="cursor: pointer"
							@click="submitFilters"
						/>
					</template>
				</el-input>
				<el-input
					v-else-if="field.type === 'input'"
					v-model="model[fieldName]"
					size="default"
					:placeholder="field.label"
					:minlength="field.minlength"
					:maxlength="field.maxlength"
					:disabled="field.disabled"
					:data-ta="fieldName"
					:clearable="field.clearable"
					@update:modelValue="callFn(field.onInput, field)"
				/>
				<el-select
					v-if="field.type === 'select'"
					:key="field.loading"
					v-model="model[fieldName]"
					:placeholder="field.label"
					:clearable="field.clearable"
					:filterable="field.filterable"
					:multiple="field.multiple"
					:disabled="field.disabled"
					:data-ta="fieldName"
					:loading="field.loading"
					:popper-class="'ta-select-dropdown ta-select-dropdown-' + fieldName"
					size="default"
					collapse-tags
					@update:modelValue="callFn(field.onInput, field)"
				>
					<el-option-group
						:label="field.optionsTitle"
					>
						<el-option
							v-for="item in field.options"
							:key="item.value"
							:label="item.label"
							:value="item.value"
							:data-ta="fieldName + '-' + item.value"
						/>
					</el-option-group>
				</el-select>
				<el-date-picker
					v-if="field.type === 'daterangePicker'"
					v-model="field.value"
					:disabled="field.disabled"
					:data-ta="fieldName"
					:disabled-date="date => fieldDatepickerCheckDisabledDate(date, field)"
					:picker-options="{
						firstDayOfWeek: 1,
						onPick: value => fieldDatepickerOnPick(value, field),
					}"
					:clearable="field.clearable"
					:fallback-placements="['bottom']"
					format="DD.MM.YYYY"
					value-format="YYYY-MM-DD"
					class="com-filters__daterange-picker"
					size="default"
					type="daterange"
					align="right"
					start-placeholder="С"
					end-placeholder="По"
					@update:modelValue="onDatarangeChange(field)"
				/>
				<el-tooltip
					v-if="field.tooltip"
					v-bind="field.tooltip"
					class="com-filters_tooltip"
				>
					<ItIcon
						name="info"
						width="14"
						height="14"
						class="fill-grey"
					/>
				</el-tooltip>
			</el-form-item>
		</template>
		<div
			v-if="useButtons"
			class="com-filters__buttons-wrap d-flex"
		>
			<el-button
				size="default"
				class="mr-3"
				:disabled="typeof model === 'object' && Object.entries(model).length === 0"
				@click="resetFilters"
			>{{ $t('Сбросить') }}</el-button>
			<el-button
				size="default"
				class="flex-1"
				:disabled="typeof model === 'object' && Object.entries(model).length === 0"
				:type="isChanged ? 'primary' : ''"
				@click="submitFilters"
			>
				<span>{{ $t('Отфильтровать') }}</span>
			</el-button>
		</div>
	</el-form>
</template>

<script>
import { mask } from 'vue-the-mask'
import isEqual from 'lodash/isEqual'
import { formatDate } from '~/utils/formatters'

export default {
	name: 'ComFilters',
	directives: {
		mask,
	},
	props: {
		value: {
			type: Object,
			default: () => ({}),
		},
		fields: {
			type: Object,
			default: () => ({}),
		},
		useButtons: {
			type: Boolean,
			default: true,
		},
		saveState: {
			type: Boolean,
			default: false,
		},
		saveStateKey: {
			type: String,
			default: 'filters.fields',
		},
		saveStateIgnoreFields: {
			type: Array,
			default: () => ([]),
		},
	},
	data() {
		return {
			model: null,
			initialized: false,
		}
	},
	computed: {
		isChanged() {
			const changed = Object.entries(this.model).find(([key, value]) => {
				const queryValue = this.$route.query[key] || ''
				const item = this.fields[key]
				if (item && item.multiple) {
					// multiple select can changes own type
					if (!queryValue && (!value || value.length === 0)) {
						return false
					}

					return value.length !== queryValue.length || !isEqual(value, queryValue)
				}
				return value && value !== queryValue
			})
			return changed
		},
		componentsState() {
			return this.$store.getters['user/gComponentsState'] || {}
		},
		lastField() {
			return Object.keys(this.fields).pop()
		},
		datarangesFields() {
			return Object.values(this.fields).filter(field => field.type === 'daterangePicker')
		},
	},
	watch: {
		value: {
			deep: true,
			handler(val) {
				this.model = {
					...val,
				}
				if (this.initialized) {
					this.setQueryFromFilters()
				}
			},
		},
	},
	created() {
		this.model = { ...this.value }
		this.setFilterFromFields()
		if (this.saveState) {
			this.setFilterFromSavedState()
		}
		this.setFilterFromQuery()
		this.setQueryFromFilters()
		this.emitFilters()
		setTimeout(() => {
			this.initialized = true
		})
	},
	methods: {
		initDatapicker(item, values = []) {
			const from = values[0] || item.defaultValue[0]
			const to = values[1] || item.defaultValue[1]
			if (from) { this.model[item.fromName] = from }
			if (to) { this.model[item.toName] = to }
			item.value = [from, to]
		},
		getFieldByKey(key) {
			if (this.fields[key]) {
				return this.fields[key]
			}
			return Object.values(this.fields).find(item => {
				if (item.type === 'daterangePicker') {
					return item.fromName === key || item.toName === key
				}
				return false
			})
		},
		setFilterFromFields() {
			Object.entries(this.fields).forEach(([key, item]) => {
				if (item.type === 'daterangePicker') {
					this.initDatapicker(item, [
						this.$route.query[item.fromName],
						this.$route.query[item.toName],
					])
				} else if (![null, undefined].includes(item.defaultValue)) {
					this.model[key] = item.defaultValue
				}
			})
		},
		setFilterFromSavedState() {
			const savedFields = this.componentsState[this.saveStateKey] || {}

			Object.entries(savedFields).forEach(([key, value]) => {
				const item = this.getFieldByKey(key)
				if (!value || !item) {
					return
				}
				if (item.type === 'daterangePicker') {
					this.initDatapicker(item, [
						savedFields[item.fromName],
						savedFields[item.toName],
					])
				}
				this.model[key] = value
			})
		},
		setFilterFromQuery() {
			const query = { ...this.$route.query }
			Object.entries(query).forEach(([key, value]) => {
				const item = this.getFieldByKey(key)
				if (item && item.multiple && typeof value === 'string') {
					// multiple select with 1 selected option is string
					this.model[key] = [value]
					this.$router.push({
						...query,
						[key]: [value],
					})
				// type switch should cast string to boolean values
				} else if (item?.type === 'switch') {
					this.model[key] = value === 'true'
				} else if (this.model[key] !== undefined) {
					this.model[key] = value
				}
			})
		},
		setQueryFromFilters() {
			const query = { ...this.$route.query }
			Object.entries(this.model).forEach(([key, value]) => {
				if (Array.isArray(value)) { // multiple select
					query[key] = value.length ? value.map(i => i && String(i)) : null
				} else if (value === null || value === '') {
					delete query[key]
				} else {
					query[key] = String(value) // this.$route.query all values is String type
				}
			})

			if (!isEqual(query, this.$route.query)) {
				this.$router.push({ query })
				this.emitFilters()
				if (this.saveState) {
					this.saveFieldsState()
				}
			}
		},
		fixWrongDataranges() {
			this.datarangesFields.forEach(item => {
				if (item.value[0] > item.value[1]) {
					this.fieldDatepickerOnPick(item)
				}
			})
		},
		submitFilters() {
			this.fixWrongDataranges()
			this.setQueryFromFilters()
		},
		resetFilters() {
			Object.keys(this.model).forEach(key => {
				const item = this.fields[key]
				if (item) {
					this.model[key] = item?.defaultValue || null
				}
			})
			Object.values(this.fields).forEach(item => {
				if (item.type === 'daterangePicker') {
					this.$route.query[item.fromName] = null
					this.$route.query[item.toName] = null
					this.initDatapicker(item, [null, null])
				}
			})
			this.setQueryFromFilters()
		},
		emitFilters() {
			this.$emit('update:modelValue', this.model)
		},
		fieldDatepickerCheckDisabledDate(date, item) {
			if (item.maxDate && date.getTime() > item.maxDate) return true
			return item.minDate && date < item.minDate
		},
		fieldDatepickerOnPick(item) {
			const from = item.value[0] ? formatDate(item.value[0], 'YYYY-MM-DD') : null
			const to = item.value[1] ? formatDate(item.value[1], 'YYYY-MM-DD') : null

			this.model[item.fromName] = from || item.minDate || null
			this.model[item.toName] = to || item.maxDate || null
		},
		fieldDatepickerOnChange(item) {
			this.fieldDatepickerOnPick({
				minDate: new Date(item.value[0]),
				maxDate: new Date(item.value[1]),
			}, item)
		},
		onDatarangeChange(item) {
			this.fieldDatepickerOnPick(item)
			this.callFn(item.onInput, item)
		},
		callFn(fn, item) {
			fn?.call(this, item) // eslint-disable-line
		},
		saveFieldsState() {
			let data = { ...this.model }

			if (this.saveStateIgnoreFields.length) {
				data = Object.entries(data).reduce((res, [key, value]) => {
					if (!this.saveStateIgnoreFields.includes(key)) {
						res[key] = value
					}
					return res
				}, {})
			}
			this.$store.commit('user/mSetComponentStateSession', {
				[this.saveStateKey]: data,
			})
		},
		focus(ref) {
			this.$refs[ref][0].focus()
		},
	},
}
</script>

<style lang="scss">
.com-filters {
	overflow: hidden;

	.el-input,
	.el-date-editor,
	.el-date-editor.el-input,
	.el-date-editor.el-input__wrapper {
		--el-input-height: var(--el-filters-controls-height);
	}

	.filters-container {
		grid-gap: 6px 24px;
		display: grid;
		grid-auto-rows: auto;
		grid-template-columns: 1fr 1fr 1fr;
	}

	.com-filters__input,
	.com-filters__select,
	.com-filters__date-picker {
		box-sizing: border-box;
		width: 100%;

		.el-select {
			width: 100%;
		}
	}

	.el-input__suffix-inner {
		display: flex !important;
	}

	.com-filters__buttons-wrap {
		grid-column-end: 4;
	}

	.com-filters_date-picker {
		width: 320px;
	}

	.com-filters_tooltip {
		position: absolute;
		top: 50%;
		margin-top: -7px;
		right: 15px;
	}

	.el-form-item {
		margin-bottom: 0px;
	}
}

.bounce-enter-active {
	animation: bounce-in 0.4s;
}

.bounce-leave-active {
	animation: bounce-in 0.4s reverse;
}

@keyframes bounce-in {
	0% {
		max-height: 0;
	}

	100% {
		max-height: 100px;
	}
}
</style>