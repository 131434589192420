export default {
	methods: {
		// TODO: сделать компонент для пагинации и перенести проверку в него
		dropIfPageEmpty(content) {
			// При смене фильтров и выборки может оказаться, что на данной странице нулевая выборки. Делаем сбросить на первую страницу
			if (!content.length && this.pagination.page !== 1) {
				this.setPage(1)
				return true
			}

			return false
		},
	},
}