import VuexHttp from '../../vuexHttpService'

const vHttp = new VuexHttp('barcodesSettings')

export default {
	async aBarcodesSettings() {
		return vHttp.GET(this, {
			url: 'settings/barcodes',
			mutation: 'mBarcodesSettings',
		})
	},
	async aBarcodesSettingsUpdate(_, data) {
		return vHttp.PUT(this, {
			url: 'settings/barcodes',
			data,
		})
	},
	async aBarcodesPreview(_, data) {
		return vHttp.POST(this, {
			url: 'settings/barcodes/preview',
			data,
		})
	},
	async aTestPrint(_, data) {
		return vHttp.POST(this, {
			url: 'settings/barcodes/test-print',
			data,
		})
	},
	async aUPCSettings(_, params) {
		return vHttp.GET(this, {
			url: {
				path: 'settings/upc',
				query: params,
			},
			mutation: 'mUPCSettings',
		})
	},
}