<template>
	<el-card id="page-bonus">
		<template #header>
			<div
				class="d-flex align-items-center justify-content-between"
			>
				<div class="d-flex align-items-center">
					<h3 class="my-0">{{ $t('Бонусная система') }}</h3>

					<OptimaHelpTooltip
						text="Как работать с бонусной системой"
						href="https://optimahelp.itigris.ru/m/57456/l/1358443-"
					/>
				</div>

				<div
					class="d-flex"
				>
					<!-- если будет поиск, добавить метод updateList с debounce -->
					<!-- <el-input
						v-model="searchQuery"
						placeholder="Поиск"
						clearable
						@update:modelValue="filter"
					>
						<template #prefix><ItIcon

							name="search-small"
							class="fill-text-placeholder"
						/></template>
					</el-input> -->

					<router-link
						:to="{ name: 'BonusSettings' }"
						class="ml-4"
					>
						<el-button
							plain
						>
							<ItIcon name="menu-sys-settings"/>
							<span>{{ $t('Настройки') }}</span>
						</el-button>
					</router-link>
				</div>
			</div>

			<el-divider class="mt-5 mb-1"/>
		</template>

		<div class="d-flex justify-content-between">
			<div class="d-flex gap-4">
				<el-radio-group
					v-model="filters.mode.value"
					@change="routeReplace('mode')"
				>
					<el-radio-button
						v-for="(mode, value) in modes"
						:key="value"
						:value="value"
						:data-ta="'modes_' + mode"
					>{{ mode.label }}</el-radio-button>
				</el-radio-group>

				<el-select
					v-model="filters.hideActive.value"
					class="border-none height-32"
					style="width: 140px"
					popper-class="ta-select-dropdown"
					data-ta="hideActive"
					@change="routeReplace('hideActive')"
				>
					<el-option :label="$t('Активные')" :value="false"/>
					<el-option :label="$t('Завершенные')" :value="true"/>
				</el-select>
			</div>

			<div class="d-flex">
				<el-radio-group
					v-if="isByFiltersView"
					v-model="filters.accrualType.value"
					class="ml-6"
					data-ta="accrualTypes"
					@change="routeReplace('accrualType')"
				>
					<el-radio-button
						v-for="(accrualType, value) in accrualTypes"
						:key="value"
						:value="value"
						:data-ta="'accrualTypes_' + accrualType"
					>{{ accrualType.label }}</el-radio-button>
				</el-radio-group>

				<template v-if="!isByFiltersView">
					<el-select
						v-model="filters.ruleType.value"
						:placeholder="$t('Тип товара')"
						clearable
						filterable
						class="small-select height-32 ml-6"
						popper-class="ta-select-dropdown"
						data-ta="ruleType"
						@change="routeReplace('ruleType')"
					>
						<el-option
							v-for="category in categories"
							:key="category.code"
							:value="category.code"
							:label="category.label"
						/>
					</el-select>

					<el-select
						v-model="filters.departmentId.value"
						:placeholder="$t('Магазин')"
						clearable
						filterable
						class="small-select height-32 ml-4"
						popper-class="ta-select-dropdown"
						data-ta="departmentId"
						@change="routeReplace('departmentId')"
					>
						<el-option
							v-for="department in departments"
							:key="department.id"
							:value="department.id"
							:label="department.name"
						/>
					</el-select>
				</template>

				<router-link
					v-if="canCreateBonus"
					:to="createRouterTo"
					class="ml-6"
				>
					<el-button
						type="primary"
						data-ta="btn_create"
						size="small"
					>
						<ItIcon name="plus"/>
						<span>{{ $t('Создать') }}</span>
					</el-button>
				</router-link>
			</div>
		</div>

		<div v-loading="isLoading" class="mt-8">
			<template v-if="1">
				<el-table
					ref="table"
					:data="bonuses"
					:row-class-name="({ row }) => row.id === accentBonusId ? 'bg-color-border-extra-light clickable' : 'clickable'"
					size="small"
					data-ta="bonusesTable"
					@row-click="openReadPage"
					@sort-change="sortChange"
				>
					<el-table-column
						v-if="!isByFiltersView && filters.hideActive.value"
						align="center"
						width="48"
					>
						<template #default="{ row }">
							<ItIcon
								:name="row.cancelDate ? 'danger-outline' : 'warning-outline'"
								:class="row.cancelDate ? 'fill-danger' : 'fill-warning'"
							/>
						</template>
					</el-table-column>

					<el-table-column
						v-for="(column, i) in tableColumns"
						:key="i"
						:label="column.label"
						:prop="column.prop"
						:width="column.width"
						:sortable="column.sortable"
						:formatter="column.formatter"
						:class-name="column.class"
					/>

					<el-table-column
						v-if="isByFiltersView && isOneTime"
						align="center"
						width="48"
					>
						<template #default="{ row }">
							<div
								v-if="!row.done"
								class="clickable line-height-0"
								:class="{ 'icon-loading': row.isLoading }"
								@click.stop="refreshClientsCount(row)"
							>
								<ItIcon
									name="refresh"
									class="fill-text-placeholder"
								/>
							</div>
						</template>
					</el-table-column>

					<el-table-column
						v-if="availableActions.length > 0 && canEditBonus"
						align="center"
						width="48"
					>
						<template #default="{ row }">
							<div v-if="(isByFiltersView && !isOneTime) || checkBonusActive(row)" class="line-height-0">
								<el-popover
									placement="bottom-end"
									trigger="hover"
									:visible-arrow="false"
									popper-class="border-none border-radius-12 shadow-popover px-0 py-0"
								>
									<template #reference>
										<ItIcon
											name="menu-dots-horizontal"
											class="fill-text-placeholder"
											@click.stop
										/>
									</template>

									<div
										v-for="action in availableActions"
										:key="action.label"
									>
										<el-button
											:disabled="!!action.checkDisabled && action.checkDisabled(row)"
											class="justify-content-start border-none px-3 w-100p"

											:data-ta="'btn_' + action.icon"
											@click="action.method(row)"
										>
											<ItIcon :name="action.icon"/>
											<span>{{ action.label }}</span>
										</el-button>
									</div>
								</el-popover>
							</div>
						</template>
					</el-table-column>
				</el-table>
			</template>
			<LayoutPartPagination
				v-if="pagination"
				v-model="pagination"
				@update:modelValue="onPaginationChange"
			/>
		</div>
	</el-card>
</template>

<script>
import { mapGetters } from 'vuex'
import LayoutPartPagination from '~/layout/parts/LayoutPartPagination'
import OptimaHelpTooltip from '~/components/old/OptimaHelpTooltip'
import dropIfPageEmpty from '~/mixins/common/dropIfPageEmpty'
import { formatDateCell, formatDateTime } from '~/utils/formatters'
import { parseCronFormatWords } from '~/helpers/bonus/methods'
import Filters from '~/kit/mixins/Filters'

const periodFormatter = row => {
	const { familyName, firstName, patronymicName } = row.creator

	return `${formatDateTime(row.startDate)} - ${formatDateTime(row.finishDate)}\n${[familyName, firstName, patronymicName].join(' ')}`
}

const SORT_ORDERS = { ascending: 'ASC', descending: 'DESC' }
const SORT_PROPS = { period: 'FINISH_DATE', name: 'NAME' }
const SORT_ORDERS_REVERSE = { ASC: 'ascending', DESC: 'descending' }
const SORT_PROPS_REVERSE = { FINISH_DATE: 'period', NAME: 'name' }
const SORT_PROPS_V2 = { FINISH_DATE: 'finishDate', NAME: 'name' }

export default {
	name: 'Bonus',
	components: {
		LayoutPartPagination,
		OptimaHelpTooltip,
	},
	mixins: [
		dropIfPageEmpty,
		Filters,
	],
	data() {
		return {
			searchQuery: '',
			bonuses: [],
			pagination: null,
			filters: {
				mode: {
					value: 'accumulation',
					defaultValue: 'accumulation',
					validator: mode => !!this.modes[mode],
				},
				/* в routeReplace свойство false убирается из query,
					т.е. его нужно поставить значением по умолчанию */
				hideActive: {
					value: false,
					defaultValue: false,
					valueType: 'boolean',
					backendFilterKey: 'showActive',
					formatter: v => !v,
				},
				ruleType: {
					value: null,
					defaultValue: null,
					formatter: v => (this.isByFiltersView ? undefined : v),
				},
				departmentId: {
					value: null,
					defaultValue: null,
					valueType: 'number',
					formatter: v => (this.isByFiltersView ? undefined : v),
				},
				accrualType: {
					value: 'one-time',
					defaultValue: 'one-time',
					validator: type => !!this.accrualTypes[type],
					formatter: v => (this.isByFiltersView ? v : undefined),
				},
				orderField: {
					value: null,
					defaultValue: null,
					validator: field => !!SORT_PROPS_REVERSE[field],
					formatter: v => (this.isByFiltersView ? undefined : v),
				},
				orderType: {
					value: null,
					defaultValue: null,
					validator: type => !!SORT_ORDERS_REVERSE[type],
					formatter: v => (this.isByFiltersView ? undefined : v),
				},
			},
			accrualTypes: {
				'one-time': {
					label: this.$t('Однократное'),
					apiLink: 'bonus/points',
					deactivate: {
						apiLink: 'bonus/points',
						apiMethod: 'delete',
					},
					createRouterName: 'BonusPointsByFilters',
					editRouterName: 'BonusPointsByFiltersEdit',
				},
				regular: {
					label: this.$t('Регулярное'),
					apiLink: 'bonus/auto/points',
					deactivate: {
						apiLink: 'bonus/auto/points/deactivate',
						apiMethod: 'put',
					},
					activate: {
						apiLink: 'bonus/auto/points/activate',
						apiMethod: 'put',
					},
					createRouterName: 'BonusPointsByFilters',
					editRouterName: 'BonusPointsByFiltersEdit',
				},
			},
			categories: [],
			departments: [],
			isLoading: true,
			isAllFiltersSet: false,
			filtersMixinOptions: {
				excludeFormatFilters: ['accrualType'],
				filtersDataName: 'filters',
				usePage: true,
			},
			accentBonusId: null,
		}
	},
	computed: {
		...mapGetters('user', [
			'canCreateBonus',
			'canEditBonus',
			'canReadBonusByFilters',
		]),
		modes() {
			const modes = {
				accumulation: {
					label: this.$t('Начисления'),
					optima2: true,
					apiLink: 'bonus/rules',
					deactivate: {
						apiLink: 'bonus/rules',
						apiMethod: 'delete',
					},
					createRouterName: 'BonusRule',
					editRouterName: 'BonusRuleEdit',
				},
				spending: {
					label: this.$t('Бонусы:Списания'),
					optima2: true,
					apiLink: 'bonus/rules',
					deactivate: {
						apiLink: 'bonus/rules',
						apiMethod: 'delete',
					},
					createRouterName: 'BonusRule',
					editRouterName: 'BonusRuleEdit',
				},
			}

			if (this.canReadBonusByFilters) {
				modes['by-filters'] = {
					label: this.$t('По фильтрам'),
					createRouterName: '',
				}
			}

			return modes
		},
		currentModeData() {
			return this.isByFiltersView ? this.accrualTypes[this.filters.accrualType.value] : this.modes[this.filters.mode.value]
		},
		createRouterTo() {
			const route = { name: this.currentModeData.createRouterName }

			if (!this.isByFiltersView) {
				route.query = {
					mode: this.filters.mode.value,
					ruleType: this.filters.ruleType.value,
				}
			} else {
				route.query = {
					accrualType: this.filters.accrualType.value,
				}
			}

			return route
		},
		isByFiltersView() {
			return this.filters.mode.value === 'by-filters'
		},
		isOneTime() {
			return this.filters.accrualType.value === 'one-time'
		},
		tableColumns() {
			if (!this.isByFiltersView) {
				return [
					{ label: this.$t('Период действия'), prop: 'period', width: '250', sortable: 'custom', formatter: periodFormatter, class: 'table-period' },
					{ label: this.$t('Название правила'), prop: 'name', width: '200', sortable: 'custom' },
					{ label: this.$t('Размер'), prop: this.filters.mode.value === 'accumulation' ? 'bonusPoints' : 'discount', width: '100', formatter: (row, column, cellValue) => (cellValue ? `${cellValue}%` : '-') },
					{ label: this.$t('Департаменты'), prop: 'departments', formatter: (row, column, cellValue) => (cellValue && cellValue.length > 0 ? cellValue.map(dep => dep.name).join(', ') : this.$t('Все департаменты')) },
				]
			}

			if (this.isOneTime) {
				return [
					{ label: this.$t('Дата'), prop: 'date', width: '120', formatter: formatDateCell },
					{ label: this.$t('Фильтр'), prop: 'filter.name' },
					{ label: this.$t('Кол-во баллов'), prop: 'bonusPoints', width: '102' },
					{ label: this.$t('Срок действия'), prop: 'duration', width: '100' },
					{ label: this.$t('Кол-во клиентов'), prop: 'realClientsCount', width: '100', formatter: row => (row.done ? row.realClientsCount : row.primaryClientsCount) },
					{ label: this.$t('Начислено баллов'), prop: 'realBonusPoints', width: '100', formatter: row => (row.done ? row.realClientsCount * row.bonusPoints : 0) },
					{ label: this.$t('Статус'), prop: 'done', width: '120', formatter: this.formatDoneCell },
				]
			}

			return [
				// { label: 'Дата', prop: 'date', width: '120', formatter: formatDateCell },
				{ label: this.$t('Завершено'), prop: 'deletedDate', width: '120', formatter: formatDateCell },
				{ label: this.$t('Фильтр'), prop: 'filter.name' },
				{ label: this.$t('Кол-во баллов'), prop: 'bonusPoints', width: '80' },
				{ label: this.$t('Срок действия'), prop: 'duration', width: '80' },
				{ label: this.$t('Период срабатывания'), prop: 'cronExpression', width: '292', formatter: this.parseCronFormatWordsCell(this.$store.state.user.locale) },
				{ label: this.$t('Статус'), prop: 'active', width: '120', formatter: this.formatActiveCell },
			]
		},
		availableActions() {
			const actions = [
				{ label: this.$t('Редактировать'), icon: 'pensil', method: this.openEditPage, checkDisabled: () => this.filters.hideActive.value },
				{ label: this.$t('Удалить'), icon: 'trash', method: this.deactivateBonus, checkDisabled: () => this.filters.hideActive.value },
			]

			if (this.isByFiltersView) {
				if (this.currentModeData.activate) actions.splice(1, 0, { label: this.$t('Восстановить'), icon: 'restore', method: this.activateBonus, checkDisabled: row => this.checkBonusActive(row) })
			} else {
				actions.splice(1, 0, { label: this.$t('Дублировать'), icon: 'duplicate', method: this.copyBonus, checkDisabled: row => !this.checkBonusActive(row) })
			}

			return actions
		},
		compositeFilter() {
			return {
				...this.filters,
			}
		},
	},
	watch: {
		compositeFilter: {
			deep: true,
			handler: 'setBonuses',
		},
		bonuses: 'manualChangeSort',
	},
	async created() {
		this.setDocumentTitle(this.$t('Бонусная система'))

		await this.setCategories()
		this.setDepartments()

		this.setFiltersFromQuery()

		this.$nextTick(async () => {
			this.isAllFiltersSet = true

			await this.setBonuses()

			if (this.$route.query.bonusId) {
				const { bonusId, ...query } = this.$route.query

				this.accentBonusId = Number(bonusId)

				this.$router.replace({ query })
			}
		})
	},
	methods: {
		parseCronFormatWords,
		parseCronFormatWordsCell(locale) {
			return (row, column, cellValue) => this.parseCronFormatWords(cellValue, locale)
		},
		filter() {},
		async setCategories() {
			try {
				this.categories = (await this.$http.optima2.get('bonus/rules/category')).data
			} catch (error) {
				this.$notifyUserAboutError(error)
			}
		},
		async setDepartments() {
			try {
				this.departments = (await this.$http.optima2.get('bonus/rules/departments')).data
			} catch (error) {
				this.$notifyUserAboutError(error)
			}
		},
		onPaginationChange() {
			this.setBonuses()
		},
		async setBonuses() {
			if (!this.isAllFiltersSet) return

			this.isLoading = true

			try {
				const filters = this.getFormattedFilters()

				if (!this.isByFiltersView) filters.isActive = filters.showActive

				const params = {
					...filters,
					page: this.pagination?.number || 0,
					size: this.pagination?.size || 10,
				}

				// api v2
				if (this.currentModeData.optima2) {
					if (params.orderField) {
						const orderField = SORT_PROPS_V2[params.orderField] || params.orderField
						params.sort = `${orderField},${params.orderType === SORT_ORDERS.descending ? 'desc' : 'asc'}`
					}
					delete params.orderField
					delete params.orderType
					delete params.showActive

					params.mode = filters.mode ? filters.mode.toUpperCase() : null
					params.ruleType = filters.ruleType ? filters.ruleType.toUpperCase() : null
				}

				const httpOptima = this.currentModeData.optima2 ? this.$http.optima2 : this.$http.optima

				const { content, ...other } = (await httpOptima.get(this.currentModeData.apiLink, { params })).data

				this.pagination = other
				this.bonuses = content
			} catch (error) {
				this.$notifyUserAboutError(error)
			}

			this.isLoading = false
		},
		async sortChange({ prop, order }) {
			if (order === null) {
				this.filters.orderField.value = null
				this.filters.orderType.value = null
			} else {
				this.filters.orderField.value = SORT_PROPS[prop]
				this.filters.orderType.value = SORT_ORDERS[order]
			}

			this.routeReplace('orderField')
			this.routeReplace('orderType')
		},
		manualChangeSort() {
			if (!this.filters.orderField.value || !this.filters.orderType.value) return

			this.$nextTick(() => {
				const prop = SORT_PROPS_REVERSE[this.filters.orderField.value]
				const order = SORT_ORDERS_REVERSE[this.filters.orderType.value]

				const tableStore = this.$refs.table.store

				const column = tableStore.states.columns.find(tableColumn => tableColumn.property === prop)

				if (column) {
					column.order = order

					tableStore.updateSort(column, prop, order)
				}
			})
		},
		openReadPage(row) {
			this.openEditPage(row, true)
		},
		openEditPage({ id, active, done, ruleType }, isReadView = false) {
			if (!isReadView && !this.checkBonusActive({ active, done })) return

			const params = { id }
			const query = {}

			if (this.isByFiltersView) params.type = this.filters.accrualType.value
			else {
				params.mode = this.filters.mode.value
				params.ruleType = ruleType
			}

			if (isReadView) query.isReadView = 'true'

			this.$router.push({ name: this.currentModeData.editRouterName, params, query })
		},
		async deactivateBonus(row) {
			try {
				await this.$confirm(this.$t('Вы точно хотите удалить правило?'), this.$t('Внимание'), {
					confirmButtonText: this.$t('Удалить'),
					cancelButtonText: this.$t('Отмена'),
					type: 'warning',
				})

				const httpOptima = this.currentModeData.optima2 ? this.$http.optima2 : this.$http.optima

				await httpOptima[this.currentModeData.deactivate.apiMethod](`${this.currentModeData.deactivate.apiLink}/${row.id}`)

				const bonusIndex = this.bonuses.findIndex(bonus => bonus.id === row.id)
				if (bonusIndex > -1) this.bonuses.splice(bonusIndex, 1)
			} catch (error) {
				if (error === 'cancel' || error === 'close') return
				this.$notifyUserAboutError(error)
			}
		},
		async activateBonus(row) {
			try {
				const httpOptima = this.currentModeData.optima2 ? this.$http.optima2 : this.$http.optima

				await httpOptima[this.currentModeData.activate.apiMethod](`${this.currentModeData.activate.apiLink}/${row.id}`)

				this.negateBonusActiveStatus(row)
			} catch (error) {
				this.$notifyUserAboutError(error)
			}
		},
		copyBonus({ id, ruleType }) {
			this.$router.push({ name: this.currentModeData.createRouterName, query: { fromId: id, mode: this.filters.mode.value, ruleType } })
		},
		negateBonusActiveStatus(row) {
			const fieldName = typeof row.done === 'boolean' ? 'done' : 'active'

			row[fieldName] = !row[fieldName]
		},
		checkBonusActive({ active, done }) {
			return active || (typeof done === 'boolean' && !done)
		},
		async refreshClientsCount(bonusRule) {
			if (bonusRule.isLoading) return

			bonusRule.isLoading = true

			try {
				const { data } = await this.$http.optima.put(`bonus/points/refresh-clients-count/${bonusRule.id}`)

				bonusRule.primaryClientsCount = data.primaryClientsCount
			} catch (error) {
				this.$notifyUserAboutError(error)
			}

			bonusRule.isLoading = false
		},
		formatActiveCell(row, column, cellValue) {
			return cellValue ? this.$t('Активный') : this.$t('Завершенный')
		},
		formatDoneCell(row, column, cellValue) {
			return cellValue ? this.$t('Завершенный') : this.$t('Активный')
		},
	},
}
</script>

<style lang="scss">

#page-bonus {
	.el-card__header {
		border: none;
		padding-bottom: 0;
	}
	.table-period {
		.cell {
			white-space: pre;
		}
	}

	.small-select {
		width: 120px;
	}

	.icon-loading {
		animation: loading-rotate 1s linear infinite;
	}

	@keyframes loading-rotate {
		100% {
			transform: rotate(-360deg);
		}
	}

	.el-radio {
		&__inner {
			border-color: var(--el-border-color-dark);
		}

		&-button {
			&__inner {
				background: var(--el-color-white) !important;
				border-color: var(--el-border-color-dark) !important;
				padding-top: 7px;
				padding-bottom: 7px;
			}

			&.is-active {
				.el-radio-button__inner {
					background: var(--el-color-primary) !important;
					// border: none;
				}
			}
		}
	}

	.el-radio-group {
		flex-wrap: nowrap;
	}

	.height-32 {
		.el-input {
			height: 32px !important;
			line-height: 32px !important;
		}
	}
}
</style>