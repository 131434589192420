import store from '~/plugins/store'
import { dayjs } from '~/kit/plugins/dayjs'
import { formatDate } from '~/utils/formatters'
import { ORDER_TYPE_PASCAL, ORDER_TYPE_MAP } from '~/helpers/common/OrderTypes'
import getQueryParamValue from '../getQueryParamValue'

import { routerPush, callBeforeRouteLeaveForLegacyLink } from './routerPush'
import checkCompanyAccessToRoute from './checkCompanyAccessToRoute'


export const loadLinkSimpleCheckup = async url => {
	const { company } = store.state.user || ''

	/** Новый интерфейс страницы выбора рецепта заблокирован
	* до полной готовности заказа КЛ */

	// if (url.startsWith('saleOrder/selectClPrescription')) {
	// 	const clientId = getQueryParamValue({ url, paramKey: 'clientId' })

	// 	routerPush(`/order/contact-lenses/receipts/${clientId}`)
	// 	return true
	// }

	if (url.startsWith('loyaltyOrder/startPage')) {
		const queryParams = url.split('?')[1].split('&').reduce((res, item) => {
			const [k, v] = item.split('=')
			res[k] = v
			return res
		}, {})
		const orderType = ORDER_TYPE_PASCAL[ORDER_TYPE_MAP[queryParams.fromOrderButton]]

		routerPush(`/clients-journal?orderType=${orderType}&loyaltyOrder=${queryParams.loyaltyProgramName}`)
		return true
	}

	if (
		url.startsWith('storeOrdersJournal/openJournal')
		|| url.startsWith('doctorOrdersJournal/openJournal')
	) {
		const orderId = url.split('lastLineId=')[1]

		routerPush(`/orders-journal?activeOrderRow=${parseInt(orderId)}`)
		return true
	}

	if (url.startsWith('returnsJournal/openJournal')) {
		const returnNum = url.split('lastLineId=')[1]
		routerPush(`/returns-journal?activeReturnRow=${parseInt(returnNum)}`)
		return true
	}

	// Order Fast Repair
	if (
		url.startsWith('saleOrder/startOrder')
		&& url.includes('type=2')
	) {
		const settings = await store.dispatch('aSettings')

		if (settings?.newOrderFastRepair) {
			const clientId = getQueryParamValue({ url, paramKey: 'clientId' })

			routerPush(`/order/repair/${parseInt(clientId)}`)
			return true
		}
	}

	if (store.state?.settings?.newOrderSale) {
		if (url.startsWith('saleOrder/startOrderClPrescription')) {
			return callBeforeRouteLeaveForLegacyLink()
		}

		if (url.includes('type=') && !url.includes('type=1')) {
			return callBeforeRouteLeaveForLegacyLink()
		}

		if (url.startsWith('saleOrder/startOrder')) {
			const clientId = url.split('clientId=')[1]
			routerPush(`/order/sale/${parseInt(clientId)}`)
			return true
		}
	}

	if (url.startsWith('transfer/') && !checkCompanyAccessToRoute(company, 'transfersJournal')) {
		return callBeforeRouteLeaveForLegacyLink()
	}

	if (url.startsWith('transfer/openJournalOutcome')) {
		const transferId = url.split('lastLineId=')[1]
		routerPush(`/transfers-journal?activeTransferRow=${parseInt(transferId)}&transfers=OUTGOING`)
		return true
	}

	if (url.startsWith('transfer/openJournal')) {
		const transferId = url.split('lastLineId=')[1]
		routerPush(`/transfers-journal?activeTransferRow=${parseInt(transferId)}`)
		return true
	}

	if (url.startsWith('cancellationsJournal/openJournal')) {
		routerPush(`/cancellations-journal`)
		return true
	}

	if (url.startsWith('clientCancellation/openJournal')) {
		routerPush(`/cancellations-journal/materials`)
		return true
	}

	if (url.startsWith('cancellationsJournal/showNewCancellations')) {
		const START_FROM = formatDate(dayjs().subtract(2, 'month'), 'YYYY-MM-DD')
		const START_TO = formatDate(Date.now(), 'YYYY-MM-DD')
		routerPush(`/cancellations-journal?createdOnFrom=${START_FROM}&createdOnTo=${START_TO}&statuses=CREATED`)
		return true
	}

	if (url.startsWith('cancellationsJournal/acceptCancellation')) {
		const { moveToJournalAfterCancellationAction } = store.getters.gSettings
		if (moveToJournalAfterCancellationAction) {
			routerPush(`/cancellations-journal`)
			return true
		}
		const id = url.split('/').pop()
		window.loadLinkSimpleLegacy(`cancellationsJournal/showCancellation/${id}`)
		return true
	}

	if (url.startsWith('cancellationsJournal/cancelCancellation')) {
		const { moveToJournalAfterCancellationAction } = store.getters.gSettings
		if (moveToJournalAfterCancellationAction) {
			routerPush(`/cancellations-journal`)
			return true
		}
		const id = url.split('/').pop()
		window.loadLinkSimpleLegacy(`cancellationsJournal/showCancellation/${id}`)
		return true
	}

	if (url.startsWith('productionActiveGlasses/acceptOrder')) {
		const { moveToJournalAfterProductionAction } = store.getters.gSettings
		if (moveToJournalAfterProductionAction) {
			routerPush(`/workshop-journal`)
			return true
		}
		const id = url.split('/').pop()
		window.loadLinkSimpleLegacy(`productionActiveGlasses/showOrder/${id}`)
		return true
	}

	if (url.startsWith('productionGlassesJournal/finishOrder')) {
		if (store.getters.gSettings.moveToJournalAfterProductionAction) {
			routerPush(`/glasses-journal`)
		} else {
			const id = url.split('/').pop()
			window.loadLinkSimpleLegacy(`productionGlassesJournal/showOrder/${id}`)
		}
		return true
	}

	if (url.startsWith('supply/openJournal/saved')) {
		const activeRow = url.split('lastLineId=')[1]

		routerPush(`/supplies-journal/saved?activeRow=${parseInt(activeRow)}`)
		return true
	}

	if (url.startsWith('supply/openJournal')) {
		const activeRow = url.split('lastLineId=')[1]

		routerPush(`/supplies-journal/confirmed?activeRow=${parseInt(activeRow)}`)
		return true
	}

	if (url.startsWith('revision/openJournal')) {
		const activeRow = url.split('lastLineId=')[1]

		routerPush(`/revisions-journal?activeRow=${parseInt(activeRow)}`)
		return true
	}

	if (url.startsWith('reevaluationsJournal/openJournal')) {
		const activeRow = url.split('lastLineId=')[1]
		const isDepot = store.getters['user/isDepot']
		const { hasBasePrice } = store.getters.gSettings

		if (isDepot && hasBasePrice) {
			routerPush(`/reevaluations/wholesale?activeRow=${parseInt(activeRow)}`)
		} else {
			routerPush(`/reevaluations?activeRow=${parseInt(activeRow)}`)
		}
		return true
	}

	if (url.startsWith('extrasOrdersJournal/openJournal')) {
		const isExtrasGroup = url.includes('groupOrderTab=true')
		const extraId = url.split('lastLineId=')[1]

		if (isExtrasGroup) {
			routerPush(`/extras-journal?activeExtraRow=${parseInt(extraId)}`)
		} else {
			routerPush(`/extras-journal/single?activeExtraRow=${parseInt(extraId)}`)
		}

		return true
	}

	if (url.startsWith('client/')
		&& !url.startsWith('client/show')
		&& !url.startsWith('client/createOrder')
	) {
		routerPush(`/${url}`)
		return true
	}

	if (url.startsWith('discounts')) {
		if (url.startsWith('discounts/standardDiscounts')) {
			routerPush('/discounts-journal')
			return true
		}

		if (url.startsWith('discounts/couponsDiscounts')) {
			routerPush('/discounts-journal/coupons')
			return true
		}

		if (url.startsWith('discounts/autoDiscounts')) {
			routerPush('/discounts-journal/automatic')
			return true
		}

		if (url.startsWith('discounts/clientCardDiscounts')) {
			routerPush('/discounts-journal/client-card')
			return true
		}

		if (url.startsWith('discounts/clReplacementDiscounts')) {
			routerPush('/discounts-journal/cl-replacement')
			return true
		}

		if (url.startsWith('discounts/MarkdownDiscounts')) {
			routerPush('/discounts-journal/markdown-discounts')
			return true
		}
	}

	if (url.startsWith('goodsCertificates/list')) {
		const activeRow = url.split('lastLineId=')[1]

		routerPush(`/compilance-register-journal?activeRow=${parseInt(activeRow)}`)
	}

	if (url.startsWith('goodsCertificatesActions/add')) {
		routerPush('/compilance-register-creation')
		return true
	}

	if (url.startsWith('checkVisionEdit/showServicesList')) {
		let orderId = parseInt(url.split('/').at(-1))

		// в легаси под разными пользователями по-разному формируется урл для перехода в новый фронт
		// Из-за этого этот костыль
		if (!+orderId) {
			orderId = parseInt(url.split('id=').at(-1))
		}

		routerPush(`/order/${orderId}/services`)
		return true
	}

	if (url.startsWith('distribution/showMailing')) {
		const id = url.split('/').pop()
		routerPush(`/mailing/${id}`)
		return true
	}

	if (url.startsWith('distribution/openJournal')) {
		const type = url.split('?')[1] || 'sms'
		routerPush(`/mailing/list/${type}`)
		return true
	}

	if (url.startsWith('returnsJournal/showReturn')) {
		const returnId = getQueryParamValue({ url, paramKey: 'returnId' })
		routerPush(`/order/return/${returnId}/view`)
		return true
	}

	if (url.startsWith('order/refund')) {
		const orderId = parseInt(url.split('/').at(-1))

		routerPush(`/order/return/${orderId}`)
		return true
	}

	if (url.startsWith('clientRelations/list/')) {
		const clientId = url.split('/')[2]
		routerPush(`/client-relations/${clientId}`)
		return true
	}

	return callBeforeRouteLeaveForLegacyLink()
}