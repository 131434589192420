<template>
	<el-select
		v-bind="$attrs"
		ref="select"
		popper-class="ta-select-dropdown"
		:value="value"
		:data-ta="name"
		:clearable="clearable"
		@change="handleChange"
	>
		<div
			v-if="isMultiple"
			class="mass-selection px-5 d-flex justify-content-between bg-color-border-extra-light"
			style="height: 34px; line-height: 34px;"
		>
			<div
				class="clickable"
				@click.stop="massSelection('all')"
			>{{ selectAllString }}</div>
			<div
				class="clickable"
				@click.stop="massSelection('deselect')"
			>{{ deselectString }}</div>
		</div>
		<slot/>
	</el-select>
</template>

<script>
export default {
	name: 'ItSelect',
	props: {
		value: {
			type: [String, Number, Boolean, Array, Object, Date, Function, Symbol],
			required: false,
			default: null,
		},
		sort: {
			type: [Boolean, Function],
			required: false,
			default: null,
		},
		name: {
			type: [],
			default: null
		},
		items: {
			type: [Array],
			required: false,
			default: () => ([]),
		},
		clearable: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		isMultiple() {
			return typeof this.$attrs.multiple !== 'undefined'
		},
		selectAllString() {
			return this.$t ? this.$t('Выбрать всё') : 'Выбрать всё'
		},
		deselectString() {
			return this.$t ? this.$t('Сбросить всё') : 'Сбросить всё'
		},
	},
	mounted() {
		this.fixParkinsonUI()
	},
	methods: {
		// при масштабировании в браузере zoom < 100% в компоненте el-select
		// стартует бесконечный цикл пересчёта высоты и элемент начинает "дрожать"
		// установка inline css height останавливает бесконечное изменение высоты
		fixParkinsonUI() {
			let throttle = 0
			const config = {
				attributes: false,
				childList: true,
				subtree: true,
			}
			const observer = new MutationObserver(() => {
				if (throttle && throttle < Date.now()) {
					return this.setInlineHeight()
				}
				throttle = Date.now() + 100
				return true
			})

			observer.observe(this.$el, config)
		},
		setInlineHeight() {
			const elTagsContainer = this.$el.querySelector('.el-select__tags')

			if (!elTagsContainer) return

			elTagsContainer.style.height = 'auto'
			setTimeout(() => {
				elTagsContainer.style.height = `${elTagsContainer.offsetHeight}px`
			})
		},
		handleChange($event) {
			this.$emitWrapper($event)
		},
		massSelection(mode) {
			let value = []
			if (mode === 'all') {
				value = this.items.map(item => item.value || item.id)
			}

			this.$emitWrapper(value)
			this.$refs.select.blur()
		},
		$emitWrapper(value) {
			if (!this.isMultiple || !this.sort) {
				this.$emit('update:modelValue', value)
				return
			}

			const sortFunc = (typeof this.sort === 'function') ? this.sort : this.defaultSortFunc

			const sortedValue = this.items
				.map(item => ({
					value: item.value || item.id,
					label: item.label || item.name,
				}))
				.filter(opt => value.includes(opt.value))
				.sort(sortFunc)
				.map(opt => opt.value)

			this.$emit('update:modelValue', sortedValue)
		},
		defaultSortFunc(a, b) {
			const A = typeof a === 'object' ? a.label : a
			const B = typeof b === 'object' ? b.label : b

			return A.localeCompare ? A.localeCompare(B) : A - B
		},
	},
}
</script>

<style lang="scss">
.is-empty .mass-selection {
	display: none !important;
}
</style>