<template>
	<el-table
		:data="cartServices"
		class="mb-4"
		data-ta="selected-services-list"
		stripe
	>
		<el-table-column
			v-for="col in cartTableColumns"
			:key="col.prop"
			:prop="col.prop"
			:label="col.label"
			:width="col.width"
			:formatter="col.formatter"
			:align="col.align || 'left'"
		>
			<template v-if="col.custom" #default="{ row }">
				<div v-if="col.prop === 'discount'" class="d-flex flex-column gap-1">
					<div
						v-if="row.activeDiscount"
						class="d-flex align-items-center gap-2"
					>
						<span>{{ `${row.activeDiscount.name} ${row.activeDiscount.discountPercentage}% (${row.activeDiscount.discountSum})` }}</span>
						<el-tooltip
							v-if="row.activeDiscount.deletable"
							effect="dark"
							:content="$t('Удалить скидку')"
							placement="top"
						>
							<ItIcon
								name="close"
								width="18"
								height="18"
								class="cursor-pointer color-text-regular fill-current flex-shrink"
								@click.stop="deleteDiscount(row)"
							/>
						</el-tooltip>
					</div>
					<div
						v-if="row.extraDiscount?.discountSum"
					>{{ `${row.extraDiscount.name} ${row.extraDiscount.discountPercentage}% (${row.extraDiscount.discountSum})` }} </div>
				</div>
				<template v-else-if="col.prop === 'count'">
					<QuantityInput
						:ref="row.cartItemId"
						:cart-item-id="row.cartItemId"
						:count="row.count"
						@change-cnt="changeCnt($event, row.cartItemId, row)"
					/>
				</template>
			</template>
		</el-table-column>
		<el-table-column width="40">
			<template #default="{ row }">
				<ItIcon
					name="trash"
					width="16"
					height="16"
					class="clickable"
					@click="removeService(row.cartItemId)"
				/>
			</template>
		</el-table-column>
	</el-table>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import debounce from 'lodash/debounce'
import { formatCurrencyCell, formatDateTimeCell } from '~/utils/formatters'
import QuantityInput from '../components/QuantityInput.vue'

export default {
	name: 'PartServicesCart',
	components: {
		QuantityInput,
	},
	data() {
		return {
			changeCntFunctions: {},
			cartServices: [],
			cartTableColumns: [
				{
					prop: 'createdAt',
					label: this.$t('Дата'),
					formatter: formatDateTimeCell,
				},
				{
					prop: 'serviceName',
					label: this.$t('Название'),
				},
				{
					prop: 'fullName',
					label: this.$t('ФИО сотрудника'),
				},
				{
					prop: 'count',
					label: this.$t('Кол-во'),
					custom: true,
				},
				{
					prop: 'discount',
					label: this.$t('Скидка'),
					custom: true,
				},
				{
					prop: 'totalDiscountPrice',
					label: this.$t('Стоимость'),
					formatter: formatCurrencyCell,
					align: 'right',
				},
			],
		}
	},
	computed: {
		...mapState('orders', ['servicesCartUuid']),
		...mapGetters('orders', ['gPreparedCartServices']),
		isChangingCnt() {
			return !!Object.keys(this.changeCntFunctions).length
		},
	},
	watch: {
		isChangingCnt(isChangingCnt) {
			this.$emit('set-is-changing-cnt', isChangingCnt)
		},
		'gPreparedCartServices.services': {
			handler(services) {
				// нужно в некоторых кейсах для принудительного изменения значения в инпуте,
				// чтобы корректно отображалось значение после изменения c 1 на другое число с разбиением услуги на 2
				services?.forEach(service => {
					if (this.$refs[service.cartItemId]) {
						if (+this.$refs[service.cartItemId][0].$refs[`quantity${service.cartItemId}`].input.value !== service.count) {
							this.$refs[service.cartItemId][0].$refs[`quantity${service.cartItemId}`].input.value = service.count
						}
					}
				})

				this.cartServices = services
			},
		},
	},
	methods: {
		...mapActions('orders', [
			'aDeleteDiscount',
			'aTableItemCountServes',
			'aRemoveServiceFromCart',
		]),
		deleteDiscount(row) {
			const cartItemIds = [row.cartItemId]
			const uid = this.servicesCartUuid
			this.aDeleteDiscount({ uid, cartItemIds })
		},
		/* сделан объект для хранения debounce функций changeCntFunctions, так как отдельный метод debounce тут не пойдет,
		потому что в данном случае это обработчик для нескольких инпутов, соответственно нужны разные очереди выполнения.
		иначе при быстром изменении в инпутах будет применяться только последнее изменение в последнем инпуте */
		changeCnt(cnt, cartItemId, service) {
			if (!this.changeCntFunctions[cartItemId]) {
				this.changeCntFunctions[cartItemId] = debounce((count, id) => {
					if (+count) {
						this.aTableItemCountServes({ uid: this.servicesCartUuid, id, data: { count } })
					} else {
						this.removeService(id, service)
					}

					delete this.changeCntFunctions[cartItemId]
				}, 1000)
			}

			return this.changeCntFunctions[cartItemId](cnt, cartItemId)
		},
		async removeService(cartItemId, service = null) {
			try {
				await this.$confirm(this.$t('Удалить позицию из заказа?'), this.$t('Внимание'), {
					confirmButtonText: this.$t('Удалить'),
					cancelButtonText: this.$t('Отмена'),
					type: 'warning',
				})

				await this.aRemoveServiceFromCart({ cartUuid: this.servicesCartUuid, cartItemId })
			} catch (error) {
				if (!error && service?.cartItemId) {
					this.$refs[service.cartItemId][0].$refs[`quantity${service.cartItemId}`].input.value = service.count
					return
				}

				this.$notifyUserAboutError(error)
			}
		},
	},
}
</script>