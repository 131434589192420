export default function () {
	return {
		journalsClients: [],
		journalsClientsLoading: false,
		journalsClientsPagination: {},

		journalsDepartments: [],
		journalsDepartmentsLoading: false,
		journalsDepartmentsPagination: {},

		journalsUsers: [],
		journalsUsersLoading: false,
		journalsUsersPagination: {},

		journalsGlasses: [],
		journalsGlassesLoading: false,
		journalsGlassesPagination: {},

		journalsLegalEntities: [],
		journalsLegalEntitiesLoading: false,
		journalsLegalEntitiesPagination: {},

		journalsLicences: [],
		journalsLicencesLoading: false,
		journalsLicencesPagination: {},

		journalsAccessControl: [],
		journalsAccessControlLoading: false,
		journalsAccessControlPagination: {},

		journalsAccessControlStatistics: [],
		journalsAccessControlStatisticsLoading: false,
		journalsAccessControlStatisticsPagination: {},

		journalsClientFiles: [],
		journalsClientFilesLoading: false,
		journalsClientFilesPagination: {},

		journalsSalesPlans: [],
		journalsSalesPlansLoading: false,
		journalsSalesPlansPagination: {},

		journalsDirectory: [],
		journalsDirectoryLoading: false,
		journalsDirectoryPagination: {},

		journalsOrders: [],
		journalsOrdersLoading: false,
		journalsOrdersPagination: {},
		journalsOrdersTotals: {},

		journalsOrdersExpectedDate: [],
		journalsOrdersExpectedDateLoading: false,
		journalsOrdersExpectedDatePagination: {},
		journalsOrdersExpectedDateTotals: {},

		journalsOrdersCompleted: [],
		journalsOrdersCompletedLoading: false,
		journalsOrdersCompletedPagination: {},

		journalsReturns: [],
		journalsReturnsLoading: false,
		journalsReturnsPagination: {},

		journalsAcceptTransfer: [],
		journalsAcceptTransferLoading: false,
		journalsAcceptTransferPagination: {},

		journalsTransfers: [],
		journalsTransfersLoading: false,
		journalsTransfersPagination: {},
		journalsTransfersTotals: null,

		journalsExtras: [],
		journalsExtrasLoading: false,
		journalsExtrasPagination: {},
		journalsExtrasTotals: {},

		journalsExtrasGroups: [],
		journalsExtrasGroupsLoading: false,
		journalsExtrasGroupsPagination: {},
		journalsExtrasGroupsTotals: {},

		journalsRevisions: [],
		journalsRevisionsLoading: false,
		journalsRevisionsPagination: {},

		journalsCancellations: [],
		journalsCancellationsLoading: false,
		journalsCancellationsPagination: {},
		journalsCancellationsTotals: {},

		journalsCancellationsMaterials: [],
		journalsCancellationsMaterialsLoading: false,
		journalsCancellationsMaterialsPagination: {},
		journalsCancellationsMaterialsTotals: {},

		journalsReevaluations: [],
		journalsReevaluationsLoading: false,
		journalsReevaluationsPagination: {},

		journalsWholesaleReevaluations: [],
		journalsWholesaleReevaluationsLoading: false,
		journalsWholesaleReevaluationsPagination: {},

		journalsConfirmedSupplies: [],
		journalsConfirmedSuppliesLoading: false,
		journalsConfirmedSuppliesPagination: {},
		journalsConfirmedSuppliesTotals: {},

		journalsSavedSupplies: [],
		journalsSavedSuppliesLoading: false,
		journalsSavedSuppliesPagination: {},
		journalsSavedSuppliesTotals: {},

		journalsDiscountsStandard: [],
		journalsDiscountsStandardLoading: false,
		journalsDiscountsStandardPagination: {},

		journalsDiscountsCoupons: [],
		journalsDiscountsCouponsLoading: false,
		journalsDiscountsCouponsPagination: {},

		journalsDiscountsAutomatic: [],
		journalsDiscountsAutomaticLoading: false,
		journalsDiscountsAutomaticPagination: {},

		journalsDiscountsClientsCard: [],
		journalsDiscountsClientsCardLoading: false,
		journalsDiscountsClientsCardPagination: {},

		journalsDiscountsCLReplacement: [],
		journalsDiscountsCLReplacementLoading: false,
		journalsDiscountsCLReplacementPagination: {},

		journalsDiscountsMarkdown: [],
		journalsDiscountsMarkdownLoading: false,
		journalsDiscountsMarkdownPagination: {},

		journalsWorkshop: [],
		journalsWorkshopLoading: false,
		journalsWorkshopPagination: {},

		journalsBalance: [],
		journalsBalanceLoading: false,
		journalsBalancePagination: {},

		journalsNews: [],
		journalsNewsLoading: false,
		journalsNewsPagination: {},

		journalsGiftCertificates: [],
		journalsGiftCertificatesLoading: false,
		journalsGiftCertificatesPagination: {},

		journalsGiftCertificatesByDate: [],
		journalsGiftCertificatesByDateLoading: false,
		journalsGiftCertificatesByDatePagination: {},

		journalsCompilanceRegister: [],
		journalsCompilanceRegisterLoading: false,
		journalsCompilanceRegisterPagination: {},

		journalsMailingSms: [],
		journalsMailingSmsLoading: false,
		journalsMailingSmsPagination: {},
		journalsMailingSmsTotals: {},

		journalsMailingEmail: [],
		journalsMailingEmailLoading: false,
		journalsMailingEmailPagination: {},
		journalsMailingEmailTotals: {},

		journalsMailingFailed: [],
		journalsMailingFailedLoading: false,
		journalsMailingFailedPagination: {},

		journalsClientsFilters: [],
		journalsClientsFiltersLoading: false,
		journalsClientsFiltersPagination: {},
	}
}