export default {
	data() {
		return {
			isFormChanged: false,
		}
	},
	async beforeRouteUpdate(to, from, next) {
		const res = await this.mxConfirmIfFormChanged()
		next(Boolean(res))
	},
	async beforeRouteLeave(to, from, next) {
		const res = await this.mxConfirmIfFormChanged()
		next(Boolean(res))
	},
	methods: {
		async mxConfirmIfFormChanged() {
			if (this.$store.state.isIframeHid) return true

			if (!this.isFormChanged) return true

			try {
				await this.$confirm(`${this.$t('Несохраненные данные будут потеряны')}.<br>${this.$t('Уйти со страницы')}?`, {
					dangerouslyUseHTMLString: true,
					confirmButtonText: 'ОК',
					cancelButtonText: this.$t('Отмена'),
					type: 'warning',
					confirmButtonClass: 'confirm-button--danger',
					cancelButtonClass: 'cancel-button--primary',
				})

				this.isFormChanged = false
				return true
			} catch (error) {
				return false
			}
		},
		async mxCancelFormEdit() {
			try {
				await this.$confirm(`${this.$t('Несохранённые данные будут потеряны. Продолжить?')}`, {
					confirmButtonText: 'ОК',
					cancelButtonText: this.$t('Отмена'),
					type: 'warning',
					confirmButtonClass: 'confirm-button--danger',
					cancelButtonClass: 'cancel-button--primary',
				})
				return true
			} catch (error) {
				return false
			}
		},
	},
}