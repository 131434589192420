<template>

	<div
		:data-ta="`currency_${name}`"
		class="com-field-item com-field-currency"
	>
		<PartFieldView
			v-if="!isEdit"
			v-model="viewValue"
			:name="name"
			:label="label"
			:label-width="labelWidth"
			:label-color-dot="labelColorDot"
			:link-pattern="linkPattern"
			:show-label="showLabel"
			:is-html="isHtml"
			:on-click="onClick"
			:info-tooltip="infoTooltip"
		/>


		<el-form-item
			v-else
			:ref="name"
			:prop="name"
			:label="label"
			:label-width="labelWidth"
		>

			<PartInfoTooltip
				v-if="infoLabelTooltip"
				:info-tooltip="infoLabelTooltip"
				label-tooltip
			/>

			<div class="d-flex align-items-center w-100p">
				<ComCurrencyInput
					ref="inputRef"
					v-model="formattedValue"
					v-loading="loading"
					:placeholder="placeholder"
					:data-ta="name"
					:clearable="clearable"
					:disabled="disabled || readonly"
					:options="options"
					:maxlength="maxLength"
					:show-word-limit="showWordLimit"
					:size="size"
					:prefix-icon="prefixIcon"
					:suffix-icon="suffixIcon"
					:suffix-char="suffixChar"
					@update:modelValue="handleInput"
					@clear="onClear"
				/>

				<PartInfoTooltip
					v-if="infoTooltip"
					:info-tooltip="infoTooltip"
				/>

			</div>
		</el-form-item>
	</div>
</template>

<script>
// import { currencyToNumber } from '~/utils/currencies'
import defaultProps from './defaultFieldsProps'
import ComCurrencyInput from '../ComCurrencyInput'
import PartFieldView from './parts/PartFieldView'
import PartInfoTooltip from './parts/PartInfoTooltip'

export default {
	name: 'ComFieldCurrency',
	components: {
		PartFieldView,
		ComCurrencyInput,
		PartInfoTooltip,
	},
	props: {
		...defaultProps,
		prefixIcon: {
			type: String,
			default: null,
		},
		suffixIcon: {
			type: String,
			default: null,
		},
		suffixChar: {
			type: String,
			default: null,
		},
		options: {
			type: Object,
			default: null,
		},
		modelValue: {
			type: Number,
			default: null,
		},
	},
	data() {
		return {
			formattedValue: null,
		}
	},
	computed: {
		viewValue() {
			return this.formattedValue || '-'
		},
	},
	watch: {
		modelValue: {
			handler(val) {
				this.formattedValue = val
			},
			immediate: true,
		},
	},
	methods: {
		handleInput(value) {
			// Временно убрал, в третьей версии эти костыли кажутся избыточными:
			/* // Дважды отрабатывает событие input
			const numValue = currencyToNumber(value, this.$i18n.locale)
			const numFormattedValue = currencyToNumber(this.formattedValue, this.$i18n.locale)

			console.log('numValue', 'ComFieldCurrency.vue:155', numValue === numFormattedValue)

			if (numValue !== numFormattedValue) {
				this.$emit('update:modelValue', numValue || null)
			} else if (numValue === 0) {
				if (this.options.valueRange.min === 0) {
					this.$emit('update:modelValue', 0)
				}
			} */

			this.formattedValue = value
			this.$emit('update:modelValue', value)
		},
		onClear() {
			this.formattedValue = null
			this.$emit('clear')
		},
		focus() {
			this.$refs.inputRef.focus()
		},
	},
}
</script>