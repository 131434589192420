import VuexHttp from '../../vuexHttpService'

const vHttp = new VuexHttp('good')

export default {
	async aGoodTypes() {
		return vHttp.GET(this, {
			url: {
				path: 'good/types',
			},
			mutation: 'mGoodTypes',
		})
	},
	async aBarcodesLengths() {
		return vHttp.GET(this, {
			url: 'good/barcodes/length',
			mutation: 'mBarcodesLengths',
		})
	},
	aGoodsBarcodePrint(_, data) {
		return vHttp.POST(this, {
			url: 'barcodes/print',
			data,
		})
	},
}