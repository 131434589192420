<template>
	<div
		:data-ta="`dadata_${name}`"
		class="com-field-item com-field-dadata"
	>
		<PartFieldView
			v-if="!isEdit"
			v-model="viewValue"
			:name="name"
			:label="label"
			:label-width="labelWidth"
			:label-color-dot="labelColorDot"
			:link-pattern="linkPattern"
			:show-label="showLabel"
			:is-html="isHtml"
			:on-click="onClick"
			:info-tooltip="infoTooltip"
		/>

		<el-form-item
			v-else
			:ref="name"
			:prop="name"
			:label="label"
			:label-width="labelWidth"
		>

			<PartInfoTooltip
				v-if="infoLabelTooltip"
				:info-tooltip="infoLabelTooltip"
				label-tooltip
			/>

			<div
				class="d-flex align-items-center w-100p"
				:class="autosize ? '' : 'no-autosize'"
			>

				<el-autocomplete
					v-model="model"
					:type="inputType"
					:fetch-suggestions="onInput"
					:trigger-on-focus="triggerOnFocus"
					:placeholder="placeholder"
					:show-word-limit="showWordLimit"
					:maxlength="maxLength"
					:debounce="debounce"
					:disabled="disabled || readonly"
					:size="size"
					:autosize="autosize"
					:teleported="true"
					:data-ta="name"
					class="inline-input w-100p"
					@select="handleSelect"
					@click="onClickItem"
					@update:modelValue="onManualInput"
					@keydown.enter.prevent="onEnter"
				>
					<template #default="{ item }">
						<div
							class="el-autocomplete-dadata-item"
							@click="onClickOption(item)"
						>{{ item.value }}</div>
					</template>
				</el-autocomplete>

				<PartInfoTooltip
					v-if="infoTooltip"
					:info-tooltip="infoTooltip"
				/>

			</div>
		</el-form-item>
	</div>
</template>

<script>
import { cacheFn } from '~/utils'
import {
	dadataGetCity,
	dadataGetAddress,
	dadataGetStreet,
	dadataGetStreetHouse,
	dadataGetPassportIssuedBy,
	dadataRequest,
} from '~/utils/dadata'
import defaultProps from './defaultFieldsProps'
import PartFieldView from './parts/PartFieldView'
import PartInfoTooltip from './parts/PartInfoTooltip'

const THROTTLE_INPUT = 500
const TIMEOUT_ON_DADATA_ERROR = 30

export default {
	components: {
		PartFieldView,
		PartInfoTooltip,
	},
	props: {
		...defaultProps,
		modelValue: {
			type: [String, Object],
			default: '',
		},
		bound: {
			type: String, // city, street, address
			default: 'street',
		},
		region: {
			type: String,
			default: 'RU',
		},
		city: {
			type: String,
			default: null,
		},
		cities: {
			type: Array,
			default: null,
		},
		autosize: {
			type: Object,
			default: null,
		},
		returnStringValue: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			returnDadataObject: false,
			selectedValue: '',
			selectedData: null,
			dadataErrorState: false,
			debounce: THROTTLE_INPUT,
		}
	},
	computed: {
		model: {
			get() {
				const value = this.modelValue?.string || this.modelValue
				return typeof value === 'object' ? '' : value
			},
			set(value) {
				this.$emit(
					'update:modelValue',
					this.returnDadataObject && value ? {
						string: value,
						data: this.selectedData,
					} : value
				)
			},
		},
		triggerOnFocus() {
			return (this.bound === 'city' && Boolean(this.cities?.length)) || this.model?.length > 10
		},
		viewValue() {
			if (typeof this.modelValue === 'string') {
				return this.modelValue || '—'
			}
			if (this.returnDadataObject) {
				return this.modelValue?.string || '—'
			}
			return '—'
		},
		inputType() {
			if (this.bound === 'city') {
				return 'input'
			}
			return 'textarea'
		},
	},
	created() {
		this.returnDadataObject = !this.returnStringValue

		this.getAddress = cacheFn(
			this.getAddress,
			this,
			{ cacheTimeout: 300 }
		)
		this.getStreet = cacheFn(
			this.getStreet,
			this,
			{ cacheTimeout: 300 }
		)
		this.getStreetHouse = cacheFn(
			this.getStreetHouse,
			this,
			{ cacheTimeout: 300 }
		)
		this.getCity = cacheFn(
			this.getCity,
			this,
			{ cacheTimeout: 300 }
		)
	},
	methods: {
		handleSelect(option) {
			// когда строка адреса вставляется из буфера и она равна строке адреса из дадата,
			// после выбора из списка не отменяется ошибка валидации, т.к. значение не меняется
			// поэтому сначала сбрасываем адрес, потом присваиваем заново
			if (this.model === option.value) {
				this.model = ''
			}
			this.$nextTick(() => {
				this.selectedValue = option.value
				this.selectedData = option.data || null
				this.model = option.value
				this.onInput(this.model, () => {})
			})
		},
		setDadataError() {
			this.dadataErrorState = true
			setTimeout(() => {
				this.dadataErrorState = false
			}, TIMEOUT_ON_DADATA_ERROR * 1000)
		},
		async onManualInput(val = '') {
			// пользователь удалил часть адреса
			if (this.selectedValue.length > val.length) {
				this.selectedData = null
			// в поле вставлен текст из буфера
			} else if (!val.startsWith(this.selectedValue)) {
				this.selectedData = null
			}
		},
		async onInput(value = '', callback = () => {}) {
			let result
			const val = Object.hasOwn(value, 'string') ? value.string : value

			if (this.bound === 'city' && this.cities?.length) {
				result = this.getFilteredCities(val)
				callback(result)
				return
			}

			if (!val || val.length < 3) {
				callback([])
				return
			}
			// улица выбрана, для ввода дома и квартиры не запрашиваем дадату
			if (this.bound === 'street' && this.selectedValue && val.startsWith(this.selectedValue)) {
				callback([])
				return
			}

			if (this.bound === 'address') {
				result = await this.getAddress(val)
			} else if (this.bound === 'house') {
				result = await this.getStreetHouse(val)
			} else if (this.bound === 'street') {
				result = await this.getStreet(val)
			} else if (this.bound === 'passportIssuedBy') {
				result = await this.getPassportIssuedBy(val)
			} else {
				result = await this.getCity(val)
			}

			callback(result)

			// валидация не всегда срабатывает вовремя
			this.$nextTick(this.$refs[this.name].validate)
		},
		onEnter() {
			this.onInput(this.model, () => {})
		},
		onClickItem() {
			this.onInput(this.model, () => {})
		},
		onClickOption(item) {
			this.handleSelect(item)
		},
		async request(query) {
			if (this.dadataErrorState) {
				return []
			}
			return dadataRequest(query)
		},
		getFilteredCities(val) {
			let { cities } = this
			if (val) {
				cities = this.cities.filter(city => city?.toLowerCase().includes(val.toLowerCase()))
			}
			return cities.map(city => ({
				value: city,
				label: city,
			}))
		},
		async getCity(val) {
			if (this.region !== 'RU') {
				return []
			}
			return dadataGetCity(val)
		},
		async getStreet(val) {
			if (this.region !== 'RU') {
				return []
			}
			return dadataGetStreet(val, this.city)
		},
		async getStreetHouse(val) {
			if (this.region !== 'RU') {
				return []
			}
			return dadataGetStreetHouse(val, this.city)
		},
		async getAddress(val) {
			if (this.region !== 'RU') {
				return []
			}
			return dadataGetAddress(val, this.city)
		},
		async getPassportIssuedBy(val) {
			if (this.region !== 'RU') {
				return []
			}
			return dadataGetPassportIssuedBy(val)
		},
	},
}
</script>

<style lang="scss">
.com-field-dadata {
	.no-autosize {
		.el-textarea__inner {
			height: calc(var(--el-input-height, 40px) - 2px);
			line-height: 28px;
		}
	}
}
</style>