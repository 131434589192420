import $dayjs from 'dayjs'
import i18n from '~/plugins/i18n'
import store from '~/plugins/store'

export const isRequired = {
	message: i18n.global.t('Обязательное поле'),
	trigger: 'change',
	required: true,
}

export const isWhitespace = {
	message: i18n.global.t('Не может состоять из пробелов'),
	trigger: 'change',
	whitespace: true,
}

export const isEmail = {
	message: i18n.global.t('Некорректный емейл'),
	trigger: 'blur',
	type: 'email',
}

export const isNumber = {
	trigger: 'change',
	validator: (rule, value, callback) => {
		if (!value) return callback()
		if (typeof value !== 'number' && typeof value === 'string' && Number.isNaN(Number(value))) {
			return callback(i18n.global.t('Введите числовое значение'))
		}
		return callback()
	},
}

export const isInteger = {
	trigger: 'change',
	validator: (rule, value, callback) => {
		isNumber.validator(rule, value, res => {
			if (res || String(value).includes('.')) {
				return callback(i18n.global.t('Введите целое числовое значение'))
			}
			return callback()
		})
	},
}

export const isLatin = {
	trigger: 'change',
	validator: (rule, value, callback) => {
		const regex = /^[0-9a-zA-Z]+$/
		if (regex.test(value)) {
			return callback()
		}
		return callback(i18n.global.t('Только латинские символы'))
	},
}

export const notNumber = {
	trigger: 'change',
	validator: (rule, value, callback) => {
		const regex = /[^\d]+$/
		if (regex.test(value)) {
			return callback()
		}
		return callback(i18n.global.t('Без ввода чисел'))
	},
}

export const isUppercase = {
	trigger: 'change',
	validator: (rule, value, callback) => {
		if (value !== value.toUpperCase()) {
			return callback(i18n.global.t('В верхнем регистре'))
		}
		return callback()
	},
}

export const isLowercase = {
	trigger: 'change',
	validator: (rule, value, callback) => {
		if (value !== value.toLowerCase()) {
			return callback(i18n.global.t('В нижнем регистре'))
		}
		return callback()
	},
}


export function minLength(num) {
	return {
		message: i18n.global.t(`Не менее {0} символов`, [num]),
		trigger: 'change',
		min: num,
	}
}

export function maxLength(num) {
	return {
		message: i18n.global.t(`Не более {0} символов`, [num]),
		trigger: 'change',
		max: num,
	}
}

export function specialNumberOfCharacters(num) {
	return {
		message: i18n.global.t(`Должно быть {0} символов`, [num]),
		trigger: 'change',
		min: num,
		max: num,
	}
}

export function forbiddenSymbols(regExp) {
	return {
		trigger: 'change',
		validator: (rule, value, callback) => {
			if (regExp.test(value)) {
				return callback(i18n.global.t(`Недопустимый символ`))
			}
			return callback()
		},
	}
}

export const hasWhitespaces = {
	trigger: 'change',
	validator: (rule, value, callback) => {
		const regex = /\s/g
		if (!value) return callback()
		if (regex.test(value)) {
			return callback(i18n.global.t('Не может состоять из пробелов'))
		}
		return callback()
	},
}

export function minNumber(num) {
	return {
		trigger: 'change',
		validator: (rule, value, callback) => {
			if ((value || value === 0) && value < num) {
				return callback(i18n.global.t(`Не может быть меньше {0}`, [num]))
			}
			return callback()
		},
	}
}

export function maxNumber(num) {
	return {
		trigger: 'change',
		validator: (rule, value, callback) => {
			if (!num) {
				return callback()
			}

			if ((value || Number(value) === 0) && Number(value) > Number(num)) {
				return callback(i18n.global.t(`Не может быть больше {0}`, [num]))
			}
			return callback()
		},
	}
}

export function minDate(minDateValue) {
	return {
		trigger: 'change',
		validator: (rule, value, callback) => {
			if (value && new Date(value).getTime() < new Date(minDateValue).getTime()) {
				return callback(`${i18n.global.t('Не может быть раньше')} ${$dayjs(minDateValue).format('DD.MM.YYYY')}`)
			}
			return callback()
		},
	}
}

export function maxDate(maxDateValue) {
	return {
		trigger: 'change',
		validator: (rule, value, callback) => {
			if (!maxDateValue) {
				return callback()
			}

			if (value && new Date(value).getTime() > new Date(maxDateValue).getTime()) {
				return callback(`${i18n.global.t('Не может быть позже')} ${$dayjs(maxDateValue).format('DD.MM.YYYY')}`)
			}
			return callback()
		},
	}
}

export function validateInn(personOrLegal = 'legal') {
	return {
		trigger: 'change',
		validator: (rule, value, callback) => {
			const innLength = personOrLegal === 'legal' ? 10 : 12
			if (!value) return callback()
			if (Number.isNaN(Number(value))) {
				return callback(i18n.global.t('ИНН должен состоять только из цифр'))
			}
			if (`${value}`.length !== innLength) {
				return callback(i18n.global.t(`Длина ИНН должна быть {0} символов`, [innLength]))
			}
			return callback()
		},
	}
}

export function validateKpp() {
	return {
		trigger: 'change',
		validator: (rule, value, callback) => {
			if (!value) return callback()
			if (Number.isNaN(Number(value))) {
				return callback(new Error(i18n.global.t('КПП должен состоять только из цифр')))
			}
			if (`${value}`.length !== 9) {
				return callback(new Error(i18n.global.t('Длина КПП должна быть 9 символов')))
			}
			return callback()
		},
	}
}

export const passportNumberLength = {
	trigger: 'blur',
	validator: (rule, value, callback) => {
		if (!value || value.length === 11) callback()
		else callback(new Error(i18n.global.t('Введите серию и номер')))
	},
}

export function getPhoneValidator(phoneMask = '') {
	return {
		trigger: 'blur',
		validator: (rule, value, callback) => {
			if (value?.length < phoneMask.length) {
				return callback(i18n.global.t('Укажите номер полностью'))
			}
			return callback()
		},
	}
}

export const getPasswordRules = (data, refForm) => {
	function validatePasswordEquity(rule, value, callback) {
		if (data.password !== data.password2) {
			callback(new Error(i18n.global.t('Введенные пароли должны совпадать')))
			return
		}

		refForm.clearValidate(['password', 'password2'])
		callback()
	}

	return {
		password: [
			isRequired,
			minLength(8),
			maxLength(32),
			{ validator: validatePasswordEquity, trigger: 'change' },
		],
		password2: [
			isRequired,
			minLength(8),
			maxLength(32),
			{ validator: validatePasswordEquity, trigger: 'change' },
		],
	}
}

// валидатор срабатывает только на выборе адреса из выпадающего списка в поле
// ignoreStringType - исключение для ЮЛ, в которых адрес хранится строкой
export function dadataValidator(bound, ignoreStringType = false) {
	return {
		trigger: 'change',
		validator: (rule, value, callback) => {
			if (!value 
				|| value && value.string === ''
				|| ignoreStringType && typeof value === 'string'
			) {
				return callback()
			}
		
			if (bound === 'city') {
				if (!value.string) {
					return callback(new Error(i18n.global.t(`Выберите город`)))
				}
				return callback()
			}

			if (['street', 'address'].includes(bound)) {
				if (value.string?.length < 3) {
					return callback(i18n.global.t(`Нужно ввести адрес`))
				}
				if (!value.data) {
					return callback(i18n.global.t(`Нужно выбрать адрес из списка`))
				}
				if (!value.data?.house && bound === 'address') {
					return callback(i18n.global.t(`Нужно ввести адрес с номером дома`))
				}
				if (!value.data?.street && bound === 'street') {
					return callback(i18n.global.t(`Нужно выбрать улицу`))
				}
			}
			
			return callback()
		},
	}
}

export function snilsValidator(owner, snilsOwner = 'CLIENT') {
	const { id: ownerId, snilsNumber: currentSnils } = owner || { id: null }
	return {
		trigger: 'change',
		validator: async (rule, snilsNumber, callback) => {
			if (!snilsNumber) return callback()

			if (snilsNumber?.length < 14) return callback('Укажите СНИЛС полностью')
			if (snilsNumber?.length > 14) return callback('Некорректный номер СНИЛС')
			if (snilsNumber.replace(/\D/g, '') === String(currentSnils)) return callback()

			const response = await store.dispatch(
				'n3healthIntegration/aUniqueSnilsCheck',
				{ ownerId, snilsNumber, snilsOwner }
			)

			if (!response) return callback('Укажите другой СНИЛС')

			return callback()
		},
	}
}

export function phoneValidator() {
	return {
		trigger: 'blur',
		validator: (rule, value, callback) => {
			if (value.length < store.getters.gPhoneMask?.length) return callback(i18n.global.t('Укажите номер полностью'))
			if (value.length > store.getters.gPhoneMask?.length) return callback(i18n.global.t('Некорректный номер'))
			return callback()
		},
	}
}

function monthsDaysValidation(value) {
	const { year, month, day } = value
	const monthsDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
	if (year && year % 4 === 0) {
		monthsDays[1] = 29
	}
	if (month && day && monthsDays[month - 1] < day) {
		return i18n.global.t('Неправильная дата')
	}
	return false
}

export function dateSeparatedValidator() {
	return {
		trigger: 'change',
		validator: (rule, value, callback) => {
			const monthsDaysInvalid = monthsDaysValidation(value)
			if (monthsDaysInvalid) return callback(monthsDaysInvalid)
			return callback()
		},
	}
}

export function dateSeparatedValidatorRequired() {
	return {
		trigger: 'change',
		validator: (rule, value, callback) => {
			const filledFields = +!!value.year + +!!value.month + +!!value.day
			if (!filledFields) return callback(i18n.global.t('Обязательное поле'))
			if (filledFields < 3) return callback('Укажите полную дату')

			const monthsDaysInvalid = monthsDaysValidation(value)
			if (monthsDaysInvalid) return callback(monthsDaysInvalid)
			return callback()
		},
	}
}

export function clientCardValidator() {
	return {
		trigger: 'blur',
		validator: async (rule, value, callback) => {
			if (!value) {
				return callback()
			}
			if (store.state.settings.clientCardLength > value.length) {
				return callback(i18n.global.t('Введите номер карты полностью'))
			}
			if (store.state.settings.clientCardLength < value.length) {
				return callback(i18n.global.t(`Номер карты должен содержать {0} символов`, [store.state.settings.clientCardLength]))
			}
			return callback()
		},
	}
}
