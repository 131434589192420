import { goodsParamsFiltersFormatter, goodsTypesFiltersFormatter } from '~/pages/discounts-creation/data/goodsParamsFormatters'
import VuexHttp from '../../vuexHttpService'

const vHttp = new VuexHttp('discounts')

export default {
	async aAutomaticDiscount(_, id) {
		return vHttp.GET(this, {
			url: {
				path: `auto-discounts/${id}`,
			},
			mutation: 'mAutomaticDiscount',
		})
	},
	async aDeleteStandardAndCouponDiscount(_, id) {
		return vHttp.DELETE(this, {
			url: {
				path: `discounts/${id}`,
			},
		})
	},
	async aDeleteAutomaticDiscount(_, id) {
		return vHttp.DELETE(this, {
			url: {
				path: `auto-discounts/${id}`,
			},
		})
	},
	async aDeleteClientCardDiscount(_, id) {
		return vHttp.DELETE(this, {
			url: {
				path: `client-card-discounts/${id}`,
			},
		})
	},
	async aDeleteCLReplacementDiscount(_, id) {
		return vHttp.DELETE(this, {
			url: {
				path: `cl-replace-discounts/${id}`,
			},
		})
	},
	async aDeleteMarkdownDiscount(_, id) {
		return vHttp.DELETE(this, {
			url: {
				path: `markdown-discounts/${id}`,
			},
		})
	},
	async aRestoreStandardAndCouponDiscount(_, id) {
		return vHttp.PUT(this, {
			url: {
				path: `discounts/${id}/restore`,
			},
		})
	},
	async aRestoreClientCardDiscount(_, id) {
		return vHttp.PUT(this, {
			url: {
				path: `client-card-discounts/${id}/restore`,
			},
		})
	},
	async aRestoreCLReplacementDiscount(_, id) {
		return vHttp.PUT(this, {
			url: {
				path: `/cl-replace-discounts/${id}/restore`,
			},
		})
	},
	async aCreateStandardDiscount(_, data) {
		return vHttp.POST(this, {
			url: 'discounts',
			data,
		})
	},
	async aCreateAutomaticDiscount(_, data) {
		const formattedData = {}

		if (data.goodsType) {
			formattedData.goodsType = goodsTypesFiltersFormatter(data.goodsType)
		} else {
			formattedData.goodsType = 'ALL_GOODS'
		}

		Object.entries(data).forEach(([paramName, paramValue]) => {
			if (paramName === 'goodsType') return
			if (paramValue === null || paramValue === '') return
			if (Array.isArray(paramValue) && !paramValue.length) return

			const formattedParamName = goodsParamsFiltersFormatter(paramName, data.goodsType)

			if (formattedParamName.includes('.')) {
				const [prefix, postfix] = formattedParamName.split('.')
				if (prefix === 'serve') {
					formattedData.serve = {
						servicesId: paramValue.map(item => item.toString()),
					}
				} else {
					formattedData[prefix] = {
						...formattedData[prefix],
						[postfix]: paramValue,
					}
				}
			} else {
				formattedData[formattedParamName] = paramValue
			}
		})

		return vHttp.POST(this, {
			url: 'auto-discounts',
			data: formattedData,
		})
	},
	async aCreateClientCardDiscount(_, data) {
		return vHttp.POST(this, {
			url: 'client-card-discounts',
			data,
		})
	},

	async aCreateCLReplacementDiscount(_, data) {
		return vHttp.POST(this, {
			url: 'cl-replace-discounts',
			data,
		})
	},

	async aCreateMarkdownDiscount(_, data) {
		return vHttp.POST(this, {
			url: 'markdown-discounts',
			data,
		})
	},

	async aCLManufacturers() {
		return vHttp.GET(this, {
			url: {
				path: 'contact-lenses/manufacturers/list',
				query: {
					size: 10000,
				},
			},
			mutation: 'mCLManufacturers',
		})
	},
	aCLNames(_, manufacturer = null) {
		const path = manufacturer
			? 'nomenclature/contactlenses/name'
			: 'contact-lenses/names/list'

		const query = manufacturer
			? { manufacturer }
			: { size: 1000, deleted: false }

		return vHttp.GET(this, {
			url: {
				path,
				query,
			},
			mutation: 'mCLNames',
		})
	},
	aRounds() {
		return vHttp.GET(this, {
			url: {
				path: 'rounds',
			},
			mutation: 'mRounds',
		})
	},
	aReasonMarkdowns() {
		return vHttp.GET(this, {
			url: {
				path: 'reason-markdowns',
				query: {
					size: 10000,
				},
			},
			mutation: 'mReasonMarkdowns',
		})
	},
	aBarcodesScan(_, barcode) {
		return vHttp.GET(this, {
			url: `remains-goods/${barcode}`,
		})
	},
	aFilterByRemains(_, params) {
		const formattedParams = {}

		if (params.goodsType) {
			formattedParams.goodsType = goodsTypesFiltersFormatter(params.goodsType)
		}

		Object.entries(params).forEach(([paramName, paramValue]) => {
			if (paramName === 'goodsType') return
			if (paramValue === null || paramValue === '') return
			if (!paramValue && paramValue !== 0 && paramName !== 'active' && paramName !== 'groupByDepartmentAndParams') return

			const formattedParamName = goodsParamsFiltersFormatter(paramName, params.goodsType)
			formattedParams[formattedParamName] = paramValue
		})

		return vHttp.GET(this, {
			url: {
				path: `remains-goods`,
				query: formattedParams,
			},
		})
	},
}