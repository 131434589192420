<template>
	<div class="order-bonus-card">
		<transition name="fade" mode="out-in">
			<el-form
				v-if="!card"
				ref="form"
				:model="bonusCard"
				:rules="rules"
				data-ta="order-bonus-card-form"
				require-asterisk-position="right"
			>
				<el-form-item prop="cardId">
					<el-input
						v-model="bonusCard.cardId"
						:placeholder="$t('Просканируйте дисконтную карту')"
						:maxlength="settings.clientCardLength"
						data-ta="order-bonus-card-input"
						size="default"
						@update:modelValue="scanBonusCard"
					/>
				</el-form-item>
			</el-form>

			<el-tag
				v-else
				type="info"
				class="order-bonus-card__info"
			>
				<div class="color-text-regular">{{ $t('Дисконтная карта') }}</div>

				<div
					data-ta="order-bonus-card-number"
					class="color-text-regular bold"
				>{{ card.cardId }}</div>

				<div class="order-bonus-card__handle">
					<el-tooltip
						:content="$t('Удалить')"
						placement="top"
					>
						<ItIcon
							data-ta="order-bonus-card-delete"
							name="restore"
							width="19"
							height="19"
							class="fill-text-regular"
							@click="deleteBonusCard"
						/>
					</el-tooltip>

					<el-tooltip
						placement="top-end"
						:disabled="isPopoverVisible"
						:content="$t('Скидки по карте')"
					>
						<ItIcon
							name="warning-outline"
							width="16"
							height="16"
							class="fill-text-regular"
							@click="isPopoverVisible = !isPopoverVisible"
						/>
					</el-tooltip>

					<el-popover
						:visible="isPopoverVisible"
						placement="top-end"
						width="300"
						:title="$t('Скидки по карте')"
					>

						<template #reference><span/></template>
						<ul class="order-bonus-card__products-list">
							<li
								v-for="(value, label) in bonusCardDiscounts"
								:key="label"
								class="order-bonus-card__product"
							>
								<span>{{ label }}</span>
								<span
									class="bold"
								>{{ value }}%</span>
							</li>
						</ul>
					</el-popover>
				</div>
			</el-tag>
		</transition>
	</div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { hasWhitespaces, isNumber } from '~/utils/validationRules'

export default {
	name: 'BonusCard',
	props: {
		card: {
			type: Object,
			default: () => ({}),
		},
	},
	data: () => ({
		isPopoverVisible: false,
		bonusCard: {
			cardId: null,
		},
		rules: {
			cardId: [hasWhitespaces, isNumber],
		},
	}),
	computed: {
		...mapState(['settings']),
		...mapState('orders', ['cartUid']),

		bonusCardProducts() {
			return {
				ACCESSORY: this.$t('Аксессуары'),
				CONTACT_LENS: this.$t('Контактные линзы'),
				EXTRA: this.$t('Массовка'),
				GLASSES: this.$t('Оправы'),
				LENS: this.$t('Линзы'),
				SUNGLASSES: this.$t('Солнцезащитные очки'),
				SERVE: this.$t('Услуги'),
			}
		},

		bonusCardDiscounts() {
			const bonusCardDiscounts = Object.entries(this.bonusCardProducts)
				.reduce((acc, [name, label]) => {
					const discounts = this.card?.discounts
					acc[label] = discounts?.[name]?.discountPercent || 0
					return acc
				}, {})

			return bonusCardDiscounts
		},
	},
	methods: {
		...mapActions('orders', [
			'aAddBonusCard',
			'aDeleteBonusCard',
			'aServices',
			'aUnmarkedGoods',
			'aCart',
		]),

		async scanBonusCard() {
			this.bonusCard.cardId = this.bonusCard.cardId.replace('.', '')
			this.bonusCard.cardId = this.bonusCard.cardId.replace(',', '')

			if (this.bonusCard.cardId.length < this.settings.clientCardLength) {
				return
			}

			this.$refs.form.validate(async valid => {
				if (valid) {
					const result = await this.aAddBonusCard({
						uid: this.cartUid,
						cardId: this.bonusCard.cardId,
					})

					if (result?.status === 200) {
						Promise.all([
							this.aServices(this.cartUid),
							this.aUnmarkedGoods(this.cartUid),
							this.aCart(this.cartUid),
						])
						this.$notify({ title: this.$t('Дисконтная карта добавлена'), type: 'success' })
					}

					this.bonusCard.cardId = null
				}

				return false
			})
		},

		async deleteBonusCard() {
			const result = await this.aDeleteBonusCard(this.cartUid)

			if (result?.status === 200) {
				Promise.all([
					this.aServices(this.cartUid),
					this.aUnmarkedGoods(this.cartUid),
					this.aCart(this.cartUid),
				])
				this.$notify({ title: this.$t('Дисконтная карта удалена'), type: 'success' })
			}
		},
	},
}
</script>

<style lang="scss">

.order-bonus-card {
	&__info {
		width: 100%;
		.el-tag__content {
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			background: var(--el-background-color-base) !important;

			& > div:nth-child(1) {
				flex-basis: 50%;
			}

			& > div:nth-child(2), div:nth-child(3) {
				cursor: pointer
			}
		}
	}

	&__handle {
		display: flex;
		gap: 11px;
		align-items: center;
	}

	&__products-list {
		padding-left: 0;
		margin-bottom: 0;
	}

	&__product {
		margin-bottom: 6px;
		list-style-type: none;
		display: flex;
		justify-content: space-between;
		font-size: 12px;

		&:last-child {
			margin-bottom: 0;
		}
	}
}
</style>