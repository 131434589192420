import { throttle } from 'throttle-debounce'

const throttleFunc = throttle(
	30000,
	() => {
		window.holdOnLegacySessionRequest()
	},
	{ noLeading: false, noTrailing: false }
)

export default throttleFunc