import applicationSettingsState from './applicationSettingsState'
import applicationSettingsActions from './applicationSettingsActions'
import applicationSettingsMutations from './applicationSettingsMutations'
import applicationSettingsGetters from './applicationSettingsGetters'

export default {
	namespaced: true,
	state: applicationSettingsState,
	getters: applicationSettingsGetters,
	mutations: applicationSettingsMutations,
	actions: applicationSettingsActions,
}