let selectedStyleSheet

export function getStyleSheet() {
	if (selectedStyleSheet) {
		return selectedStyleSheet
	}

	try {
		selectedStyleSheet = new CSSStyleSheet()
		document.adoptedStyleSheets = [selectedStyleSheet]
	} catch (e) {
		selectedStyleSheet = null
	}
	return selectedStyleSheet
}
