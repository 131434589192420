<template>
	<div class="order-bonuses" data-ta="order-bonuses-container">
		<div>
			<span class="mr-1">{{ $t('Бонусы') }}</span>
			<span class="order-bonuses__amount" data-ta="order-bonuses-accumulation-points">{{ getAccumulatioBonusPoints }}</span>
			<span data-ta="order-bonuses-card-points"> {{ getClientCardBonusPoints }}</span>
		</div>
		<div class="d-flex align-items-center">
			<el-switch
				v-model="bonusMode"
				class="mr-2 line-height-16"
				active-value="SPENDING"
				inactive-value="ACCUMULATION"
				data-ta="order-bonuses-switch"
			/>
			<span
				class="mr-1"
				:class="{
					'color-primary': isSpendingBonusMode,
					'bold': isSpendingBonusMode,
				}"
			>{{ $t('Списать') }}</span>
			<span
				class="bold"
				data-ta="order-spend-bonuses-points"
				:class="{ 'color-primary': isSpendingBonusMode }"
			>{{ getSpendBonusPoints }}</span>
		</div>
	</div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { formatCurrency } from '~/utils/currencies'

export default {
	name: 'Bonuses',
	props: {
		card: {
			type: Object,
			default: () => ({}),
		},
	},

	computed: {
		...mapState('orders', [
			'cartUid',
		]),

		bonusMode: {
			get() {
				return this.card.bonusMode
			},
			set(value) {
				this.updateBonusMode(value)
			},
		},
		getAccumulatioBonusPoints() {
			return this.card.accumulationBonusPoints > 0
				? `+${this.formatCurrency(this.card.accumulationBonusPoints, this.$i18n.locale)}`
				: '0'
		},
		getClientCardBonusPoints() {
			const bonusPoints = this.card.displayedBonusPoints || this.card.clientCardBonusPoints || 0
			return `(${this.formatCurrency(bonusPoints, this.$i18n.locale)})`
		},
		getSpendBonusPoints() {
			return this.formatCurrency(this.card.spendBonusPoints, this.$i18n.locale)
		},
		isSpendingBonusMode() {
			return this.bonusMode === 'SPENDING'
		},
	},
	methods: {
		formatCurrency,
		...mapActions('orders', [
			'aUpdateBonusMode',
			'aServices',
			'aUnmarkedGoods',
		]),

		async updateBonusMode(value) {
			const data = {
				bonusMode: value,
			}
			await this.aUpdateBonusMode({
				data,
				uid: this.cartUid,
			})

			this.aServices(this.cartUid)
			this.aUnmarkedGoods(this.cartUid)
		},
	},
}
</script>

<style lang="scss">
.order-bonuses {
	display: flex;
	justify-content: space-between;
	align-items: center;

	&__amount {
		color: green;
		margin-right: 2px;
	}

	.line-height-16 {
		line-height: 16px !important;
	}
}
</style>