<!-- eslint-disable vue/no-v-html -->
<template>
	<el-popover
		placement="top"
		width="200"
		trigger="click"
		popper-class="part-phone-popover"
	>
		<p
			v-if="popover.title"
			class="mb-2"
		>{{ $t('Существующий номер') }} -<br>{{ popover.title }}</p>
		<el-input
			v-model="newPhone"
			:placeholder="popover.placeholder"
			:data-ta="name + '__popover'"
			:maxlength="maxLength"
			:clearable="clearable"
			:size="size"
			class="mb-3"
			:class="{
				'is-error': error,
			}"
			@update:modelValue="onInput"
		/>
		<div v-if="error" class="el-form-item__error popover-error">{{ error }}</div>
		<div class="ma-0 mt-1" style="text-align: right">
			<el-button
				size="small"
				:data-ta="name + '__popover-cancel'"
				@click.stop="onCancel"
			>{{ $t('Отмена') }}</el-button>
			<el-button
				type="primary"
				size="small"
				class="ml-3"
				:disabled="Boolean(error)"
				:data-ta="name + '__popover-accept'"
				@click.stop="onAccept"
			>{{ $t('Ок') }}</el-button>
		</div>
		<template #reference>
			<el-button
				type="primary"
				plain
				data-ta="popover-edit-btn"
				class="part-phone-popover__action-btn ml-3"
			><ItIcon
				name="pencil"
				class="return-button-icon fill-primary"
			/></el-button>
		</template>
	</el-popover>
</template>

<script>

import { mapGetters } from 'vuex'
import phoneMasking from '~/utils/phoneMasking'
import Validations from '~/utils/validation'

export default {
	name: 'PartDeletePopover',

	props: {
		name: {
			type: String,
			required: true,
		},
		popover: {
			type: Object,
			required: true,
		},
		rules: {
			type: Array,
			default() { return [] },
		},
		size: {
			type: String,
			default: null,
		},
		maxLength: {
			type: Number,
			default: null,
		},
		clearable: {
			type: Boolean,
			default: true,
		},
	},

	data() {
		return {
			error: '',
			newPhone: '',
			validationError: null,
		}
	},

	computed: {
		...mapGetters(['gPhoneMask']),
	},

	methods: {
		closePopover() {
			document.body.click()
		},
		onInput(value) {
			this.newPhone = phoneMasking(value, this.gPhoneMask)
			this.validation(value)
		},
		onCancel() {
			this.error = ''
			this.newPhone = ''
			this.closePopover()
			this.$nextTick(this.validation)
		},
		async validation(value) {
			this.error = await Validations(value, this.rules)
		},
		async onAccept() {
			const newPhone = phoneMasking(this.newPhone, this.gPhoneMask)
			this.error = await Validations(newPhone, this.rules || [])

			if (!this.error) {
				this.$emit('acceptEdit', newPhone, this.closePopover())
			}
		},
	},
}
</script>


<style lang="scss">
.part-phone-popover {

	.popover-warn,
	.part-phone-popover__title {
		word-break: keep-all;
		text-align: left;
	}

	.el-input-group__append {
		padding: 0 3px;
	}

	&__action-btn {
		height: 28px;
		width: 28px;
		padding-left: 8px;
		padding-right: 8px;
	}

	.is-error {
		.el-input__inner {
			border-color: var(--el-color-danger);
		}
	}
}
</style>