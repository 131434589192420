import i18n from '~/plugins/i18n'
import fieldsCommonForm from '~/pages/departments/data/fieldsCommonForm'


// Переводы в departments.xlsx

export default {
	gDepartmentsTypesMap(_, getters) {
		return getters.gDepartmentsTypesOptions.reduce((map, item) => (map[item.value] = item.value, map), {}) //eslint-disable-line
	},
	gDepartmentsTypesMapLocale(_, getters) {
		return getters.gDepartmentsTypesOptions.reduce((map, item) => (map[item.value] = item.label, map), {}) //eslint-disable-line
	},
	gDepartmentsTypesOptions(state) {
		return state.departmentsTypes.map(item => ({
			value: item.id,
			label: i18n.global.t(item.name),
		}))
	},
	gDepartmentsOptions(state) {
		const departmentsOptions = state.departments.map(item => ({
			value: String(item.id),
			label: item.name,
		}))
		departmentsOptions.sort((a, b) => (a.label < b.label ? -1 : 1))
		return departmentsOptions
	},
	gDepartmentData(state, getters, rootState) {
		if (!state.department) return {}

		const basicLegalEntity = state.departmentLegalEntityRules.basic?.id
		const locationRu = state.department.location

		return {
			...state.department,
			...state.departmentSettings,
			type: state.department.type,
			location: rootState.settings.region === 'RU' ? locationRu : null,
			basicLegalEntity,
		}
	},
	gDepartmentsDepotOptions(state) {
		return [{
			value: '',
			label: i18n.global.t('Нет'),
			viewLabel: i18n.global.t('Нет'),
		},
		...state.departmentsDepot.map(item => ({
			label: item.name,
			value: item.id,
		})),
		]
	},
	gDepartmentsForSearchOptions(state) {
		const currentId = state.department?.id
		const options = [
			...state.departmentsForSearch.map(item => ({
				value: item.id,
				label: item.name,
			})),
		]

		// new department
		if (!currentId) {
			options.unshift({
				value: 'current',
				label: i18n.global.t('Текущий департамент'),
			})
		}

		return [{
			value: '',
			label: i18n.global.t('Все департаменты'),
			viewLabel: i18n.global.t('Все департаменты'),
		},
		...options.filter(opt => opt.value === currentId),
		...options.filter(opt => opt.value !== currentId),
		]
	},
	gDepartmentsProductionOptions(state) {
		return [{
			value: '',
			label: i18n.global.t('Нет'),
			viewLabel: i18n.global.t('Нет'),
		},
		...state.departmentsProduction.map(item => ({
			label: item.name,
			value: item.id,
		})),
		]
	},
	gFiscalRegisterTypesOptions(state) {
		return [{
			value: '',
			label: i18n.global.t('Нет'),
			viewLabel: i18n.global.t('Нет'),
		},
		...state.fiscalRegisterTypes.map(item => ({
			label: item.type,
			value: item.type,
		})),
		]
	},
	gFiscalRegisterTypesMap(state) {
		return state.fiscalRegisterTypes.reduce((map, item) => (map[item.type] = item, map), {}) //eslint-disable-line
	},
	gFiscalRegisterExternalOptions(state) {
		return state.fiscalRegisterExternal.map(item => ({
			value: item.id,
			label: item.name,
		}))
	},
	gFiscalRegisterMap(state) {
		return state.fiscalRegister.reduce((map, item) => (map[item.id] = item, map), {}) //eslint-disable-line
	},
	gFiscalRegisterMapExternalId(state) {
		return state.fiscalRegister.reduce((map, item) => (map[item.externalId] = item, map), {}) //eslint-disable-line
	},
	gCardAccountOptions(state) {
		return [{
			value: '',
			label: i18n.global.t('Нет'),
			viewLabel: i18n.global.t('Нет'),
		},
		...state.accountsCashless.map(item => ({
			label: item.name,
			value: item.id,
		})),
		]
	},
	gCashAccountOptions(state) {
		return [{
			value: '',
			label: i18n.global.t('Нет'),
			viewLabel: i18n.global.t('Нет'),
		},
		...state.accountsCash.map(item => ({
			label: item.name,
			value: item.id,
		})),
		]
	},
	gCommonFields(state, getters, rootState, rootGetters) {
		const fields = [...fieldsCommonForm]

		let collectionAccountOptions = [...getters.gCashAccountOptions]
		if (rootState.settings.cardCollectionAccounts) {
			collectionAccountOptions = [
				...getters.gCardAccountOptions.slice(1),
				...getters.gCashAccountOptions.slice(1),
			]
			collectionAccountOptions.sort((a, b) => (a.label > b.label ? 1 : -1))
			collectionAccountOptions = [
				{
					value: '',
					label: i18n.global.t('Нет'),
					viewLabel: i18n.global.t('Нет'),
				},
				...collectionAccountOptions,
			]
		}

		fields.forEach(f => {
			if (f.name === 'type') {
				f.options = [...getters.gDepartmentsTypesOptions]
			}
			if (f.name === 'legalEntityId') {
				f.options = [...rootGetters['legalEntities/gLegalEntitiesOptions']]
			}
			if (f.name === 'depotId') {
				f.options = [...getters.gDepartmentsDepotOptions]
			}
			if (f.name === 'productionId') {
				f.options = [...getters.gDepartmentsProductionOptions]
			}
			if (f.name === 'cardAccountId') {
				f.options = [...getters.gCardAccountOptions]
			}
			if (f.name === 'cashAccountId') {
				f.options = [...getters.gCashAccountOptions]
			}
			if (f.name === 'timeZone') {
				f.options = [...rootState.timezones]
			}
			if (f.name === 'collectionAccountId') {
				f.options = [...collectionAccountOptions]
			}
			if (f.name === 'grayAccountId') {
				f.options = [...getters.gCashAccountOptions]
				f.hidden = !rootState.settings.grayStoreAccount
			}
			if (f.name === 'collectionGrayAccountId') {
				f.options = [...collectionAccountOptions]
				f.hidden = !rootState.settings.grayStoreAccount
			}
			if (f.name === 'otherDepartmentSearchId') {
				f.options = [...getters.gDepartmentsForSearchOptions]
			}
			if (rootState.settings.region === 'RU') {
				if (f.name === 'city') {
					f.region = rootState.settings.region
				}
				if (f.name === 'location') {
					f.region = rootState.settings.region
				}
			} else {
				if (f.name === 'city') {
					f.cities = rootState.cities
					f.region = rootState.settings.region
				}
				if (f.name === 'location') {
					f.hidden = true
				}
				if (f.name === 'description') {
					f.label = i18n.global.t('Адрес')
				}
			}
		})

		return fields
	},
	gDepartmentLegalEntityRules(state) {
		// костыльно заполняем значения для отображения юрлиц у аксессуаров и услуг
		// если в селекте только один вариант юрлица и он не указан явно
		function fillAccsessoriesAndServices() {
			const serviceTypes = { ...state.departmentLegalEntityRules.serviceTypes }
			const accessoryCategories = { ...state.departmentLegalEntityRules.accessoryCategories }
			Object.entries(state.departmentLegalEntityRulesPossibleAccessoryCategories).forEach(([name, leArr]) => {
				if (leArr.length === 1 && !accessoryCategories[name]) {
					accessoryCategories[name] = leArr[0] // eslint-disable-line
				}
			})
			Object.entries(state.departmentLegalEntityRulesPossibleServiceTypes).forEach(([name, leArr]) => {
				if (leArr.length === 1 && !serviceTypes[name]) {
					serviceTypes[name] = leArr[0] // eslint-disable-line
				}
			})
			return { ...accessoryCategories, ...serviceTypes }
		}

		const rules = {
			orderTypes: {
				...state.departmentLegalEntityRules.orderTypes,
			},
			productsTypes: {
				...state.departmentLegalEntityRules.sellableCategories,
			},
			accessoriesAndServices: fillAccsessoriesAndServices(),
		}
		return rules
	},
	gDepartmentLegalEntitiesTable(state) {
		return (state.departmentLegalEntities || []).map(row => ({
			legalEntity: row.legalEntity || {},
			account: row.account || '',
			accountId: row.accountId || row.account?.id || '',
			fiscalRegister: row.fiscalRegister || '',
			fiscalRegisterId: row.fiscalRegisterId || row.fiscalRegister?.id,
			fiscalRegisterType: row.fiscalRegisterType || row.fiscalRegister?.type,
			fiscalRegisterOriginalId: row.fiscalRegisterOriginalId || row.fiscalRegister?.id,
			fiscalRegisterOriginalType: row.fiscalRegisterOriginalType || row.fiscalRegister?.type,
			newRow: row.newRow || false,
			popoverFiscalRegisterVisible: row.popoverFiscalRegisterVisible || false,
			deleted: row.deleted || false,
			unremovable: row.unremovable,
		}))
	},
}