import isEqual from 'lodash/isEqual'
import VuexHttp from '../../vuexHttpService'


const vHttp = new VuexHttp('departments')

export default {
	async aDepartments(_, params) {
		return vHttp.GET(this, {
			url: {
				path: 'departments',
				query: {
					...params,
					size: 1000,
				},
			},
			mutation: 'mDepartments',
		})
	},
	async aDepartmentsDepot() {
		const params = {
			types: 'DEPOT',
			size: 1000,
		}
		return vHttp.GET(this, {
			url: {
				path: 'departments',
				query: params,
			},
			mutation: 'mDepartmentsDepot',
		})
	},
	async aDepartmentsForSearch() {
		const params = {
			types: ['DEPOT', 'PRODUCTION', 'STORE'],
			size: 1000,
		}
		return vHttp.GET(this, {
			url: {
				path: 'departments',
				query: params,
			},
			mutation: 'mDepartmentsForSearch',
		})
	},
	async aDepartmentsProduction() {
		const params = {
			types: 'PRODUCTION',
			size: 1000,
		}
		return vHttp.GET(this, {
			url: {
				path: 'departments',
				query: params,
			},
			mutation: 'mDepartmentsProduction',
		})
	},
	async aDepartment(_, id) {
		return vHttp.GET(this, {
			url: `departments/${id}`,
			mutation: 'mDepartment',
		})
	},
	async aDepartmentDelete(_, id) {
		return vHttp.DELETE(this, {
			url: `departments/${id}`,
			mutation: 'mDepartment',
		})
	},
	async aDepartmentRestore(_, id) {
		return vHttp.PUT(this, {
			url: `departments/restore/${id}`,
			mutation: 'mDepartment',
		})
	},


	async aDepartmentSettings(_, id) {
		return vHttp.GET(this, {
			url: `departments/${id}/settings`,
			mutation: 'mDepartmentSettings',
		})
	},
	async aDepartmentSettingsSave(_, {id, data}) {
		return vHttp.POST(this, {
			url: `departments/${id}/settings`,
			data,
		})
	},

	async aDepartmentAdd(_, data) {
		const setCurrentIdAfterCreate = data.otherDepartmentSearchId === 'current'

		const response = await vHttp.POST(this, {
			url: `departments`,
			mutation: 'mDepartment',
			data: {
				...data,
				otherDepartmentSearchId: setCurrentIdAfterCreate ? null : data.otherDepartmentSearchId,
			},
		})

		if (response && setCurrentIdAfterCreate) {
			await vHttp.PUT(this, {
				url: `departments/${response.data.id}`,
				mutation: 'mDepartment',
				data: {
					...data,
					id: response.data.id,
					otherDepartmentSearchId: response.data.id,
				},
			})
		}

		return response
	},

	async aRulesSave({ getters, state }, { id, rules }) {
		function getChangedRules(original, updated = {}) {
			const changes = []
			Object.keys(updated).forEach(key => {
				if (!original[key] && updated[key] !== null) {
					changes.push({
						key,
						value: updated[key],
						create: true,
					})
				} else if (!isEqual(original[key], updated[key])) {
					changes.push({
						key,
						value: updated[key],
						create: false,
					})
				}
			})
			return changes
		}

		function isService(accessoryOrService) {
			return state.servicesTypes[accessoryOrService]
		}

		const {
			orderTypes,
			productsTypes,
			accessoriesAndServices,
		} = getters.gDepartmentLegalEntityRules
		const requests = []

		if (rules) {
			const orderTypesChanges = getChangedRules(orderTypes, rules.orderTypes)
			const productsTypesChanges = getChangedRules(productsTypes, rules.productsTypes)
			const accessoriesAndServicesChanges = getChangedRules(accessoriesAndServices, rules.accessoriesAndServices)

			orderTypesChanges.forEach(orderType => {
				if (!orderType.value) {
					const request = vHttp.DELETE(this, {
						url: `departments/${id}/legal-entity-rules/order-types/${orderType.key}`,
					})
					requests.push(request)
				} else {
					const method = orderType.create ? 'POST' : 'PUT'
					const request = vHttp[method](this, {
						url: `departments/${id}/legal-entity-rules/order-types`,
						data: {
							legalEntityId: orderType.value,
							orderType: orderType.key,
						},
					})
					requests.push(request)
				}
			})

			productsTypesChanges.forEach(sellableCategory => {
				if (!sellableCategory.value) {
					const request = vHttp.DELETE(this, {
						url: `departments/${id}/legal-entity-rules/sellable-categories/${sellableCategory.key}`,
					})
					requests.push(request)
				} else {
					const method = sellableCategory.create ? 'POST' : 'PUT'
					const request = vHttp[method](this, {
						url: `departments/${id}/legal-entity-rules/sellable-categories`,
						data: {
							legalEntityId: sellableCategory.value,
							sellableCategory: sellableCategory.key,
						},
					})
					requests.push(request)
				}
			})

			accessoriesAndServicesChanges.forEach(accessoryOrService => {
				let method = accessoryOrService.create ? 'POST' : 'PUT'
				let url = `departments/${id}/legal-entity-rules/accessory-categories`
				let data = {
					legalEntityId: accessoryOrService.value,
					accessoryCategoryName: accessoryOrService.key,
				}
				if (isService(accessoryOrService.key)) {
					url = `departments/${id}/legal-entity-rules/service-types`
					data = {
						legalEntityId: accessoryOrService.value,
						serviceTypeId: Number(accessoryOrService.key),
					}
				}
				if (!accessoryOrService.value) {
					method = 'DELETE'
					url += `/${accessoryOrService.key}`
					data = null
				}
				const request = vHttp[method](this, {
					url,
					data,
				})
				requests.push(request)
			})
		}

		return Promise.all(requests)
	},

	async aDepartmentSave({ dispatch }, data) {
		const { department, rules, legalEntities } = data

		return Promise.all([
			dispatch('aRulesSave', {
				id: department.id,
				rules,
			}),
			dispatch('aLegalEntitiesSave', {
				id: department.id,
				legalEntities,
			}),
			dispatch('aCommonSave', {
				id: department.id,
				common: department,
			}),
		]).then(result => result[2])
	},

	async aCommonSave({ dispatch }, data) {
		const { id, common } = data
		const expectedGlassesMode = common.expectedGlassesMode
		delete common.expectedGlassesMode

		const [settings, department] = await Promise.all([
			dispatch('aDepartmentSettingsSave', {
				id: data.common.id,
				data: {
					expectedGlassesMode
				},
			}),
			vHttp.PUT(this, {
				url: `departments/${data.common.id}`,
				data: data.common,
			}),
		])

		return {
			...department,
			...settings
		}
	},

	async aLegalEntitiesSave(_, { id, legalEntities }) {
		const deletionRequests = []
		const requests = []

		if (!legalEntities) {
			return true
		}
		legalEntities.forEach(row => {
			let request
			const leId = row.legalEntity?.id || row.legalEntity
			if (!leId || isNaN(+leId)) return // eslint-disable-line no-restricted-globals
			if (row.deleted) {
				request = vHttp.DELETE(this, {
					url: `departments/${id}/legal-entities/${leId}`,
				})
				deletionRequests.push(request)
			} else {
				const method = row.newRow ? 'POST' : 'PUT'

				request = vHttp[method](this, {
					url: `departments/${id}/legal-entities/${leId}`,
					data: {
						accountId: row.accountId || null,
						fiscalRegisterId: row.fiscalRegisterId || null,
						fiscalRegisterType: row.fiscalRegisterType || null,
					},
				})
				requests.push(request)
			}
		})
		await Promise.all(deletionRequests)
		return Promise.all(requests)
	},
	// список департаментов по типу пользователей, в них работающих
	// (например, офис для менеджеров, магазин для продавцов и врачей и т.д.)
	async aDepartmentsByUserGroup() {
		return vHttp.GET(this, {
			url: `departments/by-user-group`,
			mutation: 'mDepartmentsByUserGroup',
		})
	},
	// список производств
	async aDepartmentsTypes() {
		return vHttp.GET(this, {
			url: `departments/types`,
			mutation: 'mDepartmentsTypes',
		})
	},
	// список счетов по типу счёта
	async aAccountsCash() {
		return vHttp.GET(this, {
			url: `departments/accounts?type=CASH`,
			mutation: 'mAccountsCash',
		})
	},
	async aAccountsCashless() {
		return vHttp.GET(this, {
			url: `departments/accounts?type=CASHLESS`,
			mutation: 'mAccountsCashless',
		})
	},
	async aAccountsTypes() {
		return vHttp.GET(this, {
			url: `accounts/types`,
			mutation: 'mAccountsTypes',
		})
	},

	// список юрлиц по департаменту
	async aDepartmentLegalEntities(_, id) {
		return vHttp.GET(this, {
			url: `departments/${id}/legal-entities`,
			mutation: 'mDepartmentLegalEntities',
		})
	},
	// список юрлиц (действующих), не работающих в департаменте (используется при создании новой привязки)
	async aDepartmentUnusedLegalEntities(_, id) {
		return vHttp.GET(this, {
			url: `departments/${id}/unused-legal-entities`,
			mutation: 'mDepartmentUnusedLegalEntities',
		})
	},
	// список типов касс (в т. ч. и нативных)
	async aFiscalRegisterTypes() {
		return vHttp.GET(this, {
			url: `fiscal-register/types`,
			mutation: 'mFiscalRegisterTypes',
		})
	},
	async aFiscalRegisterExternal(_, params) {
		return vHttp.GET(this, {
			url: {
				path: `fiscal-register/external`,
				query: params,
			},
			mutation: 'mFiscalRegisterExternal',
		})
	},
	async aFiscalRegister(_, params) {
		return vHttp.GET(this, {
			url: {
				path: `fiscal-register`,
				query: params,
			},
			mutation: 'mFiscalRegister',
		})
	},
	async aFiscalRegisterAdd(_, data) {
		return vHttp.POST(this, {
			url: `fiscal-register`,
			data,
		})
	},
	async aFiscalRegisterSave(_, { id, data }) {
		return vHttp.PUT(this, {
			url: `fiscal-register/${id}`,
			data,
			mutation: 'mFiscalRegister',
		})
	},

	// Департаменты-Юрлица
	// все правила по юрлицам в департаменте
	async aDepartmentLegalEntityRules(_, id) {
		return vHttp.GET(this, {
			url: `departments/${id}/legal-entity-rules`,
			mutation: 'mDepartmentLegalEntityRules',
		})
	},
	async aDepartmentLegalEntityRulesPossibleAccessoryCategories(_, id) {
		return vHttp.GET(this, {
			url: `departments/${id}/legal-entity-rules/accessory-categories`,
			mutation: 'mDepartmentLegalEntityRulesPossibleAccessoryCategories',
		})
	},
	async aDepartmentLegalEntityRulesPossibleServiceTypes(_, id) {
		return vHttp.GET(this, {
			url: `departments/${id}/legal-entity-rules/service-types`,
			mutation: 'mDepartmentLegalEntityRulesPossibleServiceTypes',
		})
	},
	async aAccessoryTypes() {
		return vHttp.GET(this, {
			url: `accessories/categories`,
			mutation: 'mAccessoryTypes',
		})
	},
	async aProductsTypes() {
		return vHttp.GET(this, {
			url: `legal-entity-rules/sellable-categories`,
			mutation: 'mProductsTypes',
		})
	},
	async aOrdersTypes() {
		return vHttp.GET(this, {
			url: `orders/types`,
			mutation: 'mOrdersTypes',
		})
	},
	async aServicesTypes() {
		return vHttp.GET(this, {
			url: `services/types`,
			mutation: 'mServicesTypes',
		})
	},

	// Запись на приём
	async aRegistrySettings(_, departmentId) {
		const url = `registry/settings?departmentId=${departmentId}`
		return vHttp.GET(this, {
			url,
			mutation: 'mRegistryData',
		})
	},
	async aAddRegistry(_, data) {
		const url = `registry/settings`
		return vHttp.POST(this, {
			url,
			data,
		})
	},
	async aDeleteRegistry(_, id) {
		return vHttp.DELETE(this, {
			url: `registry/settings/${id}`,
		})
	},
}