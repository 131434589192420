import router from '~/plugins/router'

function defaultState() {
	return {
		hasFrom: false,
		from: null,
		// используется для хранения истории и возвращения к предыдущим
		// страницам без учёта изменённых параметров query strings
		returnHistory: [],
		confirmLeave: null,
	}
}

export default {
	namespaced: true,
	state: defaultState(),
	mutations: {
		// для совместимости для работы Return на старых страницах
		mFrom(state, { to, from }) {
			if (from.name !== to.name) {
				state.from = from
				state.hasFrom = Boolean(from)
			}
		},
		mAddRouteToReturnHistory(state, route) {
			const last = state.returnHistory[state.returnHistory.length - 1]
			if (route && last?.name === route.name) {
				state.returnHistory.pop()
			}
			state.returnHistory.push(route)
		},
		mCleanReturnHistoryAfterReturn(state) {
			state.returnHistory.pop()
			state.returnHistory.pop()
		},
		mResetState(state) {
			Object.assign(state, defaultState())
		},
		mConfirmLeave(state, payload) {
			state.confirmLeave = payload
		},
	},
	actions: {
		async aReturn({ state, commit }, { returnForce, returnTo }) {
			const prev = state.returnHistory[state.returnHistory.length - 2]

			await router.push(returnForce || prev || returnTo)

			commit('mCleanReturnHistoryAfterReturn')
		},
	},
}
