
export default {
	mApplicationSettings(state, payload) {
		state.applicationSettings = payload
	},
	mSecuritySettings(state, payload) {
		state.securitySettings = payload
	},
	mInformationSettings(state, payload) {
		state.informationSettings = payload
	},
	mMedicalCardSettings(state, payload) {
		state.medicalCardSettings = payload
	},
	mCLExpirationDateSettings(state, payload) {
		state.CLExpirationDateSettings = payload
	},
	mLensesTypes(state, payload) {
		state.lensesTypes = payload
	},
	mValidatorTypes(state, payload) {
		state.validatorTypes = payload
	},
	mLifetimeRules(state, payload) {
		state.lifetimeRules = payload
	},
	mWearingPeriod(state, payload) {
		state.wearingPeriod = payload
	},
	mLensesCategories(state, payload) {
		state.lensesCategories = payload
	},
}