import {
	isRequired,
	minLength,
	maxLength,
	specialNumberOfCharacters,
} from './validationRules'


function validation(fieldData, rule = {}) {
	if (!rule) {
		return Promise.resolve()
	}

	if (rule.required) {
		if (fieldData === undefined || fieldData === '') {
			return Promise.reject(isRequired.message)
		}
		return Promise.resolve()
	}

	if (rule.min && rule.max) {
		if (fieldData && fieldData.length !== rule.max) {
			return Promise.reject(specialNumberOfCharacters(rule.max).message)
		}
		return Promise.resolve()
	}

	if (rule.max) {
		if (fieldData && fieldData.length > rule.max) {
			return Promise.reject(maxLength(rule.max).message)
		}
		return Promise.resolve()
	}

	if (rule.min) {
		if (!fieldData || fieldData.length < rule.min) {
			return Promise.reject(minLength(rule.min).message)
		}
		return Promise.resolve()
	}

	if (rule.validator) {
		return new Promise((resolve, reject) => {
			rule.validator(rule, fieldData, result => {
				if (result) return reject(result)
				return resolve()
			})
		})
	}
	return Promise.resolve()
}

export default function Validations(data, rules) {
	if (!rules) return Promise.resolve(null)

	const promises = []

	rules.forEach(rule => {
		if (typeof rule === 'function') {
			promises.push(validation(data, rule()))
		} else {
			promises.push(validation(data, rule))
		}
	})

	return Promise.allSettled(promises)
		.then(results => results.map(res => {
			if (res.status === 'fulfilled') {
				return null
			}
			return res.reason
		}).find(rule => Boolean(rule)))
}


export function FormValidation(data, rules = []) {
	const validations = Object.entries(rules)
		.reduce((promises, [field, fieldRules]) => {
			if (!Array.isArray(fieldRules)) {
				throw new Error(`${field}: validation rules must be array`)
			}
			fieldRules.forEach(rule => promises.push(validation(data[field], rule)))
			return promises
		}, [])

	return Promise.all(validations)
}