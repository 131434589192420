import cloneDeep from 'lodash/cloneDeep'
import i18n from '~/plugins/i18n'

export default {
	gLegalEntities(state) {
		return state.legalEntities
	},
	gLicenses(state) {
		return state.licenses
	},
	gTaxScheme(state) {
		return state.taxSchemes
	},
	gLicense(state) {
		return state.license
	},
	gLegalEntitiesMap(state) {
		return state.legalEntities.reduce((map, item) => (map[item.id] = item.shortName, map), {}) //eslint-disable-line
	},
	gLegalEntitiesOptions(state) {
		return state.legalEntities.map(item => ({
			value: item.id,
			label: item.shortName,
		}))
	},
	gLegalEntitiesDepartments(state) {
		return state.legalEntityDepartments
	},
	gLegalEntitiesDepartmentsData(state) {
		const data = cloneDeep({
			content: state.legalEntityDepartments,
			...state.legalEntityDepartmentsPagination,
		})

		data.content.forEach(item => {
			item.account = item.account === null
				? {
					label: null,
					value: null,
				}
				: {
					label: item.account.name || item.account.label,
					value: item.account.id || item.account.value,
				}

			item.delete = false
		})
		return data
	},
	gLegalEntityDepartmentsIds(state) {
		return state.legalEntityDepartments.map(row => row.department?.id)
	},
	gLegalEntityDepartmentsPagination(state) {
		return state.legalEntityDepartmentsPagination
	},
	gLegalEntity(state) {
		return state.legalEntity
	},
	gTaxRules(state) {
		const data = cloneDeep(state.taxRules)

		Object.entries(data).forEach(([key, value]) => {
			if (key === 'baseTaxScheme') {
				data[key] = {
					baseTaxScheme: value,
				}
			} else if (key === 'servesTaxScheme') {
				data[key] = {
					servesTaxScheme: value,
				}
			} else if (key === 'sellableCategories') {
				if (!data[key].ACCESSORY && data[key].ACCESSORY !== null) {
					data[key].ACCESSORY = {
						ACCESSORY: undefined,
					}
				} else {
					data[key].ACCESSORY = {
						ACCESSORY: data[key].ACCESSORY,
					}
				}
				Object.entries(value).forEach(([innerKey, innerValue]) => {
					if (innerKey !== 'ACCESSORY') {
						data[key][innerKey] = innerValue
					}
				})
			} else if (value && typeof value === 'object' && Object.keys(value).length !== 0) {
				Object.entries(value).forEach(([innerKey, innerValue]) => {
					data[key][innerKey] = innerValue
				})
			}
		})

		return data
	},
	gOrdersTypes(state) {
		return state.ordersTypes.map(f => ({
			...f,
			title: f.label,
			label: f.label,
			options: [
				{
					label: i18n.global.t('Базовая'),
					viewLabel: '—',
					value: '',
				},
				{
					label: i18n.global.t('УСН доход'),
					value: 'SIMPLE',
				},
				{
					label: i18n.global.t('Общая'),
					value: 'FULL',
				},
				{
					label: i18n.global.t('Патент'),
					value: 'PATENT',
				},
				{
					label: i18n.global.t('УСН доход-расход'),
					value: 'SIMPLE_IE',
				},
			],
		}))
	},
	gProductsTypes(state) {
		return state.productsTypes.map(f => ({
			...f,
			title: f.title,
			label: f.title,
			localName: null,
			options: [
				{
					label: i18n.global.t('По типу заказа'),
					viewLabel: '—',
					value: '',
				},
				{
					label: i18n.global.t('УСН доход'),
					value: 'SIMPLE',
				},
				{
					label: i18n.global.t('Общая'),
					value: 'FULL',
				},
				{
					label: i18n.global.t('Патент'),
					value: 'PATENT',
				},
				{
					label: i18n.global.t('УСН доход-расход'),
					value: 'SIMPLE_IE',
				},
			],
		}))
	},
	gAccesories(_state, _, __, rootGetters) {
		const accessoryCategories = cloneDeep(rootGetters['layout/gTabsData'])
		const fields = []
		Object.entries(accessoryCategories.TabTaxScheme.accessoryCategories).forEach(([accessoryCategoryName]) => {
			fields.push({
				name: accessoryCategoryName,
				title: accessoryCategoryName,
				label: accessoryCategoryName,
				value: accessoryCategoryName,
				type: 'select',
				options: [
					{
						label: i18n.global.t('По аксессуарам'),
						value: '',
					},
					{
						label: i18n.global.t('УСН доход'),
						value: 'SIMPLE',
					},
					{
						label: i18n.global.t('Общая'),
						value: 'FULL',
					},
					{
						label: i18n.global.t('Патент'),
						value: 'PATENT',
					},
					{
						label: i18n.global.t('УСН доход-расход'),
						value: 'SIMPLE_IE',
					},
				],
				actions: [{
					fn: () => {},
					action: 'delete',
					icon: 'trash',
					tooltip: i18n.global.t('Удалить'),
				}],
			})
		})
		return fields
	},
	gAccesoriesCategory(state, getters) {
		const accessories = cloneDeep(getters.gAccesories)
		let accessoriesCategories = cloneDeep(state.accessoriesCategories)

		accessories
			.forEach(field => {
				const includedElement = accessoriesCategories.find(element => element.name === field.name)
				accessoriesCategories = includedElement
					? accessoriesCategories.filter(type => type.name !== includedElement.name)
					: accessoriesCategories
			})
		return accessoriesCategories.map(item => ({ name: item.name }))
	},
	gServices(state, _, __, rootGetters) {
		const servesTypes = rootGetters['layout/gTabsData'] && cloneDeep(rootGetters['layout/gTabsData'])
		const services = []

		if (servesTypes.TabTaxScheme && servesTypes.TabTaxScheme.servesTypes) {
			const layoutServesTypes = Object.entries(servesTypes.TabTaxScheme.servesTypes)

			layoutServesTypes.forEach(service => {
				const stateServiceTypes = state.servicesTypes && Object.entries(state.servicesTypes)
				const result = stateServiceTypes.find(item => item[0] === service[0])

				if (result) {
					services.push({
						name: `${result[1].id}`,
						label: result[1].name,
						value: result[1].id,
						type: 'select',
						options: [
							{
								label: i18n.global.t('По услугам'),
								value: '',
							},
							{
								label: i18n.global.t('УСН доход'),
								value: 'SIMPLE',
							},
							{
								label: i18n.global.t('Общая'),
								value: 'FULL',
							},
							{
								label: i18n.global.t('Патент'),
								value: 'PATENT',
							},
							{
								label: i18n.global.t('УСН доход-расход'),
								value: 'SIMPLE_IE',
							},
						],
						actions: [{
							fn: () => {},
							action: 'delete',
							title: `${i18n.global.t('Удалить услугу')}?`,
							description: i18n.global.t('Вы действительно хотите удалить данную услугу?'),
							confirm: i18n.global.t('Да, удалить'),
							cancel: i18n.global.t('Отмена'),
							icon: 'trash',
							tooltip: i18n.global.t('Удалить'),
						}],
					})
				}
			})
		}

		return services
	},
	gServicesTypes(state) {
		const servicesTypes = cloneDeep(state.servicesTypes)
		return Object.values(servicesTypes).map(type => {
			type.price += ',00'
			return type
		})
	},
	gServesTypesGroups(state, getters) {
		const services = getters.gServices

		let servesTypesGroups = cloneDeep(state.servesTypesGroups)
			.map(type => {
				type.userGroups = type.userGroups
					.map(user => {
						let result

						switch (user) {
						case 'PRODUCTION':
							result = i18n.global.t('Сотрудник цеха')
							break
						case 'STORE':
							result = i18n.global.t('Сотрудник магазина')
							break
						case 'OPTOMETRIST':
							result = i18n.global.t('Врач с лицензией')
							break
						case 'DOCTOR':
							result = i18n.global.t('Врач без лицензии')
							break
						default:
							break
						}

						return result
					})
					.join(', ')

				return type
			})

		// Исключаем уже добавленные услуги из списка всех услуг
		services
			.forEach(field => {
				const includedElement = servesTypesGroups.find(element => element.name === field.label)
				servesTypesGroups = includedElement
					? servesTypesGroups.filter(type => type.name !== includedElement.name)
					: servesTypesGroups
			})

		return servesTypesGroups.map(serve => {
			serve.price += ',00'
			return serve
		})
	},
	gSectionsData(state) {
		const data = cloneDeep(state.sections)
		const result = {}

		data.forEach(section => {
			if (section.category === 'GOODS') {
				switch (section.vatType) {
				case 'NON_TAXABLE':
					result['GOODS-NON_TAXABLE'] = `${section.section}`
					break
				case 'PRIVILEGED':
					result['GOODS-PRIVILEGED'] = `${section.section}`
					break
				case 'NORMAL':
					result['GOODS-NORMAL'] = `${section.section}`
					break
				default:
					break
				}
			}

			if (section.category === 'SERVES') {
				switch (section.vatType) {
				case 'NON_TAXABLE':
					result['SERVES-NON_TAXABLE'] = `${section.section}`
					break
				case 'PRIVILEGED':
					result['SERVES-PRIVILEGED'] = `${section.section}`
					break
				case 'NORMAL':
					result['SERVES-NORMAL'] = `${section.section}`
					break
				default:
					break
				}
			}
		})

		return result
	},
	gSectionsFields(state) {
		const sections = state.sections
			.map(section => {
				const cloneSection = cloneDeep(section)
				cloneSection.type = 'input'

				if (cloneSection.category === 'GOODS') {
					switch (cloneSection.vatType) {
					case 'NON_TAXABLE':
						cloneSection.title = i18n.global.t('Для товаров без НДС')
						cloneSection.label = i18n.global.t('Для товаров без НДС')
						cloneSection.name = 'GOODS-NON_TAXABLE'
						break
					case 'PRIVILEGED':
						cloneSection.title = i18n.global.t('Для товаров c НДС 10,0%')
						cloneSection.label = i18n.global.t('Для товаров c НДС 10,0%')
						cloneSection.name = 'GOODS-PRIVILEGED'
						break
					case 'NORMAL':
						cloneSection.title = i18n.global.t('Для товаров c НДС 20,0%')
						cloneSection.label = i18n.global.t('Для товаров c НДС 20,0%')
						cloneSection.name = 'GOODS-NORMAL'
						break
					default:
						break
					}
				}

				if (cloneSection.category === 'SERVES') {
					switch (cloneSection.vatType) {
					case 'NON_TAXABLE':
						cloneSection.title = i18n.global.t('Для услуг без НДС')
						cloneSection.label = i18n.global.t('Для услуг без НДС')
						cloneSection.name = 'SERVES-NON_TAXABLE'
						break
					case 'PRIVILEGED':
						cloneSection.title = i18n.global.t('Для услуг c НДС 10,0%')
						cloneSection.label = i18n.global.t('Для услуг c НДС 10,0%')
						cloneSection.name = 'SERVES-PRIVILEGED'
						break
					case 'NORMAL':
						cloneSection.title = i18n.global.t('Для услуг c НДС 20,0%')
						cloneSection.label = i18n.global.t('Для услуг c НДС 20,0%')
						cloneSection.name = 'SERVES-NORMAL'
						break
					default:
						break
					}
				}

				return cloneSection
			})

		// Кастомная сортировка массива для отображения в нужном виде
		const customSortingArray = [
			'GOODS-NON_TAXABLE',
			'GOODS-PRIVILEGED',
			'GOODS-NORMAL',
			'SERVES-NON_TAXABLE',
			'SERVES-PRIVILEGED',
			'SERVES-NORMAL',
		]

		function customSort(a, s) {
			return a.sort((x1, x2) => {
				const i1 = s.indexOf(x1.name)
				const i2 = s.indexOf(x2.name)
				let result

				if (i1 < 0) result = 1
				else if (i2 < 0) result = -1
				else result = i1 - i2

				return result
			})
		}

		customSort(sections, customSortingArray)

		return sections
	},
	gUnusedDepartments(state) {
		return state.unusedDepartments
			.map(department => ({
				label: department.name,
				value: department.id,
				type: department.type,
			}))
	},
}