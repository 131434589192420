import { http } from '~/kit/plugins/http'
import VuexHttp from '../../vuexHttpService'

const vHttp = new VuexHttp('users')

export default {
	async aUser(_, id) {
		return vHttp.GET(this, {
			url: `users/${id}`,
			mutation: 'mUser',
		})
	},
	async aUserUpdate(_, { id, data, successMessage }) {
		return vHttp.PUT(this, {
			url: {
				path: `users/${id}`,
			},
			data,
			successMessage,
		})
	},
	async aUserUpdatePatronymic(_, { id, patronymic }) {
		return vHttp.PUT(this, {
			url: {
				path: `users/${id}/patronymic`,
			},
			data: {patronymic},
		})
	},
	async aUserDelete(_, id) {
		return vHttp.PUT(this, {
			url: `users/${id}/fire`,
		})
	},
	async aUserRestore(_, id) {
		return vHttp.PUT(this, {
			url: `users/${id}/recover`,
		})
	},
	async aUsers(_, params) {
		return vHttp.GET(this, {
			url: {
				path: 'users',
				query: params,
			},
			mutation: 'mUsers',
		})
	},
	async aUsersNotFired() {
		return vHttp.GET(this, {
			url: 'users/active',
			mutation: 'mUsersNotFired',
		})
	},
	async aUserGroups() {
		return vHttp.GET(this, {
			url: 'user-groups',
			mutation: 'mUserGroups',
		})
	},
	// Запрос групп пользователя с учётом текущего уровня доступа
	async aUsersUserGroups() {
		return vHttp.GET(this, {
			url: 'users/user-groups',
			mutation: 'mUsersUserGroups',
		})
	},
	async aUsersAssignable(_, assignLocation) {
		// /users/assignable?userAssignLocation=SALE
		// [SALE,GLASSES,REPAIR,CONTACT_LENS,CHECK_VISION]
		return vHttp.GET(this, {
			url: `users/assignable?userAssignLocation=${assignLocation}`,
			mutation: 'mUsersAssignable',
		})
	},
	// роли всех пользователей для фильтра в журнале
	async aUsersRoles() {
		return vHttp.GET(this, {
			url: {
				path: `roles`,
			},
			mutation: 'mUsersRoles',
		})
	},
	async aUsersInDepartmentByRoles(_, params) {
		return vHttp.GET(this, {
			url: {
				path: 'departments/current/users',
				query: params,
			},
			mutation: 'mUsersInDepartmentByRoles',
		})
	},
	async aUsersDepartments(_, params) {
		return vHttp.GET(this, {
			url: {
				path: `departments/by-user-group`,
				query: params,
			},
			mutation: 'mUsersDepartments',
		})
	},
	// вызывает aUsersDepartments пока не соберёт все департаменты
	async aUsersDepartmentsAll({ state, dispatch }, params) {
		let response
		const size = 100

		if (!params.page) {
			response = await dispatch('aUsersDepartments', {
				userGroupId: params.userGroupId,
				size,
			})
			params.page = 0
			params.size = size
		}

		if (response?.last) {
			return state.usersDepartments
		}

		params.page += 1
		response = await vHttp.GET(this, {
			url: {
				path: `departments/by-user-group`,
				query: params,
			},
			mutation: 'mUsersDepartmentsAppend',
		})

		if (response.last) {
			return state.usersDepartments
		}

		return await dispatch('aUsersDepartmentsAll', params) //eslint-disable-line
	},


	async aAvailableAssistants() {
		return vHttp.GET(this, {
			url: `users/available/assistant`,
			mutation: 'mAvailableAssistants',
		})
	},

	async aDoctorCategories(_, userGroupId) {
		return vHttp.GET(this, {
			url: {
				path: `doctor/categories`,
				query: {
					userGroupId,
				},
			},
			mutation: 'mDoctorCategories',
		})
	},

	async aMedicalPositions() {
		return vHttp.GET(this, {
			url: {
				path: `users/medical-positions`,
			},
			mutation: 'mMedicalPositions',
		})
	},

	async aCreateUser(_, data) {
		return vHttp.POST(this, {
			url: `users`,
			data,
		})
	},

	async aDownloadCertificate(_, userId) {
		/* if (import.meta.env.VITE_MODE === 'development') {
			return new Blob(['test'], { type: 'text/html' })
		} */
		const data = await http.optima2.get(
			`users/${userId}/certificate`,
			{
				responseType: 'blob',
			}
		)
		return data
	},
}