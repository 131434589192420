export default {
	mStatistics(state, payload) {
		state.confirmedMoneyTransferCount = payload.confirmedMoneyTransferCount
		state.createdMoneyTransferCount = payload.createdMoneyTransferCount
		state.newCancellationCount = payload.newCancellationCount
		state.newTaskCount = payload.newTaskCount

		if (state.confirmedMoneyTransferCount || state.createdMoneyTransferCount) {
			window.triggerOldFrontMethod('account', 'updateUnprocessedCount', state.confirmedMoneyTransferCount, state.createdMoneyTransferCount)
		}
		if (state.newCancellationCount) {
			window.triggerOldFrontMethod('cancellationF', 'updateNewCount', state.newCancellationCount)
		}
		if (state.newTaskCount) {
			window.triggerOldFrontMethod('taskF', 'updateUnreadCount', state.newTaskCount)
		}
	},
}