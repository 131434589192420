
import VuexHttp from '../../vuexHttpService'

const vHttp = new VuexHttp('barcodesOnSheet')

export default {
	async aBarcodesOnSheet() {
		return vHttp.GET(this, {
			url: `settings/barcode-position`,
			mutation: 'mBarcodesOnSheet',
		})
	},
	async aBarcodesOnSheetUpdate(_, data) {
		return vHttp.PUT(this, {
			url: 'settings/barcode-position',
			mutation: 'mBarcodesOnSheet',
			action: 'aBarcodesOnSheet',
			data,
		})
	},
}