import { isMainIframe } from '../iframes'

export function updateMainIframeStyles() {
	if (!isMainIframe()) return

	const legacyHeaderHeight = window.iframeLegacyScreenPositions?.headerHeight || 63
	const layoutEl = document.getElementById('layout-index')

	if (layoutEl) {
		layoutEl.style.paddingTop = (legacyHeaderHeight + 15) + 'px'
	}
}
