import VuexHttp, { ERRORS_PROCESSING_MODES } from '../../vuexHttpService'

const vHttp = new VuexHttp('orders')

export default {
	async aOrderById(_, orderId) {
		return vHttp.GET(this, {
			url: `orders/${orderId}`,
			mutation: 'mOrder',
		})
	},
	async aOrdersCompletedById(_, orderId) {
		return vHttp.GET(this, {
			url: `orders/completed/${orderId}`,
			mutation: 'mOrder',
			errorsProcessing: ERRORS_PROCESSING_MODES.DEFAULT,
		})
	},
	async aInitializeCart(_, params) {
		return vHttp.POST(this, {
			url: {
				path: 'carts',
			},
			data: params,
			mutation: 'mInitializeCart',
		})
	},
	async aCart(_, uid) {
		return vHttp.GET(this, {
			url: `carts/${uid}`,
			mutation: 'mCart',
		})
	},
	async aScanBarcode(_, { uid, data }) {
		return vHttp.POST(this, {
			url: `carts/${uid}/scan`,
			data,
		})
	},
	async aAddGoods(_, { uid, data }) {
		return vHttp.PUT(this, {
			url: `carts/${uid}/goods`,
			data,
			action: 'aCart',
			actionPayload: uid,
		})
	},
	async aDeleteGoods(_, { uid, id }) {
		return vHttp.DELETE(this, {
			url: `carts/${uid}/items/${id}`,
		})
	},
	async aServices(_, uid) {
		return vHttp.GET(this, {
			url: `carts/${uid}/serves/for-sale`,
			mutation: 'mServices',
		})
	},
	async aInitializeServices(_, uid) {
		return vHttp.POST(this, {
			url: `carts/${uid}/serves/for-sale`,
		})
	},
	async aAddService(_, { uid, data }) {
		return vHttp.PUT(this, {
			url: `carts/${uid}/serves/for-sale`,
			data,
			action: 'aServices',
			actionPayload: uid,
		})
	},
	async aUsersByService({ state, commit }, id) {
		if (state.usersByService[id]) {
			return state.usersByService[id]
		}

		const users = await vHttp.GET(this, {
			url: `serves/types/${id}/users`,
		})

		if (users) {
			commit('mUsersByService', {
				id,
				users,
			})
		}

		return users
	},
	async aAddAllServices(_, { uid, data }) {
		return vHttp.PUT(this, {
			url: `carts/${uid}/serves`,
			data,
			action: 'aCart',
			actionPayload: uid,
		})
	},
	async aUnmarkedGoods(_, uid) {
		return vHttp.GET(this, {
			url: `carts/${uid}/goods/unmarked/for-sale`,
			mutation: 'mUnmarkedGoods',
		})
	},
	async aInitializeUnmarkedGoods(_, uid) {
		return vHttp.POST(this, {
			url: `carts/${uid}/goods/unmarked/for-sale`,
		})
	},
	async aUnmarkedGoodsAmount(_, { uid, count, id }) {
		return vHttp.PUT(this, {
			url: `carts/${uid}/goods/unmarked/for-sale/items/${id}`,
			data: {
				count,
			},
			action: 'aCart',
			actionPayload: uid,
		})
	},
	async aUnmarkedDeleteDiscount(_, { uid, id }) {
		return vHttp.DELETE(this, {
			url: `carts/${uid}/goods/unmarked/for-sale/items/${id}/discounts`,
			action: 'aCart',
			actionPayload: uid,
		})
	},
	async aAddAllUnmarkedGoods(_, { uid, data }) {
		return vHttp.PUT(this, {
			url: `carts/${uid}/goods/unmarked`,
			data,
			action: 'aCart',
			actionPayload: uid,
		})
	},
	async aScanCertificate(_, params) {
		return vHttp.GET(this, {
			url: {
				path: `gift-certificates/scan`,
				query: {
					...params,
				},
			},
			// successMessage: i18n.global.t('Подарочный сертификат добавлен'),
			errorMessage: false,
		})
	},
	aGiftCertificatesStatuses(_, query) {
		return vHttp.GET(this, {
			url: {
				path: 'gift-certificates/statuses',
				query,
			},
			mutation: 'mGiftCertificatesStatuses',
		})
	},
	aGiftCertificateRemove(_, { comment, id }) {
		return vHttp.DELETE(this, {
			url: `gift-certificates/${id}`,
			data: { comment },
		})
	},
	aMakeGiftCertificateUsed(_, id) {
		return vHttp.PUT(this, {
			url: {
				path: `gift-certificates/${id}`,
				query: {
					id,
				},
			},
		})
	},
	async aAddCertificate(_, { uid, data }) {
		return vHttp.PUT(this, {
			url: `carts/${uid}/gift-certificates`,
			data,
			action: 'aCart',
			actionPayload: uid,
		})
	},
	async aCartsDiscounts() {
		return vHttp.GET(this, {
			url: 'carts/discounts?deleted=false',
			mutation: 'mCartsDiscounts',
		})
	},
	async aAddDiscount(_, { uid, discountId }) {
		return vHttp.PUT(this, {
			url: `carts/${uid}/discounts/${discountId}`,
			action: 'aCart',
			actionPayload: uid,
		})
	},
	async aAddExtraDiscount(_, { uid, data }) {
		return vHttp.PUT(this, {
			url: `carts/${uid}/discounts/extra`,
			data,
			action: 'aCart',
			actionPayload: uid,
		})
	},

	async aDeleteDiscount(_, { uid, cartItemIds }) {
		const urlSearchParams = new URLSearchParams()
		cartItemIds.forEach(param => urlSearchParams.append('cartItemIds', param))
		return vHttp.DELETE(this, {
			url: `carts/${uid}/discounts/items`,
			options: {
				params: urlSearchParams,
			},
			action: 'aCart',
			actionPayload: uid,
		})
	},

	async aDeleteGeneralDiscount(_, { uid, discountsType }) {
		const url = discountsType === 'discount'
			? `carts/${uid}/discounts`
			: `carts/${uid}/discounts/extra`

		return vHttp.DELETE(this, {
			url,
			action: 'aCart',
			actionPayload: uid,
		})
	},

	async aAddBonusCard(_, { uid, cardId }) {
		return vHttp.POST(this, {
			url: `carts/${uid}/bonus-card/${cardId}`,
		})
	},

	async aDeleteBonusCard(_, uid) {
		return vHttp.DELETE(this, {
			url: `carts/${uid}/bonus-card`,
		})
	},

	async aUpdateBonusMode(_, { uid, data }) {
		return vHttp.PUT(this, {
			url: `carts/${uid}/bonus-card/mode`,
			data,
			action: 'aCart',
			actionPayload: uid,
		})
	},

	async aCartTerminate(_, { uid, payments, giftCertificatesNumbers, orderNotes, main }) {
		return vHttp.PUT(this, {
			url: `carts/${uid}/terminate`,
			data: {
				payment: {
					main,
					payments,
					giftCertificatesNumbers,
				},
				orderComment: orderNotes?.orderComment,
				firstOrderReason: orderNotes?.firstOrderReason,
				secondOrderReason: orderNotes?.secondOrderReason,
			},
		})
	},

	async aDeleteServiceDiscount(_, { uid, id }) {
		return vHttp.DELETE(this, {
			url: `carts/${uid}/serves/for-sale/items/${id}/discounts`,
			action: 'aServices',
			actionPayload: uid,
		})
	},

	async aTableItemCount(_, { uid, id, data }) {
		return vHttp.PUT(this, {
			url: `carts/${uid}/items/${id}/count`,
			data,
			action: 'aCart',
			actionPayload: uid,
		})
	},

	async aTableItemCountServes(_, { uid, id, data }) {
		return vHttp.PUT(this, {
			url: `carts/update-serves/${uid}/items/${id}/count`,
			data,
			action: 'aCart',
			actionPayload: uid,
		})
	},

	async aInfoFieldsSettings() {
		return vHttp.GET(this, {
			url: `settings/orders-reasons`,
			mutation: 'aInfoFieldsSettings',
		})
	},
	async aInfoFieldsOptions() {
		return vHttp.GET(this, {
			url: `orders/reasons?orderType=SALE`,
			mutation: 'mInfoFieldsOptions',
		})
	},
	aOrderExtrasById(_, orderId) {
		return vHttp.GET(this, {
			url: `order/${orderId}/extras`,
			mutation: 'mOrderExtrasById',
		})
	},
	aOrderExtrasGroupById(_, orderId) {
		return vHttp.GET(this, {
			url: `group-order/${orderId}/extras`,
			mutation: 'mOrderExtrasGroupById',
		})
	},
	aCLPrescriptions(_, clientId) {
		return vHttp.GET(this, {
			url: `clients/${clientId}/prescription/contact-lens`,
			mutation: 'mCLPrescriptions',
		})
	},
	aCLNomenclature() {
		return vHttp.GET(this, {
			url: 'nomenclature/contactlenses',
			mutation: 'mCLNomenclature',
		})
	},
	aReceiptRecommendedModels() {
		return vHttp.GET(this, {
			url: {
				path: 'doctor-dictionary/recommended-models/list',
				query: {
					size: 10000,
				},

			},
			mutation: 'mReceiptRecommendedModels',
		})
	},
	aCreateCLPrescription(_, { uid, data }) {
		return vHttp.POST(this, {
			url: `carts/${uid}/prescription/contact-lens`,
			data,
		})
	},
	aOrderServices(_, orderId) {
		return vHttp.GET(this, {
			url: `orders/${orderId}/serves/types`,
			mutation: 'mOrderServices',
		})
	},
	aServicesCartUuid(_, orderId) {
		return vHttp.POST(this, {
			url: 'carts/update-serves',
			options: {
				params: { orderId },
			},
			mutation: 'mServicesCartUuid',
		})
	},
	aAddServiceInCart(_, { cartUuid, serveTypeId }) {
		return vHttp.POST(this, {
			url: `carts/${cartUuid}/add-serve`,
			options: {
				params: { serveTypeId },
			},
			action: 'aCart',
			actionPayload: cartUuid,
		})
	},
	aRemoveServiceFromCart(_, { cartUuid, cartItemId }) {
		return vHttp.DELETE(this, {
			url: `carts/${cartUuid}/serves/${cartItemId}`,
			action: 'aCart',
			actionPayload: cartUuid,
		})
	},
	aServicesDiscounts() {
		return vHttp.GET(this, {
			url: 'serve/discounts?deleted=false',
			mutation: 'mServicesDiscounts',
		})
	},
	aSaveServices(_, { cartUuid, comment }) {
		return vHttp.PUT(this, {
			url: `carts/update-serves/${cartUuid}/terminate`,
			data: {
				comment,
			},
		})
	},
}