export default {
	mIntegratedSuplier(state, payload) {
		state.integratedSupplier = payload
	},
	mIntegratedSuppliers(state, payload) {
		state.integratedSuppliers = payload
	},
	mLegalEntities(state, payload) {
		state.legalEntities = payload.content
	},
	mSettings(state, payload) {
		state.settings = payload
	},
}