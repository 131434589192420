<!-- eslint-disable vue/no-v-html -->
<template>
	<div class="part-field-view mb-3 d-flex align-items-center">
		<span
			v-if="showLabel"
			class="flex-shrink com-field__label--read-mode"
			:style="{
				width: labelWidth,
			}"
		>
			<span
				v-if="labelColorDot"
				class="com-field__label-color-dot"
				:style="'background-color:' + labelColorDot"
			/>
			{{ label }}
		</span>

		<div
			class="w-100p d-flex align-items-center com-field__values--read-mode pb-2"
		>
			<template
				v-for="(tag, index) in tags"
				:key="index"
			>
				<el-tag
					type="info"
					size="default"
					class="com-field__value-tag border-radius-round mr-1 mb-1"
					:data-ta="`tag_${tag.value}`"
					:class="tag.class"
					@click="onTagClick(tag)"
				>
					<ItIcon
						v-if="tag.icon"
						:name="tag.icon"
						width="16"
						height="16"
						class="com-field__value-tag-icon"
					/>
					<span
						v-if="isHtml"
						class="com-field__value-tag-label--html"
						v-html="tag.label"
					/>
					<span
						v-else
						class="com-field__value-tag-label--text"
					>
						{{ tag.label }}
					</span>
				</el-tag>
			</template>


			<span
				v-if="infoTooltip"
				style="margin-left: -6px; margin-bottom: 12px;"
			>
				<PartInfoTooltip
					:info-tooltip="infoTooltip"
				/>
			</span>
		</div>
	</div>
</template>

<script>

import PartInfoTooltip from './PartInfoTooltip'

export default {
	name: 'PartFieldView',
	components: {
		PartInfoTooltip,
	},
	props: {
		modelValue: {
			type: [String, Number, Boolean, Array],
			default: '',
		},
		options: {
			type: Array,
			default: null,
		},
		name: {
			type: String,
			required: true,
		},
		showLabel: {
			type: Boolean,
			default: true,
		},
		label: {
			type: String,
			default: '',
		},
		labelWidth: {
			type: String,
			default: '40%',
		},
		labelColorDot: {
			type: String,
			default: null,
		},
		linkPattern: {
			type: String,
			default: null,
		},
		isHtml: {
			type: Boolean,
			default: false,
		},
		onClick: {
			type: Function,
			default: null,
		},
		infoTooltip: {
			type: Object,
			default: null,
		},
	},
	computed: {
		tags() {
			const values = Array.isArray(this.modelValue) ? this.modelValue : [this.modelValue]

			const tags = values.map(value => {
				const optionValue = this.options?.find(option => {
					if (option.value === (value?.value ?? '')) {
						return option
					}
					if (option.value === (value?.value || value)) {
						return option
					}
					return false
				})

				// select
				if (optionValue) {
					return {
						label: optionValue.viewLabel || optionValue.label || '—',
						link: this.linkPattern?.replace('%value%', optionValue.value), // используется в департаментах?
						class: this.linkPattern ? 'color-primary cursor-pointer' : 'color-regular',
						icon: optionValue.icon,
						value,
					}
				}

				return {
					label: value?.label || value || '—',
					link: Boolean(this.onClick),
					onClick: this.onClick,
					class: this.onClick ? 'color-primary cursor-pointer' : 'color-regular',
					value: value?.value || value,
				}
			})

			if (!tags.length) {
				return [{ label: '—' }]
			}

			return tags
		},
	},
	methods: {
		onTagClick(tag) {
			if (tag.onClick) {
				tag.onClick()
			} else if (tag.link) {
				this.$router.push(tag.link)
			}
		},
	},
}
</script>

<style lang="scss">
.part-field-view {
	font-size: var(--el-form-label-font-size);

	.com-field__label-color-dot {
		width: 8px;
		height: 8px;
		border-radius: 50%;
		display: inline-block;
		margin-right: 8px;
	}

	.com-field__values--read-mode {
		margin-bottom: -12px;
		flex-wrap: wrap;
	}

	.el-tag__content {
		display: flex;
		align-items: center;

		p {
			font-size: var(--el-tag-font-size);
		}

		.it-icon {
			margin-right: 8px;
			line-height: 1;
		}
	}
}
</style>