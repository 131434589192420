<template>
	<div
		v-loading="isPageLoading"
		class="order-fast-repair"
		data-ta="order-fast-repair-main-container"
	>
		<PartOrderHeader
			:title="orderTitle"
			:optima-help="optimaHelpTooltip"
			:has-return-button="true"
			@on-return="onReturn"
		/>

		<PartOrderBarcode @addGoods="addGoods"/>

		<div
			class="order-fast-repair__filling-buttons grid-2"
		>
			<PartOrderServices/>
			<PartOrderUnmarkedGoods/>
		</div>

		<PartOrderGoodsTable
			v-loading="isCartLoading"
			@addGoods="addGoods"
		/>

		<section class="order-payment-section">
			<PartOrderAdditionalInfo/>
			<PartOrderTotal
				@return="onReturn"
				@submit-payments="onSubmitPayments"
			/>
		</section>
	</div>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
import soundsEffects from '~/utils/soundsEffects.js'
import confirmBeforeLeave from '~/plugins/router/hooks/confirmBeforeLeave'
import PartOrderGoodsTable from './parts/PartOrderGoodsTable.vue'
import PartOrderHeader from './parts/PartOrderHeader.vue'
import PartOrderBarcode from './parts/PartOrderBarcode.vue'
import PartOrderServices from './parts/PartOrderServices.vue'
import PartOrderUnmarkedGoods from './parts/PartOrderUnmarkedGoods.vue'
import PartOrderAdditionalInfo from './parts/PartOrderAdditionalInfo.vue'
import PartOrderTotal from './parts/PartOrderTotal.vue'

export default {
	name: 'OrderFastRepair',
	components: {
		PartOrderHeader,
		PartOrderBarcode,
		PartOrderServices,
		PartOrderUnmarkedGoods,
		PartOrderGoodsTable,
		PartOrderAdditionalInfo,
		PartOrderTotal,
	},

	provide() {
		return {
			setCartLoading: this.setCartLoading,
		}
	},

	async beforeRouteUpdate(to, from, next) {
		if (this.$store.state.isIframeHid) return next(true)
		if (this.confirmLeaveParams.skipConfirm) return next(true)
		const res = await confirmBeforeLeave.call(this, this.confirmLeaveParams)
		return next(Boolean(res))
	},

	async beforeRouteLeave(to, from, next) {
		if (this.$store.state.isIframeHid) return next(true)
		if (this.confirmLeaveParams.skipConfirm) return next(true)
		const res = await confirmBeforeLeave.call(this, this.confirmLeaveParams)
		return next(Boolean(res))
	},

	data() {
		return {
			orderType: 'repairOrder',
			isPageLoading: false,
			orderTitle: this.$t('Ремонт'),
			browserTitle: this.$t('Ремонт: выбор товаров и услуг'),
			isCartLoading: false,
			optimaHelpTooltip: {
				text: this.$t('Как оформить заказ Ремонт?'),
				href: 'https://optimahelp.itigris.ru/m/56364/l/538133-',
			},
			confirmLeaveParams: {
				title: `${this.$t('Отменить ремонт')}?`,
				message: this.$t('Данные будут потеряны'),
				confirmButtonText: this.$t('Да'),
				cancelButtonText: this.$t('Нет'),
				customClass: 'confirm-popup--order',
				skipConfirm: false,
			},
		}
	},
	computed: {
		...mapState('client', ['clientInfo']),
		...mapGetters('client', ['gAnonimusClient']),
		...mapGetters('orders', [
			'gCartInfo',
			'gCartItems',
		]),
		...mapState('orders', [
			'cartUid',
			'cart',
		]),
		...mapState('users', ['selectedSeller']),
	},
	watch: {
		'selectedSeller.id': 'loadData',
	},
	beforeUnmount() {
		this.mClientInfo({})
		this.mResetOrder()
	},
	mounted() {
		this.init()
		if (this.selectedSeller?.id) {
			this.loadData()
		}
	},
	methods: {
		...mapMutations('client', ['mClientInfo']),
		...mapMutations('orders', [
			'mResetOrder',
			'mOrderType',
		]),
		...mapActions('client', ['aClientInfo']),
		...mapActions('orders', [
			'aInitializeCart',
			'aCart',
			'aDeleteGoods',
			'aAddGoods',
			'aInitializeServices',
			'aInitializeUnmarkedGoods',
			'aServices',
			'aUnmarkedGoods',
		]),
		...mapActions('good', ['aBarcodesLengths']),
		...mapActions('router', [
			'aReturn',
		]),

		init() {
			this.mOrderType(this.orderType)
			this.setDocumentTitle(this.browserTitle)
		},

		async loadData() {
			this.isPageLoading = true
			const clientId = Number(this.$route.params.id)

			await this.aInitializeCart({
				clientId,
				userId: this.selectedSeller?.id,
				orderType: 'REPAIR',
			})

			const requests = [
				this.aClientInfo(clientId),
				this.aBarcodesLengths(),
				this.aCart(this.cartUid),
				this.aInitializeServices(this.cartUid),
				this.aInitializeUnmarkedGoods(this.cartUid),
			]

			await Promise.all(requests)
			await Promise.all([
				this.aUnmarkedGoods(this.cartUid),
				this.aServices(this.cartUid),
			])

			this.isPageLoading = false

			return true
		},

		async addGoods({ item, barcode, isScan = false }) {
			this.isCartLoading = true
			const data = {
				barcode,
				count: item.currentQuantity || null,
			}
			const response = await this.aAddGoods({ uid: this.cartUid, data })

			if (!response) soundsEffects('barcode-scan-error.wav')

			this.isCartLoading = false

			if (response.status === 200) {
				if (isScan) soundsEffects('barcode-scan-ok.wav')
				this.$notify({ title: this.$t('Добавлено в корзину'), type: 'success' })
			}
		},

		async onReturn() {
			const defaultReturn = {
				name: 'ClientsJournal',
				query: {
					orderType: 'RepairOrder',
				},
			}
			this.aReturn({
				returnForce: defaultReturn,
			})
		},

		openOrderClientInfo() {
			if (!this.isAnonimus) {
				window.userInfo(this.$route.params.id, 'order-sale')
			}
		},

		async onSubmitPayments(orderId) {
			if (orderId) {
				const previousOrdersSum = this.clientInfo.ordersSum ?? null
				let finishPage = `saleOrderLegacy/finalPage?id=${orderId}`
				if (previousOrdersSum !== null) {
					finishPage += `&previousOrdersSum=${previousOrdersSum}`
				}
				this.mResetOrder()

				// Добавлено для сброса заказа при переходе в легаси
				this.mClientInfo({})
				this.mResetOrder()
				this.confirmLeaveParams.skipConfirm = true
				this.$router.push('/order-completion')

				window.loadLinkSimple(finishPage)
			}
		},

		setCartLoading(val) {
			this.isCartLoading = val
		},
	},
}
</script>

<style lang="scss">

.order-fast-repair {
	font-size: 12px !important;
	height: 950px;

	.order-payment-section {
		margin: 32px 0 0 4px;
		display: grid;
		grid-template-columns: 1fr 315px;
		gap: 16px;
	}

	&__filling-buttons {
		display: grid;
		gap: 16px;
		margin-top: 16px;
	}

	&__client-btn {
		display: flex;
		align-items: center;
		cursor: pointer;

		&--disabled {
			cursor: default;
		}
	}
}
</style>