<template>
	<div
		:data-ta="`date-separated_${name}`"
		class="com-field-item com-field-date-separated"
	>
		<PartFieldView
			v-if="!isEdit"
			v-model="viewValue"
			:name="name"
			:label="label"
			:label-width="labelWidth"
			:label-color-dot="labelColorDot"
			:link-pattern="linkPattern"
			:show-label="showLabel"
			:is-html="isHtml"
			:on-click="onClick"
			:info-tooltip="infoTooltip"
		/>

		<el-form-item
			v-else
			:ref="name"
			:prop="name"
			:label="label"
			:label-width="labelWidth"
		>

			<PartInfoTooltip
				v-if="infoLabelTooltip"
				:info-tooltip="infoLabelTooltip"
				label-tooltip
			/>

			<div class="d-flex">
				<el-select
					v-model="model.day"
					:placeholder="$t('дд')"
					:size="size"
					:disabled="disabled || readonly"
					popper-class="ta-select-dropdown"
					class="mr-2"
					filterable
					clearable
				>
					<el-option
						v-for="day in dateDays"
						:key="day"
						:value="day"
					/>
				</el-select>

				<el-date-picker
					v-model="model.month"
					:placeholder="$t('мм')"
					:size="size"
					:disabled="disabled || readonly"
					:fallback-placements="['bottom']"
					type="month"
					format="MMM"
					value-format="M"
					class="mr-2"
					popper-class="no-header"
				/>

				<el-date-picker
					v-model="model.year"
					:disabled-date="fieldDatepickerCheckDisabledDate"
					:picker-options="{
						firstDayOfWeek: 1,
					}"
					:size="size"
					:disabled="disabled || readonly"
					:fallback-placements="['bottom']"
					type="year"
					format="YYYY"
					value-format="YYYY"
					:placeholder="$t('гггг')"
				/>

				<PartInfoTooltip
					v-if="infoTooltip"
					:info-tooltip="infoTooltip"
				/>

			</div>
		</el-form-item>
	</div>
</template>

<script>
import defaultProps from './defaultFieldsProps'
import PartFieldView from './parts/PartFieldView'
import PartInfoTooltip from './parts/PartInfoTooltip'

export default {
	name: 'ComFieldDateSeparated',
	components: {
		PartFieldView,
		PartInfoTooltip,
	},
	props: {
		...defaultProps,
		modelValue: {
			type: [String, Object],
			default: null,
		},
		format: {
			type: String,
			default: '',
		},
		minDate: {
			type: [String, Object],
			default: '',
		},
		maxDate: {
			type: [String, Object],
			default: '',
		},
	},
	data() {
		return {
			model: {
				day: null,
				month: null,
				year: null,
			},
			dateDays: new Array(31).fill(0).map((_, i) => String(i + 1)),
		}
	},
	computed: {
		viewValue() {
			return this.modelValue || '—'
		},
	},
	watch: {
		modelValue: {
			immediate: true,
			handler(val) {
				if (!val) return
				this.model = {
					day: String(val.day || '') || null,
					month: String(val.month || '') || null,
					year: String(val.year || '') || null,
				}
			},
		},
		'model.day': 'dateUpdated',
		'model.month': 'dateUpdated',
		'model.year': 'dateUpdated',
	},
	methods: {
		dateUpdated() {
			this.$emit('update:modelValue', this.model)
			this.$el.click() // эмуляция клика для срабатывания проверки изменения данных при очистве поля
		},
		fieldDatepickerCheckDisabledDate(date) {
			if (this.maxDate && date.getTime() > Date.parse(this.maxDate)) return true
			return this.minDate && date < Date.parse(this.minDate)
		},
	},
}
</script>

<style lang="scss">
.com-field-date-separated {
	.el-date-picker.no-header {
		.el-date-picker__header {
			display: none;
		}

		.el-picker-panel__content {
			margin-top: 15px;
		}
	}

	.el-select, .el-date-editor.el-input {
		width: 33%;
	}
}
</style>