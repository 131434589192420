import i18n from '~/plugins/i18n'

export const STATUS_CREATED = 'CREATED'
export const STATUS_USED = 'USED'
export const STATUS_CANCELED = 'CANCELED'
export const STATUS_PARTLY_USED = 'PARTLY_USED'
export const STATUS_WITHOUT_CANCELED = 'WITHOUT_CANCELED'
export const STATUS_RETURNED = 'RETURNED'

export const statusLabels = Object.freeze({
	[STATUS_CREATED]: i18n.global.t('Создан'),
	[STATUS_USED]: i18n.global.t('Использован'),
	[STATUS_CANCELED]: i18n.global.t('Отменен'),
	[STATUS_PARTLY_USED]: i18n.global.t('Частично использован'),
	[STATUS_RETURNED]: i18n.global.t('Возвращён'),
})

export const statusLabelsFilterOptions = Object.freeze({
	...statusLabels,
	[STATUS_WITHOUT_CANCELED]: i18n.global.t('Без отмененных'),
})