import { isMainIframe } from '../iframes'

export default function updateElNotificationIframeStyles() {
	const iframeScreen = window.iframeLegacyScreenPositions
	if (!iframeScreen) return

	const shift = {
		top: 0,
		right: 0,
	}
	
	if (isMainIframe()) {
		shift.top = 110
		shift.right = iframeScreen.horizontalMargins
	}

	const notifications = document.getElementsByClassName('el-notification')
	const top = Math.max(0, iframeScreen.windowScrollY - iframeScreen.iframeOffsetTop) + shift.top

	Array.from(notifications).forEach(notification => {
		notification.style.marginTop = `${top}px`
		notification.style.marginRight = `${shift.right}px`
	})
}