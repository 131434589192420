export default function () {
	return {
		accessoriesCategories: [],
		accessoriesModels: [],
		contactLensManufacturers: [],
		contactLensNames: [],
		glassesManufacturers: [],
		glassesBrands: [],
		glassesBrandsByManufacturer: [],
		lensManufacturers: [],
		lensBrands: [],
		lensBrandsByManufacturer: [],
		sunglassesManufacturers: [],
		sunglassesBrands: [],
		sunglassesBrandsByManufacturer: [],
		certificatesGoodsTypes: [],
		certificateById: null,
	}
}