<template>
	<div
		:data-ta="`phone-popover_${name}`"
		class="com-field-item com-field-phone-popover"
	>
		<PartFieldView
			v-if="!isEdit"
			v-model="viewValue"
			:name="name"
			:label="label"
			:label-width="labelWidth"
			:label-color-dot="labelColorDot"
			:link-pattern="linkPattern"
			:show-label="showLabel"
			:is-html="isHtml"
			:on-click="onClick"
			:info-tooltip="infoTooltip"
		/>

		<el-form-item
			v-else
			:ref="name"
			:prop="name"
			:label="label || title"
			:label-width="labelWidth"
		>
			<div class="d-flex align-items-center w-100p">
				<el-input
					v-if="visibleMode === 'input'"
					v-model="model"
					:placeholder="placeholder"
					:data-ta="name"
					:disabled="true"
					:size="size"
				/>

				<el-tag
					v-else
					:data-ta="`tag_${name}`"
					type="info"
					size="default"
					class="border-radius-round color-regular"
				>{{ model || '—' }}</el-tag>


				<PartPhonePopover
					v-if="popover"
					:name="name"
					:size="size"
					:clearable="clearable"
					:popover="popover"
					:rules="rules"
					:max-length="maxLength"
					@acceptEdit="onAcceptEdit"
				/>
			</div>
		</el-form-item>
	</div>
</template>

<script>
import defaultProps from './defaultFieldsProps'
import PartFieldView from './parts/PartFieldView'
import PartPhonePopover from './parts/PartPhonePopover'

export default {
	name: 'ComFieldPhonePopover',
	components: {
		PartFieldView,
		PartPhonePopover,
	},
	props: {
		...defaultProps,
		format: {
			type: String,
			default: '',
		},
		mask: {
			type: String,
			default: '',
		},
		popover: {
			type: Object,
			required: true,
		},
		acceptFn: {
			type: Function,
			default: null,
		},
		rules: {
			type: Array,
			default: null,
		},
		visibleMode: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			newPhone: '',
			error: null,
		}
	},
	computed: {
		model: {
			get() { return this.modelValue },
			set(value) {
				this.$emit('update:modelValue', value)
			},
		},
		viewValue() {
			return this.modelValue || '—'
		},
	},
	methods: {
		async onAcceptEdit(newPhone, callback = () => {}) {
			let result = true
			if (this.acceptFn) {
				result = await this.acceptFn(newPhone)
			}
			if (result) {
				this.model = newPhone
				await this.$nextTick()
				callback()
			}
		},
	},
}
</script>

<style lang="scss">
.com-field-phone-popover {
	&__action-btn {
		padding-left: 8px;
		padding-right: 8px;
	}
}
.popover-error {
	top: auto;
	left: auto;
	padding: 0;
	margin-top: -12px;
}
</style>