export function onEdit() {
	if (this.tabsMethods.beforeEdit) {
		this.tabsMethods.beforeEdit()
	}

	this.componentsMethods.edit()
	this.mSetEditState(true)
}

export async function onCancel() {
	if (this.tabsMethods.beforeCancel) {
		await this.tabsMethods.beforeCancel()
	}

	if (this.isChanged) {
		const cancelEdit = await this.mxCancelFormEdit()

		if (cancelEdit) {
			await this.componentsMethods.mxInit()
			await this.componentsMethods.cancel()
			this.mSetChangedState(false)
		}
	}
	this.mSetEditState(false)

	if (this.tabsMethods.cancelled) {
		await this.tabsMethods.cancelled()
	}
}

export async function onDelete() {
	if (this.tabsMethods.beforeDelete) {
		await this.tabsMethods.beforeDelete()
	}

	this.isDeletionProceed = true
	const deleteResult = await this.componentsMethods.delete()
	setTimeout(() => {
		this.isDeletionProceed = false
	}, 500)

	if (deleteResult) {
		if (this.layoutParams.deleteSuccessMessage) {
			this.$notify({ title: this.layoutParams.deleteSuccessMessage, type: 'success' })
		}
		this.mSetEditState(false)
		this.mSetChangedState(false)
		this.mSetDeletedState(true)
		if (this.tabsMethods.deleted) {
			await this.tabsMethods.deleted()
		}
	} else if (this.layoutParams.deleteErrorMessage) {
		this.$notify({ title: this.layoutParams.deleteErrorMessage, type: 'error' })
	}
}

export async function onRestore() {
	if (this.tabsMethods.beforeRestore) {
		await this.tabsMethods.beforeRestore()
	}

	this.isRestoreProceed = true
	const restoreResult = await this.componentsMethods.restore()
	setTimeout(() => {
		this.isRestoreProceed = false
	}, 500)

	if (restoreResult) {
		if (this.layoutParams.restoreSuccessMessage) {
			this.$notify({ title: this.layoutParams.restoreSuccessMessage, type: 'success' })
		}
		this.mSetEditState(false)
		this.mSetChangedState(false)
		this.mSetDeletedState(false)
		if (this.tabsMethods.restored) {
			await this.tabsMethods.restored()
		}
	} else if (this.layoutParams.restoreErrorMessage) {
		this.$notify({ title: this.layoutParams.restoreErrorMessage, type: 'error' })
	}
}

export async function onSave() {
	const validationState = await this.validateTabs()

	if (!validationState) {
		return
	}

	this.isSavingProceed = true
	const saveResult = await this.componentsMethods.save()
	setTimeout(() => {
		this.isSavingProceed = false
	}, 500)

	if (saveResult === undefined) {
		throw new Error('Ф-я save() должна возвращать результат сохранения')
	}

	if (saveResult) {
		if (this.layoutParams.saveSuccessMessage) {
			this.$notify({ title: this.layoutParams.saveSuccessMessage, type: 'success' })
		}

		this.mSetEditState(false)
		this.mSetChangedState(false)
		this.resetLayout()
		this.$nextTick(() => {
			this.componentsMethods.saved && this.componentsMethods.saved()
		})
	} else if (this.layoutParams.saveErrorMessage) {
		this.$notify({ title: this.layoutParams.saveErrorMessage, type: 'error' })
	}
}

export async function onReturn() {
	if (this.componentsMethods?.return) {
		this.componentsMethods.return()
	} else {
		await this.aReturn(this.layoutParams)
	}
	this.mLayoutDropState()
}
