export default {
	gAccessories(state) {
		const accessories = {}
		const commonTab = state.barcodesSettings
			.find(item => item.goodsType === 'Accessories')

		const additionalTab = state.barcodesSettings
			.find(item => item.goodsType === 'AccessoriesSpecial1')

		accessories.common = commonTab || {}

		accessories.additional = additionalTab || {}


		return accessories
	},
	gContactLenses(state) {
		const contactLenses = {}
		const commonTab = state.barcodesSettings
			.find(item => item.goodsType === 'ContactLens')

		const additionalTab = state.barcodesSettings
			.find(item => item.goodsType === 'ContactLensSpecial1')

		contactLenses.common = commonTab || {}

		contactLenses.additional = additionalTab || {}


		return contactLenses
	},
	gLenses(state) {
		const lenses = {}
		const commonTab = state.barcodesSettings
			.find(item => item.goodsType === 'Lens')

		const additionalTab = state.barcodesSettings
			.find(item => item.goodsType === 'LensSpecial1')

		lenses.common = commonTab || {}

		lenses.additional = additionalTab || {}


		return lenses
	},
	gGlasses(state) {
		const glasses = {}
		const commonTab = state.barcodesSettings
			.find(item => item.goodsType === 'Glasses')

		const additionalTab = state.barcodesSettings
			.find(item => item.goodsType === 'GlassesSpecial1')

		glasses.common = commonTab || {}

		glasses.additional = additionalTab || {}


		return glasses
	},
	gExtra(state) {
		const extra = {}
		const commonTab = state.barcodesSettings
			.find(item => item.goodsType === 'Extras')

		const additionalTab = state.barcodesSettings
			.find(item => item.goodsType === 'ExtrasSpecial1')

		extra.common = commonTab || {}

		extra.additional = additionalTab || {}


		return extra
	},
	gReadyGlasses(state) {
		const readyGlasses = {}
		const commonTab = state.barcodesSettings
			.find(item => item.goodsType === 'ReadyGlasses')

		const additionalTab = state.barcodesSettings
			.find(item => item.goodsType === 'ReadyGlassesSpecial1')

		readyGlasses.common = commonTab || {}

		readyGlasses.additional = additionalTab || {}


		return readyGlasses
	},
	gSemifinished(state) {
		const semifinished = {}
		const commonTab = state.barcodesSettings
			.find(item => item.goodsType === 'SemifinishedLens')

		const additionalTab = state.barcodesSettings
			.find(item => item.goodsType === 'SemifinishedLensSpecial1')

		semifinished.common = commonTab || {}

		semifinished.additional = additionalTab || {}


		return semifinished
	},
	gSunglasses(state) {
		const sunglasses = {}
		const commonTab = state.barcodesSettings
			.find(item => item.goodsType === 'Sunglasses')

		const additionalTab = state.barcodesSettings
			.find(item => item.goodsType === 'SunglassesSpecial1')

		sunglasses.common = commonTab || {}

		sunglasses.additional = additionalTab || {}


		return sunglasses
	},
}