import { defineAsyncComponent } from 'vue'

export default [
	{
		name: 'GlassesProductionServiceVAT',
		path: '/settings/glasses-production-service-vat',
		meta: {
			title: 'Ставка НДС на изготовление очков с переменной стоимостью',
		},
		component: defineAsyncComponent(() => import('~/pages/settings/GlassesProductionServiceVAT')),
	},
	{
		name: 'N3healthIntegrationLegacyPopup',
		path: '/popups/n3health-integration/:orderId',
		component: () => import('~/pages/popups/N3healthIntegrationLegacyPopup'),
	},
	{
		name: 'DocumentsSelectPopup',
		path: '/popups/documents',
		component: () => import('~/pages/popups/DocumentsSelectPopup'),
	},
	{
		name: 'DocumentsPrintPopup',
		path: '/popups/documents/print',
		component: () => import('~/pages/popups/DocumentsPrintPopup'),
	},
	{
		name: 'ClientAgreementTypesSelector',
		path: '/client/:clientId/client-agreement-type-selector',
		meta: {
			title: 'СОПД выбор',
		},
		component: defineAsyncComponent(
			() => import('~/pages/clients-agreements/ClientAgreementTypesSelector/ClientAgreementTypesSelector')
		),
	},
	{
		name: 'ClientAgreementPaper',
		path: '/client/:clientId/client-agreement-paper',
		meta: {
			title: 'СОПД бумажный вариант',
		},
		component: defineAsyncComponent(
			() => import('~/pages/clients-agreements/ClientAgreementPaper/ClientAgreementPaper')
		),
	},
	{
		name: 'ClientAgreementSms',
		path: '/client/:clientId/client-agreement-sms',
		meta: {
			title: 'СОПД СМС',
		},
		component: defineAsyncComponent(
			() => import('~/pages/clients-agreements/ClientAgreementSms/ClientAgreementSms')
		),
	},
	{
		name: 'ClientAgreementEmail',
		path: '/client/:clientId/client-agreement-email',
		meta: {
			title: 'СОПД Email',
		},
		component: defineAsyncComponent(
			() => import('~/pages/clients-agreements/ClientAgreementEmail/ClientAgreementEmail.vue')
		),
	},
	{
		name: 'ClientAgreementVk',
		path: '/client/:clientId/client-agreement-vk',
		meta: {
			title: 'СОПД ВК',
		},
		component: defineAsyncComponent(
			() => import('~/pages/clients-agreements/ClientAgreementVk/ClientAgreementVk.vue')
		),
	},
	{
		name: 'ClientAgreementReject',
		path: '/client/:clientId/client-agreement-reject',
		meta: {
			title: 'СОПД Отклонить',
		},
		component: defineAsyncComponent(
			() => import('~/pages/clients-agreements/ClientAgreementReject/ClientAgreementReject.vue')
		),
	},
	{
		name: 'ClientAgreementAlcon',
		path: '/client/:id/alcon-loyality-program',
		meta: {
			title: 'СОПД Алкон',
		},
		component: defineAsyncComponent(() => import('~/pages/clients-agreements/ClientAgreementAlcon/ClientAgreementAlcon')),
	},
]