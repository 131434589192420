const API_KEY = import.meta.env.VITE_DADATA_TOKEN

const ADDRESS_URL = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address'
const PASSPORT_ISSUED_BY_URL = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/fms_unit'

async function dadataRequest(query, url = ADDRESS_URL) {
	const options = {
		method: 'POST',
		mode: 'cors',
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			Authorization: `Token ${API_KEY}`,
		},
		body: JSON.stringify(query),
	}

	const result = await fetch(url, options)
		.then(response => {
			if (response.status >= 400) {
				return false
			}
			return response.json()
		})

	return result.suggestions || []
}

async function dadataGetCity(val) {
	const query = {
		query: val,
		from_bound: { value: 'city' },
		to_bound: { value: 'city' },
		restrict_value: true,
	}
	const data = await dadataRequest(query)
	return data.map(row => ({
		value: row.data.city,
		label: row.data.city,
		data: row.data,
	}))
}

async function dadataGetStreet(val, city, options = {}) {
	const query = {
		query: val,
		from_bound: { value: 'street' },
		to_bound: { value: 'street' },
		restrict_value: true,
		...options,
	}
	if (city) {
		query.locations = [{ city }, { region: city }]
	}
	const data = await dadataRequest(query)

	return data.map(row => {
		const street = row.data.street_with_type
		const address = street + row.value.split(street).pop()
		return {
			value: address,
			label: row.value,
			data: row.data,
		}
	})
}

async function dadataGetStreetHouse(val, city) {
	const options = {
		to_bound: { value: 'house' },
	}
	return dadataGetStreet(val, city, options)
}

async function dadataGetAddress(val, city) {
	const query = {
		query: val,
	}

	const getFormattedCity = value => {
		const capitalSymbolIndex = value.search(/[А-Я]/)
		return value.slice(capitalSymbolIndex)
	}

	if (city) {
		const formattedCity = getFormattedCity(city)
		query.locations = [{ city: formattedCity }]
		query.restrict_value = true
	}

	const data = await dadataRequest(query)
	return data.map(row => ({
		value: row.value,
		label: row.value,
		data: row.data,
	}))
}

async function dadataGetPassportIssuedBy(val) {
	const query = {
		query: val,
	}
	const data = await dadataRequest(query, PASSPORT_ISSUED_BY_URL)
	return data.map(row => ({
		value: row.value,
		label: row.value,
		data: row.data,
	}))
}

export {
	dadataGetCity,
	dadataGetStreet,
	dadataGetAddress,
	dadataGetStreetHouse,
	dadataGetPassportIssuedBy,
	dadataRequest,
}