// import i18n from '~/plugins/i18n'

export default {
	mCLManufacturers(state, payload) {
		state.CLManufacturers = payload
	},
	mCLNames(state, payload) {
		state.CLNames = payload.name || payload.content
	},
	mRounds(state, payload) {
		state.rounds = payload
	},
	mReasonMarkdowns(state, payload) {
		state.reasonMarkdowns = payload
	},
	mAutomaticDiscount(state, payload) {
		state.automaticDiscount = payload
		if (payload?.sellableResponse?.sellableCategory === 'EXTRA') {
			payload.sellableResponse = {
				...payload.sellableResponse.glasses,
				sellableCategory: 'EXTRA',
			}
		}
	},
}