const callbacks = {}

export const iframesCallbacks = {
	list() {
		// eslint-disable-next-line no-console
		console.log('callbacks', 'iframesCallbacks.js:5', callbacks)
	},
	register(uid, fn) {
		callbacks[uid] = fn
	},
	run(uid, payload) {
		if (callbacks[uid]) {
			callbacks[uid](payload)
			setTimeout(() => {
				delete callbacks[uid]
			}, 500)
		}
	},
}