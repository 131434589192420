import i18n from '~/plugins/i18n'

export default [
	{
		paymentType: 'CASH',
		sum: null,
		label: i18n.global.t('Наличные'),
		comment: null,
	},
	{
		paymentType: 'CASHLESS',
		sum: null,
		label: i18n.global.t('Карта'),
		comment: null,
		errorMessage: i18n.global.t('Сумма платежа по карте не должна превышать сумму оплаты'),
	},
	{
		paymentType: 'CREDIT_CONTRACT',
		sum: null,
		label: i18n.global.t('Кредитный договор'),
		comment: null,
		errorMessage: i18n.global.t('Сумма платежа по кредитному договору не должна превышать сумму оплаты'),
	},
	{
		paymentType: 'INSURANCE',
		sum: null,
		label: i18n.global.t('Мед. страховка'),
		comment: null,
		errorMessage: i18n.global.t('Сумма платежа по страховке не должна превышать сумму оплаты'),
	},
]