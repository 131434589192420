import VuexHttp from '../../vuexHttpService'

const vHttp = new VuexHttp('n3healthIntegration')

export default {
	namespaced: true,

	state: {
		activeLegalEntities: [],
		legalEntitySettings: null,
	},

	mutations: {
		mActiveLegalEntities(state, payload) {
			state.activeLegalEntities = payload
		},
		mLegalEntitySettings(state, payload) {
			state.legalEntitySettings = payload
		},
	},

	actions: {
		async aActiveLegalEntities() {
			return vHttp.GET(this, {
				url: 'legal-entities-active',
				mutation: 'mActiveLegalEntities',
			})
		},
		async aSaveSettings(_, data) {
			const { legalEntityId } = data
			return vHttp.POST(this, {
				url: `legal-entities/${legalEntityId}/n3health`,
				data,
			})
		},
		async aLegalEntitySettings(_, legalEntityId) {
			return vHttp.GET(this, {
				url: `legal-entities/${legalEntityId}/n3health`,
				mutation: 'mLegalEntitySettings',
			})
		},
		async aOrderN3health(_, orderId) {
			return vHttp.GET(this, {
				url: `orders/${orderId}/n3health`,
			})
		},
		async aSignOrderN3health(_, { orderId, data }) {
			return vHttp.POST(this, {
				url: `orders/${orderId}/n3health`,
				data,
			})
		},
		async aUniqueSnilsCheck(_, { ownerId, snilsOwner, snilsNumber }) {
			return vHttp.POST(this, {
				url: {
					path: `snils/check`,
					query: { ownerId },
				},
				data: {
					snilsNumber,
					snilsOwner,
				},
			})
		},
	},

	getters: {
		gActiveLegalEntities(state) {
			return state.activeLegalEntities
				.map(item => ({
					label: item.shortName,
					value: item.id,
				}))
		},
	},
}