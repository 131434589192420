import i18n from '~/plugins/i18n'
import { formatCurrencyCell } from '~/utils/currencies'

export const tableConfig = [
	{
		label: i18n.global.t('Категория'),
		prop: 'type',
		width: 180,
	},
	{
		label: i18n.global.t('Наименование'),
		prop: 'name',
		custom: true,
	},
	{
		label: i18n.global.t('Стоимость'),
		prop: 'retailPrice',
		width: 140,
		align: 'right',
		headerAlign: 'right',
		formatter: formatCurrencyCell,
	},
	{
		label: i18n.global.t('Сумма (со скидкой)'),
		prop: 'soldPrice',
		width: 140,
		align: 'right',
		formatter: formatCurrencyCell,
	},
]