<template>
	<div
		v-if="settings"
		id="layout-journal"
		class="py-1"
	>
		<div class="layout-journal__header d-inline-flex justify-content-between w-100p">
			<LayoutPartTitle
				v-if="title"
				v-bind="layoutParams"
				@on-return="onReturn"
			/>

			<LayoutPartActionBlocks
				v-if="hasHeaderBlocks"
				:blocks="layoutParams.headerBlocks"
				:position="layoutParams.headerBlocksPosition"
				data-ta="headerActionBlocks"
			/>
		</div>

		<!-- <span @click="$router.replace('/supplies-journal')">/supplies-journal</span> -->
		<!-- <span @click="$router.replace('/supplies-journal/confirmed')">/supplies-journal/confirmed</span> -->

		<el-tabs
			v-if="availableTabs.length > 1"
			v-model="activeTabName"
			class="mb-2"
			style="height: 47px"
			@tab-change="onChangeTab"
		>
			<el-tab-pane
				v-for="item in availableTabs"
				:key="item.name"
				:label="item.label"
				:name="item.name"
			/>
		</el-tabs>

		<div
			v-loading="loading"
		>
			<div
				v-if="layoutParams.filters.main"
				:key="'filters' + activeTabName + updateFilters"
				class="pb-6"
			>
				<LayoutPartFilters
					v-bind="{
						...layoutParams.filters,
						...layoutParams.filters.main,
						main: null,
						additional: null,
					}"
					ref="mainFilters"
					:fields="mainFiltersFields"
					:has-additional="Boolean(layoutParams.filters.additional)"
					:show-additional="showAdditionalFilters"
					:disable-buttons="disableFilterButtons"
					:additional-filters="additionalFilters"
					:additional-filters-fields="additionalFiltersFields"
					:has-popup="hasPopup"
					data-ta="mainFilters"
					@toggle-additional="onToggleAdditionalFilters"
					@on-filters-update="onFiltersUpdated"
					@on-filters-reset="onFiltersResetted"
					@on-filters-change="onMainFiltersChanged"
					@on-init="onMainFiltersInit"
					@on-update="onClickUpdateFilters"
					@on-reset-filters="onClickResetFilters"
				/>
				<LayoutPartFilters
					v-if="layoutParams.filters.additional"
					v-bind="{
						...layoutParams.filters,
						...layoutParams.filters.additional,
						main: null,
						additional: null,
					}"
					ref="additionalFilters"
					:fields="additionalFiltersFields"
					:show-additional="showAdditionalFilters"
					:has-popup="hasPopup"
					is-additional
					class="overflow-hidden"
					data-ta="additionalFilters"
					@on-filters-update="onFiltersUpdated"
					@on-filters-reset="onFiltersResetted"
					@on-filters-change="onAdditionalFiltersChanged"
					@on-init="onAdditionalFiltersInit"
				/>
			</div>

			<component
				:is="Page || activeTab.component"
				:key="journalName + activeTabName + updatePage"
				ref="page"
				:filters-on-init="allFilters"
				:default-sort="defaultSort"
				@on-layout-props-update="mxLayoutParamsUpdate"
				@on-main-filters-fields-update="mainFiltersFieldsUpdate"
				@on-additional-filters-fields-update="additionalFiltersFieldsUpdate"
			/>

			<div class="layout-journal__footer d-flex justify-content-between my-6 align-items-center">
				<div class="flex-grow">
					<LayoutPartPagination
						v-if="layoutParams.hasPagination"
						v-show="filteredDataTableLoaded"
						:key="'pagination' + activeTabName"
						v-model="pagination"
						:save-state="true"
						:save-state-key="`pagination.size.` + journalName + `Journal-` + (activeTabName || '')"
						@update:modelValue="onPaginationChange"
						@on-init="onPaginationInit"
					/>
				</div>
				<div
					v-if="layoutParams.footerBlocks"
					class="flex-shrink"
				>
					<LayoutPartActionBlocks
						:blocks="layoutParams.footerBlocks"
						:position="layoutParams.footerBlocksPosition"
						data-ta="footerActionBlocks"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import mxLayouts from '~/mixins/layouts/mxLayouts'
import mxComponentState from '~/mixins/mxComponentState'
import LayoutPartTitle from '~/layout/parts/LayoutPartTitle.vue'
import LayoutPartTabsWrapper from '~/layout/parts/LayoutPartTabsWrapper.vue'
import LayoutPartPagination from '~/layout/parts/LayoutPartPagination.vue'
import LayoutPartActionBlocks from '~/layout/parts/LayoutPartActionBlocks.vue'
import LayoutPartFilters from '~/layout/parts/LayoutPartFilters/LayoutPartFilters.vue'
import layoutPrintJournal from './data/layoutPrintJournal'
import layoutBeforeRouteEnter from './data/layoutBeforeRouteEnter'
import { DEFAULT_LAYOUT_JOURNAL2_PARAMS } from './data/defaults'

export default {
	name: 'LayoutJournal2',
	components: {
		LayoutPartTitle,
		LayoutPartTabsWrapper,
		LayoutPartPagination,
		LayoutPartActionBlocks,
		LayoutPartFilters,
	},
	mixins: [
		mxLayouts,
		mxComponentState,
	],
	provide() {
		return {
			layoutMethods: {
				forceUpdate: this.forceUpdatePage,
				registerComponent: this.registerComponent,
				unregisterComponent: this.unregisterComponent,
				updateParams: this.updateParams,
				printJournal: this.onPrintJournal,
				updateSort: this.updateSort,
				getQueryParams: this.getQueryParams,
				setPage: this.setPage,
			},
			filtersMethods: {
				setMainFields: this.mainFiltersFieldsUpdate,
				setAdditionalFields: this.additionalFiltersFieldsUpdate,
				mergeMainData: this.mergeMainFilters,
				mergeAdditionalData: this.mergeAdditionalFilters,
			},
		}
	},
	beforeRouteLeave(to, from, next) {
		if (this.layoutParams.filters.main?.cleanupOnRouteLeave) {
			if (this.layoutParams.filters.main?.cleanupOnRouteLeave(to)) {
				this.$refs.mainFilters.cleanUpSavedState()
			}
		}

		if (this.layoutParams.filters.additional?.cleanupOnRouteLeave) {
			if (this.layoutParams.filters.additional?.cleanupOnRouteLeave(to)) {
				this.$refs.additionalFilters.cleanUpSavedState()
			}
		}

		next()
	},
	props: {
		Page: {  // eslint-disable-line
			type: [Object, Function],
			default: null,
		},
		Tabs: {  // eslint-disable-line
			type: Array,
			default: () => ([]),
		},
		journalName: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			activeTabName: null,
			initializationCounter: 0,
			initialization: {
				mainFilters: false,
				additionalFilters: false,
				pagination: false,
			},
			filteredDataTableLoaded: false,
			loading: true,
			pagination: null,
			updatePage: 0,
			updateFilters: 0,
			mainFilters: null,
			additionalFilters: null,
			mainFiltersFields: null,
			additionalFiltersFields: null,
			showAdditionalFilters: false,
			filterShowAdditionslStateKey: `filters.showAdditionalFilters.${this.$route.name}Journal-${this.activeTabName}`,
			componentsWatchers: [],
			layoutParams: DEFAULT_LAYOUT_JOURNAL2_PARAMS(),
			saveFields: ['sort'],
			saveStateKey: `${this.$route.name}.params`,
			sort: this.$route.query.sort || null,
		}
	},
	computed: {
		...mapState([
			'settings',
		]),
		...mapState('user', [
			'authorities',
		]),
		...mapGetters('user', [
			'gComponentsState',
		]),
		title() {
			return this.layoutParams.title || ''
		},
		name() {
			return this.activeTab.name || this.journalName
		},
		availableTabs() {
			return (this.Tabs || []).filter(tab => {
				let tabNotAvailable = false
				if (tab.notAvailable) {
					tabNotAvailable = tab.notAvailable(this.$store)
				}
				if (tab.notAvailableToRoles) {
					tabNotAvailable = tabNotAvailable || tab.notAvailableToRoles.filter(role => this.authorities.includes(role)).length
				}
				return !tabNotAvailable
			})
		},
		activeTab() {
			return this.availableTabs.find(tab => tab.name === this.activeTabName) || {}
		},
		disableFilterButtons() {
			return {
				update: !this.filteredDataTableLoaded,
				reset: !this.filteredDataTableLoaded,
			}
		},
		paginationStore() {
			const paginationStore = `journals${this.name}Pagination`
			return this.$store.state.journals[paginationStore]
		},
		allFilters() {
			return {
				...this.mainFilters,
				...this.additionalFilters,
			}
		},
		hasHeaderBlocks() {
			return this.layoutParams.headerBlocks.length
		},

		defaultSort() {
			if (!this.sort) return {}
			const [prop, ord] = this.sort.split(',')
			return { prop, order: ord === 'desc' ? 'descending' : 'ascending' }
		},
		hasPopup() {
			return !!this.layoutParams.filters?.additional?.popupProps
				|| !!this.layoutParams.filters?.main?.popupProps
		},
	},
	watch: {
		async initializationCounter() {
			const { main, additional } = this.layoutParams.filters
			const filtersAmount = 0 + !!main + !!additional
			const importantConponentsAmount = 2 + filtersAmount
			// importantConponentsAmount содержит количество компонентов,
			// которые читают данные своего состояния:
			// фильтры основной, фильтр дополнительный, пагинация

			if (this.initializationCounter >= importantConponentsAmount) {
				this.loading = false
				if (filtersAmount) {
					this.sendFilters()
				} else {
					this.filteredDataTableLoaded = true
				}
			}
		},
		'layoutParams.filters.additional': function (additional) {
			if (additional?.popupProps?.isPopup) {
				this.showAdditionalFilters = false
			} else {
				this.showAdditionalFilters = this.gComponentsState[this.filterShowAdditionslStateKey]
			}
		},
		'$route.params': function (val, oldVal) {
			if (!val.tab && val.tab !== oldVal.tab) {
				const nextTab = this.availableTabs.find(tab => tab.tab === (val.tab || ''))
				if (!nextTab) {
					// переход с другой страницы или из легаси без определённой табы
					// если в роутере /journal-page/:tab?
					this.onChangeTabOutside(this.availableTabs[0])
				}
			}
		},
		'layoutParams.filters': {
			deep: true,
			handler() {
				this.forceUpdateFilters()
			},
		},
	},

	// eslint-disable-next-line vue/order-in-components
	beforeRouteEnter: layoutBeforeRouteEnter,

	async created() {
		this.mLayoutDropState()
		this.showAdditionalFilters = this.gComponentsState[this.filterShowAdditionslStateKey]

		if (this.availableTabs.length) {
			const activeTab = this.availableTabs.find(tab => tab.tab === (this.$route.params.tab || ''))
			this.activeTabName = activeTab?.name || this.availableTabs[0].name
		}
	},

	methods: {
		...mapMutations('layout', [
			'mLayoutDropState',
		]),
		...mapMutations('user', [
			'mSetComponentState',
		]),

		async replaceRouteTab(nextTab) {
			const newRoute = {
				name: this.$route.name,
				params: {
					tab: nextTab.tab || '',
				},
				query: null,
			}
			await this.$router.replace(newRoute)
		},

		onChangeTabOutside(nextTab) {
			if (this.activeTabName === nextTab.name) {
				this.replaceRouteTab(nextTab)
				this.forceUpdateLayout()
				return
			}

			this.initializationCounter = 0
			this.layoutParams = DEFAULT_LAYOUT_JOURNAL2_PARAMS()
			this.loading = true
			this.activeTabName = nextTab.name
		},


		async onChangeTab(nextTabName) {
			this.initializationCounter = 0
			this.loading = true
			const nextTab = this.availableTabs.find(tab => tab.name === nextTabName)
			await this.replaceRouteTab(nextTab)
			this.activeTabName = nextTab.name
			this.forceUpdatePage()
		},

		async onReturn() {
			if (this.componentsMethods?.return) {
				this.componentsMethods.return()
			} else {
				await this.aReturn(this.layoutParams)
			}
			this.mLayoutDropState()
		},

		onPaginationChange(pagination) {
			this.pagination = pagination

			if (this.componentsMethods?.paginationChange) {
				this.componentsMethods.paginationChange()
			} else if (this.$refs.page) {
				this.$refs.page.layoutPaginationChange()
			}
		},

		// Filters
		sendFilters() {
			this.filteredDataTableLoaded = false

			if (this.throttleTimeoutHandle) {
				clearTimeout(this.throttleTimeoutHandle)
			}

			this.throttleTimeoutHandle = setTimeout(async () => {
				await this.componentsMethods.filtersChange(this.allFilters)
				this.filteredDataTableLoaded = true
			}, 50)
		},
		onFiltersUpdated(filters, isAdditional) {
			if (isAdditional) {
				this.additionalFilters = filters
			} else {
				this.mainFilters = filters
			}
			if (!this.layoutParams.filters.additional || isAdditional) {
				this.$nextTick(this.sendFilters)
			}
		},
		onFiltersResetted(filters, isAdditional) {
			if (isAdditional) {
				this.additionalFilters = filters
			} else {
				this.mainFilters = filters
			}
			if (!this.layoutParams.filters.additional || isAdditional) {
				this.$nextTick(this.sendFilters)
			}
		},
		onMainFiltersChanged(filters) {
			this.mainFilters = filters
			this.sendFilters()
		},
		onAdditionalFiltersChanged(filters) {
			this.additionalFilters = filters
			this.sendFilters()
		},
		onMainFiltersInit(filters) {
			this.mainFilters = filters
			this.initialization.mainFilters = true
			this.initializationCounter += 1
		},
		onAdditionalFiltersInit(filters) {
			this.additionalFilters = filters
			this.initialization.additionalFilters = true
			this.initializationCounter += 1
		},
		onPaginationInit() {
			this.initialization.pagination = true
			this.initializationCounter += 1
		},
		onClickResetFilters() {
			if (this.layoutParams.filters.additional) {
				this.$refs.additionalFilters.resetFilters()
			}
			setTimeout(this.$refs.mainFilters.resetFilters)
		},
		onClickUpdateFilters() {
			this.mainFilters = this.$refs.mainFilters.getFiltersData()
			if (this.layoutParams.filters.additional) {
				this.additionalFilters = this.$refs.additionalFilters.getFiltersData()
			}
			this.sendFilters()
		},
		onToggleAdditionalFilters() {
			if (this.layoutParams.filters.additional?.popupProps) {
				this.$refs.additionalFilters.onShowFiltersFieldsPopup()
				return
			}

			this.showAdditionalFilters = !this.showAdditionalFilters
			this.mSetComponentState({
				[this.filterShowAdditionslStateKey]: this.showAdditionalFilters,
			})
		},

		// Filters methods provided to component
		mergeMainFilters(filters) {
			if (this.$refs.mainFilters?.mergeFilters) {
				this.$refs.mainFilters.mergeFilters(filters)
			}
		},
		mergeAdditionalFilters(filters) {
			if (this.$refs.additionalFilters?.mergeFilters) {
				this.$refs.additionalFilters.mergeFilters(filters)
			}
		},
		mainFiltersFieldsUpdate(fields) {
			this.mainFiltersFields = fields
		},
		additionalFiltersFieldsUpdate(fields) {
			this.additionalFiltersFields = fields
		},

		// Layout methods provided to component
		forceUpdateFilters() {
			this.updateFilters++
		},
		forceUpdatePage() {
			this.updatePage++
		},
		forceUpdateLayout() {
			this.$emit('force-update-layout')
		},
		registerComponent(componentCtx) {
			if (!componentCtx.layoutParams) {
				throw new Error('Компонент журнала должен содержать объект с параметрами шаблона layoutParams')
			}
			if (!componentCtx.loadData) {
				throw new Error('Компонент журнала должен содержать метод loadData(), который вызывается из mxInit')
			}
			if (componentCtx.layoutParams.hasPagination && !componentCtx.layoutPaginationChange) {
				throw new Error('Компонент журнала должен содержать метод layoutPaginationChange')
			}
			if (componentCtx.layoutParams.filters?.main && !componentCtx.layoutFilterChange) {
				throw new Error('Компонент журнала должен содержать метод layoutFilterChange')
			}

			this.componentsWatchers = []
			this.componentsWatchers.push(componentCtx.$watch(`layoutParams`, this.updateParams))
			this.componentsWatchers.push(this.$watch(`paginationStore`, value => {
				this.pagination = value
			}))

			this.componentCtx = componentCtx
			this.mxLayoutParamsUpdate(componentCtx.layoutParams)

			componentCtx.layoutParams = {
				...DEFAULT_LAYOUT_JOURNAL2_PARAMS(),
				...componentCtx.layoutParams,
			}

			this.componentsMethods = {
				paginationChange: componentCtx.layoutPaginationChange,
				filtersChange: componentCtx.layoutFilterChange,
				return: componentCtx.return,
			}
			this.initializationCounter += 1

			// Загрузка данных в журнал без фильтров
			if (!componentCtx.layoutParams.filters?.main) {
				componentCtx.loadData()
			}
		},
		unregisterComponent() {
			this.componentsMethods = {}
			this.componentsWatchers.forEach(fn => fn())
			this.componentsWatchers = []
		},
		updateParams(layoutParams) {
			this.mxLayoutParamsUpdate(layoutParams)
		},
		updateSort(sort) {
			if (sort !== this.$route.query.sort) {
				const query = { ...this.$route.query }
				if (!sort) {
					delete query.sort
				} else {
					query.sort = sort
				}
				this.$router.push({ query })
			}

			this.sort = sort
		},
		onPrintJournal(hasSignature = false, fioShort = null) {
			const journalTable = this.$el.querySelector('.el-table__inner-wrapper').innerHTML
			const { mainFiltersFields, additionalFiltersFields } = this
			const { title } = this.layoutParams
			const { query } = this.$route

			layoutPrintJournal({
				title,
				query,
				journalTable,
				mainFiltersFields,
				additionalFiltersFields,
				hasSignature,
				fioShort,
			})
		},
		getQueryParams() {
			const filters = {}

			if (this.layoutParams.filters.main && this.mainFilters) {
				Object.keys(this.mainFilters).forEach(k => {
					if (!['', null].includes(this.mainFilters[k])) {
						filters[k] = this.mainFilters[k]
					}
				})
			}

			if (this.layoutParams.filters.additional && this.additionalFilters) {
				Object.keys(this.additionalFilters).forEach(k => {
					if (!['', null].includes(this.additionalFilters[k])) {
						filters[k] = this.additionalFilters[k]
					}
				})
			}

			// const page = (this.$route.query.page || this.pagination?.page || 1) - 1
			// const size = this.$route.query.size || this.pagination?.size || this.layoutParams.defaultPaginationSize
			const page = (this.pagination?.page || 1) - 1
			const size = Number(this.pagination?.size) || this.layoutParams.defaultPaginationSize

			const params = {
				...filters,
				page,
				size,
			}

			return params
		},
		setPage(page) {
			this.pagination = {
				...this.pagination,
				page,
			}
		},
	},
}
</script>

<style lang="scss">
#layout-journal {
	min-height: 700px;
}

.layout-journal {
	&__header {
		margin-bottom: 24px;
		align-items: center;
	}
}

.el-loading-parent--relative {
	* {
		opacity: 0;
	}

	.el-loading-mask,
	.el-loading-mask * {
		opacity: 1;
	}
}
</style>