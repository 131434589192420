import i18n from '~/plugins/i18n'
import { getFioFull, normalizeDioptreItem } from '~/utils/formatters'
import { dayjs } from '~/kit/plugins/dayjs'
import { statusLabelsFilterOptions } from '~/helpers/common/giftCertificates'

const discountsTypes = [
	'activeDiscount',
	'extraDiscount',
	'bonusDiscount',
]

function formatParameter(item) {
	return item && String(item).replaceAll('.', ',')
}

export default {
	gCartItems(state, _getters, rootState) {
		const { firstNameBeforeFamilyName } = rootState.settings
		const items = state?.cart?.cartGroups
			.map(group => {
				const barcodes = !group.sellable.factory
					? group.cartItems
						.map((item, idx) => ({
							name: `barcode-${idx}`,
							value: item.sellableId,
						}))
					: []

				const result = {
					...group,
					...group.sellable,
					discounts: [],
				}

				discountsTypes
					.forEach(type => {
						if (group[type] && (group[type].discountSum || group[type].discountPercentage)) {
							const discount = {
								id: group[type].id,
								deletable: group[type].deletable,
								sum: group[type].discountSum,
							}
							discount.label = `${group[type].name} `
							discount.label += `${group[type].discountPercentage}%`
							result.discounts.push(discount)
						}
					})

				if (result.accessoryCategory === 'Не маркируемые товары') {
					// По умолчанию sellableCategory = ACCESSORY
					result.sellableCategory = 'UNMARKED_GOODS'
				}

				if (result.sellableCategory === 'ACCESSORY') {
					result.type = i18n.global.t('Аксессуары')
					result.description = {
						name: `${result.accessoryCategory} ${result.model}`,
					}

					result.nestedRow = {
						tags: [...barcodes],
					}
				} else if (result.sellableCategory === 'CONTACT_LENS') {
					result.type = `${i18n.global.t('Конт. линзы')}`
					result.description = {
						name: result.name,
						params: [
							formatParameter(result.curvatureRadius),
							`${result.packageQuantity}pk`,
						],
						recipeParams: [
							normalizeDioptreItem(result.dioptre),
							formatParameter(result.cylinder),
							formatParameter(result.axis),
							formatParameter(result.add),
						],
					}

					result.nestedRow = {
						tags: [
							...barcodes,
							result.manufacturer,
							formatParameter(result.diameter),
							result.wearingPeriod,
						],
					}

					if (result.expireAt) {
						const expireAt = dayjs(result.expireAt).format('DD.MM.YYYY')
						result.description.params
							.push(`${i18n.global.t('годен до:')} ${expireAt}`)
					}
				} else if (result.sellableCategory === 'LENS') {
					result.type = i18n.global.t('Линзы')
					result.description = {
						name: result.manufacturer,
						params: [
							result.brand,
							formatParameter(result.diameter),
							formatParameter(result.refractionIndex),
							result.lensClass,
							result.cover,
							result.color,
						].filter(param => param > ''),
						recipeParams: [
							normalizeDioptreItem(result.dioptre),
							formatParameter(result.cylinderDioptre),
							formatParameter(result.axis),
							formatParameter(result.addidation),
						],
						radius: result.curvatureRadius,
					}

					result.nestedRow = {
						tags: [
							...barcodes,
							result.geometry,
							result.material,
						],
					}
				} else if (result.sellableCategory === 'GLASSES') {
					result.type = i18n.global.t('Оправы')
					result.description = {
						name: `${result.brand} ${result.model}`,
						color: result.color,
						size: result.size,
					}

					result.nestedRow = {
						tags: [
							...barcodes,
							result.manufacturer,
							result.material,
							result.targetGroup,
							result.design,
						],
					}
				} else if (result.sellableCategory === 'SUNGLASSES') {
					result.type = i18n.global.t('Солнц. очки')
					result.description = {
						name: `${result.brand} ${result.model}`,
						color: result.color,
						size: result.size,
					}

					result.nestedRow = {
						tags: [
							...barcodes,
							result.manufacturer,
							result.material,
							result.frameType,
							result.targetGroup,
							result.design,
						],
					}
				} else if (result.sellableCategory === 'SERVE') {
					result.type = i18n.global.t('Услуги')
					result.maxSize = 100
					result.description = {
						name: result.serveType.name,
						params: [
							result.comment ? result.comment : null,
						],
					}

					result.nestedRow = {
						tags: [
							`${i18n.global.t('Оказывает')} ${getFioFull(result.employee, firstNameBeforeFamilyName)}`,
						],
					}
				} else if (result.sellableCategory === 'UNMARKED_GOODS') {
					result.type = i18n.global.t('Не маркир. товары')
					result.description = {
						name: result.model,
					}
				} else if (result.sellableCategory === 'GIFT_CERTIFICATE') {
					const certificateNumber = ` ${result.certificateNumber || result.cartItems[0].sellableId || ''}`

					result.type = i18n.global.t('Сертификат')
					result.description = {
						name: result.type + certificateNumber,
					}
				} else if (result.sellableCategory === 'EXTRA') {
					result.type = i18n.global.t('Массовка')
					result.description = {
						name: `${result.extrasDesc.frameBrand} ${result.extrasDesc.frameModel}`,
						color: result.extrasDesc.frameColor,
						params: [result.extrasDesc.frameMaterial],
						recipeParams: [
							normalizeDioptreItem(result.extrasDesc.odDioptre),
							formatParameter(result.dpp),
						],
					}
					result.nestedRow = {
						tags: [
							...barcodes,
							result.extrasDesc.frameManufacturer,
							result.extrasDesc.frameType,
							result.extrasDesc.frameTargetGroup,
						].filter(param => param > '' && param !== '-'),
					}
				}

				return result
			})

		if (items?.length) {
			items.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))
		}

		return items
	},

	gCartInfo(state) {
		return state.cart && state.cart.cartInfo
	},

	gServices(state) {
		const services = state.services
			.map(service => {
				const result = {
					...service.sellable,
					discountPrice: service.discountPrice,
					name: service.sellable.serveType.name,
					discounts: [],
					amount: service.sellable.count,
				}

				discountsTypes
					.forEach(type => {
						if (service[type]) {
							const discount = {
								id: service[type].id,
								deletable: service[type].deletable,
								sum: service[type].discountSum,
							}
							discount.label = `${service[type].name} `
							discount.label += `${service[type].discountPercentage}%`
							result.discounts.push(discount)
						}
					})

				return result
			})
			.sort((a, b) => {
				const serviceA = a.name.toLowerCase()
				const serviceB = b.name.toLowerCase()

				if (serviceA < serviceB) { return -1 }
				if (serviceA > serviceB) { return 1 }
				return 0
			})

		return services
	},
	gUnmarkedGoods(state) {
		return state.unmarkedGoods
			.map(item => {
				const result = {
					...item.sellable,
					id: item.id,
					discountPrice: item.discountPrice,
					discounts: [],
				}

				discountsTypes
					.forEach(type => {
						if (item[type]) {
							const discount = {
								id: item[type].id,
								deletable: item[type].deletable,
								sum: item[type].discountSum,
							}
							discount.label = `${item[type].name} `
							discount.label += `${item[type].discountPercentage}%`
							result.discounts.push(discount)
						}
					})

				return result
			})
			.sort((a, b) => {
				const serviceA = a.model.toLowerCase()
				const serviceB = b.model.toLowerCase()

				if (serviceA < serviceB) { return -1 }
				if (serviceA > serviceB) { return 1 }
				return 0
			})
	},
	gCartsDiscounts(state) {
		return state.cartsDiscounts
			.map(discount => {
				const result = {}
				result.value = discount.id

				if (discount.fixedDiscount !== null) {
					result.label = `${discount.name} (${discount.fixedDiscount})`
				}

				if (discount.percentageDiscount !== null) {
					result.label = `${discount.name} (${discount.percentageDiscount}%)`
				}

				return result
			})
	},
	gCLNomenclatureOptions(state) {
		const result = {}

		Object.entries(state.CLNomenclature)
			.forEach(([key, val]) => {
				result[key] = val.map(item => ({ label: item, value: item }))
			})

		return result
	},
	gReceiptRecommendedModelsOptions(state) {
		return state.receiptRecommendedModels
			.map(item => ({
				label: item.name,
				value: item.name,
			}))
	},
	gPreparedCartServices(state) {
		return {
			services: state.cart?.cartGroups.map(group => ({
				createdAt: group.localCreatedAt,
				serviceName: group.sellable.serveType.name,
				cartItemId: group.cartItems[0].id,
				fullName: group.sellable.employee.fullName,
				count: group.groupSize,
				activeDiscount: group.activeDiscount,
				extraDiscount: group.extraDiscount,
				discountPrice: group.discountPrice,
				totalDiscountPrice: group.totalDiscountPrice,
			})) || [],
			info: {
				cartPrice: state.cart?.cartInfo.cartPrice || 0,
				cartDiscountPrice: state.cart?.cartInfo.cartDiscountPrice || 0,
				cartDiscount: state.cart?.cartInfo.cartDiscount || 0,
				comment: state.cart?.cartInfo.orderComment || '',
				selectedDiscount: state.cart?.cartInfo.selectedDiscount || {},
				extraDiscount: state.cart?.cartInfo.extraDiscount || {},
				hasDiscounts: state.cart?.cartInfo.discountsInCheckVision || false,
			},
		}
	},
	gGiftCertificatesStatusesOptions(state) {
		return state.giftCertificatesStatuses.map(item => ({
			value: item,
			label: statusLabelsFilterOptions[item],
		}))
	},
}