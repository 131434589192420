import clientState from './clientState'
import clientActions from './clientActions'
import clientMutations from './clientMutations'
import clientGetters from './clientGetters'

export default {
	namespaced: true,
	state: clientState,
	getters: clientGetters,
	mutations: clientMutations,
	actions: clientActions,
}