export default {
	gDeliverDetailsReadCount(state) {
		const deliverDetailsReadCount = state.deliverDetails.length
			? {
				total: state.deliverDetails.length,
				read: state.deliverDetails.filter(item => item.readedAt !== null).length,
			}
			: {
				total: 0,
				read: 0,
			}

		return deliverDetailsReadCount
	},
	gDeliverDetailsFormated(state) {
		const deliverDetailsFormated = {}

		state.deliverDetails.forEach(item => {
			if (!deliverDetailsFormated[item.departmentName]) deliverDetailsFormated[item.departmentName] = { respondents: [] }
			deliverDetailsFormated[item.departmentName].respondents.push(item)
		})

		Object.keys(deliverDetailsFormated).forEach(deptId => {
			const dept = deliverDetailsFormated[deptId]
			// Сортируем респондентов по алфавиту
			dept.respondents.sort((a, b) => (`${a.familyName}${a.firstName}${a.patronymicName}`).localeCompare((`${b.familyName}${b.firstName}${b.patronymicName}`)))

			dept.count = {
				total: dept.respondents.length,
				read: dept.respondents.filter(item => item.readedAt !== null).length,
			}
		})

		return deliverDetailsFormated
	},
}