<template>
	<div
		:data-ta="`range_dates_${name}_${nameTo}`"
		class="com-field-item com-field-range-dates"
	>
		<PartFieldView
			v-if="!isEdit"
			v-model="viewValues"
			:name="name"
			:options="options"
			:label="label"
			:label-width="labelWidth"
			:label-color-dot="labelColorDot"
			:link-pattern="linkPattern"
			:show-label="showLabel"
			:is-html="isHtml"
			:on-click="onClick"
			:info-tooltip="infoTooltip"
		/>

		<el-form-item
			v-else
			:ref="name"
			:prop="name"
			:label="label"
			:label-width="labelWidth"
		>

			<PartInfoTooltip
				v-if="infoLabelTooltip"
				:info-tooltip="infoLabelTooltip"
				label-tooltip
			/>

			<div class="d-flex align-items-center w-100p">
				<span class="mr-2 com-field-range__label">{{ placeholderFrom }}</span>
				<div :data-ta="name" class="w-100p">
					<el-date-picker
						v-model="from"
						:placeholder="placeholder"
						type="date"
						:format="format"
						:value-format="valueFormat"
						:disabled="disabled || readonly"
						:disabled-date="fieldDatepickerCheckDisabledDate"
						:fallback-placements="['bottom']"
						:size="size"
						class="w-100p"
					/>
				</div>

				<span class="mr-2 ml-2 com-field-range__label">{{ placeholderTo }}</span>
				<div :data-ta="nameTo" class="w-100p">
					<el-date-picker
						v-model="to"
						:placeholder="placeholder"
						type="date"
						:format="format"
						:value-format="valueFormat"
						:disabled="disabled || readonly"
						:disabled-date="fieldDatepickerCheckDisabledDate"
						:fallback-placements="['bottom']"
						:size="size"
						class="w-100p"
					/>
				</div>

				<el-tooltip
					v-if="deleteTooltip"
					:content="deleteTooltip"
					:disabled="!deleteTooltip"
					placement="right"
					effect="light"
				>
					<ItIcon
						v-if="deleteFn"
						name="trash"
						class="ml-6 clickable"
						@click="deleteFn"
					/>
				</el-tooltip>

				<PartInfoTooltip
					v-if="infoTooltip"
					:info-tooltip="infoTooltip"
				/>

			</div>
		</el-form-item>
	</div>
</template>

<script>
import i18n from '~/plugins/i18n'
import defaultProps from './defaultFieldsProps'
import PartFieldView from './parts/PartFieldView'
import PartInfoTooltip from './parts/PartInfoTooltip'

export default {
	name: 'ComFieldRangeDates',
	components: {
		PartFieldView,
		PartInfoTooltip,
	},
	props: {
		...defaultProps,
		nameTo: {
			type: String,
			required: true,
		},
		modelFrom: {
			type: [String, Object],
			default: '',
		},
		modelTo: {
			type: [String, Object],
			default: '',
		},
		options: {
			type: Array,
			default: () => ([]),
		},
		valueKey: {
			type: String,
			default: null,
		},
		multiple: {
			type: Boolean,
			default: false,
		},
		fieldType: {
			type: String,
			default: 'text',
		},
		suffixSlot: {
			type: Object,
			default: null,
		},
		filterable: {
			type: Boolean,
			default: true,
		},
		placeholderFrom: {
			type: String,
			default: i18n.global.t('от'),
		},
		placeholderTo: {
			type: String,
			default: i18n.global.t('по'),
		},
		deleteFn: {
			type: Function,
			default: null,
		},
		deleteTooltip: {
			type: String,
			default: null,
		},
		collapseTags: {
			type: Boolean,
			default: false,
		},
		emptyValueLabel: {
			type: String,
			default: '—',
		},
		format: {
			type: String,
			default: 'DD.MM.YYYY',
		},
		valueFormat: {
			type: String,
			default: 'YYYY-MM-DD',
		},
	},
	computed: {
		from: {
			get() { return this.modelFrom ?? '' },
			set(value) {
				this.$emit('change', value)
				this.$emit('update:modelFrom', value)
			},
		},
		to: {
			get() { return this.modelTo ?? '' },
			set(value) {
				this.$emit('change', value)
				this.$emit('update:modelTo', value)
			},
		},
		viewValuesFrom() {
			return this.modelFrom
				? this.$dayjs(this.modelValue).format(this.format)
				: this.emptyValueLabel
		},
		viewValuesTo() {
			return this.modelTo
				? this.$dayjs(this.modelTo).format(this.format)
				: this.emptyValueLabel
		},
		viewValues() {
			return [this.viewValuesFrom[0], this.viewValuesTo[0]]
		},
	},
	methods: {
		fieldDatepickerCheckDisabledDate(date) {
			if (this.maxDate && date.getTime() > Date.parse(this.maxDate)) return true
			return this.minDate && date < Date.parse(this.minDate)
		},
	},
}
</script>


<style lang="scss">
.com-field-range-dates {
	.el-form-item__content {
		line-height: 28px;
	}
}
</style>