export const MESSAGES_SETTINGS_TYPES = Object.freeze({
	TYPE_GENERAL_ID: 'general',
	TYPE_SMS_ID: 'sms',
	TYPE_EMAIL_ID: 'email',
})

export const APPLICATION_SETTINGS_TAB = Object.freeze({
	GENERAL: 'general',
	SECURITY: 'security',
	CL_EXPIRATION_DATE: 'clExpirationDate',
	INFORMATION_FIELDS: 'informationFields',
	MEDICAL_CARD: 'medicalCard',
})

export const USER_AUTHORITIES = Object.freeze({
	ROLE_MANAGER: 'ROLE_OPTIMA_MANAGER',
	ROLE_BONUS_ACCUMULATION_MANAGER: 'ROLE_OPTIMA_BONUS_ACCUMULATION_MANAGER',
	ROLE_NOTIFICATION_MANAGER: 'ROLE_OPTIMA_NOTIFICATION_MANAGER',
	ROLE_ACCOUNTANT: 'ROLE_OPTIMA_ACCOUNTANT',
	ROLE_DOCTOR: 'ROLE_OPTIMA_DOCTOR',
	ROLE_DEPOT: 'ROLE_OPTIMA_DEPOT',
	ROLE_PRODUCTION: 'ROLE_OPTIMA_PRODUCTION',
	ROLE_STORE: 'ROLE_OPTIMA_STORE',
	ROLE_ADMIN: 'ROLE_OPTIMA_ADMIN',
	ROLE_TOP_MANAGER: 'ROLE_OPTIMA_TOP_MANAGER',
	ROLE_SECRETARY: 'ROLE_OPTIMA_SECRETARY',
	ROLE_DOCTOR_STORE: 'ROLE_OPTIMA_DOCTOR_STORE',
	ROLE_OPT_CLIENT: 'ROLE_OPTIMA_OPT_CLIENT',
	ROLE_ITIGRIS_USER: 'ROLE_OPTIMA_ITIGRIS_USER',
	ROLE_MERGE_CLIENTS: 'ROLE_OPTIMA_MERGE_CLIENTS',
})

export const NOTIFICATION_MESSAGE_TYPE = Object.freeze({
	ORDER_READINESS: 'NOTIFICATION_ORDER',
	BONUSES_ACCRUAL: 'NOTIFICATION_BONUS',
})

export const ORDER_ID_LENGTH = 10

export const TRANSFER_ID_LENGTH = 10

export const CANCELLATION_ID_LENGTH = 10

export const GOOD_ID_LENGTH = 10