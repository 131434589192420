<template>
	<div
		class="com-table-nested-tags"
		data-ta="tags"
	>
		<el-tag
			v-for="(tag, idx) of tagsFiltered"
			:key="idx"
			type="info"
			class="com-table-nested-tags__tag"
			@click="clickTag(tag)"
		>
			<ItIcon
				v-if="tag.name && tag.name.includes('barcode')"
				name="barcode-settings"
				class="color-text-regular"
				width="14"
				height="14"
			/>
			<span class="color-text-regular">{{ tag.text }}</span>
		</el-tag>
	</div>
</template>

<script>
export default {
	name: 'ComTableNestedTags',
	props: {
		tags: {
			type: Array,
			default: null,
		},
	},
	computed: {
		tagsFiltered() {
			return this.tags
				.filter(item => !!item)
				.map(item => {
					let text
					if (item.name && item.name.includes('barcode')) {
						text = `${item.value}`
					} else if (item?.name) {
						text = `${item.name}: ${item.amount}`
					} else {
						text = item?.value || item
					}


					if (typeof item === 'string') {
						return {
							text,
						}
					}

					return {
						...item,
						text,
					}
				})
		},
	},
	methods: {
		clickTag(tag) {
			this.$emit('click', tag)
		},
		deleteTag(tag) {
			this.$emit('delete', tag)
		},
	},
}
</script>

<style lang="scss">
.com-table-nested-tags {
	display: flex;
	flex-wrap: wrap;
	gap: 4px;
	cursor: pointer;

	&__tag {
		border-radius: 20px;
		display: flex;
		align-items: center;
		gap: 4px;
		max-width: 200px;
		
		.el-tag__content {
			display: flex;
			align-items: center;
		}

		span {
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}
	}
}
</style>