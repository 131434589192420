import VuexHttp from '../../vuexHttpService'

const vHttp = new VuexHttp('clientsAgreements')

export default {
	async aStoreBalanceInfo() {
		return vHttp.GET(this, {
			url: 'balance/enough-money-for-notification',
			mutation: 'mStoreBalanceInfo',

		})
	},
	async aPrepareAgreementsText(_, { clientId, data }) {
		// Вспомогательный запрос для бэка,
		// который устанавливает тип согласия в базу
		return vHttp.POST(this, {
			url: `clients/${clientId}/agreements/prepare-text`,
			data,
		})
	},
	async aSendMessage(_, { clientId, data }) {
		return vHttp.POST(this, {
			url: `clients/${clientId}/agreements/message`,
			data,
			mutationError: 'mSendMessageError',
			errorsProcessing: 3,
		})
	},
	async aAcceptAgreement(_, params) {
		const data = {
			agreementType: params.agreementType,
			collectionMethod: params.collectionMethod,
		}

		if (params.confirmationCode) {
			data.confirmationCode = params.confirmationCode
		}

		return vHttp.POST(this, {
			url: `clients/${params.clientId}/agreements`,
			data,
			mutationError: 'mAcceptingAgreementError',
		})
	},
	async aAgreement(_, { clientId, agreementType }) {
		return vHttp.GET(this, {
			url: `clients/${clientId}/agreements?clientAgreementType=${agreementType}`,
			mutation: 'mAgreement',
		})
	},
	async aRevokeAgreement(_, { clientId, agreementType }) {
		return vHttp.DELETE(this, {
			url: `clients/${clientId}/agreements?clientAgreementType=${agreementType}`,
		})
	},
	async aCheckingWasSmsSent(_, { clientId }) {
		return vHttp.GET(this, {
			url: `clients/${clientId}/agreements/is-message-sent`,
			mutation: 'mCheckingWasSmsSent',
		})
	},
	async aClientsEmail(_, clientId) {
		const url = `clients/${clientId}/email`
		return vHttp.GET(this, {
			url,
			mutation: 'mClientsEmail',
		})
	},
}