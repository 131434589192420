import { http } from '../http'

const AUTH_MODULE_NAME = 'auth'

const MUTATION_KEYS = {
	SET_TOKEN: 'SET_TOKEN',
	DROP_TOKEN: 'DROP_TOKEN',
	SET_AUTHORIZED: 'SET_AUTHORIZED',
	SET_AUTHORIZING: 'SET_AUTHORIZING',
	SET_COMPANY: 'SET_COMPANY',
	SET_UUID: 'SET_UUID',
	SET_LOAD_ID: 'SET_LOAD_ID',
	SET_LOGOUT_PROCESS: 'SET_LOGOUT_PROCESS',
}

/* здесь будут храниться все опции */
let options = {}
let httpInstance = http

function getFullApiTokenPath(refresh) {
	const { authPath, tokenUrl } = options.http

	return authPath && typeof authPath === 'object'
		? `${tokenUrl}${refresh ? authPath.refresh : authPath.login}`
		: `${tokenUrl}${authPath}`
}

function isGetTokenRequest(error) {
	const { url } = error.response.config

	if (typeof options.http.authPath !== 'object') return url.indexOf(getFullApiTokenPath()) !== -1

	return url.indexOf(getFullApiTokenPath()) !== -1 || url.indexOf(getFullApiTokenPath(true)) !== -1
}

function parseQuery(queryString) {
	if (!queryString) return {}

	const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&')

	return pairs.reduce((query, pair) => {
		const splittedPair = pair.split('=')
		query[decodeURIComponent(splittedPair[0])] = decodeURIComponent(splittedPair[1] || '')
		return query
	}, {})
}

const defaultOptions = {
	http: {
		accessTokenKey: 'access_token', // в каком формате приходит access token
		refreshTokenKey: 'refresh_token', // в каком формате приходит refresh token
		withCredentials: false, // атрибут axios (используется, например, в алконе)
		client: '', // параметр для доп.опций (например, в алконе), если указаны client и secret, то используем доп.опции
		secret: '', // параметр для доп.опций (например, в алконе), если указаны client и secret, то используем доп.опции
		credentials: {}, // credentials для получения токена, например client_id, client_secret
		tokenKeyLS: 'user/token', // под каким ключом сохранять токен в localStorage
		tokenUrl: import.meta.env.VITE_URL_API, // url для отправки запросов api на авторизацию (другой, например, в алконе)
		rememberMe: false, // используется ли флаг rememberMe
		authPath: '/oauth/token', // путь к токену, может быть объектом (например, { refresh: 'sign/refresh', login: 'sign/in' })
		useHttpInstance: 'optima', // если используется несколько ссылок на api, какая используется
		useJSON: false, // использовать JSON вместо FormData
		useGrantType: true, // использовать grant_type в запросе на получение токена
		paramsToGetToken: ['username', 'password'], // передаваемые параметры для получения токена
		sso: false, // работа с sso (передача company в query при получении токена)
		companyKeyLS: 'user/company', // ключ для сохранения компании в localStorage
		pageUUIDKey: 'pageUUID', // ключ для взятия параметра pageUUID из url
		UUIDKeyLS: 'user/UUID', // ключ для сохранения UUID в localStorage
		loadIdKey: 'loadId', // ключ для взятия параметра loadId из url
		loadIdKeyLS: 'user/loadId', // ключ для сохранения loadId в localStorage
		returnToOptima: false, // возвращать ли в Оптима при разлогине (использует UUID)
		optimaUrl: import.meta.env.VITE_OPTIMA_URL, // путь к Оптима при разлогине
	},
}

const toUrlEncoded = obj => Object.keys(obj).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`).join('&')

function getCurrentInstance() {
	if (httpInstance.interceptors) {
		return httpInstance
	}
	return httpInstance[defaultOptions.http.useHttpInstance]
}


export const authStoreModule = {
	namespaced: true,
	state() {
		return {
			token: {},
			isAuthorizing: false,
			isAuthorized: false,
			company: localStorage.getItem(options.http.companyKeyLS) || '',
			UUID: localStorage.getItem(options.http.UUIDKeyLS) || '',
			loadId: localStorage.getItem(options.http.loadIdKeyLS) || '',
			isLogoutProcess: false,
		}
	},
	mutations: {
		[MUTATION_KEYS.SET_TOKEN](state, token) {
			state.token = token
			localStorage.setItem(options.http.tokenKeyLS, JSON.stringify(token))
			getCurrentInstance().defaults.headers.common.Authorization = state.token[options.http.accessTokenKey] ? `Bearer ${state.token[options.http.accessTokenKey]}` : ''
		},
		[MUTATION_KEYS.DROP_TOKEN](state) {
			state.token = {}
			localStorage.removeItem(options.http.tokenKeyLS)
			delete getCurrentInstance().defaults.headers.common.Authorization
		},
		[MUTATION_KEYS.SET_AUTHORIZED](state, status) {
			state.isAuthorized = status
		},
		[MUTATION_KEYS.SET_AUTHORIZING](state, status) {
			state.isAuthorizing = status
		},
		[MUTATION_KEYS.SET_COMPANY](state, company) {
			state.company = company

			if (company) localStorage.setItem(options.http.companyKeyLS, company)
			else localStorage.removeItem(options.http.companyKeyLS)
		},
		[MUTATION_KEYS.SET_UUID](state, UUID) {
			state.UUID = UUID

			if (UUID) localStorage.setItem(options.http.UUIDKeyLS, UUID)
			else localStorage.removeItem(options.http.UUIDKeyLS)
		},
		[MUTATION_KEYS.SET_LOGOUT_PROCESS](state, status) {
			state.isLogoutProcess = status
		},
		[MUTATION_KEYS.SET_LOAD_ID](state, loadId) {
			state.loadId = loadId

			if (loadId) localStorage.setItem(options.http.loadIdKeyLS, loadId)
			else localStorage.removeItem(options.http.loadIdKeyLS)
		},
	},
	actions: {
		init({ state, dispatch, commit }) {
			if (options.http.withCredentials) getCurrentInstance().defaults.withCredentials = true

			let isAlreadyFetchingAccessToken = false
			let subscribers = []

			const addSubscriber = cb => {
				subscribers.push(cb)
			}

			const onTokensFetched = token => {
				subscribers = subscribers.filter(callback => callback(token))
			}

			getCurrentInstance().interceptors.response.use(response => response, async error => {
				if (error.response && error.response.status === 401 && !isGetTokenRequest(error)) {
					error.disableUserNotice = true

					try {
						if (options.http.rememberMe && !state.token.rememberMe) throw error

						const originalRequest = error.config

						const retryOriginalRequest = new Promise(resolve => {
							addSubscriber(token => {
								originalRequest.headers.Authorization = `Bearer ${token}`
								resolve(getCurrentInstance().request(originalRequest))
							})
						})

						if (!isAlreadyFetchingAccessToken) {
							isAlreadyFetchingAccessToken = true
							await dispatch('setToken', { refresh: true, rememberMe: options.http.rememberMe })
							isAlreadyFetchingAccessToken = false
							onTokensFetched(state.token[options.http.accessTokenKey])
						}

						return retryOriginalRequest
					} catch (err) {
						isAlreadyFetchingAccessToken = false
						dispatch('logout')
					}
				}

				throw error
			})

			const {
				[options.http.accessTokenKey]: accessToken,
				[options.http.refreshTokenKey]: refreshToken,
				[options.http.pageUUIDKey]: pageUUID,
				[options.http.loadIdKey]: loadId,
			} = parseQuery(window?.location.search)

			if (pageUUID) commit(MUTATION_KEYS.SET_UUID, pageUUID)
			if (loadId) commit(MUTATION_KEYS.SET_LOAD_ID, loadId)

			if (accessToken && refreshToken) {
				commit(MUTATION_KEYS.SET_TOKEN, { [options.http.accessTokenKey]: accessToken, [options.http.refreshTokenKey]: refreshToken })
				commit(MUTATION_KEYS.SET_AUTHORIZED, true)
			} else dispatch('setToken')
		},
		dropToDefaults({ commit }) {
			commit(MUTATION_KEYS.DROP_TOKEN)
			commit(MUTATION_KEYS.SET_COMPANY, '')
			commit(MUTATION_KEYS.SET_UUID, '')
			commit(MUTATION_KEYS.SET_LOAD_ID, '')
		},
		async getToken({ state }, { refresh = false, ...allParams }) {
			const requiredParams = options.http.paramsToGetToken.reduce((obj, param) => {
				obj[param] = allParams[param] || null
				return obj
			}, {})

			const everyParamExist = Object.values(requiredParams).every(param => !!param)

			if (!everyParamExist && !refresh) {
				try {
					return JSON.parse(localStorage.getItem(options.http.tokenKeyLS))
				} catch (error) {
					return null
				}
			}

			if (refresh && !state.token[options.http.refreshTokenKey]) throw Error

			let credentials = { ...options.http.credentials }
			let reqOptions = null

			if (options.http.useGrantType) credentials.grant_type = (refresh ? options.http.refreshTokenKey : 'password')

			if (refresh) {
				credentials = { ...credentials, [options.http.refreshTokenKey]: state.token[options.http.refreshTokenKey] }
			} else if (everyParamExist) {
				credentials = { ...credentials, ...requiredParams }
			} else {
				throw String('Не все параметры переданы')
			}

			const { client, secret } = options.http
			if (client && secret) {
				const auth = btoa(`${client}:${secret}`)
				reqOptions = { headers: { Authorization: `Basic ${auth}` }, withCredentials: true, baseURL: '' }
			}

			if (options.http.sso) reqOptions = { ...reqOptions, params: { company: state.company } }

			const { useJSON } = options.http

			return (await getCurrentInstance().post(getFullApiTokenPath(refresh), useJSON ? credentials : toUrlEncoded(credentials), reqOptions)).data
		},
		/**
		* @description Запрашивает и кладёт в store токен.
		*
		* @param {Object} [payload] - параметры
		* @param {string} [payload.username] - имя пользователя (email/login/username)
		* @param {string} [payload.password] - пароль
		* @param {boolean} [payload.rememberMe] - флаг запомнить меня
		* @param {boolean} [payload.refresh] - обновить токен
		*/
		async setToken({ commit, dispatch }, payload = { rememberMe: false, refresh: false }) {
			commit(MUTATION_KEYS.SET_AUTHORIZING, true)

			const { rememberMe, refresh, ...params } = payload

			try {
				const token = await dispatch('getToken', { ...params, refresh })

				if (token) {
					if (options.http.rememberMe && rememberMe) token.rememberMe = true
					commit(MUTATION_KEYS.SET_TOKEN, token)
					commit(MUTATION_KEYS.SET_AUTHORIZED, true)
				} else {
					commit(MUTATION_KEYS.DROP_TOKEN)
				}
			} finally {
				commit(MUTATION_KEYS.SET_AUTHORIZING, false)
			}
		},
		login({ dispatch }, payload) {
			return dispatch('setToken', payload)
		},
		async logout({ state, commit, dispatch }, params) {
			commit(MUTATION_KEYS.SET_AUTHORIZED, false)

			const { company } = state

			if (options.http.returnToOptima && company) {
				commit(MUTATION_KEYS.SET_LOGOUT_PROCESS, true)

				let queryToOptima = ''

				if (params?.toOptima) {
					try {
						const token = await dispatch('getToken', { refresh: true })
						const { UUID, loadId } = state

						// TODO можно убрать значение по умолчанию для loadId после обновлении Optima на проде
						queryToOptima = `?accessToken=${token[options.http.accessTokenKey]}&pageUUID=${UUID}&loadId=${loadId || 'stockNewRequest'}`
					} catch {} // eslint-disable-line
				}

				window.location.href = `${options.http.optimaUrl}/${company}${queryToOptima}`
			}

			commit(MUTATION_KEYS.DROP_TOKEN)

			Object.keys(this._actions) // eslint-disable-line
				.filter(action => /dropToDefaults/.test(action))
				.forEach(action => dispatch(action, null, { root: true }))
		},
	},
}

export default {
	install(Vue, { store, httpConfig = {} }) {
		options = {
			http: {
				...defaultOptions.http,
				...httpConfig,
			},
		}

		Object.freeze(options)

		if (!store) throw new Error('Auth: store instance is required as Plugin options property')
		if (store.state[AUTH_MODULE_NAME]) throw new Error(`Auth: module "${AUTH_MODULE_NAME}" already exist`)

		store.registerModule(AUTH_MODULE_NAME, authStoreModule)
		store.dispatch(`${AUTH_MODULE_NAME}/init`)
	},
}