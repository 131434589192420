<template>
	<div
		class="it-select-picker__item flex-shrink text-center border-box"
		:class="{ active: isActive, 'in-range': inRange }"
		@click="changeStatus"
	>{{ item.label }}</div>
</template>

<script>
export default {
	name: 'SelectPickerItem',
	props: {
		item: {
			type: Object,
			required: true,
		},
		value: {
			type: Array,
			required: true,
		},
		range: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	computed: {
		itemIndex() {
			return this.value.indexOf(this.item.value)
		},
		isActive() {
			return this.itemIndex !== -1
		},
		inRange() {
			if (!this.range || this.value.length < 2) return false

			return this.item.value > this.value[0] && this.item.value < this.value[1]
		},
	},
	methods: {
		changeStatus() {
			if (this.range && this.value.length === 2) this.value.splice(0, 2, this.item.value)
			else if (this.range && this.value.length === 1 && this.value[0] > this.item.value) this.value.splice(0, 0, this.item.value)
			else if (this.isActive) this.value.splice(this.itemIndex, 1)
			else {
				const localValue = [...this.value]
				localValue.push(this.item.value)
				localValue.sort((a, b) => a - b)
				this.value.splice(0, this.value.length, ...localValue)
			}
		},
	},
}
</script>