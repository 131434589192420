// import { Vue } from 'vue'
// import VueI18n from 'vue-i18n'
import { createI18n } from 'vue-i18n'

import { defineAsyncComponent } from 'vue'
import getCurrentLocale from '~/utils/locales'

const DEFAULT_LANG = 'ru'
const locale = getCurrentLocale()
const messages = await import (`../../assets/locales/${locale}.json`) // eslint-disable-line

const i18n = createI18n({
	locale: DEFAULT_LANG,
	globalInjection: true,
	fallbackLocale: 'en',
	legacy: false,
	allowComposition: true, // you need to specify that!
	messages: {
		[DEFAULT_LANG]: messages.default,
	},
})


// Далее большая часть кода отсюда http://kazupon.github.io/vue-i18n/ru/guide/lazy-loading.html

// список локализаций, которые пред-загружены
const loadedLanguages = [locale]

function setI18nLanguage(language) {
	i18n.locale = language
	document.querySelector('html').setAttribute('lang', language)
	return language
}

export async function loadLanguageAsync(language) {
	// Если локализация та же
	if (i18n.locale === language) {
		setI18nLanguage(language)
		return
	}

	// Если локализация уже была загружена
	if (loadedLanguages.includes(language)) {
		setI18nLanguage(language)
		return
	}

	// Если локализация ещё не была загружена
	try {
		const translations = defineAsyncComponent(() => import(`../../assets/locales/${language}.json`))
		i18n.setLocaleMessage(language, translations.default)
		loadedLanguages.push(language)
		setI18nLanguage(language)
	} catch (err) {
		setI18nLanguage(DEFAULT_LANG)
	}
}

export default i18n