import i18n from '~/plugins/i18n'

export default () => [
	{
		label: i18n.global.t('Стоимость от'),
		name: 'priceFrom',
		placeholder: i18n.global.t('Введите'),
		type: 'inputCurrency',
	},
	{
		label: i18n.global.t('Стоимость до'),
		name: 'priceTo',
		placeholder: i18n.global.t('Введите'),
		type: 'inputCurrency',
	},
]