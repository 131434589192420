<template>
	<div
		class="com-order-table-quantity"
		:class="{ 'com-order-table-quantity--error': isOverload || isInvalid }"
		@click.stop
	>
		<el-tooltip
			v-model="isTooltipVisible"
			:disabled="errorTooltip.disabled"
			:content="errorTooltip.content"
			placement="top"
			effect="dark"
		>
			<el-input
				ref="comOrderTableQuantity"
				v-model="quantity"
				:disabled="isInputDisabled"
				class="com-order-table-quantity__input"
				size="default"
				data-ta="com-order-table-input"
				type="number"
				@update:modelValue="setQuantity"
				@blur="onBlur"
				@focus="onFocus"
			/>
		</el-tooltip>

		<el-popover
			v-model:visible="popoverVisible"
			:close-delay="0"
			:title="popoverTitle"
			trigger="contextmenu"
			popper-class="com-order-table-quantity-popover"
			width="269"
			@keydown.esc="onClose"
		>
			<template #reference>
				<div
					v-if="isChangable"
					class="com-order-table-quantity__controls"
				>
					<div
						class="d-flex justify-content-center control-btn"
						:class="{ 'is-disabled': isCountUpdating || isDisabledRising }"
					>
						<el-icon
							:size="12"
							@click="raiseCount"
						>
							<Arrow-Up/>
						</el-icon>
					</div>
					<div
						class="d-flex justify-content-center control-btn"
						:class="{ 'is-disabled': isCountUpdating || isDisabledDowning }"
					>
						<el-icon
							:size="12"
							@click="downCount"
						>
							<Arrow-Down/>
						</el-icon>
					</div>
				</div>
				<el-button
					v-else
					size="mini"
					class="com-order-table-quantity__button button-delete color-text-regular"
					@click="openPopover"
				>
					<el-icon
						:size="12"
					>
						<Close/>
					</el-icon>
				</el-button>
			</template>

			<template v-if="showPopoverBody">
				<div class="popover-body" data-ta="deleteGroupItems">
					<el-checkbox
						v-for="checkbox of checkboxes"
						:key="checkbox.id"
						v-model="checkbox.isChoosen"
						class="checkbox"
						:label="checkbox.id"
					/>
				</div>
				<div class="d-flex justify-content-flex-end">
					<el-button
						type="primary"
						plain
						@click="onClose"
					>{{ $t('Отмена') }}</el-button>
					<el-button
						:disabled="!disabledDeleting"
						:class="{ disabled: !disabledDeleting }"
						type="primary"
						class="ml-3"
						@click="onDelete(true)"
					>{{ $t('Удалить') }}</el-button>
				</div>
			</template>

			<div v-else class="d-flex justify-content-center">
				<el-button
					type="primary"
					plain
					class="w-100p"
					@click="onClose"
				>{{ $t('Отмена') }}</el-button>
				<el-button
					type="primary"
					class="w-100p ml-3"
					@click="onDelete(false)"
				>{{ $t('Удалить') }}</el-button>
			</div>
		</el-popover>
	</div>
</template>

<script>
import { mapMutations } from 'vuex'
import multiplicityCLPackageTest from '~/utils/multiplicityCLPackageTest'

export default {
	name: 'ComOrderTableQuantity',
	props: {
		row: {
			type: Object,
			default: null,
			required: true,
		},
		modelValue: {
			type: Number,
			default: 0,
		},
		isCountUpdating: {
			type: Boolean,
			default: false,
		},
		changable: {
			type: Boolean,
			default: false,
		},
		max: {
			type: Number,
			default: 100,
		},
		min: {
			type: Number,
			default: 0,
		},
		from: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			quantity: 0,
			popoverVisible: false,
			isTooltipVisible: false,
			selectedRowItems: [],
			throttleTO: null,
			checkboxes: null,
		}
	},
	computed: {
		isChangable() {
			return this.row?.countUpdatable || this.changable
		},
		rowItems() {
			return this.row?.cartItems || []
		},
		popoverTitle() {
			const unavailableСategories = ['UNMARKED_GOODS', 'SERVE']
			const title1 = this.$t('Удалить позицию из заказа?')
			const title2 = this.$t('Товар с каким штрихкодом нужно удалить?')

			if (unavailableСategories.includes(this.row.sellableCategory)) {
				return title1
			}

			return this.rowItems.length === 1
				? title1
				: title2
		},
		showPopoverBody() {
			const unavailableСategories = ['UNMARKED_GOODS', 'SERVE']

			if (unavailableСategories.includes(this.row.sellableCategory)) {
				return false
			}

			return this.rowItems.length > 1
		},
		disabledDeleting() {
			return !!this.checkboxes
				.filter(checkbox => checkbox.isChoosen)
				.length
		},
		errorTooltip() {
			let content = `${this.$t('Доступно для продажи:')} ${this.max}`

			if (this.row.sellableCategory === 'CONTACT_LENS') {
				content = this.isOverload
					? this.$t('Блистеров в упаковке: {0}', [this.max])
					: this.$t('Стоимость пачки не кратна стоимости выбранного количества линз')
			}

			return {
				disabled: (!this.isOverload && !this.isInvalid) || !this.isChangable,
				content,
			}
		},
		isInvalid() {
			if (this.row.onlyFullPackages) return false

			return this.isCLWrongQuantity || this.isOverload
		},
		isOverload() {
			return this.max < this.quantity
		},
		isCLWrongQuantity() {
			if (this.row.sellableCategory === 'CONTACT_LENS' && !this.row.factory) {
				const { packageQuantity, retailPrice } = this.row
				return !multiplicityCLPackageTest(retailPrice, packageQuantity, this.quantity)
			}
			return false
		},
		isInputDisabled() {
			if (!this.isChangable) {
				return true
			}

			return this.isCountUpdating
		},
		isGoodsCart() {
			return this.from === 'goodsCart'
		},
		isDisabledRising() {
			return this.quantity === this.max
				|| this.quantity > this.max
		},
		isDisabledDowning() {
			return this.quantity === this.min
				|| this.quantity < this.min
		},
	},
	watch: {
		rowItems: {
			handler: 'setCheckboxes',
			immediate: true,
			deep: true,
		},
		popoverVisible(val) {
			if (val) {
				this.$emit('onOpenPopover', true)
			} else {
				this.$emit('onOpenPopover', false)
			}
		},
		modelValue: {
			handler(val) {
				this.quantity = val
			},
			immediate: true,
		},
		isTooltipVisible: {
			handler(val) {
				this.$emit('isTooltipVisible', val)
			},
		},
	},
	methods: {
		...mapMutations('orders', [
			'mOrderErrors',
		]),
		throttleCount(val, manualInput = false) {
			if (val < this.min) {
				return
			} if (val > this.max) {
				this.quantity = val
				this.setOrderErrors()
				return
			}

			this.quantity = val
			this.setOrderErrors()

			// Если изменяем кол-во ручным вводом в инпут, то даем пользователю 500 мс
			// Чтобы успеть вести все цифры 
			const throttlingSize = manualInput
				? 500
				: 0

			if (this.throttleTO) {
				clearTimeout(this.throttleTO)
			}

			this.throttleTO = setTimeout(() => {
				this.$emit('update', this.quantity)
			}, throttlingSize)
		},
		raiseCount() {
			if (this.isGoodsCart) {
				this.popoverVisible = false
			}

			const value = this.quantity + 1

			if (value > this.max) return

			this.throttleCount(value)
		},
		downCount() {
			if (this.isGoodsCart) {
				if (this.quantity === 0) return

				if (this.quantity === 1) {
					this.popoverVisible = true
					return
				}
			}

			const value = this.quantity - 1

			this.throttleCount(value)
		},
		setQuantity(value) {
			if (this.isGoodsCart) {
				if (value === '') {
					if (this.throttleTO) {
						clearTimeout(this.throttleTO)
					}
					return
				}

				if (Number(value) < 0) {
					this.quantity = this.rowItems.length
					return
				}

				if (Number(value) === 0) {
					this.quantity = 0
					this.popoverVisible = true
					return
				}

				this.popoverVisible = false
			}

			this.throttleCount(Number(value), true)
		},
		setOrderErrors() {
			// У сгруппированного товара в корзине нет id
			const row = this.from === 'goodsCart'
				? this.row.model
				: this.row.id

			this.mOrderErrors({
				from: this.from,
				error: {
					[row]: this.isOverload || this.isCLWrongQuantity,
				},
			})
		},
		openPopover() {
			this.popoverVisible = true
		},
		onClose() {
			this.popoverVisible = false
			if (!this.showPopoverBody && !this.quantity) {
				this.quantity = 1
			} else if (this.showPopoverBody && !this.quantity) {
				this.quantity = this.rowItems.length
			}
			this.$emit('onOpenPopover', false)
		},
		onDelete(islist) {
			if (this.row.countUpdatable) {
				// При быстром изменении кол-ва товаров
				// И при последующем удалении бэк не успевает обрабатывать запросы

				setTimeout(() => { this.$emit('update', 0) }, 900)
			} else if (this.rowItems.length === 1) {
				const { id } = this.rowItems[0]
				this.$emit('onDelete', id)
			} else if (islist) {
				const ids = this.checkboxes
					.filter(item => item.isChoosen)
					.map(item => item.id)
				this.$emit('onDelete', ids)
			} else {
				const ids = this.rowItems
					.map(item => item.id)
				this.$emit('onDelete', ids)
			}

			this.popoverVisible = false
			this.$emit('onOpenPopover', false)
		},
		setCheckboxes() {
			this.checkboxes = this.rowItems
				.map(item => ({
					...item,
					isChoosen: false,
				}))
		},
		onBlur() {
			if (!this.quantity) {
				this.quantity = this.rowItems.length
			}
		},
		onFocus() {
			this.$refs.comOrderTableQuantity.select()
		},
	},
}
</script>

<style lang="scss">

.com-order-table-quantity {
	display: flex;
	align-items: center;

	.el-input__inner {
		padding-right: 5px;
		padding-left: 5px;
		text-align: center;
		border-radius: 4px;
		border-bottom-right-radius: 0;
		border-top-right-radius: 0;
		border-right: 0;
	}

	.el-input__wrapper {
		padding: 0;
		border: 1px solid #cbcbd5 !important;
	}

	&__input {
		width: 32px;

		input {
			background-color: #fff !important;
			color: black !important;
			border: 0;
		}
	}

	.el-input.is-disabled {
		opacity: 1 !important;

		.el-input__inner {
			color: var(--el-text-placeholder-dark) !important;
			opacity: 1 !important;
			background: var(--el-border-color-extra-light) !important;
		}
	}

	.shewron-icon {
		font-weight: 600;
	}

	.el-button--mini {
		padding: 6px 10px;
	}

	&__button {
		width: 28px;
		border-bottom-left-radius: 0;
		border-top-left-radius: 0;
		border-bottom-right-radius: 4px;
		border-top-right-radius: 4px;
		border: 1px solid #cbcbd5;
		border-left: 0;
	}

	&__controls {
		width: 28px;
		border: 1px solid #cbcbd5;
		border-bottom-left-radius: 0;
		border-top-left-radius: 0;
		border-bottom-right-radius: 4px;
		border-top-right-radius: 4px;
		background: var(--el-background-color-base);
		margin-left: -1px;
	}

	.button-delete {
		background: var(--el-background-color-base);
		color: var(--el-color-text-regular);
		font-size: 1.1rem;
		padding: 7px 12px;
		border-bottom-left-radius: 0;
		border-top-left-radius: 0;
		// border-left: 0;
	}

	.close-icon {
		font-size: 1.1rem;
		line-height: 10;
	}

	.not-allowed-cursor {
		cursor: not-allowed
	}

	.control-btn {
		cursor: pointer;
		color: var(--el-color-text-regular);
		font-weight: bold;

		&:first-child {
			border-bottom: 1px solid #cbcbd5;
			padding-top: 1px;
		}

		&:hover {
			color: var(--el-color-primary)
		}
	}

	&--error {
		input {
			border-color: var(--el-color-danger) !important;
		}
	}

	&--error &__controls {
		border-top-color: var(--el-color-danger);
		border-right-color: var(--el-color-danger);
		border-bottom-color: var(--el-color-danger);
	}

	.el-checkbox__label {
		margin: 8px !important;
	}

	.disabled {
		background: #f2f6fc !important;
		border: 1px solid #cbcbd5 !important;
		color: #c0c4cc !important;
	}

	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		/* display: none; <- Crashes Chrome on hover */
		-webkit-appearance: none;
		margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
	}

	.is-disabled {
		pointer-events: none;

		i {
			color: var(--el-text-placeholder-dark);
		}
	}
}

.el-popover__title {
	font-weight: 600;
}

.com-order-table-quantity-popover {
	.popover-body {
		display: flex;
		flex-direction: column;
		margin-bottom: 4px;
		max-height: 200px;
		overflow-y: auto;
	}
}
</style>