import { defineAsyncComponent } from 'vue'
import { USER_AUTHORITIES } from '~/utils/consts'
import LayoutPage from '~/layout/LayoutPage.vue'
import LayoutPageTabs from '~/layout/LayoutPageTabs.vue'
import LayoutPageTabs2 from '~/layout/LayoutPageTabs2.vue'
import i18n from '~/plugins/i18n'

export default [
	{
		name: 'ClientView',
		path: '/client/:id/view',
		meta: {
			title: 'Просмотр клиента',
		},
		component: defineAsyncComponent(() => import('~/pages/client/ClientView')),
	},
	{
		name: 'ClientOrders',
		path: '/client/:id/orders',
		meta: {
			title: 'Заказы клиента',
		},
		component: defineAsyncComponent(() => import('~/pages/client/ClientOrders')),
	},
	{
		name: 'ClientContactLenses',
		path: '/client/:id/contact-lenses',
		meta: {
			title: 'Проданные контактные линзы',
		},
		component: defineAsyncComponent(() => import('~/pages/client/ClientContactLenses')),
	},
	{
		name: 'DepartmentNew',
		path: '/department/new',
		meta: {
			title: 'Создание департамента',
		},
		component: defineAsyncComponent(() => import('~/pages/departments/DepartmentNew')),
	},
	{
		name: 'LegalEntityCreate',
		path: '/legal-entity-create',
		meta: {
			title: 'Создание юр.лица',
		},
		component: defineAsyncComponent(() => import('~/pages/legal-entities/LegalEntityCreate')),
	},
	{
		name: 'License',
		path: '/licences/:id',
		meta: {
			title: 'Лицензия',
		},
		component: defineAsyncComponent(() => import('~/pages/licenses/License')),
	},
	{
		name: 'LicenseCreate',
		path: '/licenses/create',
		meta: {
			title: 'Создание лицензии',
		},
		component: defineAsyncComponent(() => import('~/pages/licenses/LicenseCreate')),
	},
	{
		name: 'Directories',
		path: '/directories',
		meta: {
			title: 'Справочники',
		},
		component: defineAsyncComponent(() => import('~/pages/directories/Directories')),
	},
	{
		name: 'NewsView',
		path: '/news/view',
		alias: '/news/page',
		meta: {
			title: 'Просмотр новости',
		},
		component: defineAsyncComponent(() => import('~/pages/news/NewsView')),
	},


	// TEMPLATES
	{
		name: 'ClientNew',
		path: '/client/new',
		component: LayoutPage,
		meta: {
			title: 'Новый клиент',
		},
		props: {
			Page: defineAsyncComponent(() => import('~/pages/client/ClientNew')),
		},
	},
	{
		name: 'ClientEdit',
		path: '/client/:id',
		alias: '/client/edit/:id',
		component: LayoutPage,
		meta: {
			title: 'Редактирование клиента',
		},
		props: {
			Page: defineAsyncComponent(() => import('~/pages/client/ClientEdit')),
		},
	},
	{
		name: 'BarcodesOnSheet',
		path: '/barcodes-on-sheet',
		component: LayoutPage,
		meta: {
			title: 'Штрихкоды на листе',
		},
		props: {
			Page: defineAsyncComponent(() => import('~/pages/barcodes-on-sheet/BarcodesOnSheet')),
		},
	},
	{
		name: 'BarcodesSettings',
		path: '/barcodes-settings',
		component: LayoutPageTabs,
		meta: {
			title: 'Настройки штрихкодов',
		},
		props: {
			Page: defineAsyncComponent(() => import('~/pages/barcodes-settings/BarcodesSettings')),
		},
	},
	{
		name: 'ApplicationSettings',
		path: '/settings/application-settings/:tab?',
		component: LayoutPageTabs2,
		meta: {
			title: 'Настройки приложения',
			accessGranted: [
				USER_AUTHORITIES.ROLE_ADMIN,
			],
		},
		props: {
			Tabs: [
				{
					tab: 'general',
					name: 'General',
					label: i18n.global.t('Общие'),
					component: defineAsyncComponent(() => import('~/pages/application-settings/TabGeneral.vue')),
				},
				{
					tab: 'security',
					name: 'Security',
					label: i18n.global.t('Безопасность'),
					component: defineAsyncComponent(() => import('~/pages/application-settings/TabSecurity.vue')),
				},
				{
					tab: 'cl-expiration-date',
					name: 'CLExpirationDate',
					label: i18n.global.t('КЛ: срок годности'),
					component: defineAsyncComponent(() => import('~/pages/application-settings/TabCLExpirationDate.vue')),
				},
				{
					tab: 'information-fields',
					name: 'InformationFields',
					label: i18n.global.t('Информационные поля'),
					component: defineAsyncComponent(() => import('~/pages/application-settings/TabInformationFields.vue')),
				},
				{
					tab: 'medical-card',
					name: 'MedicalCard',
					label: i18n.global.t('Медицинская карта'),
					component: defineAsyncComponent(() => import('~/pages/application-settings/TabMedicalCard.vue')),
				},
				{
					tab: 'n3health-integration',
					name: 'n3healthIntegration',
					label: i18n.global.t('Интеграция с n3health (ЕГИСЗ)'),
					component: defineAsyncComponent(() => import('~/pages/application-settings/TabN3healthIntegration.vue')),
				},
			],
		},
	},
	{
		name: 'Department',
		component: LayoutPageTabs,
		path: '/department/:id',
		meta: {
			title: 'Департамент',
		},
		props: {
			Page: defineAsyncComponent(() => import('~/pages/departments/Department')),
		},
	},
	{
		name: 'LegalEntity',
		path: '/legal-entity/:id',
		component: LayoutPageTabs,
		meta: {
			title: 'Юридическое лицо',
		},
		props: {
			Page: defineAsyncComponent(() => import('~/pages/legal-entities/LegalEntity')),
		},
	},
	{
		name: 'SalesPlan',
		path: '/sales-plan/:salesPlanId',
		component: LayoutPage,
		meta: {
			title: 'План продаж',
		},
		props: {
			Page: defineAsyncComponent(() => import('~/pages/sales-plan/SalesPlan')),
		},
	},
	{
		name: 'Unload1C',
		path: '/unload-1c',
		component: LayoutPageTabs,
		meta: {
			title: 'Выгрузка в 1С',
		},
		props: {
			Page: defineAsyncComponent(() => import('~/pages/unload-1c/Unload1C')),
		},
	},
	{
		name: 'User',
		path: '/user/:id',
		component: LayoutPage,
		meta: {
			title: 'Пользователь',
		},
		props: {
			Page: defineAsyncComponent(() => import('~/pages/users/User')),
		},
	},
	{
		name: 'UserCreate',
		path: '/create-user',
		component: LayoutPage,
		meta: {
			title: 'Создание пользователя',
		},
		props: {
			Page: defineAsyncComponent(() => import('~/pages/users/UserCreate')),
		},
	},
	{
		name: 'NewsEdit',
		path: '/news/edit',
		component: LayoutPage,
		meta: {
			title: 'Создание и редактирование новости',
		},
		props: {
			Page: defineAsyncComponent(() => import('~/pages/news/NewsEdit')),
		},
	},

	// Для сброса корзины заказа
	{
		name: 'OrderCompletionPage',
		path: '/order-completion',
	},

	{
		name: 'IntegrationSettings',
		path: '/integration-settings/:id',
		component: LayoutPage,
		meta: {
			title: 'Настройки интеграции',
		},
		props: {
			Page: defineAsyncComponent(() => import('~/pages/integration-settings/IntegrationSettings')),
		},
	},

	{
		name: 'StandradDiscountCreation',
		path: '/standrad-discount-creation',
		component: LayoutPage,
		props: {
			Page: defineAsyncComponent(() => import('~/pages/discounts-creation/StandradDiscountCreation.vue')),
		},
	},

	{
		name: 'AutomaticDiscountCreation',
		path: '/automatic-discount-creation',
		component: LayoutPage,
		props: {
			Page: defineAsyncComponent(() => import('~/pages/discounts-creation/AutomaticDiscountCreation.vue')),
		},
	},

	{
		name: 'ClientCardDiscountCreation',
		path: '/client-card-discount-creation',
		component: LayoutPage,
		props: {
			Page: defineAsyncComponent(() => import('~/pages/discounts-creation/ClientCardDiscountCreation.vue')),
		},
	},

	{
		name: 'CLReplacementDiscountCreation',
		path: '/cl-replacement-discount-creation',
		component: LayoutPage,
		props: {
			Page: defineAsyncComponent(() => import('~/pages/discounts-creation/CLReplacementDiscountCreation.vue')),
		},
	},
	{
		name: 'MarkdownDiscountCreation',
		path: '/markdown-discount-creation',
		component: LayoutPage,
		props: {
			Page: defineAsyncComponent(() => import('~/pages/discounts-creation/MarkdownDiscountCreation.vue')),
		},
	},
	{
		name: 'CompilanceRegisterCreation',
		path: '/compilance-register-creation',
		component: LayoutPage,
		meta: {
			title: 'Добавление сертификата',
			accessGranted: [
				USER_AUTHORITIES.ROLE_TOP_MANAGER,
				USER_AUTHORITIES.ROLE_MANAGER,
				USER_AUTHORITIES.ROLE_DEPOT,
			],
		},
		props: {
			Page: defineAsyncComponent(() => import('~/pages/compilance-register/CompilanceRegisterCreation.vue')),
		},
	},
	{
		name: 'CompilanceRegisterEdit',
		path: '/compilance-register/:id',
		component: LayoutPage,
		meta: {
			title: 'Просмотр сертификата',
			accessGranted: [
				USER_AUTHORITIES.ROLE_TOP_MANAGER,
				USER_AUTHORITIES.ROLE_MANAGER,
				USER_AUTHORITIES.ROLE_ACCOUNTANT,
				USER_AUTHORITIES.ROLE_DEPOT,
				USER_AUTHORITIES.ROLE_STORE,
			],
		},
		props: {
			Page: defineAsyncComponent(() => import('~/pages/compilance-register/CompilanceRegisterEdit.vue')),
		},
	},
	{
		name: 'MailingView',
		component: LayoutPage,
		path: '/mailing/:id',
		meta: {
			title: 'Рассылка',
		},
		props: {
			Page: defineAsyncComponent(() => import('~/pages/mailing/MailingView.vue')),
		},
	},
	{
		name: 'ClientsFilter',
		path: '/clients-filters/:id',
		component: LayoutPage,
		meta: {
			title: 'Фильтр клиента',
			accessGranted: [
				USER_AUTHORITIES.ROLE_TOP_MANAGER,
				USER_AUTHORITIES.ROLE_MANAGER,
				USER_AUTHORITIES.ROLE_ACCOUNTANT,
				USER_AUTHORITIES.ROLE_DEPOT,
				USER_AUTHORITIES.ROLE_STORE,
			],
		},
		props: {
			Page: defineAsyncComponent(() => import('~/pages/clients-filters/ClientsFilter.vue')),
		},
	},
	{
		name: 'ClientRelations',
		path: '/client-relations/:id',
		meta: {
			title: 'Связи клиента',
		},
		component: defineAsyncComponent(() => import('~/pages/client/ClientRelations')),
	},
	{
		name: 'ClientDuplicates',
		path: '/client-duplicates',
		meta: {
			title: 'Client duplicates',
		},
		component: () => import('~/pages/client/ClientDuplicates'),
	},
]