import { GOODS_TYPES } from '~/helpers/common/GoodsTypes.js'

export default {
	gCertificatesGoodsTypes(state) {
		return state.certificatesGoodsTypes.map(item => ({
			value: item,
			label: GOODS_TYPES[item].label,
		}))
	},
	gAccessoriesCategoriesOptions(state) {
		return state.accessoriesCategories
			.map(item => ({
				label: item,
				value: item,
			}))
	},
	gAccessoriesModelsOptions(state) {
		return state.accessoriesModels
			.map(item => ({
				label: item,
				value: item,
			}))
	},
	gContactLensManufacturersOptions(state) {
		return state.contactLensManufacturers
			.map(item => ({
				label: item,
				value: item,
			}))
	},
	gContactLensNamesOptions(state) {
		return state.contactLensNames
			.map(item => ({
				label: item,
				value: item,
			}))
	},
	gGlassesManufacturersOptions(state) {
		return state.glassesManufacturers
			.map(item => ({
				label: item,
				value: item,
			}))
	},
	gGlassesBrandsOptions(state) {
		return state.glassesBrands
			.map(item => ({
				label: item,
				value: item,
			}))
	},
	gGlassesBrandsByManufacturerOptions(state) {
		return state.glassesBrandsByManufacturer
			.map(item => ({
				label: item,
				value: item,
			}))
	},
	gLensManufacturersOptions(state) {
		return state.lensManufacturers
			.map(item => ({
				label: item,
				value: item,
			}))
	},
	gLensBrandsOptions(state) {
		return state.lensBrands
			.map(item => ({
				label: item,
				value: item,
			}))
	},
	gLensBrandsByManufacturerOptions(state) {
		return state.lensBrandsByManufacturer
			.map(item => ({
				label: item,
				value: item,
			}))
	},
	gSunglassesManufacturersOptions(state) {
		return state.sunglassesManufacturers
			.map(item => ({
				label: item,
				value: item,
			}))
	},
	gSunglassesBrandsOptions(state) {
		return state.sunglassesBrands
			.map(item => ({
				label: item,
				value: item,
			}))
	},
	gSunglassesBrandsByManufacturerOptions(state) {
		return state.sunglassesBrandsByManufacturer
			.map(item => ({
				label: item,
				value: item,
			}))
	},
}