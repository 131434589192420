export default function () {
	return {
		applicationSettings: null,
		securitySettings: null,
		informationSettings: null,
		medicalCardSettings: null,
		CLExpirationDateSettings: null,
		lensesCategories: null,
		validatorTypes: null,
		lifetimeRules: null,
		wearingPeriod: null,
	}
}