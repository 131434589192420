import i18n from '~/plugins/i18n'

export const cartColumns = [
	{
		prop: 'type',
		label: i18n.global.t('Тип'),
		width: 112,
		align: 'left',
		sortable: true,
		slot: 'type',
	},
	{
		prop: 'description',
		label: i18n.global.t('Описание'),
		width: 342,
		align: 'left',
		sortable: false,
		slot: 'description',
	},
	{
		prop: 'retailPrice',
		label: i18n.global.t('Цена'),
		width: 84,
		align: 'left',
		sortable: false,
		slot: 'retailPrice',
	},
	{
		prop: 'currentQuantity',
		label: i18n.global.t('Кол-во'),
		width: 84,
		align: 'left',
		sortable: false,
		slot: 'currentQuantity',
	},
	{
		prop: 'discounts',
		label: i18n.global.t('Скидка'),
		align: 'right',
		sortable: false,
		slot: 'discounts',
	},
	{
		prop: 'cost',
		label: i18n.global.t('Стоимость'),
		width: 84,
		align: 'right',
		sortable: false,
		slot: 'cost',
	},
]