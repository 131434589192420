import { isMainIframe } from '../iframes'
import { getStyleSheet } from './utils/getStyleSheet'
import { deleteStyleSheetRule } from './utils/deleteStyleSheetRule'

export default function updateElDialogIframeStyles() {
	if (!isMainIframe()) return

	const iframeLegacyScreenPositions = window.iframeLegacyScreenPositions

	if (!iframeLegacyScreenPositions) return

	const elements = document.getElementsByClassName('el-dialog')
	const topPadding = (iframeLegacyScreenPositions.headerHeight || 63) + 20
	const top = iframeLegacyScreenPositions.windowScrollY + topPadding
	const horizontalPadding = iframeLegacyScreenPositions.horizontalMargins

	// запрет на сдвиг за пределы iframe
	Array.from(elements).forEach(el => {
		if (el.offsetHeight + top < window.innerHeight) {
			el.style.marginTop = `${top}px`
		}
	})

	// общий стиль для срабатывания при первом открытии до скролла
	const styleSheet = getStyleSheet()
	if (!styleSheet) return

	const maxHeight = Math.max(200, window.innerHeight - topPadding * 2)

	const styleDialogString = `
	margin-top: ${top}px;
	`
	const styleDialogBodyString = `
	max-height: ${maxHeight}px;
	`
	const styleDialogOverlayString = `
	padding-right: ${horizontalPadding}px;
	`
	const selector = '.el-dialog'
	const selectorBody = '.el-dialog .el-dialog__body'
	const selectorOverlayDialog = '.el-overlay-dialog'

	deleteStyleSheetRule(styleSheet, selector)
	deleteStyleSheetRule(styleSheet, selectorBody)
	deleteStyleSheetRule(styleSheet, selectorOverlayDialog)

	styleSheet.insertRule(`${selector} {${styleDialogString}}`, styleSheet.cssRules.length)
	styleSheet.insertRule(`${selectorBody} {${styleDialogBodyString}}`, styleSheet.cssRules.length)
	styleSheet.insertRule(`${selectorOverlayDialog} {${styleDialogOverlayString}}`, styleSheet.cssRules.length)
}