import { defineAsyncComponent } from 'vue'
import BonusPointsByFilters from '~/pages/bonus/BonusPointsByFilters'
import BonusRule from '~/pages/bonus/BonusRule'
import BonusSettings from '~/pages/bonus/BonusSettings'
import Bonus from '~/pages/Bonus'


export default [
	{
		name: 'NotificationSettings',
		path: '/notification/settings/:notificationType?',
		meta: {
			title: 'Настройки оповещений',
		},
		component: defineAsyncComponent(() => import('~/pages/NotificationSettings')),
	},
	{
		name: 'Report',
		path: '/report',
		meta: {
			title: 'Отчёт',
		},
		component: defineAsyncComponent(() => import('~/pages/Report')),
	},
	{
		name: 'Bonus',
		path: '/bonus',
		alias: '/notification/bonus/rules',
		meta: {
			title: 'Бонусы',
		},
		component: Bonus,
	},
	{
		name: 'BonusPointsByFilters',
		path: '/bonus/by-filter/new',
		meta: {
			title: 'Новое правило начисления баллов по фильтру',
		},
		component: BonusPointsByFilters,
	},
	{
		name: 'BonusPointsByFiltersEdit',
		path: '/bonus/by-filter/:type/:id',
		props: true,
		meta: {
			title: 'Правило начисления баллов по фильтру',
		},
		component: BonusPointsByFilters,
	},
	{
		name: 'BonusRule',
		path: '/bonus/rule/new',
		meta: {
			title: 'Новое правило бонусной системы',
		},
		component: BonusRule,
	},
	{
		name: 'BonusRuleEdit',
		path: '/bonus/rule/:mode/:ruleType/:id',
		props: true,
		meta: {
			title: 'Правило бонусной системы',
		},
		component: BonusRule,
	},
	{
		name: 'BonusSettings',
		path: '/bonus/settings',
		meta: {
			title: 'Настройки бонусной системы',
		},
		component: BonusSettings,
	},
]