<template>
	<div
		:data-ta="`input-number_${name}`"
		class="com-field-item com-field-input-number"
	>
		<PartFieldView
			v-if="!isEdit"
			v-model="viewValue"
			:name="name"
			:label="label"
			:label-width="labelWidth"
			:label-color-dot="labelColorDot"
			:link-pattern="linkPattern"
			:show-label="showLabel"
			:is-html="isHtml"
			:on-click="onClick"
		/>

		<el-form-item
			v-else
			:ref="name"
			:prop="name"
			:label="label"
			:label-width="labelWidth"
		>

			<PartInfoTooltip
				v-if="infoLabelTooltip"
				:info-tooltip="infoLabelTooltip"
				label-tooltip
			/>

			<div class="d-flex align-items-center w-100p">
				<el-input-number
					v-if="mask"
					v-model="model"
					v-mask="mask"
					:step="step"
					:placeholder="placeholder"
					:data-ta="name"
					:clearable="clearable"
					:disabled="disabled || readonly"
					:maxlength="maxLength"
					:controls="controls"
					:controls-position="controlsPosition"
					:min="min"
					:max="max"
					:show-word-limit="showWordLimit"
					:precision="precision"
					:size="size"
					:value-on-clear="valueOnClear"
					:class="inputClassName"
					class="w-100p"
				/>
				<el-input-number
					v-else
					v-model="model"
					:placeholder="placeholder"
					:step="step"
					:data-ta="name"
					:clearable="clearable"
					:disabled="disabled || readonly"
					:maxlength="maxLength"
					:controls="controls"
					:controls-position="controlsPosition"
					:min="min"
					:max="max"
					:show-word-limit="showWordLimit"
					:precision="precision"
					:size="size"
					:value-on-clear="valueOnClear"
					:class="inputClassName"
					class="w-100p"
				/>

				<PartInfoTooltip
					v-if="infoTooltip"
					:info-tooltip="infoTooltip"
				/>

			</div>
		</el-form-item>
	</div>
</template>

<script>
import { mask } from 'vue-the-mask'
import defaultProps from './defaultFieldsProps'
import PartFieldView from './parts/PartFieldView'
import PartInfoTooltip from './parts/PartInfoTooltip'

export default {
	name: 'ComFieldInputNumber',
	components: {
		PartInfoTooltip,
		PartFieldView,
	},
	directives: {
		mask,
	},
	props: {
		...defaultProps,
		max: {
			type: Number,
			default: undefined,
		},
		min: {
			type: Number,
			default: undefined,
		},
		format: {
			type: String,
			default: '',
		},
		mask: {
			type: String,
			default: '',
		},
		controlsPosition: {
			type: String,
			default: '',
		},
		controls: {
			type: Boolean,
			default: true,
		},
		step: {
			type: Number,
			default: 1,
		},
		precision: {
			type: Number,
			default: 0,
		},
		valueOnClear: {
			type: String,
			default: null,
		},
		alignText: {
			type: String,
			default: null,
		},
	},
	computed: {
		model: {
			get() { return typeof this.modelValue === 'undefined' ? null : this.modelValue },
			set(value) {
				let modelValue = value

				// TODO здесь теряется value, нужно переделать на работу с modelValue
				if (value === null && this.valueOnClear) {
					// eslint-disable-next-line no-nested-ternary
					modelValue = this.valueOnClear === 'min'
						? this.min
						: this.valueOnClear === 'max'
							? this.max
							: value
				}

				this.$emit('update:modelValue', modelValue)
			},
		},
		viewValue() {
			return this.modelValue || '—'
		},
		inputClassName() {
			return this.alignText ? `align-${this.alignText}` : ''
		},
	},
}
</script>

<style lang="scss">
.com-field-input-number {
	.el-input-number.align-left {
		.el-input__inner {
			text-align: left;
		}
	}
}
</style>