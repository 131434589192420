import { inputNumberTemplate } from './Decimal'

const getOnInputValue = str => str
	.replace(/[^0-9-]/g, '') // убираем всё, кроме цифр и минусов
	.replace(/^(-?)(.*)$/, (a, b, c) => b + c.replace(/-/g, '')) // убираем лишние минусы
	.replace(/^(-?)0*(\d+)/, '$1$2') // убираем первые нули

const getOnChangeValue = str => getOnInputValue(str)
	.replace(/^-(0?)$/, '$1') // убираем лишний минус (если значение `-0` или `-`)

export default inputNumberTemplate(getOnInputValue, getOnChangeValue)