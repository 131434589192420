import { GOODS_TYPES_MAP } from '~/helpers/common/GoodsTypes'
import { ORDERS_TYPES } from '~/helpers/common/OrderTypes'
import { PRESCRIPTION_TYPES_MAP } from '~/helpers/common/PrescriptionsTypes'

export default {
	gFiltersAuthors(state) {
		return state.filtersAuthors.map(item => ({
			label: item.label,
			value: String(item.id),
		}))
	},
	gFiltersAddidationsOptions(state) {
		return (state.filtersValues?.addidations || []).map(value => ({
			label: value,
			value,
		}))
	},
	gFiltersAssistantsOptions(state) {
		return (state.filtersValues?.clientValues?.assistants || []).map(value => ({
			label: value.label,
			value: value.id,
		}))
	},
	gFiltersDoctorsOptions(state) {
		return (state.filtersValues?.prescriptionValues?.doctors || []).map(value => ({
			label: value.label,
			value: value.id,
		}))
	},
	gFiltersPurposesOptions(state) {
		return (state.filtersValues?.prescriptionValues?.purposes || []).map(value => ({
			label: value,
			value,
		}))
	},
	gFiltersCylindersOptions(state) {
		return (state.filtersValues?.cylinders || []).map(value => ({
			label: value,
			value: Number(value.replace(',', '.')),
		}))
	},
	gFiltersDepartmentsOptions(state) {
		return (state.filtersValues?.orderValues?.departments || []).map(value => ({
			label: value.name,
			value: value.id,
		}))
	},
	gFiltersCitiesOptions(state) {
		return (state.filtersValues?.clientValues?.cities || []).filter(Boolean).map(value => ({
			label: value,
			value,
		}))
	},
	gFiltersPrescriptionTypeOptions(state) {
		return (state.filtersValues?.prescriptionValues?.type || []).map(value => ({ ...PRESCRIPTION_TYPES_MAP[value], value }))
	},
	gFiltersGoodsTypesOptions(state) {
		return (state.filtersValues?.goodTypes || []).map(value => ({ ...GOODS_TYPES_MAP[value], value }))
	},
	gFiltersOrdersTypesOptions(state) {
		return (state.filtersValues?.orderTypes || []).map(value => ({ ...ORDERS_TYPES[value], value }))
	},

	// clients/filters/goods/values
	gFiltersGoodsNamesOptions(state) {
		return (state.filtersGoodsValues?.names || []).map(value => ({
			label: value,
			value,
		}))
	},
	gFiltersGoodsWearingPeriodsOptions(state) {
		return (state.filtersGoodsValues?.wearingPeriods || []).map(value => ({
			label: value,
			value,
		}))
	},
	gFiltersGoodsPackageNumsOptions(state) {
		return (state.filtersGoodsValues?.packageNums || []).map(value => ({
			label: value,
			value,
		}))
	},
	gFiltersGoodsAddidationsOptions(state) {
		return (state.filtersGoodsValues?.addidations || []).map(value => ({
			label: value,
			value,
		}))
	},
	gFiltersGoodsColorsOptions(state) {
		return (state.filtersGoodsValues?.colors || []).map(value => ({
			label: value,
			value,
		}))
	},
	gFiltersGoodsCategoriesOptions(state) {
		return (state.filtersGoodsValues?.categories || []).map(value => ({
			label: value,
			value,
		}))
	},
	gFiltersGoodsBrandsOptions(state) {
		return (state.filtersGoodsValues?.brands || []).map(value => ({
			label: value,
			value,
		}))
	},
	gFiltersGoodsLensClassesOptions(state) {
		return (state.filtersGoodsValues?.lensClasses || []).map(value => ({
			label: value,
			value,
		}))
	},
	gFiltersGoodsLensCoversOptions(state) {
		return (state.filtersGoodsValues?.lensCovers || []).map(value => ({
			label: value,
			value,
		}))
	},
	gFiltersGoodsLensTypesOptions(state) {
		return (state.filtersGoodsValues?.lensTypes || []).map(value => ({
			label: value,
			value,
		}))
	},
	gFiltersGoodsManufacturersOptions(state) {
		return (state.filtersGoodsValues?.manufacturers || []).map(value => ({
			label: value,
			value,
		}))
	},
	gFiltersGoodsRefractionIndicesOptions(state) {
		return (state.filtersGoodsValues?.refractionIndices || []).map(value => ({
			label: value,
			value,
		}))
	},
	gFiltersGoodsDesignsOptions(state) {
		return (state.filtersGoodsValues?.designs || []).map(value => ({
			label: value,
			value,
		}))
	},
	gFiltersGoodsTargetGroupsOptions(state) {
		return (state.filtersGoodsValues?.targetGroups || []).map(value => ({
			label: value,
			value,
		}))
	},
	gFiltersGoodsServiceTypesOptions(state) {
		return (state.filtersGoodsValues?.serviceTypes || []).map(value => ({
			label: value.name,
			value: value.id,
		}))
	},
	gFiltersGoodsUserGroupsOptions(state) {
		return Object.entries(state.filtersGoodsValues?.userGroups || {}).map(([key, value]) => ({
			label: value,
			value: Number(key),
		}))
	},
}