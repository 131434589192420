
export default (to, from, next) => {
	const accessControl = (store, { accessDenied = [], accessGranted = [] }) => {
		const { authorities } = store.state.user
		if (accessDenied.length > 1) {
			for (let i = 0; i < accessDenied.length; i++) {
				const deniedRole = accessDenied[i]
				if (authorities.includes(deniedRole)) {
					return { name: 'Error403' }
				}
			}
		}
		if (accessGranted.length > 1) {
			for (let i = 0; i < accessGranted.length; i++) {
				const grantedRole = accessGranted[i]
				if (authorities.includes(grantedRole)) {
					return null
				}
			}
			return { name: 'Error403' }
		}
		return null // default access when accessGranted array is empty
	}

	function accessControlTab(store, toRoute) {
		let redirectTo = null

		function getAvaiableRouteTabs() {
			const routeProps = toRoute.matched[0]?.props?.default
			const { Tabs } = routeProps

			if (!Tabs) return []

			return Tabs.filter(tab => {
				let tabNotAvailable = false
				if (tab.notAvailable) {
					tabNotAvailable = tab.notAvailable(store)
				}
				if (tab.notAvailableToRoles) {
					tabNotAvailable = tabNotAvailable || tab.notAvailableToRoles.filter(role => store.state.user.authorities.includes(role)).length
				}
				return !tabNotAvailable
			})
		}

		const availableTabs = getAvaiableRouteTabs()

		const activeTab = toRoute.params.tab || ''

		if (availableTabs.length) {
			const isAvailableTab = availableTabs.find(tab => tab.tab === activeTab)

			if (!isAvailableTab) {
				redirectTo = {
					name: toRoute.name,
					params: {
						...toRoute.params,
						// текущая таба не доступна, включаем первую доступную
						tab: availableTabs[0].tab,
					},
				}
			}
		}
		return redirectTo
	}

	if (from === to) {
		next()
	} else {
		next(async vm => {
			let redirectTo = accessControl(vm.$store, to.meta) || null
			if (!redirectTo) {
				redirectTo = accessControlTab(vm.$store, to)
			}
			if (redirectTo) {
				await vm.$router.replace(redirectTo)
				vm.$emit('force-update-layout')
			}
		})
	}
}