<template>
	<div
		id="layout-index"
		v-loading="isAuthorizing"
		:class="layoutClasses"
	>
		<iframe
			v-if="!hasResizeObserver"
			id="oldBrowserHeightChangeListener"
			title="iframe"
			tabindex="-1000"
		/>
		<LayoutPartSidebar v-if="showSidebar" class="mr-4 noprint"/>

		<main
			v-if="isAuthorized && settings"
			class="w-100p"
		>
			<router-view
				:key="layoutKey"
				@force-update-layout="forceUpdateLayout"
			/>
		</main>
	</div>
</template>

<script>
/* eslint-disable no-restricted-globals */
import { mapState, mapMutations, mapActions } from 'vuex'
import { loadLanguageAsync } from '~/plugins/i18n'
import { isIframe, isMainIframe } from '~/utils/iframes'
import {
	getIframeId,
	iframeMessagesBeforeFetchSettingsQueue,
} from '~/utils/iframes'
import {
	iframeEvents,
} from '~/utils/iframes/iframesEvents'
import {
	iframeBodyResizeRegister,
	iframeBodyResizeRemove,
} from '~/utils/iframes/iframesSizeObserver'
import beforeEachStoreHistoryUpdate from '~/plugins/router/hooks/beforeEachStoreHistoryUpdate'
import beforeEachStatisticsRequest from '~/plugins/router/hooks/beforeEachStatisticsRequest'
import LayoutPartSidebar from './layout/parts/LayoutPartSidebar'


export default {
	components: {
		LayoutPartSidebar,
	},
	data() {
		return {
			layoutKey: this.$route.name,
			layoutClasses: '',
		}
	},
	computed: {
		...mapState('auth', [
			'isAuthorizing',
			'isAuthorized',
			'token',
		]),
		...mapState('user', [
			'optima2BackendUrl',
			'optimaLegacyBackendUrl',
		]),
		...mapState([
			'settings',
		]),
		hasResizeObserver() {
			return !!window.ResizeObserver
		},
		showSidebar() {
			return !isIframe
		},
	},
	watch: {
		token: {
			deep: true,
			handler: 'setOptima2AuthHeader',
		},
		'$route.name': 'forceUpdateLayout',
	},
	beforeCreate() {
		if (isIframe) document.body.classList.add('is-iframe')
	},
	created() {
		this.$router.beforeEach(async (...args) => beforeEachStoreHistoryUpdate.apply(this, args))
		this.$router.beforeEach(async (...args) => beforeEachStatisticsRequest.apply(this, args))

		if (isIframe) {
			this.parentWindowMessageListener('listen')
			this.keydownListener('add')
		}

		if (!isIframe) {
			this.customAuth()
		}
	},
	mounted() {
		if (isIframe) {
			iframeBodyResizeRegister()
			window.onIframeAppMounted()
		}
		this.setLayoutClasses()
	},
	beforeUnmount() {
		if (isIframe) {
			iframeBodyResizeRemove()
			this.parentWindowMessageListener('remove')
			this.keydownListener('remove')
		}
	},
	methods: {
		...mapMutations('client', [
			// используется в iframes.js checkVisionEvent для передачи данныз из легаси
			'mSetLegacyClientData',
			'mClientCheckVisionData',
		]),
		...mapActions(['aSettings']),

		setLayoutClasses() {
			let classes = ''
			if (isIframe) {
				if (!getIframeId()) {
					setTimeout(this.setLayoutClasses, 100)
					return
				}

				classes = 'position-relative'
				if (isMainIframe()) {
					classes += ' main-iframe'
				}
			} else {
				classes = 'd-flex min-h-100vh w-100p'
			}
			this.layoutClasses = classes
		},
		parentWindowMessageListener(type = 'listen') {
			if (type === 'listen') {
				window.addEventListener('message', this.eventProxy)
			} else {
				window.removeEventListener('message', this.eventProxy)
			}
		},
		eventProxy(event) {
			let message = event.data
			if (typeof message === 'string') {
				try {
					message = JSON.parse(message)
				} catch (error) {
					//
				}
			}
			if (!message.type) return

			iframeEvents.run.call(this, message.type, message.data)
		},
		async customAuth() {
			try {
				let token = localStorage.getItem('user/token')
				const company = localStorage.getItem('user/company')

				if (import.meta.env.VITE_MODE === 'development' && !token) {
					const { tokens } = await import(/* @vite-ignore */ './mocks/index.js')
					token = tokens.admin
				} else {
					try {
						token = JSON.parse(token)
					} catch (err) {
						return
					}
				}


				// this.setLocale(locale) TODO тут возможно нужно установить локаль
				this.$store.commit('auth/SET_TOKEN', token)
				this.$store.commit('auth/SET_COMPANY', company)
				await this.$store.dispatch('user/setTokenMeta')
				this.$store.commit('auth/SET_AUTHORIZED', true)
				this.$store.commit('user/mLoadComponentState')
				this.setAppBaseUrl(company)

				await this.$store.dispatch('user/aAfterAuth')

				this.$store.commit('user/mSetComponentStateSession', {
					'com-seller-select.selectedUser': null,
				})
			} catch (error) {
				this.$notifyUserAboutError(error)
			}
		},

		// calls from:
		// this.customAuth() - when isIframe !== true
		// iframeEvents.setTokenEvent() - isIframe === true
		async setAppBaseUrl(company) {
			const BASE_URL = 'https://optima-1.stage.itigris.ru/api/v1'.replace('%COMPANY%', company)
			const BASE2_URL = 'https://optima-2-backend-1.stage.itigris.ru/api/v2'.replace('%COMPANY%', company) || this.optima2BackendUrl

			// production mode
			if (import.meta.env.VITE_MODE === 'production') {
				this.$http.optima.defaults.baseURL = BASE_URL
				this.$http.optima2.defaults.baseURL = BASE2_URL
			// stage mode
			} else if (import.meta.env.VITE_MODE === 'development' && import.meta.env.NODE_ENV === 'production') {
				this.$http.optima.defaults.baseURL = BASE_URL
				this.$http.optima2.defaults.baseURL = BASE2_URL
			// development mode
			} else {
				this.$http.optima.defaults.baseURL = import.meta.env.VITE_LOCAL_API_V1_ORIGIN + import.meta.env.VITE_LOCAL_API_V1_PATH
				this.$http.optima2.defaults.baseURL = import.meta.env.VITE_LOCAL_API_V2_ORIGIN + import.meta.env.VITE_LOCAL_API_V2_PATH
			}
			await this.aSettings()

			iframeMessagesBeforeFetchSettingsQueue.execAll(this)
		},

		async setLocale(locale) {
			this.$store.commit('user/setLocale', locale)
			await loadLanguageAsync(locale)
		},
		setOptima2AuthHeader() {
			this.$http.optima2.defaults.headers.common.Authorization = this.$http.optima.defaults.headers.common.Authorization
		},
		globalKeydownHandler(event) {
			const data = { keyCode: event.keyCode }
			parent.postMessage({ type: 'keydown', data, iframeId: getIframeId() }, '*')
		},
		keydownListener(type) {
			if (type === 'add') document.addEventListener('keydown', this.globalKeydownHandler)
			else if (type === 'remove') document.removeEventListener('keydown', this.globalKeydownHandler)
		},
		forceUpdateLayout() {
			this.layoutKey = this.$route.name + Date.now()
		},
	},
}
</script>


<style lang="scss">
#layout-index.main-iframe {
	padding-top: 78px;
}
</style>