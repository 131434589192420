<template>
	<!-- eslint-disable vue/no-v-html -->
	<svg
		xmlns="http://www.w3.org/2000/svg"
		:width="width"
		:height="height"
		:viewBox="`0 0 ${boxSize} ${boxSize}`"
		class="it-icon"
		v-html="svgContent"
		:data-ta="'ItIcon_' + name"
	/>
</template>

<script>
export default {
	name: 'ItIcon',
	props: {
		width: {
			type: [Number, String],
			default: 24,
		},
		height: {
			type: [Number, String],
			default: 24,
		},
		name: {
			type: String,
			required: false,
			default: 'empty-icon',
		},
		address: {
			type: String,
			required: false,
			default: 'https://static.itigris.ru/icons',
		},
		defaultBoxSize: {
			type: String,
			default: '24'
		}
	},
	data() {
		return {
			svgContent: '',
		}
	},
	computed: {
		boxSize() {
			if (this.name) {
				const size = Number(this.name.substr(this.name.lastIndexOf('-') + 1))
				return Number.isNaN(size) ? this.defaultBoxSize : size
			}

			return this.defaultBoxSize
		},
	},
	watch: {
		name: {
			immediate: true,
			handler: 'setSvgContent',
		},
	},
	methods: {
		async setSvgContent() {
			try {
				if (this.name) {
					this.svgContent = await this.loadIcon(`${this.name}.xml`)
				} else {
					this.setEmptyIcon()
				}
			} catch (error) {
				if (error.code === 'MODULE_NOT_FOUND') {
					this.setEmptyIcon()
				} else {
					console.dir(error) // eslint-disable-line no-console
				}
			}
		},
		async setEmptyIcon() {
			this.svgContent = await this.loadIcon(`empty-icon.xml`)
		},
		async loadIcon(icon) {
			const url = `${this.address}/${icon}`
			return new Promise((resolve, reject) => {
				const xhr = new XMLHttpRequest()
				xhr.open('GET', url)
				xhr.send()
				xhr.onload = function () {
					if (xhr.status >= 400) {
						return reject(new Error({
							status: xhr.status,
							statusText: xhr.statusText,
						}))
					}
					return resolve(xhr.response)
				}
				xhr.onerror = function (err) {
					return reject(err)
				}
			})
		},
	},
}
</script>