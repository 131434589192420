<template>
	<div class="d-flex align-items-start">
		<span
			class="mr-4 mt-2 flex-shrink font-size-12"
			style="width: 144px;"
		>{{ label }}</span>

		<div
			v-if="Array.isArray(value)"
			style="margin-bottom: -12px;"
		>
			<el-tag
				v-for="(item, index) in value"
				:key="index"
				type="info"
				:size="size"
				class="border-radius-round color-regular mb-3 mr-3"
				:class="classes"
			>{{ item }}</el-tag>
		</div>

		<el-tag
			v-else
			type="info"
			:size="size"
			class="border-radius-round color-regular"
			:class="classes"
		>{{ value }}</el-tag>
	</div>
</template>

<script>

export default {
	name: 'TagItem',
	props: {
		label: {
			type: String,
			required: true,
		},
		classes: {
			type: String,
			default: null,
		},
		value: {
			type: [Array, String, Number],
			required: true,
		},
		size: {
			type: String,
			default: 'large',
		},
	},
}
</script>