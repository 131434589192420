<template>
	<div
		id="layout-journal"
		:key="update"
	>
		<LayoutPartTitle
			v-if="title"
			v-bind="layoutParams"
			@on-return="onReturn"
		/>

		<component
			:is="Page"
			ref="page"
			v-loading="loadingStore"
			@on-mounted="onMountedPage"
			@on-layout-props-update="mxLayoutParamsUpdate"
		/>

		<LayoutPartPagination
			v-if="layoutParams.journalName && layoutParams.hasPagination"
			v-model="pagination"
			:save-state="layoutParams.savePagination"
			:save-state-key="`pagination.size.` + layoutParams.journalName + `Journal`"
			class="mt-8 d-flex justify-content-flex-start"
			@update:modelValue="onPaginationChange"
		/>

	</div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import mxLayouts from '~/mixins/layouts/mxLayouts'
import LayoutPartTitle from '~/layout/parts/LayoutPartTitle.vue'
import LayoutPartPagination from '~/layout/parts/LayoutPartPagination.vue'

export default {
	name: 'LayoutList',
	components: {
		LayoutPartTitle,
		LayoutPartPagination,
	},
	mixins: [mxLayouts],
	props: {
		Page: {  // eslint-disable-line
			type: [Function, Object, Boolean],
			required: true,
		},
		buttons: {
			type: [Array, Boolean],
			default: false,
		},
	},
	data() {
		return {
			pageMethods: {},
			pagination: null,
			update: 0,
			layoutParams: {
				title: '',
				browserTitle: '',
				hasReturnButton: false,
				disableButtons: {},
				returnTo: { name: 'DepartmentsJournal' },
				deleteConfirmation: this.$t('Продолжить?'),
				restoreSuccessMessage: this.$t('Восстановлено'),
				restoreErrorMessage: this.$t('Ошибка'),
				deleteSuccessMessage: this.$t('Удалено'),
				deleteErrorMessage: this.$t('Ошибка'),
				saveSuccessMessage: this.$t('Сохранено'),
				saveErrorMessage: this.$t('Ошибка'),
				journalName: null,
				hasPagination: true,
			},
		}
	},
	computed: {
		...mapState('layout', []),
		...mapState('journals', [
			'journalsClientsLoading',
			'journalsClientsPagination',
			'journalsDepartmentsLoading',
			'journalsDepartmentsPagination',
			'journalsUsersLoading',
			'journalsUsersPagination',
			'journalsGlassesLoading',
			'journalsGlassesPagination',
			'journalsLegalEntitiesLoading',
			'journalsLegalEntitiesPagination',
			'journalsLicencesLoading',
			'journalsLicencesPagination',
			'journalsAccessControlLoading',
			'journalsAccessControlPagination',
			'journalsAccessControlStatisticsLoading',
			'journalsAccessControlStatisticsPagination',
			'journalsClientFilesLoading',
			'journalsClientFilesPagination',
			'journalsSalesPlansLoading',
			'journalsSalesPlansPagination',
			'journalsDirectoryPagination',
		]),

		title() {
			return this.layoutParams.title || ''
		},
		disableButtons() {
			return {
				...this.layoutParams?.disableButtons,
				save: !this.isChanged,
			}
		},
		paginationStore() {
			const paginationStore = `journals${this.layoutParams.journalName}Pagination`
			return this[paginationStore]
		},
		loadingStore() {
			const loadingStore = `journals${this.layoutParams.journalName}Loading`
			return this[loadingStore]
		},
	},
	created() {
		this.mLayoutDropState()
	},
	methods: {
		...mapMutations('layout', [
			'mLayoutDropState',
		]),

		onMountedPage(layoutParams) {
			this.mxLayoutParamsUpdate(layoutParams)

			this.$watch(`paginationStore`, value => {
				this.pagination = value
			})
		},

		onReturn() {
			this.mxReturn()
		},

		onPaginationChange() {
			this.$refs.page.layoutPaginationChange(this.pagination)
		},

		updateLayout() {
			this.update += 1
		},
	},

}
</script>

<style lang="scss">
	.return-button {
		cursor: pointer
	}
</style>