import i18n from '~/plugins/i18n'

export const GOODS_TYPES = {
	ACCESSORY: {
		label: i18n.global.t('Аксессуары'),
		value: 'ACCESSORY',
	},
	CONTACT_LENS: {
		label: i18n.global.t('Контактные линзы'),
		shortLabel: i18n.global.t('Конт. линзы'),
		value: 'CONTACT_LENS',
	},
	EXTRA: {
		label: i18n.global.t('Массовка'),
		value: 'EXTRA',
	},
	GLASSES: {
		label: i18n.global.t('Оправы'),
		value: 'GLASSES',
	},
	LENS: {
		label: i18n.global.t('Линзы'),
		value: 'LENS',
	},
	SUNGLASSES: {
		label: i18n.global.t('Солнцезащитные очки'),
		value: 'SUNGLASSES',
	},
	SEMIFINISHED_LENS: {
		label: i18n.global.t('Полузаготовки'),
		value: 'SEMIFINISHED_LENS',
	},
	SERVE: {
		label: i18n.global.t('Услуги'),
		value: 'SERVE',
	},
}

export const GOODS_TYPES_MAP = {
	ACCESSORIES: GOODS_TYPES.ACCESSORY,
	ACCESSORY: GOODS_TYPES.ACCESSORY,
	CONTACT_LENSES: GOODS_TYPES.CONTACT_LENS,
	CONTACT_LENS: GOODS_TYPES.CONTACT_LENS,
	LENSES: GOODS_TYPES.LENS,
	LENS: GOODS_TYPES.LENS,
	GLASSES: GOODS_TYPES.GLASSES,
	EXTRAS: GOODS_TYPES.EXTRA,
	EXTRA: GOODS_TYPES.EXTRA,
	SUNGLASSES: GOODS_TYPES.SUNGLASSES,
	// SERVICES: GOODS_TYPES.SERVE,
	SERVE: GOODS_TYPES.SERVE,
	SEMIFINISHED_LENS: GOODS_TYPES.SEMIFINISHED_LENS,
}

export const GoodsTypes = GOODS_TYPES // для совместимости

export const sellableCategories = Object.freeze({
	...GOODS_TYPES,
	GIFT_CERTIFICATE: {
		label: i18n.global.t('Подарочные сертификаты'),
		value: 'GIFT_CERTIFICATE',
	},
})
