const goodsTypesAndParamsDictionary = {
	GLASSES: {
		goodsType: 'GLASSES',
		manufacturer: 'glasses.manufacturer',
		brand: 'glasses.brand',
		targetGroup: 'glasses.targetGroup',
		frameType: 'glasses.frameType',
		glassesMaterial: 'glasses.glassesMaterial',
		models: 'glasses.models',
		groupByDepartmentAndParams: 'groupByDepartmentAndParams',
		retailAndPurchasingPrice: 'glasses.retailAndPurchasingPrice',
	},
	SUNGLASSES: {
		goodsType: 'SUNGLASSES',
		manufacturer: 'sunglasses.manufacturer',
		brand: 'sunglasses.brand',
		targetGroup: 'sunglasses.targetGroup',
		frameType: 'sunglasses.frameType',
		lensesType: 'sunglasses.lensesType',
		glassesMaterial: 'sunglasses.glassesMaterial',
		models: 'sunglasses.models',
		design: 'sunglasses.design',
		groupByDepartmentAndParams: 'groupByDepartmentAndParams',
		retailAndPurchasingPrice: 'sunglasses.retailAndPurchasingPrice',
	},
	CONTACT_LENSES: {
		goodsType: 'CONTACT_LENS',
		manufacturer: 'contactLens.manufacturer',
		contactLensesName: 'contactLens.contactLensesName',
		radiusOfCurvature: 'contactLens.curvatureRadius',
		expireOnFrom: 'contactLens.expireOnFrom',
		expireOnTo: 'contactLens.expireOnTo',
		cylinderFrom: 'contactLens.cylinderFrom',
		cylinderTo: 'contactLens.cylinderTo',
		dioptresFrom: 'contactLens.dioptresFrom',
		dioptresTo: 'contactLens.dioptresTo',
		diameter: 'contactLens.diameter',
		wearingPeriod: 'contactLens.wearingPeriod',
		groupByDepartmentAndParams: 'groupByDepartmentAndParams',
		retailAndPurchasingPrice: 'contactLens.retailAndPurchasingPrice',
		groupByExpirationDate: 'contactLens.groupByExpirationDate',
		criticalDateOnly: 'contactLens.criticalDateOnly',
		packageQuantity: 'contactLens.packageQuantity',
	},
	LENSES: {
		goodsType: 'LENS',
		manufacturer: 'lens.manufacturer',
		brand: 'lens.brand',
		refractionIndex: 'lens.refractionIndex',
		lensType: 'lens.lensType',
		lensClass: 'lens.lensClass',
		technology: 'lens.technology',
		diameter: 'lens.diameter',
		dioptresFrom: 'lens.dioptresFrom',
		dioptresTo: 'lens.dioptresTo',
		cylinderFrom: 'lens.cylinderFrom',
		cylinderTo: 'lens.cylinderTo',
		lensCover: 'lens.lensCover',
		lensGeometry: 'lens.lensGeometry',
		color: 'lens.color',
		material: 'lens.material',
		groupByDepartmentAndParams: 'groupByDepartmentAndParams',
		retailAndPurchasingPrice: 'lens.retailAndPurchasingPrice',
		partOfOrder: 'lens.partOfOrder',
		inOtherDepartment: 'lens.inOtherDepartment',
		inProduction: 'lens.inProduction',
	},
	ACCESSORIES: {
		goodsType: 'ACCESSORY',
		category: 'accessory.category',
		model: 'accessory.model',
		groupByDepartmentAndParams: 'groupByDepartmentAndParams',
		retailAndPurchasingPrice: 'accessory.retailAndPurchasingPrice',
	},
	EXTRAS: {
		goodsType: 'EXTRA',
		manufacturer: 'extra.manufacturer',
		brand: 'extra.brand',
		models: 'extra.models',
		glassesMaterial: 'extra.glassesMaterial',
		type: 'extra.type',
		targetGroup: 'extra.targetGroup',
		dioptresFrom: 'extra.dioptresFrom',
		dioptresTo: 'extra.dioptresTo',
		cylinderFrom: 'extra.cylinderFrom',
		cylinderTo: 'extra.cylinderTo',
		lensesManuf: 'extra.lensesManuf',
		lensesBrand: 'extra.lensesBrand',
		groupByDepartmentAndParams: 'groupByDepartmentAndParams',
		retailAndPurchasingPrice: 'extra.retailAndPurchasingPrice',
	},
	SERVE: {
		goodsType: 'SERVE',
		servicesId: 'serve.serveTypeIds',
		groupByDepartmentAndParams: 'groupByDepartmentAndParams',
		retailAndPurchasingPrice: 'serve.retailAndPurchasingPrice',
	},
}

export function goodsTypesFiltersFormatter(goodsType) {
	return goodsTypesAndParamsDictionary[goodsType]
		? goodsTypesAndParamsDictionary[goodsType].goodsType
		: goodsType
}

export function goodsParamsFiltersFormatter(parameter, goodsType) {
	return goodsTypesAndParamsDictionary[goodsType] && goodsTypesAndParamsDictionary[goodsType][parameter]
		? goodsTypesAndParamsDictionary[goodsType][parameter]
		: parameter
}