import VuexHttp from '../../vuexHttpService'

const vHttp = new VuexHttp('clientFilters')

export default {
	aFilter(_, id) {
		return vHttp.GET(this, {
			url: `clients/filters/${id}`,
			mutation: 'mFilter',
		})
	},
	aSaveFilter(_, { id, data }) {
		return vHttp.PUT(this, {
			url: `clients/filters/${id}`,
			data,
		})
	},
	aCreateFilter(_, data) {
		delete data.clientParameters?.id
		delete data.soldGoodParameters?.id
		delete data.notSoldGoodParameters?.id
		delete data.madeOrderParameters?.id
		delete data.notMadeOrderParameters?.id
		delete data.madePrescriptionParameters?.id
		delete data.notMadePrescriptionParameters?.id

		return vHttp.POST(this, {
			url: `clients/filters`,
			data,
		})
	},
	aFiltersValues() {
		return vHttp.GET(this, {
			url: `clients/filters/values`,
			mutation: 'mFiltersValues',
		})
	},
	aFiltersGoodsValues(_, type) {
		return vHttp.GET(this, {
			url: `clients/filters/goods/values?type=${type}`,
			mutation: `mFiltersGoodsValues`,
		})
	},
	aFiltersAuthors() {
		return vHttp.GET(this, {
			url: `clients/filters/authors`,
			mutation: 'mFiltersAuthors',
		})
	},
	aSetFavourite(_, { id, isFavourite }) {
		return vHttp.PUT(this, {
			url: `clients/filters/${id}/favourite`,
			data: {
				isFavourite,
			},
		})
	},
	aDeleteFilter(_, id) {
		return vHttp.DELETE(this, {
			url: `clients/filters/${id}`,
		})
	},
	aFilterClients(_, data) {
		return vHttp.POST(this, {
			url: `clients/filters/apply`,
			data,
		})
	},
	aFilterClientsById(_, id) {
		return vHttp.GET(this, {
			url: `clients/filters/${id}/apply`,
		})
	},
}