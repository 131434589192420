<script setup>
import { useStore } from 'vuex'
import { ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import LayoutPartTitle from '~/layout/parts/LayoutPartTitle'
import PartReturn from './OrderReturn/PartReturn'
import PaymentPopup from './OrderReturn/PartPaymentPopup'
import { sellableCategories } from '~/helpers/common/GoodsTypes'

const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const store = useStore()

const isPaymentPopupVisible = ref(false)
const selectedItems = ref([])
const comment = ref('')
const fromCardReturn = ref(false)

const orderId = computed(() => +route.params.id)

const onReturn = async () => {
	const order = await store.dispatch('orders/aOrderById', orderId.value)

	if (order) {
		window.loadLinkSimpleLegacy(`order/show/${orderId.value}?returnParam=order&clientId=${order.clientId}`)
	}
}

const paymentSum = computed(() => selectedItems.value.reduce((sum, item) => (sum + item.soldPrice), 0))

const preparedItems = computed(() => {
	const servesIds = []
	const giftCertificatesIds = []
	const goodsIds = []

	selectedItems.value.forEach(item => {
		if (item.sellableCategory === sellableCategories.SERVE.value) {
			servesIds.push(item.id)
		} else if (item.sellableCategory === sellableCategories.GIFT_CERTIFICATE.value) {
			giftCertificatesIds.push(item.id)
		} else {
			goodsIds.push(item.id)
		}
	})

	return {
		servesIds,
		giftCertificatesIds,
		goodsIds,
	}
})

const save = async (payments = {}) => {
	const returnNumber = (await store.dispatch('returns/aSaveReturnItems', {
		orderId: orderId.value,
		goodsIds: preparedItems.value.goodsIds,
		servesIds: preparedItems.value.servesIds,
		giftCertificatesIds: preparedItems.value.giftCertificatesIds,
		comment: comment.value,
		fromCardReturn: fromCardReturn.value,
		// TODO вернуть для подключения составных платежей
		// cash: +payments.cash,
		// card: +payments.card,
	})).data

	if (returnNumber) {
		isPaymentPopupVisible.value = false
		router.push(`/order/return/${returnNumber}/view?print=onload`)
	}

	isPaymentPopupVisible.value = false
}

const showPaymentPopup = () => {
	// TODO вернуть для подключения составных платежей и убрать вызов ф-и save отсюда
	// isPaymentPopupVisible.value = true
	save()
}
</script>

<template>
	<div>
		<LayoutPartTitle
			:title="[t('Оформление возврата по заказу'), `№ ${orderId}`]"
			has-return-button
			class="mb-4"
			@on-return="onReturn"
		/>

		<PartReturn
			v-model:selected-items="selectedItems"
			:order-id="orderId"
			@show-payment-popup="showPaymentPopup"
			@set-comment="comment = $event"
			@set-card-return="fromCardReturn = $event"
		/>

		<PaymentPopup
			v-model:is-visible="isPaymentPopupVisible"
			:payment-sum="paymentSum"
			@save-payments="save"
		/>
	</div>
</template>