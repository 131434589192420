export default function () {
	return {
		settings: null,
		loading: true,
		sending: false,
		error: null,
		previousPageLink: null,
		cities: [],
		timezones: [],
		languages: [],
		isIframeHid: false,

		// TODO перенести в модуль settings, объединить с applications-settings?
		glassesProductionServiceVAT: null,
	}
}