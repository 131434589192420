<template>
	<div class="layout-part-title d-flex align-items-center">
		<div
			v-if="hasReturnButton"
			class="header-height d-flex align-items-center"
		>
			<span
				class="return-button mr-2"
				data-ta="return-button"
				@click="onReturn"
			>
				<ItIcon
					name="arrow-left"
					class="return-button-icon fill-text-primary"
				/>
			</span>
		</div>

		<h3
			class="m-0"
		>{{ mainTitle }}<span v-if="secondaryTitle" class="color-text-secondary"> — {{ secondaryTitle }}</span></h3>

		<el-tag
			v-if="tag"
			type="info"
			size="large"
			class="ml-3 border-radius-round color-regular"
		>{{ tag }}</el-tag>
		<span class="pl-3">
			<OptimaHelpTooltip
				v-if="optimaHelp && optimaHelp.text"
				:text="optimaHelp.text"
				:href="optimaHelp.href"
			/>
		</span>
	</div>
</template>

<script>
import OptimaHelpTooltip from '~/components/old/OptimaHelpTooltip'

export default {
	name: 'LayoutPartTitle',
	components: {
		OptimaHelpTooltip,
	},
	props: {
		title: {
			type: [Array, String],
			default: '',
		},
		hasReturnButton: {
			type: Boolean,
			default: false,
		},
		optimaHelp: {
			type: Object,
			default: null,
		},
	},
	computed: {
		hasSecondaryTitle() {
			return Array.isArray(this.title)
		},
		mainTitle() {
			if (this.hasSecondaryTitle) {
				return this.title[0]
			}
			return this.title
		},
		secondaryTitle() {
			if (this.hasSecondaryTitle) {
				return this.title[1]
			}
			return null
		},
		tag() {
			if (this.hasSecondaryTitle) {
				return this.title[2]
			}
			return null
		},
	},
	methods: {
		onReturn() {
			this.$emit('on-return')
		},
	},
}
</script>

<style lang="scss">
.layout-part-title {
	height: 36px;

	.return-button-icon {
		margin-top: 2px;
	}

	.return-button {
		cursor: pointer
	}
}
</style>