const popovers = new Map()
let counter = 0
let disabled = false

const closePopoversMoreThen = (clickedGroup, clickedNum) => {
	popovers.forEach(({ group, num, closeFn }) => {
		if (group !== clickedGroup || num > clickedNum) {
			closeFn()
		}
	})
}

const testClickOnPopover = (event, { ref }) => {
	const popoverEl = ref.popperRef?.contentRef
	return Boolean(popoverEl) && event.composedPath().includes(popoverEl)
}

const testClickOnTrigger = (event, { triggerRef }) => {
	const triggerEl = triggerRef?.ref
	return Boolean(triggerEl) && event.composedPath().includes(triggerEl)
}

export const registerPopover = ({ group, ref, closeFn, triggerRef }) => {
	popovers.set(ref, {
		group,
		ref,
		triggerRef,
		closeFn,
		num: ++counter,
	})
}

export const unregisterPopover = ref => {
	popovers.delete(ref)
	counter--
}

export const clickProcessing = event => {
	if (disabled) return

	let popoverNum = false
	let triggerNum = false
	let group = false

	popovers.forEach(value => {
		if (popoverNum === false && testClickOnPopover(event, value)) {
			popoverNum = value.num
			group = value.group
		}
		if (triggerNum === false && testClickOnTrigger(event, value)) {
			triggerNum = value.num
			group = value.group
		}
	})

	if (triggerNum !== false) {
		closePopoversMoreThen(group, triggerNum + 1)
	} else {
		closePopoversMoreThen(group, popoverNum || 0)
	}
}

export const clickOutsidePopoverEnable = () => {
	disabled = false
}

export const clickOutsidePopoverDisable = () => {
	disabled = true
}

document.addEventListener('click', clickProcessing)