// копия утилит из optima-admin вынести в кит
import utc from 'dayjs/plugin/utc'
import { dayjs } from '~/kit/plugins/dayjs'
import i18n from '~/plugins/i18n'
import { $settings } from '~/plugins/settings'

dayjs.extend(utc)

export const isValidDate = (date, format) => date && dayjs(date, format).isValid()

export const formatDate = (date, format = 'DD.MM.YYYY') => {
	const djsDate = dayjs(date)
	if (djsDate.format) {
		return djsDate.format(format)
	}
	return djsDate
}

export const formatValidDate = (date, format, invalidValue = '—') => {
	if (!isValidDate(date)) return invalidValue
	return formatDate(date, format)
}

export const formatDateUtc = (date, format = 'DD.MM.YYYY') => formatDate(dayjs(date).utc(), format)

export const formatDateTimeUtc = (date, format = 'DD.MM.YYYY HH:mm') => formatDate(dayjs(date).utc(), format)

export const formatDateCell = (row, column, cellValue) => (cellValue ? formatDate(cellValue) : '—')

export const formatDateTime = date => (date ? formatDate(date, 'DD.MM.YYYY HH:mm') : '—')

export const formatDateTimeCell = (row, column, cellValue) => formatDateTime(cellValue)

export const formatDateTimeCellUtc = (row, column, cellValue) => formatDateTime(dayjs(cellValue).utc())

export const formatCurrency = digit => {
	const parsedDigit = parseFloat(digit)
	if (Number.isNaN(parsedDigit)) return null
	return parsedDigit.toFixed(2).replace('.', ',')
}

export const formatCurrencyCell = (row, column, cellValue) => formatCurrency(cellValue)

export function normalizeDioptreItem(item) {
	const firstSign = item > 0 ? '+' : ''

	const normalizedNumber = Number.isInteger(item) ? item.toFixed(1) : String(item)

	return `${firstSign}${normalizedNumber.replace('.', ',')}`
}

export function formatOrderId(orderId = null) {
	if (orderId === null) {
		return ''
	}
	const orderIdStr = String(orderId)
	const il = orderIdStr.length
	if (!il) return '-'
	let formattedNumber = ''
	for (let i = il - 1; i >= 0; i--) {
		formattedNumber += orderIdStr[i] + ((il - i) % 3 === 0 ? ' ' : '')
	}
	return formattedNumber.split('').reverse().join('')
}

export const formatOrderIdCell = (row, column, cellValue) => formatOrderId(cellValue)

export const getFioFull = (person = {}, firstNameBeforeFamilyName = $settings?.firstNameBeforeFamilyName) => {
	if (String(person.id) === '1000000000') {
		return i18n.global.t('Системный сотрудник')
	}

	let fio = [person.familyName, person.firstName, (person.patronymicName || person.patronymic)]
	if (firstNameBeforeFamilyName) {
		fio = [fio[1], fio[0], fio[2]]
	}
	return fio.join(' ').trim()
}

export const getFioShort = person => {
	const fio = [person.familyName]
	const f = person.firstName?.substr(0, 1)
	const p = person.patronymicName?.substr(0, 1)

	if (f) fio.push(`${f}.`)
	if (p) fio.push(`${p}.`)

	return fio.join(' ')
}

export const lensesParametersConvertation = item => {
	Object.keys(item).forEach(k => {
		if (k.startsWith('sph') || k.startsWith('cyl') || k.startsWith('dioptre')) {
			item[k] = item[k] > 0 ? `+${item[k]}` : item[k]
		}
		if (item[k] === 0) {
			item[k] = '0'
		}
		if (item[k] === '0' && (k.startsWith('add') || k.startsWith('cyl'))) {
			item[k] = null
		}
		if (k === 'createdOn' && !item.date) {
			item.date = item.createdOn
		}
	})
	return item
}

export const clientBirthday = (client = {}, hideAge = false, emptyBirthday = '—') => {
	const { birthdayDay, birthdayMonth, birthdayYear, age } = client

	let birthdayString = client.birthday
	if (birthdayDay || birthdayMonth || birthdayYear) {
		birthdayString = [
			(`0${birthdayDay || '**'}`).slice(-2),
			(`0${birthdayMonth || '**'}`).slice(-2),
			birthdayYear || '****',
		].join('.')
	}

	if (birthdayString && age && !hideAge) {
		birthdayString += ` (${i18n.global.t('возраст')}: ${age})`
	}

	return birthdayString || emptyBirthday
}