import { isMainIframe } from '../iframes'
import { getStyleSheet } from './utils/getStyleSheet'
import { deleteStyleSheetRule } from './utils/deleteStyleSheetRule'

export default function updateElDrawerIframeStyles() {
	if (!isMainIframe()) return

	const iframeScreen = window.iframeLegacyScreenPositions

	if (!iframeScreen) return

	const top = iframeScreen.windowScrollY

	// общий стиль для срабатывания при первом открытии до скролла
	const styleSheet = getStyleSheet()
	if (!styleSheet) return

	const styleDrawerString = `
	padding-top: ${top}px;
	`
	const selector = '.el-drawer'
	deleteStyleSheetRule(styleSheet, selector)
	styleSheet.insertRule(`${selector} {${styleDrawerString}}`, styleSheet.cssRules.length)
}