import DEFAULT_DATA from './filtersDefaultData'

export default {
	mFilter(state, payload) {
		// вотчеры меняют null на []
		// TODO разобраться с ComFieldSelect
		const goodsSelectsFields = [
			'brands',
			'designs',
			'targetGroups',
			'packageNums',
			'categories',
			'colors',
			'names',
			'addidations',
			'cylinders',
			'lensClasses',
			'lensTypes',
			'lensCovers',
			'wearingPeriods',
			'refractionIndices',
			'userGroups',
			'serviceTypes',
		]

		if (payload.soldGoodParameters) {
			goodsSelectsFields.forEach(field => {
				payload.soldGoodParameters[field] = payload.soldGoodParameters[field] || []
			})
		}

		if (payload.notSoldGoodParameters) {
			goodsSelectsFields.forEach(field => {
				payload.soldGoodParameters[field] = payload.soldGoodParameters[field] || []
			})
		}

		// бэк снова шлёт объекты
		if (payload.madeOrderParameters?.departments?.length) {
			payload.madeOrderParameters.departments = payload.madeOrderParameters.departments.map(item => item.id || item)
		}
		if (payload.notMadeOrderParameters?.departments?.length) {
			payload.notMadeOrderParameters.departments = payload.notMadeOrderParameters.departments.map(item => item.id || item)
		}

		const defaultFilter = DEFAULT_DATA()

		const data = {
			...defaultFilter,
			...payload,
			clientParameters: {
				...defaultFilter.clientParameters,
				...payload.clientParameters,
			},
			madeOrderParameters: {
				...defaultFilter.madeOrderParameters,
				...payload.madeOrderParameters,
			},
			notMadeOrderParameters: {
				...defaultFilter.notMadeOrderParameters,
				...payload.notMadeOrderParameters,
			},
			soldGoodParameters: {
				...defaultFilter.soldGoodParameters,
				...payload.soldGoodParameters,
			},
			notSoldGoodParameters: {
				...defaultFilter.notSoldGoodParameters,
				...payload.notSoldGoodParameters,
			},
			madePrescriptionParameters: {
				...defaultFilter.madePrescriptionParameters,
				...payload.madePrescriptionParameters,
			},
			notMadePrescriptionParameters: {
				...defaultFilter.notMadePrescriptionParameters,
				...payload.notMadePrescriptionParameters,
			},
		}

		state.filter = data
	},
	mFiltersAuthors(state, payload) {
		state.filtersAuthors = payload
	},
	mFiltersValues(state, payload) {
		state.filtersValues = payload
	},
	mFiltersGoodsValues(state, payload) {
		state.filtersGoodsValues = {
			...state.filtersGoodsValues,
			...payload,
		}
	},
}