<template>
	<div>
		<el-button
			ref="btnPopover"
			type="primary"
			plain
			class="part-order-services__btn"
			data-ta="order-services-btn"
			@click="popoverServicesVisible = !popoverServicesVisible"
		>{{ $t('Услуги') }}</el-button>

		<el-popover
			ref="popover"
			:visible="popoverServicesVisible"
			:popper-options="{ boundariesElement: 'body', gpuAcceleration: false }"
			:visible-arrow="true"
			popper-class="com-popover"
			placement="bottom"
			width="692"
			:offset="10"
			trigger="manual"
		>
			<template #reference>
				<el-button
					class="invisible-popover-trigger"
				><!-- invisible trigger for el-popover appends into body --></el-button>
			</template>

			<div
				class="part-order-services"
				data-ta="order-services"
			>
				<el-input
					ref="searchInput"
					v-model="searchServiceString"
					:placeholder="$t('Искать услугу')"
					class="part-order-services__search mb-4"
					size="default"
					data-ta="order-services-search"
				>
					<template #suffix>
						<ItIcon
							name="search"
							class="fill-text-placeholder"
						/>
					</template>
				</el-input>

				<PartOrderServicesTable
					ref="partOrderServicesTable"
					:columns="servicesColumns"
					:services="servicesFilteredList"
					:empty-text="$t('Услуги закончились')"
					@deleteDiscount="deleteDiscount"
				/>

				<div v-if="showTotalCost" class="part-order-services__selected">
					<ComOrderTags
						:tags="selectedTags"
						:deletable="true"
						style="width: 70%"
						@click="clickOnTag"
						@delete="deleteService"
					/>

					<div class="part-order-services__amount">
						<span class="part-order-services__total">{{ $t('Всего') }}</span>
						<span
							class="part-order-services__amount-total color-text-primary"
							data-ta="order-services-total"
						>{{ formatCurrency(totalCost, $i18n.locale) }}</span>
					</div>
				</div>
			</div>

			<div class="d-flex justify-content-flex-end mt-6">
				<el-button
					type="primary"
					class="bold"
					plain
					@click="close"
				>
					{{ $t('Отмена') }}
				</el-button>
				<el-button
					type="primary"
					class="ml-3 bold"
					:class="{ disabled: !selectedTags.length }"
					:disabled="isDisabledConfirm"
					@click="confirmPopover"
				>{{ $t('Добавить') }}</el-button>
			</div>
		</el-popover>
	</div>
</template>

<script>
import { mapActions, mapMutations, mapGetters, mapState } from 'vuex'
import { formatCurrency } from '~/utils/currencies'
import ComOrderTags from '../components/ComOrderTags.vue'
import PartOrderServicesTable from './PartOrderServices/PartOrderServicesTable.vue'
import { servicesColumns } from '../data/servicesColumns'
import { registerPopover, unregisterPopover } from '../data/clickOutsidePopover'

export default {
	name: 'PartOrderServices',

	components: {
		ComOrderTags,
		PartOrderServicesTable,
	},

	inject: ['setCartLoading'],

	data() {
		return {
			popoverServicesVisible: false,
			servicesColumns,
			prop: 'services',
			hasActions: true,
			searchServiceString: '',
			searchEmployeString: '',
		}
	},

	computed: {
		...mapState('orders', [
			'cartUid',
			'orderErrors',
		]),
		...mapGetters('orders', [
			'gServices',
		]),

		selectedServices() {
			return this.gServices
				.filter(service => service.amount)
		},

		servicesFilteredList() {
			const substring = this.searchServiceString.toLowerCase()
			return this.gServices
				.filter(service => service.name.toLowerCase().includes(substring))
				.map(service => ({
					...service,
					expand: null,
					retailPriceStr: formatCurrency(service.retailPrice, this.$i18n.locale),
					discountPriceStr: formatCurrency(service.discountPrice, this.$i18n.locale),
				}))
		},

		selectedTags() {
			return this.selectedServices
				.map(service => ({
					...service,
					amount: service.amount,
				}))
		},

		totalCost() {
			return this.selectedServices
				.map(service => service.discountPrice)
				.reduce((prev, current) => prev + current, 0)
		},

		showTotalCost() {
			return this.selectedServices.length
		},
		isDisabledConfirm() {
			if (this.orderErrors?.services) {
				const servicesErrors = Object.values(this.orderErrors.services)
				if (servicesErrors.includes(true)) return true
			}

			return !this.selectedTags.length
		},
	},

	watch: {
		popoverServicesVisible: {
			handler() {
				this.$nextTick(() => { this.$refs.searchInput.focus() })
			},
		},
	},

	mounted() {
		registerPopover({
			group: 'services',
			ref: this.$refs.popover,
			closeFn: this.close,
			triggerRef: this.$refs.btnPopover,
		})
	},

	beforeUnmount() {
		unregisterPopover(this.$refs.popover)
	},

	methods: {
		formatCurrency,
		...mapMutations('orders', ['mOrderErrors']),
		...mapActions('orders', [
			'aAddService',
			'aServices',
			'aAddAllServices',
			'aDeleteServiceDiscount',
		]),

		clickOnTag(tag) {
			const { name } = tag
			const clickedItemIndex = this.servicesFilteredList.findIndex(goods => goods.name === name)

			if (clickedItemIndex < 0) return

			const wrapper = this.$refs.partOrderServicesTable.$el.querySelector('.el-table__body-wrapper')
			const trs = wrapper.querySelectorAll('tbody tr')
			wrapper.scrollTo({
				top: trs[clickedItemIndex]?.offsetTop || 0,
				left: 0,
				behavior: 'smooth',
			})
		},

		async addAllServices() {
			this.setCartLoading(true)

			const data = this.selectedServices
				.map(service => ({
					serveTypeId: service.id,
					count: service.amount,
					userId: service.employee ? service.employee.id : null,
					comment: service.comment,
				}))

			const response = await this.aAddAllServices({ uid: this.cartUid, data })

			if (response?.status === 200) {
				this.aServices(this.cartUid)
				this.$notify({ title: this.$t('Добавлено в корзину'), type: 'success' })
			}

			this.setCartLoading(false)
		},

		async deleteService(service) {
			this.mOrderErrors({
				from: 'services',
				error: {
					[service.id]: false,
				},
			})

			const data = {
				serveTypeId: service.id,
				count: 0,
				userId: service.employee.id,
				comment: service.comment,
			}
			await this.aAddService({
				uid: this.cartUid,
				data,
			})
		},

		deleteDiscount(id) {
			this.aDeleteServiceDiscount({
				uid: this.cartUid,
				id,
			})
		},

		async confirmPopover() {
			if (this.$refs.partOrderServicesTable.validate()) {
				this.addAllServices()
				this.close()
			}
		},

		close() {
			this.popoverServicesVisible = false
		},
	},
}
</script>

<style lang="scss">
.com-popover {
	max-height: 514px;
}

.invisible-popover-trigger {
	width: 100%;
	height: 0;
	margin: 0;
	padding: 0;
	border: none;
	overflow: hidden;
	display: block;

	* {
		display: none !important;
	}
}

.part-order-services {
	&__btn {
		font-weight: 600;
		width: 100%;
	}

	&__selected {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		margin-top: 24px;
		padding: 0 5px;
	}

	&__amount {
		display: flex;
		align-items: center;
		gap: 12px;
		max-height: 28px;
	}

	&__total {
		margin-top: 7px;
	}

	&__amount-total {
		font-weight: 700;
		font-size: 24px;
	}

	.icon {
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.el-input-number--mini .el-input-number__increase,
	.el-input-number--mini .el-input-number__decrease {
		width: 33px;
	}

	.el-input-group__append {
		padding: 7px 2px 3px 8px !important;
		width: 44% !important;
	}

	.el-table__expanded-cell {
		padding: 0 !important;
	}

	.disabled {
		background: #f2f6fc !important;
		border: 1px solid #cbcbd5 !important;
		color: #c0c4cc !important;
	}
}
</style>