import integrationSettingsState from './integrationSettingsState'
import integrationSettingsActions from './integrationSettingsActions'
import integrationSettingsMutations from './integrationSettingsMutations'
import integrationSettingsGetters from './integrationSettingsGetters'

export default {
	namespaced: true,
	state: integrationSettingsState,
	getters: integrationSettingsGetters,
	mutations: integrationSettingsMutations,
	actions: integrationSettingsActions,
}