<template>
	<div
		id="goods-params"
		v-loading="isLoading"
	>

		<div class="h-100p">
			<el-form
				ref="form"
				:model="data"
				:rules="rules"
				label-width="100px"
				label-position="left"
				size="small"
				require-asterisk-position="right"
			>
				<el-form-item
					v-if="!isOnlyOneGeneralRuleType"
					:label="$t('Категория')"
					size="small"
					class="mb-6"
				>
					<el-radio-group v-model="currentGeneralRuleType">
						<el-radio
							v-for="(generalRuleType, code) in generalRuleTypes"
							:key="code"
							border
							:label="code"
							:data-ta="generalRuleType.value"
							class="mr-4"
						>{{ generalRuleType.label }}</el-radio>
					</el-radio-group>
				</el-form-item>

				<div v-if="isSelectedGoodsRuleType" class="d-flex flex-wrap mb-3">
					<div
						v-for="(subRuleType, code) in subRuleTypes"
						:key="code"
						:data-ta="subRuleType.value"
						class="clickable border-base d-flex align-items-center line-height-0 border-radius-round px-1 py-1 mr-3 mb-3 border-box"
						:class="{
							'border-active': currentRuleType === code,
						}"
						@click="changeRuleType(code)"
					>
						<ItIcon :name="subRuleType.icon" class="mr-3"/>
						<span>{{ subRuleType.label }}</span>
					</div>
				</div>

				<ComFormFields
					:data="data"
					:fields="fields"
					:label-width="labelWidth"
					size="small"
					:is-edit="true"
					:columns="2"
				/>

				<el-divider
					v-if="isSelectedGoodsRuleType || isServicesRuleType"
					class="mt-4"
				/>

				<component
					:is="currentRuleTypeComponent"
					v-if="!isLoading && currentRuleTypeComponent"
					ref="currentRule"
					v-model="data"
					:form-fields="currentComponentsFormItems"
					class="mb-6"
					@services-loaded="onServicesLoaded"
				/>
			</el-form>
		</div>
	</div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { mapState } from 'vuex'
import { getDefaultRulesTypes, getDefaultSubRulesTypes } from '~/components/goods/data/getDefaultRulesTypes'
import capitalizeFirstLetter from '~/utils/capitalizeFirstLetter'
import CostFormat from '~/kit/filters/CostFormat'
import { DIOPTRE_FIELDS } from '~/components/bonus/rules/Utils'
import { normalizeDioptreItem } from '~/utils/formatters'
import { getDefaultData } from '~/components/goods/data/getDefaultData.js'
import { getDefaultRuleTypeForms } from '~/components/goods/data/getDefaultRuleTypeForms.js'
import getDefaultFields from '~/components/goods/data/getDefaultFields.js'
import ComFormFields from '~/components/ComFormFields'
import { minNumber } from '~/utils/validationRules'

export default {
	name: 'GoodsParams',

	components: {
		ComFormFields,
		Accessories: defineAsyncComponent(() => import('~/components/goods/GoodsParams/Accessories')),
		ContactLenses: defineAsyncComponent(() => import('~/components/goods/GoodsParams/ContactLenses')),
		Extras: defineAsyncComponent(() => import('~/components/goods/GoodsParams/Extras')),
		Glasses: defineAsyncComponent(() => import('~/components/goods/GoodsParams/Glasses')),
		Lenses: defineAsyncComponent(() => import('~/components/goods/GoodsParams/Lenses')),
		Serve: defineAsyncComponent(() => import('~/components/goods/GoodsParams/Serve')),
		Sunglasses: defineAsyncComponent(() => import('~/components/goods/GoodsParams/Sunglasses')),
	},

	props: {
		modelValue: {
			type: Object,
			default: getDefaultData,
		},
		fields: {
			type: Array,
			default: getDefaultFields,
		},
		componentsFormItems: {
			type: Object,
			default: null,
		},
		labelWidth: {
			type: String,
			default: '100px',
		},
		generalRuleTypes: {
			type: Object,
			default: getDefaultRulesTypes,
		},
		subRuleTypes: {
			type: Object,
			default: getDefaultSubRulesTypes,
		},
	},

	data() {
		return {
			data: {},
			initialized: false,
			currentGeneralRuleType: 'ALL',
			currentSubRyleType: 'GLASSES',
			labelAllItems: {
				models: this.$t('Все модели'),
				servicesId: this.$t('Все услуги'),
				departmentIds: this.$t('Все департаменты'),
			},
			ruleTypes: {
				ALL: {
					label: this.$t('Все товары'),
				},
				SELECTED: {
					label: this.$t('Избранные товары'),
				},
				SERVE: {
					label: this.$t('Услуги'),
				},
				GLASSES: {
					label: this.$t('Оправы'),
					icon: 'glasses',
				},
				SUNGLASSES: {
					label: this.$t('Солнцезащитные очки'),
					icon: 'sun-glasses',
				},
				CONTACT_LENSES: {
					label: this.$t('Контактные линзы'),
					icon: 'contact-lens',
				},
				LENSES: {
					label: this.$t('Линзы'),
					icon: 'lenses',
				},
				ACCESSORIES: {
					label: this.$t('Аксессуары'),
					icon: 'glasses-case',
				},
				EXTRAS: {
					label: this.$t('Массовка'),
					icon: 'massovka',
				},
			},
			ruleTypeForms: getDefaultRuleTypeForms(),
			isLoading: false,
			selectedFields: [],
		}
	},
	computed: {
		...mapState(['settings']),

		currency() {
			return this.settings.currency
		},

		currentRuleTypeComponent() {
			if (!this.ruleTypeForms[this.currentRuleType]) return null

			return this.currentRuleType.toLowerCase().split('_').map(capitalizeFirstLetter).join('')
		},

		currentRuleType() {
			if (this.generalRuleTypes[this.currentGeneralRuleType] && !this.isSelectedGoodsRuleType) return this.currentGeneralRuleType

			return this.currentSubRyleType
		},

		isAllRuleType() {
			return this.currentGeneralRuleType === 'ALL'
		},

		isSelectedGoodsRuleType() {
			return this.currentGeneralRuleType === 'SELECTED'
		},

		isServicesRuleType() {
			return this.currentGeneralRuleType === 'SERVE'
		},
		isOnlyOneGeneralRuleType() {
			return Object.keys(this.generalRuleTypes).length === 1
		},
		currentComponentsFormItems() {
			return this.componentsFormItems?.[this.currentRuleTypeComponent] || null
		},

		rules() {
			return {
				priceTo: [minNumber(this.data.priceFrom)],
			}
		},
	},
	watch: {
		data: {
			deep: true,
			handler(newData) {
				if (this.initialized) {
					const preparedNewData = { ...this.modelValue }
					const availableKeys = [
						'groupByDepartmentAndParams',
						'priceFrom',
						'priceTo',
						'goodsType',
					]

					Object.entries(newData).forEach(([key, value]) => {
						if (this.hasKeyCheck(this.ruleTypeForms, key)) {
							preparedNewData[key] = value
						} else if (availableKeys.includes(key)) {
							preparedNewData[key] = value
						}
					})

					this.$emit('update:modelValue', preparedNewData)
					this.setSelectedFields()
				}
			},
		},
		currentGeneralRuleType() {
			if (this.initialized) {
				this.onChangeRuleType()
			}
		},
		currentSubRyleType() {
			if (this.initialized) {
				this.onChangeRuleType()
			}
		},
	},
	mounted() {
		this.init()
		this.$nextTick(this.afterInit)
	},
	methods: {
		init() {
			const firstRuleType = Object.values(this.generalRuleTypes)[0]
			this.changeRuleType(this.modelValue?.goodsType || firstRuleType.value)
		},

		afterInit() {
			this.isLoading = true
			this.updateData()
			this.setSelectedFields()
			setTimeout(() => {
				this.initialized = true
				this.isLoading = false
			})
		},

		changeRuleType(code) {
			if (this.generalRuleTypes[code]) {
				this.currentGeneralRuleType = code
			} else {
				this.currentGeneralRuleType = 'SELECTED'
				this.currentSubRyleType = code
			}
		},

		onChangeRuleType() {
			this.data = {
				...getDefaultData(),
				...this.modelValue,
				priceTo: this.data.priceTo,
				priceFrom: this.data.priceFrom,
			}

			this.ruleTypeForms = {
				...getDefaultRuleTypeForms(),
			}

			if (!this.isAllRuleType) {
				this.data.goodsType = this.currentRuleType
			} else {
				this.data.goodsType = ''
			}
		},

		updateData() {
			this.data = { ...this.modelValue }

			if (!this.isAllRuleType) {
				this.data.goodsType = this.currentRuleType
			}
		},

		getLabel(item) {
			if (item && typeof item === 'object') return item.name

			return item
		},

		getSelectedFields() {
			const selectedFields = []

			selectedFields.push({
				value: this.ruleTypes[this.currentRuleType].label,
				label: this.ruleTypes[this.currentRuleType].label,
				key: 'ruleType',
				filter: 'ruleType',
				closable: this.currentRuleType !== 'ALL' && !this.isEditView,
			})

			if (this.data.priceFrom) {
				selectedFields.push({
					value: this.data.priceFrom,
					label: `${this.$t('Стоимость от')} ${CostFormat(Number(this.data.priceFrom))} ${this.currency?.code}`,
					key: 'priceFrom',
					filter: 'priceFrom',
					closable: true,
				})
			}

			if (this.data.priceTo) {
				selectedFields.push({
					value: this.data.priceTo,
					label: `${this.$t('Стоимость до')} ${CostFormat(Number(this.data.priceTo))} ${this.currency?.code}`,
					key: 'priceTo',
					filter: 'priceTo',
					closable: true,
				})
			}

			if (this.currentRuleType === 'ALL') return selectedFields

			if (this.currentRuleType === 'SERVE') {
				let errors = null
				if (this.data.servicesId.length > 0) {
					this.data.servicesId.forEach(serviceId => {
						const serviceFull = typeof serviceId === 'object'
							? serviceId
							: this.$refs.currentRule?.services.find(service => service.id === serviceId)

						if (serviceFull) {
							const label = this.getLabel(serviceFull)

							selectedFields.push({
								value: serviceId,
								label,
								key: `servicesId_${label}`,
								filter: 'servicesId',
								closable: !this.isEditView,
							})
						}
					})
				} else {
					errors = [this.$t('Скидка не распространяется ни на одну услугу')]
				}

				return {
					fields: selectedFields,
					errors,
				}
			}

			// TODO с expirationDateFrom и expirationDateTo костыль, надо сделать по-другому
			const date = []

			Object.keys(this.ruleTypeForms[this.currentRuleType]).forEach(key => {
				if (this.data[key] && (key === 'expireOnFrom' || key === 'expireOnTo')) {
					if (key === 'expireOnFrom') date.unshift(this.data[key])
					else date.push(this.data[key])
				} else if ((this.data[key] || this.data[key] === 0) && (!Array.isArray(this.data[key]) || this.data[key].length !== 0)) {
					(Array.isArray(this.data[key]) ? this.data[key] : [this.data[key]]).forEach(value => {
						selectedFields.push({
							value,
							label: DIOPTRE_FIELDS.includes(key) ? normalizeDioptreItem(value) : value,
							key: `${key}_${value}`,
							filter: key,
							closable: !this.isEditView,
						})
					})
				} else if ((Array.isArray(this.data[key]) || this.data[key] === null) && this.labelAllItems[key]) {
					selectedFields.push({
						value: '',
						label: this.labelAllItems[key],
						key,
						filter: key,
						closable: false,
					})
				}
			})

			if (date.length > 0) {
				const value = date.map(d => this.$dayjs(d).format('DD.MM.YYYY')).join(' - ')

				selectedFields.push({
					value,
					label: value,
					key: `date_${value}`,
					filter: ['expireOnFrom', 'expireOnTo'],
					closable: !this.isEditView,
				})
			}

			return selectedFields
		},
		setSelectedFields() {
			if (this.isReadView) return

			this.selectedFields = this.getSelectedFields()

			this.$emit('set-selected-fields', this.selectedFields)
		},

		resetField(entryField, value) {
			if (entryField === 'ruleType') {
				this.currentGeneralRuleType = 'ALL'

				return
			}

			const field = Array.isArray(entryField) ? entryField[0] : entryField

			let data = null

			if (field in this.data) {
				data = this.data
			}

			if (!data) return

			if (Array.isArray(entryField)) {
				entryField.forEach(entryOneField => {
					data[entryOneField] = null
				})

				return
			}

			if (Array.isArray(data[field])) {
				const index = data[field].indexOf(value)

				if (index !== -1) data[field].splice(index, 1)
			} else {
				data[field] = null
				this.ruleTypeForms[this.currentRuleType][field] = null
			}
		},
		resetAllFields() {
			this.data = getDefaultData()
			this.ruleTypeForms = getDefaultRuleTypeForms()
			this.currentGeneralRuleType = 'ALL'
		},
		validate() {
			return this.$isFormValid('form')
		},
		onServicesLoaded() {
			this.setSelectedFields()
		},
		hasKeyCheck(obj, key) {
			/* eslint-disable */
			if (!obj || (typeof obj !== 'object' && !Array.isArray(obj))) {
				return false
			}

			if (obj.hasOwnProperty(key)) {
				return true
			}

			if (Array.isArray(obj)) {
				for (let i = 0; i < obj.length; i++) {
					const result = this.hasKeyCheck(obj[i], key)
					if (result) {
						return result
					}
				}
			} else {
				for (const k in obj) {
					const result = this.hasKeyCheck(obj[k], key)
					if (result) {
						return result
					}
				}
			}

			return false
		},
	},
}
</script>

<style lang="scss">

#goods-params {
  .form-item {
    &-large {
      width: 278px;
    }

    &-small {
      width: 117px;
    }
  }

  .rule-type-block {
    width: 200px;
  }

  .el-form-item {
    margin-bottom: 12px;

    &__label {
		height: 30px;
		margin-top: 0;
		align-items: center;
		display: flex;
		line-height: 1;
		margin-bottom: 0px;
	}
  }

  .el-radio {
    &__inner {
      border-color: var(--el-border-color-dark);
    }

    &__input.is-checked {
      .el-radio__inner {
        border: none !important;
      }
    }

    &-group {
      background: var(--el-color-white);
      border-radius: 4px;
	  display: inline-block;
    }

    &-button {
      &__inner {
        background: var(--el-color-white) !important;
        border-color: var(--el-border-color-dark) !important;
      }

      &.is-active {
        .el-radio-button__inner {
          background: var(--el-color-primary) !important;
        }
      }
    }
  }
}
</style>