import { clientBirthday, getFioFull } from '~/utils/formatters'

export default {
	gJournalsDepartmentsList(state, getters, _, rootGetters) {
		return state.journalsDepartments.map(dep => ({
			...dep,
			legalEntity: rootGetters['legalEntities/gLegalEntitiesMap'][dep.legalEntityId],
			location: dep.location?.string || '-',
			type: rootGetters['departments/gDepartmentsTypesMapLocale'][dep.type],
		}))
	},

	gJournalsDirectoryList(state) {
		return state.journalsDirectory.length
			? state.journalsDirectory
				.map(directory => {
					const result = {}

					if (directory.name) {
						result.name = directory.name
						result.deleted = directory.deleted
					} else if (directory.value && !directory.type) {
						result.value = String(directory.value)
						result.deleted = directory.deleted
					} else if (directory.type) {
						result.value = `${directory.value}%`
						result.type = directory.type
					} else {
						result.name = directory
						result.deleted = false
					}

					return result
				})
			: state.journalsDirectory
	},

	gReturnsJournal(state) {
		const MAX_COMMENT_LENGTH = 27

		return state.journalsReturns
			.map(item => {
				const isLengthOverloaded = item.comment.length > MAX_COMMENT_LENGTH
				const shortComment = item.comment.substring(0, MAX_COMMENT_LENGTH)

				return {
					...item,
					isLengthOverloaded,
					shortComment: shortComment + (isLengthOverloaded ? '...' : ''),
				}
			})
	},
	gJournalsClients(state, _getters, rootState) {
		return state.journalsClients.map(client => {
			let fio = getFioFull(client, rootState.settings.firstNameBeforeFamilyName)

			return {
				...client,
				birthday: clientBirthday(client, true, '-'),
				fio,
			}
		})
	},
}