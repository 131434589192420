import journalsState from './journalsState'
import journalsActions from './journalsActions'
import journalsMutations from './journalsMutations'
import journalsGetters from './journalsGetters'

export default {
	namespaced: true,
	state: journalsState,
	getters: journalsGetters,
	mutations: journalsMutations,
	actions: journalsActions,
}