<template>
	<div>
		<el-form-item
			:label="$t('Подарочный сертификат')"
			:class="{ 'is-error': certificateRequestError }"
			prop="certificate"
			:rules="certificateRules"
			class="mb-3"
		>
			<el-input
				v-model="model"
				data-ta="gift-certificate-number-input"
				:placeholder="$t('Введите номер')"
				:maxlength="gSettings.giftCertificateRealNumLength"
				show-word-limit
				size="default"
				class="mt-0 gift-certificate-input"
				clearable
				@update:modelValue="scanCertificate"
			>
				<template #prefix>
					<ItIcon
						name="gift-certificate"
						class="fill-text-placeholder mr-2"
						width="16"
						height="16"
					/>
				</template>
			</el-input>
		</el-form-item>

		<div
			v-if="certificateRequestError"
			class="payment-popup__error d-flex align-items-center mb-3 justify-content-flex-end"
		>
			<div class="payment-popup__error-nested">
				<ItIcon
					name="info-outline"
					width="16"
					height="16"
					class="fill-danger mr-3"
				/>
				<div>{{ $t('Сертификат не найден') }}</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { isNumber, specialNumberOfCharacters, hasWhitespaces } from '~/utils/validationRules'

export default {
	name: 'PaymentCertificate',

	props: {
		certificateRequestError: {
			type: Boolean,
			default: false,
		},
		modelValue: {
			type: String,
			default: '',
		},
	},

	computed: {
		...mapGetters(['gSettings']),

		certificateRules() {
			const certificateLength = this.gSettings.giftCertificateRealNumLength
			return [isNumber, hasWhitespaces, specialNumberOfCharacters(certificateLength)]
		},

		model: {
			get() {
				return this.modelValue
			},
			set(val) {
				this.$emit('update:modelValue', val)
			},
		},
	},

	methods: {
		scanCertificate() {
			this.$emit('scanCertificate')
		},
	},
}
</script>