<template>
	<el-popover
		:visible="visible"
		:placement="placement"
		popper-class="com-popover"
		:visible-arrow="visibleArrow"
		:width="width"
		:title="title"
		:offset="offset"
		@hide="hide"
		@show="show"
	>
		<div>
			<slot name="main"/>
		</div>

		<template #reference>
			<div @click="toggle">
				<slot name="button"/>
			</div>
		</template>

		<div
			v-if="hasActions"
			class="mt-6"
		>
			<div
				:class="{
					'com-popover__actions-right': actionsPosition === 'right',
					'com-popover__actions-center': actionsPosition === 'center',
				}"
			>
				<el-button
					type="primary"
					class="bold"
					plain
					@click="close"
				>
					{{ $t('Отмена') }}
				</el-button>
				<el-button
					type="primary"
					class="ml-3 bold"
					:disabled="isDisabledConfirm"
					@click="confirm"
				>
					{{ actionConfirm }}
				</el-button>
			</div>
		</div>
	</el-popover>
</template>

<script>
export default {
	name: 'ComPopover',
	props: {
		title: {
			type: String,
			default: '',
		},
		width: {
			type: String,
			default: 'auto',
		},
		hasActions: {
			type: Boolean,
			default: false,
		},
		actionsPosition: {
			type: String,
			default: 'right',
		},
		actionConfirm: {
			type: String,
			default: '',
		},
		offset: {
			type: Number,
			default: 0,
		},
		isDisabledConfirm: {
			type: Boolean,
			default: false,
		},
		placement: {
			type: String,
			default: 'bottom',
		},
		visibleArrow: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			visible: false,
		}
	},
	methods: {
		toggle() {
			if (this.visible) {
				this.close()
			} else {
				this.open()
			}
		},
		close() {
			this.visible = false
			this.$emit('close')
		},
		open() {
			this.visible = true
			this.$emit('open', this.close)
		},
		confirm() {
			this.$emit('confirm')
		},
		hide() {
			this.$emit('hide')
		},
		show() {
			this.$emit('show')
		},
	},
}
</script>

<style lang="scss">
	.com-popover {
		box-shadow: 0 16px 48px rgba(46, 56, 77, 0.48);

		&__actions-right {
			display: flex;
			justify-content: flex-end;
		}

		&__actions-center {
			display: flex;
			justify-content: center;
		}

		&__actions-center > * {
			width: 50%;
		}
	}

	.el-popover__title {
		font-weight: 600 !important;
		font-size: 14px !important;
		color: #2e384d !important;
		margin-bottom: 16px !important;
	}
</style>
