export default {
	modelValue: {
		type: [String, Number, Boolean, Array],
		default: '',
	},
	defaultValue: {
		type: [String, Number, Boolean, Array],
		default: null,
	},
	showTitle: {
		type: Boolean,
		default: true,
	},
	isEdit: {
		type: Boolean,
		default: false,
	},
	htmlView: {
		type: Boolean,
		default: false,
	},
	label: {
		type: String,
		default: '',
	},
	labelPosition: {
		type: String,
		default: 'left',
	},
	name: {
		type: String,
		required: true,
	},
	placeholder: {
		type: String,
		default: '',
	},
	maxLength: {
		type: Number,
		default: undefined,
	},
	showWordLimit: {
		type: Boolean,
		default: false,
	},
	disabled: {
		type: Boolean,
		default: false,
	},
	readonly: {
		type: Boolean,
		default: false,
	},
	clearable: {
		type: Boolean,
		default: true,
	},
	loading: {
		type: Boolean,
		default: false,
	},
	labelWidth: {
		type: String,
		default: '40%',
	},
	css: {
		type: Object,
		default: null,
	},
	format: {
		type: String,
		default: '',
	},
	linkPattern: {
		type: String,
		default: '',
	},
	size: {
		type: String,
		default: 'default',
		validator(value) {
			return ['', 'small', 'default'].includes(value)
		},
	},
	switchable: {
		type: Boolean,
		default: false,
	},
	switchParams: {
		type: Object,
		default: null,
	},
	popperClass: {
		type: String,
		default: '',
	},
	options: {
		type: Array,
		default: null,
	},
	labelColorDot: {
		type: String,
		default: null,
	},
	showLabel: {
		type: Boolean,
		default: true,
	},
	isHtml: {
		type: Boolean,
		default: false,
	},
	onClear: {
		type: Function,
		default: null,
	},
	onClick: {
		type: Function,
		default: null,
	},
	infoTooltip: {
		type: Object,
		default: null,
	},
	infoLabelTooltip: {
		type: Object,
		default: null,
	},
}