<template>
	<div
		:data-ta="`input_${name}`"
		class="com-field-item com-field-input"
	>
		<PartFieldView
			v-if="!isEdit"
			v-model="viewValue"
			:name="name"
			:label="label"
			:label-width="labelWidth"
			:label-color-dot="labelColorDot"
			:link-pattern="linkPattern"
			:show-label="showLabel"
			:is-html="isHtml"
			:on-click="onClick"
			:info-tooltip="infoTooltip"
		/>

		<el-form-item
			v-else
			:ref="name"
			:prop="name"
			:label="label"
			:label-width="labelWidth"
		>

			<PartInfoTooltip
				v-if="infoLabelTooltip"
				:info-tooltip="infoLabelTooltip"
				label-tooltip
			/>

			<div class="d-flex align-items-center w-100p gap-4">
				<el-input
					v-if="mask"
					ref="inputMask"
					v-model="model"
					v-mask="mask"
					v-loading="loading"
					v-input-suffix-padding
					:placeholder="placeholder"
					:data-ta="dataTa || name"
					:clearable="clearable"
					:disabled="disabled || readonly"
					:maxlength="maxLength"
					:show-word-limit="showWordLimit"
					:size="size"
					:type="fieldType"
				>
					<template
						v-if="suffixSlot"
						#suffix
					>
						<PartSuffixTooltip
							:suffix-slot="suffixSlot"
						/>
					</template>
					<template
						v-if="prepend"
						#prepend
					>{{ prepend }}</template>
					<template
						v-if="append"
						#append
					>{{ append }}</template>
				</el-input>

				<el-input
					v-else
					ref="inputField"
					v-model="model"
					v-loading="loading"
					v-input-suffix-padding
					:placeholder="placeholder"
					:data-ta="dataTa || name"
					:clearable="clearable"
					:disabled="disabled || readonly"
					:maxlength="maxLength"
					:show-word-limit="showWordLimit"
					:size="size"
					:type="fieldType"
				>
					<template
						v-if="suffixSlot"
						#suffix
					>
						<PartSuffixTooltip
							:suffix-slot="suffixSlot"
						/>
					</template>
					<template
						v-if="prepend"
						#prepend
					>{{ prepend }}</template>
					<template
						v-if="append"
						#append
					>{{ append }}</template>
				</el-input>

				<PartSwitch
					v-if="switchable"
					:switch-params="switchParams"
					:size="size"
					@on-change-switch="onChangeSwitch"
				/>

				<PartInfoTooltip
					v-if="infoTooltip"
					:info-tooltip="infoTooltip"
				/>

			</div>
		</el-form-item>
	</div>
</template>

<script>
import { mask } from 'vue-the-mask'
import inputSuffixPadding from '~/helpers/directives/InputSuffixPadding.js'
import defaultProps from './defaultFieldsProps'
import PartFieldView from './parts/PartFieldView'
import PartInfoTooltip from './parts/PartInfoTooltip'
import PartSuffixTooltip from './parts/PartSuffixTooltip'
import PartSwitch from './parts/PartSwitch.vue'

export default {
	name: 'ComFieldInput',
	components: {
		PartFieldView,
		PartInfoTooltip,
		PartSuffixTooltip,
		PartSwitch,
	},
	directives: {
		mask,
		inputSuffixPadding,
	},
	props: {
		...defaultProps,
		mask: {
			type: String,
			default: '',
		},
		fieldType: {
			type: String,
			default: 'text',
		},
		suffixSlot: {
			type: Object,
			default: null,
		},
		onPaste: {
			type: Function,
			default() {},
		},
		emptyValueLabel: {
			type: String,
			default: '—',
		},
		switchable: {
			type: Boolean,
			default: false,
		},
		confirmParams: {
			type: Object,
			default: null,
		},
		activeText: {
			type: String,
			default: '',
		},
		inactiveText: {
			type: String,
			default: '',
		},
		append: {
			type: String,
			default: '',
		},
		prepend: {
			type: String,
			default: '',
		},
		dataTa: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			oldValue: null,
		}
	},
	computed: {
		model: {
			get() { return typeof this.modelValue === 'undefined' ? null : this.modelValue },
			set(value) {
				// В FireFox el-input с дир-вой v-mask дважды вызывает событие input
				if (this.oldValue === value) return
				this.oldValue = value
				this.$emit('update:modelValue', value)
			},
		},
		viewValue() {
			return this.modelValue || this.emptyValueLabel
		},
	},
	mounted() {
		this.listenersRegister()
	},
	beforeUnmount() {
		this.listenersRemove()
	},
	methods: {
		listenersRegister() {
			const inputRef = (this.$refs?.inputMask || this.$refs?.inputField)?.$el
			if (inputRef && this.onPaste) {
				inputRef.addEventListener('paste', e => {
					const value = this.onPaste(e)
					this.$emit('update:modelValue', value)
				})
			}
		},
		listenersRemove() {
			const inputRef = (this.$refs?.inputMask || this.$refs?.inputField)?.$el
			if (inputRef && this.onPaste) {
				inputRef.removeEventListener('paste', this.onPaste)
			}
		},

		onChangeSwitch(val) {
			if (this.switchParams?.onChange) {
				this.switchParams.onChange(val)
			}
		},
	},
}
</script>

<style lang="scss">
.com-field-input {
	flex: 1;
}
</style>