<template>
	<div
		:data-ta="`range_selects_${name}_${nameTo}`"
		class="com-field-item com-field-range-selects"
	>
		<PartFieldView
			v-if="!isEdit"
			v-model="viewValues"
			:name="name"
			:options="options"
			:label="label"
			:label-width="labelWidth"
			:label-color-dot="labelColorDot"
			:link-pattern="linkPattern"
			:show-label="showLabel"
			:is-html="isHtml"
			:on-click="onClick"
			:info-tooltip="infoTooltip"
		/>

		<el-form-item
			v-else
			:ref="name"
			:prop="name"
			:label="label"
			:label-width="labelWidth"
		>

			<PartInfoTooltip
				v-if="infoLabelTooltip"
				:info-tooltip="infoLabelTooltip"
				label-tooltip
			/>

			<div class="d-flex align-items-center w-100p">
				<span class="mr-2 com-field-range__label">{{ placeholderFrom }}</span>
				<el-select
					v-model="from"
					:disabled="disabled || readonly"
					:filterable="filterable"
					:data-ta="dataTaFrom || name"
					:multiple="multiple"
					:placeholder="placeholder"
					:loading="loading"
					:clearable="clearable"
					:size="size"
					:collapse-tags="collapseTags"
					:popper-class="'ta-select-dropdown ta-select-dropdown-' + name"
					:value-key="valueKey"
					class="w-100p"
				>
					<el-option
						v-for="{ value: value2, label: label2 } in options"
						:key="value2"
						:value="value2"
						:label="label2"
						@click="onOptionClick(value2)"
					/>
				</el-select>

				<span class="mr-2 ml-2 com-field-range__label">{{ placeholderTo }}</span>
				<el-select
					v-model="to"
					:disabled="disabled || readonly"
					:filterable="filterable"
					:data-ta="dataTaTo || nameTo"
					:multiple="multiple"
					:placeholder="placeholder"
					:loading="loading"
					:clearable="clearable"
					:size="size"
					:collapse-tags="collapseTags"
					:popper-class="'ta-select-dropdown ta-select-dropdown-' + nameTo"
					:value-key="valueKey"
					class="w-100p"
				>
					<el-option
						v-for="{ value: value2, label: label2 } in options"
						:key="value2"
						:value="value2"
						:label="label2"
						@click="onOptionClick(value2)"
					/>
				</el-select>

				<el-tooltip
					v-if="deleteTooltip"
					:content="deleteTooltip"
					:disabled="!deleteTooltip"
					placement="right"
					effect="light"
				>
					<ItIcon
						v-if="deleteFn"
						name="trash"
						class="ml-6 clickable"
						@click="deleteFn"
					/>
				</el-tooltip>

				<PartInfoTooltip
					v-if="infoTooltip"
					:info-tooltip="infoTooltip"
				/>

			</div>
		</el-form-item>
	</div>
</template>

<script>
import i18n from '~/plugins/i18n'
import defaultProps from './defaultFieldsProps'
import PartFieldView from './parts/PartFieldView'
import PartInfoTooltip from './parts/PartInfoTooltip'

export default {
	name: 'ComFieldRangeSelects',
	components: {
		PartFieldView,
		PartInfoTooltip,
	},
	props: {
		...defaultProps,
		nameTo: {
			type: String,
			required: true,
		},
		modelFrom: {
			type: [Number, Array],
			default: 0,
		},
		modelTo: {
			type: [Number, Array],
			default: 0,
		},
		options: {
			type: Array,
			default: () => ([]),
		},
		valueKey: {
			type: String,
			default: null,
		},
		multiple: {
			type: Boolean,
			default: false,
		},
		fieldType: {
			type: String,
			default: 'text',
		},
		suffixSlot: {
			type: Object,
			default: null,
		},
		filterable: {
			type: Boolean,
			default: true,
		},
		placeholderFrom: {
			type: String,
			default: i18n.global.t('от'),
		},
		placeholderTo: {
			type: String,
			default: i18n.global.t('по'),
		},
		deleteFn: {
			type: Function,
			default: null,
		},
		deleteTooltip: {
			type: String,
			default: null,
		},
		collapseTags: {
			type: Boolean,
			default: false,
		},
		emptyValueLabel: {
			type: String,
			default: '—',
		},
		optionsFrom: {
			type: Array,
			default: null,
		},
		optionsTo: {
			type: Array,
			default: null,
		},
		dataTaFrom: {
			type: String,
			default: null,
		},
		dataTaTo: {
			type: String,
			default: null,
		},
	},
	computed: {
		from: {
			get() { return this.modelFrom ?? '' },
			set(value) {
				this.$emit('change', value)
				this.$emit('update:modelFrom', value)
			},
		},
		to: {
			get() { return this.modelTo ?? '' },
			set(value) {
				this.$emit('change', value)
				this.$emit('update:modelTo', value)
			},
		},
		viewValuesFrom() {
			let values = Array.isArray(this.modelFrom) ? this.modelFrom : [this.modelFrom]

			if (!values.length) {
				return [{
					label: this.emptyValueLabel,
					value: null,
				}]
			}

			values = values.map(val => {
				const emptyVal = val ?? ''
				const option = this.options.find(item => item.value === emptyVal)
				if (option) {
					return {
						label: option.viewLabel || option.label || this.emptyValueLabel,
						link: this.linkPattern.replace('%value%', option.value),
						value: val,
					}
				}
				return {
					label: val || this.emptyValueLabel,
					value: val || emptyVal,
				}
			})

			return values
		},
		viewValuesTo() {
			let values = Array.isArray(this.modelTo) ? this.modelTo : [this.modelTo]

			if (!values.length) {
				return [{
					label: this.emptyValueLabel,
					value: null,
				}]
			}

			values = values.map(val => {
				const emptyVal = val ?? ''
				const option = this.options.find(item => item.value === emptyVal)
				if (option) {
					return {
						label: option.viewLabel || option.label || this.emptyValueLabel,
						link: this.linkPattern.replace('%value%', option.value),
						value: val,
					}
				}
				return {
					label: val || this.emptyValueLabel,
					value: val || emptyVal,
				}
			})

			return values
		},
		viewValues() {
			return [this.viewValuesFrom[0], this.viewValuesTo[0]]
		},
	},
	mounted() {
		this.fixParkinsonUI()
	},
	methods: {
		onOptionClick(val) {
			this.$emit('on-option-click', val)
		},
		// при масштабировании в браузере zoom < 100% в компоненте el-select
		// стартует бесконечный цикл пересчёта высоты и элемент начинает "дрожать"
		// установка inline css height останавливает бесконечное изменение высоты
		fixParkinsonUI() {
			let throttle = 0
			const config = {
				attributes: false,
				childList: true,
				subtree: true,
			}
			const observer = new MutationObserver(() => {
				if (throttle && throttle < Date.now()) {
					return this.setInlineHeight()
				}
				throttle = Date.now() + 100
				return true
			})

			observer.observe(this.$el, config)
		},
		setInlineHeight() {
			const elTagsContainer = this.$el.querySelector('.el-select__tags')

			if (!elTagsContainer) return

			elTagsContainer.style.height = 'auto'
			setTimeout(() => {
				elTagsContainer.style.height = `${elTagsContainer.offsetHeight}px`
			})
		},
	},
}
</script>


<style lang="scss">
.com-field-range-selects {
	.el-form-item__content {
		line-height: 28px;
	}
}
</style>