<template>
	<div
		:data-ta="`input-popover_${name}`"
		class="com-field-item com-field-input-popover"
	>
		<PartFieldView
			v-if="!isEdit"
			v-model="viewValue"
			:name="name"
			:label="label"
			:label-width="labelWidth"
			:label-color-dot="labelColorDot"
			:link-pattern="linkPattern"
			:show-label="showLabel"
			:is-html="isHtml"
			:on-click="onClick"
			:info-tooltip="infoTooltip"
		/>

		<el-form-item
			v-else
			:ref="name"
			:prop="name"
			:label="label || title"
			:label-width="labelWidth"
		>
			<PartInfoTooltip
				v-if="infoLabelTooltip"
				:info-tooltip="infoLabelTooltip"
				label-tooltip
			/>

			<div class="d-flex align-items-center w-100p">
				<el-input
					v-if="visibleMode === 'input'"
					v-model="model"
					:placeholder="placeholder"
					:data-ta="name"
					:disabled="true"
					:size="size"
				/>

				<el-tag
					v-else
					:data-ta="`tag_${name}`"
					type="info"
					size="default"
					class="border-radius-round color-regular"
				>{{ model || '—' }}</el-tag>

				<span v-if="infoTooltip" class="ml-2">
					<PartInfoTooltip
						v-if="infoTooltip"
						:info-tooltip="infoTooltip"
					/>
				</span>

				<PartDeletePopover
					v-if="deleteFn"
					:name="name"
					:delete-popover-confirm="deletePopoverConfirm"
					@acceptDelete="onAcceptDelete"
				/>

				<PartInputPopover
					v-if="popover"
					:name="name"
					:mask="mask"
					:error="error"
					:warn="warn"
					:size="size"
					:clearable="clearable"
					:popover="popover"
					:rules="rules"
					:max-length="maxLength"
					:field-type="fieldType"
					@acceptEdit="onAcceptEdit"
					@cancelEdit="onCancelEdit"
				/>
			</div>
		</el-form-item>
	</div>
</template>

<script>
import { mask } from 'vue-the-mask'
import defaultProps from './defaultFieldsProps'
import PartInfoTooltip from './parts/PartInfoTooltip'
import PartFieldView from './parts/PartFieldView'
import PartDeletePopover from './parts/PartDeletePopover'
import PartInputPopover from './parts/PartInputPopover'

export default {
	name: 'ComFieldPhonePopover',
	components: {
		PartInfoTooltip,
		PartFieldView,
		PartDeletePopover,
		PartInputPopover,
	},
	directives: {
		mask,
	},
	props: {
		...defaultProps,
		format: {
			type: String,
			default: '',
		},
		mask: {
			type: String,
			default: '',
		},
		fieldType: {
			type: String,
			default: 'text',
		},
		infoTooltip: {
			type: Object,
			default: null,
		},
		infoLabelTooltip: {
			type: Object,
			default: null,
		},
		popover: {
			type: Object,
			required: true,
		},
		acceptFn: {
			type: Function,
			default: null,
		},
		cancelFn: {
			type: Function,
			default: null,
		},
		deleteFn: {
			type: Function,
			default: null,
		},
		deletePopoverConfirm: {
			type: String,
			default: '',
		},
		visibleMode: {
			type: String,
			default: '',
		},
		rules: {
			type: Array,
			default: null,
		},
		warn: {
			type: String,
			default: null,
		},
		error: {
			type: String,
			default: null,
		},
	},
	computed: {
		model: {
			get() { return typeof this.modelValue === 'undefined' ? null : this.modelValue },
			set(value) {
				this.$emit('update:modelValue', value)
			},
		},
		viewValue() {
			return this.modelValue || '—'
		},
	},
	methods: {
		closePopover() {
			document.body.click()
		},
		onCancelEdit() {
			if (this.cancelFn) {
				this.cancelFn()
			}
		},
		async onAcceptEdit(newText, callback = () => {}) {
			let result = true
			if (this.acceptFn) {
				result = await this.acceptFn(newText)
			}
			if (result) {
				this.model = newText
				await this.$nextTick()
				callback()
			}
		},
		async onAcceptDelete() {
			try {
				await this.deleteFn()
				this.newText = ''
			} catch (e) {
				//
			}
		},
	},
}
</script>