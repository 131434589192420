import VuexHttp from '../../vuexHttpService'

const vHttp = new VuexHttp('documents')

export default {
	async aDocuments(_, orderType) {
		let orderTypeParam = ''
		if (orderType) {
			orderTypeParam = '?orderType=' + orderType
		}
		return vHttp.GET(this, {
			url: `print-forms/client${orderTypeParam}`,
			mutation: 'mDocuments',
		})
	},
	async aSetClientRepresentative(_, { clientId, representativeId }) {
		return vHttp.POST(this, {
			url: `clients/${clientId}/representative`,
			data: {
				representativeId,
			},
		})
	},
	async aClientRepresentative(_, { clientId, errorsProcessing }) {
		return vHttp.GET(this, {
			url: `clients/${clientId}/representative`,
			errorsProcessing,
		})
	},
}