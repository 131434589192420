<template>
	<div class="payment-popup">
		<el-button
			:disabled="isPaymentDisabled"
			type="primary"
			size="large"
			class="order-payment__confirm"
			data-ta="order-pay"
			@click="onClick"
		>{{ btnString }}</el-button>

		<el-dialog
			v-model="visible"
			width="424px"
			data-ta="payment-dialog-container"
			:modal="false"
			:show-close="false"
			:lock-scroll="false"
			:before-close="close"
			:close-on-click-modal="true"
			:top="isIframe ? iframeScreenPositionsData : '15vh'"
		>
			<template #header>
				<div class="d-flex justify-content-between">
					<h3>
						<span class="mr-2">{{ $t('Оплата') }}</span>
						<span data-ta="order-total-sum"> {{ totalSumStr }}</span>
					</h3>
					<ItIcon
						name="close"
						data-ta="payment-close-icon"
						class="payment-popup__close"
						@click="close"
					/>
				</div>
			</template>

			<el-divider class="mb-4 mt-0"/>

			<div
				v-if="isReceiptPrintingMode"
				class="payment-popup__notification mb-4"
			>
				<ItIcon
					name="warning-solid"
					width="16px"
					height="16px"
				/>

				<span class="color-text-regular">
					{{ $t('Режим печати чека') }}
				</span>
			</div>

			<el-form
				ref="form"
				:model="certificateValidateForm"
				require-asterisk-position="right"
				label-position="left"
				label-width="25%"
			>
				<PaymentMethod
					v-for="method in paymentMethods"
					:key="method.paymentType"
					v-model="method.sum"
					:method="method"
					:is-valid="isValid"
					:validation-stage="validationStage"
					:invalid-comments-fields="invalidCommentsFields"
					@inputTotalSum="inputTotalSum"
					@commentUpdate="onCommentUpdate"
				/>

				<el-divider class="mb-4 mt-0"/>

				<PaymentCertificate
					v-if="!onlyCash"
					v-model="certificateValidateForm.certificate"
					:certificate-request-error="certificateRequestError"
					@scanCertificate="scanCertificate"
				/>
			</el-form>

			<GiftCertificates
				v-if="!onlyCash"
				:certificates="certificates"
				:total-sum="totalSum"
				:certificates-calcs="certificatesCalcs"
				@removeCertificate="removeCertificate"
			/>

			<el-divider v-if="!onlyCash" class="mt-4 mb-4"/>

			<div
				v-if="hasOddMoney"
				class="grey-block"
			>
				<div class="d-flex align-items-center">
					<ItIcon
						name="wallet-arrow-up"
						class="fill-success mr-4"
					/>

					<span class="bold">{{ $t('Сдача') }}</span>
				</div>
				<div
					class="payment-popup__large-title color-text-primary"
					:class="{
						'font-size-16': oddMoneyStr.length >= 14,
					}"
					data-ta="payment-odd-money"
				>
					{{ oddMoneyStr }}
				</div>
			</div>

			<div
				v-else
				class="grey-block"
			>
				<div class="d-flex align-items-center">
					<ItIcon
						name="wallet-arrow-down"
						class="fill-danger mr-4"
					/>

					<span class="bold">{{ $t('Осталось внести') }}</span>
				</div>
				<div
					data-ta="payment-debt"
					class="payment-popup__large-title color-text-primary"
					:class="{
						'font-size-16': leftToPayStr.length >= 14,
					}"
				>
					{{ leftToPayStr }}
				</div>
			</div>

			<el-button
				:disabled="!canPay"
				:loading="isSavingPayment"
				type="primary"
				size="large"
				data-ta="order-pay-submit"
				class="order-payment__confirm mt-4"
				@click="savePayment"
			>
				{{ $t('Сохранить') }}
			</el-button>
		</el-dialog>
	</div>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
import { formatCurrency } from '~/utils/currencies'
import { setIframeHeight, getIframeContentHeight } from '~/utils/iframes/iframesContentHeight'
import { isIframe } from '~/utils/iframes'
import PaymentMethod from './PaymentPopup/PaymentMethod.vue'
import PaymentCertificate from './PaymentPopup/PaymentCertificate.vue'
import GiftCertificates from './PaymentPopup/GiftCertificates.vue'
import DEFAULT_PAYMENT_METHODS from '../../data/paymentsMethods'

export default {
	name: 'PaymentPopup',
	components: {
		PaymentMethod,
		PaymentCertificate,
		GiftCertificates,
	},
	props: {
		onlyCash: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			isIframe,
			visible: false,
			certificateValidateForm: {
				certificate: '',
			},
			certificates: [],
			certificateRequestError: false,
			paymentMethods: [],
			validationStage: false,
			iframeScreenPositionsData: `10px`,
			isSavingPayment: false,
		}
	},
	computed: {
		...mapState('orders', [
			'orderType',
			'cartUid',
			'orderNotes',
			'orderErrors',
			'infoFieldsSettings',
			'infoFieldsOptions',
		]),
		...mapGetters(['gSettings']),
		...mapGetters('orders', [
			'gCartInfo',
			'gCartItems',
		]),

		isReceiptPrintingMode() {
			return (this.gCartInfo && !this.gCartInfo.mainOnly)
				&& !this.onlyCash
		},

		btnString() {
			return this.$t('Принять оплату') + (this.onlyCash ? ' *' : '')
		},
		getLocal() {
			return this.$i18n.locale
		},
		totalSum() {
			return this.gCartInfo?.cartDiscountPrice || 0
		},
		totalSumStr() {
			return formatCurrency(this.totalSum, this.getLocal)
		},
		oddMoneyStr() {
			return this.oddMoney ? formatCurrency(this.oddMoney, this.getLocal) : '—'
		},
		leftToPay() {
			return this.totalSum - this.currentPaymentsSum - this.currentCertificatesSum
		},
		leftToPayStr() {
			return formatCurrency(this.leftToPay, this.getLocal)
		},
		certificatesCalcs() {
			let currentCertificatesSum = 0

			return this.certificates.map(cert => {
				const oddSum = this.totalSum - currentCertificatesSum
				const soldPrice = Math.sign(oddSum) === -1
					? this.totalSum
					: Math.min(cert.retailPrice, oddSum)

				const retailPrice = cert.retailPrice - soldPrice
				currentCertificatesSum += soldPrice
				const result = {
					...cert,
					retailPrice,
					soldPrice,
					totalPriceFormatted: formatCurrency(retailPrice, this.getLocal),
				}

				result.soldPriceFormatted = formatCurrency(result.soldPrice, this.getLocal)

				return result
			})
		},
		currentCertificatesSum() {
			return this.certificatesCalcs.reduce((acc, item) => item.soldPrice + acc, 0)
		},
		currentPaymentsSum() {
			return this.paymentMethods.reduce((acc, item) => item.sum + acc, 0)
		},
		currentPaymentsMap() {
			// eslint-disable-next-line
			return this.paymentMethods.reduce((acc, item) => (acc[item.paymentType] = item.sum, acc), {})
		},
		currentCashSum() {
			return this.currentPaymentsMap.CASH || 0
		},
		currentCardSum() {
			return this.currentPaymentsMap.CASHLESS || 0
		},
		hasOddMoney() {
			return this.totalSum
				<= this.currentPaymentsSum + this.currentCertificatesSum
		},
		oddMoney() {
			if (this.hasInvalidFields) return 0

			// полная оплата сертификатом
			let sum = this.totalSum - this.currentCertificatesSum
			if (sum <= 0) {
				return this.currentCashSum || 0
			}
			// полная оплата безналом, по договору или страховкой
			sum -= (this.currentPaymentsSum - this.currentCashSum)
			if (sum <= 0) {
				return this.currentCashSum
			}

			const result = (this.currentCashSum - sum) // сдача
				.toFixed(2)

			return Number(result)
		},
		invalidCommentsFields() {
			const invalidFields = []
			this.paymentMethods.forEach(pm => {
				if (['CREDIT_CONTRACT', 'INSURANCE'].includes(pm.paymentType) && pm.sum && !pm.comment) {
					invalidFields.push(pm.paymentType)
				}
			})
			return invalidFields
		},
		diffSums() {
			// Сумма общей сдачи не может быть больше суммы наличной оплаты
			return this.currentCashSum < Math.abs(this.leftToPay).toFixed(2)
		},
		canPay() {
			if (this.hasInvalidFields) {
				return false
			}

			if (this.diffSums) {
				return false
			}

			if (Number(this.oddMoney) < 0) {
				return false
			}

			return !this.overloadSum
		},
		orderHasErrors() {
			if (this.orderErrors?.goodsCart) {
				return Boolean(Object.values(this.orderErrors.goodsCart).find(item => !!item))
			}

			return false
		},
		isPaymentDisabled() {
			return !this.gCartItems?.length || this.orderHasErrors
		},
		isValid() {
			// Если сумма по методу оплаты (кроме суммы наличных) превышает общую сумму,
			// то поле становится невалидным
			const isValid = {
				CASH: true,
				CASHLESS: !this.currentCardSum,
				CREDIT_CONTRACT: !this.currentPaymentsMap.CREDIT_CONTRACT,
				INSURANCE: !this.currentPaymentsMap.INSURANCE,
			}

			return this.overloadSum
				? isValid
				: {
					CASH: true,
					CASHLESS: true,
					CREDIT_CONTRACT: true,
					INSURANCE: true,
				}
		},
		overloadSum() {
			// Если сумма всех способов оплаты превышает общую сумму
			// На сумму, которая больше суммы налички, то поля становятся невалидными 
			return (this.currentPaymentsSum + this.currentCertificatesSum > this.totalSum)
				&& this.diffSums
		},
		hasInvalidFields() {
			const invalidFields = Object.values(this.isValid)
				.includes(false)

			return invalidFields
		},
	},
	watch: {
		visible(isVisible) {
			if (isVisible && !this.paymentMethods.length) {
				this.setPaymentMethods()
			}

			if (!this.isIframe) return

			if (isVisible) {
				const parentScroll = Math.max(window.iframeLegacyScreenPositions?.windowScrollY || 10, 100)
				this.iframeScreenPositionsData = `${parentScroll}px`

				this.$nextTick(() => {
					const height = Math.max(parentScroll + this.$el.querySelector('.el-dialog').offsetHeight, getIframeContentHeight())
					setIframeHeight(height + 110)
					if (!window.preventAutoprint && this.documentHtml) {
						this.printHtmlDocument(this.documentHtml)
					}
				})
			} else {
				setIframeHeight()
			}
		},
	},
	methods: {
		...mapActions('orders', [
			'aCartTerminate',
			'aScanCertificate',
		]),
		...mapMutations('orders', ['mOrderErrors']),
		setPaymentMethods() {
			this.paymentMethods = DEFAULT_PAYMENT_METHODS
				.filter(pm => !this.onlyCash || pm.paymentType === 'CASH')
				.filter(pm => this.gCartInfo.insuranceAvailable || pm.paymentType !== 'INSURANCE')
				.filter(pm => this.gCartInfo.creditAvailable || pm.paymentType !== 'CREDIT_CONTRACT')
				.map(pm => {
					const result = { ...pm }

					if (pm.paymentType === 'CASH') {
						result.label = this.onlyCash
							? `${this.$t('Наличные')} *`
							: result.label
					}

					return result
				})
		},
		close() {
			this.visible = false
		},
		inputTotalSum(method) {
			const currentPayments = this.paymentMethods.filter(m => m.sum)

			// неоплачено или оплачено не полностью
			if (this.leftToPay > 0) {
				method.sum += this.leftToPay
			// платёж полностью введён в другое поле, переносим его в это поле
			} else if (currentPayments.length === 1 && !this.currentCertificatesSum) {
				currentPayments[0].sum = null
				method.sum = this.totalSum
			}

			this.paymentMethods = [...this.paymentMethods]
		},
		onCommentUpdate(method, comment) {
			this.paymentMethods = this.paymentMethods.map(pm => {
				if (method === pm) {
					return {
						...pm,
						comment,
					}
				}
				return pm
			})
		},
		scanCertificate() {
			async function sendRequest() {
				if (this.certificateValidateForm.certificate.length < this.gSettings.giftCertificateRealNumLength) {
					return
				}

				if (this.certificates.find(crt => crt.certificateNumber === this.certificateValidateForm.certificate.trim())) {
					return
				}

				this.throttleTimeoutHandle = null
				this.certificateRequestError = false


				this.$refs.form.validate(async valid => {
					if (valid) {
						const result = await this.aScanCertificate({
							giftCertificateNumber: this.certificateValidateForm.certificate,
						})

						if (!result) {
							this.certificateRequestError = true
							setTimeout(() => { this.certificateRequestError = false }, 3000)
							return
						}

						this.certificates.push({
							...result,
							soldPrice: null,
						})

						this.certificateValidateForm.certificate = ''
					}
				})
			}

			if (this.throttleTimeoutHandle) {
				clearTimeout(this.throttleTimeoutHandle)
			}

			this.throttleTimeoutHandle = setTimeout(() => sendRequest.call(this), 800)
		},
		removeCertificate(idx) {
			this.certificates.splice(idx, 1)
			this.certificates = [...this.certificates]
		},
		async savePayment() {
			if (this.invalidCommentsFields.length) {
				this.validationStage = true
				setTimeout(() => { this.validationStage = false }, 2000)
				return
			}

			this.isSavingPayment = true

			const response = await this.aCartTerminate({
				uid: this.cartUid,
				payments: this.paymentMethods
					.map(item => {
						let { sum } = item
						if (item.paymentType === 'CASH' && this.oddMoney > 0) {
							sum = sum - this.oddMoney !== 0
								? (sum - this.oddMoney).toFixed(2)
								: 0
						}
						return {
							paymentType: item.paymentType,
							sum,
							comment: item.comment,
						}
					})
					.filter(item => item.sum),
				giftCertificatesNumbers: this.certificates.map(cert => cert.id),
				orderNotes: this.orderNotes,
				main: !this.onlyCash,
			})

			this.isSavingPayment = false

			const orderId = response?.data?.orderId || null
			this.$emit('submit-payments', orderId)
			this.visible = false
		},
		onClick() {
			this.visible = this.isValidInfoFields()
				? !this.visible
				: this.visible
		},
		isValidInfoFields() {
			const [infoField0, infoField1] = this.infoFieldsSettings
			const [infoField0Options, infoField1Options] = this.infoFieldsOptions || []
			let isValid = true

			if (infoField0?.[this.orderType] && !this.orderNotes.firstOrderReason) {
				if (infoField0?.required && infoField0Options?.names?.length) {
					this.mOrderErrors({
						from: 'goodsCart',
						error: {
							firstOrderReason: true,
						},
					})
					isValid = false
				}
			}

			if (infoField1?.[this.orderType] && !this.orderNotes.secondOrderReason) {
				if (infoField1?.required && infoField1Options?.names?.length) {
					this.mOrderErrors({
						from: 'goodsCart',
						error: {
							secondOrderReason: true,
						},
					})
					isValid = false
				}
			}

			return isValid
		},
	},
}
</script>

<style lang="scss">

.payment-popup {
	.el-form-item {
		&__label {
			line-height: 16px !important;
			word-break: normal;
		}

		&____content {
			flex-basis: 100%;
		}
	}

	&__close {
		cursor: pointer;
	}

	&__notification {
		display: flex;
		align-items: center;
		gap: 16px;
		border: 1px solid var(--el-color-warning);
		padding: 8px 16px;
		border-radius: 4px;
		font-weight: 400;
	}

	&__error-nested {
		display: flex;
		align-items: center;
		width: 288px;
		margin-top: 5px;
	}

	.el-textarea {
		display: flex;
		justify-content: flex-end;

		textarea {
			height: 28px;
			overflow: hidden;
		}

		.el-textarea__inner {
			min-height: 28px !important;
			padding: 4px 15px;
			width: 288px;
		}
	}

	&__large-title {
		font-size: 24px;
		font-weight: 700;
		line-height: 24px;
		display: flex;
		align-items: center;
		gap: 16px;
	}

	&__small-title {
		font-size: 12px;
		line-height: 16px;
	}

	&__error {
		margin-top: -4px;
		line-height: 16px;
		font-size: 12px;

		&-msg {
			word-break: normal;
		}
	}

	.grey-block {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 12px 16px;
		background: var(--el-border-color-extra-light);
		border: 1px solid #cbcbd5;
		border-radius: 4px;
	}

	.gift-certificate-input {
		.el-input__inner {
			padding-left: 30px;
		}
	}

	.el-input--mini .it-icon {
		width: 16px !important;
		height: 16px !important;
	}

	.el-input__prefix {
		margin-right: 8px;
	}

	.font-size-16 {
		font-size: 16px;
	}

	.close-icon {
		color: var(--el-color-text-regular);
		padding: 0;
		font-size: 1rem;
	}
}
</style>