import updateElMessageBoxIframeStyles from '~/utils/iframes/iframesElMessageBoxStyle'

export default {
	install(app) {
		const alert = app.config.globalProperties.$alert
		app.config.globalProperties.$alert = function (...args) {
			// eslint-disable-next-line no-async-promise-executor
			return new Promise(async (resolve, reject) => {
				updateElMessageBoxIframeStyles()
				try {
					await alert.call(this, ...args)
					resolve()
				} catch (e) {
					reject()
				}
			})
		}
	},
}