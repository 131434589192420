export default class HashTable {
	constructor() {
		this.table = (new Array(255)).fill(null)
	}

	hash(key) {
		let hash = 0
		for (let i = 0; i < key.length; i++) {
			hash += key.charCodeAt(i)
		}
		return hash % this.table.length
	}

	set(key, value) {
		const index = this.hash(key)
		this.table[index] = [key, value]
	}

	get(key) {
		const data = this.table[this.hash(key)]
		if (!data || key !== data[0]) return null
		return data[1]
	}

	remove(key) {
		const index = this.hash(key)

		if (this.table[index] !== null) {
			this.table[index] = null
			return true
		}
		return false
	}
}