import VuexHttp from '../../vuexHttpService'

const vHttp = new VuexHttp('statistics')

export default {
	async aStatistics() {
		return vHttp.GET(this, {
			url: 'statistics',
			mutation: 'mStatistics',
		})
	},
}