import { http } from '~/kit/plugins/http'
import i18n from '~/plugins/i18n'
import VuexHttp from '../../vuexHttpService'
import { ANONIMUS } from './client.data'

const vHttp = new VuexHttp('client')


export default {
	async aCreateClient(_, payload) {
		return vHttp.POST(this, {
			url: `clients`,
			data: payload,
		})
	},
	async aUpdateClient(_, payload) {
		return vHttp.PUT(this, {
			url: `clients/${payload.id}`,
			data: payload,
		})
	},
	async aUpdateClientFields(_, { id, key, jsonKey, value, successMessage }) {
		return vHttp.PUT(this, {
			url: `clients/${id}/${key}`,
			data: {
				[jsonKey || key]: value,
			},
			successMessage: successMessage || i18n.global.t('Сохранено'),
		})
	},
	async aUpdateClientMerge({ dispatch }, { id, data }) {
		const client = await dispatch('aClient', id)
		const snilsNumber = (data.snilsNumber || client.snilsNumber)?.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1-$2-$3 $4')

		return vHttp.PUT(this, {
			url: `clients/${id}`,
			data: {
				...client,
				...data,
				snilsNumber,
			},
		})
	},
	async aClients(_, params) {
		return vHttp.GET(this, {
			url: {
				path: 'clients',
				query: {
					...params,
					size: 1000,
				},
			},
		})
	},
	async aClientInfo({ commit }, id) {
		if (+id === ANONIMUS.id) {
			commit('mClientInfo', ANONIMUS)
			return ANONIMUS
		}
		const url = `clients/${id}/info`
		return vHttp.GET(this, {
			url,
			mutation: 'mClientInfo',
		})
	},
	async aCurrent(_, id) {
		const url = `clients/${id}/info`
		const data = await vHttp.GET(this, {
			url,
			mutation: 'mCurrent',
		})
		return data
	},
	async aClient(_, id) {
		const url = `clients/${id}`
		const data = await vHttp.GET(this, {
			url,
			mutation: 'mClient',
		})
		return data
	},
	async aClientPhones(_, id) {
		const url = `clients/${id}/phones`
		const data = await vHttp.GET(this, {
			url,
			mutation: 'mClientPhones',
		})
		return data
	},
	async aClientPrescription(_, id) {
		const url = `clients/${id}/prescription`
		return vHttp.GET(this, {
			url,
			mutation: 'mSetPrescription',
		})
	},
	async aClientCardEdit({ dispatch }, { clientId, cardId }) {
		return dispatch('aUpdateClientFields', {
			key: 'card',
			jsonKey: 'cardId',
			id: clientId,
			value: cardId,
		})
	},
	async aClientCardCheck(_, id) {
		const url = `clients/cards/${id}/check`
		return vHttp.POST(this, {
			url,
			mutationError: 'mClientCardCheckError',
		})
	},
	async aClientCardClient(_, id) {
		const url = `clients/cards/${id}/client`
		return vHttp.GET(this, {
			url,
		})
	},
	async aClientCardDelete(_, id) {
		const url = `clients/cards/${id}`
		return vHttp.DELETE(this, {
			url,
		})
	},
	async aClientComments(_, params) {
		const path = `clients/${params.id}/comments`
		const query = {
			page: params.page,
			size: params.size,
		}
		return vHttp.GET(this, {
			url: {
				path,
				query,
			},
			mutation: 'mSetComments',
		})
	},
	async aInformationSources() {
		const url = `clients/information-source`
		return vHttp.GET(this, {
			url,
			mutation: 'mInformationSources',
		})
	},
	async aClientOrders(_, params) {
		const path = `clients/${params.id}/orders`
		// delete params.id

		return vHttp.GET(this, {
			url: {
				path,
				query: params,
			},
			mutation: 'mClientOrders',
		})
	},
	async aContactLenses(_, params) {
		const path = `clients/${params.id}/contact-lens`
		delete params.id

		return vHttp.GET(this, {
			url: {
				path,
				query: params,
			},
			mutation: 'mContactLenses',
		})
	},
	async aDownloadClientFile(_, fileId) {
		try {
			const response = await http.optima2.get(
				`orders/files/${fileId}`,
				{ responseType: 'blob' }
			)
			return response
		} catch (error) {
			this.commit('mError', error)
			return Promise.reject(error)
		}
	},
	async aDeleteClientFile(_, fileId) {
		return vHttp.DELETE(this, {
			url: `orders/files/${fileId}`,
		})
	},
	async aDeleteClient(_, params) {
		return vHttp.DELETE(this, {
			url: `clients/${params.id}`,
		})
	},
	async aSaveClientSnils(_, { data, clientId }) {
		data.snilsNumber = data.snilsNumber?.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1-$2-$3 $4') || null

		return vHttp.PUT(this, {
			url: `clients/${clientId}/snils`,
			data,
		})
	},
	async aSaveClientBirthday(_, { data, clientId }) {
		return vHttp.PUT(this, {
			url: `clients/${clientId}/birthday`,
			data,
		})
	},
	async aClientRelations(_, clientId) {
		return vHttp.GET(this, {
			url: `clients/${clientId}/relations`,
			mutation: 'mClientRelations',
		})
	},
	async aClientRelationDelete(_, { relationId, clientId }) {
		return vHttp.DELETE(this, {
			url: `clients/relations/${relationId}`,
			action: 'aClientRelations',
			actionPayload: clientId,
			successMessage: i18n.global.t('Связь удалена'),
		})
	},
	async aClientRelationCreate(_, { clientId, data }) {
		return vHttp.POST(this, {
			url: `clients/${clientId}/relations`,
			data,
			action: 'aClientRelations',
			actionPayload: clientId,
			successMessage: i18n.global.t('Связь создана'),
		})
	},
	async aSaveClientAddress(_, { data, clientId }) {
		return vHttp.PUT(this, {
			url: `clients/${clientId}/address`,
			data,
		})
	},
	async aClientsMerge(_, { data }) {
		return http.optima2({
			url: `clients/merge`,
			method: 'POST',
			data,
		})
	},
}