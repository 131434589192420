export const getDefaultRuleTypeForms = () => ({
	ACCESSORIES: {
		category: null,
		model: null,
	},
	CONTACT_LENSES: {
		manufacturer: null,
		contactLensesName: null,
		wearingPeriod: '',
		radiusOfCurvature: '',
		diameter: '',
		expireOnFrom: '',
		expireOnTo: '',
		dioptresFrom: '',
		dioptresTo: '',
		cylinderFrom: '',
		cylinderTo: '',
		packageQuantity: '',
		groupByExpirationDate: false,
		criticalDateOnly: false,
	},
	EXTRAS: {
		brand: null,
		manufacturer: null,
		glassesMaterial: '',
		targetGroup: '',
		type: '',
		dioptresFrom: '',
		dioptresTo: '',
		models: [],
	},
	GLASSES: {
		brand: null,
		manufacturer: null,
		glassesMaterial: '',
		targetGroup: '',
		frameType: '',
		models: [],
	},
	LENSES: {
		brand: null,
		color: '',
		lensCover: '',
		diameter: '',
		dioptresFrom: '',
		dioptresTo: '',
		cylinderFrom: '',
		cylinderTo: '',
		lensGeometry: '',
		refractionIndex: '',
		lensClass: '',
		manufacturer: null,
		material: '',
		technology: '',
		lensType: '',
		partOfOrder: false,
		inOtherDepartment: false,
		inProduction: null,
	},
	SERVE: {
		servicesId: [],
	},
	SUNGLASSES: {
		brand: null,
		manufacturer: null,
		glassesMaterial: '',
		lensesType: '',
		frameType: '',
		targetGroup: '',
		models: [],
		design: '',
	},
})