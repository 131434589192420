import cloneDeep from 'lodash/cloneDeep'
import dayjs from 'dayjs'
import i18n from '~/plugins/i18n'
import { http } from '~/kit/plugins/http'
import { isIframe } from '~/utils/iframes'
import HashTable from '~/utils/hashTable'
import getUrl from '~/utils/getUrl'
import holdOnLegacySession from './holdOnLegacySession'

const IS_TEST = import.meta.env.VITE_TEST === 'test'
const USE_MOCK_DATA = IS_TEST // || import.meta.env.VITE_MODE === 'development'

const DUPLICATE_REQUESTS_TIMEOUT = 300
const HASHTABLE_METHODS_MAP = {
	get: new HashTable(),
	post: new HashTable(),
	put: new HashTable(),
	delete: new HashTable(),
}

const ERRORS_TEXT_MESSAGES = {
	DEFAULT: i18n.global.t('Обратитесь в техподдержку'),
	401: i18n.global.t('Ошибка авторизации'),
	403: i18n.global.t('Доступ запрещен'),
}

export const ERRORS_PROCESSING_MODES = {
	DEFAULT: 0, // in service
	SILENT: 1,
	USE_MESSAGE: 2,
	THROW_ERROR: 3,
}

let closeListener = () => {}
let getMockData = () => {}
let startListen = () => {}

if (USE_MOCK_DATA) {
	const requestBox = await import('~/../tests/helpers/requestBox')
	const mocks = await import('~/mocks')
	closeListener = requestBox.closeListener
	startListen = requestBox.startListen
	getMockData = mocks.getMockData
}

const hasDuplicatedRequest = (method, url, data = {}) => {
	const hashRequest = HASHTABLE_METHODS_MAP[method].get(url)

	if (!hashRequest) return false

	if (method === 'post' || method === 'put') {
		const hashData = hashRequest.data || {}
		return Object.keys(hashData).join('-') === Object.keys(data).join('-')
		&& JSON.stringify(Object.values(hashData)) === JSON.stringify(Object.values(data))
	}

	if (method === 'get' && hashRequest.ts + DUPLICATE_REQUESTS_TIMEOUT < Date.now()) {
		return true
	}

	return method === 'delete'
}

async function request({
	url,
	method,
	commitName,
	commitErrorName,
	dispatchName,
	payload,
	data,
	useLoader = {},
	options,
	successMessage,
	errorMessage,
	errorsProcessing = ERRORS_PROCESSING_MODES.DEFAULT,
	mock,
	preventDuplicatedRequest = true,
}) {
	const processingMutationName = method === 'get' ? 'mLoading' : 'mSending'
	let result
	let mockData

	// поддержание активности сессии в легаси
	if (isIframe) {
		holdOnLegacySession()
	}

	if (preventDuplicatedRequest && hasDuplicatedRequest(method, url, data)) {
		console.warn('Duplicated request:', method.toUpperCase(), url) // eslint-disable-line
		return false
	}

	if (useLoader) {
		this.commit(processingMutationName, {
			...useLoader,
			processing: true,
		})
	}
	try {
		if (USE_MOCK_DATA || mock) {
			startListen(url)
			mockData = await getMockData(method, url)
		}

		if (mockData) {
			result = mockData
		} else {
			HASHTABLE_METHODS_MAP[method].set(url, {
				ts: Date.now(),
				data,
			})
			if (method === 'get') {
				// TODO сейчас http.optima2 бросает исключение на статусах 400+,
				// в результате невозможно получить и обработать по разному 403 404 и т.п.
				// есть смысл перейти на стандартный axios, но придётся проверить все GET,
				// чтобы возвращать полный результат запроса, а не только данные
				result = (await http.optima2[method](url, options)).data
			} else if (method === 'delete') {
				result = (await http.optima2[method](url, {
					...options,
					data,
				}))
			} else {
				result = (await http.optima2[method](url, data, options))
			}
		}
		if (commitName) {
			this.commit(commitName, cloneDeep(result) || null)
		}
		if (dispatchName) {
			this.dispatch(dispatchName, payload || result || null, { root: true })
		}
		if (successMessage) {
			this.$notify.success({ title: successMessage })
		}
	} catch (error) {
		if (USE_MOCK_DATA) {
			// eslint-disable-next-line no-console
			console.log('method, url', 'vuexHttpService.js:136', method, url)
		}

		let message

		if (import.meta.env.VITE_MODE === 'development') {
			// eslint-disable-next-line no-console
			console.error('vuexHttpService error', error)
		}

		if (options?.signal?.aborted) {
			// eslint-disable-next-line
			console.log(`Request aborted with reason: ${options.signal.reason}`)
			return false
		}

		if (errorsProcessing === ERRORS_PROCESSING_MODES.DEFAULT) {
			const errors = error?.response?.data?.errors

			if (errors && Array.isArray(errors)) {
				message = error?.response?.data?.errors[0].message
			} else if (error && error.response) {
				const date = dayjs().format('DD-MM-YYYY HH:mm')
				const { status } = error.response
				const textError = error.response.data?.message
					|| ERRORS_TEXT_MESSAGES[status]
					|| ERRORS_TEXT_MESSAGES.DEFAULT

				message = `${date}`
				message += '<br>'
				message += `${i18n.global.t('Код ошибки')} ${status}`
				message += '<br>'
				message += textError
			} else {
				const date = dayjs().format('DD-MM-YYYY HH:mm')
				message = `${date}`
				message += '<br>'
				message += ERRORS_TEXT_MESSAGES.DEFAULT
			}
		}

		if (errorsProcessing === ERRORS_PROCESSING_MODES.USE_MESSAGE) {
			message = errorMessage
		}

		if (IS_TEST) {
			// eslint-disable-next-line no-console
			console.log('ERR:', method, url)
		} else if (errorsProcessing !== ERRORS_PROCESSING_MODES.SILENT) {
			this.$notify.error({
				title: i18n.global.t('Ошибка'),
				dangerouslyUseHTMLString: true,
				message,
			})
		}

		if (commitErrorName) {
			this.commit(commitErrorName, true)
		}

		if (import.meta.env.VITE_MODE === 'development' && window.location.hostname === 'localhost') { // develop
			if (mockData && commitName) {
				console.info('Mock data', `${url}:`, mockData) // eslint-disable-line
				this.commit(commitName, mockData)
			}
		}

		if (errorsProcessing === ERRORS_PROCESSING_MODES.THROW_ERROR) {
			result = error
		} else {
			result = false
		}
	} finally {
		if (useLoader) {
			this.commit(processingMutationName, {
				...useLoader,
				processing: false,
			})
		}
		HASHTABLE_METHODS_MAP[method].remove(url)
	}
	closeListener()
	return result
}

class VuexHttp {
	constructor(moduleName) {
		this.moduleName = moduleName
	}

	getCommitName(mutation) {
		let commitName = this.moduleName && mutation ? `${this.moduleName}/` : ''
		commitName += mutation || ''
		return commitName
	}

	getDispatchName(action) {
		let dispatchName = this.moduleName && action ? `${this.moduleName}/` : ''
		dispatchName += action || ''
		return dispatchName
	}

	// eslint-disable-next-line class-methods-use-this
	getStateFromMutationName(mutation) {
		return mutation.substring(1, 2).toLowerCase() + mutation.substring(2)
	}

	getLoaderStateName(context, mutation) {
		if (!mutation) return false

		const stateName = `${this.getStateFromMutationName(mutation)}Loading`
		if (this.moduleName && context.state[this.moduleName][stateName] !== undefined) {
			return {
				moduleName: this.moduleName,
				stateName,
			}
		} if (context.state[stateName] !== undefined) {
			return {
				stateName,
			}
		}
		return false
	}

	getRequestParams(args) {
		const {
			url,
			mutation,
			mutationError,
			action,
			actionPayload,
			useLoader,
			options,
			successMessage,
			errorMessage,
			mock,
			preventDuplicatedRequest,
		} = args

		let { errorsProcessing } = args

		if (errorMessage === false) {
			errorsProcessing = ERRORS_PROCESSING_MODES.SILENT
		}

		const commitName = this.getCommitName(mutation)
		const commitErrorName = this.getCommitName(mutationError)
		const dispatchName = this.getDispatchName(action)

		return {
			commitName,
			commitErrorName,
			dispatchName,
			payload: actionPayload,
			url: getUrl(url),
			useLoader,
			options,
			successMessage,
			errorMessage,
			errorsProcessing,
			mock,
			preventDuplicatedRequest,
		}
	}

	async GET(context, args) {
		const response = await request.call(context, {
			...this.getRequestParams(args),
			method: 'get',
			useLoader: this.getLoaderStateName(context, args.mutation) || args.useLoader,
		})

		if (args.errorsProcessing === ERRORS_PROCESSING_MODES.THROW_ERROR && response instanceof Error) {
			throw response
		}
		return response
	}

	async POST(context, args) {
		const response = await request.call(context, {
			...this.getRequestParams(args),
			data: args.data || null,
			method: 'post',
		})
		if (args.errorsProcessing === ERRORS_PROCESSING_MODES.THROW_ERROR && response instanceof Error) {
			throw response
		}
		return response
	}

	async PUT(context, args) {
		const response = await request.call(context, {
			...this.getRequestParams(args),
			data: args.data || null,
			method: 'put',
		})
		if (args.errorsProcessing === ERRORS_PROCESSING_MODES.THROW_ERROR && response instanceof Error) {
			throw response
		}
		return response
	}

	async DELETE(context, args) {
		const response = await request.call(context, {
			...this.getRequestParams(args),
			data: args.data || null,
			method: 'delete',
		})
		if (args.errorsProcessing === ERRORS_PROCESSING_MODES.THROW_ERROR && response instanceof Error) {
			throw response
		}
		return response
	}
}

export default VuexHttp