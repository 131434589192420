<template>
	<div class="simple-card border-radius-12 border-base p-4">
		<div v-if="header" class="d-flex justify-content-between">
			<h5 class="color-black mb-3">{{ header }}</h5>

			<slot name="headerRight"/>
		</div>

		<slot/>
	</div>
</template>
<script>
export default {
	name: 'Simplecard',
	props: {
		header: {
			type: String,
			default: null,
		},
	},
}
</script>
<style lang="scss">
.simple-card {
	position: relative;
}
</style>