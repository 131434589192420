<template>
	<div class="part-order-services-subrow py-1 px-2">
		<el-popover
			v-if="employeeSetting"
			ref="popoverRef"
			:visible="usersPopoverVisible"
			placement="bottom"
			popper-class="part-order-services-subrow__popover"
			width="628"
			trigger="manual"
			:visible-arrow="true"
		>
			<template #reference>
				<el-input
					:value="employeeFullName"
					:placeholder="$t('Кто оказывает услугу')"
					:class="{ 'part-order-services-subrow__error ': !selectedUser.id }"
					suffix-icon="arrow-down"
					data-ta="part-order-services-employee"
					class="part-order-services-subrow__user w-100p cursor-pointer"
					size="default"
					readonly
					@click="togglePopover(service)"
				/>
			</template>

			<div class="d-flex gap-16 mb-4">
				<el-input
					v-model="searchUserStr"
					:placeholder="`${$t('Искать сотрудника')}`"
					class="mr-4"
					data-ta="search-user"
				>
					<template #suffix><ItIcon

						name="search"
						class="fill-text-placeholder"
					/></template>
				</el-input>
				<el-button
					type="primary"
					:disabled="selectedSellerServeRestricted"
					class="bold"
					@click="onSelectUser(selectedSeller)"
				>
					{{ $t('Текущий сотрудник') }}
				</el-button>
			</div>

			<el-table
				v-loading="isUsersLoading"
				:data="usersList"
				style="width: 100%"
				height="200"
				data-ta="order-table-employees"
				@row-click="onSelectUserRow"
			>
				<el-table-column
					prop="id"
					label=""
					width="40"
				>
					<template #default="scope">
						<el-radio
							v-model="selectedUser.id"
							:label="scope.row.id"
							@click="onSelectUserId(scope.row.id)"
						/>
					</template>
				</el-table-column>
				<el-table-column
					prop="fullName"
					:label="$t('ФИО')"
				/>
				<el-table-column
					prop="userGroupName"
					:label="$t('Должность')"
					width="150"
				/>
				<el-table-column
					prop="departmentName"
					:label="$t('Департамент')"
					width="150"
				/>
			</el-table>
		</el-popover>

		<el-input
			v-if="service.needsComment"
			v-model="comment"
			v-input-suffix-padding
			:placeholder="$t('Введите комментарий')"
			:maxlength="150"
			show-word-limit
			class="w-100p"
			data-ta="nested-info-comment"
			size="default"
			clearable
			@update:modelValue="onSelectComment"
		/>
	</div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { getFioFull } from '~/utils/formatters'
import InputSuffixPadding from '~/helpers/directives/InputSuffixPadding.js'
import { usersColumns } from '../../data/usersColumns'
import { registerPopover, unregisterPopover } from '../../data/clickOutsidePopover'


export default {
	name: 'PartOrderServicesSubrow',

	directives: { InputSuffixPadding },

	props: {
		service: {
			type: Object,
			required: true,
		},
		employeeSetting: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			usersPopoverVisible: false,
			searchUserStr: '',
			usersColumns,
			selectedUser: {
				id: null,
			},
			comment: '',
			valid: null,
			isUsersLoading: false,
		}
	},

	computed: {
		...mapState(['settings']),
		...mapState('users', [
			'selectedSeller',
		]),
		...mapState('orders', [
			'usersByService',
			'orderType',
		]),
		...mapGetters('user', [
			'gFioFull',
		]),

		usersList() {
			const users = this.usersByService[this.service.id] || []
			const { firstNameBeforeFamilyName } = this.settings

			return users
				.map(user => {
					const result = {
						...user,
						fullName: getFioFull(user, firstNameBeforeFamilyName),
					}

					switch (result.userGroupName) {
					case 'Офтальмологи':
						result.userGroupName = 'Офтальмолог'
						break
					case 'Работники цеха':
						result.userGroupName = 'Работник цеха'
						break
					case 'Продавцы':
						result.userGroupName = 'Продавец'
						break
					default:
						break
					}

					return result
				})
				.filter(user => (new RegExp(this.searchUserStr, 'gi')).test(user.fullName))
		},

		employeeFullName() {
			return this.service?.employee?.fullName || ''
		},

		selectedSellerServeRestricted() {
			return !this.usersList.find(user => user.id === this.selectedSeller.id)
		},
	},

	watch: {
		usersPopoverVisible(val) {
			if (val) {
				this.$emit('open-popover')
			} else {
				this.$emit('close-popover')
			}
		},
		selectedUser: {
			deep: true,
			handler() {
				if (!this.service.employee) {
					this.togglePopover()
				}
			},
		},
	},

	async mounted() {
		this.isUsersLoading = true
		this.comment = this.service.comment
		if (this.service.employee) {
			this.selectedUser = this.service.employee
		} else {
			this.togglePopover()
		}
		await this.aUsersByService(this.service.id)

		this.isUsersLoading = false

		registerPopover({
			group: 'services',
			ref: this.$refs.popoverRef,
			closeFn: this.closePopover,
			// triggerRef: this.$refs.btnPopover,
		})
	},

	beforeUnmount() {
		unregisterPopover(this.$refs.popover)
	},

	methods: {
		...mapActions('orders', [
			'aUsersByService',
		]),

		togglePopover() {
			if (this.employeeSetting) {
				setTimeout(() => {
					this.usersPopoverVisible = !this.usersPopoverVisible
				})
			}
		},

		closePopover() {
			if (this.usersPopoverVisible) {
				this.usersPopoverVisible = false
			}
		},

		onSelectUser(user) {
			this.selectedUser = user
			this.$emit('select-employee', this.service, this.comment, user)
			this.usersPopoverVisible = false
		},

		onSelectUserId(id) {
			const user = this.usersList.find(u => u.id === id)
			this.onSelectUser(user)
		},

		onSelectUserRow(row) {
			const user = this.usersList.find(u => u.id === row.id)
			this.onSelectUser(user)
		},

		onSelectComment() {
			this.$emit('select-comment', this.service, this.comment, this.selectedUser)
		},

		validate() {
			if (this.employeeSetting) {
				this.valid = Boolean(this.selectedUser?.id)
			}

			return this.valid
		},
	},
}
</script>

<style lang="scss">

.selectable {
	cursor: pointer;
}

.part-order-services-subrow {
	display: flex;
	gap: 8px;

	&__tag {
		border-radius: 20px;
	}

	&__params {
		padding: 3px 10px;
		display: flex;
		justify-content: space-between;
		width: 100%;
		gap: 8px;
	}

	&__error .el-input__inner {
		border-color: var(--el-color-danger);
	}

	& > * {
		flex-basis: 50%;
	}

	.tags {
		gap: 4px;
	}

	.cursor-pointer {
		cursor: pointer !important;
	}

	.el-input__inner,
	*:not(.el-select) > .el-input [readonly="readonly"].el-input__inner {
		background-color: white !important;
		cursor: pointer !important;
	}

	&__popover {
		.el-radio__label {
			display: none;
		}
	}

	.is-scrolling {
		overflow-y: auto;
	}
}

</style>