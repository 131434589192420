<template>
	<div
		:data-ta="`phone_${name}`"
		class="com-field-item com-field-phone"
	>
		<PartFieldView
			v-if="!isEdit"
			v-model="viewValue"
			:name="name"
			:label="label"
			:label-width="labelWidth"
			:label-color-dot="labelColorDot"
			:link-pattern="linkPattern"
			:show-label="showLabel"
			:is-html="isHtml"
			:on-click="onClick"
			:info-tooltip="infoTooltip"
		/>


		<el-form-item
			v-else
			:ref="name"
			:prop="name"
			:label="label"
			:label-width="labelWidth"
		>

			<PartInfoTooltip
				v-if="infoLabelTooltip"
				:info-tooltip="infoLabelTooltip"
				label-tooltip
			/>

			<div class="d-flex align-items-center w-100p">
				<el-input
					v-model="model"
					:placeholder="placeholder"
					:data-ta="name"
					:clearable="clearable"
					:disabled="disabled || readonly"
					:maxlength="maxLength"
					:show-word-limit="showWordLimit"
					:size="size"
				/>

				<PartInfoTooltip
					v-if="infoTooltip"
					:info-tooltip="infoTooltip"
				/>

			</div>
		</el-form-item>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import phoneMasking from '~/utils/phoneMasking'
import defaultProps from './defaultFieldsProps'
import PartFieldView from './parts/PartFieldView'
import PartInfoTooltip from './parts/PartInfoTooltip'

export default {
	name: 'ComFieldPhone',
	components: {
		PartFieldView,
		PartInfoTooltip,
	},
	props: {
		...defaultProps,
		format: {
			type: String,
			default: '',
		},
		mask: {
			type: String,
			default: '',
		},
	},
	computed: {
		...mapGetters(['gPhoneMask']),
		model: {
			get() { return this.modelValue ? phoneMasking(this.modelValue, this.gPhoneMask) : this.modelValue },
			set(value) {
				this.$emit('update:modelValue', phoneMasking(value, this.gPhoneMask))
			},
		},
		viewValue() {
			return this.modelValue || '—'
		},
	},
	mounted() {
		this.$emit('update:modelValue', this.model)
	},
}
</script>