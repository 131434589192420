<template>
	<el-card
		id="page-bonus-points-by-filters"
		v-loading="isLoading"
	>
		<template #header>
			<div class="d-flex align-items-center">
				<ReturnButton ref="ReturnButton" :force-to="{ name: 'Bonus', query: { mode: 'by-filters' } }"/>

				<div class="font-size-large font-semi-bold ml-4 mr-2">
					<template v-if="isReadView">{{ $t('Просмотр правила бонусной системы по фильтрам') }}</template>
					<template v-else-if="isEditView">{{ $t('Редактирование правила бонусной системы по фильтрам') }}</template>
					<template v-else-if="isCreateView">{{ $t('Новое правило начисления баллов по фильтру') }}</template>
				</div>

				<OptimaHelpTooltip
					v-if="isCreateView"
					text="Как создать новое правило начисления баллов по фильтру"
					href="https://optimahelp.itigris.ru/m/57456/l/1358449-"
				/>
			</div>
		</template>

		<StatusCard
			v-if="isReadView"
			:type="statusCardType"
		/>

		<div v-if="isEditView" class="font-semi-bold mb-6">{{ currentAccrualTypeData.title }}</div>

		<el-radio-group
			v-if="isCreateView"
			v-model="currentAccrualType"
			class="mt-3 mb-8"
		>
			<el-radio-button
				v-for="(accrualType, accrualTypeValue) in accrualTypes"
				:key="accrualTypeValue"
				:label="accrualTypeValue"
				:data-ta="'currentAccrualType_' + accrualTypeValue"
			>{{ accrualType.label }}</el-radio-button>
		</el-radio-group>

		<el-form
			ref="form"
			:model="form"
			:rules="rules"
			label-width="152px"
			label-position="left"
			scroll-to-error
			require-asterisk-position="right"
		>
			<el-form-item prop="filterId">
				<template #label>
					<span>{{ isReadView ? $t('Фильтр') : $t('Выбор фильтра') }}</span>

					<el-tooltip>
						<ItIcon
							name="info-outline"
							width="12px"
							height="12px"
							class="ml-2 fill-info"
						/>

						<template #content>
							<div style="width: 200px">{{ $t('Только избранные фильтры, в которых задан параметр Дисконтная карта: да') }}</div>
						</template>
					</el-tooltip>
				</template>

				<el-tag
					v-if="isReadView"
					type="info"
					size="large"
					class="border-radius-round color-regular"
				>{{ currentFilterName }}</el-tag>

				<el-select
					v-else
					v-model="form.filterId"
					filterable
					:placeholder="$t('Название')"
					data-ta="filterId"
					class="form-item-large"
					popper-class="ta-select-dropdown"
				>
					<el-option
						v-for="filter in availableFilters"
						:key="filter.id"
						:label="filter.name"
						:value="filter.id"
					/>
				</el-select>
			</el-form-item>

			<el-form-item :label="$t('Бонусные баллы')" prop="bonusPoints">
				<el-tag
					v-if="isReadView"
					type="info"
					size="large"
					class="border-radius-round color-regular"
				>{{ form.bonusPoints }}</el-tag>

				<el-input
					v-else
					v-model="form.bonusPoints"
					data-ta="bonusPoints"
					class="form-item-small"
				/>
			</el-form-item>

			<el-form-item :label="$t('Срок действия')" prop="bonusDuration">
				<el-tag
					v-if="isReadView"
					type="info"
					size="large"
					class="border-radius-round color-regular"
				>{{ form.bonusDuration }} {{ $t('дней') }}</el-tag>

				<el-input
					v-else
					v-model="form.bonusDuration"
					data-ta="bonusDuration"
					class="form-item-small"
				/>
			</el-form-item>

			<el-divider/>

			<div
				v-if="!isOneTime"
				class="font-semi-bold mb-5"
			>{{ $t('Расписание начислений') }}</div>

			<template v-if="isOneTime">
				<el-form-item
					:label="$t('Дата начисления')"
					prop="accumulationDate"
				>
					<el-tag
						v-if="isReadView"
						type="info"
						size="large"
						class="border-radius-round color-regular"
					>{{ formatDate(form.accumulationDate) }}</el-tag>

					<el-date-picker
						v-else
						v-model="form.accumulationDate"
						:disabled-date="date => $dayjs(date) < $dayjs()"
						:picker-options="{
							firstDayOfWeek: 1,
						}"
						format="DD.MM.YYYY"
						value-format="YYYY-MM-DD"
						:placeholder="$t('Выберите дату')"
						data-ta="accumulationDate"
						class="form-item-large"
						clearable
					/>
				</el-form-item>
			</template>

			<div
				v-else
				class="border-radius-round mb-6"
				:class="{ 'border-base': !isReadView }"
			>
				<div
					v-if="isReadView"
					class="mb-4"
				>
					<TagItem :label="$t('Месяцы')" :value="periodForm.months.length ? periodForm.months.map(month => monthsLocale[month - 1]) : $t('Все')"/>
				</div>

				<div v-else class="px-4 pt-4 pb-2">
					<div class="d-flex justify-content-between">
						<div class="font-semi-bold mb-3">{{ $t('Месяцы') }}</div>

						<div
							class="clickable color-primary font-size-small"
							@click="periodForm.months = allMonthsValue"
						>{{ $t('Выделить все') }}</div>
					</div>

					<el-checkbox-group
						v-model="periodForm.months"
						class="outline"
						size="default"
						@update:modelValue="sortMonthStraight"
					>
						<el-checkbox-button
							v-for="monthItem in monthItems"
							:key="monthItem.value"
							:label="monthItem.value"
							:data-ta="'monthItem_' + monthItem"
							class="mr-2 mb-2"
						>{{ monthItem.label }}</el-checkbox-button>
					</el-checkbox-group>
				</div>

				<div
					v-if="isReadView"
					class="mb-4"
				>
					<TagItem :label="$t('Даты')" :value="periodTypes[periodForm.daysType].infoTitle"/>
				</div>

				<div v-else class="p-4 pb-10">
					<div class="font-semi-bold mb-4">{{ $t('Даты') }}</div>

					<el-radio-group
						v-model="periodForm.daysType"
						class="outline"
						size="default"
					>
						<el-radio-button
							v-for="dayType in daysTypes"
							:key="dayType.value"
							:label="dayType.value"
							:data-ta="'daysTypes_' + dayType"
							class="mr-3 mb-4"
						>{{ dayType.label }}</el-radio-button>
					</el-radio-group>
				</div>

				<div
					v-if="isReadView"
					class="mb-4"
				>
					<TagItem
						:label="$t('Дни')"
						:value="daysInfo"
					/>
				</div>

				<el-form-item
					v-else
					class="px-4 pt-4 bg-color-white"
					style="margin-top: -40px;"
					:label="periodTypes[periodForm.daysType].label"
				>
					<keep-alive>
						<SelectPicker
							v-if="periodTypes[periodForm.daysType].showPicker"
							:key="periodForm.daysType"
							v-model="periodForm.period[periodForm.daysType]"
							:type="periodTypes[periodForm.daysType].type"
							:placeholder="periodTypes[periodForm.daysType].placeholder"
							select-class="w-100p"
						/>

						<el-checkbox-group
							v-else-if="periodForm.daysType === 'weekday'"
							v-model="periodForm.period[periodForm.daysType]"
							class="outline"
							size="default"
							@update:modelValue="sortWeekdayStraight"
						>
							<el-checkbox-button
								v-for="weekdayItem in weekdayItems"
								:key="weekdayItem.cronValue"
								:label="weekdayItem.cronValue"
								:data-ta="'weekdayItems_' + weekdayItem"
								class="mr-2"
							>{{ weekdayItem.label }}</el-checkbox-button>
						</el-checkbox-group>
					</keep-alive>
				</el-form-item>
			</div>
		</el-form>

		<div v-if="!isLoading" class="text-right">
			<template v-if="isReadView && isActive">
				<el-button
					@click="removeRule(removeRuleParams.method, removeRuleParams.path)"
				>{{ $t('Удалить') }}</el-button>

				<router-link
					class="ml-4"
					:to="{ ...$route, query: {} }"
				>
					<el-button type="primary">{{ $t('Редактировать') }}</el-button>
				</router-link>
			</template>

			<el-button
				v-if="isReadView && !isActive && !isOneTime"
				:loading="isActivating"
				data-ta="restore_btn"
				@click="activateBonus"
			>{{ $t('Восстановить') }}</el-button>

			<el-button
				v-if="isCreateView || isEditView"
				type="primary"
				:loading="isSaving"
				class="ml-4"
				data-ta="save_btn"
				@click="save"
			>{{ $t('Сохранить') }}</el-button>
		</div>
	</el-card>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import ReturnButton from '~/components/old/ReturnButton'
import SelectPicker from '~/components/old/SelectPicker'
import { isRequired, minNumber, maxNumber, isInteger } from '~/utils/validationRules'
import { createCronFormat, parseCronFormat } from '~/utils/cron'
import { formatDate } from '~/utils/formatters'
import { getMonths, getWeekDays } from '~/utils/localeConsts'
import { removeRule } from '~/helpers/bonus/methods'
import { monthItems, weekdayItems } from '~/helpers/bonus/computed'
import mxFormChanged from '~/mixins/common/mxFormChanged'

const getDefaultForm = () => ({
	filterId: null,
	bonusPoints: null,
	bonusDuration: null,
	accumulationDate: '',
	cronExpression: '',
})

const getDefaultPeriodForm = () => ({
	months: [],
	daysType: 'everyday',
	period: {
		day: [],
		date: [],
		weekday: [],
	},
})

export default {
	name: 'BonusPointsByFilters',
	components: {
		ReturnButton,
		SelectPicker,
		StatusCard: defineAsyncComponent(() => import('~/components/bonus/rules/StatusCard')),
		TagItem: defineAsyncComponent(() => import('~/components/bonus/rules/TagItem')),
		OptimaHelpTooltip: defineAsyncComponent(() => import('~/components/old/OptimaHelpTooltip')),
	},
	mixins: [
		mxFormChanged,
	],
	async beforeRouteUpdate(to, from, next) {
		const res = await this.mxConfirmIfFormChanged()
		next(Boolean(res))
	},
	async beforeRouteLeave(to, from, next) {
		const res = await this.mxConfirmIfFormChanged()
		next(Boolean(res))
	},
	props: {
		type: {
			type: String,
			required: false,
			default: null,
		},
		id: {
			type: [Number, String],
			required: false,
			default: null,
		},
	},
	data() {
		return {
			isRequired,
			form: getDefaultForm(),
			periodForm: getDefaultPeriodForm(),
			isLoading: false,
			isSaving: false,
			isActivating: false,
			currentAccrualType: 'one-time',
			accrualTypes: {
				'one-time': {
					title: this.$t('Однократное начисление'),
					label: this.$t('Однократное'),
					apiLink: 'bonus/points',
				},
				regular: {
					title: this.$t('Регулярное начисление'),
					label: this.$t('Регулярное'),
					apiLink: 'bonus/auto/points',
				},
			},
			daysTypes: [
				{ label: this.$t('Ежедневно'), value: 'everyday' },
				{ label: this.$t('Каждый день периода'), value: 'day' },
				{ label: this.$t('Каждое число'), value: 'date' },
				{ label: this.$t('Каждый день недели'), value: 'weekday' },
			],
			periodTypes: {
				everyday: {
					type: 'day',
					placeholder: '',
					label: this.$t('Все дни'),
					showPicker: false,
					infoTitle: this.$t('Ежедневно'),
				},
				day: {
					type: 'daterange',
					placeholder: '',
					label: this.$t('Укажите период'),
					showPicker: true,
					infoTitle: this.$t('Каждый день периода'),
				},
				date: {
					type: 'date',
					placeholder: this.$t('Выберите числа'),
					label: this.$t('Укажите числа'),
					showPicker: true,
					infoTitle: this.$t('Каждое число месяца'),
				},
				weekday: {
					type: 'weekdays',
					placeholder: this.$t('Выберите день недели'),
					label: this.$t('Укажите дни'),
					showPicker: false,
					infoTitle: this.$t('Каждый день недели'),
				},
			},
			availableFilters: [],
		}
	},
	computed: {
		monthItems,
		weekdayItems,
		monthsLocale() {
			return getMonths(this.$store.state.user.locale)
		},
		weekDaysLocale() {
			return getWeekDays({ locale: this.$store.state.user.locale, isSundayFirst: true })
		},
		isOneTime() {
			return this.currentAccrualType === 'one-time'
		},
		isActive() {
			return this.isOneTime ? !this.form.done : !!this.form.active
		},
		isCreateView() {
			return !this.isEditView && !this.isReadView
		},
		isEditView() {
			return this.type && this.id && !this.isReadView
		},
		isReadView() {
			return this.$route.query.isReadView === 'true'
		},
		currentAccrualTypeData() {
			return this.accrualTypes[this.currentAccrualType]
		},
		initialAccrualType() {
			const accrualType = this.type || this.$route.query.accrualType

			if (accrualType && this.accrualTypes[accrualType] !== undefined) return accrualType

			return 'one-time'
		},
		statusCardType() {
			if (this.isLoading) return 'info'

			return this.isActive ? 'success' : 'warning'
		},
		rules() {
			if (this.isReadView) return {}

			const rules = {
				filterId: isRequired,
				bonusPoints: [isRequired, isInteger, minNumber(1), maxNumber(9999)],
				bonusDuration: [isRequired, isInteger, minNumber(1), maxNumber(1000)],
			}

			if (this.isOneTime) rules.accumulationDate = isRequired

			return rules
		},
		currentFilterName() {
			return this.availableFilters.find(filter => filter.id === this.form.filterId)?.name
		},
		daysInfo() {
			const { daysType } = this.periodForm
			const period = this.periodForm.period[daysType]

			if (!period?.length) return this.$t('Все')

			if (daysType === 'weekday') return period.map(item => this.weekDaysLocale[item - 1])

			if (daysType === 'day') return period.join(' - ')

			return period
		},
		removeRuleParams() {
			if (this.currentAccrualType === 'one-time') return { method: 'delete', path: this.accrualTypes[this.currentAccrualType].apiLink }
			return { method: 'put', path: `${this.accrualTypes[this.currentAccrualType].apiLink}/deactivate` }
		},
		allMonthsValue() {
			return this.monthItems.map(item => item.value)
		},
	},
	watch: {
		id: {
			immediate: true,
			handler: 'setBonusData',
		},
		form: {
			deep: true,
			handler() {
				this.isFormChanged = true
			},
		},
	},
	created() {
		this.setAvailableFilters()
		this.formatDate = formatDate
		this.removeRule = removeRule
	},
	methods: {
		async setAvailableFilters() {
			try {
				const { data } = await this.$http.optima.get('bonus/points/filters')

				this.availableFilters = data
			} catch (error) {
				this.$notifyUserAboutError(error)
			}
		},
		async save() {
			const isValid = await this.$isFormValid('form')
			if (!isValid) {
				return
			}

			const form = { ...this.form }

			if (!this.isOneTime) {
				if (this.periodForm.daysType !== 'day' && this.periodForm.daysType !== 'everyday' && this.periodForm.period[this.periodForm.daysType].length === 0) {
					this.$notifyUserAboutError(this.$t('Не выбран период'))
					return
				}

				form.cronExpression = createCronFormat(this.periodForm)
			}

			this.isSaving = true

			form.accumulationDate = `${form.accumulationDate}T00:00:00.000Z`

			try {
				let { id } = this

				if (this.isEditView) {
					await this.$http.optima.put(`${this.currentAccrualTypeData.apiLink}/${this.id}`, form)
				} else {
					const { data } = await this.$http.optima.post(this.isOneTime ? 'bonus/points' : 'bonus/auto/points', form)

					id = data.id
				}

				this.$nextTick(() => {
					this.isFormChanged = false
					this.$router.push({ name: 'Bonus', query: { mode: 'by-filters', accrualType: this.currentAccrualType, bonusId: id } })
				})

				this.$notify({ title: this.$t('Сохранено'), type: 'success' })
			} catch (error) {
				this.$notifyUserAboutError(error)
			}

			this.isSaving = false
		},
		async setBonusData() {
			this.currentAccrualType = this.initialAccrualType

			if (!this.id) return

			this.isLoading = true

			try {
				const { data } = await this.$http.optima.get(`${this.currentAccrualTypeData.apiLink}/${this.id}`)

				this.form.filterId = data.filter.id
				this.form.bonusPoints = data.bonusPoints
				this.form.bonusDuration = data.duration
				this.form.accumulationDate = data.date
				this.form.cronExpression = data.cronExpression

				if (!this.isOneTime) {
					this.periodForm = parseCronFormat(this.form.cronExpression)
					this.form.active = data.active
				} else {
					this.form.done = data.done
				}

				this.$nextTick(() => {
					this.isFormChanged = false
				})
			} catch (error) {
				this.$notifyUserAboutError(error)
			}

			this.isLoading = false
		},
		async activateBonus() {
			this.isActivating = true

			try {
				await this.$http.optima.put(`bonus/auto/points/activate/${this.id}`)
				await this.setBonusData()
			} catch (error) {
				this.$notifyUserAboutError(error)
			} finally {
				this.isActivating = false
			}
		},
		sortMonthStraight(event) {
			this.periodForm.months = event.sort((a, b) => a - b)
		},
		// Воскресенье 1 день недели в cron-формате, но должно быть на последнем месте в отображаемой
		// сортировке, остальные дни по возрастанию
		sortWeekdayStraight(event) {
			this.periodForm.period[this.periodForm.daysType] = event.sort((a, b) => {
				if (a === 1) return 1
				if (b === 1) return -1
				return a - b
			})
		},
	},
}
</script>

<style lang="scss">

#page-bonus-points-by-filters {
	.form-item {
		&-large {
			width: 278px;
		}

		&-small {
			width: 117px;
		}
	}

	.rule-type-block {
		width: 200px;
	}

	.el-form-item {
		margin-bottom: 12px;

		&__label {
			padding-right: 8px;
		}
	}

	.el-radio {
		&__inner {
			border-color: var(--el-border-color-dark);
		}

		&__input.is-checked {
			.el-radio__inner {
				border: none !important;
			}
		}

		&-group {
			background: var(--el-color-white);
			border-radius: 4px;
		}

		&-button {
			&__inner {
				background: var(--el-color-white) !important;
				border-color: var(--el-border-color-dark) !important;
			}

			&.is-active {
				.el-radio-button__inner {
					background: var(--el-color-primary) !important;
				}
			}
		}
	}

	.form-item {
		&-large {
			width: 290px;
		}

		&-small {
			width: 144px;
		}
	}

	.el-checkbox-group.outline {
		.el-checkbox {
			&-button {
				.el-checkbox-button__inner {
					border-radius: var(--el-border-radius-round);
					border: 1px solid var(--el-background-color-base);
					background-color: var(--el-background-color-base);
					box-shadow: none;
					padding-left: 16px;
					padding-right: 16px;
				}

				&.is-checked {
					.el-checkbox-button__inner {
						border-color: var(--el-color-primary);
						color: var(--el-color-primary);
						background-color: var(--el-color-white);
					}
				}
			}
		}
	}

	.el-radio-group.outline {
		.el-radio {
			&-button {
				.el-radio-button__inner {
					border-radius: var(--el-border-radius-round);
					border: 1px solid var(--el-background-color-base) !important;
					background-color: var(--el-color-white) !important;
					box-shadow: none;
				}

				&.is-active {
					.el-radio-button__inner {
						border-color: var(--el-color-primary) !important;
						color: var(--el-color-primary) !important;
					}
				}
			}
		}
	}
}
</style>