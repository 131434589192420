import dayjsOriginal from 'dayjs'
import updateLocale from  'dayjs/plugin/updateLocale'
import 'dayjs/locale/en'
import 'dayjs/locale/ru'

export const dayjs = dayjsOriginal

dayjs.extend(updateLocale)

export default {
	install(app, locale = 'ru') {
		dayjs.locale(locale)
		dayjs.updateLocale('ru', {
			monthsShort: [
				"Янв", "Фев", "Мар", "Апр", "Май", "Июн", "Июл",
				"Авг", "Сен", "Окт", "Ноя", "Дек"
			]
		})
		app.config.globalProperties.$dayjs = dayjs;
	},
}