<template>
	<el-popover
		:visible="visible"
		placement="bottom"
		:title="$t('Найден сертификат с таким номером')"
		popper-class="num-confirm-popover"
		width="350"
		trigger="manual"
	>
		<template #reference>
			<el-input
				ref="certificateNumber"
				v-model="model"
				data-ta="certificate-number"
				:placeholder="field.placeholder"
				:name="field.name"
				:size="field.size"
				:type="field.type"
				:label="field.label"
				:maxlength="certificateLength"
				:show-word-limit="true"
			/>
		</template>

		<div>
			<ItIcon
				width="13px"
				height="13px"
				name="info"
				class="fill-warning mr-3"
			/>
			<span class="num-confirm-popover__content">{{ content }}</span>
		</div>

		<div class="d-flex justify-content-center mt-6">
			<el-button
				type="primary"
				plain
				style="width: 50%"
				@click="cancel"
			>
				{{ $t('Нет') }}
			</el-button>
			<el-button
				type="primary"
				class="ml-3"
				style="width: 50%"
				@click="close"
			>
				{{ $t('Да') }}
			</el-button>
		</div>
	</el-popover>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { formatCurrency } from '~/utils/currencies'

export default {
	props: {
		modelValue: {
			type: String,
			default: '',
		},
		field: {
			type: Object,
			default: null,
		},
		certificateLength: {
			type: Number,
			default: 0,
		},
	},
	data() {
		return {
			visible: false,
			certificate: null,
		}
	},
	computed: {
		...mapGetters(['gSettings']),
		model: {
			get() {
				return this.modelValue
			},
			set(value) {
				this.scanCertificate(value)
				this.$emit('update:modelValue', value)
			},
		},
		content() {
			const sum = this.certificate?.retailPrice
			const currencyCode = this.gSettings?.currency.code
			let content = `${this.$t(`На сертификате осталось`)} ${formatCurrency(sum, this.$i18n.locale, currencyCode)}. `
			content += 'При повторной продаже данный сертификат будет аннулирован. Продолжить?'
			return content
		},
	},
	methods: {
		...mapActions('orders', ['aScanCertificate']),

		async scanCertificate(value) {
			if (value.length === this.certificateLength) {
				try {
					const response = await this.aScanCertificate({
						giftCertificateNumber: value,
					})

					if (response) {
						this.certificate = response
						this.visible = true
					}
				} catch (e) {
					//
				}
			}
		},
		cancel() {
			this.$emit('clearCertificateNum')
			this.close()
		},
		close() {
			this.visible = false
		},
		focus() {
			this.$refs.certificateNumber.focus()
		},
	},
}
</script>

<style lang="scss">
.num-confirm-popover {
	&__content {
		font-size: 13px;
	}
}
</style>