import VuexHttp, { ERRORS_PROCESSING_MODES } from '../../vuexHttpService'

const vHttp = new VuexHttp('salesPlans')

export default {
	async aSalesPlansStatistics(_, { id, sort }) {
		const path = `sales-plans/statistics/${id}`
		return vHttp.GET(this, {
			url: {
				path,
				query: {
					sort,
				},
			},
			mutation: 'mSalesPlanStatistics',
		})
	},
	async aSalesPlansStatisticsGeneral(_, params) {
		const path = `sales-plans/statistics`
		return vHttp.GET(this, {
			url: {
				path,
				query: params,
			},
			mutation: 'mSalesPlanStatistics',
		})
	},
	async aSalesPlansCurrent(_, silent) {
		const path = `sales-plans/current`
		return vHttp.GET(this, {
			url: {
				path,
			},
			errorsProcessing: silent ? ERRORS_PROCESSING_MODES.SILENT : ERRORS_PROCESSING_MODES.DEFAULT,
			mutation: 'mSalesPlanCurrent',
		})
	},
	async aDepartmentSalesPlans(_, { id, deleted = false }) {
		const url = `departments/${id}/sales-plans?deleted=${deleted}`
		return vHttp.GET(this, {
			url,
			mutation: `mDepartmentSalesPlans`,
		})
	},
	async aDeleteSalesPlan(_, id) {
		return vHttp.DELETE(this, {
			url: `sales-plans/${id}`,
		})
	},
	async aCreateSalesPlan(_, data) {
		return vHttp.POST(this, {
			url: `sales-plans`,
			data,
		})
	},
}