import { isIframe } from '~/utils/iframes'
import { getIframeId } from '~/utils/iframes'

const BASE_MARGIN = 160

export const getIframeContentHeight = () => {
	// Раньше observe был на <body> но он не уменьшался при уменьшении кол-ва выводимых элементов
	// в пагинации (страница оставалась по прежнему с большой высотой)
	const resizeableElement = window.ResizeObserver ? document.getElementById('layout-index') : document.getElementById('oldBrowserHeightChangeListener')

	let height = resizeableElement.scrollHeight
	// выбор языка?
	if (isIframe && document.querySelector('.ql-picker-label')) {
		height = resizeableElement.scrollHeight + BASE_MARGIN
	}

	// открытые диалоговые окна
	const overlays = document.querySelectorAll('.el-overlay')
	if (isIframe && overlays?.length) {
		overlays.forEach(el => {
			if (window.getComputedStyle(el).getPropertyValue('display') !== 'none') {
				const dialog = el.querySelector('.el-dialog')

				if (dialog) {
					const dialogHeight = dialog.offsetHeight + BASE_MARGIN
					const dialogMarginTop = parseInt(window.getComputedStyle(dialog).getPropertyValue('margin-top'))
					const dialogMarginBottom = parseInt(window.getComputedStyle(dialog).getPropertyValue('margin-bottom'))

					height = Math.max(height, dialogHeight + dialogMarginTop + dialogMarginBottom)
				}
			}
		})
	}

	return height
}


export const setIframeHeight = height => {
	if (!getIframeId()) {
		setTimeout(() => {
			setIframeHeight(0)
		}, 100)
		return
	}

	const currentHeight = getIframeContentHeight()

	window.onWindowHeightChanged(height || currentHeight)
	// dialogs opens animation
	if (currentHeight !== height) {
		setTimeout(() => {
			setIframeHeight(currentHeight)
		}, 100)
	}
}


export const increaseIframeHeight = height => {
	if (!getIframeId()) {
		setTimeout(() => {
			setIframeHeight(0)
		}, 100)
		return
	}

	setIframeHeight(height + getIframeContentHeight())
}