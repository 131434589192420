import store from '~/plugins/store'

import { routerPush, callBeforeRouteLeaveForLegacyLink } from './routerPush'
import checkCompanyAccessToRoute from './checkCompanyAccessToRoute'

export const openMenuElementCheckup = async menuItem => {
	const { company } = store.state.user

	if (!checkCompanyAccessToRoute(company, menuItem)) {
		return callBeforeRouteLeaveForLegacyLink()
	}

	if (menuItem === 'storeOrdersJournal') {
		routerPush('/orders-journal')
		return true
	}
	if (menuItem === 'doctorOrdersJournal') {
		routerPush('/orders-journal')
		return true
	}
	if (menuItem === 'returnsJournal') {
		routerPush('/returns-journal')
		return true
	}
	if (menuItem === 'receiveGoods') {
		routerPush('/accept-transfer-journal')
		return true
	}
	if (menuItem === 'cancellationsJournal') {
		routerPush('/cancellations-journal')
		return true
	}
	if (menuItem === 'transfersJournal') {
		routerPush('/transfers-journal')
		return true
	}
	if (menuItem === 'extrasJournal') {
		routerPush('/extras-journal')
		return true
	}
	if (menuItem === 'revisionsJournal') {
		routerPush('/revisions-journal')
		return true
	}
	if (menuItem === 'productionActiveGlasses') {
		routerPush('/workshop-journal')
		return true
	}
	if (menuItem === 'reevaluationsJournal') {
		const isDepot = store.getters['user/isDepot']
		const { hasBasePrice } = store.getters.gSettings

		if (isDepot && hasBasePrice) {
			routerPush('/reevaluations/wholesale')
		} else {
			routerPush('/reevaluations')
		}
		return true
	}
	if (menuItem === 'basePriceReevaluationsJournal') {
		routerPush('/reevaluations/wholesale')
		return true
	}
	if (menuItem.startsWith('loyaltyOrder_')) {
		const isDoctor = store.getters['user/isDoctor']
		const isDoctorStore = store.getters['user/isDoctorStore']
		if (isDoctorStore) {
			return false
		}
		if (isDoctor) {
			routerPush(`/clients-journal?orderType=CheckVisionOrder&${menuItem.split('_').join('=')}`)
		} else {
			routerPush(`/clients-journal?orderType=ClOrder&${menuItem.split('_').join('=')}`)
		}
		return true
	}
	if (menuItem === 'suppliesJournal') {
		routerPush('/supplies-journal')
		return true
	}
	if (menuItem === 'discounts') {
		routerPush('/discounts-journal')
		return true
	}
	if (menuItem === 'emailDistribution') {
		routerPush('/mailing/list/email')
		return true
	}
	if (menuItem === 'smsDistribution') {
		routerPush('/mailing/list/sms')
		return true
	}

	if (menuItem === 'giftCertificatesJournal') {
		routerPush('/gift-certificates')
		return true
	}

	if (menuItem === 'goodsCertificates') {
		routerPush('/compilance-register-journal')
		return true
	}

	if (menuItem === 'clientFilter') {
		routerPush('/clients-filters')
		return true
	}

	return callBeforeRouteLeaveForLegacyLink()
}