
import iframe from './iframe'
import router from './router'
import user from './user'
import journals from './journals'
import client from './client'
import departments from './departments'
import workshops from './workshops'
import orders from './orders'
import layout from './layout'
import users from './users'
import legalEntities from './legal-entities'
import applicationSettings from './application-settings'
import salesPlans from './sales-plans'
import barcodesOnSheet from './barcodes-on-sheet'
import directories from './directories'
import good from './good'
import cancellations from './cancellations'
import barcodesSettings from './barcodes-settings'
import news from './news'
// import settings from './settings'
import integrationSettings from './integration-settings'
import transfers from './transfers'
import statistics from './statistics'
import returns from './returns'
import revisions from './revisions'
import supplies from './supplies'
import clientsAgreements from './clients-agreements'
import discounts from './discounts'
import balance from './balance'
import compilanceRegister from './compilance-register'
import mailings from './mailings'
import n3healthIntegration from './n3health-integration'
import clientFilters from './client-filters'
import documents from './documents'

export default {
	iframe,
	router,
	user,
	journals,
	client,
	layout,
	users,
	departments,
	orders,
	workshops,
	legalEntities,
	applicationSettings,
	salesPlans,
	barcodesOnSheet,
	directories,
	good,
	cancellations,
	barcodesSettings,
	statistics,
	news,
	mailings,

	// TODO объединить rootsettings, settings, applications-settings ?
	// settings,
	integrationSettings,

	transfers,
	returns,
	revisions,
	supplies,
	clientsAgreements,
	discounts,
	balance,
	compilanceRegister,
	n3healthIntegration,
	clientFilters,
	documents,
}