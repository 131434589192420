export const DEFAULT_CLIENT_FIELDS = {
	deleted: false,
	familyName: '',
	firstName: '',
	patronymicName: '',
	gender: null,
	birthdayDay: null,
	birthdayMonth: null,
	birthdayYear: null,
	tel1: '',
	tel2: '',
	email: '',
	informationSource: '',
	passportNumber: '',
	city: '',
	address: {},
	profession: '',
	employmentPlace: '',
	driver: false,
	computer: false,
	assistant: {},
	clientCardId: null,
	clReplacementDiscountDate: null,
	phoneAccepted: false,
	smsAccepted: true,
	emailAccepted: true,
	mailAccepted: false,
	contractId: null,
	contractDate: null,
	comment: '',
	feedback: '',
	snilsNumber: null,
	snilsIssueOn: null,
	passportIssueOn: null,
	passportIssuedBy: null,
}


export const ANONIMUS = {
	...DEFAULT_CLIENT_FIELDS,
	id: 1000000002,
	fullName: 'Анонимный',
}