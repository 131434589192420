<template>
	<div
		class="layout-part-radio-buttons-group border-radius-8 align-self-flex-start"
	>
		<el-radio-group
			v-model="tabValue"
			class="d-flex"
			size="small"
			@change="onChange"
		>
			<el-radio-button
				v-for="tab in tabs"
				:key="tab.value"
				:value="tab.value"
				:data-ta="tab.dataTa"
				class="flex-1 radio-button"
				:class="{ 'radio-button-active': tabValue === tab.value }"
			>{{ tab.label }}</el-radio-button>
		</el-radio-group>
	</div>
</template>

<script>
export default {
	name: 'LayoutPartRadioButtonsGroup',
	props: {
		modelValue: {
			type: [String, Boolean],
			default: null,
		},
		default: {
			type: [String, Boolean],
			default: null,
		},
		tabs: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			tabValue: null,
		}
	},
	watch: {
		modelValue: {
			immediatly: true,
			handler(value) {
				this.tabValue = value
			},
		},
	},
	created() {
		this.tabValue = this.modelValue || this.default
	},
	methods: {
		onChange(val) {
			this.$emit('update:modelValue', val)
		},
	},
}
</script>

<style lang="scss">

.layout-part-radio-buttons-group {
	display: inline-block;
	width: 100%;
	max-width: 232px;

	.el-radio-group {
		border-radius: 4px !important;
		background-color: var(--el-border-color-light) !important;
	}

	.radio-button {
		.el-radio-button__inner {
			display: flex;
			justify-content: center;
			align-items: center;
			border: none !important;
			font-weight: var(--el-font-weight-regular) !important;
			background-color: var(--el-border-color-light) !important;
			color: var(--el-color-text-regular);
			font-size: 12px;
			border-radius: 4px !important;
			box-shadow: none !important;
			height: 24px;
		}

		height: 24px;
		margin: 4px;
	}

	.radio-button-active {
		.el-radio-button__inner {
			display: flex;
			justify-content: center;
			background-color: var(--el-color-white) !important;
			font-weight: var(--el-font-weight-semi-bold) !important;
			color: var(--el-color-primary) !important;
		}
	}
}
</style>