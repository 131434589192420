<script setup>
import { reactive, ref, computed, defineAsyncComponent } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import ComCurrencyInput from '~/components/ComCurrencyInput'
import { formatCurrency } from '~/utils/currencies'

const PartOverrunError = defineAsyncComponent(() => import('./PartOverrunError.vue'))

const store = useStore()
const { t } = useI18n()

const props = defineProps({
	paymentSum: {
		type: Number,
		required: true,
	},
	isVisible: {
		type: Boolean,
		required: true,
	},
})

const emit = defineEmits([
	'update:is-visible',
	'save-payments'
])

const paymentForm = reactive({
	cash: null,
	card: null,
})
const clearForm = () => {
	paymentForm.cash = null
	paymentForm.card = null
}
const close = () => {
	clearForm()
	emit('update:is-visible', false)
}

const returnAvailableCash = computed(() => {
	return store.state.returns?.returnAvailableCash
})
const maxCashSum = computed(() => (props.paymentSum >= returnAvailableCash.value ? returnAvailableCash.value : props.paymentSum))
const hasErrorCashLimit = computed(() => +paymentForm.cash > maxCashSum.value)

const totalFilled = computed(() => +paymentForm.cash + +paymentForm.card)
const hasTotalPartOverrunError = computed(() => totalFilled.value > props.paymentSum)

const remains = computed(() => props.paymentSum - totalFilled.value)
const remainsLabel = computed(() => (remains.value >= 0 ? t('Осталось распределить') : t('Превышение суммы')))
const remainsSum = computed(() => (remains.value >= 0 ? remains.value : remains.value * -1))

const isSaving = ref(false)

const onSave = async () => {
	isSaving.value = true
	emit('save-payments', paymentForm)
}

const setAllCash = () => {
	if (paymentForm.cash || !remainsSum.value) {
		paymentForm.cash = Math.min(maxCashSum.value, remainsSum.value)
		paymentForm.card = ''
	} else {
		paymentForm.cash = remainsSum.value ? Math.min(maxCashSum.value, remainsSum.value) : maxCashSum.value
	}
}

const setAllCard = () => {
	if (paymentForm.card || !remainsSum.value) {
		paymentForm.card = props.paymentSum
		paymentForm.cash = ''
	} else {
		paymentForm.card = remainsSum.value
	}
}
</script>

<template>
	<el-dialog
		:model-value="isVisible"
		width="424"
		:show-close="false"
		@closed="close"
	>
		<template #header>
			<div class="d-flex justify-content-between align-items-center">
				<h3 class="color-text-primary mb-0">
					{{ t('Возврат') }} {{ formatCurrency(paymentSum) }}
				</h3>
				<ItIcon
					name="close"
					class="clickable"
					@click="close"
				/>
			</div>
		</template>

		<el-form
			id="payment-popup"
			:model="paymentForm"
		>
			<el-divider class="mt-2 mb-6"/>

			<el-form-item
				v-if="returnAvailableCash > 0"
				:label="t('Наличные')"
				prop="cash"
			>
				<div class="d-flex">
					<ComCurrencyInput
						v-model="paymentForm.cash"
						class="currency-input"
						data-ta="cashInput"
						:class="{ 'is-error': hasErrorCashLimit || (hasTotalPartOverrunError && !!paymentForm.cash) }"
					/>

					<el-button
						type="primary"
						plain
						size="large"
						data-ta="btnAllSumCash"
						@click="setAllCash"
					>{{ t('Вся сумма') }}</el-button>
				</div>
			</el-form-item>

			<PartOverrunError
				v-if="hasErrorCashLimit"
				:max-cash-sum="maxCashSum"
				data-ta="errorCashLimit"
			/>
			<PartOverrunError
				v-else-if="hasTotalPartOverrunError && !!paymentForm.cash"
				data-ta="errorCashSum"
			/>

			<el-form-item :label="t('Карта')" prop="card">
				<div class="d-flex">
					<ComCurrencyInput
						v-model="paymentForm.card"
						class="currency-input"
						data-ta="cardInput"
						:class="{ 'is-error': hasTotalPartOverrunError && !!paymentForm.card }"
					/>
					<el-button
						type="primary"
						plain
						size="large"
						data-ta="btnAllSumCard"
						@click="setAllCard"
					>{{ t('Вся сумма') }}</el-button>
				</div>
			</el-form-item>

			<PartOverrunError
				v-if="hasTotalPartOverrunError && !!paymentForm.card"
				data-ta="errorCardSum"
			/>

			<el-divider/>

			<div class="d-flex justify-content-between align-items-center gap-3 bg-color-border-extra-light border-base border-radius-4 p-3 mb-4">
				<ItIcon name="wallet-arrow-down" class="fill-danger"/>
				<div class="flex-grow font-semi-bold color-text-primary">{{ remainsLabel }}</div>
				<div class="font-size-24 font-bold color-text-primary">{{ formatCurrency(remainsSum) }}</div>
			</div>

			<el-button
				type="primary"
				size="extra-large"
				class="w-100p"
				:disabled="remains !== 0 || hasErrorCashLimit"
				:loading="isSaving"
				@click="onSave"
			>{{ t('Сохранить') }}</el-button>
		</el-form>
	</el-dialog>
</template>

<style lang="scss">
#payment-popup {
	.el-form-item {
		display: flex;
		align-items: center;

		.el-form-item__label {
			flex: 1;
		}

		.el-form-item__content {
			flex: 3;

			.el-button {
				border-radius: 0 4px 4px 0;
			}

			.el-input {
				.el-input__wrapper {
					border-radius: 4px 0 0 4px;
					box-shadow: none;
					border: 1px solid var(--el-button-border-color);
					border-right: none;
				}

				&.is-error {
					.el-input__wrapper {
						border: 1px solid var(--el-color-danger);
						border-right: none;
					}
				}
			}
		}

		.currency-input .el-input__inner {
			font-size: 24px;
			font-weight: bold;
		}
	}
}
</style>