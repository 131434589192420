import { routerPush, callBeforeRouteLeaveForLegacyLink } from './routerPush'

// Обработка ссылок с передачей дополнительных параметров
export const loadLinkWithParamsCheckup = async url => {
	if (url.includes('/cancellationsJournal/openJournalFromCreation')) {
		routerPush(`/cancellations-journal`)
		return true
	}

	return callBeforeRouteLeaveForLegacyLink()
}