<!-- eslint-disable vue/no-v-html -->
<template>
	<el-popover
		placement="top"
		width="219"
		trigger="click"
		popper-class="part-delete-popover"
	>
		<p
			v-if="deletePopoverConfirm"
			class="mb-2 part-delete-popover__title"
		>{{ deletePopoverConfirm }}</p>

		<div style="text-align: right; margin: 0">
			<el-button
				size="small"
				:data-ta="name + '__popover-cancel'"
				@click.stop="onCancelDelete"
			>{{ $t('Отмена') }}</el-button>
			<el-button
				type="primary"
				size="small"
				class="ml-3"
				:data-ta="name + '__popover-accept'"
				@click.stop="onAcceptDelete"
			>{{ $t('Ок') }}</el-button>
		</div>

		<template #reference>
			<el-button
				type="primary"
				plain
				:data-ta="name + '__delete-btn'"
				class="part-delete-popover__action-btn ml-3"
			><ItIcon
				name="trash"
				class="return-button-icon fill-primary"
			/></el-button>
		</template>
	</el-popover>
</template>

<script>

export default {
	name: 'PartDeletePopover',

	props: {
		name: {
			type: String,
			required: true,
		},
		deletePopoverConfirm: {
			type: String,
			default: null,
		},
	},


	methods: {
		closePopover() {
			document.body.click()
		},
		onCancelDelete() {
			this.closePopover()
		},
		async onAcceptDelete() {
			this.closePopover()
			this.$emit('acceptDelete')
		},
	},
}
</script>


<style lang="scss">
.part-delete-popover {

	.popover-warn,
	.part-delete-popover__title {
		word-break: keep-all;
		text-align: left;
	}

	.el-input-group__append {
		padding: 0 3px;
	}

	&__action-btn {
		height: 28px;
		width: 28px;
		padding-left: 8px;
		padding-right: 8px;
	}

	.is-error {
		.el-input__inner {
			border-color: var(--el-color-danger);
		}
	}
}
</style>