export default [
	/* {
		path: '/',
		redirect: '/balance',
	}, */
	/* {
		path: '/sales-plans/:salesPlanId',
		redirect: '/sales-plan/:salesPlanId',
	}, */
	/* {
		path: '/news/list',
		redirect: '/news',
	}, */
]