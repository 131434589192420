import router from '~/plugins/router'
import getUrl from '../getUrl'

export function routerPush(url) {
	const route = router.currentRoute.value

	// костыль для обновления страницы при переоткрытии из легаси
	// router.go(0) не работает
	let [urlPath, urlSearch] = url.split('?') // eslint-disable-line
	if (urlPath === route.path) {
		const routeQuery = route.query

		// Переход из журнала клиентов с выбором клиента в заказ (продажа, ремонт и т.д.) в обычный журнал клиентов
		if (urlPath === '/clients-journal' && !urlSearch.includes('orderType')) {
			delete routeQuery.orderType
		}

		if (urlSearch) {
			urlSearch = urlSearch.split('&')
			for (let i = 0; i < urlSearch.length; i++) {
				const [k, v] = urlSearch[i].split('=')
				routeQuery[decodeURIComponent(k)] = decodeURIComponent(v)
			}
		}

		let newUrl = getUrl({
			path: urlPath,
			query: routeQuery,
		})

		newUrl = encodeURIComponent(newUrl)
		return router.replace(`/?redirect=${newUrl}`)
	}

	if (url !== route.fullPath) {
		return router.push(url)
	}

	return Promise.resolve()
}


export const callBeforeRouteLeaveForLegacyLink = () => router.push('/')
	.then(() => false)
	.catch(err => {
		if (err?.message === 'unconfirmed') {
			// $confirmLeave error
			throw err
		} else {
			return false
		}
	})