import barcodesSettingsState from './barcodesSettingsState'
import barcodesSettingsActions from './barcodesSettingsActions'
import barcodesSettingsMutations from './barcodesSettingsMutations'
import barcodesSettingsGetters from './barcodesSettingsGetters'

export default {
	namespaced: true,
	state: barcodesSettingsState,
	getters: barcodesSettingsGetters,
	mutations: barcodesSettingsMutations,
	actions: barcodesSettingsActions,
}