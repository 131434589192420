<template>
	<div class="mb-3">
		<div
			class="payment-method"
			:class="{ 'payment-method--error': !isValid[method.paymentType] }"
		>
			<ComFieldCurrency
				v-model="sum"
				:is-edit="true"
				:name="method.paymentType"
				:class="{ active: method.sum }"
				:min="0"
				:max="method.max"
				:label="method.label"
				class="w-100p"
				:clearable="true"
				label-width="35%"
				@clear="clearPayment"
				@update:modelValue="inputPayment"
			/>
			<div
				class="payment-method__btn"
				@click="inputTotalSum(method)"
			>
				<span>{{ $t('Вся сумма') }}</span>
			</div>
		</div>

		<div
			v-if="!isValid[method.paymentType]"
			class="payment-popup__error mb-3 d-flex justify-content-flex-end"
		>
			<div class="payment-popup__error-nested">
				<ItIcon
					name="info-outline"
					width="16"
					height="16"
					class="fill-danger mr-3"
				/>
				<div class="payment-popup__error-msg">{{ method.errorMessage }}</div>
			</div>
		</div>


		<div
			v-if="['CREDIT_CONTRACT', 'INSURANCE'].includes(method.paymentType) && method.sum"
			:class="{ 'payment-method--error': validationStage && invalidCommentsFields.includes(method.paymentType) }"
			class="payment-popup__additionalInfo mt-1"
		>
			<el-input
				v-model="comment"
				:autosize="{ minRows: 1, maxRows: 5 }"
				:placeholder="$t('Дополнительная информация')"
				type="textarea"
				size="default"
				@update:modelValue="onCommentUpdate"
			/>
			<div
				v-if="validationStage && invalidCommentsFields.includes(method.paymentType)"
				class="payment-popup__error d-flex align-items-center mb-3"
			>
				<ItIcon
					name="info-outline"
					width="16"
					height="16"
					class="fill-danger mr-3"
				/>
				<div>{{ $t('Необходимо заполнить дополнительную информацию') }}</div>
			</div>
		</div>
	</div>
</template>

<script>
import ComFieldCurrency from '~/components/ComFields/ComFieldCurrency'

export default {
	name: 'PaymentMethod',

	components: {
		ComFieldCurrency,
	},

	props: {
		method: {
			type: Object,
			default: null,
		},
		isValid: {
			type: Object,
			default: null,
		},
		validationStage: {
			type: Boolean,
			default: false,
		},
		invalidCommentsFields: {
			type: Array,
			default: () => [],
		},
		modelValue: {
			type: Number,
			default: null,
		},
	},

	data() {
		return {
			comment: null,
			sum: null,
		}
	},

	watch: {
		modelValue: {
			handler(val) {
				this.sum = val
			},
			immediate: true,
		},
	},

	mounted() {
		this.comment = this.method.comment
	},

	methods: {
		clearPayment() {
			this.$emit('update:modelValue', null)
		},
		inputPayment(value) {
			this.$emit('update:modelValue', Number(value))
		},
		inputTotalSum() {
			this.$emit('inputTotalSum', this.method)
		},
		onCommentUpdate() {
			this.$emit('commentUpdate', this.method, this.comment)
		},
	},
}
</script>

<style lang="scss">

.payment-method {
	display: flex;
	margin-bottom: 0;

	&--error input,
	&--error textarea {
		border-color: var(--el-color-danger);
	}

	.el-form-item {
		margin-bottom: 0;

		&__label {
			height: 40px;
		}
	}

	.el-input__suffix {
		height: 40px !important;
		margin-top: 1px;
	}

	.com-field-currency {
		.el-input__wrapper {
			box-sizing: border-box;
			height: 40px;
			padding: 12px;
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
			border-right: none;
			font-size: 22px;
			font-weight: 700;
			line-height: 24px;
		}

		input {
			-moz-appearance: textfield;
		}

		input::-webkit-outer-spin-button,
		input::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}

		&.active {
			.el-input__inner {
				color: #2e384d;
			}
		}
	}

	&__btn {
		box-sizing: border-box;
		background: var(--el-color-primary-light-9);
		border: 1px solid var(--el-color-primary);
		font-size: 12px;
		font-weight: 600;
		line-height: 24px;
		letter-spacing: 0;
		color: #1989fa;
		height: 40px;
		border-top-right-radius: 4px;
		border-bottom-right-radius: 4px;
		flex-basis: 103px;
		display: flex;
		justify-content: center;
		align-items: center;

		&:active,
		&:focus {
			background: var(--el-color-primary-light-9);
			color: #1989fa;
		}

		&:hover {
			color: #fff;
			background: #1989fa;
			cursor: pointer;
		}
	}
}
</style>