<template>
	<div id="order-services">
		<LayoutPartTitle
			:title="`${$t('Проверка зрения')} № ${orderId} — ${$t('Редактирование услуг')}`"
			class="mb-10 pt-2"
			has-return-button
			@on-return="goBack"
		/>

		<div v-loading="isInitLoading">
			<div class="border-base border-radius-8 p-4 mb-8">
				<el-input
					v-model="servicePattern"
					class="mb-4"
					:placeholder="$t('Искать услугу')"
					suffix-icon="el-icon-search"
					clearable
					data-ta="order-services-search"
				/>
				<el-table
					:data="filteredServices"
					stripe
					row-class-name="clickable"
					style="max-height: 225px"
					class="services-list overflow-auto"
					data-ta="part-order-table-services"
					@row-click="addService"
				>
					<el-table-column
						v-for="col in allServicesTableColumns"
						:key="col.prop"
						:prop="col.prop"
						:label="col.label"
						:width="col.width"
						:align="col.align || 'left'"
						:formatter="col.formatter"
					/>
				</el-table>
			</div>

			<PartServicesCart @set-is-changing-cnt="isChangingCnt = $event"/>

			<el-row :gutter="$gutter" class="mb-8 w-100p">
				<el-col
					:span="16"
				>
					<el-form
						:model="formData"
						data-ta="order-additional-info-form"
						label-position="top"
					>
						<div v-if="hasDiscounts" class="d-flex gap-4">
							<el-form-item :label="$t('Скидка')" class="flex-1">
								<el-select
									v-model="formData.discount"
									clearable
									:placeholder="$t('Выберите скидку')"
									class="w-100p"
									data-ta="discount"
									@change="setDiscount"
								>
									<el-option
										v-for="discount in servicesDiscounts"
										:key="discount.id"
										:label="discount.name"
										:value="discount.id"
									/>
								</el-select>
							</el-form-item>

							<el-form-item :label="$t('Дополнительная скидка')" class="flex-1">
								<el-input
									v-model="formData.extraDiscount"
									:placeholder="$t('Укажите дополнительную скидку')"
									clearable
									data-ta="additionalDiscount"
									@input="setExtraDiscount"
								/>
							</el-form-item>
						</div>

						<el-form-item :label="$t('Комментарий')">
							<el-input
								v-model="formData.comment"
								type="textarea"
								:rows="2"
								:placeholder="$t('Введите текст')"
								maxlength="250"
								show-word-limit
								data-ta="orderComment"
								@input="isChanged = true"
							/>
						</el-form-item>
					</el-form>
				</el-col>
				<el-col
					:span="8"
				>
					<div
						class="border-base border-radius-8 font-size-12 p-4"
						style="box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.08);"
					>
						<div class="d-flex justify-content-between mb-3">
							<div>{{ $t('Сумма заказа') }}</div>
							<div class="font-semi-bold">{{ CostFormat(cartInfo.cartPrice) }}</div>
						</div>
						<div v-if="hasDiscounts" class="d-flex justify-content-between">
							<div>{{ $t('Скидка') }}</div>
							<div class="font-semi-bold">
								<div v-if="cartInfo.cartDiscount" class="color-danger">
									-{{ CostFormat(cartInfo.cartDiscount) }}
								</div>
								<div v-else>0</div>
							</div>
						</div>

						<el-divider class="my-4"/>

						<div class="d-flex justify-content-between align-items-center">
							<div class="font-semi-bold">{{ $t('Итого') }}</div>
							<div class="font-bold font-size-24">{{ CostFormat(cartInfo.cartDiscountPrice) }}</div>
						</div>
					</div>
				</el-col>
			</el-row>

			<div class="d-flex justify-content-flex-end gap-4">
				<el-button
					type="danger"
					plain
					data-ta="cancel"
					@click="goBack"
				>{{ $t('Отменить редактирование') }}</el-button>
				<el-button
					type="primary"
					:loading="isSaving || isChangingCnt"
					data-ta="save"
					@click="save"
				>{{ $t('Сохранить') }}</el-button>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import debounce from 'lodash/debounce'
import { formatCurrencyCell } from '~/utils/formatters'
import CostFormat from '~/kit/filters/CostFormat'
import confirmBeforeLeave from '~/plugins/router/hooks/confirmBeforeLeave'
import LayoutPartTitle from '~/layout/parts/LayoutPartTitle'
import PartServicesCart from './parts/PartServicesCart.vue'

export default {
	name: 'OrderServices',
	components: {
		PartServicesCart,
		LayoutPartTitle,
	},
	async beforeRouteLeave(to, from, next) {
		if (this.$store.state.isIframeHid) return next(true)
		if (this.confirmLeaveParams.skipConfirm) return next(true)
		const res = await confirmBeforeLeave.call(this, this.confirmLeaveParams)
		return next(Boolean(res))
	},
	data() {
		return {
			servicePattern: '',
			isInitLoading: false,
			isSaving: false,
			isChangingCnt: false,
			isChanged: false,
			confirmLeaveParams: {
				title: this.$t('Данные будут потеряны'),
				message: this.$t('Отменить редактирование?'),
				confirmButtonText: this.$t('Да, отменить'),
				cancelButtonText: this.$t('Нет'),
			},
			formData: {
				discount: null,
				extraDiscount: null,
				comment: null,
			},
			allServicesTableColumns: [
				{
					label: this.$t('Описание'),
					prop: 'name',
					width: '784',
				},
				{
					label: this.$t('Цена'),
					prop: 'price',
					width: '130',
					formatter: formatCurrencyCell,
					align: 'right',
				},
			],
		}
	},
	computed: {
		...mapState('orders', ['orderServices', 'servicesCartUuid', 'servicesDiscounts']),
		...mapGetters('orders', ['gPreparedCartServices']),
		orderId() {
			return this.$route.params.id
		},
		filteredServices() {
			return this.orderServices
				.filter(service => service.name
					.toLowerCase()
					.includes(this.servicePattern.toLowerCase().trim()))
		},
		cartInfo() {
			return this.gPreparedCartServices.info
		},
		hasDiscounts() {
			return this.cartInfo.hasDiscounts
		},
	},
	watch: {
		'gPreparedCartServices.info': {
			handler(info) {
				this.formData.discount = info.selectedDiscount?.id || null
				this.formData.extraDiscount = info.extraDiscount?.fixedDiscount || null
				this.isChanged = true
			},
		},
		isChanged(isChanged) {
			this.confirmLeaveParams.skipConfirm = !isChanged
		},
	},
	created() {
		this.init()
	},
	methods: {
		CostFormat,
		...mapActions('orders', [
			'aOrderServices',
			'aServicesCartUuid',
			'aAddServiceInCart',
			'aOrderById',
			'aServicesDiscounts',
			'aAddExtraDiscount',
			'aAddDiscount',
			'aCart',
			'aDeleteGeneralDiscount',
			'aSaveServices',
		]),
		init() {
			this.aOrderServices(this.orderId)
			this.aServicesDiscounts()
			this.initCart()
		},
		async initCart() {
			this.isInitLoading = true

			const cartUuid = (await this.aServicesCartUuid(this.orderId)).data
			await this.aCart(cartUuid)
			this.formData.comment = this.gPreparedCartServices.info.comment
			this.isChanged = false

			this.isInitLoading = false
		},
		addService(service) {
			if (!this.servicesCartUuid) return

			this.aAddServiceInCart({ cartUuid: this.servicesCartUuid, serveTypeId: service.id })
		},
		async goBack() {
			const order = await this.aOrderById(this.orderId)

			if (order) {
				window.loadLinkSimpleLegacy(`order/show/${this.orderId}?returnParam=order&clientId=${order.clientId}`)
			}
		},
		setExtraDiscount(discount) {
			if (discount.endsWith('.') && !discount.startsWith('.')) return

			this.formData.extraDiscount = this.getCorrectExtraDiscount(discount)
			this.setExtraDiscountDebounce()
		},
		setExtraDiscountDebounce: debounce(function () {
			const uid = this.servicesCartUuid

			if (this.formData.extraDiscount) {
				this.aAddExtraDiscount({ uid, data: { sum: this.formData.extraDiscount || null } })
			} else {
				this.aDeleteGeneralDiscount({ uid })
			}
		}, 700),
		getCorrectExtraDiscount(discount) {
			if (!discount || Number.isInteger(+discount)) return +discount || ''
			if (parseFloat(discount) !== discount) return parseFloat(discount) || ''

			const discountParts = discount.split('.')
			if (discountParts[1].length <= 2) return discount

			return `${discountParts[0]}.${discountParts[1].substring(0, 2)}`
		},
		setDiscount(discountId) {
			const uid = this.servicesCartUuid

			if (discountId) {
				this.aAddDiscount({ uid, discountId })
			} else {
				this.aDeleteGeneralDiscount({ uid, discountsType: 'discount' })
			}
		},
		async save() {
			this.isSaving = true

			const resp = await this.aSaveServices({
				cartUuid: this.servicesCartUuid,
				comment: this.formData.comment,
			})

			this.isSaving = false

			if (resp.status === 200) {
				this.isChanged = false
				this.goBack()
			}
		},
	},
}
</script>

<style lang="scss">
	#order-services {
		.services-list::before {
			height: 0;
		}

		.input-cnt {
			.el-input__inner {
				text-align: center;
			}
		}

		.el-table .el-table__body .cell {
			word-break: keep-all !important;
		}
	}
</style>