<template>
	<div class="com-field-item com-field-buttons-group">
		<el-form-item
			:ref="name"
			:label="label || title"
			:prop="name"
			:label-width="labelWidth"
			class="line-height-base"
		>
			<el-radio-group
				v-model="model"
				:disabled="disabled || readonly || !isEdit"
				:data-ta="name"
				:size="size"
				class="w-100p"
			>
				<el-radio-button
					v-for="{ value: value2, label: label2, icon: icon2 } in options"
					:key="value2"
					:label="value2"
					:data-ta-radio="value2"
					:style="styles"
					@click="onRadioClick(value2)"
				>
					<div>
						<ItIcon
							v-if="icon2"
							:name="icon2"
							width="14"
							height="14"
						/>
						<span v-if="label2">{{ label2 }}</span>
					</div>
				</el-radio-button>
			</el-radio-group>
		</el-form-item>
	</div>
</template>

<script>
import defaultProps from './defaultFieldsProps'

export default {
	name: 'ComFieldButtonsGroup',
	components: {
	},
	props: {
		...defaultProps,
		options: {
			type: Array,
			default: () => ([]),
		},
	},
	computed: {
		model: {
			get() { return typeof this.modelValue === 'undefined' ? null : this.modelValue },
			set(value) {
				this.$emit('change', value)
				this.$emit('update:modelValue', value)
			},
		},

		styles() {
			const buttonsCount = Object.keys(this.options).length

			return {
				width: `${100 / buttonsCount}%`,
			}
		},
	},
	methods: {
		onRadioClick(val) {
			this.$emit('on-radio-click', val)
		},
	},
}
</script>

<style lang="scss">

.com-field-buttons-group {
	.el-radio-button__inner{
		width: 100%;
		justify-content: center;
		display: flex !important;
		// height: var(--el-component-size);
		align-items: center;
	}

	.el-radio__input {
		display: inline-flex;
		align-items: center;
	}

	.el-radio__label {
		display: inline-flex;
		align-items: center;
	}

	.el-radio-button:not(.is-active) {
		.el-radio-button__inner {
			color: var(--el-color-text-regular) !important;
		}

		.it-icon path {
			fill: var(--el-color-text-regular) !important;
		}
	}
}
</style>