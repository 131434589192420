<template>
	<div
		id="page-bonus-rule"
		v-loading="isLoading"
		class="d-flex"
	>
		<el-card class="flex-grow h-100p">
			<template #header>
				<div class="d-flex align-items-center">
					<ReturnButton ref="ReturnButton" :force-to="{ name: 'Bonus' }"/>

					<div class="font-size-large font-semi-bold ml-4 mr-2">
						<template v-if="isReadView">{{ $t('Просмотр правила бонусной системы') }}</template>
						<template v-else-if="isEditView">{{ $t('Редактирование правила бонусной системы') }}</template>
						<template v-else>{{ $t('Новое правило бонусной системы') }}</template>
					</div>

					<OptimaHelpTooltip
						v-if="isCreateView"
						text="Как создать новое правило бонусной системы"
						href="https://optimahelp.itigris.ru/m/57456/l/1358447-"
					/>
				</div>
			</template>

			<template v-if="isReadView">
				<StatusCard
					v-bind="statusCard"
				/>

				<div class="d-flex">
					<div class="flex-1 mb-4">
						<TagItem :label="$t('Тип правила')" :value="currentMode.label"/>
					</div>

					<div class="flex-1 mb-4">
						<TagItem :label="$t('Срок действия')" :value="`${formatDate(period[0])} - ${formatDate(period[1])}`"/>
					</div>
				</div>

				<div class="d-flex">
					<div class="flex-1 mb-4">
						<TagItem :label="$t('Название')" :value="form.name"/>
					</div>

					<div class="flex-1 mb-4">
						<TagItem :label="modeLabel" :value="pointsTag"/>
					</div>
				</div>

				<el-divider class="mt-2"/>

				<template v-if="departmentTags">
					<TagItem :label="$t('Департаменты')" :value="departmentTags"/>

					<el-divider/>
				</template>

				<template v-if="isReadView">
					<div class="d-flex mb-2">
						<div class="flex-1 mb-4">
							<TagItem
								:label="$t('Тип')"
								:value="ruleTypes[currentRuleType].label"
							/>
						</div>

						<div v-if="priceRange" class="flex-1 mb-4">
							<TagItem
								:label="$t('Стоимость')"
								:value="priceRange"
							/>
						</div>
					</div>

					<component
						:is="currentRuleTypeComponent"
						v-if="!isLoading && currentRuleTypeComponent"
						:form="ruleTypeForms[currentRuleType]"
						:is-read-view="isReadView"
					/>
				</template>
			</template>

			<template v-else>
				<div v-if="isEditView" class="font-size-medium mt-3 mb-6">{{ currentMode.label }}</div>

				<el-radio-group
					v-else
					v-model="form.mode"
					class="mt-1 mb-6"
					data-ta="modeTypes"
				>
					<el-radio-button
						v-for="(modeData, modeValue) in modeTypes"
						:key="modeValue"
						:label="modeValue"
						:data-ta="'modeTypes_' + modeValue"
					>{{ modeData.label }}</el-radio-button>
				</el-radio-group>

				<el-form
					ref="form"
					:model="form"
					:rules="rules"
					label-width="140px"
					label-position="left"
					size="default"
					scroll-to-error
					require-asterisk-position="right"
				>
					<div class="color-text-primary font-semi-bold mb-5">{{ $t('Общая информация') }}</div>

					<el-form-item :label="$t('Название')" prop="name">
						<el-input
							v-model="form.name"
							:placeholder="$t('Введите название')"
							:maxlength="100"
							:readonly="!canEdit"
							data-ta="name"
							class="form-item-large"
							show-word-limit
						/>
					</el-form-item>

					<el-form-item
						:label="$t('Период действия')"
						data-ta="formItem_finishDate"
						prop="finishDate"
					>
						<el-date-picker
							v-if="!isEditView"
							v-model="period"
							type="daterange"
							:start-placeholder="$t('Начало')"
							:end-placeholder="$t('Окончание')"
							:disabled-date="disabledLastDays"
							:picker-options="{
								firstDayOfWeek: 1,
							}"
							:fallback-placements="['bottom']"
							format="DD.MM.YYYY"
							value-format="YYYY-MM-DD"
							clearable
							class="form-item-large"
						>
							<template #range-separator>
								<ItIcon
									name="arrow-right"
									width="16"
									height="16"
									class="fill-text-placeholder"
								/>
							</template>
						</el-date-picker>

						<template v-else>
							<el-date-picker
								v-model="form.startDate"
								readonly
								:disabled-date="disabledLastDays"
								:picker-options="{
									firstDayOfWeek: 1,
								}"
								:fallback-placements="['bottom']"
								format="DD.MM.YYYY"
								value-format="YYYY-MM-DD"
								class="form-item-small"
								data-ta="startDate"
							/>
							<el-date-picker
								v-model="form.finishDate"
								:readonly="!canEdit"
								:disabled-date="date => $dayjs(form.startDate) > $dayjs(date)"
								:picker-options="{
									firstDayOfWeek: 1,
								}"
								format="DD.MM.YYYY"
								value-format="YYYY-MM-DD"
								class="form-item-small ml-4"
								data-ta="finishDate"
							/>
						</template>
					</el-form-item>

					<el-form-item :label="$t('Департаменты')" prop="departmentIds">
						<ItSelect
							v-model="form.departmentIds"
							:placeholder="$t('Все')"
							:disabled="!canEdit"
							:readonly="!canEdit"
							:items="departments"
							data-ta="departmentIds"
							multiple
							filterable
							sort
							class="form-item-large"
						>
							<el-option
								v-for="department in departments"
								:key="department.id"
								:value="department.id"
								:label="department.name"
							/>
						</ItSelect>
					</el-form-item>

					<template v-if="!isEditView">
						<div class="color-text-primary font-semi-bold mt-6 mb-5">{{ modeLabel }}</div>

						<div class="d-flex">
							<el-form-item
								:label="$t('Общий')"
								prop="points"
								class="flex-grow"
							>
								<el-input
									v-model="form.points"
									class="form-item-small"
									data-ta="points"
									type="number"
								>
									<template #suffix>%</template>
								</el-input>
							</el-form-item>

							<el-form-item
								v-if="isMobileAccess"
								:label="$t('Моб. приложение')"
								prop="mobilePoints"
								class="flex-grow"
							>
								<el-input
									v-model="form.mobilePoints"
									data-ta="mobilePoints"
									class="form-item-small"
									type="number"
								>
									<template #suffix>%</template>
								</el-input>
							</el-form-item>
						</div>

						<el-divider class="mt-3"/>

						<el-form-item
							:label="$t('Применить к')"
							:rules="isRequired"
							size="large"
							class="mb-6"
						>
							<el-radio-group v-model="currentGeneralRuleType" data-ta="currentGeneralRuleType">
								<el-radio
									v-for="(generalRuleType, code) in availableGeneralRuleTypes"
									:key="code"
									:label="code"
									:data-ta="'availableGeneralRuleTypes_' + code"
									border
									class="mr-4"
								>{{ generalRuleType.label }}</el-radio>
							</el-radio-group>
						</el-form-item>

						<div v-if="currentGeneralRuleType === 'selected'" class="d-flex flex-wrap mb-3">
							<div
								v-for="(ruleTypeItem, code) in availableSubRuleTypes"
								:key="code"
								:class="{
									'border-active': currentRuleType === code,
								}"
								class="clickable border-base d-flex align-items-center line-height-0 border-radius-round px-5 py-2 mr-3 mb-3 border-box"
								@click="changeRuleType(code)"
							>
								<ItIcon :name="ruleTypeItem.icon" class="mr-3"/>
								<span>{{ ruleTypeItem.label }}</span>
							</div>
						</div>

						<div class="d-flex">
							<el-form-item
								:label="$t('Стоимость от')"
								prop="priceFrom"
								class="flex-grow"
							>
								<el-input
									v-model="form.priceFrom"
									class="form-item-small"
									data-ta="priceFrom"
									type="number"
								>
									<template #suffix>{{ currency.code }}</template>
								</el-input>
							</el-form-item>

							<el-form-item
								:label="$t('Стоимость до')"
								prop="priceTo"
								class="flex-grow"
							>
								<el-input
									v-model="form.priceTo"
									class="form-item-small"
									data-ta="priceTo"
									type="number"
								>
									<template #suffix>{{ currency.code }}</template>
								</el-input>
							</el-form-item>
						</div>

						<el-divider class="mt-4"/>

						<keep-alive>
							<component
								:is="currentRuleTypeComponent"
								v-if="!isLoading && currentRuleTypeComponent"
								:key="currentRuleTypeComponent"
								ref="currentRule"
								:form="ruleTypeForms[currentRuleType]"
								class="mb-6"
								@services-loaded="setSelectedFields"
							/>
						</keep-alive>
					</template>
				</el-form>
			</template>

			<div
				v-if="canEdit"
				class="text-right"
			>
				<el-button
					v-if="isReadView && !isDeleted"
					:class="{ 'mr-4': !isFinished }"
					data-ta="copy_btn"
					@click="copyBonus"
				>{{ $t('Дублировать') }}</el-button>

				<template v-if="isReadView && !isDeleted && !isFinished">
					<el-button
						data-ta="delete_btn"
						@click="removeRule('delete', 'bonus/rules')"
					>{{ $t('Удалить') }}</el-button>

					<router-link
						class="ml-4"
						:to="{ ...$route, query: {} }"
					>
						<el-button type="primary">{{ $t('Редактировать') }}</el-button>
					</router-link>
				</template>

				<el-button
					v-if="isCreateView || isEditView"
					:loading="isSaving"
					data-ta="save_btn"
					type="primary"
					class="ml-4"
					@click="save"
				>{{ $t('Сохранить') }}</el-button>
			</div>
		</el-card>

		<el-card
			v-if="!isReadView"
			shadow="never"
			body-style="padding: 20px 16px 8px; width: 232px;"
			class="flex-shrink h-100p ml-5"
		>
			<div class="font-size-large color-primary font-semi-bold mb-5">{{ $t('Детали') }}</div>

			<div class="font-semi-bold mb-4">{{ modeLabel }}</div>

			<el-tag
				v-if="form.points"
				type="info"
				size="default"
				class="border-radius-round mr-3 mb-3"
			>{{ $t('Общий') }}: {{ form.points }}%</el-tag>

			<el-tag
				v-if="form.mobilePoints"
				type="info"
				size="default"
				class="border-radius-round mr-3 mb-3"
			>{{ $t('Моб. приложение') }}: {{ form.mobilePoints }}%</el-tag>

			<div class="font-semi-bold mb-4">{{ $t('Товар') }} / {{ $t('услуга') }}</div>

			<el-tag
				v-for="field in selectedFields"
				:key="field.key"
				type="info"
				size="default"
				class="crop-ellipsis border-radius-round mr-3 mb-3"
				:closable="field.closable"
				data-ta="selectedFields"
				@close="resetField(field.filter, field.value)"
			>{{ field.label }}</el-tag>
		</el-card>
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'
import ReturnButton from '~/components/old/ReturnButton'
import { isRequired, isInteger, maxNumber, isNumber, minNumber } from '~/utils/validationRules'
import capitalizeFirstLetter from '~/utils/capitalizeFirstLetter'
import { formatDate, normalizeDioptreItem } from '~/utils/formatters'
import { removeRule } from '~/helpers/bonus/methods'
import mxFormChanged from '~/mixins/common/mxFormChanged'
import { DIOPTRE_FIELDS } from '~/components/bonus/rules/Utils'
import CostFormat from '~/kit/filters/CostFormat'
import ItSelect from '~/kit/components/ItSelect'
import TagItem from '~/components/bonus/rules/TagItem'

const getDefaultForm = () => ({
	mode: 'accumulation',
	active: null,
	cancelDate: null,
	canceller: null,
	name: '',
	startDate: '',
	finishDate: '',
	priceFrom: null,
	priceTo: null,
	points: null,
	mobilePoints: null,
	departmentIds: [],
})

const getDefaultRuleTypeForms = () => ({
	ACCESSORIES: {
		category: null,
		model: null,
	},
	CONTACT_LENSES: {
		manufacturer: null,
		clName: null,
		wearingPeriod: null,
		radiusOfCurvature: null,
		diameter: null,
		expirationDateFrom: null,
		expirationDateTo: null,
		dioptresFrom: null,
		dioptresTo: null,
		cylinderFrom: null,
		cylinderTo: null,
	},
	EXTRAS: {
		brand: null,
		manufacturer: null,
		glassesMaterial: null,
		targetGroup: null,
		type: null,
		models: [],
	},
	GLASSES: {
		brand: null,
		manufacturer: null,
		glassesMaterial: null,
		targetGroup: null,
		frameType: null,
		models: [],
	},
	LENSES: {
		brand: null,
		color: null,
		lensCover: null,
		diameter: null,
		dioptresFrom: null,
		dioptresTo: null,
		cylinderFrom: null,
		cylinderTo: null,
		lensGeometry: null,
		refractionIndex: null,
		lensClass: null,
		manufacturer: null,
		material: null,
		technology: null,
		lensType: null,
	},
	SERVE: {
		servicesId: [],
	},
	SUNGLASSES: {
		brand: null,
		manufacturer: null,
		glassesMaterial: null,
		lensesType: null,
		frameType: null,
		targetGroup: null,
		models: [],
	},
})

const API_LINKS_BY_RULE_TYPE = {
	ALL: '',
	SERVE: '/service',
	ACCESSORIES: '/accessory',
	CONTACT_LENSES: '/contact-lenses',
	LENSES: '/lenses',
	GLASSES: '/glasses',
	SUNGLASSES: '/sunglasses',
	EXTRAS: '/extras',
}

function getLabel(item) {
	if (item && typeof item === 'object') return item.name

	return item
}

export default {
	name: 'BonusRule',
	components: {
		ReturnButton,
		TagItem,
		Accessories: defineAsyncComponent(() => import('~/components/bonus/rules/Accessories')),
		ContactLenses: defineAsyncComponent(() => import('~/components/bonus/rules/ContactLenses')),
		Extras: defineAsyncComponent(() => import('~/components/bonus/rules/Extras')),
		Glasses: defineAsyncComponent(() => import('~/components/bonus/rules/Glasses')),
		Lenses: defineAsyncComponent(() => import('~/components/bonus/rules/Lenses')),
		Serve: defineAsyncComponent(() => import('~/components/bonus/rules/Serve')),
		Sunglasses: defineAsyncComponent(() => import('~/components/bonus/rules/Sunglasses')),
		// TagItem: defineAsyncComponent(() => import('~/components/bonus/rules/TagItem')),
		StatusCard: defineAsyncComponent(() => import('~/components/bonus/rules/StatusCard')),
		OptimaHelpTooltip: defineAsyncComponent(() => import('~/components/old/OptimaHelpTooltip')),
		ItSelect,
	},
	mixins: [
		mxFormChanged,
	],
	async beforeRouteUpdate(to, from, next) {
		const res = await this.mxConfirmIfFormChanged()
		next(Boolean(res))
	},
	async beforeRouteLeave(to, from, next) {
		const res = await this.mxConfirmIfFormChanged()
		next(Boolean(res))
	},
	props: {
		mode: {
			type: String,
			required: false,
			default: null,
		},
		id: {
			type: [Number, String],
			required: false,
			default: null,
		},
		ruleType: {
			type: String,
			required: false,
			default: null,
		},
	},
	data() {
		return {
			ruleTypes: {
				ALL: {
					label: this.$t('Все товары'),
				},
				SERVE: {
					label: this.$t('Услуги'),
				},
				GLASSES: {
					label: this.$t('Оправы'),
					icon: 'glasses',
				},
				SUNGLASSES: {
					label: this.$t('Солнцезащитные очки'),
					icon: 'sun-glasses',
				},
				CONTACT_LENSES: {
					label: this.$t('Контактные линзы'),
					icon: 'contact-lens',
				},
				LENSES: {
					label: this.$t('Линзы'),
					icon: 'lenses',
				},
				ACCESSORIES: {
					label: this.$t('Аксессуары'),
					icon: 'glasses-case',
				},
				EXTRAS: {
					label: this.$t('Массовка'),
					icon: 'massovka',
				},
			},
			labelAllItems: {
				models: this.$t('Все модели'),
				servicesId: this.$t('Все услуги'),
				departmentIds: this.$t('Все департаменты'),
			},
			form: getDefaultForm(),
			ruleTypeForms: getDefaultRuleTypeForms(),
			isLoading: false,
			isSaving: false,
			isCopying: false,
			isRequired,
			rules: {
				mode: isRequired,
				name: isRequired,
				startDate: isRequired,
				finishDate: isRequired,
				priceTo: [isNumber, minNumber(0)],
				priceFrom: [isNumber, minNumber(0)],
				points: [isRequired, isInteger, maxNumber(100)],
				mobilePoints: [isInteger, maxNumber(100)],
			},
			modeTypes: {
				accumulation: {
					label: this.$t('Начисление'),
				},
				spending: {
					label: this.$t('Списание'),
				},
			},
			departments: [],
			currentGeneralRuleType: 'ALL',
			currentSubRuleType: 'GLASSES',
			isMobileAccess: false,
			selectedFields: [],
			initialFinishDate: null,
		}
	},
	computed: {
		...mapState(['settings']),
		...mapGetters('user', [
			'canEditBonus',
		]),
		currency() {
			return this.settings.currency
		},
		canEdit() {
			return this.isEditView ? (this.canEditBonus && !this.isDeleted && !this.isFinished) : this.canEditBonus
		},
		isCreateView() {
			return !this.isEditView && !this.isReadView
		},
		isEditView() {
			return !!this.id && !this.isReadView
		},
		isReadView() {
			return this.$route.query.isReadView === 'true'
		},
		currentMode() {
			return this.modeTypes[this.form.mode]
		},
		period: {
			get() { return [this.form.startDate, this.form.finishDate] },
			set(period) {
				[this.form.startDate, this.form.finishDate] = period ? period.map(date => `${date}T00:00:00.000Z`) : ['', '']
			},
		},
		initialMode() {
			const mode = this.mode || this.$route.query.mode

			if (mode && this.modeTypes[mode]) return mode

			return this.form.mode
		},
		initialRuleType() {
			const ruleType = this.ruleType || this.$route.query.ruleType

			if (ruleType && API_LINKS_BY_RULE_TYPE[ruleType] !== undefined) return ruleType

			return 'ALL'
		},
		currentRuleTypeComponent() {
			if (!this.ruleTypeForms[this.currentRuleType]) return null

			return this.currentRuleType.toLowerCase().split('_').map(capitalizeFirstLetter).join('')
		},
		isRuleTypeAll() {
			return this.currentRuleType === 'ALL'
		},
		generalRuleTypes() {
			return {
				ALL: this.ruleTypes.ALL,
				selected: {
					label: this.$t('Избранные товары'),
				},
				SERVE: this.ruleTypes.SERVE,
			}
		},
		availableGeneralRuleTypes() {
			if (!this.isEditView) return this.generalRuleTypes

			const currentGeneralRuleType = this.generalRuleTypes[this.currentGeneralRuleType]

			return currentGeneralRuleType
				? { [this.currentGeneralRuleType]: currentGeneralRuleType }
				: { [this.generalRuleTypes.ALL]: this.generalRuleTypes.ALL }
		},
		availableSubRuleTypes() {
			if (!this.isEditView) {
				return Object.fromEntries(Object.entries(this.ruleTypes).filter(([type]) => !this.generalRuleTypes[type]))
			}

			const currentRuleType = this.ruleTypes[this.currentRuleType]

			return currentRuleType
				? { [this.currentRuleType]: currentRuleType }
				: { [this.ruleTypes.GLASSES]: this.ruleTypes.GLASSES }
		},
		pointsTag() {
			const tags = [`${this.$t('Общий')}: ${this.form.points} %`]
			if (this.isMobileAccess && this.form.mobilePoints) tags.push(`${this.$t('Моб. приложение')}: ${this.form.mobilePoints} %`)
			return tags
		},
		departmentTags() {
			if (this.isLoading) return []

			if (this.form.departmentIds.length === 0 || this.form.departmentIds.length === this.departments.length) return this.labelAllItems.departmentIds
			return this.departments.filter(dept => this.form.departmentIds.includes(dept.id)).map(d => d.name)
		},
		isDeleted() {
			return !this.form.active
		},
		isFinished() {
			return this.$dayjs().format('YYYY-MM-DD') > this.initialFinishDate
		},
		isCreationView() {
			return !this.isReadView && !this.isEditView
		},
		isDuplication() {
			return this.isCreationView && !!this.$route.query.fromId
		},
		statusCard() {
			if (this.isLoading) return { type: 'info' }

			if (this.isDeleted) {
				const patronymicNameChar = this.form.canceller?.patronymicName ? ` ${this.form.canceller.patronymicName[0]}.` : ''
				const firstChars = `${this.form.canceller?.firstName[0]}.${patronymicNameChar}`

				return {
					type: 'danger',
					desc: `${this.form.canceller?.familyName} ${firstChars} ${this.formatDate(this.form.cancelDate)}`,
				}
			}

			if (this.isFinished) return { type: 'warning' }
			return { type: 'success' }
		},
		priceRange() {
			if (this.form.priceFrom == null && this.form.priceTo == null) return null

			const from = this.form.priceFrom ? `${this.$t('от')} ${this.form.priceFrom} ` : ''
			const to = this.form.priceTo ? `${this.$t('до')} ${this.form.priceTo} ` : ''

			return `${from}${to} ${this.currency?.code}`
		},
		currentRuleType() {
			if (this.generalRuleTypes[this.currentGeneralRuleType] && this.currentGeneralRuleType !== 'selected') return this.currentGeneralRuleType

			return this.currentSubRuleType
		},
		modeLabel() {
			if (this.form.mode === 'spending') {
				return this.$t('Размер скидки')
			}
			return this.$t('Размер накопления')
		},
	},
	watch: {
		id: {
			immediate: true,
			handler: 'setBonusData',
		},
		'$route.query.fromId': {
			immediate: true,
			handler: 'setBonusData',
		},
		currentRuleType() {
			this.$nextTick(this.setSelectedFields)
		},
		form: {
			deep: true,
			handler: 'setSelectedFields',
		},
		ruleTypeForms: {
			deep: true,
			handler: 'setSelectedFields',
		},
		isEditView() {
			this.setSelectedFields()

			this.isFormChanged = false
		},
	},
	created() {
		this.setDepartments()
		this.setMobileAccess()
		this.setSelectedFields()
		this.formatDate = formatDate
		this.removeRule = removeRule
	},
	methods: {
		async setDepartments() {
			try {
				this.departments = (await this.$http.optima2.get('bonus/rules/departments')).data
			} catch (error) {
				this.$notifyUserAboutError(error)
			}
		},
		async save() {
			const isValid = await this.$isFormValid('form')

			if (!isValid) {
				this.$notifyUserAboutError(this.$t('Проверьте правильность введенных данных'))
				return
			}

			this.isSaving = true

			const currentRuleTypeForm = this.ruleTypeForms[this.currentRuleType]

			if (this.isDuplication && this.currentRuleType === 'SERVE') {
				currentRuleTypeForm.servicesId = currentRuleTypeForm.servicesId.map(s => s.id || s)
			}

			const form = {
				...this.form,
				...currentRuleTypeForm,
				mode: this.form.mode ? this.form.mode.toUpperCase() : null,
				startDate: formatDate(this.form.startDate, 'YYYY-MM-DD'),
				finishDate: formatDate(this.form.finishDate, 'YYYY-MM-DD'),
			}

			const linkToSave = API_LINKS_BY_RULE_TYPE[this.currentRuleType]

			try {
				let { id } = this

				if (!this.isEditView) {
					const { data } = await this.$http.optima2.post(`bonus/rules${linkToSave}`, form)

					id = data.id
				} else await this.$http.optima2.put(`bonus/rules/${this.id}`, form)

				this.$nextTick(() => {
					this.isFormChanged = false
					this.$router.push({ name: 'Bonus', query: { mode: this.form.mode, bonusId: id } })
					this.$notify({ title: this.$t('Сохранено'), type: 'success' })
				})
			} catch (error) {
				this.$notifyUserAboutError(error)
			}

			this.isSaving = false
		},
		async setBonusData(id) {
			this.form.mode = this.initialMode

			this.changeRuleType(this.initialRuleType)

			if (!id) return

			this.isLoading = true

			const linkToGet = API_LINKS_BY_RULE_TYPE[this.currentRuleType]

			try {
				const { data } = await this.$http.optima2.get(`bonus/rules${linkToGet}/${id}`)

				this.initialFinishDate = data.finishDate

				this.form.active = data.active
				this.form.cancelDate = data.cancelDate
				this.form.canceller = data.canceller
				this.form.name = data.name
				this.form.priceFrom = data.priceFrom
				this.form.priceTo = data.priceTo
				this.form.points = this.form.mode === 'accumulation' ? data.bonusPoints : data.discount
				this.form.mobilePoints = this.form.mode === 'accumulation' ? data.mobileBonusPoints : data.mobileDiscount
				this.form.departmentIds = data.departments?.map(department => department.id) || []

				this.period = this.isDuplication ? null : [data.startDate, data.finishDate]

				const currentRuleTypeForm = this.ruleTypeForms[this.currentRuleType]

				if (currentRuleTypeForm) {
					Object.keys(currentRuleTypeForm).forEach(key => {
						currentRuleTypeForm[key] = data[key]
					})
				}

				if (this.currentRuleType === 'SERVE') currentRuleTypeForm.servicesId = data.services
			} catch (error) {
				this.$notifyUserAboutError(error)
			}

			this.$nextTick(() => { this.isLoading = false })
		},
		changeRuleType(code) {
			if (this.isEditView && code !== this.initialRuleType) return

			if (this.generalRuleTypes[code]) this.currentGeneralRuleType = code
			else {
				this.currentGeneralRuleType = 'selected'
				this.currentSubRuleType = code
			}
		},
		disabledLastDays(date) {
			return date < this.$dayjs().startOf('day')
		},
		async setMobileAccess() {
			try {
				this.isMobileAccess = (await this.$http.optima2.get('bonus/rules/mobile-access')).data.hasMobileAccess
			} catch (error) {
				this.$notifyUserAboutError(error)
			}
		},
		resetField(entryField, value) {
			if (entryField === 'ruleType') {
				this.currentGeneralRuleType = 'ALL'

				return
			}

			const field = Array.isArray(entryField) ? entryField[0] : entryField

			let form = null

			if (field in this.form) {
				form = this.form
			} else if (field in this.ruleTypeForms[this.currentRuleType]) {
				form = this.ruleTypeForms[this.currentRuleType]
			}

			if (!form) return

			if (Array.isArray(entryField)) {
				entryField.forEach(entryOneField => {
					form[entryOneField] = null
				})

				return
			}

			if (Array.isArray(form[field])) {
				const index = form[field].indexOf(value)

				if (index !== -1) form[field].splice(index, 1)
			} else form[field] = null
		},
		getSelectedFields() {
			const selectedFields = []

			selectedFields.push({
				value: this.ruleTypes[this.currentRuleType].label,
				label: this.ruleTypes[this.currentRuleType].label,
				key: 'ruleType',
				filter: 'ruleType',
				closable: this.currentRuleType !== 'ALL' && !this.isEditView,
			})

			if (this.form.priceFrom) {
				selectedFields.push({
					value: this.form.priceFrom,
					label: `${this.$t('Стоимость от')} ${CostFormat(Number(this.form.priceFrom))} ${this.currency?.code}`,
					key: 'priceFrom',
					filter: 'priceFrom',
					closable: !this.isEditView,
				})
			}

			if (this.form.priceTo) {
				selectedFields.push({
					value: this.form.priceTo,
					label: `${this.$t('Стоимость до')} ${CostFormat(Number(this.form.priceTo))} ${this.currency?.code}`,
					key: 'priceTo',
					filter: 'priceTo',
					closable: !this.isEditView,
				})
			}

			if (this.currentRuleType === 'ALL') return selectedFields

			if (this.currentRuleType === 'SERVE') {
				if (this.ruleTypeForms[this.currentRuleType].servicesId.length > 0) {
					this.ruleTypeForms[this.currentRuleType].servicesId.forEach(serviceId => {
						const serviceFull = typeof serviceId === 'object'
							? serviceId
							: this.$refs.currentRule?.services.find(service => service.id === serviceId)

						if (serviceFull) {
							const label = getLabel(serviceFull)

							selectedFields.push({
								value: serviceId,
								label,
								key: `servicesId_${label}`,
								filter: 'servicesId',
								closable: !this.isEditView,
							})
						}
					})
				} else {
					selectedFields.push({
						value: null,
						label: this.labelAllItems.servicesId,
						key: 'servicesId',
						filter: 'servicesId',
						closable: false,
					})
				}

				return selectedFields
			}

			// TODO с expirationDateFrom и expirationDateTo костыль, надо сделать по-другому
			const date = []

			Object.entries(this.ruleTypeForms[this.currentRuleType]).forEach(([key, fieldValue]) => {
				if (fieldValue && (key === 'expirationDateFrom' || key === 'expirationDateTo')) {
					if (key === 'expirationDateFrom') date.unshift(fieldValue)
					else date.push(fieldValue)
				} else if ((fieldValue || fieldValue === 0) && (!Array.isArray(fieldValue) || fieldValue.length !== 0)) {
					(Array.isArray(fieldValue) ? fieldValue : [fieldValue]).forEach(value => {
						selectedFields.push({
							value,
							label: DIOPTRE_FIELDS.includes(key) ? normalizeDioptreItem(value) : value,
							key: `${key}_${value}`,
							filter: key,
							closable: !this.isEditView,
						})
					})
				} else if ((Array.isArray(fieldValue) || fieldValue === null) && this.labelAllItems[key]) {
					selectedFields.push({
						value: null,
						label: this.labelAllItems[key],
						key,
						filter: key,
						closable: false,
					})
				}
			})

			if (date.length > 0) {
				const value = date.map(formatDate).join(' - ')

				selectedFields.push({
					value,
					label: value,
					key: `date_${value}`,
					filter: ['expirationDateFrom', 'expirationDateTo'],
					closable: !this.isEditView,
				})
			}

			return selectedFields
		},
		setSelectedFields() {
			if (this.isReadView) return

			const isFirstTime = this.selectedFields.length === 0

			this.selectedFields = this.getSelectedFields()

			if (!this.isLoading && !isFirstTime) this.isFormChanged = true
		},
		async copyBonus() {
			this.$router.push({
				name: 'BonusRule',
				query: {
					fromId: this.id,
					mode: this.form.mode,
					ruleType: this.currentRuleType,
				},
			})
		},
	},
}
</script>

<style lang="scss">

#page-bonus-rule {
	.form-item {
		&-large {
			width: 278px;
		}

		&-small {
			width: 117px;
		}
	}

	.el-date-editor.el-input__wrapper {
		flex-grow: 0;
		box-sizing: border-box;
	}

	.rule-type-block {
		width: 200px;
	}

	.el-form-item {
		margin-bottom: 12px;

		&__label {
			padding-right: 8px;
		}
	}

	.el-radio {
		&__inner {
			border-color: var(--el-border-color-dark);
		}

		&__input.is-checked {
			.el-radio__inner {
				border: none !important;
			}
		}

		&-group {
			background: var(--el-color-white);
			border-radius: 4px;
			display: inline-block;
		}

		&-button {
			&__inner {
				background: var(--el-color-white) !important;
				border-color: var(--el-border-color-dark) !important;
			}

			&.is-active {
				.el-radio-button__inner {
					background: var(--el-color-primary) !important;
				}
			}
		}
	}
}
</style>