export default {
	mOrder(state, payload) {
		state.order = payload
	},
	mOrderType(state, payload) {
		state.orderType = payload
	},
	mInitializeCart(state, payload) {
		state.cartUid = payload.data
	},
	mCart(state, payload) {
		state.cart = payload
	},
	mCartItem(state, payload) {
		const { index, prop, value } = payload
		const { cart } = state
		cart.cartItems[index].item[prop] = value
	},
	mServices(state, payload) {
		state.services = payload
	},
	mUsersByService(state, payload) {
		state.usersByService = {
			...state.usersByService,
			[payload.id]: payload.users,
		}
	},
	mUnmarkedGoods(state, payload) {
		state.unmarkedGoods = payload
	},
	mCartsDiscounts(state, payload) {
		state.cartsDiscounts = payload
	},
	mOrderNotes(state, payload) {
		state.orderNotes = {
			...state.orderNotes,
			...payload,
		}
	},
	mOrderErrors(state, { from, error }) {
		state.orderErrors = {
			...state.orderErrors,
			[from]: {
				...state.orderErrors[from],
				...error,
			},
		}
	},
	mResetOrder(state) {
		state.cart = null
		state.cartUid = null
		state.orderNotes = {}
		state.barcodesLength = []
		state.services = []
		state.usersByService = {}
		state.unmarkedGoods = []
		state.discounts = []
		state.orderErrors = {}
	},
	aInfoFieldsSettings(state, payload) {
		state.infoFieldsSettings = payload
	},
	mInfoFieldsOptions(state, payload) {
		state.infoFieldsOptions = payload
	},
	mOrderExtras(state, payload) {
		state.extrasOrder = payload
	},
	mOrderExtrasById(state, payload) {
		state.orderExtrasById = payload
	},
	mOrderExtrasGroupById(state, payload) {
		state.orderExtrasGroupById = payload
	},
	mCLPrescriptions(state, payload) {
	},
	mCLNomenclature(state, payload) {
		state.CLNomenclature = payload
	},
	mReceiptRecommendedModels(state, payload) {
		state.receiptRecommendedModels = payload.content
	},
	mOrderServices(state, payload) {
		state.orderServices = payload
	},
	mServicesCartUuid(state, payload) {
		state.servicesCartUuid = payload.data
	},
	mServicesDiscounts(state, payload) {
		state.servicesDiscounts = payload
	},
	mGiftCertificatesStatuses(state, payload) {
		state.giftCertificatesStatuses = payload
	},
}