export default function multiplicityCLPackageTest(retailPrice, packageQuantity, currentQuantity) {
	const factor = Math.pow(10, String(~~retailPrice).length)// eslint-disable-line
	const validationResult = (((retailPrice * factor) / packageQuantity) * currentQuantity) / factor


	// сотые доли (копейки) переводим в рубли и получаем дробную часть
	const decimal = validationResult * 100 - ~~(validationResult * 100) // eslint-disable-line

	// погрешность вычислений игнорируем (.00000000001, .999999999998)
	if (decimal < 0.00001 || decimal > 0.99999) {
		return true
	}


	// здесь decimal > 0, значит в currentQuantity не кратное количество
	return false
}