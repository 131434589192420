import VuexHttp from '../../vuexHttpService'

const vHttp = new VuexHttp('mailings')

export default {
	aMailing(_, id) {
		return vHttp.GET(this, {
			url: `mailings/${id}`,
			mutation: 'mMailing',
		})
	},
	aResendInfo(_, id) {
		return vHttp.GET(this, {
			url: `mailings/${id}/failed-messages/resend-info`,
			mutation: 'mResendInfo',
		})
	},
	aSendFailedMessages(_, id) {
		return vHttp.PUT(this, {
			url: `mailings/${id}/failed-messages`,
		})
	},
	aStatuses() {
		return vHttp.GET(this, {
			url: `mailings/statuses`,
			mutation: 'mStatuses',
		})
	},
}