<template>
	<div class="order-additional-info">
		<div
			v-if="contactLensReplacementBefore"
			class="order-additional-info__grey-block mb-4"
		>
			<span>{{ $t('Замена КЛ до:') }}</span>
			<span>{{ contactLensReplacementBefore }}</span>
		</div>

		<div class="order-additional-info__discounts">
			<el-form
				ref="form"
				:model="additionalInfo"
				:rules="rules"
				label-position="top"
				data-ta="order-additional-info-form"
				class="order-additional-info__form"
				require-asterisk-position="right"
			>
				<el-form-item
					v-for="field of formFields"
					:key="field.name"
					:label="field.label"
					:prop="field.name"
					:class="field.class"
				>
					<el-select
						v-if="field.type === 'select'"
						v-model="additionalInfo[field.name]"
						:placeholder="field.placeholder"
						:data-ta="field.name"
						:filterable="field.filterable"
						:popper-class="'ta-select-dropdown ta-select-dropdown-' + field.name"
						class="w-100p"
						size="default"
						clearable
						@update:modelValue="submitForm($event, field.name)"
						@clear="onClear(field.name)"
					>
						<el-option
							v-for="option in field.options"
							:key="option.value"
							:label="option.label"
							:value="option.value"
						/>
					</el-select>

					<ComFieldCurrency
						v-if="field.type === 'input'"
						v-model="additionalInfo[field.name]"
						size="default"
						type="number"
						is-edit
						class="w-100p"
						:placeholder="field.placeholder"
						:name="field.name"
						:options="{
							valueRange: { min: 0.01 },
						}"
						@update:modelValue="submitForm($event, field.name)"
						@clear="onClear(field.name)"
					/>

					<el-input
						v-if="field.type === 'textarea'"
						v-model="additionalInfo[field.name]"
						v-input-suffix-padding
						:placeholder="field.placeholder"
						:data-ta="field.name"
						:autosize="{ minRows: 3, maxRows: 15 }"
						type="textarea"
						maxlength="1200"
						show-word-limit
						input-style="max-height: 262px"
						@update:modelValue="submitForm($event, field.name)"
					/>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import { isRequired } from '~/utils/validationRules'
import ComFieldCurrency from '~/components/ComFields/ComFieldCurrency.vue'
import InputSuffixPadding from '~/helpers/directives/InputSuffixPadding.js'


export default {
	name: 'PartOrderAdditionalInfo',

	components: {
		ComFieldCurrency,
	},

	directives: { InputSuffixPadding },

	data: () => ({
		additionalInfo: {
			discount: null,
			additionalDiscount: null,
			orderComment: null,
			firstOrderReason: null,
			secondOrderReason: null,
		},
		formFields: [],
	}),

	computed: {
		...mapState('orders', [
			'orderType',
			'cartUid',
			'infoFieldsSettings',
			'infoFieldsOptions',
			'orderErrors',
		]),
		...mapState(['settings']),
		...mapGetters('orders', [
			'gCartInfo',
			'gCartsDiscounts',
		]),

		contactLensReplacementBefore() {
			if (this.orderType === 'repairOrder') {
				return false
			}

			return this.gCartInfo?.contactLensReplacementBefore
				&& this.$dayjs(this.gCartInfo.contactLensReplacementBefore)
					.format('DD.MM.YYYY')
		},

		rules() {
			const [infoField0, infoField1] = this.infoFieldsSettings || []
			const [infoField0Options, infoField1Options] = this.infoFieldsOptions || []
			const rules = {}

			if (infoField0?.[this.orderType] && infoField0Options?.names?.length) {
				if (infoField0?.required) {
					rules.firstOrderReason = [isRequired]
				}
			}

			if (infoField1?.[this.orderType] && infoField1Options?.names?.length) {
				if (infoField1?.required) {
					rules.secondOrderReason = [isRequired]
				}
			}

			return rules
		},
	},

	watch: {
		orderErrors: {
			deep: true,
			handler(val) {
				if (val?.goodsCart?.firstOrderReason || val?.goodsCart?.secondOrderReason) {
					const orderErrors = Object.keys(val.goodsCart)
					this.$refs.form.validateField(orderErrors)
				}
			},
		},
	},

	async mounted() {
		await Promise.all([
			this.aInfoFieldsSettings(),
			this.aInfoFieldsOptions(),
			this.aCartsDiscounts(),
		])
		this.setFormFields()
	},

	methods: {
		...mapMutations('orders', [
			'mOrderNotes',
			'mOrderErrors',
		]),
		...mapActions('orders', [
			'aAddDiscount',
			'aAddExtraDiscount',
			'aServices',
			'aUnmarkedGoods',
			'aDeleteGeneralDiscount',
			'aInfoFieldsSettings',
			'aInfoFieldsOptions',
			'aCartsDiscounts',
		]),

		setFormFields() {
			const [infoField0, infoField1] = this.infoFieldsSettings || []
			const [infoField0Options, infoField1Options] = this.infoFieldsOptions || []

			const fields = [{
				name: 'discount',
				label: this.$t('Скидка'),
				placeholder: this.$t('Выберите скидку'),
				type: 'select',
				filterable: true,
				options: this.gCartsDiscounts,
				class: this.settings.additionalCashDiscount ? 'column' : 'w-100p',
			}]

			if (this.settings.additionalCashDiscount) {
				fields.push({
					name: 'additionalDiscount',
					label: this.$t('Дополнительная скидка'),
					placeholder: this.$t('Введите сумму'),
					type: 'input',
					class: 'column',
				})
			}

			if (infoField0?.[this.orderType] && infoField0Options?.names?.length) {
				fields.push({
					name: 'firstOrderReason',
					label: infoField0?.label,
					placeholder: this.$t('Выберите'),
					type: 'select',
					filterable: true,
					class: infoField1?.saleOrder ? 'column' : 'w-100p',
					options: infoField0Options?.names
						.map(name => ({
							label: name,
							value: name,
						})),
				})
			}

			if (infoField1?.[this.orderType] && infoField1Options?.names?.length) {
				fields.push({
					name: 'secondOrderReason',
					label: infoField1?.label,
					placeholder: this.$t('Выберите'),
					type: 'select',
					filterable: true,
					class: infoField0?.saleOrder ? 'column' : 'w-100p',
					options: infoField1Options?.names
						.map(name => ({
							label: name,
							value: name,
						})),
				})
			}


			fields.push({
				name: 'orderComment',
				label: this.$t('Комментарий'),
				placeholder: this.$t('Введите текст'),
				type: 'textarea',
				maxLength: 1200,
				showWordLimit: true,
				class: 'w-100p',
			},)

			this.formFields = fields
		},

		async submitForm(fieldValue, fieldKey) {
			if (fieldKey === 'orderComment' && fieldValue) {
				this.mOrderNotes({
					orderComment: fieldValue,
				})
			}

			if (fieldKey === 'firstOrderReason' && fieldValue) {
				this.mOrderNotes({
					firstOrderReason: fieldValue,
				})
				this.mOrderErrors({
					from: 'goodsCart',
					error: {
						firstOrderReason: false,
					},
				})
			}

			if (fieldKey === 'secondOrderReason' && fieldValue) {
				this.mOrderNotes({
					secondOrderReason: fieldValue,
				})
				this.mOrderErrors({
					from: 'goodsCart',
					error: {
						secondOrderReason: false,
					},
				})
			}

			if (fieldKey === 'additionalDiscount') {
				if (fieldValue === 0) return
			}

			async function sendRequest() {
				this.throttleTimeoutHandle = null
				let result

				if (fieldKey === 'discount' && fieldValue) {
					result = await this.aAddDiscount({
						uid: this.cartUid,
						discountId: fieldValue,
					})
				}

				if (fieldKey === 'additionalDiscount' && fieldValue) {
					result = await this.aAddExtraDiscount({
						uid: this.cartUid,
						data: {
							sum: fieldValue,
						},
					})
				}

				if (fieldKey === 'additionalDiscount' && !fieldValue) {
					this.onClear(fieldKey)
				}

				if (result?.status === 200) {
					this.bonusCard = null
					this.aServices(this.cartUid)
					this.aUnmarkedGoods(this.cartUid)
					this.$notify({ title: this.$t('Скидка добавлена'), type: 'success' })
				}
			}

			if (this.throttleTimeoutHandle) {
				clearTimeout(this.throttleTimeoutHandle)
			}

			this.throttleTimeoutHandle = setTimeout(() => sendRequest.call(this), 500)
		},
		async onClear(fieldKey) {
			if (fieldKey === 'firstOrderReason' || fieldKey === 'secondOrderReason') {
				const orderNote = {}
				orderNote[fieldKey] = null
				this.mOrderNotes(orderNote)
				return
			}

			const result = await this.aDeleteGeneralDiscount({
				uid: this.cartUid,
				discountsType: fieldKey,
			})

			if (result?.status === 200) {
				this.additionalInfo[fieldKey] = null
				this.bonusCard = null
				this.aServices(this.cartUid)
				this.aUnmarkedGoods(this.cartUid)
				this.$notify({ title: this.$t('Скидка удалена'), type: 'success' })
			}
		},
	},
}
</script>

<style lang="scss">

.order-additional-info {
	&__form {
		display: flex;
		flex-wrap: wrap;
		column-gap: 16px;
	}

	&__grey-block {
		display: flex;
		gap: 8px;
		background: #f4f4f8;
		padding: 7px 12px;
		border-radius: 4px;
		border: 1px solid var(--el-border-color-dark);
		align-items: center;
	}

	.column {
		flex-basis: 48.7%;
	}

	.el-form-item__label {
		padding: 0;
	}

	.el-input__count {
		margin: 5px 0 5px 8px;
		box-sizing: border-box !important;
		height: 20px !important;
	}

	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		/* display: none; <- Crashes Chrome on hover */
		-webkit-appearance: none;
		margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
	}
}
</style>