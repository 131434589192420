import dayjs from 'dayjs'
import i18n from '~/plugins/i18n'

export const TODAY = {
	text: i18n.global.t('Сегодня'),
	value: () => [new Date(), new Date()],
}
export const CURRENT_WEEK = {
	text: i18n.global.t('Текущая неделя'),
	value: () => {
		const start = dayjs().startOf('week').$d
		const end = dayjs().endOf('week').$d
		return [start, end]
	},
}
export const LAST_WEEK = {
	text: i18n.global.t('Предыдущая неделя'),
	value: () => {
		const date = dayjs().subtract(1, 'week')
		const start = date.startOf('week').$d
		const end = date.endOf('week').$d
		return [start, end]
	},
}
export const LAST_MONTH = {
	text: i18n.global.t('Предыдущий месяц'),
	value: () => {
		const date = dayjs().subtract(1, 'month')
		const start = date.startOf('month').$d
		const end = date.endOf('month').$d
		return [start, end]
	},
}
export const LAST_YEAR = {
	text: i18n.global.t('Предыдущий год'),
	value: () => {
		const date = dayjs().subtract(1, 'year')
		const start = date.startOf('year').$d
		const end = date.endOf('year').$d
		return [start, end]
	},
}
export const CURRENT_MONTH = {
	text: i18n.global.t('Текущий месяц'),
	value: () => {
		const start = dayjs().startOf('month').$d
		const end = dayjs().endOf('month').$d
		return [start, end]
	},
}
export const CURRENT_YEAR = {
	text: i18n.global.t('Текущий год'),
	value: () => {
		const start = dayjs().startOf('year').$d
		const end = dayjs().endOf('year').$d
		return [start, end]
	},
}
export const SLIDING_WEEK = {
	text: i18n.global.t('Скользащая неделя'),
	value: () => {
		const start = dayjs().subtract(7, 'day').$d
		const end = dayjs().$d
		return [start, end]
	},
}

export const SLIDING_MONTH = {
	text: i18n.global.t('Скользящий месяц'),
	value: () => {
		const start = dayjs().subtract(1, 'month').$d
		const end = dayjs().$d
		return [start, end]
	},
}

export const SLIDING_YEAR = {
	text: i18n.global.t('Скользящий год'),
	value: () => {
		const start = dayjs().subtract(1, 'year').$d
		const end = dayjs().$d
		return [start, end]
	},
}
export const YESTERDAY = {
	text: i18n.global.t('Вчера'),
	value: () => {
		const start = dayjs().subtract(1, 'day').$d
		const end = start
		return [start, end]
	},
}
export const NOW = {
	text: i18n.global.t('Сейчас'),
	value: () => dayjs(),
}
export const ADD_30_MIN = {
	text: i18n.global.t('Через полчаса'),
	value: () => dayjs().add(30, 'minute'),
}
export const ADD_1_HOUR = {
	text: i18n.global.t('Через час'),
	value: () => dayjs().add(1, 'hour'),
}
export const TOMORROW = {
	text: i18n.global.t('Завтра'),
	value: () => dayjs().add(1, 'day').startOf('day').add(8, 'hour'),
}

export default {
	TODAY,
	CURRENT_WEEK,
	LAST_WEEK,
	LAST_MONTH,
	LAST_YEAR,
	CURRENT_MONTH,
	CURRENT_YEAR,
	SLIDING_WEEK,
	SLIDING_MONTH,
	SLIDING_YEAR,
	YESTERDAY,
	NOW,
	ADD_30_MIN,
	ADD_1_HOUR,
	TOMORROW,
}