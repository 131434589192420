export default {
	currentClient: null,
	clientAgreementType: null,
	sendMessageError: null,
	acceptingAgreementError: null,
	clientAgreementData: null,
	checkingWasSmsSent: false,
	smsTimersByClientId: {},
	agreement: null,
	agreementLoading: false,
	clientsEmail: null,
	storeBalanceInfo: null,
}