import i18n from '~/plugins/i18n'

export default {
	CREATED: {
		label: i18n.global.t('Активна'),
		value: 'CREATED',
		color: '#ff3c3c',
	},
	FINISHED: {
		label: i18n.global.t('Завершена'),
		value: 'FINISHED',
		color: '#1cad4b',
	},
	CANCELLED: {
		label: i18n.global.t('Отменена'),
		value: 'CANCELLED',
		color: '#909399',
	},
}