<template>
	<div class="cl-popover-body">
		<div v-if="isExpiredCl" class="d-flex gap-12">
			<ItIcon
				width="20"
				height="20"
				name="alert-triangle"
				class="fill-warning mt-2"
			/>
			<span>
				<b>{{ $t('Срок годности пачки истек!') }}</b><br>
				{{ $t('Все равно добавить товар в заказ?') }}
			</span>
		</div>

		<div
			v-else-if="!isExpiredCl && !contactLens.onlyFullPackages"
			id="selectClNumAndPrice"
		>
			<div>
				<span class="cl-popover-body__contact-lens">{{ $t('Контактные линзы') }}</span>
				<h5 class="cl-popover-body__lens-model">{{ contactLens.name }}</h5>
			</div>
			<div class="mt-6">
				<span>{{ $t('Укажите количество блистеров') }}</span>
				<div class="d-flex align-items-center mt-2">
					<el-input
						ref="CLQuantity"
						v-model="model"
						size="default"
						:min="1"
						:max="maxSize"
						data-ta="order-barcode-quantity"
						type="number"
						@focus="onFocus"
					>
						<template #prepend>
							<el-icon
								class="color-text-regular count-icon"
								:class="{ 'is-disabled': !isDownCountEnabled }"
								data-ta="decrease-blister-button"
								@click="downCount"
							><Minus/></el-icon>
						</template>
						<template #append>
							<el-icon
								class="color-text-regular count-icon"
								:class="{ 'is-disabled': !isRaiseCountEnabled }"
								data-ta="increase-blister-button"
								@click="raiseCount"
							><Plus/></el-icon>
						</template>
					</el-input>
					<el-checkbox
						v-model="isFullPackage"
						:disabled="maxSize < contactLens.packageQuantity"
						data-ta="order-barcode-full-package"
						class="ml-4"
					>
						{{ $t('Целая упаковка') }}
					</el-checkbox>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'CLPopoverBody',

	props: {
		contactLens: {
			type: Object,
			default: null,
		},
		isExpiredCl: {
			type: Boolean,
			default: false,
		},
		maxSize: {
			type: Number,
			default: 0,
		},
		fullPackage: {
			type: Number,
			default: 0,
		},
		modelValue: {
			type: Number,
			default: 0,
		},
		isPopoverVisible: {
			type: Boolean,
			default: false,
		},
	},

	computed: {
		model: {
			get() {
				return this.modelValue
			},

			set(val) {
				const validationManualEnter = (val <= this.maxSize) && (val > 0)

				if (validationManualEnter) {
					this.$emit('update:modelValue', Number(val))
				}
			},
		},
		isFullPackage: {
			get() {
				return this.contactLens
					? (this.contactLens.currentQuantity === this.contactLens.packageQuantity)
					: false
			},
			set() {
				this.model = this.fullPackage
			},
		},

		isRaiseCountEnabled() {
			return this.model < this.maxSize
		},

		isDownCountEnabled() {
			return this.model > 1
		},
	},

	watch: {
		isPopoverVisible: {
			immediate: true,
			handler(val) {
				if (val) {
					this.$nextTick(() => this.$refs.CLQuantity.focus())
				}
			},
		},
	},

	methods: {
		onFocus() {
			this.$refs.CLQuantity.select()
		},
		raiseCount() {
			if (this.isRaiseCountEnabled) {
				this.model++
			}
		},
		downCount() {
			if (this.isDownCountEnabled) {
				this.model--
			}
		},
	},
}
</script>

<style lang="scss">
.cl-popover-body {
	&__contact-lens {
		font-size: 12px;
		color: #7d7d7d;
	}

	&__lens-model {
		margin-top: 0 !important;
	}

	.el-input {
		width: 88px !important;
		height: 30px !important;

		&__wrapper {
			padding: 0;
		}

		&__inner {
			box-sizing: border-box;
			padding: 0 5px;
			text-align: center;
			width: 35px;
			font-size: 12px;
		}
	}

	.el-input-group__prepend,
	.el-input-group__append {
		padding: 0;
		height: 30px !important;
		cursor: pointer;
	}

	.count-icon {
		width: 30px;
		height: 30px;
	}


	.is-disabled {
		cursor: not-allowed !important;
	}

	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		/* display: none; <- Crashes Chrome on hover */
		-webkit-appearance: none;
		margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
	}
}
</style>