export default {
	mAccessoriesCategories(state, payload) {
		state.accessoriesCategories = payload.category
	},
	mAccessoriesModels(state, payload) {
		state.accessoriesModels = payload.model
	},
	mContactLensNomenclature(state, payload) {
		state.contactLensManufacturers = payload.manufacturer
	},
	mContactLensNames(state, payload) {
		state.contactLensNames = payload.name
	},
	mGlassesNomenclature(state, payload) {
		state.glassesManufacturers = payload.manufacturer
		state.glassesBrands = payload.brand
	},
	mGlassesBrandsByManufacturer(state, payload) {
		state.glassesBrandsByManufacturer = payload.brand
	},
	mLensNomenclature(state, payload) {
		state.lensManufacturers = payload.manufacturer
		state.lensBrands = payload.brand
	},
	mLensBrandsByManufacturer(state, payload) {
		state.lensBrandsByManufacturer = payload.brand
	},
	mSunglassesNomenclature(state, payload) {
		state.sunglassesManufacturers = payload.manufacturer
		state.sunglassesBrands = payload.brand
	},
	mSunglassesBrandsByManufacturer(state, payload) {
		state.sunglassesBrandsByManufacturer = payload.brand
	},
	mCertificatesGoodsTypes(state, payload) {
		state.certificatesGoodsTypes = payload
	},
	mCertificateById(state, payload) {
		state.certificateById = payload
	},
}