import isEqual from 'lodash/isEqual'

export default function objectsDiff(o1, o2) {
	return Object.entries(o1).reduce((res, [k, v]) => {
		if (!isEqual(v, o2[k])) {
			res[k] = [v, o2[k]]
		}
		return res
	}, {})
}

export function showObjectsDiff(o1, o2) {
	// o1 = JSON.parse(JSON.stringify(o1))
	// o2 = JSON.parse(JSON.stringify(o2))
	const res = objectsDiff(o1, o2)
	console.log('Changed fields:', res)
}