import updateElMessageBoxIframeStyles from '~/utils/iframes/iframesElMessageBoxStyle'

export default {
	install(app) {
		const confirm = app.config.globalProperties.$confirm
		app.config.globalProperties.$confirm = function (...args) {
			// eslint-disable-next-line no-async-promise-executor
			return new Promise(async (resolve, reject) => {
				try {
					const confirmMessageBox = confirm.call(this, ...args)
					updateElMessageBoxIframeStyles()
					await confirmMessageBox
					resolve()
				} catch (e) {
					reject()
				}
			})
		}
		app.provide('$confirm', app.config.globalProperties.$confirm)

		app.config.globalProperties.$confirmLeave = function (params) {
			// eslint-disable-next-line no-async-promise-executor
			return new Promise(async (resolve, reject) => {
				try {
					// eslint-disable-next-line prefer-const
					let { title, message, ...options } = params
					options = {
						cancelButtonClass: 'confirm-popup__cancel-btn',
						confirmButtonClass: 'confirm-popup__confirm-btn',
						showClose: false,
						skipConfirm: false,
						type: 'warning',
						...options,
						customClass: `confirm-popup confirm-popup--leave ${options.customClass || ''}`,
					}
					const confirmMessageBox = confirm.call(this, title, message, options)
					updateElMessageBoxIframeStyles()
					await confirmMessageBox
					resolve()
				} catch (e) {
					reject(new Error('unconfirmed'))
				}
			})
		}
	},
}