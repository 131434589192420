
import jwtDecode from 'jwt-decode'
import router from '~/plugins/router'
import getUrl from '~/utils/getUrl'
import { iframesCallbacks } from '~/utils/iframes/iframesCallbacks'
import { shareStateUpdate } from '~/plugins/store/shareStateService'
import { setIframeId } from '../iframes'
import updateElMessageBoxIframeStyles from './iframesElMessageBoxStyle'
import updateElNotificationIframeStyles from './iframesElNotificationsStyle'
import updateElDialogIframeStyles from './iframesElDialogStyle'
import updateElDrawerIframeStyles from './iframesElDrawerStyle'
import { updateMainIframeStyles } from './mainIframeStyles'

import {
	openMenuElementCheckup,
	loadLinkSimpleCheckup,
	loadFormSimpleCheckup,
	loadLinkWithParamsCheckup,
} from '../legacyRedirects'

export const iframeEvents = {
	run(type, data) {
		if (type === 'postMessageCallback') {
			const { uid, payload } = data
			iframesCallbacks.run(uid, payload)
			return
		}

		const typeEvent = `${type}Event`
		if (iframeEvents[typeEvent]) {
			iframeEvents[typeEvent].call(this, data)
		}
	},
	async setIdEvent(id) {
		setIframeId(id)
	},
	// Первичная установка токена из родительского айфрейма
	async setTokenEvent({
		company,
		locale,
		accessToken,
		refreshToken,
		expiresAt,
		optima2BackendUrl,
		optimaLegacyBackendUrl,
		applicationUrl,
	}) {
		try {
			// TODO пока что нет нормального разлогина. Вероятно при нескольких айфреймах это будет вызывать баг
			this.$store.dispatch('auth/logout')
			this.$store.commit('user/setBackendUrls', { optima2BackendUrl, optimaLegacyBackendUrl })
			this.$store.commit('user/setApplicationUrl', { applicationUrl })
			await this.setLocale(locale)
			this.$store.commit('auth/SET_TOKEN', { accessToken, refreshToken, expiresAt })
			this.$store.commit('auth/SET_COMPANY', company)
			await this.$store.dispatch('user/setTokenMeta')
			this.$store.commit('auth/SET_AUTHORIZED', true)
			this.$store.commit('user/mLoadComponentState')
			this.setAppBaseUrl(company)
			await this.$store.dispatch('user/aAfterAuth')
		} catch (error) {
			this.$notifyUserAboutError(error)
		}
	},
	// Обновление токена из родительского айфрейма
	async updateTokenEvent({
		accessToken,
		refreshToken,
		expiresAt,
	}) {
		try {
			const { principal } = JSON.parse(jwtDecode(accessToken).sub)

			this.$store.commit('auth/SET_TOKEN', { accessToken, refreshToken, expiresAt })
			await this.$store.dispatch('user/setTokenMeta')
			await this.$store.dispatch('user/aMe')
			await this.$store.commit('users/mSelectedSeller', principal)
		} catch (error) {
			this.$notifyUserAboutError(error)
		}
	},
	iframeSetPreventAutoprintEvent(data) {
		window.preventAutoprint = Boolean(data)
	},
	iframeGetBoundingClientRectEvent(data) {
		this.$store.commit('iframe/setBoundingClientRect', data)
	},
	// Симуляция клика за пределами айфрейма
	iframeOutsideClickEvent() {
		['mousedown', 'mouseup'].forEach(eventType => {
			const clickEvent = new Event(eventType, { bubbles: true, cancelable: false })
			document.dispatchEvent(clickEvent)
		})
	},
	async iframeRedirectEvent(url) {
		const { confirmLeave } = this.$store.state.router

		if (confirmLeave) {
			this.$store.commit('router/mConfirmLeave', {
				...this.confirmLeaveParams,
				skipConfirm: true,
			})
		}

		try {
			const routeObj = JSON.parse(url)
			router.push(routeObj)
		} catch (e) {
			const route = router.currentRoute.value.path

			// костыль для обновления страницы при переоткрытии из легаси
			// router.go(0) не работает
			let [urlPath, urlSearch] = url.split('?') // eslint-disable-line
			if (urlPath === route.path) {
				const routeQuery = route.query

				if (urlSearch) {
					urlSearch = urlSearch.split('&')
					for (let i = 0; i < urlSearch.length; i++) {
						const pair = urlSearch[i].split('=')
						routeQuery[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1])
					}
				}

				let newUrl = getUrl({
					path: urlPath,
					query: routeQuery,
				})

				newUrl = encodeURIComponent(newUrl)
				await router.replace(`/?redirect=${newUrl}`)
			} else if (url !== route.fullPath) {
				router.push(url)
			}

			window.mainIframeShow()
		}
	},
	iframeLegacyScreenPositionsEvent(data) {	
		window.iframeLegacyScreenPositions = data || window.iframeLegacyScreenPositions
		updateElMessageBoxIframeStyles()
		updateElNotificationIframeStyles()
		updateElDialogIframeStyles()
		updateElDrawerIframeStyles()
		updateMainIframeStyles()
	},
	checkVisionEvent(data) {
		this.mSetLegacyClientData(data)
		this.mClientCheckVisionData(data)
	},
	stateUpdatedEvent(data) {
		shareStateUpdate(data)
	},
	async openMenuElementInNewFrontEvent(menuItem) {
		try {
			const result = await openMenuElementCheckup(menuItem)

			if (result) {
				window.hideLegacyPopups()
				window.mainIframeShow()
			} else {
				window.mainIframeHide()
				window.commonF.openMenuElementLegacy(menuItem)
			}
		} catch (error) {
			// Navigation aborted
		}
	},
	async loadLinkSimpleInNewFrontEvent(url) {
		try {
			const result = await loadLinkSimpleCheckup(url)
			if (result) {
				window.hideLegacyPopups()
				window.mainIframeShow()
			} else {
				window.mainIframeHide()
				window.loadLinkSimpleLegacy(url)
			}
		} catch (error) {
			// Navigation aborted
		}
	},
	async loadLinkWithParamsInNewFrontEvent(params) {
		try {
			const result = await loadLinkWithParamsCheckup(params.url)
			if (result) {
				window.hideLegacyPopups()
				window.mainIframeShow()
			}
		} catch (error) {
			// Navigation aborted
		}
	},
	async loadFormSimpleInNewFrontEvent({ url, formId }) {
		try {
			const result = await loadFormSimpleCheckup(url, formId)
			if (result) {
				window.hideLegacyPopups()
				window.mainIframeShow()
			} else {
				window.mainIframeHide()
				if (!formId) {
					window.loadLinkSimpleLegacy(url)
				} else {
					window.loadFormSimpleLegacy(url, formId)
				}
			}
		} catch (error) {
			// Navigation aborted
		}
	},

	// markNewsAsReadEvent вызывается из легаси при нажатии на кнопку "Прочитано" в попапе с новостью
	async markNewsAsReadEvent(newsId) {
		this.$store.dispatch('news/aMarkAsRead', newsId)
	},
}
