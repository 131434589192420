export default {
	mCancellationsStatuses(state, payload) {
		state.cancellationsStatuses = payload
	},
	mCancellationsBasises(state, payload) {
		state.cancellationsBasises = payload.content || []
	},
	mCancellationsGoodsTypes(state, payload) {
		state.cancellationsGoodsTypes = payload
	},
}