import updateElNotificationIframeStyles from '~/utils/iframes/iframesElNotificationsStyle'

export default {
	install(app) {
		const { error, success } = app.config.globalProperties.$notify

		app.config.globalProperties.$notify.error = function (...args) {
			// eslint-disable-next-line no-async-promise-executor
			return new Promise(async (resolve, reject) => {
				try {
					const notify = error.call(this, ...args)
					updateElNotificationIframeStyles()
					await notify
					resolve()
				} catch (e) {
					reject()
				}
			})
		}

		app.config.globalProperties.$notify.success = function (...args) {
			// eslint-disable-next-line no-async-promise-executor
			return new Promise(async (resolve, reject) => {
				try {
					const notify = success.call(this, ...args)
					updateElNotificationIframeStyles()
					await notify
					resolve()
				} catch (e) {
					reject()
				}
			})
		}
	},
}