<template>
	<div
		class="com-field-select-header"
	>
		<div
			class="clickable"
			@click.stop="selectAll"
		>{{ $t('Выбрать всё') }}</div>

		<div
			class="clickable"
			@click.stop="deselectAll"
		>{{ $t('Сбросить всё') }}</div>
	</div>
</template>

<script setup>
const emit = defineEmits([
	'selectAll',
	'deselectAll',
])

const selectAll = () => emit('selectAll')
const deselectAll = () => emit('deselectAll')
</script>

<style lang="scss">
.com-field-select-header {
	padding: 0 10px;
	display: flex;
	justify-content: space-between;
}
</style>