import VuexHttp from '../../vuexHttpService'

const vHttp = new VuexHttp('cancellations')

export default {
	async aCancellationsStatuses() {
		return vHttp.GET(this, {
			url: {
				path: 'cancellations/statuses',
			},
			mutation: 'mCancellationsStatuses',
		})
	},
	async aCancellationsBasises(_, params) {
		return vHttp.GET(this, {
			url: {
				path: 'general/cancellation-basises/list',
				query: {
					...params,
					size: 1000,
				},
			},
			mutation: 'mCancellationsBasises',
		})
	},
	async aCancellationsById(_, id) {
		return vHttp.GET(this, {
			url: {
				path: `cancellations/${id}`,
			},
			mutation: 'mJournalsCancellations',
		})
	},
	async aCancellationsByGoodsId(_, id) {
		return vHttp.GET(this, {
			url: {
				path: `cancellations/goods/${id}`,
			},
			mutation: 'mJournalsCancellations',
		})
	},
	async aCancellationsGoodsTypes(_) {
		return vHttp.GET(this, {
			url: {
				path: `cancellations/goods-types`,
			},
			mutation: 'mCancellationsGoodsTypes',
		})
	},
}