// В element ui счетчики и иконки абсолютно споцизионированы
// поэтому перекрывают контент инпута 

export default {
	componentUpdated(el) {
		const elInputSuffix = el.querySelector('.el-input__suffix') || el.querySelector('.el-input__count')
		const elInputInner = el.querySelector('.el-input__inner')
			|| el.querySelector('.el-textarea__inner')

		if (!elInputSuffix) return

		const counterWidth = elInputSuffix.getBoundingClientRect().width
		elInputInner.style.paddingRight = `${counterWidth}px`
	},
}