<!-- eslint-disable vue/no-v-html -->
<template>
	<el-tooltip
		class="part-suffix-tooltip item"
		effect="dark"
		:disabled="!suffixSlot.hasTooltip"
		placement="left-start"
		offset="10"
	>
		<template #content>
			<div class="part-suffix-tooltip__tooltip-content">{{ suffixSlot.tooltipContent }}</div>
		</template>
		<ItIcon
			:name="suffixSlot.iconName"
			width="16"
			size="16"
			class="fill-text-placeholder"
		/>
	</el-tooltip>
</template>

<script>

export default {
	name: 'PartSuffixTooltip',

	props: {
		suffixSlot: {
			type: Object,
			default: null,
		},
	},
}
</script>


<style lang="scss">
.part-suffix-tooltip {
	&__tooltip-content {
		width: 256px;
		font-size: 12px !important;
	}
}
</style>