<template>
	<div
		:data-ta="`checkboxes_${name}`"
		class="com-field-item com-field-checkboxes"
	>
		<el-form-item
			:ref="name"
			:label="label"
			:prop="name"
			:label-width="labelWidth"
			class="line-height-base"
		>
			<div class="d-flex w-100p">
				<el-checkbox
					v-for="(checkbox, idx) in options"
					:key="checkbox.property"
					v-model="model[checkbox.property]"
					:disabled="disabled || readonly || !isEdit"
					:value="checkbox.value"
					:label="checkbox.label"
					:data-ta="checkbox.property"
					:class="{ 'ml-4': idx }"
					:size="size"
					class="flex-1"
					border
					@change="value => onChange(value, checkbox)"
				/>
			</div>
		</el-form-item>
	</div>
</template>

<script>
import defaultProps from './defaultFieldsProps'

export default {
	name: 'ComFieldCheckboxes',
	components: {
	},
	props: {
		...defaultProps,
		options: {
			type: Array,
			default: () => ([]),
		},
		values: {
			type: Object,
			required: true,
		},
	},
	computed: {
		model: {
			get() { return this.values || {} },
			set(value) {
				return value
			},
		},
	},
	methods: {
		onChange(value, checkbox) {
			this.$emit('on-checkbox-change', checkbox.property, value)
		},
	},
}
</script>

<style lang="scss">
.com-field-checkboxes {
	.el-checkbox__input {
		display: inline-flex;
		align-items: center;
	}
	.el-checkbox {
		margin-right: 0;
	}
}
</style>