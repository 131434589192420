import i18n from '~/plugins/i18n'

export const usersColumns = [
	{
		prop: 'fullName',
		label: i18n.global.t('ФИО'),
		width: '193px',
	},
	{
		prop: 'userGroupName',
		label: i18n.global.t('Должность'),
		width: '193px',
	},
	{
		prop: 'departmentName',
		label: i18n.global.t('Департамент'),
		width: '193px',
	},
]