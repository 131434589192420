import capitalizeFirstLetter from '~/utils/capitalizeFirstLetter'

export function getMonths(locale) {
	const format = new Intl.DateTimeFormat(locale, { month: 'long' })
	const months = []

	for (let month = 0; month < 12; month++) {
		const testDate = new Date(Date.UTC(2000, month, 1, 0, 0, 0))
		months.push(capitalizeFirstLetter(format.format(testDate)))
	}

	return months
}

export function getWeekDays({ locale, mode = 'long', isSundayFirst = false }) {
	const format = new Intl.DateTimeFormat(locale, { weekday: mode })
	const weekDays = []

	for (let weekDay = 0; weekDay < 7; weekDay++) {
		// 3 января 2000 - понедельник
		const testDate = new Date(Date.UTC(2000, 0, 3 + weekDay, 0, 0, 0))
		weekDays.push(capitalizeFirstLetter(format.format(testDate)))
	}

	if (isSundayFirst) weekDays.unshift(weekDays.pop())

	return weekDays
}