import documentsState from './documentsState'
import documentsActions from './documentsActions'
import documentsMutations from './documentsMutations'
import documentsGetters from './documentsGetters'

export default {
	namespaced: true,
	state: documentsState,
	getters: documentsGetters,
	mutations: documentsMutations,
	actions: documentsActions,
}